import React from 'react'
import {orderBy, sumBy} from "lodash";
import styled from 'styled-components'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import ProfileSection, {ProfileSectionProps} from '../ProfileSection'
import styledContainerQuery from 'styled-container-query'
import metrics from '../../../configMetrics'


import {SimilarWeb} from '../../../components/Icons/SimilarWeb'

import {formatToReadableNumber} from '../../../utils/format'
import resolveCountry from "../../../utils/resolveCountry";
import { CHART_COLORS } from '../../../config'
import CountryFlag from "../../CountryFlag";

ChartJS.register(ArcElement, Tooltip)

interface Country {
    code: string;
    visits: number;
}

interface ChartData {
    labels: string[];
    datasets: {
        backgroundColor: string[];
        data: number[];
        hoverOffset: number;
    }[];
}

interface AudienceData {
    total: number;
    visits: number | undefined;
    chartData: ChartData;
    labels: Array<string>;
    data: Array<number>
}

interface AudienceProps {
    loading?: boolean
    total?: number
    countries: Array<Country>
}

const Root = styled(ProfileSection)<ProfileSectionProps>`
  display: flex;
  flex-direction: column;
  gap: 2em;
`
const Main = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3em;
`

const LegendContainer = styled.ul`
  list-style: none;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
`
const LegendItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  gap: 0.5em;
  &:before  {
    content: ''; 
    background: ${props => props.color };
    display: inline-block; 
    width: 1em;
    height: 1em;
    border-radius: 2em;
    margin-left: -1em;
    margin-right: 1em;
  }
`

const LegendCountry = styled.span`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 0.5em;
  `
const LegendVisits = styled.span`
    flex: 0 0 5ch;
  font-weight: bold;
  `
const DoughnutContainer = styled.div`
  width: 100%;
  min-width: 100px;
  max-width: 350px;
  height: 200px;
  cursor: pointer;
  user-select: none;
  position: relative;
  right: 0;
  //top: -1.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2 1 0;
`
const Total = styledContainerQuery.div`
    pointer-events: none;
    color: ${props => props.theme.palette.accentDarkText};
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.43;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: -1;

    &:container(min-width: 120px){
      font-size: 1.3em;
    } 
    &:container(min-width: 150px){
      font-size: 1.7em;
    } 
    &:container(min-width: 180px){
      font-size: 2.3em;
    } 
`

const FooterContainer = styled.div`
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  //margin: 0 -15px -10px -15px;
  position: relative;
`

const FooterSource = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  opacity: 0.7;
  flex: 1 1 50px;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  height: 25px;
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: black;
  }
`

const Footer = () => {
    return <FooterContainer>
        <FooterSource>
            {/*TODO: Refactor copy to config*/}
            <a href={metrics.visits.poweredBy.href} target="_blank">Insights by&nbsp;&nbsp;<SimilarWeb/></a>
        </FooterSource>
    </FooterContainer>
}

const country = (geo) => geo !== 'other' ? resolveCountry(geo).label : ' Other'

const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: '60%',
    plugins: {
        labels: {
            render: 'percentage',
            precision: 2
        },
        tooltip: {
            callbacks: {
                label: function (tooltipItem) {
                    const { label, parsed } = tooltipItem
                    return ` ${country(label)} ${formatToReadableNumber(parsed)}`
                },
                title: function () {
                    return null
                }
            }
        }
    }
}

const getAudienceData = (countries: Country[], limit: number, total: number): AudienceData => {
    let totalByCountries = Math.round(sumBy(countries, 'visits'))

    const sorted = orderBy(countries, ['visits'], ['desc'])
    const first = sorted.slice(0, limit)
    const labels = first.map(({ code }) => code)
    const data = first.map(({ visits }) => Math.round(visits))

    const otherVisits = total - totalByCountries

    if (otherVisits >= 1) {
        labels.push('other')
        data.push(Math.round(otherVisits))
    }

    return {
        total,
        visits: total,
        chartData: { labels, datasets: [{ backgroundColor: CHART_COLORS, data, hoverOffset: 4 }]},
        labels,
        data
    }
}

const Audience = (props: AudienceProps)  => {
    const { chartData, labels, data, total } = getAudienceData(props.countries, 10, props.total)

    return (
            <Root title='Audience'>
                <Main>
                    <DoughnutContainer>
                        <Total>{formatToReadableNumber(total, true)}</Total>
                        <Doughnut
                            data={chartData}
                            options={options}
                        />
                    </DoughnutContainer>
                    <LegendContainer>
                        {
                            labels.map((label, index) =>
                                <LegendItem color={CHART_COLORS[index]}>
                                    <LegendVisits> {formatToReadableNumber(data[index], true)}</LegendVisits>
                                    <LegendCountry> {country(label)}</LegendCountry>
                                </LegendItem>)
                        }
                    </LegendContainer>
                </Main>
                <Footer />
            </Root>
    )
}

export default Audience
