import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'

import { sendEvent } from '../../utils/events'


const Root = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 104px);
  margin: -50px auto 0 auto;
  justify-content: center;
  h3 {
    font-family: "GT Super Display", serif;
    font-size: 24px;
    line-height: 28px;
    color: #000021;
    margin: 20px 0 0 0;
  }
  p {
    font-size: 18px;
    color: #000021;
    margin: 16px 0 0 0;
    line-height: 150%;
  }
  h1 {
    font-family: "GT Super Display", serif;
    font-size: 3em;
  }
`;

const ContainerBooked = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 2em;
  width: 100%;
  height: ${props => props.modal ? 'calc(100vh - 159px)' : '100vh'};
  //top: 236px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: ${props => props.modal ? '53px' : 0 };
  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }
`
const Info = styled.div`
  text-align: left;
  padding-left: 20%;
  padding-top: 8em;

`
const BookingArea = styled.div`
position: relative;
`

const injectCalendly = () => {
  if (!!document.querySelector('#calendly')) return false
  const script = document.createElement('script')
  script.id = 'calendly'
  script.type = 'text/javascript'
  script.src = 'https://assets.calendly.com/assets/external/widget.js'
  script.async = true
  document.getElementsByTagName('head')[0].appendChild(script)

  return true
}

const timeout = () => new Promise(resolve => {
  setTimeout(resolve, 1000)
})

const waitForCalendly = () => new Promise (async (resolve) => {
  if (!window.Calendly) {
    await timeout()
    const calendly = await waitForCalendly()
    return resolve(calendly)
  }

  return resolve(window.Calendly)
})

export default class WelcomeBookDemo extends React.Component {
  constructor(props) {
    super(props)
    injectCalendly()
    this.state = { booked: props.booked }
  }

  componentDidMount() {
    const { brand } = this.props
    waitForCalendly().then(calendly => {
      const { fullName, email } = this.props
      calendly.initInlineWidget({
        url: 'https://calendly.com/alexander-126/growth-call?hide_landing_page_details=1&hide_gdpr_banner=1',
        parentElement: document.getElementById('calendlyWidget'),
        prefill: {
          name: fullName,
          email: email
        }
      })

      const isCalendlyEvent = (e) => {
        return e.data.event &&
          e.data.event.indexOf('calendly') === 0;
      }

      window.addEventListener(
        'message',
        (e) => {
          if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
            if (this.props.onDemoBooked) {
              this.props.onDemoBooked()
            }
            sendEvent(brand.id, 'demoScheduled')
            this.setState({ booked: true })
          }
        }
      );
    })
  }

  render () {
    const { variant, billingStatus, booked: bookedProps } = this.props
    const { booked: bookedState } = this.state
    const booked = bookedState || bookedProps


    return (

      <Root>
        {
          booked && <ContainerBooked>
            <h1>Thank you for booking a demo!</h1>
            <div style={{maxWidth: '50ch'}}>
              {
                !billingStatus && (
                  <>
                    <Link to={'/welcome/discovery'} style={{textDecoration: 'none'}} onClick={this.props.onToSelfServe}>
                      <Button variant={'contained'} color={'primary'} fullWidth>Try out your Self-serve Free Trial</Button>
                    </Link>
                    <br/><br/>
                  </>
                )
              }
              <Button variant={'outlined'} color={'primary'} fullWidth href={'https://breezy.io/blog'}>Check out articles in our blog</Button>
            </div>
          </ContainerBooked>
        }
        {
          !booked && (
            <Container modal={this.props.modal}>
              <Info>
                <div style={{maxWidth: '50ch',}}>
                  <h1>Breezy Assisted</h1>
                  <p>Want Breezy’s  partnership experts to help with the heavy lifting?</p>

                  <p>With Breezy Assisted, a dedicated Search Success Manager will tailor Breezy’s powerful partner search engine to generate you thousands of qualified Leads and Prospects – all without you lifting a finger!</p>

                  <p>It’s the perfect solution for busy partnerships teams who want to leave the partner discovery to us so they can focus on negotiating and honing successful partnerships.</p>

                  <p><strong>Just book in for a demo so we can show you how it works.</strong></p>
                  <br/><br/>
                  {
                    variant !== 'billing' && (
                      <Link style={{textDecoration: 'none'}} to={'/welcome/plans'} onClick={this.props.onBackToPlans}>
                        <Button variant={'outlined'} color={'primary'}>&larr; Back to plans selection</Button>
                      </Link>
                    )
                  }
                </div>
              </Info>
              <BookingArea>
                <div id="calendlyWidget" style={{width: '100%', height: '100%'}}/>
              </BookingArea>
            </Container>
          )
        }
      </Root>
    )
  }
}
