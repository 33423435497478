import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { LinearProgress as MuiLinearProgress } from '@material-ui/core'
import { Coffee } from '../../components/Icons/Coffee'
import { PaperPlane } from '../../components/Icons/PaperPlane'
import Notification from '../../components/Notification'

const Img = styled.div`
  width: 100%;
  max-width: 400px;
  //height: 250px;
  margin: 0 0 35px 0;
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${props => props.alignLeft ? 'flex-end' : 'center'} ;
  align-items: center;
  //flex-direction: column;
  padding: ${props => props.padding};
  h1 {
    font-family: "GT Super Display", serif;
    font-weight: bold;
    font-size: 32px;
    color: #000021;
    margin: 0 0 32px 0;
    max-width: 400px;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #3E3E3E;
    margin: 0;
    max-width: 400px;
    text-align: center;
  }
  form {
    padding: 28px 0 0 0;
  }
  &.imgRight {
    flex-direction: row;

    h1, p {
      text-align: left;
    }
    ${Img} {
      margin: 0 0 0 15%;
    }
    @media (max-width: 1100px) {
      ${Img} {
        margin: 0 20px 0 20px;
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`
const MessageContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
`
const Message = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  margin-bottom:6em;
  font-family: Work Sans, Arial, sans-serif;
  margin-left: -80px;
`
const MessageContent = styled.div`
  flex: 1 1 auto;
  padding-left: 1em;
`
const MessageHeading = styled.h2`
  font-size: 16px;
  color: #3E3E3E;
  font-weight: 600;
  margin: 0;
  padding-bottom: 0.5em;
`
const MessageBody = styled.div`
  font-size: 14px;
  color: #3E3E3E;
`
const MessageIcon = styled.div`
  width: 56px;
`
const Spacer = styled.div`
  height: 8em;
`

const LinearProgress = styled(MuiLinearProgress)`
  max-width: 330px;
  margin: 4em 0 0 0;
  background: ${props => props.theme.palette.brand.turquoise.main};
  border-radius: 6px;
  .MuiLinearProgress-barColorPrimary {
    background: ${props => props.theme.palette.brand.blue.main};
    border-radius: 3px;
  }
`;

class Running extends React.Component {
  constructor (props) {
    super(props)
    const { verified } = props
    this.state = { verified }
  }

  componentDidMount () {
    const { emailStatus, researchStatus } = this.props

    if (emailStatus === 'verified' && ['pending', 'progress'].includes(researchStatus)) {
      this.props.onStartResearch()
    }
  }

  onCloseNotification = () => this.setState({ verified: this.props.dev || false })

  render () {
    const { verified } = this.state



    return (
      <>
        <Root className="imgRight">
          <Notification
            open={verified && !this.props.disableNotification}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            message={'Email address has been verified!'}
            timeout={3000}
            onClose={this.onCloseNotification}
          />
          <div>
            <h1>
              Our engine is running
            </h1>
            <p>Digging out relevant leads is hard work!</p>
            <p>
              This could take 10-15 minutes so grab yourself a cuppa. We’ll drop you an email once your report is ready.
            </p>
            <LinearProgress variant="indeterminate" />
          </div>
          <Img>
            <Coffee />
          </Img>
        </Root>
        <MessageContainer>
          {/*<Message>*/}
          {/*  <MessageIcon>*/}
          {/*    <PaperPlane />*/}
          {/*  </MessageIcon>*/}
          {/*  <MessageContent>*/}
          {/*    <MessageHeading>We've sent you an email with some handy tips.</MessageHeading>*/}
          {/*    <MessageBody>While you’re waiting for your search to finish, why not get yourself up to speed with Breezy?</MessageBody>*/}
          {/*  </MessageContent>*/}
          {/*</Message>*/}
        </MessageContainer>
      </>
    )
  }
}

export default Running
