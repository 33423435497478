import React, {useContext} from 'react'

import {DetailsContext} from '../DetailsContext'
import Audience from '../../Profile/Audience'

export default function ProfileAudience (props) {
    const { loading, countries, summary } = useContext(DetailsContext)

    return <Audience loading={loading} countries={countries} total={summary.swVisits}/>
}
