import React, { useContext } from 'react'

import { DetailsContext } from '../DetailsContext'
import WidgetsGrid from '../../Widget/WidgetsLayout'
import ItemSummary from '../ItemSummary'
import DetailsMetrics from './DetailsMetrics'
import DetailsPromotesEcosystem from './DetailsPromotesEcosystem'
import DetailsHighestRankingKeywords from './DetailsHighestRankingKeywords'
import DetailsNetworks from './DetailsNetworks'
import DetailsAppearsIn from './DetailsAppearsIn'
import { _Actions as Actions } from '../../Actions/Actions'
import LinkButton from '../../LinkButton'

interface DetailsViewProps {
    tagManagement?: any
    onSetViewState: Function
    onQuickActions: () => void
}

export default function DetailsView (props: DetailsViewProps) {
    const { loading, variant } = useContext(DetailsContext)

    return (
        <>
            <Actions onQuickActions={props.onQuickActions} />
            <ItemSummary tagManagement={props.tagManagement} />
            <WidgetsGrid>
                {
                    variant === 'gap' && <DetailsPromotesEcosystem />
                }
                <DetailsMetrics onClick={props.onSetViewState}/>
                {
                    !['gap', 'landscape', 'partnerships', 'creator'].includes(variant) && <DetailsHighestRankingKeywords variant={variant !== 'lead' ? 'keywordsHighestAll' : 'keywordsHighest'}/>
                }
                {
                    variant !== 'creator' && <DetailsAppearsIn />
                }
                <DetailsNetworks onClick={props.onSetViewState} />
                <LinkButton loading={loading} fullWidth onClick={() => props.onSetViewState('profile')} to={'#'}>Explore full profile</LinkButton>
            </WidgetsGrid>
        </>
    )
}
