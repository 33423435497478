import React, { useContext } from 'react'

import {DetailsContext} from '../DetailsContext'
import Placements from '../../Profile/Placements'
import {useParams} from "react-router-dom";


export default function ProfilePlacements (props) {
    // @ts-ignore
    const { brandId } = useParams()
    const { loading, placements, summary } = useContext(DetailsContext)

    const link = `/brand/${brandId}/discovery`
    return (
        <Placements loading={loading} placements={placements} link={link} summary={summary} />
    )
}
