import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Autocomplete from '../../components/Autocomplete'
import { inject, observer } from 'mobx-react'

const Root = styled.div`
  max-width: 544px;
  width: 100%;
  //margin-top: -16px;
`

@inject(({ TagsStore }) => ({
  options: TagsStore.tagFilterValues
}))
@observer
export default class _TagsManagement extends React.Component {
  render () {
    const {
      value,
      disabled
    } = this.props

    return (
      <Root>
        <Autocomplete
          disabled={disabled}
          options={this.props.options}
          value={value || []}
          onChange={(e, value) => this.props.onChange(value)}
          placeholder={(!!value && value.length > 0) ? false : 'Tags'}
        />
      </Root>
    );
  }
}
