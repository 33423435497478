import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Chip, IconButton } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'


import SelectDropdown from './SelectDropdown'

const Root = styled.div`
  display: flex;
  //justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const LabelContainer = styled.div`
  font-size: 1.1em;
  font-weight: 400;
  /* top | right | bottom | left */
  > * {
    margin: 0 10px 0 0!important;
  }
`

const Placeholder = styled.span`
  color: grey;
`


export default function SingleValue ({ value, options, placeholder, onApply }) {
  const containerRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const onOpen = () => {
    if (!anchorEl) {
      setAnchorEl(containerRef.current)
    }
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return <Root ref={containerRef} onClick={onOpen}>
    <LabelContainer>
      { Boolean(value && value.label) ? <span>{value.label}</span> : <Placeholder>{placeholder}</Placeholder> }
    </LabelContainer>
    <div>
      <IconButton onClick={onOpen}>
        <ArrowDropDown />
        {/*{ anchorEl ? <ArrowDropUp /> : <ArrowDropDown /> }*/}
      </IconButton>
    </div>
    {
      Boolean(anchorEl) && (
        <SelectDropdown
          anchorEl={anchorEl}
          options={options}
          placeholder={'Search competitors'}
          onClose={onClose}
          onApply={onApply}
        />
      )
    }
  </Root>
}

SingleValue.defaultProps = {
  value: null,
  options: []
}
