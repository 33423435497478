import React from 'react'
import { inject, observer } from 'mobx-react'
import Details from './Details'


@inject(({ SearchesStore }) => ({
  onLoadProduct: id => SearchesStore.loadProduct(id),
  onUpdate: (details) => SearchesStore.updateProduct(details)
}))
@observer
class _Details extends React.Component {
  constructor (props) {
    super(props)
    this.state = { loading: true }
  }

  componentDidMount () {
    const { id } = this.props
    this.props.onLoadProduct(id).then(product => {
      const { name, description, channel, competitorUrls, keywords, irrelevants } = product
      this.setState({
        link: channel.link,
        name,
        description,
        competitors: competitorUrls || [],
        keywords: keywords || [],
        blocklist: irrelevants || [],
        planLimits: {},
        loading: false
      })
    })
  }

  onUpdate = async (details) => {
    const { id } = this.props
    return await this.props.onUpdate({ id, ...details })
  }

  render () {
    return <Details {...this.state} {...this.props} onUpdate={this.onUpdate}/>
  }
}

export default _Details
