import React, { Component } from 'react'
import styled, {css} from 'styled-components'
import WidgetBase, {Label, Value, WidgetBaseProps} from '../WidgetBase'
import ButtonBase from '@mui/material/ButtonBase'
import {formatPercent, formatToReadableNumber as format} from '../../../../utils/format'

const Unit = styled.div`
    font-size: 0.8em;
    line-height: 1.43;
    opacity: 0.7;
    display: inline-block;
`

const UnitWrapperRoot = styled.div`
    display: flex;
    align-items: center;
    gap: 0.2em;
`

const ProgressRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: auto;
  :before {
    content: '';
    width: ${props => props.value}%;
    height: 100%;
    background-color: ${props => props.theme.palette.brand.blue.light};
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    border-radius: ${props => props.theme.shape.borderRadius * 2}px;
  }
`

const Content = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
`

const Root = styled(WidgetBase)`
 ${props => props.progress && css`
   // ${props => props.disableOutline && css`
   //   box-shadow: none;
   // `}
   // background-color: ${props => props.theme.palette.brand.shadow.light};
 `}
`

export function Progress ({value, valueMax, inPercent}: {value?: number, valueMax?: number, inPercent: boolean}) {
    let progress
    if (valueMax && !inPercent) progress = <ProgressRoot value={value / valueMax * 100} />
    if (inPercent && value) progress = <ProgressRoot value={value} />

    return progress
}

export function UnitWrapper ({children, unit}: {children: React.ReactNode, unit?: string}) {
    return unit
            ? <UnitWrapperRoot>
                {children}{unit && <Unit>{unit}</Unit>}
            </UnitWrapperRoot>
            : <>{children}</>

}

export interface MetricProps extends WidgetBaseProps {
    link?: string
    unit?: string
    value?: number
    valueMax?: number
    outline?: boolean
}

function getValue (value: number, valueMax: number, inPercent: boolean, progress: boolean) {
    if (valueMax) return `${format(value || 0)}/${format(valueMax)}`
    if (progress) return value || undefined
    if (inPercent) return formatPercent(value)

    return value ? format(value) : undefined
}

function WidgetMetricBase ({value, valueMax, unit, link, label, loading, tooltip, outline, children, ...other}: MetricProps) {
    let tooltipProps = !loading ? tooltip : null
    const inPercent = unit === '%'
    const progress = Boolean((valueMax || inPercent) && value)
    const _value = getValue(value, valueMax, inPercent, progress)
    const hideUnit = !value && !valueMax

        // progress
        // ? inPercent ? formatPercent(value) : `${format(value)}/${format(valueMax)}`
        // : value ? format(value) : undefined
    return (
            <Root {...other} loading={loading} label={label} tooltip={tooltipProps} component={ButtonBase} progress={valueMax} disableOutline={!outline && valueMax}>
                <Progress value={value} valueMax={valueMax} inPercent={inPercent}/>
                <UnitWrapper unit={!hideUnit ? unit : undefined}>
                    <Value value={_value} className='WidgetMetricBase__ValueRoot' />
                </UnitWrapper>
                {children && <Content>{children}</Content>}
            </Root>
    )
}

export default WidgetMetricBase
