import { observable, action, computed, toJS } from 'mobx'
import moment from 'moment'
import { Get, Post, Put } from '../utils/request'
import hasError from './request-message'
import Notification from './notification'
import LeadsListStore from './leads-list'
import ReportStore from './report'
import ProspectLeadsStore from './prospect-leads'
import CompetitorLeadsStore from './competitor-leads'
import IrrelevantLeadsStore from './irrelevent-leads'
import { find } from 'lodash'
import countries from '../components/Leads/CountriesFlag'
import config from '../config'
import GqlClient from '../gql/gql-client'
import { GET_SEARCH, RUN_SEARCH, UPDATE_SEARCH } from '../gql/searches'

import SearchesStore from './searches'

const POLLING_INTERVAL = 10000
const RESEARCH_FAILED_TIME = 30

const researchStatusFailed = researchStartAt => {
  return moment().diff(moment(researchStartAt), 'minutes') > RESEARCH_FAILED_TIME
}

const STRING_ARRAY_FIELDS = [
  'keywords',
  'irrelevants',
  'competitorUrls'
]

export class SearchStore {
  @observable brandId
  @observable id
  @observable name
  @observable description
  @observable country
  @observable loading = true
  @observable thinking = false

  @observable tlds = []
  @observable researchStatus
  @observable researchStartAt

  @observable leadsCount = 0
  @observable leadsMaxValues = {}

  @observable keywords = []
  @observable competitorUrls = []
  @observable newCompetitorUrls = []
  @observable irrelevants = []
  @observable competitorsCount = 0
  @observable keywordsCount = 0
  @observable irrelevantsCount = 0

  @observable hidden = false
  @observable expiredAt

  @observable user = {}

  @observable channel = {}
  @observable channelId = null

  @observable researchSeen = false
  @observable firstResearchSeen = false

  @observable competitorUrlsChanged = false
  @observable keywordsChanged = false
  @observable irrelevantsChanged = false

  constructor () {}

  stringToArray (str) {
    if (str) {
      return str.split('\n')
    }
    return []
  }

  updateSearchFields (model) {
    for (let key in model) {
      if (model.hasOwnProperty(key)) {
        if (STRING_ARRAY_FIELDS.includes(key)) {
          this[key] = this.stringToArray(model[key])
        } else {
          this[key] = model[key]
        }
      }
    }

    const channelLink = (model.channel || {}).link
    const countryCode = model.country.code
    const countryLabel = (find(countries, ['value', countryCode]) || {}).label || countryCode

    this.keywordsChanged = model.keywordsChanged
    this.competitorUrlsChanged = model.competitorUrlsChanged
    this.irrelevantsChanged = model.irrelevantsChanged
    this.name = model.name // || 'My first search or service'
    this.description = model.description || `${model.name} in ${countryLabel}`
    this.country = countryCode

    // this.description = model.description || `${model.name} in ${find(countries, ['value', model.country]).label}`
    this.researchSeen = model.researchSeen
    this.firstResearchSeen = model.firstResearchSeen

    if (model.researchStatus === 'progress' && researchStatusFailed(model.researchStartAt)) {
      this.researchStatus = 'failed'
    }
  }

  @action
  async load (id, brandId, noLoading, view) {
    this.id = id || this.id
    this.loading = !noLoading

    let search

    const { success, ...rest } = await GqlClient.query({ query: GET_SEARCH, variables: { id: this.id } })

    if (success) {
      search = rest.data['search']
      this.updateSearchFields(search)

      if (this.researchStatus === 'progress') {
        this.polling().then()
      } else if (this.researchStatus === 'researched' && !this.researchSeen) {
        Notification.show('Your search is ready.', 'success', 10000)
        await this.resetReportSeen()
      }

      // this.updateSearchFields(search)
      // if (this.researchStatus === 'progress') {
      //   this.polling().then()
      // } else if (this.researchStatus === 'researched' && !this.researchSeen) {
      //   Notification.show('Your search is ready.', 'success', 10000)
      //   await this.resetReportSeen()
      // } else if (this.researchStatus === 'failed') {
      //   if (this.firstResearchSeen && !this.researchSeen) {
      //     Notification.show('Search failed. Please try again.', 'error', 10000)
      //   } else {
      //     await this.startResearch()
      //   }
      // }
      //
      // if (!details) {
      //   LeadsListStore.setSearchId(this.id, noLoading).then()
      //
      //   const { leadsMaxValues } = search
      //
      //   let initialFilters = {
      //     ...(!search.firstResearchSeen && { keywordSeedsCount: [ 1, leadsMaxValues.keywordSeedsCount ] }),
      //     ...(!search.firstResearchSeen && { competitorSeedsCount: [ 1, leadsMaxValues.competitorSeedsCount ] }),
      //   }
      //
      if (!view) {
        ReportStore.load(this.id, brandId, noLoading).then()
        CompetitorLeadsStore.load(this.id, brandId, noLoading).then()
        IrrelevantLeadsStore.load(this.id, brandId, noLoading).then()
      }
    }

    this.loading = false

    return { success, search }
  }

  @action
  async createCompany (details) {
    // const { success } = await CompanyStore.create(details)
    // if (success) { this.load('default', true).then() }
    // return { success }
  }

  sourceQuery ({ competitorUrls, keywords }) {
    const createCompetitors = !this.competitorUrls.length && competitorUrls && competitorUrls.length
    const createKeywords = !this.keywords.length && keywords && keywords.length
    return createCompetitors || createKeywords ? '?source=onboarding' : ''
  }

  @action
  async updatePartners ({ partners: links }) {
    this.thinking = true
    const { message: error } = await Post(`/api/discovery/existing-partners/import-from-list`, { links })
    this.thinking = false
    return { error }
  }

  @action
  async updateChannel(id, { link }, searchId) {
    const { success } = await Put(`/api/discovery/channels/${id}/update-candidate`, { candidate: { link }, search: searchId })
    return { success }
  }

  @action
  async update (_details) {
    const { competitors, keywords, name, description } = _details

    const UpdateSearchInput = {
      id: this.id,
      patch: {
        ...(competitors && { competitorUrls: toJS(competitors).filter(v => !!v).join('\n') }),
        ...(keywords && { keywords: toJS(keywords).filter(v => !!v).join('\n') }),
        ...(name && { name }),
        ...(description && { description }),
      }
    }

    const { success, ...update } = await GqlClient.mutation({ mutation: UPDATE_SEARCH, variables: { UpdateSearchInput } })

    if (success) {
      const search = update.data['updateSearch']
      this.updateSearchFields(search)
      SearchesStore.updateSearchByID(search)
      return { success, search }
    }
    return { success }
  }

  @action
  async updateCounters () {
    const { success, ...rest } = await GqlClient.query({ query: GET_SEARCH, variables: { id: this.id } })
    if (success) {
      this.updateSearchFields(rest.data['search'])
    }
  }

  pollingInterval = null

  @action
  async resetReportSeen () {
    if (this.researchSeen) {
      return false
    }

    const UpdateSearchInput = {
      id: this.id,
      patch: {
        researchSeen: true
      }
    }

    this.researchSeen = true
    this.firstResearchSeen = true

    const { success, ...update } = await GqlClient.mutation({ mutation: UPDATE_SEARCH, variables: { UpdateSearchInput } })

    if (!hasError(success, 'message', '')) {
      const search = update.data['updateSearch']
      this.updateSearchFields(search)
      SearchesStore.updateSearchByID(search)
    }
  }

  @action
  async polling () {
    const { success, ...rest } = await GqlClient.query({ query: GET_SEARCH, variables: { id: this.id } })

    if (success) {
      const search = rest.data['search']
      const { researchStatus, researchStartAt } = search
      this.updateSearchFields(search)

      if (researchStatus === 'researched') {
        clearInterval(this.pollingInterval)
        SearchesStore.updateSearchByID(search)

        if (this.firstResearchSeen && !this.researchSeen) {
          Notification.show('Your Discovery is ready.', 'success', 10000, () => this.resetReportSeen())
        }

        return this.load(undefined, undefined, true, undefined).then()
      } else if (researchStatusFailed(researchStartAt)) {
        clearInterval(this.pollingInterval)
        if (this.firstResearchSeen) {
          this.researchStatus = 'failed'
          Notification.show('Research failed. Please try again.', 'error', 10000)
        } else {
          this.startResearch().then()
        }
      }
      if (!this.pollingInterval) {
        this.pollingInterval = setInterval(() => this.polling(), POLLING_INTERVAL)
      }
    }
  }

  @action
  async startResearch () {
    this.thinking = true

    this.researchStatus = 'progress'

    const { success, ...rest } = await GqlClient.mutation({ mutation: RUN_SEARCH, variables: { id: this.id } })

    if (!hasError(success, 'message', '')) {
      const search = rest.data['runResearch']
      this.updateSearchFields(search)
        if (search.researchStatus === 'progress') {
          this.polling().then()
        }
    }

    this.thinking = false

    return { success }
  }

  @computed
  get isNotCompletelyFilled () {
    const { brand, name, competitorUrls, keywords } = this
    const brandStep = !brand
    const competitorsStep = !competitorUrls.length
    const keywordsStep = !keywords.length
    const nameStep = !name && competitorsStep && keywordsStep

    if (brandStep) {
      return 'company'
    } else if (nameStep) {
      return 'name'
    } else if (competitorsStep) {
      return 'competitors'
    } else if (keywordsStep) {
      return 'keywords'
    }
    return null
  }

  @computed
  get totals () {
    const { leadsCount, competitorsCount, irrelevantsCount } = this
    return { leads: leadsCount, irrelevant: irrelevantsCount, competitors: competitorsCount }
  }

  @computed
  get maxValues () {
    const { competitorsCount, keywordsCount } = this
    return { competitorsCount, keywordsCount }
  }

  @computed
  get tldsOptions () {
    const { tlds } = this
    return toJS(tlds).map(t => ({ label: t, value: t }))
  }

  @computed
  get details () {
    const owner = (toJS(this.user) || {})
    return {
      id: this.id,
      name: this.name,
      link: this.channel.domain,
      country: this.country,
      description: this.description,
      ownerName: owner.firstName,
      ownerFullName: owner.name,
      competitors: toJS(this.competitorUrls),
      keywords: toJS(this.keywords),
      expired: this.hidden
    }
  }

  @computed
  get inputsProps () {
    const { keywordsChanged, competitorUrlsChanged, irrelevantsChanged } = this
    return {
      id: this.id,
      name: this.name,
      link: this.channel.link,
      competitors: this.competitorsCount,
      keywords: this.keywordsCount,
      blocklist: this.irrelevantsCount,
      changed: keywordsChanged || competitorUrlsChanged, // || irrelevantsChanged,
      keywordsChanged,
      competitorsChanged: competitorUrlsChanged,
      blocklistChanged: irrelevantsChanged
    }
  }

  @computed
  get planLimits () {
    const { competitorUrls, keywords } = this
    return {
      competitors: false, //competitorUrls.length > 15,
      keywords: false, //keywords.length > 400,
      hasLimits: false // competitorUrls.length > 15 || keywords.length > 400
    }
  }

  @computed
  get expiredDaysLeft () {
    const date = toJS(this.expiredAt)
    const start = moment(date);
    const end = moment(new Date());
    const diff = end.diff(start, 'days')
    return config.EXPIRED_DAYS - diff
  }

  @action
  setInitial () {
    this.id = null
    this.name = ''
    this.description = ''
    this.loading = true
    this.thinking = true
    this.leadsCount = 0

    this.tlds = []
    this.researchStatus = null

    this.leadsMaxValues = {}
    this.keywords = []
    this.competitorUrls = []
    this.irrelevants = []
    this.competitorsCount = 0
    this.keywordsCount = 0
    this.irrelevantsCount = 0

    this.competitorUrlsChanged = false
    this.keywordsChanged = false
    this.irrelevantsChanged = false
    this.firstResearchSeen = false
    this.researchSeen = false

    this.hidden = false
    this.expiredAt = undefined

    clearInterval(this.pollingInterval)

    LeadsListStore.setInitial()
    ReportStore.setInitial()

    ProspectLeadsStore.setInitial()
    // CompetitorLeadsStore.setInitial()
    // IrrelevantLeadsStore.setInitial()
  }
}


export default new SearchStore()
