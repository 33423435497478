import React from 'react'
import styled from 'styled-components'
import { Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core'

export const Tab = styled(MuiTab)`
  font-size: 14px;
  line-height: 16px;
  text-transform: none;
  width: auto;
  min-width: 0;
  font-weight: bold;
  margin: 0 2em 0 0;
  min-height: 0;
  height: auto;
  padding: 0 0 8px 0;
`

export const Tabs = styled(MuiTabs)`
  min-height: 0;
  .MuiTabs-indicator {
    height: 3px;
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
`

export const TabSimple = styled(MuiTab)`
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  width: auto;
  min-width: 0;
  font-weight: bold;
  //margin: 0 2em 0 0;
  min-height: 0;
  height: auto;
  padding: 5px 15px;
  //opacity: 0.5;
`

export const TabCompact = styled(MuiTab)`
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  width: auto;
  min-width: 0;
  font-weight: bold;
  //margin: 0 2em 0 0;
  min-height: 0;
  height: auto;
  padding: 5px 8px;
  //opacity: 0.5;
`

export const TabsSimple = styled(MuiTabs)`
  min-height: 0;
  .MuiTabs-flexContainer {
    justify-content: space-between;
  }
  .MuiTabs-indicator {
    height: 0;
  }
  .MuiTab-textColorInherit {
    opacity: 0.5;
  }
  .MuiTab-textColorInherit.Mui-selected{
    opacity: 1;
  }
`
export const TabsCompact = styled(MuiTabs)`
   background: #E9F3FE;
   border-radius: 5px;
   min-height: 10px;
   padding-left: 7px;
  .MuiTabs-indicator {
    height: 0;
  }
  .MuiTab-textColorInherit {
    opacity: 0.4;
  }
  .MuiTab-textColorInherit.Mui-selected{
    opacity: 1;
  }
  .MuiTab-textColorInherit.Mui-disabled{
    font-weight: normal;
    opacity: 0.6;
  }
`

function TabPanel(props) {
  const {children, value, index} = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`tab-panel-${index}`}
    >
      { children }
    </div>
  );
}

export default TabPanel
