import React, { useEffect, useState } from 'react'
import query from 'query-string'
import { inject, observer } from 'mobx-react'
import { useLocation, useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import EmptyList from '../../components/LeadsPlaceholder/EmptyList'
import { EcosystemHeader } from '../../new-components/Ecosystem/Ecosystem'

import GapAnalysis from './GapAnalysis'
import CompetitorLandscape from './CompetitorLandscape'
import Partnerships from './Partnerships'

import { Get } from '../../utils/request'

function useQuery () {
  const { search } = useLocation()
  return React.useMemo(() => query.parse(search), [search])
}

function placeholderStatus (error, empty) {
  if (error) {
    return 'intelligence-error'
  } else if (empty) {
    return 'intelligence-empty-data'
  }
  return null
}

const IntelligenceDashboard = inject(({ IntelligenceStore }) => ({
  ecosystems: IntelligenceStore.ecosystems,
  ecosystemId: IntelligenceStore.ecosystemId,

  load: (brandId) => IntelligenceStore.load(brandId),
  setSelected: (details) => IntelligenceStore.setSelected(details),
  setEcosystems: (details, id) => IntelligenceStore.setEcosystems(details, id),
  updateIntelligenceQueries: () => IntelligenceStore.updateQueries(),
}))(observer(({ ecosystems, ...props }) => {
  let { kind, brandId } = useParams()
  const { id: ecosystemId } = useQuery()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const setInitialState = () => {
    if (!ecosystemId) {
      props.updateIntelligenceQueries()
    } else if (ecosystemId) {
      props.setSelected(ecosystemId)
    }
  }

  const fetchData = async () => {
    if (!ecosystems.length) {
      const { success, message, data } = await props.load(brandId)

      if (success) {
        props.setEcosystems(data, ecosystemId)
      } else {
        setError(message)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    setInitialState()
    fetchData().then()
  }, [])

  useEffect(() => {
    setInitialState()
  }, [kind])

  const emptyData = !ecosystems || !ecosystems.length
  const placeholder = placeholderStatus(Boolean(error), emptyData)

  return <>
    { !loading && placeholder && <EmptyList show kind={placeholder} /> }
    {
      !placeholder && (
        <Box padding={3} sx={{display: 'flex', flexDirection: 'column', height: 'calc(100vh - 55px)'}}>
          { kind !== 'partnerships' && <EcosystemHeader kind={kind} onError={() => setError(true)}/> }
          {
            kind === 'landscape' && <CompetitorLandscape brandId={brandId} onError={() => setError(true)} />
          }
          {
            kind === 'gap' && <GapAnalysis brandId={brandId} onError={() => setError(true)}/>
          }
          {/*{*/}
          {/*  kind === 'partnerships' && <Partnerships brandId={brandId} />*/}
          {/*}*/}
        </Box>
      )
    }
    </>
}))

export default IntelligenceDashboard
