import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import {Form, Input} from '../Form'
import Button from '../Button'
import Validator from '../../utils/validator'

const FORM_ID = 'email-form'

const ActionsHolder = styled.div`
  margin: 0 auto;
  width: 100%;
`
const GridInput = styled(Grid)`
  margin: 0 auto 24px auto;
  width: 100%;
`

const schema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  }
]

export default class EmailForm extends React.Component {
  constructor(props) {
    super(props)
    const { email } = this.props
    this.state = { email, errors: {}, error: null }
  }

  onChange = name => ({ target: { value } }) => {
    this.setState({ [name]: value })
    this.resetError(name)
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      async () => {
        const { email } = this.state
        const { error } = await this.props.onSubmit({ email })

        if (error) {
          switch (error) {
            case 'emailAlreadyExists':
              this.setState({errors: {email: 'User with given email already exist'}})
              break;
            case 'emailExists':
              this.setState({errors: {email: 'User with given email already exist'}})
              break;
            case 'noEmail':
              this.setState({errors: {email: 'Email not found'}})
              break;
            case 'wrongPassword':
              this.setState({errors: {password: 'Your password is not correct'}})
              break;
            default:
              this.setState({errors: {email: error}})
          }
        }
      })
  }

  render() {
    const { email, errors } = this.state
    const {
      header,
      subHeader,
      thinking,
      error
    } = this.props

    const Actions = () => <ActionsHolder>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        mb={2}
        fullWidth
        disabled={thinking}
      >
        Resend Email
      </Button>
    </ActionsHolder>

    return (
      <Form
        noValidate
        header={header}
        subHeader={subHeader}
        formId={FORM_ID}
        onSubmit={this.onSubmit}
        actions={Actions}
        error={error}
        thinking={thinking}
      >
        <Grid container>
          <GridInput item xs={12}>
            <Input
              type={'email'}
              margin="none"
              required
              fullWidth
              placeholder="e.g. joe.bloggs@example.com"
              id={'email'}
              fieldType='clearable'
              label={'Email'}
              inputLabel={'Email'}
              variant={'outlined'}
              value={email}
              error={errors.email}
              disabled={thinking}
              onChange={this.onChange('email')}
            />
          </GridInput>
        </Grid>
      </Form>
    )
  }
}
