import React, {useContext, useState, useEffect} from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import param from 'can-param'
import { DetailsContext } from '../DetailsContext'

import WidgetPromotesEcosystem from '../../Widget/WidgetPromotesEcosystem'
import {useParams} from "react-router-dom";

const REQUEST = gql`
    query ecosystemBrands (
        $channelId: String!
        $ecosystemId: String!
    ) {
        ecosystemBrandsConnection(
            ecosystemId: $ecosystemId
            take: 3
            skip: 0
            orderBy: "partnerships_in asc"
            byPartner: $channelId
        ) {
            totalCount
            edges {
                node {
                    domain
                    icon
                }
            }
        }
    }
`

const handleBrands = (edges) => {
    return edges.map(({ node: { domain, icon }}) => ({ domain, icon }))
}

export default function DetailsPromotesEcosystem () {
    // @ts-ignore
    const { brandId } = useParams()
    const [brands, setBrands] = useState([])
    const { loading, item, summary } = useContext(DetailsContext)
    const [fetchBrands, { loading: gqlLoading }] = useLazyQuery(REQUEST)

    const link = `/brand/${brandId}/intelligence/landscape?id=${item.ecosystemId}&${param({ _filters: { partner: { value: item.id, label: summary.domain} } })}`

    useEffect(() => {
        if (!loading && item.id) {
            fetchBrands({ variables: { channelId: item.id, ecosystemId: item.ecosystemId }}).then(({ data }) => {
                if (data && data.hasOwnProperty('ecosystemBrandsConnection')) {
                    setBrands(handleBrands(data['ecosystemBrandsConnection'].edges))
                }
            })
        }
    }, [loading])


    return <WidgetPromotesEcosystem  size="2x2" loading={loading || gqlLoading} domains={brands} value={item.brandsCount} valueMax={item.totalBrands} link={link} />
}
