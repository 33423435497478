import React from 'react'
import styled from 'styled-components'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from '@material-ui/core'

import IconLabelSearch from '../IconLabelSearch'

const SelectMain = styled(Select)`
  border: 1px solid ${props => props.theme.palette.brand.grey.darker};
  border-radius: 3px;
  width: 100%;
  padding: 9px 10px;
  font-size: 14px;
  &:after, &:before  {
    display: none;
  }
  .MuiInputBase-input:focus {
    background: none;
  }
`

const FormControlMain = styled(FormControl)`
  margin-left: auto;
  display: flex;
  flex: 0 0 auto;
  width: 250px;
  align-items: center;
  flex-direction: row;
  position: sticky;
  right: 0;
  padding-left: 1em;
  padding-right: 0.8em;
  background: linear-gradient(
          -90deg, white 98%, rgba(255, 255, 255, 0));
  z-index: 1;
  label + .MuiInput-formControl {
    margin-top: 0;
  }
`

export default function (props) {
  const {
    value,
    options
  } = props

  const onChange = ({ target: { value } }) => {
    props.onChange(value)
  }

  return (
    <FormControlMain>
      <SelectMain
        value={value || 'all'}
        onChange={onChange}
        id="productSelect">
        <MenuItem value={'all'}>All Searches</MenuItem>
        {
          options.map(({ label, value }) => <MenuItem key={value} value={value}><IconLabelSearch>{label}</IconLabelSearch></MenuItem>)
        }
      </SelectMain>
    </FormControlMain>
  )
}
