class Log {
  constructor(opts) {
    this.minPos = opts.minPos || 0
    this.maxPos = opts.maxPos || 100

    this.minVal = Math.log(opts.minVal || 1)
    this.maxVal = Math.log(opts.maxVal || 9000)

    this.scale = (this.maxVal - this.minVal) / (this.maxPos - this.minPos)
  }


  value(position) {
    return Math.exp((position - this.minPos) * this.scale + this.minVal)
  }


  position(value) {
    if (value === this.minVal) {
      return this.minPos
    } else if (value === this.maxVal) {
      return this.maxPos
    }

    return Math.round(this.minPos + (Math.log(value) - this.minVal) / this.scale)
  }
}


export default Log
