import React from 'react'
import { Popover, Popper, ClickAwayListener } from '@material-ui/core'
import TagsManagement from './TagsManagement'

const TagManagementPopup = ({ anchorEl, variant, ...other }) => (
  <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={other.onClose}
  >
    <TagsManagement variant={variant} {...other} />
  </Popover>

)

export default TagManagementPopup
