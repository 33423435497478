import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { Container, HeaderContainer, Header } from '../../../new-components/Ecosystem/EcosysctemStyledComponents'
import PartnershipsTable from '../../../new-components/Ecosystem/PartnershipsTable'
import Filter from '../../../new-components/Filter'
import Details from '../../../components-ts/Details'
import RequestContacts from '../../../components/Modals/_RequestContactsModal'

import { useIntelligenceQuery } from '../../../graphql/RequestWrapper'
import { getVariables } from '../../../graphql/handler'

import { sendEvent } from '../../../utils/events'
import { partnershipsFilters } from '../filtersConfig'
import { PARTNERSHIPS, addToActions, requestContact } from '../requests'

const updateSortModel = (oldFilters, newFilters) => {
  if (oldFilters.hasOwnProperty('promotedBy') && !newFilters.hasOwnProperty('promotedBy')) {
    return [{
      field: 'partnershipsOutCount',
      sort: 'desc'
    }]
  }
  return null
}

const Partnerships = inject(({ PartnershipsStore: store }) => ({
  kind: store.kind,
  filters: store.filters,
  order: store.order,
  orderBy: store.orderBy,
  sortQueries: store.sortQueries,
  setSort: (model) => store.setSort(model),
  setFilters: (filters) => store.setFilters(filters),
  updateQueries: () => store.updateQueries(),
  getQueriesFromSearch: () => store.getQueriesFromSearch(),
  getRequestOptions: () => store.getRequestOptions(),
}))(observer((props) => {
  const detailsRef = useRef(null)
  const [channelDetailsId, setChannelDetailsId] = useState(null)
  const [channel, setChannel] = useState(null)
  const [requestContacts, setRequestContacts] = useState({})

  const { data, total, error, loading, onLoad, ...queryProps } = useIntelligenceQuery(props.brandId)

  const options = () => {
    const variables = getVariables({ ...props.getRequestOptions() })
    return {
      query: PARTNERSHIPS,
      dataField: 'channelsConnection',
      variables
    }
  }

  useEffect(() => {
    const hasFields = props.getQueriesFromSearch()
    if (!hasFields) {
      props.updateQueries()
    }
    onLoad(options()).then()
  }, [])

  const onLoadMore = () => queryProps.onLoadMore(options())

  const onFiltersChanged = (filters) => {
    const newSortModel = updateSortModel(props.filters, filters)
    if (newSortModel) {
      props.setSort(newSortModel)
    }
    props.setFilters(filters)
    return onLoad(options())
  }

  const onSort = model => {
    if (!model || !model.length) return
    props.setSort(model)
    return onLoad(options())
  }

  const onOpenChannelDetails = async (rowDetails) => {
    const { row: { channelId } } = rowDetails
    setChannel(rowDetails.row)
    setChannelDetailsId(channelId)
    sendEvent(props.brandId, 'showDetails')
  }

  const onCloseChannelDetails = () => {
    setChannelDetailsId(null)
  }

  const onQuickActions = async (id, action) => {
    const { domain, channelId } = channel

    let kind = null
    if (action === 'toProspects') {
      kind = 'prospect'
    } else if (action === 'toPartners') {
      kind = 'partner'
    } else if (action === 'toRequestContacts') {
      return onRequestContactOpen()
    }

    if (kind) {
      const { success } = await addToActions[kind](props.brandId, domain)
      queryProps.onUpdateItem(channelId, 'channelId', { kind })
    }

    return 'added'
  }

  const onRequestContactOpen = () => {
    setRequestContacts({
      open: true,
      channelId: channelDetailsId,
      onRequestSingle: () => requestContact(props.brandId, channelDetailsId),
      callback: (success) => {
        if (success && detailsRef) {
          detailsRef.current.setContactRequested(success)
          queryProps.onUpdateItem(channelDetailsId, 'channelId', { contactStatus: 'requested' })
        }
      }
    })
  }

  const onRequestContactsClose = () => setRequestContacts({})

  const isBrandList =  getVariables({ ...props.getRequestOptions() }).hasOwnProperty('promotedBy')
  const sortModel = [{ field: props.orderBy, sort: props.order }]

  return (<>
    <HeaderContainer>
      <Header>
        <Filter
          variant={'intelligence'}
          placeholder={'Search and Filter'}
          filters={props.filters}
          intelligenceKind={'partnerships'}
          intelligenceProps={{ showFilterKind: false }}
          keys={partnershipsFilters}
          withoutRelevant={false}
          onChange={onFiltersChanged}
        />
      </Header>
    </HeaderContainer>
    <br/>
    <Container>
      <PartnershipsTable
        loading={loading}
        data={data}
        total={total}
        isBrandList={isBrandList}
        sortModel={sortModel}
        onPageChange={onLoadMore}
        onCellClick={onOpenChannelDetails}
        onSort={onSort}
      />
    </Container>

    <Details
      ref={detailsRef}
      variant={'partnerships'}
      open={Boolean(channelDetailsId)}
      channelId={channelDetailsId}
      brandId={props.brandId}
      geoFilter={props.geoFilter}
      intelligenceItem={channel}
      onQuickActions={onQuickActions}
      onClose={onCloseChannelDetails}
    />

    <RequestContacts {...requestContacts} onClose={onRequestContactsClose}/>
  </>)
}))

export default Partnerships
