import React from 'react'
import { useDragLayer } from 'react-dnd'
import styled from 'styled-components'
import { DragIcon } from '../../components/Icons/DragIcon'
import pluraliseUnit from '../../utils/pluraliseUnit'

const Root = styled.div`
 width: 200px;
 background: ${props => props.theme.palette.primary.main};
 height: 44px;
 display: flex;
 align-items: center;
 border-radius: 5px;
 padding: 0 10px;
 svg {
 width: auto;
  height: 15px;
  margin: 0 10px 0 0;
 }
`;

const Text = styled.p`
  color: #FFFFFF;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  padding: 0;
`;


const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 10000,
  left: 0,
  top: 0,
  opacity: 0.8,
  width: '100%',
  height: '100%',
}

function snapToGrid(x, y) {
  const snappedX = Math.round(x / 32) * 32
  const snappedY = Math.round(y / 32) * 32
  return [snappedX, snappedY]
}

function getItemStyles(clientOffset) {
  if (!clientOffset) {
    return {
      display: 'none',
    }
  }
  let { x, y } = clientOffset
  // x -= initialOffset.x
  // y -= initialOffset.y
  // ;[x, y] = snapToGrid(x, y)
  // x += initialOffset.x
  // y += initialOffset.y
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform
  }
}

const ItemPreview = (props) => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    clientOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset:  monitor.getClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const { selected } = item || {}

  if ((!isDragging && !props.dev) || itemType === 'folder') {
    return null
  }

  const count = selected ? selected.length : 0

  return (
    <div style={layerStyles}>
      <div style={{ ...getItemStyles(clientOffset), ...(props.dev && { display: 'block' }) }}>
          <Root>
            <DragIcon />
            <Text>Move {count} {pluraliseUnit('item', count)}</Text>
          </Root>
      </div>
    </div>
  )
}

export default ItemPreview
