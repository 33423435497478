import React from 'react'
import { inject, observer } from 'mobx-react'
import Details from './Details'
import Layout from '../../layouts/PageMain'
import AddModeCreditsModal from '../../components/Modals/_AddMoreCreditsModal'
import Helmet from 'react-helmet'

@inject(({ SearchStore, BrandStore }) => ({
  role: BrandStore.role,
  creditsLeft: BrandStore.credits,
  assistedLock: BrandStore.assistedLock,

  loading: SearchStore.loading,
  keywords: SearchStore.keywords,
  competitors: SearchStore.competitorUrls,
  blocklist: SearchStore.irrelevants,
  planLimits: SearchStore.planLimits,

  inputsChanged: SearchStore.keywordsChanged || SearchStore.competitorUrlsChanged,
  researchStatus: SearchStore.researchStatus,

  hidden: SearchStore.hidden,


  onUpdate: (details) => SearchStore.update(details),
  onStartProductResearch: () => BrandStore.initReRun({searchId: SearchStore.id, kind: 'product'})
}))
@observer
class _Settings extends React.Component {
  state = { credits: false }

  onOpenCredits = () => this.setState({ credits: true })
  onCloseCredits = () => this.setState({ credits: false })

  render () {
    const { loading, assistedLock, hidden } = this.props
    const { credits } = this.state
    const locked = assistedLock || hidden

    return <Layout thinking={loading}>
      <Helmet title={'Inputs'} />

      <Details
        variant={'inputs'}
        locked={locked}
        {...this.props}
        onAddCredits={this.onOpenCredits}
      />

      { credits && <AddModeCreditsModal open={credits} onClose={this.onCloseCredits} />}
    </Layout>
  }
}

export default _Settings
