import React from 'react'
import styled from 'styled-components'
import {Tooltip} from '@material-ui/core'
import {rgba, darken} from 'polished'
import {Folder} from 'react-feather'

const IconStatus = styled.div`
  color: currentColor;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  height: 100%;
  & svg {
    width: 1.4em;
    height: 1.4em;
  }
`
const Root = styled.div`
  background: ${props => rgba('rgb(83,60,55)', 0.2)};
  color:  ${props => darken(0.1, 'rgb(83,60,55)')};
  height: 1.85em;
  line-height: 1.85em;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  padding: 0 0.5em 0 0.3em;
  font-size: ${props => props.fontSize ? props.fontSize : '0.83em'};
  & svg {
    position: relative;
    top: 0.22em;
  }
`
export const FolderLabel = ({name, fontSize}) => (
    <Root fontSize={fontSize}>
      <IconStatus><Folder /></IconStatus>&nbsp;{name}
    </Root>
)
