import React from 'react'
import styled from 'styled-components'
import { formatToReadableNumber } from '../../utils/format'

const SelectionRoot = styled.strong`
  display: flex;
  width: 100%;
  min-height: 48px;
  justify-content: center;
  @media (min-width: 1458px) {
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 1458px) {
    flex-direction: column;
    justify-content: center;
    span,
    a {
      align-items: center;
      height: 16px;
      line-height: 16px;
    }
  }
`

export default function SelectAll(props) {
  const { source, selected, total, onSelect } = props
  const selectedAmount = selected ? selected.length : 0

  const onSelectAll = async (e) => {
    e.preventDefault()
    await onSelect()
    // const result = await onLoad(options())
    // props.onSelect(result.map(({ channelId }) => channelId))
  }

  const onRemoveSelection = (e) => {
    e.preventDefault()
    props.onSelect([])
  }

  if (!selectedAmount) return null

  return (
    <SelectionRoot>
      {selectedAmount < total && (
        <>
          <span>
            {selectedAmount} {source === 'report' ? 'leads' : source}{' '}
            {selectedAmount > 1 ? 'are' : 'is'} selected.&nbsp;&nbsp;
          </span>
          <a href={'#'} onClick={onSelectAll}>
            Select all {formatToReadableNumber(total)}{' '}
            {source === 'report' ? 'leads' : source}
          </a>
        </>
      )}
      {selectedAmount === total && (
        <>
          <span>
            All {formatToReadableNumber(total)}{' '}
            {source === 'report' ? 'leads' : source} are selected.&nbsp;&nbsp;
          </span>
          <a href={'#'} onClick={onRemoveSelection}>
            Clear selection
          </a>
        </>
      )}
    </SelectionRoot>
  )
}
