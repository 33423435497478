import { observable, action, computed, toJS } from 'mobx'
import { orderBy } from 'lodash'

import GqlClient from '../gql/gql-client'
import { ACCEPT_CREATOR_REQUEST, GET_CREATOR_REQUESTS, REJECT_CREATOR_REQUEST } from '../gql/creator'

import hasError from './request-message'

import Config from '../config'
import { creatorRequestToListItem } from '../utils/list-items'

const MOCKED_ITEM = {"__typename":"ContactRequestWithBrandChannel","contactRequest":{"__typename":"ContactRequest","id":"tuVbBijie1NtKRW8wcJeB","brand":{"__typename":"Brand","name":"Kaspersky"},"contactId":null,"outreachMessage":"Ээээ братищка","createdAt":"2023-12-06T12:50:13.551Z","updatedAt":"2023-12-08T07:40:47.658Z","status":"requested"},"brandChannel":{"__typename":"Channel","id":"WRk1igaMjbx","title":"Kaspersky Cyber Security Solutions for Home and Business | Kaspersky","domain":"kaspersky.com","icon":"icon.ico"}}

const sort = (items, direction, field) => orderBy(items, [field], [direction])

class CreatorRequestsStore {
  kind = null

  @observable loading = true
  @observable thinking = false
  @observable page = 1
  @observable perPage = Config.PER_PAGE
  @observable total = 0
  @observable orderBy = 'date'
  @observable order = 'desc'
  @observable items = []
  @observable _filters = {}

  constructor (kind) {
    this.kind = kind
  }

  @action
  async load () {
    const variables = {
      ...this.pagination,
      status: this.kind
    }

    const { success, ...rest } = await GqlClient.query({ query: GET_CREATOR_REQUESTS, variables })

    if (!hasError(success, '')) {
      this.items = rest.data['contactRequestsForCreator']
      //MOCKS
      // Array.apply(null, Array(5)).map(() => MOCKED_ITEM) //rest.data['contactRequestsForCreator']
      // console.log(JSON.stringify(rest.data['contactRequestsForCreator']))
    }

    this.loading = false

    return { success: true }
  }

  @action
  async accept (id) {
    const { success } = await GqlClient.mutation({ mutation: ACCEPT_CREATOR_REQUEST, variables: { id } })

    if (!hasError(success, '', 'Request successfully accepted')) {
      this.items = toJS(this.items).filter(({ contactRequest }) => contactRequest.id !== id)
    }

    return { success }
  }

  @action
  async reject (id) {
    const { success } = await GqlClient.mutation({ mutation: REJECT_CREATOR_REQUEST, variables: { id } })

    if (!hasError(success, '', 'Request successfully rejected')) {
      this.items = toJS(this.items).filter(({ contactRequest }) => contactRequest.id !== id)
    }

    return { success }
  }

  @action
  async nextPage () {
    this.page = this.page + 1
    await this.load()
  }

  @action
  async pageChange (page) {
    if (toJS(this.items).length < this.total && !this.loading && !this.thinking) {
      this.page = this.page + 1
      await this.load(null, null, true)
    }
  }

  @action
  async perPageChange (perPage) {
    this.page = 1
    this.perPage = perPage
    await this.load()
  }

  @action
  async sorting (orderBy, order) {
    this.order = order
    this.orderBy = orderBy
  }

  @computed
  get pagination () {
    const page = toJS(this.page) - 1 || 0
    const perPage = toJS(this.perPage)
    const take = perPage
    const skip = page * perPage

    return {
      take,
      skip
    }
  }

  @computed
  get listProps () {
    const { loading, thinking, items, page, perPage, total, kind } = this

    const unsorted = toJS(items).map(creatorRequestToListItem)
    //SORTING MOCKS
    //   .map(
    //   (item, index) => ({ ...item, id: index, url: `${index}-${item.url}`, date: `2023-0${index + 1}-0${index + 1}T12:50:13.551Z`})
    // )
    const sorted = sort(unsorted, this.order, this.orderBy)

    return {
      kind,
      thinking,
      loading,
      items: sorted,
      total,
      page,
      perPage
    }
  }

  @computed
  get sortProps () {
    const { orderBy, order } = this
    return { orderBy, order }
  }
}

export const CreatorInboxStore = new CreatorRequestsStore('inbox')
export const CreatorAcceptedStore = new CreatorRequestsStore('accepted')
export const CreatorRejectedStore = new CreatorRequestsStore('rejected')
