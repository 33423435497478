import React from 'react'
import styled from 'styled-components'
import CategoryIcon from '@material-ui/icons/Category'
import Image from 'material-ui-image'
import cleanUrl from '../../utils/cleanUrl'

const imageKitUrl = process.env.IMAGEKIT_URL
const retina = window.devicePixelRatio > 1 // TODO: Optimize this by moving to the app level
const imageWidth = 460

const Thumbnail = styled.div`
  width: 100%;
  max-width: ${imageWidth}px;
  height: auto;
  margin: 0 auto;
`
const Loader = styled(CategoryIcon)`
  width: 48px;
  height: 48px; 
  color: ${props => props.theme.palette.grey[300]}
`

function thumbnail(URL, retina) {
  return `${imageKitUrl}/${cleanUrl(URL, true)}?tr=w-${retina ? imageWidth * 2 : imageWidth},ar-16-9`
}

const Screenshot = ({item}) => {
  return (
    <Thumbnail>
      <a href={'http://'+item.url} target="_blank">
        <Image src={thumbnail(item.screenshot, retina)} aspectRatio={(16 / 9)} loading={<Loader/>}/>
      </a>
    </Thumbnail>
  )
}


export default Screenshot
