import React, {useState, useEffect} from 'react'
import { gql, useLazyQuery } from '@apollo/client'

import {
    fetchChannelDetails, fetchPromotes,
} from './requests'
import {handleCreatorFetchResult, handleIntelligenceData, handlePromotes} from "./handlers";


let store = null

export const CreatorRequestContext = React.createContext<any>({ loading: true })

const INTELLIGENCE_QUERY = gql`
  query Ecosystems ($channelId: String!) {
    brandChannelEcosystems(channelId: $channelId) {
      id
      name
      ecosystemId
      published
    }
    partnerChannelEcosystems(channelId: $channelId) {
      id
      name
      ecosystemId
      published
    }
    adexDistributionForChannelAsBrand(channelId: $channelId) {
        id
        name
        count
        percent
    }
    adexDistributionForChannelAsCreator(channelId: $channelId) {
        id
        name
        count
        percent
    }
  }
`

const CreatorRequestContextProvider = (props: any) => {
    const { channelId, kind } = props
    const [fetchIntelligenceData] = useLazyQuery(INTELLIGENCE_QUERY)
    const [loading, setLoading] = useState(true)
    const [summary, setSummary] = useState({})
    const [metrics, setMetrics] = useState({})
    const [promotes, setPromotes] = useState([])
    const [countries, setCountries] = useState([])
    const [networks, setNetworks] = useState([])
    const [ecosystems, setEcosystems] = useState([])

    const setDefaultValues = () => {
        setSummary({})
        setMetrics({})
        setCountries([])
        setNetworks([])
        setEcosystems([])
    }

    const setCoreApiData = (results): undefined => {
        if (!results) return
        setSummary(results.summary)
        setMetrics(results.metrics)
        setCountries(results.countries)
    }

    const setIntelligenceApiData = (results): undefined => {
        if (!results) return
        setNetworks(results.networks)
        setEcosystems(results.ecosystems)
    }

    const fetchData = async () => {
        const promises = [
            fetchIntelligenceData({ variables: { channelId } }),
            fetchChannelDetails(channelId)
        ]

        return await Promise.allSettled(promises)
    }

    useEffect(() => {
        if (channelId) {
            setLoading(true)

            setDefaultValues()

            fetchData().then((results) => {
                const [intelligence, channel] = results
                const coreApiResults = handleCreatorFetchResult([channel])
                const intelligenceApiResult = handleIntelligenceData(intelligence, coreApiResults.summary.type)

                setCoreApiData(coreApiResults)
                setIntelligenceApiData(intelligenceApiResult)

                if (coreApiResults.summary.type) {
                    fetchPromotes(channelId, coreApiResults.summary.type).then(result => {
                        if (!Boolean(result.message)) {
                            const handledPromotes = handlePromotes(result.channels, coreApiResults.summary.type)
                            setPromotes(handledPromotes)
                        }
                    })
                }

                setLoading(false)
            })
        }
    }, [channelId])

    return (
        <CreatorRequestContext.Provider
            value={{
                variant: 'creator',
                loading,
                summary,
                metrics,
                countries,
                networks,
                promotes,
                ecosystems,
                item: { id: channelId }
            }}
        >
            {
                props.children
            }
        </CreatorRequestContext.Provider>
    )

}



export default CreatorRequestContextProvider
