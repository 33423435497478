import React from 'react'
import styled from 'styled-components'
import config from '../../config'
import getReadableColor from '../../utils/getReadableColor'


const Root = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 0.5em;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  text-transform: capitalize;
  background: ${props => config.typeColors[props.type]};
  color: ${props => getReadableColor(config.typeColors[props.type])};
`

interface TypeLabelProps {
  type?: 'creator' | 'brand',
  className?: string
}

function TypeLabel ({type, className }: TypeLabelProps) {
    return type ? (
        <Root type={type} className={className}>{type}</Root>
    ): null
}

export default TypeLabel
