import React from 'react'
import { withRouter } from 'react-router-dom'
import deparam from 'can-deparam'
import { inject, observer } from 'mobx-react'
import SigninForm from '../../components/Forms/Signin'

@inject(({ UserStore }) => ({
  thinking: UserStore.thinking,
  user: UserStore.user,
  onSubmit: details => UserStore.signin(details),
  onLoginByToken: token => UserStore.signinByToken(token),
}))
@observer
@withRouter
export default class SignIn extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    const { location: { search } } = this.props
    const { token } = deparam(search)

    if (token) {
      this.props.onLoginByToken(token)
    }
  }

  onSubmit = async (details) => {
    const { error } = await this.props.onSubmit(details)
    return { error }
  }

  render () {
    const { thinking } = this.props

    return (
      <SigninForm
        thinking={thinking}
        onSubmit={this.onSubmit}
        header={'Sign In'}
        // subHeader={`We're not ready to let you under the hood just yet. Enter your info and be first to know when we launch.`}
      />
    )
  }
}
