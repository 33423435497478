import { gql } from '@apollo/client'
import { BATCH_RESULT_FIELDS, PROSPECT_FIELDS } from './fragments'

export const GET_PROSPECTS = gql`
  query GetProspects(
    $brandId: String!
    $folderId: String
    $searchId: String
    $kind: String!
    $orderBy: String
    $take: Int
    $skip: Int
    $requestFolder: Boolean!
    $search: String
    $lang: String
    $countries: [String!]
    $tagIds: [String!]
    $type1Classification: String
    $avgBreezyScoreEnd: Int
    $avgBreezyScoreStart: Int
    $totalCompetitorsStart: Int
    $totalCompetitorsEnd: Int
    $totalKeywordsStart: Int
    $totalKeywordsEnd: Int
    $mozDomainAuthorityEnd: Int
    $mozDomainAuthorityStart: Int
    $swGlobalRankEnd: Int
    $swGlobalRankStart: Int
    $swVisitsEnd: Float
    $swVisitsStart: Float
    $isCreator: Boolean
    $manualClassifications: [ClassificationByLevelInput!]
  ) {
    prospectsConnection(
      brandId: $brandId
      folderId: $folderId
      searchId: $searchId
      orderBy: $orderBy
      kind: $kind
      take: $take
      skip: $skip
      search: $search
      lang: $lang
      countries: $countries
      tagIds: $tagIds
      type1Classification: $type1Classification
      avgBreezyScoreEnd: $avgBreezyScoreEnd
      avgBreezyScoreStart: $avgBreezyScoreStart
      totalCompetitorsStart: $totalCompetitorsStart
      totalCompetitorsEnd: $totalCompetitorsEnd
      totalKeywordsStart: $totalKeywordsStart
      totalKeywordsEnd: $totalKeywordsEnd
      mozDomainAuthorityEnd: $mozDomainAuthorityEnd
      mozDomainAuthorityStart: $mozDomainAuthorityStart
      swGlobalRankEnd: $swGlobalRankEnd
      swGlobalRankStart: $swGlobalRankStart
      swVisitsEnd: $swVisitsEnd
      swVisitsStart: $swVisitsStart
      isCreator: $isCreator
      manualClassifications: $manualClassifications
    ) {
      edges {
        node {
          ...PROSPECT_FIELDS
        }
      }
      totalCount
    }
  }
  ${PROSPECT_FIELDS}
`

export const GET_PROSPECT_IDS = gql`
  query GetProspects(
    $brandId: String!
    $folderId: String
    $searchId: String
    $kind: String!
    $orderBy: String
    $take: Int
    $skip: Int
    $search: String
    $lang: String
    $countries: [String!]
    $tagIds: [String!]
    $type1Classification: String
    $avgBreezyScoreEnd: Int
    $avgBreezyScoreStart: Int
    $totalCompetitorsStart: Int
    $totalCompetitorsEnd: Int
    $totalKeywordsStart: Int
    $totalKeywordsEnd: Int
    $mozDomainAuthorityEnd: Int
    $mozDomainAuthorityStart: Int
    $swGlobalRankEnd: Int
    $swGlobalRankStart: Int
    $swVisitsEnd: Float
    $swVisitsStart: Float
    $isCreator: Boolean
  ) {
    prospectsConnection(
      brandId: $brandId
      folderId: $folderId
      searchId: $searchId
      orderBy: $orderBy
      kind: $kind
      take: $take
      skip: $skip
      search: $search
      lang: $lang
      countries: $countries
      tagIds: $tagIds
      type1Classification: $type1Classification
      avgBreezyScoreEnd: $avgBreezyScoreEnd
      avgBreezyScoreStart: $avgBreezyScoreStart
      totalCompetitorsStart: $totalCompetitorsStart
      totalCompetitorsEnd: $totalCompetitorsEnd
      totalKeywordsStart: $totalKeywordsStart
      totalKeywordsEnd: $totalKeywordsEnd
      mozDomainAuthorityEnd: $mozDomainAuthorityEnd
      mozDomainAuthorityStart: $mozDomainAuthorityStart
      swGlobalRankEnd: $swGlobalRankEnd
      swGlobalRankStart: $swGlobalRankStart
      swVisitsEnd: $swVisitsEnd
      swVisitsStart: $swVisitsStart
      isCreator: $isCreator
    ) {
      edges {
        node {
          id
          channel {
            id
          }
        }
      }
    }
  }
`

export const IMPORT_PARTNERS = gql`
  mutation ImportPartners(
    $requestFolder: Boolean!
    $ImportPartnersInput: ImportPartnersInput!
  ) {
    importPartnersFromList(input: $ImportPartnersInput) {
      ...PROSPECT_FIELDS
    }
  }
  ${PROSPECT_FIELDS}
`

export const CHANGE_PROSPECTS_KIND = gql`
  mutation ChangeProspectsKind(
    $ChangeProspectsKindInput: ChangeProspectsKindInput!
  ) {
    changeProspectsKind(input: $ChangeProspectsKindInput) {
      ...BATCH_RESULT_FIELDS
    }
  }
  ${BATCH_RESULT_FIELDS}
`

export const REMOVE_PROSPECTS = gql`
  mutation DeleteProspects($DeleteProspectsInput: DeleteProspectsInput!) {
    deleteProspects(input: $DeleteProspectsInput) {
      ...BATCH_RESULT_FIELDS
    }
  }
  ${BATCH_RESULT_FIELDS}
`
