export const CountriesFlag = [
  {
    labelEmoji: '🇫🇷',
    label: 'Global',
    value: 'GLOBAL',
  },
  {
    labelEmoji: '🇫🇷',
    label: 'France',
    value: 'FR',
  },
  {
    labelEmoji: '🇪🇸',
    label: 'Spain',
    value: 'ES',
  },
  {
    labelEmoji: '🇺🇸',
    label: 'United States',
    value: 'US',
  },
  {
    labelEmoji: '🇨🇳',
    label: 'China',
    value: 'CN',
  },
  {
    labelEmoji: '🇮🇹',
    label: 'Italy',
    value: 'IT',
  },
  {
    labelEmoji: '🇲🇽',
    label: 'Mexico',
    value: 'MX',
  },
  {
    labelEmoji: '🇬🇧',
    label: 'United Kingdom',
    value: 'GB',
  },
  {
    labelEmoji: '🇹🇷',
    label: 'Turkey',
    value: 'TR',
  },
  {
    labelEmoji: '🇩🇪',
    label: 'Germany',
    value: 'DE',
  },
  {
    labelEmoji: '🇹🇭',
    label: 'Thailand',
    value: 'TH',
  },
  {
    labelEmoji: '🇦🇹',
    label: 'Austria',
    value: 'AT',
  },
  {
    labelEmoji: '🇯🇵',
    label: 'Japan',
    value: 'JP',
  },
  {
    labelEmoji: '🇭🇰',
    label: 'Hong Kong',
    value: 'HK',
  },
  {
    labelEmoji: '🇬🇷',
    label: 'Greece',
    value: 'GR',
  },
  {
    labelEmoji: '🇲🇾',
    label: 'Malaysia',
    value: 'MY',
  },
  {
    labelEmoji: '🇮🇷',
    label: 'Iran',
    value: 'IR',
  },
  {
    labelEmoji: '🇷🇺',
    label: 'Russia',
    value: 'RU',
  },
  {
    labelEmoji: '🇵🇹',
    label: 'Portugal',
    value: 'PT',
  },
  {
    labelEmoji: '🇨🇦',
    label: 'Canada',
    value: 'CA',
  },
  {
    labelEmoji: '🇵🇱',
    label: 'Poland',
    value: 'PL',
  },
  {
    labelEmoji: '🇲🇴',
    label: 'Macau',
    value: 'MO',
  },
  {
    labelEmoji: '🇸🇪',
    label: 'Sweden',
    value: 'SE',
  },
  {
    labelEmoji: '🇸🇦',
    label: 'Saudi Arabia',
    value: 'SA',
  },
  {
    labelEmoji: '🇳🇱',
    label: 'Netherlands',
    value: 'NL',
  },
  {
    labelEmoji: '🇦🇪',
    label: 'United Arab Emirates',
    value: 'AE',
  },
  {
    labelEmoji: '🇭🇷',
    label: 'Croatia',
    value: 'HR',
  },
  {
    labelEmoji: '🇮🇳',
    label: 'India',
    value: 'IN',
  },
  {
    labelEmoji: '🇭🇺',
    label: 'Hungary',
    value: 'HU',
  },
  {
    labelEmoji: '🇺🇦',
    label: 'Ukraine',
    value: 'UA',
  },
  {
    labelEmoji: '🇸🇬',
    label: 'Singapore',
    value: 'SG',
  },
  {
    labelEmoji: '🇰🇷',
    label: 'South Korea',
    value: 'KR',
  },
  {
    labelEmoji: '🇮🇩',
    label: 'Indonesia',
    value: 'ID',
  },
  {
    labelEmoji: '🇻🇳',
    label: 'Vietnam',
    value: 'VN',
  },
  {
    labelEmoji: '🇨🇿',
    label: 'Czech Republic',
    value: 'CZ',
  },
  {
    labelEmoji: '🇲🇦',
    label: 'Morocco',
    value: 'MA',
  },
  {
    labelEmoji: '🇩🇰',
    label: 'Denmark',
    value: 'DK',
  },
  {
    labelEmoji: '🇹🇼',
    label: 'Taiwan',
    value: 'TW',
  },
  {
    labelEmoji: '🇨🇭',
    label: 'Switzerland',
    value: 'CH',
  },
  {
    labelEmoji: '🇮🇪',
    label: 'Ireland',
    value: 'IE',
  },
  {
    labelEmoji: '🇿🇦',
    label: 'South Africa',
    value: 'ZA',
  },
  {
    labelEmoji: '🇧🇬',
    label: 'Bulgaria',
    value: 'BG',
  },
  {
    labelEmoji: '🇦🇺',
    label: 'Australia',
    value: 'AU',
  },
  {
    labelEmoji: '🇸🇾',
    label: 'Syria',
    value: 'SY',
  },
  {
    labelEmoji: '🇪🇬',
    label: 'Egypt',
    value: 'EG',
  },
  {
    labelEmoji: '🇧🇪',
    label: 'Belgium',
    value: 'BE',
  },
  {
    labelEmoji: '🇹🇳',
    label: 'Tunisia',
    value: 'TN',
  },
  {
    labelEmoji: '🇦🇷',
    label: 'Argentina',
    value: 'AR',
  },
  {
    labelEmoji: '🇵🇭',
    label: 'Philippines',
    value: 'PH',
  },
  {
    labelEmoji: '🇧🇷',
    label: 'Brazil',
    value: 'BR',
  },
  {
    labelEmoji: '🇨🇱',
    label: 'Chile',
    value: 'CL',
  },
  {
    labelEmoji: '🇩🇴',
    label: 'Dominican Republic',
    value: 'DO',
  },
  {
    labelEmoji: '🇳🇴',
    label: 'Norway',
    value: 'NO',
  },
  {
    labelEmoji: '🇰🇭',
    label: 'Cambodia',
    value: 'KH',
  },
  {
    labelEmoji: '🇸🇰',
    label: 'Slovakia',
    value: 'SK',
  },
  {
    labelEmoji: '🇦🇱',
    label: 'Albania',
    value: 'AL',
  },
  {
    labelEmoji: '🇧🇭',
    label: 'Bahrain',
    value: 'BH',
  },
  {
    labelEmoji: '🇵🇪',
    label: 'Peru',
    value: 'PE',
  },
  {
    labelEmoji: '🇨🇴',
    label: 'Colombia',
    value: 'CO',
  },
  {
    labelEmoji: '🇨🇺',
    label: 'Cuba',
    value: 'CU',
  },
  {
    labelEmoji: '🇯🇴',
    label: 'Jordan',
    value: 'JO',
  },
  {
    labelEmoji: '🇵🇷',
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    labelEmoji: '🇺🇾',
    label: 'Uruguay',
    value: 'UY',
  },
  {
    labelEmoji: '🇨🇾',
    label: 'Cyprus',
    value: 'CY',
  },
  {
    labelEmoji: '🇮🇱',
    label: 'Israel',
    value: 'IL',
  },
  {
    labelEmoji: '🇸🇮',
    label: 'Slovenia',
    value: 'SI',
  },
  {
    labelEmoji: '🇳🇿',
    label: 'New Zealand',
    value: 'NZ',
  },
  {
    labelEmoji: '🇲🇲',
    label: 'Myanmar',
    value: 'MM',
  },
  {
    labelEmoji: '🇱🇦',
    label: 'Laos',
    value: 'LA',
  },
  {
    labelEmoji: '🇪🇪',
    label: 'Estonia',
    value: 'EE',
  },
  {
    labelEmoji: '🇦🇩',
    label: 'Andorra',
    value: 'AD',
  },
  {
    labelEmoji: '🇰🇿',
    label: 'Kazakhstan',
    value: 'KZ',
  },
  {
    labelEmoji: '🇨🇷',
    label: 'Costa Rica',
    value: 'CR',
  },
  {
    labelEmoji: '🇰🇬',
    label: 'Kyrgyz Republic',
    value: 'KG',
  },
  {
    labelEmoji: '🇫🇮',
    label: 'Finland',
    value: 'FI',
  },
  {
    labelEmoji: '🇷🇴',
    label: 'Romania',
    value: 'RO',
  },
  {
    labelEmoji: '🇬🇪',
    label: 'Georgia',
    value: 'GE',
  },
  {
    labelEmoji: '🇱🇹',
    label: 'Lithuania',
    value: 'LT',
  },
  {
    labelEmoji: '🇦🇿',
    label: 'Azerbaijan',
    value: 'AZ',
  },
  {
    labelEmoji: '🇩🇿',
    label: 'Algeria',
    value: 'DZ',
  },
  {
    labelEmoji: '🇿🇼',
    label: 'Zimbabwe',
    value: 'ZW',
  },
  {
    labelEmoji: '🇯🇲',
    label: 'Jamaica',
    value: 'JM',
  },
  {
    labelEmoji: '🇴🇲',
    label: 'Oman',
    value: 'OM',
  },
  {
    labelEmoji: '🇲🇹',
    label: 'Malta',
    value: 'MT',
  },
  {
    labelEmoji: '🇶🇦',
    label: 'Qatar',
    value: 'QA',
  },
  {
    labelEmoji: '🇱🇰',
    label: 'Sri Lanka',
    value: 'LK',
  },
  {
    labelEmoji: '🇧🇾',
    label: 'Belarus',
    value: 'BY',
  },
  {
    labelEmoji: '🇧🇼',
    label: 'Botswana',
    value: 'BW',
  },
  {
    labelEmoji: '🇱🇻',
    label: 'Latvia',
    value: 'LV',
  },
  {
    labelEmoji: '🇳🇬',
    label: 'Nigeria',
    value: 'NG',
  },
  {
    labelEmoji: '🇲🇪',
    label: 'Montenegro',
    value: 'ME',
  },
  {
    labelEmoji: '🇱🇧',
    label: 'Lebanon',
    value: 'LB',
  },
  {
    labelEmoji: '🇵🇦',
    label: 'Panama',
    value: 'PA',
  },
  {
    labelEmoji: '🇨🇮',
    label: 'Ivory Coast',
    value: 'CI',
  },
  {
    labelEmoji: '🇮🇸',
    label: 'Iceland',
    value: 'IS',
  },
  {
    labelEmoji: '🇳🇮',
    label: 'Nicaragua',
    value: 'NI',
  },
  {
    labelEmoji: '🇬🇹',
    label: 'Guatemala',
    value: 'GT',
  },
  {
    labelEmoji: '🇲🇿',
    label: 'Mozambique',
    value: 'MZ',
  },
  {
    labelEmoji: '🇪🇨',
    label: 'Ecuador',
    value: 'EC',
  },
  {
    labelEmoji: '🇸🇻',
    label: 'El Salvador',
    value: 'SV',
  },
  {
    labelEmoji: '🇬🇺',
    label: 'Guam',
    value: 'GU',
  },
  {
    labelEmoji: '🇵🇾',
    label: 'Paraguay',
    value: 'PY',
  },
  {
    labelEmoji: '🇮🇶',
    label: 'Iraq',
    value: 'IQ',
  },
  {
    labelEmoji: '🇷🇸',
    label: 'Serbia',
    value: 'RS',
  },
  {
    labelEmoji: '🇧🇸',
    label: 'Bahamas',
    value: 'BS',
  },
  {
    labelEmoji: '🇳🇦',
    label: 'Namibia',
    value: 'NA',
  },
  {
    labelEmoji: '🇲🇻',
    label: 'Maldives',
    value: 'MV',
  },
  {
    labelEmoji: '🇰🇪',
    label: 'Kenya',
    value: 'KE',
  },
  {
    labelEmoji: '🇲🇺',
    label: 'Mauritius',
    value: 'MU',
  },
  {
    labelEmoji: '🇺🇬',
    label: 'Uganda',
    value: 'UG',
  },
  {
    labelEmoji: '🇦🇲',
    label: 'Armenia',
    value: 'AM',
  },
  {
    labelEmoji: '🇹🇿',
    label: 'Tanzania',
    value: 'TZ',
  },
  {
    labelEmoji: '🇦🇼',
    label: 'Aruba',
    value: 'AW',
  },
  {
    labelEmoji: '🇱🇺',
    label: 'Luxembourg',
    value: 'LU',
  },
  {
    labelEmoji: '🇾🇪',
    label: 'Yemen',
    value: 'YE',
  },
  {
    labelEmoji: '🇺🇿',
    label: 'Uzbekistan',
    value: 'UZ',
  },
  {
    labelEmoji: '🇧🇴',
    label: 'Bolivia',
    value: 'BO',
  },
  {
    labelEmoji: '🇿🇲',
    label: 'Zambia',
    value: 'ZM',
  },
  {
    labelEmoji: '🇳🇵',
    label: 'Nepal',
    value: 'NP',
  },
  {
    labelEmoji: '🇭🇳',
    label: 'Honduras',
    value: 'HN',
  },
  {
    labelEmoji: '🇷🇼',
    label: 'Rwanda',
    value: 'RW',
  },
  {
    labelEmoji: '🇬🇭',
    label: 'Ghana',
    value: 'GH',
  },
  {
    labelEmoji: '🇧🇦',
    label: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    labelEmoji: '🇸🇿',
    label: 'Eswatini',
    value: 'SZ',
  },
  {
    labelEmoji: '🇵🇰',
    label: 'Pakistan',
    value: 'PK',
  },
  {
    labelEmoji: '🇸🇳',
    label: 'Senegal',
    value: 'SN',
  },
  {
    labelEmoji: '🇪🇹',
    label: 'Ethiopia',
    value: 'ET',
  },
  {
    labelEmoji: '🇲🇼',
    label: 'Malawi',
    value: 'MW',
  },
  {
    labelEmoji: '🇫🇯',
    label: 'Fiji',
    value: 'FJ',
  },
  {
    labelEmoji: '🇸🇩',
    label: 'Sudan',
    value: 'SD',
  },
  {
    labelEmoji: '🇨🇻',
    label: 'Cape Verde',
    value: 'CV',
  },
  {
    labelEmoji: '🇧🇧',
    label: 'Barbados',
    value: 'BB',
  },
  {
    labelEmoji: '🇲🇵',
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    labelEmoji: '🇬🇵',
    label: 'Guadeloupe',
    value: 'GP',
  },
  {
    labelEmoji: '🇲🇰',
    label: 'North Macedonia',
    value: 'MK',
  },
  {
    labelEmoji: '🇻🇪',
    label: 'Venezuela',
    value: 'VE',
  },
  {
    labelEmoji: '🇨🇲',
    label: 'Cameroon',
    value: 'CM',
  },
  {
    labelEmoji: '🇲🇶',
    label: 'Martinique',
    value: 'MQ',
  },
  {
    labelEmoji: '🇷🇪',
    label: 'Réunion',
    value: 'RE',
  },
  {
    labelEmoji: '🇵🇸',
    label: 'Palestine',
    value: 'PS',
  },
  {
    labelEmoji: '🇹🇬',
    label: 'Togo',
    value: 'TG',
  },
  {
    labelEmoji: '🇲🇳',
    label: 'Mongolia',
    value: 'MN',
  },
  {
    labelEmoji: '🇧🇿',
    label: 'Belize',
    value: 'BZ',
  },
  {
    labelEmoji: '🇰🇾',
    label: 'Cayman Islands',
    value: 'KY',
  },
  {
    labelEmoji: '🇹🇨',
    label: 'Turks and Caicos Islands',
    value: 'TC',
  },
  {
    labelEmoji: '🇱🇸',
    label: 'Lesotho',
    value: 'LS',
  },
  {
    labelEmoji: '🇸🇽',
    label: 'Sint Maarten',
    value: 'SX',
  },
  {
    labelEmoji: '🇨🇼',
    label: 'Curaçao',
    value: 'CW',
  },
  {
    labelEmoji: '🇦🇴',
    label: 'Angola',
    value: 'AO',
  },
  {
    labelEmoji: '🇹🇹',
    label: 'Trinidad and Tobago',
    value: 'TT',
  },
  {
    labelEmoji: '🇱🇨',
    label: 'Saint Lucia',
    value: 'LC',
  },
  {
    labelEmoji: '🇨🇬',
    label: 'Republic of the Congo',
    value: 'CG',
  },
  {
    labelEmoji: '🇸🇨',
    label: 'Seychelles',
    value: 'SC',
  },
  {
    labelEmoji: '🇲🇨',
    label: 'Monaco',
    value: 'MC',
  },
  {
    labelEmoji: '🇻🇬',
    label: 'British Virgin Islands',
    value: 'VG',
  },
  {
    labelEmoji: '🇧🇩',
    label: 'Bangladesh',
    value: 'BD',
  },
  {
    labelEmoji: '🇸🇷',
    label: 'Suriname',
    value: 'SR',
  },
  {
    labelEmoji: '🇧🇲',
    label: 'Bermuda',
    value: 'BM',
  },
  {
    labelEmoji: '🇧🇯',
    label: 'Benin',
    value: 'BJ',
  },
  {
    labelEmoji: '🇧🇳',
    label: 'Brunei',
    value: 'BN',
  },
  {
    labelEmoji: '🇧🇹',
    label: 'Bhutan',
    value: 'BT',
  },
  {
    labelEmoji: '🇭🇹',
    label: 'Haiti',
    value: 'HT',
  },
  {
    labelEmoji: '🇲🇬',
    label: 'Madagascar',
    value: 'MG',
  },
  {
    labelEmoji: '🇦🇬',
    label: 'Antigua and Barbuda',
    value: 'AG',
  },
  {
    labelEmoji: '🇬🇾',
    label: 'Guyana',
    value: 'GY',
  },
  {
    labelEmoji: '🇨🇩',
    label: 'DR Congo',
    value: 'CD',
  },
  {
    labelEmoji: '🇰🇼',
    label: 'Kuwait',
    value: 'KW',
  },
  {
    labelEmoji: '🇵🇫',
    label: 'French Polynesia',
    value: 'PF',
  },
  {
    labelEmoji: '🇲🇱',
    label: 'Mali',
    value: 'ML',
  },
  {
    labelEmoji: '🇬🇫',
    label: 'French Guiana',
    value: 'GF',
  },
  {
    labelEmoji: '🇧🇮',
    label: 'Burundi',
    value: 'BI',
  },
  {
    labelEmoji: '🇵🇬',
    label: 'Papua New Guinea',
    value: 'PG',
  },
  {
    labelEmoji: '🇨🇰',
    label: 'Cook Islands',
    value: 'CK',
  },
  {
    labelEmoji: '🇬🇲',
    label: 'Gambia',
    value: 'GM',
  },
  {
    labelEmoji: '🇹🇯',
    label: 'Tajikistan',
    value: 'TJ',
  },
  {
    labelEmoji: '🇳🇪',
    label: 'Niger',
    value: 'NE',
  },
  {
    labelEmoji: '🇼🇸',
    label: 'Samoa',
    value: 'WS',
  },
  {
    labelEmoji: '🇲🇩',
    label: 'Moldova',
    value: 'MD',
  },
  {
    labelEmoji: '🇧🇫',
    label: 'Burkina Faso',
    value: 'BF',
  },
  {
    labelEmoji: '🇪🇷',
    label: 'Eritrea',
    value: 'ER',
  },
  {
    labelEmoji: '🇬🇩',
    label: 'Grenada',
    value: 'GD',
  },
  {
    labelEmoji: '🇵🇼',
    label: 'Palau',
    value: 'PW',
  },
  {
    labelEmoji: '🇳🇨',
    label: 'New Caledonia',
    value: 'NC',
  },
  {
    labelEmoji: '🇰🇳',
    label: 'Saint Kitts and Nevis',
    value: 'KN',
  },
  {
    labelEmoji: '🇻🇺',
    label: 'Vanuatu',
    value: 'VU',
  },
  {
    labelEmoji: '🇩🇲',
    label: 'Dominica',
    value: 'DM',
  },
  {
    labelEmoji: '🇸🇲',
    label: 'San Marino',
    value: 'SM',
  },
  {
    labelEmoji: '🇻🇨',
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
  },
  {
    labelEmoji: '🇹🇱',
    label: 'Timor-Leste',
    value: 'TL',
  },
  {
    labelEmoji: '🇹🇩',
    label: 'Chad',
    value: 'TD',
  },
  {
    labelEmoji: '🇱🇮',
    label: 'Liechtenstein',
    value: 'LI',
  },
  {
    labelEmoji: '🇦🇮',
    label: 'Anguilla',
    value: 'AI',
  },
  {
    labelEmoji: '🇹🇴',
    label: 'Tonga',
    value: 'TO',
  },
  {
    labelEmoji: '🇬🇳',
    label: 'Guinea',
    value: 'GN',
  },
  {
    labelEmoji: '🇨🇫',
    label: 'Central African Republic',
    value: 'CF',
  },
  {
    labelEmoji: '🇸🇱',
    label: 'Sierra Leone',
    value: 'SL',
  },
  {
    labelEmoji: '🇩🇯',
    label: 'Djibouti',
    value: 'DJ',
  },
  {
    labelEmoji: '🇸🇹',
    label: 'São Tomé and Príncipe',
    value: 'ST',
  },
  {
    labelEmoji: '🇰🇲',
    label: 'Comoros',
    value: 'KM',
  },
  {
    labelEmoji: '🇸🇧',
    label: 'Solomon Islands',
    value: 'SB',
  },
  {
    labelEmoji: '🇦🇸',
    label: 'American Samoa',
    value: 'AS',
  },
  {
    labelEmoji: '🇳🇺',
    label: 'Niue',
    value: 'NU',
  },
  {
    labelEmoji: '🇲🇸',
    label: 'Montserrat',
    value: 'MS',
  },
  {
    labelEmoji: '🇲🇭',
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    labelEmoji: '🇹🇻',
    label: 'Tuvalu',
    value: 'TV',
  },
  {
    labelEmoji: '🇦🇫',
    label: 'Afghanistan',
    value: 'AF',
  },
  {
    labelEmoji: '🇦🇽',
    label: 'Åland Islands',
    value: 'AX',
  },
  {
    labelEmoji: '🇦🇶',
    label: 'Antarctica',
    value: 'AQ',
  },
  {
    labelEmoji: '🇧🇻',
    label: 'Bouvet Island',
    value: 'BV',
  },
  {
    labelEmoji: '🇮🇴',
    label: 'British Indian Ocean Territory',
    value: 'IO',
  },
  {
    labelEmoji: '-',
    label: 'Caribbean Netherlands',
    value: 'BQ',
  },
  {
    labelEmoji: '🇨🇽',
    label: 'Christmas Island',
    value: 'CX',
  },
  {
    labelEmoji: '🇨🇨',
    label: 'Cocos (Keeling) Islands',
    value: 'CC',
  },
  {
    labelEmoji: '🇬🇶',
    label: 'Equatorial Guinea',
    value: 'GQ',
  },
  {
    labelEmoji: '🇫🇰',
    label: 'Falkland Islands',
    value: 'FK',
  },
  {
    labelEmoji: '🇫🇴',
    label: 'Faroe Islands',
    value: 'FO',
  },
  {
    labelEmoji: '🇹🇫',
    label: 'French Southern and Antarctic Lands',
    value: 'TF',
  },
  {
    labelEmoji: '🇬🇦',
    label: 'Gabon',
    value: 'GA',
  },
  {
    labelEmoji: '🇬🇮',
    label: 'Gibraltar',
    value: 'GI',
  },
  {
    labelEmoji: '🇬🇱',
    label: 'Greenland',
    value: 'GL',
  },
  {
    labelEmoji: '🇬🇬',
    label: 'Guernsey',
    value: 'GG',
  },
  {
    labelEmoji: '🇬🇼',
    label: 'Guinea-Bissau',
    value: 'GW',
  },
  {
    labelEmoji: '🇭🇲',
    label: 'Heard Island and McDonald Islands',
    value: 'HM',
  },
  {
    labelEmoji: '🇮🇲',
    label: 'Isle of Man',
    value: 'IM',
  },
  {
    labelEmoji: '🇯🇪',
    label: 'Jersey',
    value: 'JE',
  },
  {
    labelEmoji: '🇰🇮',
    label: 'Kiribati',
    value: 'KI',
  },
  {
    labelEmoji: '🇽🇰',
    label: 'Kosovo',
    value: 'XK',
  },
  {
    labelEmoji: '🇱🇷',
    label: 'Liberia',
    value: 'LR',
  },
  {
    labelEmoji: '🇱🇾',
    label: 'Libya',
    value: 'LY',
  },
  {
    labelEmoji: '🇲🇷',
    label: 'Mauritania',
    value: 'MR',
  },
  {
    labelEmoji: '🇾🇹',
    label: 'Mayotte',
    value: 'YT',
  },
  {
    labelEmoji: '🇫🇲',
    label: 'Micronesia',
    value: 'FM',
  },
  {
    labelEmoji: '🇳🇷',
    label: 'Nauru',
    value: 'NR',
  },
  {
    labelEmoji: '🇳🇫',
    label: 'Norfolk Island',
    value: 'NF',
  },
  {
    labelEmoji: '🇵🇳',
    label: 'Pitcairn Islands',
    value: 'PN',
  },
  {
    labelEmoji: '🇧🇱',
    label: 'Saint Barthélemy',
    value: 'BL',
  },
  {
    labelEmoji: '🇸🇭',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
  },
  {
    labelEmoji: '🇲🇫',
    label: 'Saint Martin',
    value: 'MF',
  },
  {
    labelEmoji: '🇵🇲',
    label: 'Saint Pierre and Miquelon',
    value: 'PM',
  },
  {
    labelEmoji: '🇸🇴',
    label: 'Somalia',
    value: 'SO',
  },
  {
    labelEmoji: '🇬🇸',
    label: 'South Georgia',
    value: 'GS',
  },
  {
    labelEmoji: '🇸🇸',
    label: 'South Sudan',
    value: 'SS',
  },
  {
    labelEmoji: '🇸🇯',
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
  },
  {
    labelEmoji: '🇹🇰',
    label: 'Tokelau',
    value: 'TK',
  },
  {
    labelEmoji: '🇹🇲',
    label: 'Turkmenistan',
    value: 'TM',
  },
  {
    labelEmoji: '🇻🇮',
    label: 'United States Virgin Islands',
    value: 'VI',
  },
  {
    labelEmoji: '🇺🇲',
    label: 'US Minor Outlying Islands',
    value: 'UM',
  },
  {
    labelEmoji: '🇻🇦',
    label: 'Vatican City',
    value: 'VA',
  },
  {
    labelEmoji: '🇼🇫',
    label: 'Wallis and Futuna',
    value: 'WF',
  },
  {
    labelEmoji: '🇪🇭',
    label: 'Western Sahara',
    value: 'EH',
  },
]
export const countriesOnly = CountriesFlag.filter(
  ({ value }) => value !== 'GLOBAL'
)

export default CountriesFlag
