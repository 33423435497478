import React from 'react'
import { find } from 'lodash'
import styled from 'styled-components'
import CountryFlag from '../CountryFlag'
import countries from '../Leads/CountriesFlag'

const imageKitUrl = process.env.IMAGEKIT_URL

const getCountry = country => {
  const countryObject = find(countries, ['value', country])

  return countryObject ? countryObject : {}
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  font-family: Roboto, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  cursor: ${props => props.editable ? 'pointer' : 'default'}
`
const Title = styled.h2`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`
const Product = styled.div`
  font-size: 12px;
  padding-bottom: 0.4em;
`

class Discovery extends React.Component {
  state = { open: false }

  onOpen = () => {
    if (this.props.editable) {
      this.setState({ open: true })
    }
  }

  onClose = () => this.setState({ open: false })

  render () {
    const { open } = this.state
    const { name, country, description, editable, details: Details } = this.props
    const countryObject = getCountry(country)

    return (
      <>
        <Root editable={editable} onClick={this.onOpen}>
          <Title>{description || 'Untitled search'}</Title>
          <Product>
            {name}&nbsp;
            <CountryFlag
              country={countryObject.value}
              alt={countryObject.label}
              fallbackImageURL={`${imageKitUrl}/flags/${countryObject.value}.svg`}
            />

          </Product>
        </Root>

        { editable && Details && open && <Details open={open} onClose={this.onClose} /> }
      </>
    )
  }

}

export default Discovery
