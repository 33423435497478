import React, {Component, ReactElement} from 'react'
import titleToId from "../../../utils/titleToId"
import styled from 'styled-components'

const Root = styled.div`
    position: relative;
    grid-column: ${props => props.fullWidth ? 'span 2' : '1fr'};
    display: flex;
    flex-direction: column;
    //overflow: hidden;
    //min-width: 40ch;
`
interface HeadingInterface {
    id?: string
    children?: string

}

const HeadingBase = styled.h2<HeadingInterface>`
  width: 100%;
  font-size: 1.3em;
  font-weight: normal;
  color: ${props => props.theme.palette.mutedText};
`
const Body = styled.div`
  flex: 1 1 auto;
`

export interface ProfileSectionProps {
    children?: React.ReactNode
    fullWidth?: boolean
    title?: string
    className?: string

}


function Heading ({ children }: HeadingInterface) {
    const _children = children.split(' ')
        .map(word => word.match(/^[0-9]+/) ? `<strong>${word}</strong>` : word).join(' ')
    return children ? <HeadingBase id={titleToId(children)} dangerouslySetInnerHTML={{__html: _children}} /> : null
}


function ProfileSection ({ children, fullWidth, title, className }: ProfileSectionProps) {
    return (
        <Root fullWidth={fullWidth}>
            <Heading>{title}</Heading>
            <Body className={className}>
                {children}
            </Body>
        </Root>
    )
}

ProfileSection.defaultProps = {
  title: 'Appears in 90 Searches',
  children: 'It\'s a me, ProfileSection!'
}

export default ProfileSection
