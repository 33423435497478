import React from 'react'
import styled from 'styled-components'
import {
  FormHelperText,
  FormControl,
  Input as MuiInput,
  InputLabel
} from '@material-ui/core'
import Autocomplete from '../Autocomplete'

const FormErrorText = styled(FormHelperText)`
  color: #ca392e;
  position: absolute;
  bottom: -12px;
`;

const FormHelpText = styled(FormHelperText)`
  color: rgba(0,0,0, 0.35);
  font-size: 14px;
  position: absolute;
  user-select: none;
  cursor: text;
  top: 41px;
  left: 18px;
  z-index: 1;
`;

const Label = styled.label`
  cursor: text;
  user-select: none;
`

const FormControlStyle = styled(FormControl)`
  position: relative;
`;

export default class MultiSelect extends React.Component {
  render () {
    const {
      id,
      key,
      required,
      options,
      fullWidth,
      margin,
      label,
      inputLabel,
      value,
      disabled,
      helpText,
      onChange,
      error
    } = this.props

    return (
      <FormControlStyle
        margin={margin}
        required={required}
        fullWidth={fullWidth}
      >
        {helpText && <FormHelpText><Label for={id}>{helpText}</Label></FormHelpText>}
        <Autocomplete
          id={id}
          label={label}
          helpText={helpText}
          disabled={disabled}
          options={options}
          value={value}
          onChange={(e, a) => onChange({ target: { value: a } })}
          aria-describedby={`error-${id}`}
        />
        <FormErrorText id={`error-${id}`}>{error}</FormErrorText>
      </FormControlStyle>
    )
  }
}
