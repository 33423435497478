import React from 'react'
import styled from 'styled-components'
import { Button as MuiButton, Grid } from '@material-ui/core'
import { Form, Input } from '../Form'
import Validator from '../../utils/validator'

const Root = styled.div`
  padding: 0 20px;
`

const Button = styled(MuiButton)`
  text-transform: none;
  font-weight: 600;
  width: 200px;
`

const Title = styled.h4`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
`

const Text = styled.p`
  font-size: 14px;
  color: #494949;
  margin: 20px 0;
`

const FORM_ID = 'email-form'
const schema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  }
]

export default class Invite extends React.Component {
  constructor(props) {
    super(props)
    const { email } = this.props
    this.state = { email, errors: {}, error: null, changed: false }
  }

  onChange = name => ({ target: { value } }) => {
    this.setState({ [name]: value })
    this.resetError(name)
    if (this.props.onChange) {
      this.props.onChange({ [name]: value })
    }
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    const { email } = this.state
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      async () => {
        const { success, message } = await this.props.onSendInvitation({ email })
        if (success) {
          this.setState({ email: '', errors: {}, error: null })
        }
      }
    )
  }

  render() {
    const { thinking } = this.props
    const {
      email,
      error,
      errors
    } = this.state

    return (
      <Root>
        <Title>Invite members</Title>
        <Text>Your team members will be able to set up and run Discoveries</Text>
        <Form
          noValidate
          formId={FORM_ID}
          error={error}
          thinking={thinking}
          onSubmit={this.onSubmit}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={10}>
              <Input
                margin="normal"
                required
                fullWidth
                placeholder="e.g. joe.bloggs@example.com"
                id={'email'}
                fieldType='clearable'
                label={'Email'}
                inputLabel={'Email'}
                variant={'outlined'}
                value={email}
                error={errors.email}
                disabled={thinking}
                onChange={this.onChange('email')}
              />
            </Grid>
            <Grid item xs={12} md={2} style={{alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end'}}>
              <Button variant="contained" color="primary" type={'submit'} size="large" style={{marginBottom: '0.7em'}}>Send invite</Button>
            </Grid>
          </Grid>
        </Form>
      </Root>
    )
  }
}
