import React from 'react'
import styled , {css} from 'styled-components'
import {rgba} from 'polished'
import ButtonBase from '@mui/material/ButtonBase'
import getReadableColor from '../../utils/getReadableColor'
import {StyledEngineProvider} from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

const Root = styled(ButtonBase)`
    display: flex;
    flex: 1 1 auto;
    gap: 0.3em;
    justify-content: center;
    align-items: center;
    padding: 0.5em 1em;
    white-space: nowrap;
    border-radius: ${props => props.theme.shape.borderRadius * 2}px;
    color: ${props => props.disabled ? props.theme.palette.brand.shadow.dark : props.theme.palette.primary.main};
    box-shadow: inset 0 0 0 1px ${props => props.filled ? props.theme.palette.primary.main : props.theme.palette.brand.shadow.main};
  background: transparent;
    &:hover {
     background: ${props => rgba(props.theme.palette.brand.shadow.main, 0.5)};
    }
    ${props => props.filled && css`
        background: ${props.theme.palette.primary.main};
        color: ${getReadableColor(props.theme.palette.primary.main)};
        &:hover {
          background: ${rgba(props.theme.palette.primary.main, 0.8)};
        }
    `}
`

export interface ActionButtonBaseProps {
  children?: React.ReactNode
  className?: string
  icon?: React.ComponentType
  title?: string,
  onClick?: Function
  disabled?: boolean
  filled?: boolean
}

function ActionButtonBase ({ children, className, icon: IconBase, title, onClick, disabled, filled }: ActionButtonBaseProps) {
    const Icon = styled(IconBase)`
        width: 1.2em;
        height: 1.2em;
        color: currentColor;
    `
    return (
        <StyledEngineProvider injectFirst>
            <Tooltip title={title} arrow placement="top" enterDelay={400} enterNextDelay={400}>
                <Root className={className} onClick={onClick} disabled={disabled} filled={filled}>
                    <Icon />
                  {children}
                </Root>
            </Tooltip>
        </StyledEngineProvider>
    )
}

export default ActionButtonBase
