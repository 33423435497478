import React from 'react'
import { OpenInNew } from '@material-ui/icons'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'
import cleanUrl from '../../utils/cleanUrl'

const LinkText = styled.div`
  font-size: 12px;
  line-height: 25px;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ${props => props.rtl && 'rtl'};
  svg {
    width: 100px;
    height: 25px;
  }
`

const LinkAction = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const LinkIcon = styled(OpenInNew)`
  color: ${props => props.theme.palette.secondary.main}
  width: 15px;
  height: 15px;
  flex: 0 0 15px;
  filter: grayscale(100%);
`

const LinkWrapper = styled.a`
  display: block;
  min-width: 50px;
  max-width: 100%;
  padding-right: 25px;
  color: inherit;
  text-decoration: inherit;
  position: relative;
  &:hover ${LinkText} {
    mask-image: linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,0));
  }
  &:hover ${LinkAction} {
    opacity: 1;
  }
  &:hover ${LinkIcon} {
    filter: none;
  }
`



const Link = ({ value, title, link, disabled, pathOnly, rtl, icon: Icon }) => {
  return (
    <LinkWrapper href={link} target="_blank" style={disabled ? {pointerEvents: 'none'} : {}}>
      <LinkText rtl={rtl}>{ cleanUrl(value, pathOnly) }</LinkText>
      {
        link && <Tooltip title={`Open ${title ? `${cleanUrl(title)} ` : ''}`}>
          <LinkAction><LinkIcon /></LinkAction>
        </Tooltip>
      }

    </LinkWrapper>
  )
}

export default Link
