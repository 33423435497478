import config from '../config'

const COMPETITOR_COST = config.credits.competitor
const KEYWORD_COST = config.credits.keyword

export const credits = ({ keywordsCount, competitorsCount, CREDITS }) => {
  const competitorsCost = competitorsCount * COMPETITOR_COST
  const keywordsCost = keywordsCount * KEYWORD_COST
  const currentCost = competitorsCost + keywordsCost
  const creditsShort = currentCost - CREDITS

  return {
    CREDITS,
    COMPETITOR_COST,
    competitorsCount,
    competitorsCost,
    KEYWORD_COST,
    keywordsCount,
    keywordsCost,
    currentCost,
    creditsShort,
    creditLimit: creditsShort > 0
  }
}
