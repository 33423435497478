import React, { useContext, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import Button from "../../components/Button"
import ExpiredSearchModal from '../Modals/ExpiredSearchModal'
import bg from './bg.png'
import config from '../../config'
import { sendEvent } from '../../utils/events'
import ListContext from '../List/ListContext'

const BannerRoot = styled.div`
  background-color: white;
  background-image:  url(${bg}), url(${bg});
  background-repeat: no-repeat, no-repeat;
  background-position: 100% center, 60% top;
  background-size: 30%, 30%;
  box-shadow: 0 -5px 15px rgba(0,0,0,0.05);
  color: black;

  display: flex;
  //justify-content: center;
  align-items: center;
  padding: 2em;
  height: 90px;
  width: 100%;
  border-top: solid 1px ${props => props.theme.palette.brand.grey.main};
  //background-image:  ;
  position: relative;
  z-index: 9;
  font-family: Work Sans, Arial, sans-serif;
`

const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const Lead = styled.div`
  font-size: 18px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3em;
`

export const onOpenExpiredModal = () => {
  const event = new CustomEvent('toggleExpired', { detail: 'open'} )
  document.dispatchEvent(event)
}

const onCloseExpiredModal = () => {
  const event = new CustomEvent('toggleExpired', { detail: 'close'} )
  document.dispatchEvent(event)
}

const onMessageSales = (searchName) => {
  return window.Intercom('showNewMessage', `${config.EXPIRED_SEARCH_MESSAGE}"${searchName}"`)
}

const ExpiredSearchBanner = (props) => {

  const context = useContext(ListContext)

  const [openKind, setOpenKind] = useState(null)
  const [open, setOpen] = useState(context.openExpiredModal)

  const listener = ({ detail }) => {
    if (detail === 'open') {
      setOpenKind('event')
      setOpen(true)
    } else if (detail === 'close') {
      setOpenKind(null)
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('toggleExpired', listener)
    return (() => {
      document.removeEventListener('toggleExpired', listener)
    })
  }, [])

  const onClose = () => {
    if (openKind === 'event') {
      onCloseExpiredModal()
    } else {
      context.onCloseExpiredModal()
    }
  }

  const onContact = () => {
    onMessageSales(context.searchName)
    onClose()
  }

  return (
    <>
      <BannerRoot>
        <Content>
          <Heading>This search has expired.</Heading>
          <Lead>You have {context.daysLeft} days to reactivate your search before the data is deleted.</Lead>
        </Content>
        <Button
          variant={'contained'}
          color={'primary'}
          size={'large'}
          onClick={onContact}
        >
          Contact us
        </Button>
      </BannerRoot>
      <ExpiredSearchModal open={context.openExpiredModal || open} searchName={context.searchName} daysLeft={context.daysLeft} onClose={onClose} onContact={onContact} />
    </>
  )
}

export default inject(({ BrandStore }) => ({
  brandId: BrandStore.id
}))(observer(ExpiredSearchBanner))
