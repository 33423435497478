import React from 'react'
import Intelligence from './Intelligence'

class IntelligenceProvided extends React.Component {
  componentWillUnmount () {
    this.props.onUpdateBrandCounters()
    // this.props.onUpdateRelevantList()
  }

  render () {

    return <Intelligence />
  }
}

export default IntelligenceProvided
