import { find } from 'lodash'
import { gql } from '@apollo/client'
import GqlClient from './gql-client'
import { SUCCESS_FIELDS, FOLDER_FIELDS, BATCH_RESULT_FIELDS } from './fragments'

export const GET_FOLDERS = gql`
  query GetFoldersOfBrand($brandId: String!, $kind: String!) {
    foldersOfBrand(brandId: $brandId, kind: $kind) {
      ...FOLDER_FIELDS
    }
  }
  ${FOLDER_FIELDS}
`

export const GET_CHANNELS_FOLDER = gql`
  query ChannelFolderByProspects($brandId: String!, $channelIds: [String!]!) {
    channelFolderByProspects(brandId: $brandId, channelIds: $channelIds) {
      channelId
      folder {
        id
        name
      }
    }
  }
`

export const CREATE_DEFAULT_FOLDERS = gql`
  mutation CreateDefaultFolders($CreateFolderInput: CreateFolderInput!) {
    createFolder(input: $CreateFolderInput) {
      ...FOLDER_FIELDS
    }
  }
  ${FOLDER_FIELDS}
`

export const CREATE_FOLDER = gql`
  mutation CreateFolder($CreateFolderInput: CreateFolderInput!) {
    createFolder(input: $CreateFolderInput) {
      ...FOLDER_FIELDS
    }
  }
  ${FOLDER_FIELDS}
`

export const UPDATE_FOLDER = gql`
  mutation UpdateFolder($UpdateFolderInput: UpdateFolderInput!) {
    updateFolder(input: $UpdateFolderInput) {
      ...FOLDER_FIELDS
    }
  }
  ${FOLDER_FIELDS}
`

export const REMOVE_FOLDER = gql`
  mutation RemoveFolder($id: String!, $brandId: String!) {
    deleteFolder(id: $id, brandId: $brandId) {
      ...SUCCESS_FIELDS
    }
  }
  ${SUCCESS_FIELDS}
`

export const REORDER_FOLDERS = gql`
  mutation ReorderFolders($ReorderFoldersInput: ReorderFoldersInput!) {
    reorderFolders(input: $ReorderFoldersInput) {
      ...FOLDER_FIELDS
    }
  }
  ${FOLDER_FIELDS}
`

export const ADD_PROSPECTS_TO_FOLDER = gql`
  mutation AddProspectsToFolder(
    $AddProspectToFolderInput: AddProspectToFolderInput!
  ) {
    addProspectsToFolder(input: $AddProspectToFolderInput) {
      ...BATCH_RESULT_FIELDS
    }
  }
  ${BATCH_RESULT_FIELDS}
`

export const REMOVE_PROSPECTS_FROM_FOLDER = gql`
  mutation RemoveProspectsFromFolder(
    $RemoveProspectsFromFolderInput: RemoveProspectsFromFolderInput!
  ) {
    removeProspectsFromFolder(input: $RemoveProspectsFromFolderInput) {
      ...BATCH_RESULT_FIELDS
    }
  }
  ${BATCH_RESULT_FIELDS}
`

export const getChannelFolders = async (channels, brandId) => {
  let result = channels
  const channelIds = channels.map(({ channelId }) => channelId)
  const variables = { brandId, channelIds }

  if (channelIds.length) {
    const { success, ...rest } = await GqlClient.query({
      query: GET_CHANNELS_FOLDER,
      variables,
    })
    if (success) {
      const folders = rest.data['channelFolderByProspects']
      result = channels.map((channel) => {
        const channelFolder = find(folders, ['channelId', channel.channelId])
        if (channelFolder) {
          return { ...channel, folderName: channelFolder.folder.name }
        }
        return channel
      })
    }
  }

  return result
}
