import React, { useState } from 'react'
import styled from 'styled-components'
import { orderBy, uniq } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import md5 from 'md5'
import {
  Collapse,
  IconButton,
  InputAdornment,
  InputBase,
  Table as TableMUI, TableBody,
  TableCell,
  TableHead as TableHeadMUI, TableRow, TableSortLabel,
  Tooltip as TooltipMUI
} from '@material-ui/core'
import { Search as SearchIconMUI, Clear as ClearIconMUI, ChevronRight, ExpandMore } from '@material-ui/icons'

import WebsiteIcon from '../../components/Leads/Icon'
import icons from '../../components/AffiliateNetworkIcon'
import Pane from '../Details/Pane'
import NestedAffiliatesMap from '../NestedAffiliatesMap'

import copyToClipboard from '../../utils/clipboard'
import {formatNumber} from '../../utils/format'
import partner from '../../stores/partner'
import ShowAll from '../../components/ShowAll'

const ICON_SIZE = 25

const Tooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: "unset",
    color: 'white'
  },
}))(TooltipMUI);

const TableCellBody = styled(TableCell)`
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 5px 15px;
  position: relative;
`;

const TableCellHead = styled(TableCell)`
  font-size: 12px;
  line-height: 12px;
  border: none;
  padding: 5px 15px;
  font-weight: normal;
  background: #E9F3FE;
  &:first-child{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  & .MuiTableSortLabel-root {
    opacity: 0.5;
    font-weight: normal;
  }
`;

const TableHead = styled(TableHeadMUI)`
`

const SearchInput = styled(InputBase)`
  width: 100%;
  border: 1px solid #E9F3FE;
  box-sizing: border-box;
  border-radius: 5px;
  height: 30px;
  font-size: 14px;
  margin-bottom: 0.4em;
`

const SearchIcon = styled(SearchIconMUI)`
  width: 0.7em;
  margin-left: 0.3em;
  opacity: 0.5;
`

const ClearIcon = styled(ClearIconMUI)`
  width: 0.7em;
  opacity: 0.8;
`

const Domain = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Wrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //width: 100%;
  //max-width: ${props => props.width};
`

const Icon = styled.div`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  align-items: center;
  justify-content: center;
  display: flex;
  //padding-right: 20px;
  //margin: 0 auto;
  svg {
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
  }
`

const Icons = styled.div`
  position: relative;
  height: ${ICON_SIZE}px;
  width: ${props => props.width}px;
`

const IconHolder = styled.div`
  left: 0;
  top: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.more ? props.theme.palette.brand.blue.light : 'white'};
  color: ${props => props.more && props.theme.palette.brand.blue.main};
  border: 1px solid #E9F3FE;
  border-radius: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.2);
  &:last-child {
    box-shadow: none;
  }
`

const Label = styled.div`
  border-radius: 50px;
  padding: 3px 6px;
  background: ${props => props.more ? 'white' : '#fdeece'};
  border: 1px solid ${props => props.more ? '#eee' : 'transparent'};
  display: inline-block;
  text-transform: ${props => props.more ? '' : 'uppercase'};
  color: black;
`

const SeeAll = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.main};
`

const mapIcons = (networks, cut) => {
  const offset = ICON_SIZE / 2
  function renderIcon(iconArray) {
    return iconArray.map((icon, i) => {
      const localOffset = offset * i
      const zIndex = networks.length - i
      return <IconHolder style={{left: localOffset, zIndex, position: 'absolute'}}><Icon>{icons[icon] || icons['unknown']}</Icon></IconHolder>
    })
  }

  if (cut) {
    const containerSize  = ICON_SIZE + offset * 2
    return <Icons width={containerSize}>
      <IconHolder style={{left: 0, zIndex: 1, position: 'absolute'}}><Icon>{icons[networks[0]] || icons['unknown']}</Icon></IconHolder>
      <IconHolder more style={{left: ICON_SIZE * 0.9, position: 'absolute'}}><Icon>+{networks.length - 1}</Icon></IconHolder>
    </Icons>
  }

  if (typeof networks !== 'string') {
    const containerSize  = ICON_SIZE + offset * networks.length - 1
    return <Icons width={containerSize}>{renderIcon(networks)}</Icons>
  } else {
    return <IconHolder><Icon>{icons[networks] || icons['unknown']}</Icon></IconHolder>
  }
}

const generatePartnerId = data => {
  const hash = md5(data)
  return hash.substr(hash.length - 5)
}

const SortableCell = ({ orderBy, order, value, children, onSort, align }) => (
  <TableCellHead align={align}>
    <TableSortLabel
      active={orderBy === value}
      direction={orderBy === value ? order : 'asc'}
      onClick={onSort(value)}
    >
      { children }
    </TableSortLabel>
  </TableCellHead>
)

const Search = ({ search, onSearch, onClear }) => {
  return (
    <SearchInput
      value={search}
      onChange={onSearch}
      placeholder='Search Websites'
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={!!search && (
        <InputAdornment position="end">
          <IconButton onClick={onClear} size={'small'} style={{width: 30}}>
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      )
      }
    />
  )
}

const Table = ({ children, order, orderBy, onSort }) => {
  return (
    <TableMUI aria-label="Affiliate data table">
      <TableHead>
        <TableRow>
          <SortableCell
            value={'name'}
            order={order}
            orderBy={orderBy}
            onSort={onSort}
          >
            Target Website
          </SortableCell>
          <TableCellHead align={'right'} style={{minWidth: 90}}>
            Partner ID
          </TableCellHead>
          <TableCellHead align={'center'}>
            Network
          </TableCellHead>

          {/*<SortableCell*/}
          {/*  align={'right'}*/}
          {/*  value={'links'}*/}
          {/*  order={order}*/}
          {/*  orderBy={orderBy}*/}
          {/*  onSort={onSort}*/}
          {/*>*/}
          {/*  Links*/}
          {/*</SortableCell>*/}
          <TableCellHead align={'right'}>
            Links
          </TableCellHead>
          {/*<TableCellHead />*/}
        </TableRow>
      </TableHead>
      <TableBody>
        { children }
      </TableBody>
    </TableMUI>
  )
}

const PartnershipRow = (props) => {
  let allNetworks = []
  const [expanded, setExpanded] = useState(false)
  const { partnership, targetWebsite, targetIcon } = props
  const onToggle = () => {
    setExpanded(!expanded)
  }

  (partnership.rows || []).forEach(({ networks }) => {
    allNetworks = [...allNetworks, ...networks]
  })

  allNetworks = uniq(allNetworks)

  const fakeId = generatePartnerId(partnership.name)

  const multilineRow = allNetworks.length > 1 && partnership.links !== 1

  return (
    <>
      <TableRow onClick={Boolean(multilineRow) && onToggle} style={{cursor: multilineRow ? 'pointer': 'auto'}} >
        <TableCellBody style={{width: '70%', maxWidth: 0}}>
          <Domain>
            <WebsiteIcon item={partnership} />
            <Wrap>{partnership.name}</Wrap>
          </Domain>
        </TableCellBody>
        <TableCellBody align={'right'}>
          {
            multilineRow ? <Label href={'#'} more >all {partnership.total}</Label> : <Label>{fakeId}</Label>
          }
        </TableCellBody>
        <TableCellBody align={'center'}>
          {
            multilineRow ? mapIcons(allNetworks, true) : (
              <Tooltip title={<NestedAffiliatesMap
                website={{name: targetWebsite, icon: { icon: targetIcon }}}
                target={{name: partnership.rows[0].name, icon: { icon: partnership.rows[0].icon }}}
                networks={partnership.rows[0].networks} />
              }>
                {mapIcons(partnership.rows[0].networks)}
              </Tooltip>
            )
          }
        </TableCellBody>
        <TableCellBody align={'right'}>
          {partnership.links}
          {
            multilineRow && (
              <IconButton
                size={'small'} style={{position: 'absolute', right: -10, top: 4, width: 29}}
                onClick={onToggle}
              >
                { expanded ? <ExpandMore /> : <ChevronRight /> }
              </IconButton>
            )
          }
        </TableCellBody>
      </TableRow>
      {
        expanded && partnership.rows.map(({ id, name, links, networks, icon, partnerId }, index) => {
          return (
            <TableRow key={id}>
              <TableCellBody style={{width: '70%', maxWidth: 0}}></TableCellBody>
              <TableCellBody align={'right'}><Label>{`${fakeId}${index}`}</Label></TableCellBody>
              <TableCellBody>
                <Tooltip title={<NestedAffiliatesMap
                  website={{name: targetWebsite, icon: { icon: targetIcon }}}
                  target={{name: name, icon: { icon }}}
                  networks={networks} />
                }>
                  {mapIcons(networks)}
                </Tooltip>
              </TableCellBody>
              <TableCellBody align={'right'}>{links}</TableCellBody>
              {/*<TableCellBody></TableCellBody>*/}
            </TableRow>
          )
        })
      }
    </>
  )
}

const MIN_ROWS = 20

export default class PartnershipsWidget extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      orderBy: 'links',
      order: 'desc',
      search: '',
      collapsed: true,
      copied: false,
      anchorEl: null
    }
  }

  onSearch = ({ target: { value } }) => this.setState({ search: value })

  onClear = () => this.setState({ search: '' })

  onSort = (orderBy) => (e) => {
    if (this.state.orderBy === orderBy) {
      this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc'})
    } else {
      this.setState({ orderBy, order: 'desc' })
    }
  }

  onToggleCollapsed = () => this.setState({ collapsed: !this.state.collapsed })

  get partnerships () {
    const { data } = this.props
    const { order, search, collapsed } = this.state
    const filtered = data.filter(({ name }) => Boolean(search) ? name.includes(search) : true)
    const result = orderBy(filtered, [this.state.orderBy],[order])
    return collapsed ? result.slice(0, MIN_ROWS) : result
  }

  render () {
    const { data, targetWebsite, targetIcon } = this.props
    const { search, order, orderBy, collapsed } = this.state
    return (
      <Pane empty={data.length === 0} emptyMessage={'No Affiliate data is available'}>
        <Search search={search} onSearch={this.onSearch} onClear={this.onClear} />
        <Table order={order} orderBy={orderBy} onSort={this.onSort}>
          {
            this.partnerships.map((partnership, index) => {
              return <PartnershipRow key={partnership.id} partnership={partnership} targetWebsite={targetWebsite} targetIcon={targetIcon} />
            })
          }
        </Table>
        <ShowAll
          more={data.length > MIN_ROWS}
          amount={MIN_ROWS}
          total={data.length}
          collapsed={collapsed}
          onClick={this.onToggleCollapsed}
        />
      </Pane>
    )
  }
}
