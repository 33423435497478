import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import typeDefs from '../gql/typeDefs'

const GQL_LINK = process.env.GQL_API_URL

class GqlClient {
  constructor () {}

  updateToken (token) {
    localStorage.setItem('token', token)
  }

  removeToken () {
    localStorage.removeItem('token')
  }

  async query ({ query, variables = {}, unauthorized }) {
    const token = localStorage.getItem('token')
    return new Promise(resolve => {
      this.Request({
        ...(!unauthorized && { token })
      }).query({
        query,
        ...(Boolean(variables) && { variables })
      })
        .then(({ data, networkStatus}) => {
          resolve({ success: true, data })
        })
        .catch(() => resolve({ success: false }))
    })
  }

  async mutation ({ mutation, variables = {}, unauthorized, authToken }) {
    const token = localStorage.getItem('token')
    return new Promise(resolve => {
      this.Request({
        ...(!unauthorized && { token }),
        ...(authToken && { token: authToken })
      }).mutate({
        mutation,
        ...(Boolean(variables) && { variables })
      })
        .then(({ data, networkStatus}) => {
          resolve({ success: true, data })
        })
        .catch(() => resolve({ success: false }))
    })
  }

  Request ({ token }) {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...(Boolean(token) && { authorization: 'Bearer '+ token }),
        }
      }
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    })

    const httpLink = new HttpLink({
      uri: GQL_LINK
    })

    return new ApolloClient({
      link: ApolloLink.from([ errorLink, authLink, httpLink ]),
      cache: new InMemoryCache(),
      typeDefs
    })
  }
}

export default new GqlClient()
