import React, { useRef, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useIntercom } from 'react-use-intercom'

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const Intercom = ({ authorized, onboarding, user, ...rest }) => {
  const {
    boot,
    update,
    shutdown
  } = useIntercom();
  const [intercomStatus, setIntercomStatus] = useState('boot')
  const prevProps = usePrevious({ authorized, creatorLoading: rest['creatorLoading'], brandLoading: rest['brandLoading'], user, brand: rest['brand'], creator: rest['creator'] })

  useEffect(() => {
    if (prevProps) {
      if (prevProps.authorized && !authorized) {
        setIntercomStatus('boot')
        shutdown()
      }

      const role = user.role === 'creator' ? 'creator' : 'brand'
      const model = rest[role]

      if (!prevProps[role].id && model.id && intercomStatus !== 'update') {
        setIntercomStatus('boot')
      } else if (prevProps[role].id !== model.id) {
        setIntercomStatus('update')
      }

      const loading = rest[`${role}Loading`]
      const prevPropsLoading = prevProps[`${role}Loading`]

      if ((onboarding || prevPropsLoading) && !loading) {
        const company = {
          companyId: model['id'] || model['brandId'],
          name: model['name'] || role === 'creator' ? `Creator ${user.fullName || user.email}` : ''
        }
        const intercomProps = {
          userId: user.id,
          email: user.email,
          name: user.fullName,
          company
        }
        if (intercomStatus === 'boot') boot(intercomProps)
        if (intercomStatus === 'update') update(intercomProps)
      }
    }

  }, [authorized, user, rest['brand'], rest['creator'], rest['creatorLoading'], rest['brandLoading']])
  return <></>
}

export default inject(({ UserStore, BrandStore, CreatorStore }) => ({
  authorized: UserStore.authorized,
  user: UserStore.user,
  brandLoading: BrandStore.loading,
  creatorLoading: CreatorStore.loading,
  brand: BrandStore.details,
  creator: CreatorStore.details
}))(observer(Intercom))
