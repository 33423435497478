import React, { Component } from 'react'
import styled, {css} from 'styled-components'
import ButtonBase from '@mui/material/ButtonBase'
import {StyledEngineProvider} from '@mui/material/styles'
import {Compass, Activity, Lock} from 'react-feather'
import scoreColor from '../../utils/scoreColor'
import {Link, LinkProps} from 'react-router-dom'
import {TooltipMetricsBase} from '../TooltipMetrics'
import WidgetScore from '../Widget/WidgetScore'
import CountryFlag from '../CountryFlag'

const Root = styled(ButtonBase)`
  display: inline-flex;
  position: relative;
  justify-content: flex-start;
  width: auto;
  align-items: center;
  gap: 0.2em;
  padding: ${props => props.score ? '0.2em 0.5em 0.2em 0.8em' : '0.2em 0.5em'};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  background-color: ${props => props.disabled ? 'rgba(209,135,213,0.35)' : props.theme.palette.brand.shadow.main};
  color: ${props => props.theme.palette.mutedText};
  overflow: hidden;
  ${props => props.score && css`
    &:before {
       content: '';
       display: block;
       width: 1em;
       height: 80%;
       position: absolute;
       left: -0.5em;
       top: calc((100% - 80%) / 2);
       border-radius: 30%;
       background: ${scoreColor(props.score, props.theme)};
    }
  `}
`

const Label = styled.span`
  display: block;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`


const icons = {
    search: <Compass size="1em" />,
    ecosystem: <Activity size="1em" />,
    locked: <Lock size="1em" />
}

interface IconLabelProps extends LinkProps {
    variant?: 'search' | 'ecosystem' | 'locked'
    label: string
    value?: number
    geo?: string
    disabled?: boolean
}

const Score = styled(WidgetScore)`
      height: 3em!important;
      min-height: initial!important;
      min-width: 100px!important;
      padding: 0 0.8em;
      justify-content: center;
      border-radius: ${props => props.theme.shape.borderRadius}px;
  `

function Tooltip ({children, score, variant, label}: {label:string, variant:string, children: React.ReactElement, score: number}) {
    const content = score ? <Score label={null} value={score} />: null
    const prefix = variant === 'search' && score ? 'Score for' : ''
    const title = `${prefix} ${label} ${variant}`
    return <TooltipMetricsBase title={title} content={content}>
            {children}
        </TooltipMetricsBase>
}


function IconLabel ({ label, variant, value, geo, ...props }: IconLabelProps) {
    return (
        <StyledEngineProvider injectFirst>
                <Tooltip variant={variant} score={value} label={label}>
                    <Root score={value} {...props} component={Link} target={'_blank'} disabled={props.disabled}>
                        {icons[props.disabled ? 'locked' : variant]}
                        <Label>{label}</Label>
                        {geo && <CountryFlag iso={geo} />}
                    </Root>
                </Tooltip>
        </StyledEngineProvider>
    )
}

IconLabel.defaultProps = {
    label: 'Search title',
    variant: 'search'
}

export default IconLabel
