import { find, unionBy } from 'lodash'

export const getTagsList = (id, selected, items, byField) => {
  console.log('getTagsList', id, selected, items, byField)
  if (id) {
    const item = find(items, [byField || 'id', id])
    if (item) {
      return item.tags || []
    }
    return []
  } else if (selected && selected.length) {
    let allTags = []
    const itemsTags = items
      .filter(({ [byField || 'id']: id }) => selected.includes(id))
      .map(({ tags: t }) => t || [])

    itemsTags.forEach((t) => {
      allTags = unionBy(allTags, t, 'id')
    })

    let crossTags = []
    let tagCounter = 0
    allTags.forEach((tag) => {
      itemsTags.forEach((tags) => {
        const t = find(tags, ['id', tag.id])
        if (t) {
          tagCounter += 1
        }
      })
      if (tagCounter === itemsTags.length) {
        crossTags.push(tag)
      }
      tagCounter = 0
    })

    let result = []

    allTags.forEach((tag) => {
      if (!crossTags.some((t) => t.id === tag.id)) {
        result.push({ ...tag, unique: true })
      }
    })

    return [...result, ...crossTags]
  }
  return []
}
