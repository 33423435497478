import React from 'react'
import { find } from 'lodash'
import { inject, observer } from 'mobx-react'
import RunDiscoveryModal from './RunDiscoveryModal'
import BillingEndTrial from '../Billing/_BillingEndTrial'

@inject(({ BrandStore, SearchesStore, SearchStore }) => ({
  role: BrandStore.role,
  plan: BrandStore.plan,
  brandId: BrandStore.id,
  credits: BrandStore.credits,
  billingStatus: BrandStore.billingStatus,
  reRunDetails: BrandStore.reRunDetails,
  onShowPlans: () => BrandStore.showPlansModal(),
  onClose: () => BrandStore.closeReRun(),
  onUpdateCredits: details => BrandStore.updateCounters(details),

  products: SearchesStore.products,
  product: SearchStore.inputsProps,

  onRunDiscoveryProduct: (id) => SearchStore.startResearch(id),
  onRunDiscoveryList: (id) => SearchesStore.startResearch(id),
}))
@observer
export default class _ReRun extends React.Component {
  state = { thinking: false }

  get product () {
    const { kind, searchId } = this.props.reRunDetails
    let product = {}
    if (kind === 'product') {
      const {
        id,
        competitors: competitorsCount,
        keywords: keywordsCount,
        firstResearchSeen
      } = this.props.product

      product = { id, keywordsCount, competitorsCount, firstResearchSeen }
    } else {
      const p = find(this.props.products, ['id', searchId]) || {}
      const { id, competitorsCount, keywordsCount, firstResearchSeen } = p
      product = { id, keywordsCount, competitorsCount, firstResearchSeen }
    }
    return product
  }

  onRunDiscovery = async () => {
    this.setState({ thinking: true })
    const {
      reRunDetails: { searchId, kind }
    } = this.props

    const { success } = kind === 'product' ? await this.props.onRunDiscoveryProduct(searchId) : await this.props.onRunDiscoveryList(searchId)

    this.setState({ thinking: false })
    if (success) {
      this.props.onClose()
    }
  }

  render () {
    const {
      reRunDetails: { open },

      role,
      plan,
      credits,
      brandId,
      billingStatus,
      onShowPlans,
      onUpdateCredits
    } = this.props
    const { thinking } = this.state

    if (!open) {
      return null
    }

    const billingProps = {
      plan,
      credits,
      brandId,
      billingStatus,
      onUpdateCredits
    }

    return <RunDiscoveryModal
      {...this.product}
      thinking={thinking}
      open={open}
      role={role}
      creditsLeft={credits}
      onRun={this.onRunDiscovery}
      onShowPlans={onShowPlans}
      onClose={this.props.onClose}
      billingProps={billingProps}
      billingEndTrial={BillingEndTrial}
    />
  }
}
