import React from 'react'
import { inject, observer } from 'mobx-react'
import Create from './Create'
import AddModeCreditsModal from '../Modals/_AddMoreCreditsModal'

@inject(({ SearchesStore, BrandStore }) => ({
  products: SearchesStore.products,
  onCreateProduct: (brand, details) => SearchesStore.createProduct(brand, details),

  role: BrandStore.role,
  creditsLeft: BrandStore.credits,
  onUpdateBrandCounters: () => BrandStore.load(),
  onStartProductResearch: (searchId) => BrandStore.initReRun({searchId, kind: 'products'}),
}))
@observer
class _Create extends React.Component {
  constructor (props) {
    super(props)
    this.state = { credits: false }
  }

  onOpenCredits = () => this.setState({ credits: true })
  onCloseCredits = () => this.setState({ credits: false })

  onCreate = async ({ run, ...details }) => {
    const { success, product } = await this.props.onCreateProduct(details, this.props.role)
    if (success) {
      this.props.onUpdateBrandCounters()
      if (run) {
        this.props.onStartProductResearch(product.id)
      }
    }
    return { error: '' }
  }

  render () {
    const {
      open,
      role,
      products,
      creditsLeft,
      onClose
    } = this.props

    return  <>
      <Create
        role={role}
        variant={'create'}
        open={open}
        products={products}
        creditsLeft={creditsLeft}
        onClose={onClose}
        onSubmit={this.onCreate}
        onAddCredits={this.onOpenCredits}
      />
      { this.state.credits && <AddModeCreditsModal open={this.state.credits} onClose={this.onCloseCredits} />}
    </>
  }
}

export default _Create
