import React from 'react'
import { Contact } from '../Details/interfaces'

import ContactsOld from '../../new-components/Contacts'

interface ContactsProps {
  loading: boolean
  status: string | undefined
  contacts: Array<Contact> | undefined
  entity: any
  onRequest: Function
}

const removeContactsDuplicates = (contacts: Array<Contact>) => {
  const ids = []
  if (!contacts) return contacts

  return contacts.filter((contact) => {
    if (ids.includes(contact.id)) return false
    ids.push(contact.id)
    return true
  })
}

export default function Contacts(props: ContactsProps) {
  return (
    <ContactsOld
      thinking={props.loading}
      status={props.status}
      entity={props.entity}
      contacts={removeContactsDuplicates(props.contacts)}
      onRequest={props.onRequest}
    />
  )
}
