import React, {useContext} from 'react'

import {CreatorRequestContext} from '../CreatorRequestDetailsContext'
import Promotes from '../../Profile/Promotes'

export default function CreatorRequestProfilePromotes (props) {
    const { loading, promotes, summary } = useContext(CreatorRequestContext)
    const { type } = summary
    const { partnershipsInCount, partnershipsOutCount } = summary
    const total = type === 'creator' ? partnershipsOutCount : partnershipsInCount

    if (!loading && !total) return null

    const link = `#`

    return <Promotes loading={loading} type={type} total={total || 0} data={promotes} link={link} />
}
