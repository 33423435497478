import React from 'react'
import {
  Close as CloseIcon,
  Loop as ReloadIcon
} from '@material-ui/icons'
import {
  IconButton,
  Collapse,
  Snackbar,
  SnackbarContent
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import hasError from '../../stores/request-message'

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}

class Notification extends React.Component {
  render () {
    const { open, message, variant, timeout, anchorOrigin, hasErrorAction, onClose, ...rest } = this.props

    const onError = () => {
      if (hasErrorAction) {
        rest.onError()
        onClose()
      }
    }

    return (
      <Snackbar
        anchorOrigin={anchorOrigin || defaultAnchorOrigin}
        open={open}
        autoHideDuration={timeout}
        onClose={onClose}
      >
        <Alert
          style={{ alignItems: 'inherit' }}
          severity={variant}
          // icon={false}
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={hasErrorAction ? onError : onClose}
            >
              { hasErrorAction ? <ReloadIcon /> : <CloseIcon /> }
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Snackbar>
    )
  }
}

export default Notification
