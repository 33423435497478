import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Paper, Button, Grid } from '@material-ui/core'
import { onManagePaymentMethod } from '../../utils/chargebee'
import Divider from '../Divider'
import { LoaderOverlay } from '../Loader'
import { AssistedLockContent } from '../../new-components/Modals/AssistedLockModal'

const capitalize = (str) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 20px 10px;
`

const PaymentRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`

const Header = styled.div`
  padding: 0 0 28px 10px;
  //width: 100%;
  text-align: start;
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.41px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
`

const PaymentMethodItem = styled.div`
  padding: 0 0 0 10px;
  //width: 100%;
  text-align: start;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
`

const PaymentHistoryItem = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 14px 10px;
  //width: 100%;
  text-align: start;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  //font-weight: 400;
  //line-height: 16.41px;
`

const PaymentButton = styled.div`
  padding: 10px;
`


const PaymentMethods = ({ card, onManage }) => (
  <PaymentRoot>
    <Header>Payment method</Header>
    <List>
      <Grid container spacing={4}>
        <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>

          {
            card && <PaymentMethodItem>
              {capitalize(card.brand)} ending in {card.last4}
            </PaymentMethodItem>
          }
          {
            !card && <PaymentMethodItem>You haven't added any payment method yet</PaymentMethodItem>
          }
        </Grid>
        <Grid item xs={9}>
          <PaymentButton>
            <Button
              color="primary"
              variant={'contained'}
              disableElevation
              onClick={onManage}>
              {card ? 'Manage' : 'Add'} Payment Method
            </Button>
          </PaymentButton>
        </Grid>
      </Grid>
    </List>
  </PaymentRoot>
)

const toPounds = amount => (amount / 100).toFixed(2)

const PaymentHistory = ({ history }) => (
  <PaymentRoot style={{ paddingTop: 40 }}>
    <Header>Payment history</Header>

    <List>
      {
        history.map((h) => (
          <PaymentHistoryItem>
            <strong>
              £{toPounds(h.amountPaid)}
              &nbsp;
              {'successful'}
            </strong>
            <span>
                on {moment(h.paidAt * 1000).format('YYYY-MM-DD')}
              </span>

            <span>Invoice</span>
          </PaymentHistoryItem>
        ))
      }
      {
        !history.length && <PaymentHistoryItem>Your payment history is empty</PaymentHistoryItem>
      }
    </List>
  </PaymentRoot>
)

export default class Billing extends React.Component {
  state = { thinking: false }

  componentDidMount () {
    this.props.load()
  }

  onManagePaymentMethod = () => onManagePaymentMethod(this.props.brandId, null, this.props.onUpdatePayments)

  render () {
    const { card, history, assistedLock } = this.props

    return (
      <Root>
        {assistedLock &&  <AssistedLockContent padding={0} />}
        {!assistedLock && <>
            <PaymentMethods card={card} onManage={this.onManagePaymentMethod} />
            <Divider  mt='1.8em' />
            <PaymentHistory history={history} />
          </>
        }
      </Root>
    )

  }
}
