import React from 'react'
import { inject, observer } from 'mobx-react'
import RequestContactsModal from './RequestContactsModal'
import BillingEndTrial from '../Billing/_BillingEndTrial'

@inject(({ BrandStore, PartnersStore, ProspectsStore }) => ({
  plan: BrandStore.plan,
  role: BrandStore.role,
  brandId: BrandStore.id,
  credits: BrandStore.credits,
  assistedLock: BrandStore.assistedLock,
  billingStatus: BrandStore.billingStatus,
  requestContactsDetails: BrandStore.requestContactsDetails,
  onShowPlans: () => BrandStore.showPlansModal(),
  // onClose: () => BrandStore.closeRequestContacts(),
  onUpdateCredits: details => BrandStore.updateCounters(details),

  // onRequestProspectContacts: (partnerId, channelId) => ProspectsStore.requestPartnerContacts(partnerId, channelId),
  // onRequestSelectedProspectsContacts: ids => ProspectsStore.requestPartnersContacts(ids),
  //
  // onRequestPartnerContacts: (partnerId, channelId) => PartnersStore.requestPartnerContacts(partnerId, channelId),
  // onRequestSelectedPartnersContacts: ids => PartnersStore.requestPartnersContacts(ids),
}))
@observer
export default class _RequestContacts extends React.Component {
  state = { thinking: false }

  onRequest = async (message) => {
    const {
      itemIds, callback
    } = this.props

    let result = null
    this.setState({ thinking: true })

    if (Array.isArray(itemIds)) {
      result = await this.props.onRequestSelected(itemIds, message)
    } else {
      result = await this.props.onRequestSingle(itemIds, message)
    }

    if (callback) {
      callback(result.success)
    }

    this.setState({ thinking: false }, () => {
      if (result.success) {
        this.props.onUpdateCredits()
        this.props.onClose()
      }
    })
  }

  onClose = () => {
    if (this.props.callback) {
      this.props.callback(false)
    }
    this.props.onClose()
  }

  render () {
    const {
      open,
      itemIds,
      role,
      plan,
      credits,
      brandId,
      billingStatus,
      assistedLock,
      onShowPlans,
      onUpdateCredits
    } = this.props
    const { thinking } = this.state

    if (!open) {
      return null
    }

    const billingProps = {
      plan,
      credits,
      brandId,
      billingStatus,
      assistedLock,
      onUpdateCredits
    }

    return <RequestContactsModal
      open
      itemIds={itemIds}
      role={role}
      creditsLeft={credits}
      thinking={thinking}
      onShowPlans={onShowPlans}
      onRequest={this.onRequest}
      onClose={this.onClose}

      billingProps={billingProps}
      billingEndTrial={BillingEndTrial}
    />
  }
}
