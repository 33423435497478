import React from 'react'
import Button from '../Button'
import { onEndTrial } from '../../utils/chargebee'

export default class BillingEndTrial extends React.Component {
  state = { thinking: false }

  onEndTrial = async () => {
    const { brandId } = this.props
    this.setState({ thinking: true })

    await onEndTrial(brandId, async () => {
      await this.props.onPollingBrand()
      if (this.props.onSuccess) {
        this.props.onSuccess()
      }
      this.setState({ thinking: false })
    }, () => {
      this.setState({ thinking: false })
    })
  }

  render () {
    const { thinking, disabled } = this.state

    return <Button
      variant="contained"
      color="primary"
      mb={2}
      disabled={thinking}
      onClick={this.onEndTrial}
    >
      End trial and activate subscription
    </Button>
  }
}
