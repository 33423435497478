import React from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import { LoaderOverlay } from '../../components/Loader'

@inject(({ UserStore }) => ({
  thinking: UserStore.thinking,
  onSuspend: details => UserStore.suspend(details)
}))
@observer
@withRouter
export default class Suspend extends React.Component {
  constructor (props) {
    super(props)
    const {
      location: { search }
    } = this.props

    const { token } = qs.parse(search)

    this.state = { token, thinking: false }
  }

  componentDidMount () {
    this.onSuspend().then()
  }

  onSuspend = async () => {
    const { token } = this.state
    this.setState({ thinking: true })

    const { success } = await this.props.onSuspend(token)

    this.setState({ success, thinking: false })
  }

  render () {
    const { success, thinking } = this.state

    return (
      <div>
        { thinking && <LoaderOverlay /> }
        { !thinking && success && <p>'Successfully suspended'</p>}
        { !thinking && !success && <p>'Something happened wrong'</p>}
      </div>
    )
  }
}
