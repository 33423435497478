import React, { Component } from 'react'
import Tooltip from '@mui/material/Tooltip'
import styled from 'styled-components'
import {PoweredByProps} from '../../configMetrics'

interface BaseProps {
    title?: React.ReactElement | string
    content?: React.ReactElement | string
    poweredBy?: PoweredByProps
    open?: boolean
}
export interface TooltipMetricsBaseProps extends BaseProps {
    children?: React.ReactElement
}

const PoweredBy = styled.a<PoweredByProps>`
    opacity: 0.8;
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.3em 0;
    margin-top: 0.6em;
    text-decoration: none;
    color: white;
    &:hover {
        opacity: 1;
    }
`

const Root = styled.div`
    padding: 0.5em;
`
const Title = styled.strong`
    display: block;
    font-weight: bold;
    margin-bottom: 0.5em;
`

export function Base ({title, content, poweredBy}: BaseProps) {
    return (
        <Root>
            {title && content ? <Title>{title}</Title> : title}
            {content && content}
            {poweredBy &&
                <PoweredBy target="_blank" href={poweredBy.href}>{poweredBy.content}</PoweredBy>
            }
        </Root>
    )
}

function TooltipMetricsBase({children, ...other}: TooltipMetricsBaseProps) {
    return <Tooltip arrow open={other.open} enterDelay={400} enterNextDelay={400} PopperProps={{
        // modifiers: [
        //     {
        //         name: 'offset',
        //         // options: {
        //         //     offset: [-8, 0],
        //         // }
        //     }
        // ]
    }} placement="left" title={(other.content || other.title) && <Base {...other}/>}>
        {typeof children === 'string' ?  <span>{children}</span> : children}
    </Tooltip>
}

TooltipMetricsBase.defaultProps = {
  children: 'It\'s a me, TooltipLayout!'
}

export default TooltipMetricsBase
