import React from 'react'

export type DetailsSidebarKeysBase =
  | 'lead'
  | 'relevant'
  | 'partner'
  | 'gap'
  | 'landscape'
  | 'partnerships'
  | 'creator'
  | 'contacts'

const tabs: { [key: string]: Array<string> } = {
  lead: ['Profile', 'Contacts', 'Placements', 'Keywords', 'Competitors'],
  relevant: ['Profile', 'Contacts', 'Placements', 'Keywords', 'Competitors'],
  partner: ['Profile', 'Contacts', 'Placements', 'Keywords', 'Competitors'],
  gap: ['Profile', 'Contacts'],
  landscape: ['Profile', 'Contacts'],
  partnerships: ['Profile', 'Contacts'],
  creator: ['Profile'],
  contacts: ['Contacts'],
}

export default tabs
