import React from 'react'
import styled from 'styled-components'
import { StyledEngineProvider } from '@mui/material/styles'
import {TooltipMetricsBase as Tooltip, TooltipMetricsBaseProps} from '../../../TooltipMetrics'
import Skeleton from '../../../Skeleton'
import LinkOverlay from '../../../LinkOverlay'
import {Size} from '../../../../configMetrics'
import {formatToReadableNumber as format} from '../../../../utils/format'

const sizer = {
    '1x1': {colSpan: 1, height: '4.7em'},
    '1x2': {colSpan: 1, height: '4.7em'},
    '2x1': {colSpan: 2, height: '4.7em'},
    '2x2': {colSpan: 2, height: '4.7em'},
}

export const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: ${props => props.size && sizer[props.size].height};
    align-items: flex-start;
    justify-content: ${props => sizer[props.size].rowSpan > 1 ? 'flex-start' : 'center'};
    box-shadow: inset 0 0 0 1px ${props => props.loading ? 'transparent' : props.theme.palette.brand.shadow.main};
    border-radius: ${props => props.theme.shape.borderRadius * 2}px;
    padding: 0.8em;
    grid-column: ${props => props.size && `span ${sizer[props.size].colSpan}`};
    line-height: 1.43;
    font-family: Roboto, sans-serif;
    overflow: hidden;
    &:hover (LinkOverlay) {
        opacity: 0.5;
    }
`

const LabelRoot = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.8em;
    line-height: 1.43;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.palette.mutedText};
    cursor: help;
`
const ValueRoot = styled.div`
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.43;
    z-index: 1;
    color: ${props => props.theme.palette.accentDarkText};
`

export function Label ({children, tooltip}: {children: React.ReactElement | string, tooltip?: TooltipMetricsBaseProps}) {
    return children ? <Tooltip {...tooltip}><LabelRoot>{children}</LabelRoot></Tooltip> : null
}

export function Value ({children, value, className}: {children?: React.ReactNode, value?: string | number, className?: string}) {
    const _value = typeof value === 'number' ? format(value) : value
    return (value && !children) ? <ValueRoot className={className}>{_value}</ValueRoot>: <>{children}</>
}

export interface WidgetBaseProps {
    children?: React.ReactNode
    label?: string | React.ReactElement
    value?: number
    size?: Size
    tooltip?: TooltipMetricsBaseProps
    component?: React.ComponentType
    loading?: boolean,
    className?: string
    onClick?: Function
}

function WidgetBase ({children, value, label, size, tooltip, component, className, loading, onClick }: WidgetBaseProps) {
    let tooltipProps = !loading ? tooltip : null
    return (
        <StyledEngineProvider injectFirst>
                <Root as={component} component={'div'} size={size} className={className} value={value} loading={loading} onClick={onClick}>
                    <Skeleton loading={loading}>
                        <LinkOverlay />
                        <Label tooltip={tooltipProps}>{label}</Label>
                        <Value value={value}>{children}</Value>
                    </Skeleton>
                </Root>
        </StyledEngineProvider>
    )
}

WidgetBase.defaultProps = {
    size: '1x1',
    onClick: () => void(0)
}

Value.defaultProps = {
    value: 'N/A',
}

export default WidgetBase
