import { Get, Delete, Post, Put } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import { orderBy, sumBy, sortBy, findIndex, uniqBy, groupBy } from 'lodash'

const convertLeadToListItem = lead => {
  const {
    id, hostDomain, channelId,
    product: { id: searchId, name, country, description },
    breezyScore, competitorSeedsCount, keywordSeedsCount
  } = lead

  const searchName = description || `For ${name} in ${country}`

  return {
    id,
    url: hostDomain,
    channelId,
    searchId,
    title: searchName,
    country,
    fields: [
      {type: 'breezyScore', value: breezyScore },
      // {type: 'domainAuthority', value: domainAuthority === undefined ? 'N/A' : domainAuthority },
      {type: 'keywords', value: keywordSeedsCount},
      {type: 'competitors', value: competitorSeedsCount},
      // {type: 'affiliatePages', value: partnershipsOutCount || 0},
    ],

    /////NEW DATA
    name: searchName,
    breezyScore, competitorSeedsCount, keywordSeedsCount
  }
}

const convertToProspectListItem = prospect => {
  const { id, hostDomain, product, outChannel, breezyScore, competitorSeedsCount, keywordSeedsCount } = prospect
  return {
    id,
    url: hostDomain,
    title:(outChannel || {} ).mainTitle,
    product: (product || {}).name,
    searchId: (product || {}).id,
    fields: [
      {type: 'breezyScore', value: breezyScore },
      {type: 'keywords', value: keywordSeedsCount},
      {type: 'competitors', value: competitorSeedsCount}
    ]
  }
}

const convertRelevantToListItem = lead => {
  const {
    url, title, icon, breezyScore, pageAuthority,
    keywordSeeds, competitorSeeds,
    product
  } = lead

  const { name, country, description } = (product || {})
  const searchName = description || `For ${name} in ${country}`
  return {
    id: url,
    url,
    title,
    searchName,
    icon,
    fields: [
      {type: 'breezyScore', value: breezyScore },
      {type: 'pageAuthority', value: pageAuthority},
      {type: 'keywords', value: (keywordSeeds || []).length},
      {type: 'competitors', value: (competitorSeeds || []).length}
    ],
    keywordSeeds,
    competitorSeeds
  }
}

const convertToMostRelevant = leads => {
  const urls = getAllRelevant(leads)
  return urls.map(convertRelevantToListItem)
}

const getSeedsByProduct = leads => {
  let keywordsByProduct = []
  let competitorsByProduct = []

  leads.forEach(lead => {
    const { product, competitorSeeds, keywordSeeds } = lead
    const url = product.description || `${product.name} in ${product.country}`
    keywordsByProduct.push({ url, icon: null, list: keywordSeeds })
    competitorsByProduct.push({ url, icon: null, list: competitorSeeds })
  })

  return { keywordsByProduct, competitorsByProduct }
}

const getSeedsByRelevant = (leads, icon) => {
  let keywordsByRelevant = []
  let competitorsByRelevant = []

  getAllRelevant(leads).forEach(lead => {
    const { url, competitorSeeds, keywordSeeds } = lead
    keywordsByRelevant.push({ url, icon, list: keywordSeeds })
    competitorsByRelevant.push({ url, icon, list: competitorSeeds })
  })

  return { keywordsByRelevant, competitorsByRelevant }
}

const getAllRelevant = (leads, uniq) => {
  let allUrls = []
  leads.forEach(({ urls, product }) => {
    (urls || []).forEach(url => {
      const index = findIndex(allUrls, ['url', url.url])
      if (index < 0 && uniq) {
        allUrls.push({ ...url, product })
      } else {
        allUrls.push({ ...url, product })
      }
    })
  })
  return allUrls
}

const getSeedsBy = (leads, by) => {
  let seeds = []
  leads.forEach(lead => {
    seeds = [...seeds, ...lead[`${by}Seeds`]]
  })
  return seeds
}

const getCompetitorSeeds = leads => {
  const seeds = getSeedsBy(leads, 'competitor')
  return uniqBy(seeds, (seed) => seed)
}

const getKeywordSeeds = leads => {
  let seeds = getSeedsBy(leads, 'keyword')
  let result = {}

  seeds.forEach(seed => {
    const matched = seed.split(/::/)
    const keyword = matched[0]
    const rank = matched[1] ? parseInt(matched[1]) : 0

    if (result.hasOwnProperty(keyword)) {
      result[keyword] = rank > 0 && result[keyword] > rank ? rank : result[keyword]
    } else {
      result[keyword] = rank
    }
  })

  seeds = []

  for (let keyword in result) {
    if (result.hasOwnProperty(keyword)) {
      seeds.push(`${keyword}::${result[keyword]}`)
    }
  }
 //deploy
  return seeds
}

const initialPartner = {
  loading: true,

  channelId: null, link: null, mainTitle: null, icon: null, screenshot: null, lang: null,

  swRank: undefined,
  domainAuthority: undefined,
  rootDomainsToRootDomain: undefined,
  partnershipsOutCount: undefined,
  countries: [],
  campaigns: [],
  prospects: [],
  contactRequest: {},
  contactInfos: [],
  leads: []
}

class PartnerStore {
  channelId
  partnerId
  @observable link
  @observable mainTitle
  @observable icon
  @observable screenshot
  @observable lang
  @observable type1Classification

  @observable swRank
  @observable domainAuthority
  @observable rootDomainsToRootDomain
  @observable partnershipsOutCount

  @observable countries = []
  @observable campaigns = []
  @observable prospects = []
  @observable leads = []
  @observable contactRequest = {}
  @observable contactInfos = []

  @observable loading = true
  @observable loadingChannel = true
  @observable loadingView = true
  @observable thinking = false

  updatePartner (channel) {
    for( let key in channel) {
      if (channel.hasOwnProperty(key)) {
        this[key] = channel[key]
      }
    }
  }

  constructor (brandId) {
    this.brandId = brandId
  }

  @action
  load (partnerId, channelId) {
    //Set initial
    this.updatePartner(initialPartner)

    this.channelId = channelId
    this.partnerId = partnerId

    this.loadChannel().then()
    this.loadView().then()
  }

  @action
  async loadChannel () {
    this.loadingChannel = true
    const { message, channel, campaigns } = await Get(`/api/discovery/channels/${this.channelId}/details`)

    if (!message) {
      this.updatePartner(channel)
      this.campaigns = campaigns || []
    }

    this.loadingChannel = false
  }

  @action
  async loadView () {
    this.loadingView = true

    const { message: success, prospects, contactRequest, contactInfos, leads } = await Get(`/api/discovery/existing-partners/${this.partnerId}/view?brand=${this.brandId}`)

    if (!success) {
      this.prospects = prospects || []
      this.contactRequest = contactRequest || {}
      this.contactInfos = contactInfos || []
      this.leads = leads || []
    }

    this.loadingView = false
  }

  @action
  updateContactRequestStatus () {
    this.contactRequest = {
      status: 'requested'
    }
  }

  @computed
  get details () {
    const { channelId, link, mainTitle, screenshot, icon, swRank, domainAuthority, contactRequest, leads, type1Classification } = this
    const relevant = convertToMostRelevant(toJS(leads))
    const root = {
      id: channelId,
      url: link,
      title: mainTitle,
      screenshot,
      icon,
      type1Classification,
      fields: [
        {type: 'swRank', value: swRank !== undefined ? swRank : 'N/A'},
        {type: 'domainAuthority', value: domainAuthority === undefined ? 'N/A' : domainAuthority },
      ]
    }

    return {
      root,
      relevant,
      targetWebsite: link,
      competitors: getCompetitorSeeds(leads),
      keywords: getKeywordSeeds(leads),
      ...getSeedsByRelevant(leads),
      ...getSeedsByProduct(leads),
      countries: orderBy(toJS(this.countries || []).map(({ code, ...rest }) => ({ ...rest, iso: code })), ['users'], ['desc']),
      leads: toJS(this.leads).map(convertLeadToListItem),
      contactRequestStatus: contactRequest.status
    }
  }

  @computed
  get affiliatesLinks () {
    const campaigns = Object
      .entries(groupBy(toJS(this.campaigns), 'inChannelLink'))
      .map(([name, rows]) => {
        const [firstPartnership] = rows
        return {
          id: firstPartnership.inChannel,
          name,
          icon: firstPartnership.inChannelIcon,
          links: sumBy(rows, 'count'),
          total: rows.length,
          rows: rows.map(({ id, adex, count, partnerId, icon, ...rest }) => {
            let networks = adex ? adex.split(/:/) : []
            const amazonIndex = networks.indexOf('Amazon')

            if (amazonIndex >= 0) {
              networks[amazonIndex] = 'Amazon Associates'
            }

            return {
              id,
              name: rest.inChannelLink,
              networks,
              links: count,
              partnerId,
              icon
            }
          })
        }
      })

    return sortBy(campaigns, 'links').reverse()
  }

  @computed
  get affiliateNetworksDistribution () {
    const total = toJS(this.campaigns).length //sumBy(toJS(this.campaigns), 'exitsCount')
    let data = []

    toJS(this.campaigns).forEach(({ adex, exitsCount }) => {
      const splitedAdex = adex ? adex.split(/:/) : []
      const firstAdex = splitedAdex[0]

      if (firstAdex) {
        const index = findIndex(data, ['name', firstAdex])
        if (index >= 0) {
          data[index].count += 1
        } else {
          data.push({ name: firstAdex, count: 1 })
        }
      }
    })

    return sortBy(data.map(({ name, count }) => ({
      name,
      count,
      share: ((count / total) * 100)
    })), 'share').reverse()
  }

  @computed
  get contacts () {
    return toJS(this.contactInfos)
  }

  @computed
  get languages () {
    const lang = toJS(this.lang)
    return lang && lang !== 'undetected' ? [lang] : []
  }
}

export default PartnerStore
