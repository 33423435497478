import { inject, observer } from 'mobx-react'
import Notification from './Notification'

const NotificationConnected = inject(stores => ({
  message: stores.NotificationStore.message,
  variant: stores.NotificationStore.variant,
  timeout: stores.NotificationStore.timeout,
  open: stores.NotificationStore.open,
  hasErrorAction: stores.NotificationStore.hasErrorAction,
  onClose: () => stores.NotificationStore.remove(),
  onError: () => stores.NotificationStore.error(),
}))(observer(Notification))

export default NotificationConnected
