import React from 'react'
import styled from 'styled-components'
import pluraliseUnit from "../../utils/pluraliseUnit";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: 0;
  :hover {
    transition-delay: 1s;
    opacity: 1;
  }
`
const Label = styled.div`
  font-size: 12px;
  padding-bottom: 0.5em;
  opacity: 0;
  :hover {
    transition-delay: 1s;
    opacity: 1;
  }
`


const CreditsHeader = ({unitName, inputCost, inputUnitCost}) => (
  <Root>
    <Label>{inputCost} {pluraliseUnit('credit', inputCost)}</Label>
    <Label>1 {unitName} = {inputUnitCost} {pluraliseUnit('credit', inputUnitCost)}</Label>
  </Root>
)

export default CreditsHeader
