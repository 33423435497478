import React, { Component } from 'react'
import styled from 'styled-components'
import CategoryIcon from '@material-ui/icons/Category'
import Image from 'material-ui-image'
import cleanUrl from '../../utils/cleanUrl'
import Skeleton, {SkeletonBase} from '../Skeleton'

const imageKitUrl = process.env.IMAGEKIT_URL || 'https://ik.imagekit.io/afill/staging'
const retina = window.devicePixelRatio > 1 // TODO: Optimize this by moving to the app level
const imageWidth = 418
const imageHeight = 208

const Link = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
`
const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  max-height: 200px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: ${props => props.theme.shape.borderRadius * 2}px;
  box-shadow: inset 0 0 0 1px ${props => props.loading ? 'transparent' : props.theme.palette.brand.shadow.main};
  position: relative;
  
  & > div {
    z-index: -1; 
    border: 1px solid white;
    border-radius: ${props => props.theme.shape.borderRadius * 2}px;
    overflow: hidden;
  }
`

function thumbnail(URL, retina) {
    return `${imageKitUrl}/${cleanUrl(URL, true)}?tr=w-${retina ? imageWidth * 2 : imageWidth},h-${retina ? imageHeight * 2 : imageHeight}`
}

interface ItemProps {
    url: string
    screenshot?: string,
}

interface ScreenshotProps {
    item?: ItemProps,
    loading?: boolean,
    className?: string
}

function Screenshot ({ item, loading, className }: ScreenshotProps) {
    return (
        <Thumbnail loading={loading} className={className}>
            <Skeleton loading={loading}>
                <Link href={'http://' + item.url} target="_blank" />
                <Image src={thumbnail(item.screenshot, retina)} aspectRatio={(2)} loading={<SkeletonBase />}/>
            </Skeleton>
        </Thumbnail>
    )
}

Screenshot.defaultProps = {
  value: 'It\'s a me, Screenshot!'
}

export default Screenshot
