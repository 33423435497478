import React from 'react'
import { Grid } from '@material-ui/core'

import TextRows from '../../components/TextRows'
import { FormsModal } from '../../components/Forms/FormsModal'
import CreditsHeader from '../../components/CreditsHeader'
import { BlocklistTips, CompetitorsTips, KeywordsTips } from './InputsFormTips'
import InputsFormActions from './InputsFormActions'

const inputsConfig = {
  competitors: {
    helperText: 'Type one URL per line',
    placeholder: 'e.g. https://example.com',
    minRow: 2,
    minRowError: 'Please enter at least 2 competitor URLs',
  },
  blocklist: {
    helperText: 'Type one URL per line',
    placeholder: 'e.g. https://example.com'
  },
  keywords: {
    helperText: 'Enter one keyword or phrase per line',
    errorText: 'Please enter keyword or phrase',
    minRow: 5,
    minRowError: 'Please enter at least 5 keywords or phrases',
  }
}

export default class InputsForm extends React.Component {
  onSaveAndRun = () => {
    this.props.onSubmit(true)
  }

  onSave = () => {
    this.props.onSubmit()
  }

  get tips () {
    const { kind } = this.props

    if (kind === 'keywords') {
      return KeywordsTips
    }
    if (kind === 'competitors') {
      return CompetitorsTips
    }
    if (kind === 'blocklist') {
      return BlocklistTips
    }
    return null
  }

  render() {
    const {
      kind,
      variant,
      credits,
      values,
      errors,
      helperText,
      errorText,
      placeholder,
      onChange
    } = this.props

    const Tips = () => <this.tips variant={variant} />

    const Actions = () => {
      return <InputsFormActions
        {...this.props}
        onSave={this.onSave}
        onSaveAndRun={this.onSaveAndRun}
      />
    }

    return (
      <FormsModal
        Text={Tips}
        Actions={Actions}
        noPadding
      >
        <Grid container style={{marginTop: '16px'}}>
          <Grid item xs={12} style={{height: 'calc(100vh - 300px)', position: 'relative'}}>
            {/*{*/}
            {/*  kind !== 'blocklist' && (*/}
            {/*    <CreditsHeader*/}
            {/*      unitName={kind === 'competitors' ? 'Competitor' : 'Keyword'}*/}
            {/*      inputCost={kind === 'competitors' ? credits.competitorsCost : credits.keywordsCost}*/}
            {/*      inputUnitCost={kind === 'competitors' ? credits.COMPETITOR_COST : credits.KEYWORD_COST}*/}
            {/*    />*/}
            {/*  )*/}
            {/*}*/}

            <TextRows
              validation={kind}
              values={values}
              errors={errors}
              onChange={onChange}
              {...inputsConfig[kind]}
            />
          </Grid>
        </Grid>
      </FormsModal>
    )
  }
}
