import React from 'react'
import styled from "styled-components";


export const TipsPane = styled.div`
  background: #E9F3FE;
  border-radius: 5px;
  width: 100%;
  padding: 2em;
  font-family: Work Sans, Arial, sans-serif;
`

const TipsText = styled.div`
  font-family: 'Work Sans', Arial, sans-serif;
  width: 100%;
  margin: 0 auto;
  counter-reset: section;
  padding: 1.2em 1em 0 0;
  max-width: 28rem;
  
  h2 {
    font-family: Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 20px 0;
  }
  
  h3 {
    font-family: Roboto, Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin: 0 0 0.5em;
  }
  
  .prospect {
    font-weight: normal;
    font-size: 16px;
    color: #000021;
    margin: 0 0 36px 0;
  }
  // strong {
  //  //opacity: 0.8;
  //  display: inline-block;
  //  padding: 0 0.8em 0 0.3em;
  //  margin: 0.1em 0;
  //  font-size: 85%;
  //  line-height: 200%;
  //  //margin: 0 -0.3em 0 -0.8em;
  //  background: linear-gradient(104deg,rgba(0,222,209,0) .9%,#00ded1 2.4%,rgba(0,222,209,.5) 5.8%,rgba(0,222,209,.1) 93%,rgba(0,222,209,.7) 96%,rgba(0,222,209,0) 98%),linear-gradient(183deg,rgba(0,222,209,0),rgba(0,222,209,.3) 7.9%,rgba(0,222,209,0) 15%);;
  //}
  ul, ol {
  margin: 0;
  padding-left: 1.2em;
  }
  li {
    line-height: 150%;
    margin-bottom: 0.8em;
  }
  p {
    font-size: 14px;
    line-height: 150%;
    color: #494949;
    margin-top: 0;
  }
`

export default TipsText
