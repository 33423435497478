import React from 'react'
import IntermediateModal from './IntermediateModal'

import { credits } from '../../utils/credits'

export default class RunDiscoveryModal extends React.Component {
  state = { openCredits: false }

  get credits () {
    const { competitorsCount, keywordsCount, creditsLeft } = this.props
    return credits({ competitorsCount, keywordsCount, creditsLeft })
  }
  get needUpgrade () {
    const { creditsLeft } = this.props
    const { currentCost } = this.credits
    return creditsLeft < currentCost
  }

  get heading () {
    const { firstResearchSeen } = this.props
    return !this.needUpgrade ?
      `You are going to ${!!firstResearchSeen ? 're-' : ''}run a search`
      :
      'Uh-oh! You’re short on credits.'
  }

  get button () {
    return !this.needUpgrade ?
      'Run search'
      :
      'Upgrade my account to run more searches'
  }

  get errorMessage () {
    const { competitorsCount } = this.props
    return !competitorsCount ? 'Can\'t run a search without Competitors, please add few.' : null
  }

  render () {
    const {
      open,
      role,
      thinking,
      creditsLeft,
      firstResearchSeen,
      onRun,
      onClose,
      onShowPlans,
      billingProps,
      billingEndTrial
    } = this.props
    const { currentCost } = this.credits

    return (
      <IntermediateModal
        open={open}
        role={role}
        thinking={thinking}
        variant={!!firstResearchSeen ? 'run' : 're-run'}
        currentCost={currentCost}
        creditsLeft={creditsLeft}
        heading={this.heading}
        subHeading={`${!!firstResearchSeen ? 'Running' : 'Re-running'} a search with more inputs makes it more powerful so we find you Leads that are more relevant.`}
        buttonCopy={'Run search'}
        errorMessage={this.errorMessage}
        billingProps={billingProps}
        onAction={onRun}
        onClose={onClose}
        onShowPlans={onShowPlans}
        billingEndTrial={billingEndTrial}
      />
    )
  }
}
