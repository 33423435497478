import { gql } from '@apollo/client'
import { ACCOUNT_USER_FIELDS, BRAND_FIELDS } from './fragments'

export const GET_ACCOUNT_USERS = gql`
  query GetAccountUsers ($brandId: String!){
    accountUsers (brandId: $brandId) {
      ...ACCOUNT_USER_FIELDS
    }
  }
  ${ACCOUNT_USER_FIELDS}
`

export const INVITE_ACCOUNT_USER = gql`
  mutation InviteAccountUser ($InviteUserInput: InviteUserInput!) {
    inviteUser(input: $InviteUserInput) {
      ...ACCOUNT_USER_FIELDS
    }
  }
  ${ACCOUNT_USER_FIELDS}
`

export const RE_INVITE_ACCOUNT_USER = gql`
  mutation ReInviteAccountUser ($id: String!, $brandId: String!) {
    resendInvite(
      id: $id
      brandId: $brandId
    ) {
      ...ACCOUNT_USER_FIELDS
    }
  }
  ${ACCOUNT_USER_FIELDS}
`
