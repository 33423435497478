import React from 'react'
import { inject, observer } from 'mobx-react'
import FoldersMenu from './FoldersMenu'

@inject(({ RelevantFoldersStore, BrandStore }) => ({
  flags: BrandStore.flags,
  onUpdateBrandFlags: details => BrandStore.updateFlags(details),
  folders: RelevantFoldersStore.folders,
  onCreate: (name) => RelevantFoldersStore.create(name)
}))
@observer
class RelevantFolderList extends React.Component {
  constructor (props) {
    super(props)
  }

  onClick = async (folderId, folderName) => {
    if (!folderId) {
      const { flags } = this.props
      const nextFolder = flags.untitledFolders ? flags.untitledFolders += 1 : 1
      const { success, folder } = await this.props.onCreate(`Untitled folder ${nextFolder}`)
      if (success) {
        this.props.onUpdateBrandFlags({ untitledFolders: nextFolder})
        this.props.onClick(folder.id, folder.name)
      }
    } else {
      this.props.onClick(folderId, folderName)
    }
  }

  render () {
    const{ folders, anchorEl } = this.props


    return <FoldersMenu
      anchorEl={anchorEl}
      folders={folders.filter(({ id }) => id !== this.props.folderId)}
      onClose={this.props.onClose}
      onClick={this.onClick}
    />

  }
}

export default RelevantFolderList
