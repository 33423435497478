import React from 'react'
import styled from 'styled-components'
import is from 'is_js'
import { orderBy, uniqBy } from 'lodash'
import { Popper as MuiPopper, Paper as MuiPaper } from '@material-ui/core'
import { ManWithBox } from '../../components/Icons/ManWithBox'
import countriesAll from '../../components/Leads/CountriesFlag'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import MuiInput from '@material-ui/core/Input'
import MuiTextField from '@material-ui/core/TextField'
import MuiSelect from '@material-ui/core/Select'
import MuiMenuItem from '@material-ui/core/MenuItem'
import CountryFlag from '../../components/CountryFlag'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

import Button from '../../components/Button'

import { AVAILABLE_COUNTRIES } from '../../config'

const imageKitUrl = process.env.IMAGEKIT_URL

const countries = [countriesAll[0], ...orderBy(countriesAll.filter(({ value }) => AVAILABLE_COUNTRIES.includes(value)), ['label'], ['asc'])]

const autocompleteClasses = makeStyles({
  root: {
    '& input': {
      fontSize: 20,
      minWidth: `150px!important`,
      padding: '2px 0 8px!important'
    },
    '& .MuiAutocomplete-endAdornment': {
      display: 'none'
    }
  }
})

const Input = withStyles((theme) => ({
  root: {
    minWidth: 100,
    fontSize: 20,
  },
  input: {
    paddingTop: 3,
  }
}))(MuiInput)

const TextField = withStyles((theme) => ({
  root: {
    minWidth: 100,
    fontSize: 20,
  },
  input: {
    paddingTop: 3,
    fontSize: 20,
  }
}))(MuiTextField)

const Select = styled(MuiSelect)`
  ${props => (!props.value || props.value === 'none') && `
    opacity: 0.5;
  `}
  .MuiSelect-selectMenu > img {
    position: relative;
    top: 3px;
  }
`

const MenuItem = styled(MuiMenuItem)`
  ${props => props.disabled && `
    display: none;
  `}
`

const Img = styled.div`
  width: 407px;
  //height: 250px;
  margin: 0 0 35px 0;
`

const Form = styled.form`
  padding: 0;
  margin-top: 2em;
  font-size: 20px;
  font-family: Work Sans, Arial, sans-serif;
`

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${props => props.alignLeft ? 'flex-end' : 'center'} ;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.padding};
  h1 {
    font-family: "GT Super Display", serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 1;
    color: #000021;
    margin: 0 0 32px 0;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #3E3E3E;
    margin: 0;
    max-width: 400px;
    text-align: center;
  }
  button {
    margin: 37px 0 0 0;
  }
  &.imgRight {
    flex-direction: inherit;

    h5, p {
      text-align: left;
    }
    ${Img} {
      margin: 0 0 0 80px;
    }
    .textContainer {
      margin: 0 0 0 80px;
      max-width: 37em;
    }
    @media (max-width: 1100px) {
      ${Img} {
        margin: 0 20px 0 20px;
      }
      .textContainer {
        margin: 0 20px 0 20px;
      }
    }
  }
`

const Wrapper = styled.div`
  display: inline-block;
  white-space: nowrap;
`

const Popover = styled(MuiPopper)`
  z-index: 1000;
`

const Paper = styled(MuiPaper)`
  z-index: 1000;
  max-width: 350px;
  padding: 1.5em;
  margin: 1.5em;
  font-size: 16px;
  font-family: Work Sans, Arial, sans-serif;
  color: #3E3E3E;
  border-radius: 5px;
`

const filter = createFilterOptions();

export const SelectCreatable = ({ value, options, onChange }) => {
  const classes = autocompleteClasses();
  return (
    <Autocomplete
      style={{display: "inline-block"}}
      value={value}
      onChange={(event, newValue) => {
        const { name } = newValue || {}
        onChange({ target: { value: name } })
      }}
      filterOptions={(options, params) => filter(options, params)}
      selectOnFocus
      handleHomeEndKeys
      id="products-select"
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name
      }}
      renderOption={(option) => option.name}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} classes={classes} onChange={onChange} placeholder="product name" />
      )}
    />
  )
}

const InputPopover = ({ anchorEl, placement, content: Content }) => {
  const open = Boolean(anchorEl)
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      placement={placement}
    >
      <Paper elevation={5} rounded>
        <Content />
      </Paper>
    </Popover>
  )
}

Popover.defaultProps = {
  placement: 'left-start'
}


const NameContent = () => <>
  Name the product you want to tailor your search for. If you have multiple products that overlap, you might prefer to group them together under one Product. Eg. Sportswear.
</>

const WebsiteContent = () => <>
  Add the website URL that your customers type to find you.
</>

const CountryContent = () => <>
  <p>Selecting a location will generate more relevant results. If you don’t operate in a specific country, you can select ‘globally.’</p>
</>

class Discovery extends React.Component {
  constructor (props) {
    super(props)
    const { name, country, link } = props
    this.state = { name, country, link, anchorEl: null, kind: null }
  }

  selectRef = null

  onChange = (field) => ({ target: { value } }) => {
    this.setState({ [field]: value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { name, country, link } = this.state
    this.props.onSubmit({ name, country, link })
  }

  onShowPopover = (kind) => ({ currentTarget }) => {
    if (!this.state.kind || this.state.kind !== kind) {

      if (kind === 'country') {
        this.setState({ kind, anchorEl: this.selectRef })
      } else {
        this.setState({ kind, anchorEl: currentTarget })
      }
    }
  }

  onHidePopover = (e) => {
    this.setState({ kind: null, anchorEl: null })
  }

  get header () {
    const { variant, firstName } = this.props

    if (variant=== 'update') {
      return 'Search Details'
    }

    if (variant === 'create') {
      return `New search`
    }

    return `Hi ${firstName}, welcome to Breezy!`
  }

  render () {
    const { thinking, variant, products } = this.props
    const { name, country, link, kind, anchorEl  } = this.state
    const popover = {
      'name': NameContent,
      'country': CountryContent,
      'link': WebsiteContent
    }

    return (
      <Root className="imgRight">
        <InputPopover
          anchorEl={anchorEl}
          content={popover[kind]}
          placement={kind === 'name' ? 'right' : 'left'}
          transition={true}
        />

        <>
          <div className={'textContainer'}>
            <h1>
              {this.header}
            </h1>
            <p>
              Help us tailor your Partner Search to your needs.
            </p>


            <Form noValidate onSubmit={this.onSubmit}  autoComplete='off'>
              I am running this search to find partners who will <Wrapper>promote&nbsp;

              {
                //
                variant === 'create' ? (
                  <SelectCreatable
                    value={name}
                    options={uniqBy(products.filter(({ name }) => Boolean(name)), 'name')}
                    onChange={this.onChange('name')}
                    onFocus={this.onShowPopover('name')}
                    onBlur={this.onHidePopover}
                  />
                ) : (
                  <Input
                    required
                    disabled={thinking}
                    placeholder="product name"
                    margin="none"
                    error={false}
                    value={name}
                    onFocus={this.onShowPopover('name')}
                    onBlur={this.onHidePopover}
                    onChange={this.onChange('name')}
                    autoComplete='off'
                  />
                )
              }

            </Wrapper>
            &nbsp;
            <Wrapper>
              in&nbsp;
              <Select
                ref={node => this.selectRef = node}
                disabled={thinking}
                value={country}
                defaultValue={country || 'none'}
                onChange={this.onChange('country')}
                // onFocus={this.onShowPopover('country')}
                // onBlur={this.onHidePopover}
                onOpen={this.onShowPopover('country')}
                onClose={this.onHidePopover}
                input={<Input  />}
              >
                <MenuItem key="none" disabled value="none">selected country</MenuItem>
                {
                  countries.map(({ labelEmoji, label, value }) => (
                    <MenuItem value={value} key={value}>
                      <CountryFlag
                        country={value}
                        alt={label}
                        fallbackImageURL={`${imageKitUrl}/flags/${value}.svg`}
                      />&nbsp;{label}
                    </MenuItem>
                  ))
                }
              </Select>
            </Wrapper>
            by referring users to my target website&nbsp;
            <Input
              inputProps={{type: 'url'}}
              required
              disabled={thinking}
              placeholder="website URL"
              margin="none"
              error={false}
              value={link}
              onFocus={this.onShowPopover('link')}
              onBlur={this.onHidePopover}
              onChange={this.onChange('link')}
              autoComplete='off'
            />
            <br/><br/>
            {
              variant === 'create' && <>
                <Button
                  color="primary"
                  variant="outlined"
                  mb={2}
                  disabled={thinking}
                  onClick={this.props.onCancel}
                >
                  Cancel
                </Button>
                &nbsp;&nbsp;
              </>
            }
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mb={2}
              disabled={thinking || !name || !country || !is.url(link)}>
              Next
            </Button>
            </Form>

          </div>
          <Img>
            <ManWithBox />
          </Img>
        </>
      </Root>
    )
  }
}

export default Discovery
