import React from 'react'
import { inject, observer } from 'mobx-react'
import Discoveries from './Discoveries'
import { Onboarding, ProductCreate, BrandLevelProductDetails } from '../../components/Product'

@inject(({ SearchesStore, BrandStore, UserStore }) => ({
  loading: SearchesStore.loading,
  products: SearchesStore.products,
  expanded: SearchesStore.expanded,
  order: SearchesStore.order,
  orderBy: SearchesStore.orderBy,
  hidden: SearchesStore.hidden,
  onLoadProducts: () => SearchesStore.load(),
  onUpdateProduct: (id, details) => SearchesStore.updateProduct(id, details),
  onRemoveProduct: (id, index) => SearchesStore.removeProduct(id, index),
  onStartProductResearch: (searchId) => BrandStore.initReRun({searchId, kind: 'products'}),
  onStopPollingResearchStatuses: () => SearchesStore.stopPollingResearchStatuses(),
  onSort: (orderBy) => SearchesStore.sort(orderBy),

  userDetails: UserStore.user,

  brandDetails: BrandStore.details,
  isOnboardingNotCompleted: BrandStore.isCompanyNotCreated,
  assistedLock: BrandStore.assistedLock,
  onCreateBrand: details => BrandStore.create(details),
  onUpdateBrandCounters: () => BrandStore.load(),
  onResearchSeen: () => BrandStore.setFirstResearchSeen(),
  onOpenAssistedLock: () => BrandStore.openAssistedLockModal(),
}))
@observer
export default class _Discoveries extends React.Component {
  componentDidMount () {
    if (!this.props.loading) {
      this.props.onLoadProducts()
    }
  }

  render () {
    return (
      <Discoveries
        {...this.props}
        onboarding={Onboarding}
        createProduct={ProductCreate}
        detailsProduct={BrandLevelProductDetails}
      />
    )
  }
}
