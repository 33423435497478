import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {
  Tooltip,
  ListItem as MuiListItem,
  IconButton as MuiIconButton
} from '@material-ui/core'
import { Tag } from 'react-feather'
import TagMenu from './TagMenu'
import { setCaret } from '../../utils/caret'

const IconButton = styled(MuiIconButton)`
  width: 20px;
  height: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  
  svg {
    opacity: 0.8;
  }
`

const ListItem = styled(MuiListItem)`
  display: flex;
  justify-content: space-between;
  &:hover {
    ${IconButton} {
      display: flex;
    }
  }
`


const Label = styled.strong`
  // display: block;
  // //position: absolute;
  // z-index: 1;
  // left: 0.8em;
  // white-space: nowrap;
  // line-height: 1.8em;
  // height: 1.8em;
  // text-align: left;
  // flex: 1 1 auto;
  // //visibility: hidden;
  
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  ${props => props.edit && css`
     position: relative;
     left: -0.8em;
     margin-right: -1.6em;
     background: white;
     padding: 0 0.8em;
     user-select: auto;
     cursor: text;
     -webkit-user-select: auto !important;
   `}
`

class TagManagementItem extends React.Component {

  root = null

  tagInput = null

  static propTypes = {
    name: PropTypes.string.isRequired,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func
  }

  static defaultProps = {
    onUpdate: undefined,
    onRemove: undefined,
    onClick: console.log
  }

  constructor (props) {
    super(props)
    this.state = {
      edit: false,
      name: props.name || '',
      anchorEl: null
    }
  }

  // componentDidMount () {
  //   if (this.props.create) {
  //     this.tagInput.focus()
  //     this.tagInput.onblur = this.onUpdate
  //   }
  // }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { edit } = this.state

    if (!edit && this.props.name !== prevProps.name) {
      this.setState({ name: this.props.name })
    }
  }

  onOpenMenu = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.setState({ anchorEl: e.currentTarget })
  }

  onCloseMenu = () => this.setState({ anchorEl: null })

  onEdit = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    const { name } = this.props
    setTimeout(() => {
      this.setState({ edit: true, name }, () => {
        this.tagInput.innerText = name
        setCaret(this.tagInput, name ? name.length : 0)
        this.tagInput.onblur = this.onUpdate
      })
    }, 650)
    this.onCloseMenu()
  }

  onUpdate = (e) => {
    const { id } = this.props
    const { name, edit } = this.state
    const { onUpdate } = this.props

    if (edit && e && this.root.contains(e.relatedTarget)) {
      return this.tagInput.focus()
    }

    this.tagInput.onblur = null
    this.setState({ edit: false, name })
    return onUpdate({ id, label: name })
  }

  onDelete = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.props.onDelete(this.props.id)
    this.onCloseMenu()
  }

  onCancel = () => {
    const { name } = this.props
    this.tagInput.onblur = null
    this.setState({ edit: false, name })
  }

  onClick = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    if (!this.state.edit) {
      this.props.onClick({ id: this.props.id, label: this.state.name })
    }
  }

  updateTag (e) {
    switch (e.key) {
      case 'Escape':
        return this.onCancel()
      case 'Enter':
        return this.onUpdate()
      default:
        return this.setState({ name: e.target.innerText })
    }
  }

  render () {
    const {
      variant,
      children,
      focus,
      latest,
      onClick,
      onFocus,
      ...otherProps
    } = this.props
    const { id } = this.props

    const { name, edit, anchorEl } = this.state

    return (
      <ListItem
        autoFocus={focus}
        button
        ref={(node) => { this.root = node }}
        edit={edit}
        onFocus={onFocus}
        onClick={this.onClick}
      >
        <Label
          contentEditable={edit}
          suppressContentEditableWarning
          ref={node => this.tagInput = node}
          spellCheck={false}
          onKeyDown={this.updateTag.bind(this)}
          onInput={this.updateTag.bind(this)}
          edit={edit}>
          { edit ? null : name }
        </Label>

        {
          variant === 'management' && !edit && (
            <Tooltip title={'More'} leaveDelay={0}>
              <IconButton edge="end" aria-label="menu" size={'small'} onClick={this.onOpenMenu}>
                <Tag size={16} />
              </IconButton>
            </Tooltip>
          )
        }

        {
          Boolean(anchorEl) && (
            <TagMenu
              anchorEl={anchorEl}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              onClose={this.onCloseMenu}
            />
          )
        }

      </ListItem>
    )
  }
}

export default TagManagementItem
