import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import Filter from '../../new-components/Filter'
import { ContactsTable } from '../../new-components/List/ContactsTable'
import TagsManagement from '../../new-components/Tags/_TagsManagement'

import Details from '../../components-ts/Details/ContactDetails'
import { SEARCH, TAGS, REQUESTER } from '../../configFilters'

const Container = styled.div`
  width: 100%;
  padding: 0.5em 1em 0;
  height: calc(100vh - 116px);
`

const computeFiltersConfig = () => {
  return [SEARCH, TAGS, REQUESTER]
}

function Contacts(props) {
  const detailsRef = useRef(null)
  const [detailsChannelId, setDetailsChannelId] = useState(null)
  const [tagsManagement, setTagsManagement] = useState(null)
  const { filters, listProps, sortProps } = props
  const { loading, total, items } = listProps

  const onSort = (model) => {
    if (model && model[0]) {
      const { sort: direction, field } = model[0]
      props.onSort(field, direction)
    }
  }

  const onFilters = (filters = {}) => props.onFilters(filters)

  const onOpenContact = ({ row }) => setDetailsChannelId(row.channelId)

  const onCloseContact = () => setDetailsChannelId(null)

  const getChannelTags = (channelId) => {
    const manageChannel = items.filter(
      (item) => item.channelId === channelId
    )[0]

    if (manageChannel) {
      const { tags } = manageChannel
      return tags
    }

    return []
  }

  const getChannelSystemTags = (channelId) => {
    const manageChannel = items.filter(
      (item) => item.channelId === channelId
    )[0]

    if (manageChannel) {
      const { systemTags } = manageChannel
      return systemTags
    }

    return []
  }

  const onCloseTagsManagement = () => {
    setTagsManagement(null)
  }

  const getTagsManagementDetails = (channelId, target) => {
    setTagsManagement({
      anchorEl: target,
      leadId: channelId,
    })
  }

  const onQuickActions = (id, action, domain, e) => {
    if (action === 'toExport') {
      props.onExportToCSV()
    } else {
      const contact = items.filter((item) => item.id === id)[0]
      if (contact && action === 'toManageTags') {
        const { channelId } = contact
        getTagsManagementDetails(channelId, e.target)
        return null
      }
    }
  }

  const onManageTags = ({ channelId, tag, tagId, e }, action) => {
    if (action === 'select') {
      props.onAddTag(channelId, tag)
    } else if (action === 'remove') {
      props.onRemoveTag(channelId, tagId)
    } else if (action === 'update') {
      props.onUpdateTag(tag)
    } else if (action === 'delete') {
      props.onDeleteTag(tagId)
    } else if (action === 'open') {
      getTagsManagementDetails(channelId, e.target)
    }
  }

  useEffect(() => {
    props.load(props.brandId)
  }, [])

  const sortModel = { sort: sortProps.order, field: sortProps.orderBy }

  return (
    <Container>
      <Filter
        disablePopover
        variant={'contacts'}
        placeholder={'Select a filter'}
        filters={filters}
        keys={computeFiltersConfig()}
        onChange={onFilters}
      />

      <ContactsTable
        hideFooter
        total={total}
        loading={loading}
        rows={items.map((item) => ({ ...item, onQuickActions, onManageTags }))}
        sortingOrder={['desc', 'asc']}
        sortModel={[sortModel]}
        onRowsScrollEnd={props.onPageChange}
        onCellClick={onOpenContact}
        onSortModelChange={onSort}
        onManageTags={onManageTags}
        onQuickActions={onQuickActions}
        onColumnResize={console.log}
      />

      {Boolean(tagsManagement) && (
        <TagsManagement
          {...tagsManagement}
          itemTags={getChannelTags(tagsManagement.leadId)}
          onClose={onCloseTagsManagement}
          onSelectTag={(tag) =>
            onManageTags({ channelId: tagsManagement.leadId, tag }, 'select')
          }
          onUpdateTag={(tag) => onManageTags({ tag }, 'update')}
          onRemoveTag={(tagId, channelId) =>
            onManageTags({ channelId, tagId }, 'remove')
          }
          onDeleteTag={(tagId) => onManageTags({ tagId }, 'delete')}
        />
      )}

      <Details
        ref={detailsRef}
        open={Boolean(detailsChannelId)}
        brandId={props.brandId}
        channelId={detailsChannelId}
        tags={getChannelTags(detailsChannelId)}
        systemTags={getChannelSystemTags(detailsChannelId)}
        onClose={onCloseContact}
        onManageTags={onManageTags}
      />
    </Container>
  )
}

export default inject(({ ContactInfosStore }) => ({
  filters: ContactInfosStore.filters,
  listProps: ContactInfosStore.listProps,
  sortProps: ContactInfosStore.sortProps,
  load: (brandId) => ContactInfosStore.load(brandId),
  onAddTag: (channelId, tag) => ContactInfosStore.addTags(channelId, tag),
  onUpdateTag: (tag) => ContactInfosStore.updateTags(tag),
  onRemoveTag: (channelId, tagId) =>
    ContactInfosStore.removeTags(channelId, tagId),
  onDeleteTag: (tagId) => ContactInfosStore.deleteTags(tagId),
  onSort: (direction, sort) => ContactInfosStore.sorting(direction, sort),
  onFilters: (filters) => ContactInfosStore.filtersChange(filters),
  onPageChange: (page) => ContactInfosStore.pageChange(page + 1),
  onPerPageChange: (rows) => ContactInfosStore.perPageChange(rows),

  onExportToCSV: () => ContactInfosStore.exportToCSV(),
}))(observer(Contacts))
