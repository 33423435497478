import { gql } from '@apollo/client'
import {
  BATCH_RESULT_FIELDS,
  CONTACT_FIELDS,
  CONTACT_REQUEST_FIELDS,
} from './fragments'

export const GET_CONTACT_REQUESTS = gql`
  query ContactRequests(
    $brandId: String!
    $take: Int
    $skip: Int
    $search: String
    $orderBy: String
    $requesterId: String
  ) {
    contactRequestsConnection(
      brandId: $brandId
      take: $take
      skip: $skip
      search: $search
      orderBy: $orderBy
      requesterId: $requesterId
    ) {
      edges {
        node {
          ...CONTACT_REQUEST_FIELDS
        }
      }
      totalCount
    }
  }
  ${CONTACT_REQUEST_FIELDS}
`

export const GET_CONTACTS = gql`
  query Contacts(
    $brandId: String!
    $search: String
    $orderBy: String
    $tagIds: [String!]
    $requesterId: String
  ) {
    contacts(
      brandId: $brandId
      search: $search
      orderBy: $orderBy
      tagIds: $tagIds
      requesterId: $requesterId
    ) {
      contact {
        ...CONTACT_FIELDS
      }
      channels {
        channel {
          id
          icon
          domain
          title
        }
        requester {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
  ${CONTACT_FIELDS}
`

export const GET_CONTACTS_STATUS = gql`
  query GetContactsStatus($brandId: String!, $channelIds: [String!]!) {
    contactRequests(brandId: $brandId, channelIds: $channelIds) {
      channelId
      status
    }
  }
`

export const GET_CONTACT = gql`
  query Contact($brandId: String!, $channelId: String!) {
    contact(brandId: $brandId, channelId: $channelId) {
      ...CONTACT_FIELDS
    }
  }
  ${CONTACT_FIELDS}
`

export const REQUEST_CONTACTS = gql`
  mutation RequestContacts($RequestContactsInput: RequestContactsInput!) {
    requestContacts(input: $RequestContactsInput) {
      ...BATCH_RESULT_FIELDS
    }
  }
  ${BATCH_RESULT_FIELDS}
`
