import { gql } from '@apollo/client'
import { LEAD_FIELDS, SEARCH_FIELDS } from './fragments'

export const GET_LEADS = gql`
  query GetLeads(
    $searchId: String!
    $list: String
    $orderBy: String
    $take: Int
    $skip: Int
    $search: String
    $lang: String
    $countries: [String!]
    $tagIds: [String!]
    $type1Classification: String
    $breezyScoreEnd: Int
    $breezyScoreStart: Int
    $competitors: [String!]
    $competitorSeedsCountStart: Int
    $competitorSeedsCountEnd: Int
    $keywordSeedsCountStart: Int
    $keywordSeedsCountEnd: Int
    $mozDomainAuthorityEnd: Int
    $mozDomainAuthorityStart: Int
    $partnershipsOutCountStart: Int
    $partnershipsOutCountEnd: Int
    $swGlobalRankEnd: Int
    $swGlobalRankStart: Int
    $swVisitsEnd: Float
    $swVisitsStart: Float
    $withoutRelevants: Boolean
    $withoutPartners: Boolean
    $manualClassifications: [ClassificationByLevelInput!]
  ) {
    leadsConnection(
      searchId: $searchId
      list: $list
      orderBy: $orderBy
      take: $take
      skip: $skip
      search: $search
      lang: $lang
      countries: $countries
      tagIds: $tagIds
      type1Classification: $type1Classification
      breezyScoreEnd: $breezyScoreEnd
      breezyScoreStart: $breezyScoreStart
      competitors: $competitors
      competitorSeedsCountStart: $competitorSeedsCountStart
      competitorSeedsCountEnd: $competitorSeedsCountEnd
      keywordSeedsCountStart: $keywordSeedsCountStart
      keywordSeedsCountEnd: $keywordSeedsCountEnd
      mozDomainAuthorityEnd: $mozDomainAuthorityEnd
      mozDomainAuthorityStart: $mozDomainAuthorityStart
      partnershipsOutCountStart: $partnershipsOutCountStart
      partnershipsOutCountEnd: $partnershipsOutCountEnd
      swGlobalRankEnd: $swGlobalRankEnd
      swGlobalRankStart: $swGlobalRankStart
      swVisitsEnd: $swVisitsEnd
      swVisitsStart: $swVisitsStart
      withoutRelevants: $withoutRelevants
      withoutPartners: $withoutPartners
      manualClassifications: $manualClassifications
    ) {
      edges {
        node {
          ...LEAD_FIELDS
        }
      }
      totalCount
    }
  }
  ${LEAD_FIELDS}
`

export const GET_LEAD_IDS = gql`
  query GetLeads(
    $searchId: String!
    $list: String
    $orderBy: String
    $take: Int
    $skip: Int
    $search: String
    $lang: String
    $countries: [String!]
    $tagIds: [String!]
    $type1Classification: String
    $breezyScoreEnd: Int
    $breezyScoreStart: Int
    $competitors: [String!]
    $competitorSeedsCountStart: Int
    $competitorSeedsCountEnd: Int
    $keywordSeedsCountStart: Int
    $keywordSeedsCountEnd: Int
    $mozDomainAuthorityEnd: Int
    $mozDomainAuthorityStart: Int
    $partnershipsOutCountStart: Int
    $partnershipsOutCountEnd: Int
    $swGlobalRankEnd: Int
    $swGlobalRankStart: Int
    $swVisitsEnd: Float
    $swVisitsStart: Float
    $withoutRelevants: Boolean
    $withoutPartners: Boolean
  ) {
    leadsConnection(
      searchId: $searchId
      list: $list
      orderBy: $orderBy
      take: $take
      skip: $skip
      search: $search
      lang: $lang
      countries: $countries
      tagIds: $tagIds
      type1Classification: $type1Classification
      breezyScoreEnd: $breezyScoreEnd
      breezyScoreStart: $breezyScoreStart
      competitors: $competitors
      competitorSeedsCountStart: $competitorSeedsCountStart
      competitorSeedsCountEnd: $competitorSeedsCountEnd
      keywordSeedsCountStart: $keywordSeedsCountStart
      keywordSeedsCountEnd: $keywordSeedsCountEnd
      mozDomainAuthorityEnd: $mozDomainAuthorityEnd
      mozDomainAuthorityStart: $mozDomainAuthorityStart
      partnershipsOutCountStart: $partnershipsOutCountStart
      partnershipsOutCountEnd: $partnershipsOutCountEnd
      swGlobalRankEnd: $swGlobalRankEnd
      swGlobalRankStart: $swGlobalRankStart
      swVisitsEnd: $swVisitsEnd
      swVisitsStart: $swVisitsStart
      withoutRelevants: $withoutRelevants
      withoutPartners: $withoutPartners
    ) {
      edges {
        node {
          leadId
          domain
          channel {
            id
          }
        }
      }
    }
  }
`

export const MARK_LEADS_AS_RELEVANTS = gql`
  mutation MarkLeadsAsRelevants($leadIds: [String!]!, $searchId: String!) {
    markLeadsAsRelevants(leadIds: $leadIds, searchId: $searchId) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const UNMARK_LEADS_AS_RELEVANTS = gql`
  mutation UnmarkLeadsAsRelevants($leadIds: [String!]!, $searchId: String!) {
    unmarkLeadsAsRelevants(leadIds: $leadIds, searchId: $searchId) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const MARK_LEADS_AS_PARTNERS = gql`
  mutation MarkLeadsAsPartners($leadIds: [String!]!, $searchId: String!) {
    markLeadsAsPartners(leadIds: $leadIds, searchId: $searchId) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const UNMARK_LEADS_AS_PARTNERS = gql`
  mutation UnmarkLeadsAsPartners($leadIds: [String!]!, $searchId: String!) {
    unmarkLeadsAsPartners(leadIds: $leadIds, searchId: $searchId) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const MOVE_LEADS_TO_INBOX = gql`
  mutation MoveLeadsToInbox($leadIds: [String!]!, $searchId: String!) {
    moveLeadsToInbox(leadIds: $leadIds, searchId: $searchId) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const ADD_LEADS_TO_COMPETITORS = gql`
  mutation AddLeadsToCompetitors(
    $leadIds: [String!]!
    $links: [String!]
    $searchId: String!
  ) {
    addLeadsToCompetitors(
      leadIds: $leadIds
      links: $links
      searchId: $searchId
    ) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const ADD_LEADS_TO_IRRELEVANTS = gql`
  mutation AddLeadsToIrrelevants(
    $leadIds: [String!]!
    $links: [String!]
    $searchId: String!
  ) {
    addLeadsToIrrelevants(
      leadIds: $leadIds
      links: $links
      searchId: $searchId
    ) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`
