import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import {
  Popper,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Divider,
  Typography,
  IconButton
} from '@material-ui/core'
import TextAvatar from 'react-avatar'
import {LogOut as LogOutIcon} from 'react-feather'
import config from '../../config'

const AvatarHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  //& > div > div {
  //  box-shadow: 0 0 0 3px transparent;
  //  transition: box-shadow .3s ease;
  //
  //  &:hover {
  //    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.04);
  //  }
  //}
`;



const ButtonAvatar = styled(IconButton)`
  position: relative;
  margin-right: -0.4em;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const ButtonAvatarBrand = styled(IconButton)`
  border-radius: 5px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopperMain = styled(Popper)`
  .MuiList-padding {
    padding: 0;
  }
  .MuiMenuItem-root {
   padding: 10px 7px;
   &:first-child {
   border-bottom: 1px solid #e7ebf5;
   }
  }
`;

const UserName = styled(Typography)`
  //font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin: 0 0 0 8px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  position: relative;
  svg {
    position: absolute;
    font-size: 18px;
    right: 0;
    top: 50%;
    margin: -9px 0 0 0;
    opacity: 0.8;
  }
  .name {
    padding: 0 17px 0 0;
  }
  .email, .name {
   display: block;
   width: 100%;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
  }
  .email {
    font-weight: normal;
  }
`;

const Logout = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.palette.text.primary};
  padding-left: 1.4em;
  svg {
    font-size: 18px;
    margin: 0 0.55em 0 0.3em;
    opacity: 0.8;
    color: ${props => props.theme.palette.text.primary};
  }
`;

@withRouter
class BrandMenu extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      open: props.open,
    }
  }

  handleChange = ({ currentTarget }) => {
    const {
      open
    } = this.state
    this.setState({open: !open, anchorEl: currentTarget })
  }

  handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ open: !open, anchorEl: null })
    }
  }

  onOpenSettings = () => {
    this.props.onOpenSettings()
    this.setState({ open: !open, anchorEl: null })
  }

  onChangeBrand = (id) => () => {
    if (this.props.details.id !== id) {
      this.props.onClearBrand()
      this.props.history.push({ pathname: `/brand/${id}`})
    }
    this.setState({ open: !open, anchorEl: null })
  }

  render() {

    const {
      open,
      anchorEl
    } = this.state

    const {
      role,
      firstName,
      fullName,
      email,
      details,
      brands,
      onLogout,
      compact
    } = this.props

    return (
      <>
        <AvatarHolder onClick={this.handleChange}>
          <ButtonAvatar size={'smal'}>
            <TextAvatar
              name={fullName}
              textSizeRatio={2}
              fgColor={'black'}
              colors={config.avatarColors}
              size={28}
              round={28}
              variant="circle"/>
          </ButtonAvatar>
          <ButtonAvatarBrand>
            {
              role !== 'creator' && (
                <TextAvatar
                  name={details.name}
                  textSizeRatio={2}
                  fgColor={'black'}
                  colors={config.avatarColors}
                  size={28}
                  round={5}
                  variant="rounded"/>
              )
            }
          </ButtonAvatarBrand>
        </AvatarHolder>
        <PopperMain open={open}
                    container={document.body}
                    placement={'top-start'}
                    style={{
                      width: '350px',
                      zIndex: '1299'
                    }}
                    anchorEl={anchorEl}
                    transition>
          <Paper elevation={10} style={{borderRadius: '10px'}} >
            <ClickAwayListener onClickAway={this.handleChange}>
              <MenuList autoFocusItem={open} onKeyDown={this.handleListKeyDown}>
                <MenuItem onClick={this.onOpenSettings} style={{padding: '1.5em 0.8em'}} selected={false}>
                  <AvatarHolder onClick={this.onOpenSettings}>
                    <TextAvatar
                      colors={config.avatarColors}
                      fgColor={'black'}
                      name={fullName}
                      textSizeRatio={1.8}
                      size={32}
                      round={32}
                      variant="rounded"/>
                    <UserName>
                      <span className={'name'} style={{fontSize: 18}}>{firstName}</span>
                      <span className={'email'} style={{fontSize: 12, paddingTop: '0.5em'}}>{email}</span>
                    </UserName>
                  </AvatarHolder>
                </MenuItem>

                {
                  role !== 'creator' && (
                    <>
                      <Divider />
                      <div style={{fontSize: 12, padding: '0.5em 0 0.5em 2.3em', fontWeight: 'bold'}}>Your brands:</div>
                      <div style={{maxHeight: 300, height: '100vh', overflowY: 'auto'}}>


                        {
                          brands.map(brand => {
                            const { id, name } = brand
                            return (
                              <MenuItem onClick={this.onChangeBrand(id)} style={{padding: '0.8em'}} selected={details.id === id}>
                                <AvatarHolder onClick={this.onChangeBrand(id)}>
                                  <TextAvatar
                                    name={name}
                                    fgColor={'black'}
                                    textSizeRatio={1.8}
                                    colors={config.avatarColors}
                                    size={32}
                                    round={5}
                                    variant="rounded"/>
                                  <UserName>
                                    <span className={'name'}>{name}</span>
                                  </UserName>
                                </AvatarHolder>
                              </MenuItem>
                            )
                          })
                        }

                      </div>
                    </>
                  )
                }


                <Divider />
                <MenuItem onClick={() => onLogout()} style={{padding: '1.5em 0.8em'}}>
                  <Logout>
                    <LogOutIcon/>
                      Logout from Breezy
                  </Logout>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </PopperMain>
      </>
    );
  }
}

export default BrandMenu
