import React from 'react'
import styled from 'styled-components'
import {
  Typography,
  TableContainer,
  Table,
  TableHead as TableHeadMUI,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel
} from '@material-ui/core'
import {CountriesFlag} from './CountriesFlag'
import { find, orderBy } from 'lodash'
import Pane from '../Details/Pane'
import {formatNumber} from '../../utils/format'
import CountryFlag from '../../components/CountryFlag'
import ShowAll from '../ShowAll'

const imageKitUrl = process.env.IMAGEKIT_URL

const TableCellBody = styled(TableCell)`
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 5px 15px;
  height: 25px;
`;

const TableCellHead = styled(TableCell)`
  font-size: 12px;
  line-height: 12px;
  border: none;
  padding: 5px 15px;
  font-weight: normal;
  background: #E9F3FE;
  &:first-child{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  & .MuiTableSortLabel-root {
    opacity: 0.5;
    font-weight: normal;
  }
`;

const TableHead = styled(TableHeadMUI)`
  margin-bottom: 1em;
`

const Country = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  span {
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
  }
`;

const SORTABLE_COLUMNS = [
  { key: 'country', label: 'Country', align: 'left' },
  { key: 'pageViews', label: 'Page views', align: 'left' },
  { key: 'swRank', label: 'Similarweb Rank', align: 'left' },
  { key: 'users', label: 'Users', align: 'right' }
]

class Countries extends React.Component {
  static defaultProps = {
    data: [],
    minRows: 5
  }

  state = {
    orderBy: 'users',
    order: 'desc',
    collapsed: true
  }

  flagCountry = (iso) => {
    let label = 'Unknown country'
    let countryData = find(CountriesFlag, ['value', iso])
    if (countryData) {
      label = countryData.label
    }
    return (
      <Country>
        <CountryFlag
          country={iso}
          alt={label}
          fallbackImageURL={`${imageKitUrl}/flags/${iso}.svg`}
        />
        {label}
      </Country>
    )

  }

  onSort = (orderBy) => (e) => {
    if (this.state.orderBy === orderBy) {
      this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc'})
    } else {
      this.setState({ orderBy, order: 'desc' })
    }
  }

  onToggleCollapsed = () => this.setState({ collapsed: !this.state.collapsed })

  get countries () {
    const { data } = this.props
    const countries = data.map(({iso, ...rest}) => {
      const country = find(CountriesFlag, ['value', iso])
      if (country) {
        return { country: country.label, iso, ...rest}
      }
      return {iso, ...rest}
    })

    return orderBy((countries || []), [this.state.orderBy], [this.state.order])
  }

  get slicedList () {
    const { minRows } = this.props
    const { collapsed } = this.state
    const list = this.countries

    return collapsed ? list.slice(0, minRows) : list
  }

  render() {
    const {
      data,
      minRows
    } = this.props
    const { orderBy, order, collapsed } = this.state
    const listLength = this.countries.length

    return (
      <Pane empty={data.length === 0} emptyMessage={'No GEO data is available'}>
        <Table aria-label="Countries table">
          <TableHead>
            <TableRow>
              {/*<TableCellHead>Country</TableCellHead>*/}
              {
                SORTABLE_COLUMNS.map(({ key, label, align }) => (
                  <TableCellHead align={align}>
                    <TableSortLabel
                      active={orderBy === key}
                      direction={orderBy === key ? order : 'asc'}
                      onClick={this.onSort(key)}
                    >
                      { label }
                    </TableSortLabel>
                  </TableCellHead>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.slicedList.map((country) => (
                <TableRow key={country.iso}>
                  <TableCellBody>{this.flagCountry(country.iso)}</TableCellBody>
                  <TableCellBody>{country.pageViews}%</TableCellBody>
                  <TableCellBody>{formatNumber(country.swRank)}</TableCellBody>
                  <TableCellBody align={'right'}>{country.users}%</TableCellBody>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
            <ShowAll
              more={listLength > minRows}
              amount={minRows}
              total={this.countries.length}
              collapsed={collapsed}
              onClick={this.onToggleCollapsed}
            />
      </Pane>
    );
  }
}

export default Countries
