import React from 'react'
import { SimilarWebIcon } from './components/Icons/SimilarWebIcon'
import { SimilarWeb } from './components/Icons/SimilarWeb'
import { MozLogoIcon } from './components/Icons/Moz_logo'

export interface PoweredByProps {
  href: string
  content: React.ReactElement | string
}
export type Size = '1x1' | '1x2' | '2x1' | '2x2'
// These keys are used for Metric variants
export type MetricKeysBase =
  | 'promotes'
  | 'promotedBy'
  | 'backlinks'
  | 'backlinksTotal'
  | 'keywords'
  | 'keywordsTotal'
  | 'rank'
  | 'moz'
  | 'ecosystemShare'
// These are all keys in the metrics config
export type MetricKeys =
  | MetricKeysBase
  | 'visits'
  | 'score'
  | 'avgScore'
  | 'networks'
  | 'keywordsHighest'
  | 'keywordsHighestAll'
  | 'appearsIn'
  | 'added'
export interface MetricProps {
  shortName: React.ReactElement | string // Metric name
  heading?: React.ReactElement | string // Table heading
  unit?: string // Unit to diplsy im metric, e.g.: 5 Keywords
  unitShorthand?: string // Table unit to display inline with the numebr e.g.: 5kw
  poweredBy?: PoweredByProps
  title: string // Tooltip title
  content: string // Tooltip content
  target?: string
  anchor?: string
}

// TODO: Refactor make icon color universal
export const poweredBY: { [key: string]: PoweredByProps } = {
  moz: {
    href: 'https://moz.com/learn/seo/domain-authority',
    content: (
      <>
        Powered by&nbsp;&nbsp;
        <MozLogoIcon color='white' />
      </>
    ),
  },
  similarWeb: {
    href: 'https://similarweb.com',
    content: (
      <>
        Insights by&nbsp;&nbsp;
        <SimilarWeb color='white' />
      </>
    ),
  },
}

export const metrics: { [key: string]: MetricProps } = {
  score: {
    shortName: 'Score',
    heading: 'Score',
    title: 'Breezy Relevancy Score',
    content:
      'is a measure of how relevant we think a Lead is to your search. Higher is better.',
    target: 'profile',
  },
  avgScore: {
    shortName: 'Avg. Score',
    heading: 'Avg. Score',
    title: 'Average Breezy Relevancy Score',
    content:
      'is a measure of how relevant we think a Lead is to your searches. Higher is better.',
    target: 'profile',
  },
  moz: {
    shortName: <MozLogoIcon />,
    heading: <MozLogoIcon />,
    title: 'MOZ Domain Authority',
    content:
      'is a score developed by Moz that predicts how likely a website is to rank highly in search engine results pages (SERPs). Higher is better.',
    poweredBy: poweredBY.moz,
    target: 'profile',
  },
  rank: {
    shortName: (
      <>
        <SimilarWebIcon size={0.9} />
        &nbsp;Rank
      </>
    ),
    heading: (
      <>
        <SimilarWebIcon size={0.9} />
        &nbsp;Rank
      </>
    ),
    title: 'Similarweb Global Rank',
    content:
      "is calculated based on a mix of our estimations of a site's monthly unique visitors and monthly pageviews across desktop and mobile web traffic. Lower is better.",
    poweredBy: poweredBY.similarWeb,
    target: 'profile',
  },
  visits: {
    shortName: (
      <>
        <SimilarWebIcon size={0.9} />
        &nbsp;Visits
      </>
    ),
    heading: (
      <>
        <SimilarWebIcon size={0.9} />
        &nbsp;Visits
      </>
    ),
    title: 'Similarweb Visits',
    content:
      'is the sum of all visits to a website and is used as a measurement of website traffic and website visitors. Higher is better.',
    poweredBy: poweredBY.similarWeb,
    target: 'profile',
  },
  keywords: {
    shortName: 'Ranks for',
    heading: 'Keywords',
    unit: 'Keywords',
    unitShorthand: 'kw',
    title: 'Ranks for Keywords',
    content:
      'shows how many keywords a Lead shares with those you input to run your search. We count keywords for which the Lead appears in the top 100 organic positions on Google at the time your search is run. Higher is better.',
    target: 'keywords',
  },
  keywordsTotal: {
    shortName: 'Globally Ranks for',
    heading: 'Keywords',
    unit: 'Keywords',
    unitShorthand: 'kw',
    title: 'Globally Ranks for Keywords',
    content:
      'shows how many keywords a Lead shares with those you input to run all of your searches. We count keywords for which the Lead appears in the top 100 organic positions on Google at the time your search is run. Higher is better.',
    target: 'keywords',
  },
  backlinks: {
    shortName: 'Links to',
    heading: 'Comps.',
    unit: 'Competitors',
    unitShorthand: 'cp',
    title: 'Links to Competitors',
    content:
      'shows how many of the Competitors you input to run your search are receiving a backlink from a Lead. Opening the sidebar shows which ones they are. Higher is better.',
    target: 'competitors',
  },
  backlinksTotal: {
    shortName: 'Total Links to',
    heading: 'Comps.',
    unit: 'Competitors',
    unitShorthand: 'cp',
    title: 'Total Links to Competitors',
    content:
      'shows how many of the Competitors you input to run your search are receiving a backlink from a Lead. Opening the sidebar shows which ones they are. Higher is better.',
    target: 'competitors',
  },
  promotes: {
    shortName: 'Promotes',
    heading: 'Promotes',
    title: 'Promotes Brands',
    unit: 'Brands',
    content: 'shows the number of Brands which are endorsed by this creator.',
    target: 'profile',
  },
  promotedBy: {
    shortName: 'Promoted by',
    heading: 'Promoted by',
    title: 'Promoted by Creators',
    unit: 'Creators',
    content:
      'shows the number of Creators endorsing this brand. Includes affiliate partners compensated for resulting sales or conversions.',
    target: 'profile',
  },
  networks: {
    shortName: 'Active on Networks',
    heading: 'Active on Networks',
    title: 'Active on Networks',
    content: 'shows major networks where subject is active.',
    target: 'profile',
    anchor: 'networks-used',
  },
  keywordsHighest: {
    shortName: 'Highest Ranking Keywords',
    heading: 'Highest Ranking Keywords',
    title: 'Highest Ranking Keywords',
    content:
      'shows top 3 matched keywords from current search ordered by position in Google Search.',
  },
  keywordsHighestAll: {
    shortName: 'Highest Ranking Keywords',
    heading: 'Highest Ranking Keywords',
    title: 'Highest Ranking Keywords',
    content:
      'shows top 3 matched keywords from all searches ordered by position in Google Search.',
  },
  appearsIn: {
    shortName: 'Appears in',
    heading: 'Appears in',
    title: 'Appears in',
    content: 'shows searches and ecosystems where subject can be found.',
  },
  added: {
    shortName: 'Added',
    heading: 'Added',
    title: 'Added in',
    content: 'shows when prospect was added.',
  },
  ecosystemShare: {
    shortName: 'Ecosystem share',
    heading: 'Ecosystem share',
    unit: '%',
    title: 'Ecosystem share',
    content:
      "shows Brand's Creator partnerships within our network. A high share means better promotion and integration within our ecosystem.",
  },
}

export default metrics
