import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { gql, useLazyQuery } from '@apollo/client'
import { observer, inject } from 'mobx-react'

import EcosystemsSelect from './EcosystemsSelect'
import EcosystemMetrics from './EcosystemMetrics'
import { HeaderContainer } from './EcosysctemStyledComponents'

const POTENTIAL_LEADS_COUNT = gql`
  query potentialLeadsCount ($ecosystemId: String!, $channelIds: [String!]!) {
    potentialLeadsCount(
      ecosystemId: $ecosystemId
      channelIds: $channelIds
    )
  }
`

const ECOSYSTEM_DETAILS = gql`
  query ecosystem ($ecosystemId: String!, $search: String) {
    ecosystem(id: $ecosystemId) {
      id
      channelsCount
      channels(
        take: 1000
        search: $search
      ) {
        title
        icon
        domain
        id
        partnershipsInCount
      }
      name
      inPartnershipsCount
      affiliatePartnersCount
    }
  }
`

export const EcosystemHeader = inject(({ IntelligenceStore, EcosystemStore }) => ({
  selected: IntelligenceStore.selected,
  ecosystems: IntelligenceStore.ecosystems,
  ecosystemId: IntelligenceStore.ecosystemId,
  setSelected: (details) => IntelligenceStore.setSelected(details),

  ecosystem: EcosystemStore.ecosystem,
  primary: EcosystemStore.primary,
  untapped: EcosystemStore.untapped,
  setUntapped: (value) => EcosystemStore.setUntapped(value),
  setEcosystem: (details, resetFilters) => EcosystemStore.setEcosystem(details, resetFilters),
  updateQueries: () => EcosystemStore.updateQueries(),
  getQueriesFromSearch: () => EcosystemStore.getQueriesFromSearch()
}))(observer(({ kind, ...props }) => {
  const { selected, ecosystems, ecosystemId, untapped } = props
  const { ecosystem, primary } = props
  const [currentEcosystem, setCurrentEcosystem] = useState(props.ecosystemId)
  const [getEcosystemDetails] = useLazyQuery(ECOSYSTEM_DETAILS)
  const [getPotentialLeadsCount] = useLazyQuery(POTENTIAL_LEADS_COUNT)

  const fetchData = async (id, resetFilters) => {
    const { data, error } = await getEcosystemDetails({ variables: { ecosystemId: id }})
    if (!error) {
      props.setEcosystem(data.ecosystem, resetFilters)
    } else {
      props.onError()
    }
  }

  const fetchPotentialLeadsCount = async () => {
    const { data, error } = await getPotentialLeadsCount({
      variables: {
        ecosystemId,
        channelIds: primary.map(({ id }) => id)
      }
    })
    if (error) {
      props.onError()
    }
    return Boolean(error) ? 0 : data['potentialLeadsCount']
  }

  useEffect(() => {
    if (ecosystemId !== currentEcosystem) {
      setCurrentEcosystem(ecosystemId)
      fetchData(ecosystemId, true).then()
    }
  }, [ecosystemId, currentEcosystem])

  useEffect(() => {
    setCurrentEcosystem(ecosystemId)
    fetchData(ecosystemId).then()
  }, [])

  useEffect(() => {
    if (!props.getQueriesFromSearch()) {
      props.updateQueries()
    }
  }, [kind])

  useEffect(() => {
    if (primary && primary.length) {
      fetchPotentialLeadsCount().then(result => props.setUntapped(result))
    } else {
      props.setUntapped(0)
    }
  }, [primary])

  const metrics = {
    ...ecosystem,
    ...(untapped && { untapped })
  }

  return (
    <HeaderContainer>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{flex: '1 1 auto', overflow: 'hidden'}}>
          <EcosystemsSelect ecosystems={ecosystems} onSetSelected={props.setSelected} selected={selected}/>
        </Box>
        <Box sx={{flex: '1 1 auto'}}>
          <EcosystemMetrics ecosystem={metrics}/>
        </Box>
      </Box>
    </HeaderContainer>
  )
}))
