import { formatDateFull, formatDateTime } from './format'
import moment from 'moment'
import Config from '../config'

const S3_STORAGE_URL = process.env.S3_STORAGE_URL

const getSWRank = (value) =>
  value === undefined || value >= 1000000000 ? 'N/A' : value

export const getPicturePath = (domain, fileName) => {
  if (!fileName) return undefined
  if (fileName === 'missing') return fileName

  return `${S3_STORAGE_URL}/${domain}/${fileName}`
}

const NAME_FIELDS = ['firstName', 'lastName']
const MULTIPLE_FIELDS = ['email', 'companyEmail', 'phone']
const SOCIAL_FIELDS = ['linkedin', 'instagram', 'twitter', 'facebook']

export const convertItems = (item) => {
  let result = {}
  let name = ''
  let contacts = {
    emails: [],
    phones: [],
    companyEmails: [],
  }
  let social = []

  item.map(({ kind, data }) => {
    if (NAME_FIELDS.includes(kind)) {
      result[kind] = data
      name += ` ${data}`
    } else if (MULTIPLE_FIELDS.includes(kind)) {
      contacts[`${kind}s`].push(data)
    } else if (SOCIAL_FIELDS.includes(kind)) {
      social.push({
        name: kind,
        url: data,
      })
    } else {
      result[kind] = data
    }
  })

  return {
    ...result,
    ...contacts,
    social,
    name: name.trim(),
  }
}

export const convertItemToDetails = (item) => {
  if (!item.social) return item

  let social = {}
  item.social.map(({ name, url }) => (social[name] = url))

  return { ...item, ...social }
}

export const creatorRequestToListItem = (request) => {
  const { brandChannel, contactRequest } = request
  const {
    id,
    outreachMessage,
    brand: { name: brandName, link },
    createdAt,
    status,
  } = contactRequest
  const {
    id: channelId,
    domain,
    icon,
    title,
    ...restChannel
  } = brandChannel || {}

  return {
    id,
    brandName,
    channelId,
    url: domain || link,
    title: title || brandName,
    icon: getPicturePath(domain, icon),
    message: outreachMessage,
    date: createdAt,
    status,
    ...restChannel,
  }
}

export const prospectToListItem = (prospect) => {
  if (!prospect) return null
  const { channel, tags, domain, contactStatus, folder, ...restProspect } =
    prospect
  if (!channel) return null
  const { id: channelId, swGlobalRank, icon, ...restChannel } = channel
  const folderName = (folder || {}).name

  return {
    channelId,
    url: domain,
    swRank: getSWRank(swGlobalRank),
    tags: tags || [],
    folderName,
    contactStatus,
    icon: getPicturePath(domain, icon),

    ...restProspect,
    ...restChannel,
  }
}

export const leadToListItem = (lead) => {
  const { leadId, channel, tags, domain, contactStatus, ...restLead } = lead
  const { id: channelId, swGlobalRank, icon, ...restChannel } = channel

  const relevant = lead.relevant && lead.internalRelevant
  const overlapProspect = lead.relevant && !lead.internalRelevant

  return {
    id: leadId,
    leadId,
    channelId,
    url: domain,
    swRank: getSWRank(swGlobalRank),
    tags: tags || [],
    contactStatus,
    icon: getPicturePath(domain, icon),

    ...restLead,
    ...restChannel,

    relevant,
    overlapProspect,
  }
}

export const contactToListItems = (item) => {
  const { contact, channels } = item

  return channels.map((channelItem) => {
    const { channel, requester } = channelItem
    return {
      ...contact,
      channel,
      channelId: channel.id,
      id: `${contact.id}-${channel.id}`,
      requester,
    }
  })
}

export const contactList = (items) => {
  let result = []

  items.map((item) => {
    result = [...result, ...contactToListItems(item)]
  })

  return result
}

export const contactToListItem = (contact) => {
  const {
    id,
    channel,
    channelId,
    folderName,
    contactData,
    tags,
    systemTags,
    requester,
    createdAt,
    updatedAt,
  } = contact
  const { icon, domain, title, type1Classification } = channel
  const { firstName, lastName, email } = requester || {}

  return {
    id,
    channelId,
    folderName,
    domain,
    title,
    type1Classification,
    tags: tags || [],
    systemTags: systemTags || [],
    icon,
    ...convertItems(contactData),
    user: fullName(firstName, lastName, email),
    createdAt: formatDateFull(createdAt),
    updatedAt: formatDateFull(updatedAt),
    createdAtShort: moment(createdAt).format(Config.DATE_FORMAT),
    updatedAtShort: moment(updatedAt).format(Config.DATE_FORMAT),
  }
}

const fullName = (firstName, lastName, email) => {
  if (!firstName && !lastName) return email

  return `${firstName}${
    Boolean(lastName) ? `${Boolean(firstName) ? ' ' : ''}${lastName}` : ''
  }`
}

export const contactRequestToListItem = (contact) => {
  const { id, channel, user, channelId, tags, systemTags, createdAt, status } =
    contact
  const { icon, domain, title, type1Classification } = channel
  const { firstName, lastName, email } = user || {}

  return {
    id,
    channelId,
    domain,
    title,
    type1Classification,
    tags: tags || [],
    systemTags: systemTags || [],
    icon,
    createdAt: formatDateFull(createdAt),
    createdAtShort: moment(createdAt).format(Config.DATE_FORMAT),
    user: fullName(firstName, lastName, email),
    status,
  }
}
