import React, { Component } from 'react'
import styled, {css} from 'styled-components'
import WidgetMetricBase, {MetricProps} from '../Base/WidgetMetricBase'
import IconLink from '../../IconLink'
import {ListWrapper} from '../Base/WidgetListBase'

interface Domain {
    domain: string
    icon: string
}

export interface WidgetPromotesEcosystemProps extends MetricProps {
  domains?: Array<Domain>
}

const Root = styled(WidgetMetricBase)`
    background: transparent!important;
    min-height: 120px;
    justify-content: flex-start;
`

const Domain = styled(IconLink)`
    color: ${props => props.theme.palette.accentDarkText};
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const Link = styled.a`
    color: ${props => props.theme.palette.primary.main};
    text-decoration: none;
`

function WidgetPromotesEcosystem ({ domains, ...props }: WidgetPromotesEcosystemProps) {
    return (
        <Root {...props} label="Promotes in ecosystem" unit="Brands" outline>
            <Content>
                <ListWrapper list>
                    {
                        domains.map((domain, index) => {
                            return (
                                <Domain key={index} icon={domain.icon} domain={domain.domain} />
                            )
                        })
                    }
                    <Link href={props.link} target={'_blank'}>See all</Link>
                </ListWrapper>
                </Content>
        </Root>
    )
}

WidgetPromotesEcosystem.defaultProps = {
  size: '2x2'
}

export default WidgetPromotesEcosystem
