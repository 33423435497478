import React from 'react'
import styled, { css } from 'styled-components'
import { IconButton as IconButtonMui, Tooltip } from '@material-ui/core'
import {
  Tag as TagIcon,
  ThumbsDown,
  ThumbsUp,
  Zap,
  UserPlus,
  Users,
  Folder,
  Trash,
  Download,
} from 'react-feather'
import { rgba } from 'polished'

export const ActionsHolder = styled.div`
  display: flex;
  flex-direction: inherit;
  column-gap: 2px;
  align-items: center;
`

const IconButton = styled(IconButtonMui)`
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  //background: #ececec;
  ${(props) =>
    props.filled &&
    css`
      background: ${props.theme.palette.primary.main};
      color: white;
      &:hover {
        background: ${rgba(props.theme.palette.primary.main, 0.8)};
      }
    `}
`

const TooltipWrapper = styled.div`
  display: inline-block;
`

const Action = ({ title, disabled, icon: Icon, onClick, color, filled }) => {
  const colorProps = {
    ...(color && !disabled && { color }),
  }
  return (
    <Tooltip title={title} leaveDelay={0} enterDelay={200}>
      <TooltipWrapper>
        <IconButton
          color={'primary'}
          size='small'
          disabled={disabled}
          onClick={onClick}
          filled={filled}
        >
          <Icon size={16} {...colorProps} />
        </IconButton>
      </TooltipWrapper>
    </Tooltip>
  )
}

export const ContactsQuickActions = ({
  id,
  domain,
  header,
  prospect,
  partner,
  onAction,
  disable,
  contactStatus,
}) => {
  const onClick = (id, action, direction) => (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    return onAction(id, action, domain)
  }

  return (
    <ActionsHolder>
      {!header && (
        <Action
          title={'Manage tags'}
          disabled={disable}
          onClick={onClick(id, 'toManageTags')}
          icon={TagIcon}
        />
      )}
      {header && (
        <Action
          title={`Export filtered list to CSV`}
          onClick={onClick(id, 'toExport')}
          icon={Download}
        />
      )}
    </ActionsHolder>
  )
}

export const IntelligenceQuickActions = ({
  id,
  heading,
  domain,
  prospect,
  partner,
  onAction,
  disable,
  contactStatus,
}) => {
  const onClick = (id, action) => (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    return onAction(id, action, domain, e)
  }

  return (
    <ActionsHolder>
      <Action
        title={'Toggle Relevant'}
        disabled={disable || prospect}
        filled={prospect}
        icon={ThumbsUp}
        onClick={onClick(id, 'toProspects', prospect ? 'unmark' : 'mark')}
      />

      <Action
        title={'Partner'}
        disabled={disable || partner}
        onClick={onClick(id, 'toPartners')}
        icon={Users}
      />

      <Action
        title={'Request contacts'}
        disabled={
          disable || ['requested', 'found', 'optedout'].includes(contactStatus)
        }
        onClick={onClick(id, 'toRequestContacts')}
        icon={UserPlus}
      />

      <Action
        title={'Manage tags'}
        disabled={disable}
        onClick={onClick(id, 'toManageTags')}
        icon={TagIcon}
      />

      <Action
        title={`Export filtered list to CSV`}
        onClick={onClick(id, 'toExport')}
        icon={Download}
      />
    </ActionsHolder>
  )
}

const QuickActions = ({
  id,
  header,
  kind,
  view,
  prospect,
  partner,
  onAction,
  disable,
  canRequestContacts,
  contactStatus,
}) => {
  const onClick = (id, action, direction) => (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    return onAction(id, action, direction || e)
  }

  return (
    <ActionsHolder>
      {view !== 'brand' && (
        <>
          <Action
            title={'Toggle Relevant'}
            disabled={disable || kind === 'relevant'}
            filled={prospect}
            icon={ThumbsUp}
            onClick={onClick(id, 'toProspects', prospect ? 'unmark' : 'mark')}
          />
          <Action
            title={'Irrelevant'}
            disabled={disable || kind === 'irrelevant'}
            onClick={onClick(id, 'toIrrelevant')}
            icon={ThumbsDown}
          />
          <Action
            title={'Competitor'}
            disabled={disable || kind === 'competitors'}
            onClick={onClick(id, 'toCompetitors')}
            icon={Zap}
          />
        </>
      )}
      <Action
        title={'Request contacts'}
        disabled={
          disable ||
          ['requested', 'found', 'optedout'].includes(contactStatus) ||
          (header && !canRequestContacts)
        }
        onClick={onClick(id, 'toRequestContacts')}
        icon={UserPlus}
      />
      <Action
        title={'Manage tags'}
        disabled={disable}
        onClick={onClick(id, 'toManageTags')}
        icon={TagIcon}
      />
      {(kind === 'relevants' || view === 'leads') && (
        <Action
          title={'Partner'}
          disabled={disable || partner}
          onClick={onClick(id, 'toPartners')}
          icon={Users}
        />
      )}
      {kind === 'relevants' && (
        <Action
          title={'Add to folder'}
          disabled={disable || partner}
          onClick={onClick(id, 'toFolder')}
          icon={Folder}
        />
      )}

      {view === 'brand' && (
        <Action
          title={`Remove from ${
            kind === 'relevants' ? 'Relevant' : 'Partners'
          }`}
          disabled={disable || partner}
          onClick={onClick(id, 'toRemove')}
          icon={Trash}
          color={'rgba(255, 63, 86, 1)'}
        />
      )}

      <Action
        title={`Export filtered list to CSV`}
        onClick={onClick(id, 'toExport')}
        icon={Download}
      />
    </ActionsHolder>
  )
}

export default QuickActions
