import React from 'react'
import styled from 'styled-components'
import InfoIcon from '@material-ui/icons/Info'

const Info = styled.div`
  display: flex;
  align-items: center;
  .MuiSvgIcon-root {
    margin-right: 0.3em;
    height: 0.8em;
    width: 0.8em;
    opacity: 0.8;
  }
`


const CreditsInfo = () => (
  <Info><InfoIcon />Read more about credits&nbsp;<a target="_blank" href="https://breezy.io/how-it-works#credits">here</a></Info>

)

export default CreditsInfo