import React from 'react'
import styled from 'styled-components'
import {
  DialogActions,
  useMediaQuery,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle, IconButton, Tab as MuiTab, Tabs as MuiTabs, Typography,
} from '@material-ui/core'

import {useTheme} from '@material-ui/core/styles';

export const Tab = styled(MuiTab)`
  color: ${props => props.error ? '#E51453' : ''};
  font-size: 14px;
  //color: rgba(41, 77, 131, 0.4);
  font-weight: bold;
  line-height: 16px;
  text-transform: none;
  width: auto;
  min-width: 0;
  margin: 0 25px 0 0;
  min-height: 33px;
  height: auto;
  padding: 0 0 8px 0;
  p {
    display: flex;
    align-items: center;
    margin: 0;
   span {
    background: ${props => props.error ? '#E51453' : '#0041E8'};
    border-radius: 5px;
    font-weight: bold;
    font-size: 9px;
    line-height: 9px;
    color: #fff;
    padding: 3px 6px;
    display: inline-block;
    margin: 0 0 0 4px;
   }
  }
  &.steps {
   color: #333333;
   font-weight: normal;
   p {
    color: #333333;
    span {
      height: 24px;
      background-color: #0041E8;
      width: 1px;
      margin: 0 5px 0 6px;
      border-radius: 0;
      padding: 0;
      display: inline-block;
      transform: rotate(20deg);
    }
   }
  }
`

export const Tabs = styled(MuiTabs)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0 0 0;
  min-height: 0;
  .MuiTabs-indicator {
    background-color: #0041E8;
    height: 3px;
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
  .Mui-selected {
     color: #3E3E3E;
  }
  .Mui-disabled {
     color: rgba(41, 77, 131, 0.4);
  }
`

export const Close = styled(IconButton)`
    position: absolute;
    right: 5px;
    top: 5px;
`;

export const TitleDialog = styled(Typography)`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  padding: 20px 20px 0 20px;
`

export const ActionsHolder = styled(DialogActions)`
  display: flex;
  justify-content: ${props => props.right ? 'flex-end' : 'flex-start'};
  padding: ${props => props.borderTop ? '20px' : '5px 20px 20px 20px'};
  ${props => props.borderTop && 'border-top: 1px solid rgba(0, 0, 0, 0.1);'}
`

export const Note = styled.div`
  font-size: 12px;
  margin: 0 20px 0 0;
  flex: 1 1 auto;
`

export const Content = styled(MuiDialogContent)`
    padding: 0 0 20px 0;
    overflow-x: hidden;
`

const Dialog = styled(MuiDialog)`
   .MuiDialog-paperScrollPaper {
     height: ${props => props.disableMediaQuery ? '' : '100%' };
   }
  
`

export const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
`

export const Title = styled.div`
  height: 44px;
  display: flex; 
  align-items: center;
`

export const Modal = (
  {
    children,
    fullWidth,
    maxWidth,
    open,
    ariaLabelledby,
    onBackdropClick,
    onEscapeKeyDown,
    disableMediaQuery,
    fullScreen: fullScreenProperty
  }
) => {
  const theme = useTheme();
  const fullScreen = fullScreenProperty || disableMediaQuery ? false : useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      disableEnforceFocus
      disableMediaQuery={disableMediaQuery}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      aria-labelledby={ariaLabelledby}
      onBackdropClick={onBackdropClick}
      onEscapeKeyDown={onEscapeKeyDown}
    >
      {children}
    </Dialog>
  )
}
