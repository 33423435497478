import React from 'react'
import {
  Grid
} from '@material-ui/core'
import {Form, Input} from '../Form'

import {FormsModal} from './FormsModal'
import {Title} from '../Modal'
import TipsText from "../TipsText";

const FORM_ID = 'product-name-form'

export default class ProductNameForm extends React.Component {
  static defaultProps = {
    name: '',
    createRef: () => {}
  }

  constructor(props) {
    super(props)
    const {name, description} = this.props
    this.state = {name, description, errors: null, error: null}
  }

  onChange = (field) => ({target: {value}}) => {
    const { onChange } = this.props
    if (field === 'name') {
      const slicedName = value ? value.slice(0, 40) : value
      this.setState({name: slicedName, errors: null})
      !!onChange && onChange({ name: slicedName })
    } else {
      this.setState({[field]: value, errors: null})
      !!onChange && onChange({ [field]: value })
    }

  }

  onSubmit = async (e) => {
    e.preventDefault()
    const {name, description} = this.state
    if (name) {
      await this.props.onSubmit({name, description})
    } else {
      this.setState({errors: 'Product name is required'})
    }
  }

  render() {
    const {name, description, errors} = this.state
    const {
      thinking,
      error,
      stepCount,
      step,
      createRef,
      variant
    } = this.props
    const Text = () =>
      <>
        <h2 style={{marginTop: '1em'}}>{variant === 'update' ? 'Update your Product’s name' : 'Give your Product a name'}</h2>
        {variant === 'update' && <p className={'prospect'}>The name of the Product you want to tailor your Partner Search for.</p>}
        {!variant && <p className={'prospect'}>Start by inputting the first Product you want to tailor your Partner Search for.</p>}
        <p>
          Every Product has its own dashboard where you do awesome stuff like run your Partner Search, organise Prospects, select Leads and request their Contact Details.
        </p>
        <TipsText>
          <h3>Tips</h3>
          <ol>
            <li>If you have multiple Products that overlap, and are are likely to share very similar Partners, you might prefer to group them together under one ‘Product’.</li>
          </ol>
        </TipsText>
      </>
    return (
      <FormsModal
        Text={Text}
      >
        <Form
          noValidate
          step={step}
          stepCount={stepCount}
          formId={FORM_ID}
          error={error}
          thinking={thinking}
          createRef={createRef}
          onSubmit={this.onSubmit}
        >
          <Grid container style={{marginTop: '16px'}}>
            <Grid item xs={12}>
              <Input
                disabled={thinking}
                autoFocus
                required
                fullWidth
                inputLabel={variant === 'update' ? 'Update your Product’s name' : 'Enter your Product’s name'}
                placeholder="e.g. My Awesome Product"
                margin="none"
                variant={'outlined'}
                fieldType='clearable'
                error={errors}
                value={name}
                onChange={this.onChange('name')}
              />
            </Grid>
            <Grid item xs={12}  style={{marginTop: '15px'}}>
              <Input
                disabled={thinking}
                fullWidth
                inputLabel={variant === 'update' ? 'Update your Product’s description' : 'Describe your Product'}
                placeholder="e.g. My Awesome Product highlights"
                margin="none"
                variant={'outlined'}
                fieldType='clearable'
                error={errors}
                value={description}
                multiline
                rows={4}
                onChange={this.onChange('description')}
              />
            </Grid>
          </Grid>
        </Form>
      </FormsModal>
    )
  }
}
