import React from 'react'
import Helmet from 'react-helmet'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import PageMain from '../../layouts/PageMain'
import List from '../../new-components/List/CreatorRequestsList'

import CreatorRequestDetails from '../../components-ts/Details/CreatorRequestDetails'

interface CreatorRequestsProps {
    kind?: string
    loading?: boolean
    listProps?: any
    sortProps?: {
        order: string
        orderBy: string
    }
    onLoad?: Function
    onSort?: Function
    onUpdateCounters?: Function
    onMoveToAccepted?: Function
    onMoveToRejected?: Function
}

interface CreatorRequestsState {
    channelId: string
}

class CreatorRequests extends React.Component<CreatorRequestsProps, CreatorRequestsState> {
    state = { channelId: null }

    componentDidMount() {
        this.props.onLoad()
    }

    onOpenDetails = ({ row, ...rest }) => {
        const { channelId } = row
        if (channelId) {
            this.setState({ channelId })
        }
    }

    onCloseDetails = () => {
        this.setState({ channelId: null })
    }

    onToggleMessage = async (id, target) => {
        const { kind: source, onUpdateCounters, onMoveToAccepted, onMoveToRejected } = this.props
        const { success } = target === 'accepted' ? await onMoveToAccepted(id) : await onMoveToRejected(id)

        if (success) {
            onUpdateCounters(source, target)
        }

        return { success }
    }

    get title () {
        switch (this.props.kind) {
            case 'inbox':
                return 'Inbox'
            case 'accepted':
                return 'Accepted'
            case 'rejected':
                return 'Rejected'
            default:
                return 'Breezy'
        }
    }

    render(): React.ReactNode {
        const { channelId } = this.state
        const { loading, listProps, sortProps, kind } = this.props

        return (
            <>
                <Helmet title={this.title} />
                <PageMain loading={loading}>
                    <List
                        {...listProps}
                        kind={kind}
                        sortProps={sortProps}
                        onRowClick={this.onOpenDetails}
                        onPageChange={console.log}
                        onToggleMessage={this.onToggleMessage}
                        onSort={this.props.onSort}
                    />
                </PageMain>
                <CreatorRequestDetails open={Boolean(channelId)} channelId={channelId} onClose={this.onCloseDetails} />
            </>
        )
    }
}


const createList = (kind: string, storeName: string) => {
    return inject((stores) => ({
        kind,
        loading: stores[storeName].loading,
        listProps: stores[storeName].listProps,
        sortProps: stores[storeName].sortProps,

        onLoad: () => stores[storeName].load(),
        onSort: (orderBy, order) => stores[storeName].sorting(orderBy, order),
        onMoveToAccepted: (id) => stores[storeName].accept(id),
        onMoveToRejected: (id) => stores[storeName].reject(id),

        onUpdateCounters: (source, direction) => stores['CreatorStore'].updateCounters(source, direction)
    }))(observer(CreatorRequests))
}

export const InboxRequests = createList('inbox', 'CreatorInboxStore')
export const AcceptedRequests = createList('accepted', 'CreatorAcceptedStore')
export const RejectedRequests = createList('rejected', 'CreatorRejectedStore')
