import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import {formatToReadableNumber} from '../../utils/format'


const Card = ({title, children, select}) =>
  <Box sx={{height: 56, minWidth: 100, maxWidth: 150, width: '100%', display: 'flex', flexDirection: 'column', }}>
    <Typography align="center" variant="h6" fontWeight='bold' component="div" color={select && 'primary'}>{children}</Typography>
    <Typography align="center" sx={{ fontSize: 14, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'  }} color={select ? 'primary' : 'text.secondary'} gutterTop>{title}</Typography>
  </Box>


const EcosystemMetrics = ({ ecosystem }) => {
  const {
    affiliatePartnersCount,
    inPartnershipsCount,
    channelsCount,
    untapped
  } = ecosystem

  const potentialLeads = untapped ? untapped : null

  return (
    <Grid container spacing={1} justifyContent='center' wrap={'nowrap'} >
      <Card title='Partnerships'>
        {formatToReadableNumber(inPartnershipsCount)}
      </Card>
      <Divider flexItem orientation="vertical" sx={{margin: '0 1em'}}  />
      <Card title={<>Brands</>}>
        {formatToReadableNumber(channelsCount)}
      </Card>
      <Divider flexItem orientation="vertical" sx={{margin: '0 1em'}} />
      <Card title={<>Creators</>}>
        {formatToReadableNumber(affiliatePartnersCount)}
      </Card>
      {
        untapped !== undefined && <><Divider orientation="vertical" flexItem sx={{margin: '0 1em'}} /><Card title='Potential Leads' select>
          {formatToReadableNumber(potentialLeads)}
        </Card>
        </>
      }
    </Grid>

  )

}

export default EcosystemMetrics
