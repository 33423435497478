import React from 'react'
import styled from 'styled-components'
import Button from '../../components/Button'
import {FirstReportReady} from '../../components/Icons/FirstReportReady'


const Root = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-family: "GT Super Display", serif;
    font-size: 32px;
    color: #000021;
    margin: 20px 0 0 0;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #3E3E3E;
    margin: 16px 0 0 0;
  }
`;

const Container = styled.div`
  flex: 1 1 auto; 
  margin: 4em 0;
  display: flex; 
  flex-direction: column; 
  align-items: center;
  justify-content: flex-end;
`
const ImageHolder = styled.div`
  max-width: 400px;
  width: 100%;
`
const Content = styled.div`
  max-width: 400px;
  width: 100%;
  padding-bottom: 2em;
`

export default class WelcomeFinish extends React.Component {
  render () {
    const {
      id,
      name,
      total,
      onFirstResearchSeen
    } = this.props

    return (
      <Root>
        <Container>
          <ImageHolder>
            <FirstReportReady/>
          </ImageHolder>

          <Content>
            <h1>Your results are in!</h1>
            <p>Your results are now ready, and there are <strong>{total}</strong> results to review for <strong>{name}</strong>!</p>
          </Content>
          <Button
            variant="contained"
            color="primary"
            onClick={onFirstResearchSeen}
          >See your Search</Button>
        </Container>
        <div style={{flex: '2 1 auto'}} />
      </Root>
    )
  }
}
