import React from 'react'
import styled from 'styled-components'
import {Zombieing} from '../Icons/Zombieing'

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0 20px;
  h5 {
    font-family: "GT Super Display", serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000021;
    margin: 0 0 32px 0;
    max-width: 400px;
    text-align: center;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #000021;
    margin: 0;
    max-width: 400px;
    text-align: center;
  }
`

const Img = styled.div`
  width: 250px;
  height: 250px;
  margin: 0 0 35px 0;
`

const config = {
  'intelligence-empty-data': {
    title: 'The data is on vacation.',
    text: ' It\'ll be back soon!',
    // text: 'Breezy Intelligence is a beta feature and we’re still ironing out some bugs. The team are working on fixing what’s gone wrong.',
    icon: <Zombieing />
  },
  'intelligence-error': {
    title: 'The server is taking a nap.',
    text: 'Come back later!',
    // text: 'Breezy Intelligence is a beta feature and we’re still ironing out some bugs. The team are working on fixing what’s gone wrong.',
    icon: <Zombieing />
  },
  'lead-list': {
    title: 'This is an empty list',
    text: 'Organise your search by adding items into lists.',
    icon: <Zombieing />
  },
  'relevants': {
    title: 'Your Relevant Leads live here',
    text: 'You haven\'t added any Relevant items yet. <br/> Add one or more Leads to Relevant to advance them down the funnel.',
    icon: <Zombieing />
  },
  'partners': {
    title: 'Your Partners live here',
    text: 'You haven\'t added any Partners yet. <br/> Add your Existing Partners here, or items from All Relevant.',
    icon: <Zombieing />
  },
  'outreach': {
    title: 'All Relevant items across discoveries live here',
    text: 'Mark any item as Relevant and it will appear here.',
    icon: <Zombieing />
  },
  'irrelevant': {
    title: 'Your Irrelevant Leads live here',
    text: 'You haven\'t added any Irrelevant items yet. <br/> Move one or more items to Irrelevant if they are not relevant. <br/> Alternatively you can change your settings.',
    icon: <Zombieing />
  },
  'competitors': {
    title: 'This is a list of Competitors',
    text: 'Move one or more items to Competitors, and they will be used as inputs for your next search. <br/> Alternatively you can change your settings.',
    icon: <Zombieing />
  }
}

const EmptyListPlaceholder = ({ show, kind, children }) => {
  const {
    icon,
    title,
    text
  } = config[kind] || {}

  return show ? (
    <Root>
      <Img>
        {icon}
      </Img>
      <h5 dangerouslySetInnerHTML={{__html: title}}/>
      <p dangerouslySetInnerHTML={{__html: text}}/>
    </Root>
  ) : children
}

export default EmptyListPlaceholder
