import { observable, action, computed, toJS } from 'mobx'
import { Get, Delete, Post, Put } from '../utils/request'
import { findIndex } from 'lodash'
import hasError from './request-message'
import Notification from './notification'
import config from '../config'
import GqlClient from '../gql/gql-client'
import {
  GET_FOLDERS,
  CREATE_DEFAULT_FOLDERS,
  REORDER_FOLDERS,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  REMOVE_FOLDER,
  ADD_PROSPECTS_TO_FOLDER,
  REMOVE_PROSPECTS_FROM_FOLDER
} from '../gql/folders'

const kind = {
  'partners': 'partners',
  'relevants': 'relevants'
}

class FoldersStore {
  brandId = null

  @observable loading = true
  @observable thinking = false

  @observable list = []
  @observable beforeReorderList = null

  constructor (kind) {
    this.kind = kind
  }

  @action
  addNew () {
    const newIndex = findIndex(toJS(this.list), ['id', 'new'])
    if (newIndex < 0) {
      this.list = [{ id: 'new', name: 'New folder' }, ...toJS(this.list)]
    }
  }

  @action
  async load (brandId) {
    this.brandId = brandId || this.brandId

    const { success, ...rest } = await GqlClient.query({ query: GET_FOLDERS, variables: { kind: kind[this.kind], brandId: this.brandId } })

    if (!hasError(success, 'message')) {
      this.list = rest.data['foldersOfBrand']
    }

    this.loading = false
  }

  @action
  async createDefault () {
    this.thinking = true

    const payload = {
      brandId: this.brandId,
      kind: kind[this.kind],
      names: config.DEFAULT_FOLDERS
    }

    const { success,  ...rest } = await GqlClient.mutation({ mutation: CREATE_DEFAULT_FOLDERS, variables: { CreateFoldersInput: payload } })

    if (!hasError(success, 'message', /*'New List successfully created'*/)) {
      this.list = rest.data['createFolders']
    }

    return { success }
  }

  @action
  async create (name) {
    this.thinking = true

    const payload = {
      brandId: this.brandId,
      kind: kind[this.kind],
      name
    }

    const { success,  ...rest } = await GqlClient.mutation({ mutation: CREATE_FOLDER, variables: { CreateFolderInput: payload } })

    if (!hasError(success, `message === 'alreadyExists' ? 'Folder with given name already exist' : message`, /*'New List successfully created'*/)) {
      const folder = rest.data['createFolder']
      this.list = [folder, ...toJS(this.list)].filter(({ id }) => id !== 'new')
      return { success, folder }
    }

    return { success }
  }

  @action
  async update (id, name) {
    this.thinking = true

    this.list = toJS(this.list).map((folder) => {
      return {
        ...folder,
        ...(folder.id === id && { name })
      }
    })

    const payload = {
      id,
      brandId: this.brandId,
      patch: { name }
    }

    const { success,  ...rest } = await GqlClient.mutation({ mutation: UPDATE_FOLDER, variables: { UpdateFolderInput: payload } })

    if (!hasError(success, 'message', /*'New List successfully created'*/)) {
      const folder = rest.data['updateFolder']
        this.list = toJS(this.list).map((f) => {
        return f.id === id ? folder : f
      })
      return { success, folder }
    }


  }

  @action
  async remove (id) {
    this.thinking = true

    this.list = toJS(this.list).filter((folder) => folder.id !== id)

    if (id !== 'new') {
      const payload = {
        id,
        brandId: toJS(this.brandId)
      }

      const { success: requestSuccess, ...rest } = await GqlClient.mutation({ mutation: REMOVE_FOLDER, variables: payload })

      if (requestSuccess) {
        const { success } = rest.data['deleteFolder']
        if (!hasError(success, 'message', /*'New List successfully created'*/)) {
          // this.list = folders
        }
        return { success }
      }



      return { success: requestSuccess }
    }

    return { success: true }

  }

  @action
  async addToFolder (ids, folderId, sourceFolderId) {
    this.thinking = true

    this.list = toJS(this.list).map(({ id, ...rest }) => {
      let recordsCount = rest.recordsCount
      if (sourceFolderId && id === sourceFolderId) {
        recordsCount -= ids.length
      }
      if (id === folderId) {
        recordsCount += ids.length
      }
      return { id, ...rest, recordsCount }
    })

    const payload = {
      id: folderId,
      brandId: this.brandId,
      prospectIds: ids
    }

    const { success } = await GqlClient.mutation({ mutation: ADD_PROSPECTS_TO_FOLDER, variables: { AddProspectToFolderInput: payload } })

    if (!hasError(success, 'message', /*'New List successfully created'*/)) {
      // this.list = folders
    }
    return { success }
  }

  @action
  async removeFromFolder (ids, folderId) {
    this.thinking = true

    this.list = toJS(this.list).map(({ id, ...rest }) => {
      let recordsCount = rest.recordsCount
      if (id === folderId) {
        recordsCount -= ids.length
      }
      return { id, ...rest, recordsCount }
    })

    const payload = {
      id: folderId,
      brandId: this.brandId,
      prospectIds: ids
    }

    const { success } = await GqlClient.mutation({ mutation: REMOVE_PROSPECTS_FROM_FOLDER, variables: { RemoveProspectsFromFolderInput: payload } })

    if (!hasError(success, 'message', /*'New List successfully created'*/)) {
      // this.list = folders
    }
    return { success }
  }

  @action
  async reorder (items) {
    this.list = items

    const payload = {
      brandId: this.brandId,
      kind: kind[this.kind],
      folderIds: items.map(({ id }) => id)
    }

    const { success,  ...rest } = await GqlClient.mutation({ mutation: REORDER_FOLDERS, variables: { ReorderFoldersInput: payload } })

    if (!hasError(success, 'message', /*'New List successfully created'*/)) {
      this.list = rest.data['reorderFolders']
    }
    return { success }
  }

  @action
  setInitial () {
    this.loading = true
    this.thinking = false
    this.brandId = null
    this.list = []
  }

  @computed
  get folders () {
    return toJS(this.list)
  }
}

export const RelevantFoldersStore = new FoldersStore('relevants', 'folder')
export const PartnersFoldersStore = new FoldersStore('partners')

