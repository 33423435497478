import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import ArrowUp from "@material-ui/icons/ArrowUpward";
import pluraliseUnit from '../../utils/pluraliseUnit'

const Root = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: flex-end;
`
const Balance = styled.strong`
  display: flex;
  align-items: center;
  margin-right: 1em;
`



const CreditsDisplay = ({ role, creditsLeft, inactive, billingStatus, onPurchase, onPlans }) => {
  const isActivationNeeded = billingStatus === 'inTrial' || inactive

  const onClick = isActivationNeeded ? onPlans : onPurchase
  const copy = isActivationNeeded ? 'Activate subscription' : 'Purchase credits'

  return (
    <Root>
      {/*<Balance>{creditsLeft}&nbsp;{pluraliseUnit('credit', creditsLeft)}</Balance>*/}
      {/*<Button variant={'outlined'} color={'primary'} disabled={role !== 'brandOwner'} disableElevation onClick={onClick}>*/}
      {/*  {copy}*/}
      {/*</Button>*/}

    </Root>
  )
}

export default CreditsDisplay
