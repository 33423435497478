import React, {FC} from 'react'
import styled from 'styled-components'

const Aside = styled.div`
    display: grid;
    position: sticky;
    top: 1em;
    flex: 0 0 12ch;
    min-width: 12ch;
    width: 100%;
    max-width: 12ch;
      @media (max-width: 1200px) {
        max-width: 10ch;
      }
    grid-template-columns: 1fr;
    grid-auto-rows: 3.7em;
    gap: 0.5em;
    & > div {
      min-height: initial;
      padding: 0.5em 0.8em;
    }
`

const Root = styled.div`
    padding: 1em 2em 1em 0;
    display: flex;
    gap: 2.5em;
    align-items: flex-start;
    min-height: ${props => props.fullHeight && '100%'};
    box-sizing: border-box;
`

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex: 1 1 auto;
  gap: 1em;
  min-height: 100%;
  padding-bottom: 4em;
`

interface ProfileLayoutProps {
  children?: React.ReactNode
  aside?: React.ReactNode
  fullHeight?: boolean
}

function ProfileLayout ({ children, aside, fullHeight }: ProfileLayoutProps) {
    // const aside = <>
    //     <WidgetScore value={50} variant="avgScore" />
    //     <WidgetMetric value={50000} variant="rank" />
    //     <WidgetMetric value={50} variant="moz" />
    //     <Actions item={lead} kind={"leads"} vertical />
    // </>
    return (
        <Root fullHeight={fullHeight}>
            <Aside>
                {aside}
            </Aside>
            <Main>
                {children}
            </Main>
        </Root>
    )
}

ProfileLayout.defaultProps = {
  children: 'It\'s a me, ProfileLayout!'
}

export default ProfileLayout
