import React from 'react'
import { inject, observer } from 'mobx-react'
import Details from './Create'
import AddModeCreditsModal from '../Modals/_AddMoreCreditsModal'

import { SearchStore } from '../../stores/search'

@inject(({ SearchesStore, BrandStore }) => ({
  role: BrandStore.role,
  creditsLeft: BrandStore.credits,

  products: SearchesStore.products,
  onLoadProducts: () => SearchesStore.load(),

  onStartProductResearch: (searchId) => BrandStore.initReRun({searchId, kind: 'products'})
}))
@observer
class _Details extends React.Component {
  constructor (props) {
    super(props)

    this.store = new SearchStore()
    this.store.load(props.id, false, false, 'details').then()

    this.state = { credits: false }
  }

  onOpenCredits = () => this.setState({ credits: true })
  onCloseCredits = () => this.setState({ credits: false })

  onUpdate = async ({ run, ...details }) => {
    const { success, search: product } = await this.store.update(details)

    if (success) {
      this.store.setInitial()
      if (run) {
        this.props.onStartProductResearch(product.id)
      }
      this.props.onClose()
    }
    return { success, error: 'error' }
  }

  render () {
    const {
      open,
      role,
      products,
      creditsLeft,
      onClose
    } = this.props
    const { loading, details } = this.store

    return <>
      <Details
        {...details}
        role={role}
        variant={'update'}
        loading={loading}
        open={open}
        products={products}
        creditsLeft={creditsLeft}
        onClose={onClose}
        onSubmit={this.onUpdate}
        onAddCredits={this.onOpenCredits}
      />
      { this.state.credits && <AddModeCreditsModal open={this.state.credits} onClose={this.onCloseCredits} />}
    </>
  }
}

export default _Details
