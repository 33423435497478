import React from 'react'
import { inject, observer } from 'mobx-react'
import TagManagementPopup from './TagManagementPopup'

@inject(({ TagsStore, BrandStore }) => ({
  thinking: TagsStore.thinking,
  tags: TagsStore.tags,
  search: TagsStore.search,
  onCreate: (details) => TagsStore.create(details),
  onFilter: (label) => TagsStore.filtersChange(label),

  role: BrandStore.role,
  latestTags: BrandStore.latestTags,
  onUpdateLatestTags: (tag, action) => BrandStore.updateLatestTags(tag, action),
}))
@observer
export default class _TagsManagement extends React.Component {
  onCreateTag = async (details) => await this.props.onCreate(details)

  onUpdateTag = (details) => {
    if (this.props.role !== 'user')
      this.props.onUpdateLatestTags(details, 'update')
    this.props.onUpdateTag(details)
  }

  onDeleteTag = (details) => {
    if (this.props.role !== 'user')
      this.props.onUpdateLatestTags(details, 'remove')
    this.props.onDeleteTag(details)
  }

  onRemoveTag = (tagId) => {
    this.props.onRemoveTag(tagId, this.props.leadIds)
  }

  onSelectTag = (details) => {
    if (
      details &&
      details.hasOwnProperty('id') &&
      (details.hasOwnProperty('label') || details.hasOwnProperty('name'))
    ) {
      const tag = { id: details.id, label: details.label || details.name }
      if (this.props.role !== 'user')
        this.props.onUpdateLatestTags(tag, 'select')
      this.props.onSelectTag(tag)
    }
  }

  render() {
    const { tags, itemTags, latestTags, thinking, anchorEl } = this.props

    return (
      <TagManagementPopup
        variant={'management'}
        thinking={thinking}
        tags={tags}
        latestTags={latestTags}
        itemTags={itemTags}
        anchorEl={anchorEl}
        onClose={this.props.onClose}
        onFilter={this.props.onFilter}
        onCreateTag={this.onCreateTag}
        onSelectTag={this.onSelectTag}
        onUpdateTag={this.onUpdateTag}
        onRemoveTag={this.onRemoveTag}
        onDeleteTag={this.onDeleteTag}
      />
    )
  }
}
