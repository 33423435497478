import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Prospects from './Prospects'

const baseProperties = (store) => ({
  loading: store.loading,
  filters: store.filters,
  listProps: store.listProps,
  sortProps: store.sortProps,
  geoFilter: store.geoFilter,
  hasFilters: store.hasFilters,

  onLoad: (brandId, thinking, folderId, force) => store.load(brandId, thinking, folderId, force),
  onGetAllIds: () => store.loadIds(),
  onSort: (key, value, callback) => store.sorting(key, value, callback),
  onPageChange: (page) => store.pageChange(page),
  onPerPageChange: (perPage) => store.perPageChange(perPage),
  onFiltersChange: (filters) => store.filtersChange(filters),
  onUpdateNotesCount: (id, count) => store.updateNotesCount(id, count),

  onUpdateItems: (ids, details) => store.updateItems(ids, details),
  onRemoveItems: (ids) => store.removeItems(ids),


  onAddPartnerTags: (partners, tags) => store.addTags(partners, tags),
  onRemovePartnerTags: (partners, tags) => store.removeTags(partners, tags),
  onUpdatePartnerTags: (tag) => store.updateTags(tag),

  onImportExistingPartners: (details) => store.importPartners(details),
  onToggle: (kind, actionKind, ids) => store.toggle(kind, actionKind, ids),

  onRequestSingle: (itemIds, outreachMessage) => store.requestPartnerContacts(itemIds, outreachMessage),
  onRequestSelected: (itemIds, outreachMessage) => store.requestPartnersContacts(itemIds, outreachMessage),

  onExportToCSV: () => store.exportToCSV()
})

const brandProperties = store => ({
  brandId: store.id,
  counters: store.counters,
  paywall: store.paywall,
  maxValues: store.maxValues.partnersMaxValues,
  onUpdateBrandCounters: () => store.load(),
})

const tagsProperties = store => ({
  onUpdateTag: (details) => store.update(details),
  onDeleteTag: (id) => store.remove(id)
})

const folderProps = store => ({
  onCreateFolder: (name) => this.store.create(name),
  onAddToFolder: (ids, folderId) => store.addToFolder(ids, folderId),
  onRemoveFromFolder: (ids, folderId) => store.removeFromFolder(ids, folderId),
})

export const PartnersList = inject(({ PartnersStore: store, ProspectsStore, BrandStore, TagsStore, SearchesStore }) => ({
  kind: 'partners',
  ...baseProperties(store),
  ...brandProperties(BrandStore),
  ...tagsProperties(TagsStore),
  products: SearchesStore.products,
}))(observer(Prospects))

export const ProspectsList = inject(({ RelevantsStore: store, ProspectsFolderStore, RelevantFoldersStore, PartnersStore, BrandStore, TagsStore, SearchesStore }) => ({
  kind: 'relevants',
  ...baseProperties(store),
  onMoveToFolder: (ids, folder) => store.moveToFolder(ids, folder),
  onRemoveFromFolders: (ids) => store.removeFromFolders(ids),
  ...brandProperties(BrandStore),
  ...tagsProperties(TagsStore),
  ...folderProps(RelevantFoldersStore),
  products: SearchesStore.products
}))(observer(Prospects))

@inject(({ RelevantsFolderStore: store, ProspectsStore, RelevantFoldersStore, PartnersStore, BrandStore, TagsStore, SearchesStore }) => ({
  kind: 'relevants',
  ...baseProperties(store),
  onMoveToFolder: (items, folder) => store.moveToFolder(items, folder),
  onReadFolder: (folderId) => store.readFolder(folderId),
  ...brandProperties(BrandStore),
  ...tagsProperties(TagsStore),
  ...folderProps(RelevantFoldersStore),
  products: SearchesStore.products,
  onUpdateRootList: (ids, details) => store.updateItems(ids, details)
}))
@observer
@withRouter
export class RelevantFolder extends React.Component {
  render () {
    const { match: { params: { folderId }} } = this.props
    return <Prospects {...this.props} folderId={folderId} />
  }
}
