import { Get, Post, Put, Delete } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import { find, findIndex, orderBy } from 'lodash'
import hasError from './request-message'
import countries from '../components/Leads/CountriesFlag'
import GqlClient from '../gql/gql-client'
import { GET_SEARCHES, CREATE_SEARCH, UPDATE_SEARCH, REMOVE_SEARCH, RUN_SEARCH, GET_SEARCH } from '../gql/searches'

const POLLING_TIMEOUT = 10000

class SearchesStore {
  @observable id
  @observable brandId = null
  @observable loading = true
  @observable thinking = true

  @observable list = []

  @observable expandedIds = []

  @observable orderBy = 'researchDoneAt'
  @observable order = 'desc'

  pollingStarted = false
  pollingTimeout = null

  constructor () {}

  @action
  expandItem (ids) {
    this.expandedIds = ids
  }

  @action
  async load (brandId) {
    this.brandId = brandId || this.brandId

    const { success, ...rest } = await GqlClient.query({ query: GET_SEARCHES, variables: { brandId: this.brandId } })

    if (success) {
      this.list = rest.data['searchesOfBrand']
      this.startPollingResearchStatuses()
    }

    this.loading = false

    return { success }
  }

  @action
  startPollingResearchStatuses () {
    const inProgressSearches = toJS(this.list).filter(({ researchStatus }) => researchStatus === 'progress').map(({ id }) => id)

    if (inProgressSearches.length) {
      inProgressSearches.forEach(async (id) => {
        const { success, ...rest } = await GqlClient.query({ query: GET_SEARCH, variables: { id } })
        if (success) {
          const search = rest.data['search']
          if (search.researchStatus === 'researched') {
            this.list = toJS(this.list).map(item => {
              if (search.id === item.id) {
                return search
              }
              return item
            })
          }
        }
      })
      if (!this.pollingStarted) {
        this.pollingStarted = true
        this.pollingTimeout = setInterval(() => this.startPollingResearchStatuses(), POLLING_TIMEOUT)
      }
    } else {
      this.stopPollingResearchStatuses()
    }
  }

  @action
  stopPollingResearchStatuses () {
    this.pollingStarted = false
    clearInterval(this.pollingTimeout)
  }

  @action
  async loadProduct (id) {
    const { product } = await Get(`/api/discovery/products/${id}`)
    this.updateSearchByID(product, true)
    return product
  }

  @action
  async removeProduct (id) {
    if (this.expandedIds.includes(id)) {
      let expandedIds = toJS(this.expandedIds)
      const index = expandedIds.indexOf(id)
      expandedIds.splice(index, 1)
      this.expandedIds = expandedIds
    }
    this.list = toJS(this.list).filter((search) => search.id !== id)

    const { success } = await GqlClient.mutation({ mutation: REMOVE_SEARCH, variables: { id } })

    hasError(success, 'errorMessage', 'Discovery was successfully deleted')
  }

  @action
  updateSearchByID (product, field) {
    const { id } = product
    let list = toJS(this.list)
    const index = findIndex(list, ['id', id])

    if (index < 0) {
      this.list = [product, ...list]
    } else {
      if (field) {
        list[index] = {...list[index], ...product }
      } else {
        list[index] = product
      }
      this.list = list
    }
  }

  @action
  async updateProduct (id, details) {
    const { keywords, competitors, name, description } = details

    const updatePayload = {
      id,
      patch: {
        ...(competitors && { competitorUrls: toJS(competitors).filter(v => !!v).join('\n') }),
        ...(keywords && { keywords: toJS(keywords).filter(v => !!v).join('\n') }),
        ...(name && { name }),
        ...(description && { description }),
      }
    }

    const { success, ...update } = await GqlClient.mutation({ mutation: UPDATE_SEARCH, variables: { UpdateSearchInput: updatePayload } })

    if (success) {
      const search = update.data['updateSearch']
      this.updateSearchByID(search)

      return { success, product: search }
    }

    return { success }
  }


  @action
  async createProduct (details) {
    const { link, country, run, ...rest } = details

    const createPayload =  {
      brandId: this.brandId,
      countryCode: country,
      domain: link
    }
    const { success, ...create } = await GqlClient.mutation({ mutation: CREATE_SEARCH, variables: { CreateSearchInput: createPayload } })

    if (success) {
      const search = create.data['createSearch']
      this.list = [search, ...toJS(this.list)]

      return await this.updateProduct(search.id, ...rest)
    }

    return { success }
  }

  @action
  async createChannel ({ brand, link, channel, searchId }) {
    if (channel) {
      return await this.updateChannel(channel, { link }, searchId)
    }
    const details = { channel: { brand, link } }
    const { success, product, message } = await Post(`/api/discovery/channels`, details)
    if (success) {
      this.list = [product, ...toJS(this.list)]
    }
    return { success, message, product }
  }

  @action
  async updateChannel(id, { link }, searchId) {
    const { success, channelCandidate } = await Put(`/api/discovery/channels/${id}/update-candidate`, { candidate: { link }, searchId })
    if (success) {
      const { channel, link } = channelCandidate
      this.updateSearchByID({ id: searchId, channelId: channel, channel: { link } }, true)
    }
    return { success }
  }

  @action
  async startResearch (id) {
    this.updateSearchByID({ id, researchStatus: 'progress' }, true)

    const { success } = await GqlClient.mutation({ mutation: RUN_SEARCH, variables: { id } })

    if (!hasError(success, 'message', '')) {
      if (!this.pollingStarted) {
        this.startPollingResearchStatuses()
      }
    }

    return { success }
  }

  @action
  setInitial () {
    this.id = null
    this.loading = true
    this.thinking = true
    this.list = []
    this.stopPollingResearchStatuses()
  }

  @action
  sort (orderBy) {
    this.orderBy = orderBy
    this.order = this.order === 'desc' ? 'asc' : 'desc'
  }

  @computed
  get products () {
    const unsorted = toJS(this.list).map(({ channel, channelId, leadsCount, relevantsCount, ...rest }) => {

      const name = rest.name || ''
      const leads = leadsCount || 0
      const relevants = relevantsCount || 0

      const lists = 0//total - inbox
      const user = (rest.user || {})
      const ownerName = user.firstName || user.email
      const ownerFullName = `${user.firstName || ''}${user.lastName ? ' ' : ''}${user.lastName || ''}` || user.email
      const channelLink = (channel || {}).link
      const countryCode = rest.country.code
      const countryLabel = (find(countries, ['value', rest.country.code]) || {}).label || rest.country.code
      const description = rest.description || `${rest.name} in ${countryLabel}`

      return {
        ...rest,
        name,
        channel: channelId,
        channelLink,
        results: {},
        lists,
        leads,
        relevants,
        ownerName,
        ownerFullName,
        description,
        countryCode
      }
    })

    const completed = unsorted.filter(({ researchStatus }) => {
      return 'researched' === researchStatus
    })

    const updated = unsorted.filter(({ researchStatus }) => {
      return ['progress', 'pending'].includes(researchStatus)
    })

    return [...updated, ...orderBy((completed || []), [this.orderBy], [this.order])]
  }

  @computed
  get isFirstProductNotAdded () {

    return null
  }

  @computed
  get expanded () {
    return toJS(this.expandedIds)
  }
}

export default new SearchesStore()
