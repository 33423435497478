import React from 'react'
import styled from 'styled-components'
import Pane from '../Details/Pane'
import icon from '../AffiliateNetworkIcon'


const Wrapper = styled.div`

`
const Item = styled.div`
margin-bottom: 0.5rem;
position: relative;
width: 100%;
padding: 0 0.5em;
&:last-child {
margin-bottom: 0;
}
&:before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 5px;
  left: 0;
  top: 0;
  height: 100%;
  width: ${props => props.share}%;
  background: ${props => props.theme.palette.brand.grey.main};
  z-index: 0;
}
`
const Content = styled.div`
position: relative;
z-index: 1;
display: flex;
align-items: center;
line-height: 250%;
`

const Icon = styled.div`
width: 25px;
height: 25px;
`;

const share = value => typeof value === 'number' ? value.toFixed(0) : 0

class AffiliateNetworksDistribution extends React.Component {
  render() {
    const {
      data
    } = this.props

    const networks = data.map(({ name, share: networkShare }) => ({
      name, share: share(networkShare)
    })).filter(network => {
      return network.share && parseInt(network.share) > 0
    })

    return (
      <Pane empty={data.length === 0} emptyMessage={'No distribution data is available'}>
        <Wrapper>
          {
            networks.map((network) => (
              <Item share={network.share}>
                <Content>
                  <Icon>{icon[network.name] || icon['unknown']}</Icon>&nbsp;&nbsp;{network.name}&nbsp;{network.share}%
                </Content>
              </Item>
            ))
          }
        </Wrapper>
      </Pane>

    );
  }
}

export default AffiliateNetworksDistribution
