import React from 'react'
import styled from 'styled-components'
import { Post } from '../../utils/request'
import {ButtonBase} from "@material-ui/core";

import { onCharge } from '../../utils/chargebee'
import config from '../../config'
import { AssistedLockContent } from '../../new-components/Modals/AssistedLockModal'

const { credits } = config

const CHARGES = [
  {
    id: '250-Credits-GBP',
    credits: 250,
    cost: credits['250'],
    off: credits['250-off']
  },
  {
    id: '500-Credits-GBP',
    credits: 500,
    cost: credits['500'],
    off: credits['500-off']
  },
  {
    id: '1000-Credits-GBP',
    credits: 1000,
    cost: credits['1000'],
    off: credits['1000-off']
  },
]

const Items = styled.div`
  margin-top: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15ch, 32%));
  grid-column-gap: 0.6em;
  grid-row-gap: 0.6em;
  width: 100%;
  justify-content: center;
`

const ChargeRoot = styled(ButtonBase)`
  pointer-events: ${props => props.disabled ? 'none' : ''};
  opacity: ${props => props.disabled ? '0.3' : '1'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1em 2em;
  border: 1px solid #0041E8;
  box-sizing: border-box;
  border-radius: 5px;
  pointer-events: ${props => props.thinking ? 'none' : ''};
  text-decoration: none;
  
  font-weight: 700;
  font-size: 14px;
  color: #0041E8;
`

export const Charge = ({ disabled, thinking, id, cost, credits, off, ...props }) => {
  const onClick = (e) => {
    e.preventDefault()
    props.onClick({ id, credits }, 'charge')
  }

  return (
    <ChargeRoot href={id} onClick={onClick} thinking={thinking} disabled={disabled}>
      Purchase<br/>{credits} Credits
      <br/>
      for £{cost} {off && `(${off}% off)`}
    </ChargeRoot>
  )
}

export default class BillingCredits extends React.Component {
  state = { thinking: null }

  onChargeClick = async ({ id: chargeId, credits: creditsAmount }, kind) => {
    const { brandId } = this.props
    this.setState({ thinking: chargeId })
    await onCharge(brandId, chargeId, () => {
      this.props.onUpdateCredits({ credits: this.props.credits + creditsAmount, added: creditsAmount })
      if (this.props.onSuccess) {
        this.props.onSuccess()
      }
      this.setState({ thinking: null })
    })
  }

  render () {
    const { disabled, assistedLock } = this.props

    if (assistedLock) {
      return <AssistedLockContent disableImage onClose={this.props.onClose}/>
    }

    return (
      <Items>
        {
          CHARGES.map(charge => (
            <Charge
              {...charge}
              key={charge.id}
              disabled={disabled}
              thinking={this.state.thinking === charge.id}
              onClick={this.onChargeClick}
            />
          ))
        }
      </Items>
    )
  }
}
