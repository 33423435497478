import React, {useContext} from 'react'

import {CreatorRequestContext} from '../CreatorRequestDetailsContext'
import Audience from '../../Profile/Audience'

export default function CreatorRequestProfileAudience (props) {
    const { loading, countries, summary } = useContext(CreatorRequestContext)

    return <Audience loading={loading} countries={countries} total={summary.swVisits}/>
}
