import React from 'react'

import ItemSummary from '../ItemSummary/ItemSummary'
import ProfileAudience from './ProfileAudience'
import ProfileAppearsInEcosystems from './ProfileAppearsInEcosystems'
import ProfileNetworksUse from './ProfileNetworksUse'
import ProfilePromotes from './ProfilePromotes'
import ProfileAppearsInSearches from './ProfileAppearsInSearches'
import ProfilePlacements from './ProfilePlacements'
import ProfileLayout from '../../Profile/ProfileLayout'
import ProfileAside from './ProfileAside'

interface ProfileViewProps {
    variant: string
    tagManagement?: any
    onSetViewState: Function
    onQuickActions?: () => void
}

export default function ProfileView(props: ProfileViewProps) {
    const isIntelligence = ['gap', 'landscape', 'partnerships'].includes(props.variant)

    return (
        <ProfileLayout aside={<ProfileAside {...props} />}>
            <ItemSummary horizontal size={'large'} tagManagement={props.tagManagement} />

            <ProfileAudience />
            <ProfileAppearsInEcosystems />
            <ProfileNetworksUse />
            <ProfilePromotes />
            <ProfileAppearsInSearches />
            {
                !isIntelligence && <ProfilePlacements />
            }

        </ProfileLayout>
    )
}
