import React from 'react'
import styled from 'styled-components'
import Screenshot from './Screenshot'
import TitleComponent from './Title'
import Icon from './Icon'
import Link from '../Link'
import { SkeletonPlaceholder } from '../SkeletonPlaceholder'
import Pane from "../Details/Pane";
import TagList from '../../new-components/Tags/TagList'
import {TypeLabel} from '../../new-components/TypeLabel/TypeLabel'
import {FolderLabel} from '../../new-components/Folder/FolderLabel'

const Title = styled(TitleComponent)`
  display: flex;
  margin: 0.5em 0 0.8em;
`

const TitlePlaceholderContainer = styled.div`
  width: 100%;
  height: 15px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Description = styled.div`
  display: flex;
  margin: 0.5em 0 0.8em;
  align-items: center;
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
`

const Actions = styled.div`
  border-top: 1px solid ${props => props.theme.palette.brand.grey.main};
  margin: 0.4em -1em -0.5em;
  display: flex;
  justify-content: center;
  padding: 0.2em;
`
const Tags = styled.div`
  display: flex;
  column-gap: 0.3em;
  align-items: center;
  padding: 0.3em 0;
`

const PlaceholderTitle = () => (
  <TitlePlaceholderContainer>
    <SkeletonPlaceholder loading={false} offAnimationLoaded width={'80%'} height={'15px'} color={'#d8e0f0'}  highlightColor={'#e6efff'} />
  </TitlePlaceholderContainer>
)

const HomePage = ({ root, ...other }) => (
  <Pane>
    <Screenshot item={root} action={()=>{}}/>

    <Header>
      <Icon item={root} size={12} color={'#828282'} />
      <Link
        value={root.url}
        link={`//${root.url}`}
        title={root.url}
      />
    </Header>

    <Title>
      {!root.title ? PlaceholderTitle() : root.title}
    </Title>

    <Description>
      { !root.description ? PlaceholderTitle() : root.description }
    </Description>
    <Tags>
      <TagList
        variant={'details'}
        tags={other.itemTags}
        onUpdate={other.onUpdateTag}
        onRemove={other.onRemoveTag}
        onDelete={other.onDeleteTag}
      >
        { Boolean(other.itemFolder) && <FolderLabel name={other.itemFolder} /> }
        { Boolean(root.type1Classification) && <TypeLabel type={root.type1Classification} /> }
      </TagList>
    </Tags>
  </Pane>
)

export default HomePage
