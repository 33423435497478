import { observable, action, computed, toJS } from 'mobx'
import param from 'can-param'
import { findIndex } from 'lodash'

import GqlClient from '../gql/gql-client'
import { Get, Delete, Post, Put } from '../utils/request'
import hasError from './request-message'
import { CREATE_TAG, GET_BRAND_TAGS, REMOVE_TAG, UPDATE_TAG } from '../gql/tags'

const errors = {
  tagAlreadyExists: 'Tag already exists'
}

const getError = (message) => errors[message] || message

class TagsStore {
  brandId = null

  @observable loading = true
  @observable thinking = false
  @observable tagsList = []
  @observable totalCount = 0
  @observable page = 1
  @observable perPage = 100
  @observable filters = {}

  constructor () {}

  @computed
  get queries () {
    const { page, perPage, filters } = this
    return `?${param({ page, perPage, filters: filters, sort: 'label' })}`
  }

  @action
  async load (brandId) {
    this.brandId = this.brandId || brandId
    this.thinking = true

    const variables = {
      brandId: this.brandId
    }
    const { success, ...rest } = await GqlClient.query({ query: GET_BRAND_TAGS, variables })

    if (success) {
      this.tagsList = rest.data['tagsOfBrand']
    }

    this.loading = false
    this.thinking = false
  }

  @action
  async create (details) {
    const { label } = details

    if (label && label.length > 1) {
      this.thinking = true

      const payload = {
        brandId: this.brandId,
        name: label
      }

      const { success,  ...rest } = await GqlClient.mutation({ mutation: CREATE_TAG, variables: { CreateTagInput: payload } })

      this.thinking = false

      if (!hasError(success,' getError(message)', '')) {
        const tag = rest.data['createTag']
        this.tagsList = [...toJS(this.tagsList), tag]
        return { success, tag }
      }

      return { success }
    }

    hasError(false, 'Tag should be more than one symbol')

    return { success: false }
  }

  @action
  async update ({ id, ...details }) {
    this.thinking = true

    const { label: name } = details

    const payload = {
      id,
      brandId: this.brandId,
      patch: { name }
    }

    const { success, ...rest } = await GqlClient.mutation({ mutation: UPDATE_TAG, variables: { UpdateTagInput: payload } })

    this.thinking = false

    if (!hasError(success, 'getError(message)', '')) {
      const tag = rest.data['updateTag']
      let tags = toJS(this.tagsList)
      const index = findIndex(tags, ['id', id])
      if (index >= 0) {
        tags[index] = tag
      }
      this.tagsList = tags
    }

    return { success }
  }

  @action
  async remove (id) {
    this.thinking = true

    let tags = toJS(this.tagsList)

    const index = findIndex(tags, ['id', id])
    if (index >= 0) {
      tags.splice(index, 1)
    }

    this.tagsList = tags

    const variables = {
      id,
      brandId: this.brandId
    }

    const { success } = await GqlClient.mutation({ mutation: REMOVE_TAG, variables })

    if (!hasError(success, 'getError(message)', '')) {}

    return { success }
  }

  @action
  filtersChange (label) {
    if (label) {
      this.filters = { label }
    } else {
      this.filters = {}
    }
    this.load().then()
  }

  @computed
  get search () {
    return toJS(this.filters).label || ''
  }

  @action
  setInitial () {
    this.brandId = null
    this.tagsList = []
    this.page = 1
    this.perPage = 50
    this.totalCount = 0

    this.loading = true
  }

  @computed
  get tags () {
    return toJS(this.tagsList).map(({ id, name, favourite }) => ({ id, label: name }))
  }

  @computed
  get tagFilterValues () {
    return toJS(this.tagsList).map(({ id, name }) => ({ value: id, label: name }))
  }
}

export default new TagsStore()
