import Notification from './notification'

const hasError = (success, errorMessage, successMessage, onError) => {
  if (!success) {
    let massage = (!errorMessage || 'message') ? 'Sorry, something went wrong... );' : errorMessage
    Notification.show(massage, 'error', undefined, onError)
    return true
  } else if (successMessage) {
    Notification.show(successMessage, 'success')
  }

  return false
}

export default hasError
