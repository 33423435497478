import React, { useState } from 'react'
import { Tooltip } from '@mui/material'
import copyToClipboard from '../../../utils/clipboard'

const combineText = ({
                         url, geo, title, summary, networks,
                         competitors, competitorsCount, totalCompetitors, keywords, keywordsCount, totalKeywords
}) => {
    const domain = new URL(url)
    const top3Keywords = keywords.slice(0, 3).map(({ name, position }) => `${name} ranking ${position}`)
    const top10Competitors = competitors.slice(0, 10).map(({ domain }) => domain)
    const top3networks = (networks || []).slice(0, 3).map(({ name }) => name)
    const totalAdexes = (networks || []).length
    const top3KeywordsString = Boolean(top3Keywords.length) ? top3Keywords.join(', ') : 'none'
    const top10CompetitorsString = Boolean(top10Competitors.length) ? top10Competitors.join(', ') : 'none'
    const top3networksString = Boolean(top3networks.length) ? top3networks.join(', ') : 'none'
    const { partnershipsInCount, partnershipsOutCount } = (summary || {})


    return [
        domain.hostname.replace('www.', ''),
        `pageUrl: ${url}`,
        `pageTitle: ${title || 'none'}`,
        `ranksForKeywords: ${keywordsCount || 'none'}`,
        `totalKeywords: ${totalKeywords || 'none'}`,
        `top3Keywords: ${top3KeywordsString}`,
        `country: ${geo}`,
        `linksToCompetitors: ${competitorsCount || 'none'}`,
        `totalCompetitors: ${totalCompetitors || 'none'}`,
        `top10Competitors: ${top10CompetitorsString}`,
        `inPartnerships: ${partnershipsInCount || 'none'}`,
        `outPartnerships: ${partnershipsOutCount || 'none'}`,
        `totalAdexes: ${totalAdexes || 'none'}`,
        `top3adexes: ${top3networksString}`
    ].join('\n')
}

const copyRelevantInfos = async (props) => {
    const text = combineText(props)
    await copyToClipboard(text)
}

export default function PlacementTextAIWrapper ({ children, ...rest }) {
    const [copied, setCopied] = useState(false)

    const onCopy = async (e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        await copyRelevantInfos(rest)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    return <>
        <Tooltip title={copied ? 'Copied to clipboard' : `Copy relevant infos`}>
            {
                React.cloneElement(children, { onClick: onCopy })
            }
        </Tooltip>
    </>
}
