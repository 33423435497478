import React from 'react'
import styled from 'styled-components'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'


export const Tab = styled(MuiTab)`
  font-size: inherit!important;
  line-height: inherit!important;
  text-transform: none!important;
  width: auto!important;
  min-width: 0!important;
  //font-weight: bold!important;
  //margin: 0 2em 0 0;
  //min-height: 0!important;
  //height: auto!important;
  padding: 0.5em!important;
  //margin-right: 1.5em!important;
  //opacity: 0.5;
`

export const Tabs = styled(MuiTabs)`

`

export const Panel = styled.div`
  flex: 1;
  position: relative;
`

interface Props {
    children?: React.ReactNode
    value?: string
    index?: string
}

function TabPanel({ children, value, index }: Props) {

    return (
        <Panel
            role="tabpanel"
            hidden={value !== index}
            id={`tab-panel-${index}`}
        >
            { children }
        </Panel>
    );
}

export default TabPanel
