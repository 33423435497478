import React from 'react'
import styled from 'styled-components'
import CountryFlag from '../../components/CountryFlag'
import {
  Grid,
  Button as MuiButton,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  Input as MuiInput
} from '@material-ui/core'
import { Form, Input } from '../../components/Form'
import {
  Content,
  Close,
  TitleDialog,
  ActionsHolder,
  DialogTitle,
  Modal
} from '../../components/Modal'
import Validator from '../../utils/validator'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { find } from 'lodash'
import countries from '../../components/Leads/CountriesFlag'

const imageKitUrl = process.env.IMAGEKIT_URL

const getCountry = country => {
  const countryObject = find(countries, ['value', country])

  return countryObject ? countryObject : {}
}

const Root = styled.div`
  padding: 0 20px;
`

const Button = styled(MuiButton)`
  text-transform: none;
  font-weight: 600;
`

const FormInputLabel = styled(InputLabel)`
    color: #3E3E3E;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    transform: none;
    margin: 1em 0 0 0;
    position: relative;
    text-transform: none;
    .MuiInputLabel-asterisk {
      display: none;
    }
`;

const FormMuiInput = styled(MuiInput)`
    margin: 0 !important;
    border: 1px solid rgba(0,0,0,0.2);
    box-sizing: border-box;
    border-radius: 3px;
    background: #fff;
    font-size: 14px;
    padding: 9px 18px;
    &.withHelpText {
      padding: 24px 18px 14px 18px;
    }
`;

const TextField = styled(MuiTextField)`
  //.MuiFormControl-marginNormal {
    margin-top: 5px;
  //}
  .MuiInputBase-root {
    margin: 0 !important;
    //border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 3px;
    background: #fff;
    font-size: 14px;
    padding: ${props => props.padding ? props.padding : '9px 18px'};
    &.withHelpText {
      padding: 24px 18px 14px 18px;
    }
  }
`

const FORM_ID = 'product-form'

const schema = [
  {
    field: 'name',
    rules: 'required',
    messages: {
      required: 'Product name is required'
    }
  }
]

const filter = createFilterOptions();

export const SelectCreatable = ({ id, value, options, disabled, onChange }) => {
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        const { name } = (newValue || {})
        onChange({ target: { value: name } })
      }}
      filterOptions={(options, params) => filter(options, params)}
      selectOnFocus
      handleHomeEndKeys
      id={id}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name
      }}
      renderOption={(option) => option.name}
      freeSolo
      renderInput={(params) => (
        // <FormControl fullWidth>
        <>
        <FormInputLabel htmlFor={'productName'} shrink>Product name</FormInputLabel>
        <TextField
            {...params}
            fullWidth
            disabled={disabled}
            id='productName'
            placeholder="Product name"
            margin="normal"
            // label={'Product name'}
            // inputLabel={'Product name'}
            variant={'outlined'}
            fieldType='clearable'
            onChange={onChange}
          />
        {/*</FormControl>*/}
        </>
      )}
    />
  )
}

export default class DiscoveryDetailsModal extends React.Component {
  constructor(props) {
    super(props)
    const {name, description} = props
    this.state = {name, description, thinking: false, errors: {}, error: null}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      const {name, description} = this.props
      this.state = {name, description, thinking: false, errors: {}, error: null}
    }
  }

  onChange = name => ({target: {value}}) => {
    this.setState({[name]: value})
    this.resetError(name)
    if (this.props.onChange) {
      this.props.onChange({[name]: value })
    }
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    this.setState({ thinking: true })
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors, thinking: false}),
      async () => {
        const {name, description} = this.state
        const {error} = await this.props.onSubmit({name, description})

        if (error) {
          this.setState({errors: {name: message}})
        }
        this.setState({ thinking: false })

        if (!error) {
          this.props.onClose()
        }
      })
  }

  onCancel = (e) => {
    e.preventDefault()
    const {name, description} = this.props
    this.setState({name, description, errors: {}, error: null})
    this.props.onClose()
  }

  render() {
    const { name, description, errors } = this.state
    const {
      open,
      thinking,
      products,
      error,
      createRef,
      country
    } = this.props

    const countryObject = getCountry(country)

    return (
      <Modal
        disableMediaQuery
        open={open}
        ariaLabelledby="dialog-title"
        onEscapeKeyDown={this.onCancel}
      >
        <Form
          noValidate
          formId={FORM_ID}
          error={error}
          thinking={thinking}
          createRef={createRef}
          onSubmit={this.onSubmit}
        >
          <DialogTitle disableTypography>
            <Close aria-label="close" onClick={this.onCancel}>
              <CloseIcon/>
            </Close>
            <TitleDialog>Search details</TitleDialog>
          </DialogTitle>
          <Content>

            <Root>
              <br/>
              <Grid container>
                <Grid item xs={12}>
                  <Input
                    required
                    fullWidth
                    placeholder="Search name"
                    margin="normal"
                    id={'description'}
                    label={'Search name'}
                    inputLabel={'Search name'}
                    variant={'outlined'}
                    fieldType='clearable'
                    value={description}
                    disabled={thinking}
                    error={errors.description}
                    onChange={this.onChange('description')}
                  />
                </Grid>
                <Grid item xs={12}>
                  {
                    open && (
                      <SelectCreatable
                        label={'Product name'}
                        inputLabel={'Product name'}
                        id={'name'}
                        value={name}
                        options={products}
                        disabled={thinking}
                        onChange={this.onChange('name')}
                      />
                    )
                  }
                </Grid>
                <Grid item xs={12} style={{marginBottom: '1em'}}>
                  <strong>Search country:&nbsp;&nbsp;</strong>
                  <CountryFlag
                    country={countryObject.value}
                    alt={countryObject.label}
                    fallbackImageURL={`${imageKitUrl}/flags/${countryObject.value}.svg`}
                  />
                  &nbsp;
                  {countryObject.label}
                </Grid>
              </Grid>
            </Root>

          </Content>
          <ActionsHolder borderTop right>
            <Button onClick={this.onCancel} disabled={thinking} variant="outlined"  color="primary">Dismiss</Button>
            <Button style={{margin: '0 0 0 25px'}} disabled={thinking} type={'submit'} variant="contained" color="primary">Save</Button>
          </ActionsHolder>
        </Form>
      </Modal>
    )
  }
}

