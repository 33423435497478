import React from 'react'
import { inject, observer } from 'mobx-react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { Competitors, Irrelevant, Prospects, Report as Leads, LeadsList } from '../Leads'

import { ProductSettings } from '../../components/Product'

@inject(({ SearchStore, BrandStore }) => ({
  id: SearchStore.id,
  loading: SearchStore.loading,
  totals: SearchStore.totals,
  name: SearchStore.name,
  country: SearchStore.country,
  description: SearchStore.description,
  inputsProps: SearchStore.inputsProps,
  researchStatus: SearchStore.researchStatus,
  firstResearchSeen: SearchStore.firstResearchSeen,
  load: (searchId, brandId) => SearchStore.load(searchId, brandId),
  onUnmountProduct: () => SearchStore.setInitial(),
}))
@observer
@withRouter
export default class _Product extends React.Component {
  componentDidMount () {
    const {
      id,
      match: { params: { brandId, searchId } },
    } = this.props


    if (id !== searchId) {
      this.props.onUnmountProduct()
      this.props.load(searchId, brandId)
    }
  }

  componentWillUnmount () {
    this.props.onUnmountProduct()
  }

  render () {
    const {
      location,
      match: { path }
    } = this.props

    return (
      <Switch location={location}>
        <Route exact path={`${path}`}>
          <Leads />
        </Route>
        <Route exact path={`${path}/competitors`}>
          <Competitors />
        </Route>
        <Route exact path={`${path}/irrelevant`}>
          <Irrelevant />
        </Route>
        <Route exact path={`${path}/inputs`}>
          <ProductSettings />
        </Route>
      </Switch>
    )
  }
}
