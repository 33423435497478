import React from 'react'
import styled from 'styled-components'
import {Promote} from '../../Details/interfaces'
import IconLink from '../../IconLink'
import promotes from '../../../configPromotes'
import ProfileSection from '../ProfileSection'
import pluraliseUnit from '../../../utils/pluraliseUnit'
import Table from '../../TableCompact'
import LinkButton from '../../LinkButton'

import { formatToReadableNumber } from '../../../utils/format'

const Root = styled(ProfileSection)`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
    flex: 1 1 auto;
  `

const Domain = styled(IconLink)`
    color: ${props => props.theme.palette.accentDarkText};
    font-weight: bold;
`
const DomainRoot = styled.div`
    display: flex;
    gap: 0.8em;
`
const DomainTitle= styled.div`
    opacity: 0.75;
`

interface PromotesProps {
    loading?: boolean
    type: string | undefined
    total: number | undefined
    data: Array<Promote>
    link: string
}

export default function Promotes (props: PromotesProps) {
    if (!props.type) return null

    const title = `${promotes[props.type].heading} ${formatToReadableNumber(props.total)} ${pluraliseUnit(promotes[props.type].name, props.total)}`
    const columnHeader = promotes[props.type].name
    const domainRenderer = (params: any) =>
        <DomainRoot>
            <Domain icon={params.row.icon} domain={params.row.domain}/>
            <DomainTitle>{params.row.title}</DomainTitle>
        </DomainRoot>

    return <Root title={title} fullWidth>
        <Table
            columns={[
                { field: 'domain', headerName: columnHeader, flex: 1, sortable: true, renderCell: domainRenderer },
                { field: 'count', headerName: 'Links', align: 'right', headerAlign: 'right', width: 200, sortable: true, renderCell: (params) => formatToReadableNumber(params.row.count)  },
            ]}
            rows={props.data || []}
        />
        {/*<LinkButton disabled to={props.link} target={'_blank'}>See all</LinkButton>*/}
    </Root>
}
