import { gql } from '@apollo/client'
import { USER_FIELDS } from './fragments'

export const ACCOUNT_USER_FIELDS = gql`
  fragment ACCOUNT_USER_FIELDS on AccountUser {
    id
    email
    role
    status
    updatedAt
    userId
    user {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`

export const JOIN_ACCOUNT_DETAILS = gql`
  query JoinAccountDetails (
    $id: String!
    $brandId: String!
  ) {
    accountUserInfo (
      id: $id
      brandId: $brandId
    ) {
      ...ACCOUNT_USER_FIELDS
    }
  }
  ${ACCOUNT_USER_FIELDS}
`

export const JOIN_TO_BRAND = gql`
  mutation JoinToBrand (
    $id: String!
  ) {
    joinToBrandViaInvite (
      id: $id
    ) {
      ...ACCOUNT_USER_FIELDS
    }
  }
  ${ACCOUNT_USER_FIELDS}
`
