import React from 'react'
import styled, { css } from 'styled-components'
import TextAvatar from 'react-avatar'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip,
} from '@material-ui/core'
import { Facebook, Instagram, Linkedin, Twitter } from 'react-feather'

import Pane from '../Details/Pane'
import { ContactStatus } from '../../components/ContactStatus/ContactStatus'

import getSocialHandle from '../../utils/getSocialHandle'
import config from '../../config'

const CopyRequest = styled.p`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.6;
`

const ListStyled = styled(List)`
  & > div {
    border-bottom: 1px solid black;
  }
  & > div:last-child {
    border-bottom: none;
  }
`

const CopyRequested = styled.p`
  color: black;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Avatar = styled(TextAvatar)`
  cursor: pointer;
  margin-right: 15px;
`

const LinkTo = styled.a`
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: black;
    text-decoration: none;
  }
`

const ContactLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  font-size: 12px;
  border-radius: 25px;
  ${(props) =>
    props.variant === 'personal' &&
    css`
      background: rgb(254, 245, 197);
    `}
  ${(props) =>
    props.variant === 'corporate' &&
    css`
      background: rgb(196, 219, 1253);
    `}
  padding-left: 8px;
  padding-right: 8px;
`

const SocialItemRoot = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.2em;
  //text-decoration: none;
  & svg {
    position: relative;
    top: 3px;
  }
  & a {
    color: black;
    text-decoration: none;
  }
`

const icon = {
  twitter: <Twitter size={16} />,
  instagram: <Instagram size={16} />,
  linkedin: <Linkedin size={16} />,
  facebook: <Facebook size={16} />,
}

const SocialItem = ({ variant, url }) => {
  const handle = getSocialHandle(url)
  return url ? (
    <Tooltip title={`${variant}: ${handle}`}>
      <SocialItemRoot>
        <a target='_blank' href={url}>
          {icon[variant]}&nbsp;{getSocialHandle(url)}
        </a>
      </SocialItemRoot>
    </Tooltip>
  ) : (
    ''
  )
}

export const Entity = ({ entity }) => {
  const {
    companyEmails,
    phones,
    company,
    instagram,
    twitter,
    facebook,
    linkedin,
    channel,
  } = entity

  return (
    <div>
      <ListItem alignItems='flex-start'>
        <ListItemAvatar>
          <Avatar
            name={company}
            textSizeRatio={1.8}
            size={64}
            round={40}
            fgColor={'black'}
            colors={config.avatarColors}
            variant='rounded'
          />
        </ListItemAvatar>
        <ListItemText
          primary={<strong>{company}</strong>}
          secondary={
            <>
              {!!channel && channel.domain && (
                <>
                  <span>{channel.domain}</span> <br />
                </>
              )}
            </>
          }
        />
      </ListItem>
      {/*{(emails.length > 0 || companyEmails.length || phones.length > 0 || (linkedin || facebook || twitter || instagram)) && <Divider component="li" />}*/}
      <li style={{ padding: '1em 1.2em' }}>
        <table cellspacing={7}>
          {companyEmails.map((email) => (
            <tr>
              <td align={'right'}>
                <ContactLabel variant={'corporate'}>Corporate</ContactLabel>
              </td>
              <td align={'left'}>
                <LinkTo href={`mailto:${email}`}>{email}</LinkTo>
              </td>
            </tr>
          ))}
          {phones.map((phone) => (
            <tr>
              <td />
              <td align={'left'}>
                <LinkTo>{phone}</LinkTo>
              </td>
            </tr>
          ))}

          {linkedin && (
            <tr>
              <td />
              <td align={'left'}>
                <SocialItem variant={'linkedin'} url={linkedin} />
              </td>
            </tr>
          )}

          {facebook && (
            <tr>
              <td />
              <td align={'left'}>
                <SocialItem variant={'facebook'} url={facebook} />
              </td>
            </tr>
          )}

          {twitter && (
            <tr>
              <td />
              <td align={'left'}>
                <SocialItem variant={'twitter'} url={twitter} />
              </td>
            </tr>
          )}
          {instagram && (
            <tr>
              <td />
              <td align={'left'}>
                <SocialItem variant={'instagram'} url={instagram} />
              </td>
            </tr>
          )}
        </table>
      </li>
    </div>
  )
}

const Contact = ({ contact }) => {
  const {
    firstName,
    lastName,
    emails,
    companyEmails,
    phones,
    jobTitle,
    company,
    instagram,
    twitter,
    facebook,
    linkedin,
  } = contact
  const fullName = `${firstName ? `${firstName} ` : ''}${
    lastName ? lastName : ''
  }`

  return (
    <div>
      <ListItem alignItems='flex-start'>
        <ListItemAvatar>
          <Avatar
            name={fullName}
            textSizeRatio={1.8}
            size={64}
            round={40}
            fgColor={'black'}
            colors={config.avatarColors}
            variant='rounded'
          />
        </ListItemAvatar>
        <ListItemText
          primary={<strong>{fullName}</strong>}
          secondary={
            <>
              {!!jobTitle && (
                <>
                  <span>{jobTitle}</span> <br />
                </>
              )}
            </>
          }
        />
      </ListItem>
      {/*{(emails.length > 0 || companyEmails.length || phones.length > 0 || (linkedin || facebook || twitter || instagram)) && <Divider component="li" />}*/}
      <li style={{ padding: '1em 1.2em' }}>
        <table cellspacing={7}>
          {emails.map((email) => (
            <tr>
              <td align={'right'}>
                <ContactLabel variant={'personal'}>Personal</ContactLabel>
              </td>
              <td align={'left'}>
                <LinkTo href={`mailto:${email}`}>{email}</LinkTo>
              </td>
            </tr>
          ))}
          {phones.map((phone) => (
            <tr>
              <td />
              <td align={'left'}>
                <LinkTo>{phone}</LinkTo>
              </td>
            </tr>
          ))}

          {linkedin && (
            <tr>
              <td />
              <td align={'left'}>
                <SocialItem variant={'linkedin'} url={linkedin} />
              </td>
            </tr>
          )}

          {facebook && (
            <tr>
              <td />
              <td align={'left'}>
                <SocialItem variant={'facebook'} url={facebook} />
              </td>
            </tr>
          )}

          {twitter && (
            <tr>
              <td />
              <td align={'left'}>
                <SocialItem variant={'twitter'} url={twitter} />
              </td>
            </tr>
          )}
          {instagram && (
            <tr>
              <td />
              <td align={'left'}>
                <SocialItem variant={'instagram'} url={instagram} />
              </td>
            </tr>
          )}
        </table>
      </li>
    </div>
  )
}

const Contacts = ({ thinking, status, contacts, entity, onRequest }) => {
  return (
    <Pane style={{ minHeight: 160, fontSize: 14 }}>
      {!status && (
        <>
          <Button
            disabled={thinking}
            color='primary'
            variant='outlined'
            mb={2}
            mr={4}
            onClick={onRequest}
          >
            Request contacts
          </Button>
          <CopyRequest>
            Each contact request costs {config.credits.contact} credits.
          </CopyRequest>
        </>
      )}

      {status === 'optedout' && (
        <CopyRequested>
          <ContactStatus status={status} fontSize={'1.2em'} />
        </CopyRequested>
      )}

      {status === 'requested' && (
        <CopyRequested>
          <ContactStatus status={status} fontSize={'1.2em'} />
        </CopyRequested>
      )}

      {status === 'unsuccessful' && (
        <CopyRequested>
          <ContactStatus status={status} fontSize={'1.2em'} />
        </CopyRequested>
      )}
      {status === 'found' && !thinking && (
        <ListStyled style={{ width: '100%' }}>
          {entity && <Entity entity={entity} />}
          {contacts.map((contact) => (
            <Contact key={contact.id} contact={contact} />
          ))}
        </ListStyled>
      )}
    </Pane>
  )
}

export default Contacts
