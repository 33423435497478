import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import PartnersForm from '../Forms/Partners'
import {
  Content,
  Close,
  TitleDialog,
  ActionsHolder,
  Note,
  DialogTitle,
  Modal
} from '../Modal'

import Button from '@material-ui/core/Button'

export default class ImportPartners extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.open,
      kind: props.kind,
      partners: [],
      partnersErrors: [],
      hasChanges: false,
      errors: []
    }
  }

  onChange = kind => (value, errors) => {
    this.setState({[`${kind}Errors`]: errors})
    this.setState({[`${kind}`]: value, hasChanges: true})
  }

  onSave = async () => {
    this.setState({thinking: true})
    const {
      partnersErrors,
      partners
    } = this.state
    if (!!partnersErrors.length) {
      this.setState({thinking: false})
    } else {
      const {error} = await this.props.onUpdate({ partners })
      this.setState({thinking: false, hasChanges: !error})
      if (!error) this.props.onClose()
    }
  }

  render() {
    const {
      open
    } = this.props

    const {
      thinking,
      partners,
      partnersErrors,
      hasChanges
    } = this.state
    const hasErrors = !!partnersErrors.length

    return (
      <Modal
        fullWidth="true"
        maxWidth="md"
        open={open}
        disableMediaQuery
        ariaLabelledby="dialog-title"
        onBackdropClick={this.props.onClose}
        onEscapeKeyDown={this.props.onClose}
      >
        <DialogTitle>
          <Close aria-label="close" onClick={this.props.onClose}>
            <CloseIcon/>
          </Close>
          <TitleDialog>
            Import existing partners
          </TitleDialog>
        </DialogTitle>
        <Content>
          <PartnersForm
            partners={partners}
            errors={partnersErrors}
            onChange={this.onChange('partners')}
          />
        </Content>
        <ActionsHolder borderTop right>
          <Button
            disabled={thinking}
            color="primary"
            variant="outlined"
            mb={2}
            mr={4}
            onClick={this.props.onClose}
          >
            Dismiss
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!hasChanges || thinking || hasErrors}
            mb={2}
            onClick={this.onSave}
            style={{margin: '0 0 0 25px'}}
          >
            &nbsp;&nbsp;Import&nbsp;&nbsp;
          </Button>
        </ActionsHolder>
      </Modal>
    );
  }
}
