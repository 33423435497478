import React, { useContext, useEffect, useRef, useState } from 'react'
import { LinearProgress } from '@mui/material'
import styled, { css } from 'styled-components'
import { Tooltip, Popper } from '@material-ui/core'
import {
  DataGridPremium,
  LicenseInfo,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { makeStyles } from '@material-ui/styles'
import { MozLogoIcon } from '../../components/Icons/Moz_logo'
import Title from '../../components/Leads/Title'
import { ContactStatus } from '/src/components/ContactStatus/ContactStatus'

import TagList from '../../new-components/Tags/TagList'
import { SystemTags } from '../../new-components/Tags/SystemTags'

import IconLink from '../../components-ts/IconLink'
import ListContext from '../List/ListContext'
import config from '../../config'
import { IntelligenceQuickActions } from '../QuickActions/QuickActions'
import { getPicturePath } from '../../utils/list-items'
import SelectAll from '../List/SelectAll'
import { Result, Root } from '../List/ListTable'
import { formatToReadableNumber } from '../../utils/format'

const POPPER_PROPS = {
  eventsEnabled: false,
  disableEventListeners: true,
  modifiers: {
    offset: {
      offset: '0, -5px',
    },
    flip: {
      enabled: false,
    },
    preventOverflow: {
      padding: -35,
      boundariesElement: 'scrollParent',
      escapeWithReference: false,
    },
  },
}

const S3_DATA_URL = 'https://data-afill-eu.s3.amazonaws.com'

LicenseInfo.setLicenseKey(config.DATA_GRID_LICENSE_KEY)

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none!important',
    '& .pinned': {
      position: 'sticky',
      zIndex: 3,
      top: 0,
      background: 'rgba(216, 239, 255, 1)!important;',
    },
    '& .group': {
      background: 'rgba(216, 239, 255, 1)!important;',
    },
    '& .group-item': {
      background: 'rgba(216, 239, 255, 0.5)!important;',
    },
    '& .cursor': {
      cursor: 'pointer',
    },
    '& .active-details': {
      background: theme.palette.brand.turquoise.light,
      transition: 'background .2s ease',
    },
    '& .marginLeft': {
      marginLeft: 10,
    },
    '& .MuiDataGrid-pinnedRows': {
      background: 'rgba(216, 239, 255, 1)!important',
    },
    '& .MuiDataGrid-row:focus, & .MuiDataGrid-row::focus-within': {
      outline: '1px solid blue!important',
    },
    '& .MuiDataGrid-row': {
      position: 'relative',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
      {
        outline: 'none!important',
      },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      fontSize: 12,
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      padding: '0 8px',
    },
    '& .MuiDataGrid-cell.MuiDataGrid-cell--textRight': {
      padding: '12px 8px',
    },
    '& .MuiDataGrid-columnHeader--numeric': {
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        marginLeft: 20,
        padding: 0,
      },
      '& .MuiDataGrid-iconButtonContainer': {
        left: 3,
        position: 'absolute',
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      '& .MuiSvgIcon-root': {
        width: '0.8em',
        height: '0.8em',
        opacity: '0.75',
        // color: ;
      },
    },
    '& .MuiDataGrid-cell.metrics': {
      alignItems: 'center',
      justifyContent: 'flex-end',
      display: 'flex',
      position: 'relative',
      // '&:first-of-type': {
      overflow: 'visible',
      // }
    },
    '& .MuiDataGrid-cell.muted': {
      // '&:before': {
      //   content: '""',
      //   display: 'block',
      //   position: 'absolute',
      //   width: '100%',
      //   height: '90%',
      //   top: '5%',
      //   zIndex: 0,
      backgroundImage: `linear-gradient(${theme.palette.brand.historical.main}, ${theme.palette.brand.historical.main})`,
      // background: theme.palette.brand.grey.main,
      backgroundSize: '100% 90%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      // }
    },
  },
}))

const Domain = styled(IconLink)`
  font-weight: bold;
  margin-right: 0.5em;
`

const Tags = styled.div`
  display: flex;
  column-gap: 0.3em;
  align-items: center;
  padding: 0.3em 0;
`

const TooltipContent = styled.div`
  a {
    opacity: 0.8;
    display: block;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0.3em 0;
    margin-top: 0.3em;
    text-decoration: none;
    color: white;
  }
`

export const MozLogo = styled(MozLogoIcon)`
  width: 28px;
  height: 12px;
  color: ${(props) => props.color || 'black'};
  position: relative;
  top: 2px;
`

export const SwIcon = styled.span`
  position: relative;
  top: 1px;
  display: inline-flex;
  align-items: center;
`

export const Body = styled.div`
  flex: 1 1 15em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding: 0.2em 1em 0.2em 0;
  overflow: hidden;
  height: 100%;
  ${(props) =>
    props.disable &&
    css`
      pointer-events: none;
    `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding-left: 0.7em;
  //padding: 0.2em 1em 0.2em 0;
  position: relative;
  flex: 1 1 auto;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  line-height: normal;
  //justify-content: flex-start;
`

const LeadLabel = styled.div`
  position: relative;
  z-index: 1;
  width: 10px;
  height: 100%;
  &:after {
    content: '';
    display: block;
    width: 5px;
    height: 100%;
    ${(props) =>
      props.variant === 'overlap' &&
      css`
        background: ${config.labelColors.overlap};
      `}
    ${(props) =>
      props.variant === 'prospect' &&
      css`
        background: ${config.labelColors.prospect};
      `}
    ${(props) =>
      props.variant === 'partner' &&
      css`
        background: ${config.labelColors.partner};
      `}
  }
`

const LabelContainer = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`

const ActionsHolder = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 10;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  svg {
    margin: 4px 2px;
  }
`

const ActionsBackground = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: white;
  display: flex;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.palette.brand.grey.main};
  overflow: hidden;
  pointer-events: all;
`

const SelectionContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const HeadTitle = (props) => {
  const context = useContext(ListContext)
  const {
    total,
    selected,
    locked,
    lockedKind,
    quickActionProps,
    onQuickActions,
    onSelect,
    onGetAllIds,
  } = context
  const onActions = (id, action, direction, e) => {
    onQuickActions(id, action, undefined, e)
  }

  const showTotal = selected && selected.length === 0

  const onSelectAll = async () => {
    const ids = await onGetAllIds()
    onSelect(ids)
  }

  return (
    <div style={{ display: 'flex' }}>
      {!showTotal && (
        <SelectionContainer>
          <SelectAll
            selected={selected}
            total={total}
            source={'channels'}
            onSelect={onSelectAll}
          />
        </SelectionContainer>
      )}
      {showTotal && (
        <Root>
          <Result>
            <strong>{formatToReadableNumber(total)} results</strong>
          </Result>
        </Root>
      )}
      &nbsp;&nbsp;
      <IntelligenceQuickActions
        heading
        disable={selected && selected.length < 1}
        header
        {...quickActionProps}
        onAction={onActions}
      />
    </div>
  )
}

export const ColumnTooltipLogo = ({ title, logo, children }) => (
  <Tooltip
    PopperProps={{
      popperOptions: {
        modifiers: {
          offset: {
            offset: '0, 20px',
          },
        },
      },
    }}
    interactive
    title={
      <TooltipContent>
        <strong>{title}</strong>
        <br />
        {children}
      </TooltipContent>
    }
  >
    <span>{logo}</span>
  </Tooltip>
)

export const LoadingOverlay = () => {
  return <LinearProgress />
}

export function renderTitle(params) {
  const ref = useRef(null)
  const [showQuickActions, setShowQuickActions] = useState(null)

  const {
    value,
    row: {
      title,
      type1Classification,
      kind,
      domain,
      id,
      channelId,
      contactStatus,
      tags,
      systemTags,
      ...rest
    },
  } = params

  const icon = getPicturePath(domain, rest.icon)

  const onToggleShowQuickActions = ({
    type: mouseEventType,
    currentTarget,
  }) => {
    if ('mouseleave' === mouseEventType) {
      setShowQuickActions(null)
    } else if ('mouseenter' === mouseEventType) {
      setShowQuickActions(currentTarget)
    }
  }

  const onQuickActions = (id, action) => {
    rest.onQuickActions(
      id,
      action,
      domain,
      action === 'toManageTags' ? { target: ref.current } : null
    )
  }

  const additionalTagsCount =
    (Boolean(contactStatus) ? 1 : 0) + (Boolean(type1Classification) ? 1 : 0)

  return (
    <Body
      ref={ref}
      onMouseEnter={onToggleShowQuickActions}
      onMouseLeave={onToggleShowQuickActions}
    >
      <LabelContainer>
        {kind === 'partner' && (
          <Tooltip
            title={"Existing partner or a partner you've found with Breezy."}
          >
            <LeadLabel variant={'partner'} />
          </Tooltip>
        )}
        {kind === 'relevant' && (
          <Tooltip title={'Already added to Relevant.'}>
            <LeadLabel variant={'overlap'} />
          </Tooltip>
        )}
      </LabelContainer>

      <Popper
        style={{ zIndex: 1000 }}
        id={`item-qa-${channelId}`}
        placement={'top-start'}
        popperOptions={POPPER_PROPS}
        open={Boolean(showQuickActions)}
        anchorEl={showQuickActions}
      >
        <ActionsHolder>
          <ActionsBackground>
            <IntelligenceQuickActions
              id={id}
              domain={domain}
              partner={kind === 'partner'}
              prospect={['prospect', 'relevant'].includes(kind)}
              onAction={onQuickActions}
              contactStatus={contactStatus}
            />
          </ActionsBackground>
        </ActionsHolder>
      </Popper>

      <Content>
        <Header>
          <Domain icon={icon} domain={domain} />
          {Boolean(title) && <Title>{title}</Title>}
        </Header>
        <Tags>
          <TagList
            variant={'item'}
            tags={tags}
            additionalTagsCount={additionalTagsCount}
            onUpdate={(tag) => rest.onManageTags({ tag }, 'update')}
            onRemove={(tagId) =>
              rest.onManageTags({ channelId, tagId }, 'remove')
            }
            onDelete={(tagId) => rest.onManageTags({ tagId }, 'delete')}
            onManage={console.log /*e => itemActions.onManageTags(e, item.id)*/}
          >
            {Boolean(contactStatus) && <ContactStatus status={contactStatus} />}
            {Boolean(systemTags && systemTags.length > 0) && (
              <SystemTags data={systemTags} limit={1} />
            )}
          </TagList>
        </Tags>
      </Content>
    </Body>
  )
}

function EcosystemTable(props) {
  const { total, quickActionProps, selected, onQuickActions } = props
  const apiRef = useGridApiRef()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading !== props.loading) {
      setLoading(props.loading)
      if (!loading) {
        apiRef.current.forceUpdate()
      }
    }
  }, [props.loading])

  return (
    <ListContext.Provider
      value={{
        total,
        quickActionProps,
        selected,
        onSelect: props.onSelect,
        onGetAllIds: props.onGetAllIds,
        onQuickActions,
      }}
    >
      <DataGridPremium
        apiRef={apiRef}
        sortingOrder={['desc', 'asc']}
        disableVirtualization
        // disableColumnResize
        classes={classes}
        // rowHeight={69}
        hideFooter
        checkboxSelection
        disableColumnMenu
        disableRowSelectionOnClick
        disableSelectionOnClick
        rowSelectionModel={selected}
        onRowSelectionModelChange={props.onSelect}
        getRowClassName={(params) => {
          let classNames = []
          const activeItem = params.row.active
          if (activeItem) {
            classNames.push('active-details')
          }
          return classNames
        }}
        slots={{
          loadingOverlay: LoadingOverlay,
        }}
        {...props}
      />
    </ListContext.Provider>
  )
}

export default EcosystemTable
