import {Dialog, DialogActions} from '@material-ui/core'
import ModalLayout from '../../layouts/ModalLayout'
import React, {useState} from 'react'
import config from '../../config'
import Button from "../../components/Button"
import styled, {css} from 'styled-components'
import {Info as FeatherIconInfo} from 'react-feather'
const { pricing, credits, trialPeriod } = config
import {CalloutArrow, CalloutBody, CalloutRoot} from '../../pages/Welcome/Plans'
import Plan from '../Plan'

import { sendEvent } from '../../utils/events'


export const Callout = ({children, style, arrowStyle}) =>
  <CalloutRoot style={style}>
    <CalloutArrow style={arrowStyle}/>
    <CalloutBody>{children}</CalloutBody>
  </CalloutRoot>

export const Content = styled.div`
  //text-align: center;
  display: flex;
  align-items: center;
  text-align: left;
  flex-direction: column;
  padding: 0 2em;

  @media (max-width: 600px) {
    padding: 0;
  }

  h3 {
    font-size: 24px;
    color: #000021;
  }
  h1 {
    text-align: center;
    max-width: 28ch;
    margin: 0 auto;    
    font-family: "GT Super Display", serif;
    font-size: 3em;
    strong {
      text-decoration: underline;
    }
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #000021;
    margin: 16px 0 0 0;
  }
`;
const Options = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  gap: 1.5em;
  padding: 2em 2em;
  margin-top: 2em;
  max-width: 150ch;
  border-radius: 40px;
  background-color: rgb(1,99,250);
`

const Info = styled.div`
  display: flex;
  padding: 1em 2em 1.2em;
  max-width: 500px;
  margin: 0 auto;
  align-items: center;
`
const InfoContent = styled.div`
  font-size: 1.5em;
  flex: 1 1 auto;
  font-family: Roboto, Arial, sans-serif;
  padding-left: 0.5em;
  opacity: 0.8;
`
const InfoIcon = styled(FeatherIconInfo)`
  width: 28px;
  height: 28px;
  flex: 1 0 28px;
  stroke: ${props => props.theme.palette.primary.main};
`

function messageSales (plan) {
  return window.Intercom('showNewMessage', plan === 'quarterly' ? config.SALES_MESSAGE_QUARTERLY : config.SALES_MESSAGE_ANNUALLY)
}

const PaywallModal = ({ open, brandId, onClose }, props) => {
  const [option, setOption] = useState(props.option || 'annually');

  const onContact = (plan) => {
    messageSales(plan)
    sendEvent(brandId, 'contactSalesButton')
    if (Boolean(onClose)) {
      onClose()
    }
  }

  function handleOptionChange({ target: { value } }) {
    setOption(value);
  }

  return <Dialog
    open={open}
    aria-labelledby="paywall-modal"
    maxWidth={'lg'}
    fullScreen
    onEscapeKeyDown={onClose}

  >
    <ModalLayout
      noPadding
      onClose={onClose}
    >
    <Content>
      <h1>Request full access today!</h1>
      <p style={{fontSize: '1.4em', padding: '0 2em'}}>Renew each quarter or plan for the long term</p>
      <Options>
        {/*<Callout style={{position: 'absolute', top: '0.8%', left: '77%', display: 'inline-flex', flexDirection: 'column-reverse' }} arrowStyle={{transform: 'rotate(180deg)', position: 'relative', left: 0}}>Level up<br/>plans anytime</Callout>*/}
        <Plan title="Basic" price="800" description="An entry tool for those doing light discovery" sc={1} scextra={650} cc={50} ccextra={30} />
        <Plan title="Pro" price="2,700" off={15} description="For dedicated partner managers growing a program" sc={4} scextra={550} cc={200} ccextra={25} />
        <Plan title="Scale" price="6,000" off={25} description="For teams of managers scaling to multiple regions" sc={10} scextra={500} cc={500} ccextra={20} />
        <Plan title="Elite" price="10,500" off={35} description="For multi-brand Agencies or global Enterprise" sc={20} scextra={450} cc="1K" ccextra={15} />
        {/*<Options>*/}
        {/*  <Option padding={'1em 3em 1em'} active={option === 'quarterly'}>*/}
        {/*    <div>*/}
        {/*      <RadioGroup>*/}
        {/*        <RadioButton*/}
        {/*          checked={option === 'quarterly'}*/}
        {/*          type={'radio'}*/}
        {/*          name={'option'}*/}
        {/*          value={'quarterly'}*/}
        {/*          onChange={handleOptionChange}*/}
        {/*        />*/}
        {/*        <OptionHeading>Quarterly</OptionHeading>*/}
        {/*      </RadioGroup>*/}
        {/*      <p>Run bespoke Live Searches yielding thousands of Leads. Refreshed quarterly.</p><br/>*/}
        {/*      <CheckedList bold disablePadding>*/}
        {/*        <li>90 day access to each Live Search</li>*/}
        {/*        <li>1x Live Search</li>*/}
        {/*        <li>2x Hours Training</li>*/}
        {/*        <li>Hands on Support</li>*/}
        {/*      </CheckedList>*/}
        {/*    </div>*/}
        {/*    <PriceContainer>*/}
        {/*      <PricePeriod dense>From</PricePeriod>*/}
        {/*      <Price aria-label={`£${pricing.assisted} quarterly`}><PriceValue>£500</PriceValue>*/}
        {/*        <PricePeriod><span>+VAT</span><span>per Live Search</span></PricePeriod>*/}
        {/*      </Price>*/}
        {/*    </PriceContainer>*/}
        {/*  </Option>*/}
        {/*  <Option padding={'1em 3em 1em'} active={option === 'annually'}>*/}
        {/*    <div>*/}
        {/*      <BestOption offset={'2.2em'} />*/}
        {/*      <RadioGroup>*/}
        {/*        <RadioButton*/}
        {/*          checked={option === 'annually'}*/}
        {/*          type={'radio'}*/}
        {/*          name={'option'}*/}
        {/*          value={'annually'}*/}
        {/*          onChange={handleOptionChange}*/}
        {/*        />*/}
        {/*        <OptionHeading>Annually</OptionHeading>*/}
        {/*      </RadioGroup>*/}
        {/*      <p>Get everything on our Quarterly plan plus...<br/><br/><br/></p>*/}

        {/*      <CheckedList bold disablePadding>*/}
        {/*        <li>365 day access to each Live Search</li>*/}
        {/*        <li>Quarterly search refresh</li>*/}
        {/*        <li>10% discount</li>*/}
        {/*      </CheckedList>*/}
        {/*    </div>*/}
        {/*    <PriceContainer>*/}
        {/*      <PricePeriod dense>From</PricePeriod>*/}
        {/*      <Price aria-label={`£${pricing.assisted} annually`}>*/}
        {/*        <PriceValue>£1,800</PriceValue>*/}
        {/*        <PricePeriod><span>+VAT</span><span>per Live Search</span></PricePeriod>*/}
        {/*      </Price>*/}
        {/*    </PriceContainer>*/}
        {/*  </Option>*/}
        {/*</Options>*/}
      </Options>
    </Content>

      <DialogActions style={{justifyContent: 'center',  paddingBottom: 20, paddingTop: 20, flexDirection: 'column'}}>
        <Info>
          {/*<InfoIcon size={28}/>*/}
          {/*<InfoContent>*/}
          {/*  Get 20% off with an annual subscription.*/}
          {/*  /!*You have the option to request contact information for <strong>£2.50</strong> per verified contact. Final pricing will reflect the volume of Live Searches and Verified Contacts requested.*!/*/}
          {/*</InfoContent>*/}
        </Info>
        <Button variant={'contained'} color={'primary'} size={'large'} onClick={() => onContact(option)}>Contact Sales</Button>
        <p>or contact us at <a href="mailto:customersuccess@breezy.io">customersuccess@breezy.io</a></p>
      </DialogActions>
    </ModalLayout>
  </Dialog>
}

export default PaywallModal
