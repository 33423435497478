import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Chip, IconButton } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'


import SelectDropdown from './SelectDropdown'

const Actions = styled.div`
  display: flex;
  opacity: 1;
`

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  // &:hover ${Actions} {
  //   opacity: 1;
  // }
`

const TagsContainer = styled.div`
  /* top | right | bottom | left */
  > * {
    margin: 0 10px 0 0!important;
  }
`

const Placeholder = styled.span`
  color: grey;
`

export default function MultipleValues ({ values, options, limitTags, labelField, valueField, placeholder, inputPlaceholder, onApply }) {
  const containerRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const limit = limitTags ? values.slice(0, limitTags) : values

  const showRest = limitTags && limit.length === limitTags && values.length > limitTags

  const onRemove = (id) => e => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    onApply(values.filter((value) => value.id !== id))
  }

  const onOpen = () => {
    if (!anchorEl) {
      setAnchorEl(containerRef.current)
    }
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return <Root ref={containerRef} onClick={onOpen}>
    <TagsContainer>
      {
        Boolean(!limit.length) && <Placeholder>{placeholder}</Placeholder>
      }
      {
        limit.map(({ id, [labelField]: label }) => <Chip key={id} size={'small'} label={label} onDelete={onRemove(id)}/>)
      }
      {
        showRest && <strong>+{values.length - limitTags}</strong>
      }
    </TagsContainer>
    <Actions>
      {/*{*/}
      {/*  Boolean(values.length) && (*/}
      {/*    <IconButton onClick={({ target }) => setAnchorEl(target)}>*/}
      {/*      <ArrowDropDown />*/}
      {/*    </IconButton>*/}
      {/*  )*/}
      {/*}*/}
      <IconButton onClick={onOpen}>
        <ArrowDropDown />
        {/*{ anchorEl ? <ArrowDropUp /> : <ArrowDropDown /> }*/}
      </IconButton>
    </Actions>
    {
      Boolean(anchorEl) && (
        <SelectDropdown
          multiple
          anchorEl={anchorEl}
          labelField={labelField}
          valueField={valueField}
          options={options}
          selected={values.map(({ id }) => id)}
          placeholder={inputPlaceholder}
          onClose={onClose}
          onApply={onApply}
        />
      )
    }
  </Root>
}

MultipleValues.defaultProps = {
  values: [],
  options: []
}
