import React, { Component } from 'react'
import styled, {css} from 'styled-components'
import ButtonBase from '@mui/material/ButtonBase'
import WidgetBase from '../Base/WidgetBase'
import {WidgetBaseProps} from '../Base/WidgetBase'
import scoreColor from '../../../utils/scoreColor'
import metrics from '../../../configMetrics'

const ScoreRoot = styled(ButtonBase)`
  background: ${props => props.loading ? 'transparent' : scoreColor(props.value, props.theme)};
  box-shadow: none;
  width: 100%;
`

interface WidgetScoreProps extends WidgetBaseProps {
    variant?: 'score' | 'avgScore'
    onClick?: Function
}

function WidgetScore ({value, variant, onClick, ...other}: WidgetScoreProps) {
    const _value = (value === 0 || value === null) ? undefined : value

    return (
        <WidgetBase
            label={other.label || metrics[variant].shortName}
            tooltip={metrics[variant]}
            value={_value}
            component={ScoreRoot}
            onClick={() => onClick(metrics[variant].target)}
            {...other}
        />
    )
}

WidgetScore.defaultProps = {
    variant: 'score',
    onClick: () => void(0)
}

export default WidgetScore
