import React from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'

import { Get, Put } from '../../utils/request'
import GqlClient from '../../gql/gql-client'
import JoinLayout from '../../layouts/Welcome'
import Notification from '../../components/Notification'
import JoinVerifyLayout from '../../layouts/WelcomeDiscovery'
import SigninForm from '../../components/Forms/Signin'
import SignupForm from '../../components/Forms/SignUp'
import JoinAccount from '../../components/Forms/JoinAccount'
import EmailForm from '../../components/Forms/Email'
import Verify from '../Welcome/Verify'
import hasError from '../../stores/request-message'
import { JOIN_ACCOUNT_DETAILS, JOIN_TO_BRAND } from '../../gql/join'
import { GET_BRAND } from '../../gql/brands'

const JOIN_LAYOUT_STEPS = ['signup', 'signin', 'account', 'change-email']

const getBrand = async (id) => {
  const { success, ...rest } = await GqlClient.query({ query: GET_BRAND, variables: { id } })

  if (success) {
    const brand = rest.data['brand']
    return { success, brand }
  }
  return { success }
}

// const joinUser = async (id) => {
//   const { success,  ...rest } = await GqlClient.mutation({ mutation: JOIN_TO_BRAND, variables: { id } })
//   if (success) {
//     const user = rest.data['joinToBrandViaInvite']
//
//     return { success, user }
//   }
//   return { success }
// }

const getAccountDetails = async (id, brandId) => {
  const { success, ...rest } = await GqlClient.query({ query: JOIN_ACCOUNT_DETAILS, variables: { id, brandId }, unauthorized: true })

  if (success) {
    const accountUser = rest.data['accountUserInfo']

    return { success, accountUser }
  }
  return { success }
}

@inject(({ UserStore }) => ({
  thinking: UserStore.thinking,
  user: UserStore.user,
  onLogout: () => UserStore.signout(),
  onJoin: (id) => UserStore.join(id),
  onSignin: details => UserStore.signin(details),
  onSignup: details => UserStore.signup(details),
  onUpdateAccount: details => UserStore.update(details, true),
  onSendEmail: (email, brand, account) => UserStore.updateEmail(email, `join/${brand}/${account}/verify`),
  onVerify: (token) => UserStore.verify(token),
}))
@observer
@withRouter
export default class Join extends React.Component {
  constructor (props) {
    super(props)
    const {
      location: { search },
      match: { params: { brandId, accountUserId, step } }
    } = this.props
    const { token } = qs.parse(search)
    this.state = { brandId, accountUserId, step, token, loading: true, email: null }
  }

  async componentDidMount () {
    const { authorized, user } = this.props
    const { brandId, accountUserId } = this.state
    const { accountUser } = await getAccountDetails(accountUserId, brandId)

    if (authorized && accountUser.email === user.email) {
      if (user.firstName && user.lastName) {
        this.onJoinUser().then()
      } else {
        this.setState({ loading: false })
        this.onSetStep('account')
      }
    } else {
      await this.props.onLogout()
      this.setState({
        loading: false,
        email: accountUser.email
      })
    }
  }

  onJoinUser = async () => {
    const { brandId, accountUserId } = this.state
    const { success, message } = await this.props.onJoin(accountUserId)
    if (success || message === 'alreadyJoined') {
      const { brand } = await getBrand(brandId)
      hasError(true, null, `You've successfully joined ${brand.name} team`)
      this.props.history.push({ pathname: `/brand/${brandId}`})
    } else {
      this.setState({
        loading: false,
        error: 'Looks like your invitation link was canceled.'
      })
    }
  }

  onSetStep = step => {
    if (step !== this.state.step) {
      const { accountUserId, brandId } = this.state
      this.props.history.push({ pathname: `/join/${brandId}/${accountUserId}/${step}` })
      this.setState({ step })
    }
  }

  onSignin = async (details) => {
    const { success } = await this.props.onSignin(details)
    if (success) {
      const { firstName, lastName } = this.props.user
      if (!firstName && !lastName) {
        this.onSetStep('account')
      } else {
        await this.onJoinUser()
      }
    }
    return { error: !success }
  }

  onSignup = async (details) => {
    const { error } = await this.props.onSignup(details)
    if (!error) {
      this.onSetStep('account')
    }
    return { error }
  }

  onUpdateAccount = async (details) => {
    const { error } = await this.props.onUpdateAccount(details)
    if (!error) {
      await this.onJoinUser()
    }
    return { error }
  }

  onChangeEmail = async ({ email }) => {
    const { accountUserId, brandId } = this.state
    const { error } = await this.props.onSendEmail(email, brandId, accountUserId)
    if (!error) {
      this.onSetStep('verify')
    }
    return { error }
  }

  onVerify = async (token) => {
    const { success } = await this.props.onVerify(token)
    if (success) {
      setTimeout(this.onJoinUser, 1500)
    }
  }

  onToggleAuthViews = (kind) => {
    this.onSetStep(kind)
  }

  render () {
    const {
      loading,
      email,
      step,
      token
    } = this.state
    const {
      thinking,
      user
    } = this.props
    const {
      email: currentUserEmail,
      emailStatus
    } = (user || {})

    return (
      <>
        {
          JOIN_LAYOUT_STEPS.includes(step) && (
            <JoinLayout thinking={loading}>
              {
                step === 'signin' && (
                  <SigninForm
                    thinking={thinking}
                    email={email}
                    header={'Sign In'}
                    onSignUp={this.onToggleAuthViews.bind(this, 'signup')}
                    onSubmit={this.onSignin}
                  />
                )
              }
              {
                step === 'signup' && (
                  <SignupForm
                    thinking={thinking}
                    email={email}
                    header={'Sign up'}
                    subHeader={'Sign up to create an account and start your journey towards easy-breezy partner discovery.'}
                    onSignIn={this.onToggleAuthViews.bind(this, 'signin')}
                    onSubmit={this.onSignup}
                  />
                )
              }
              {
                step === 'account' && (
                  <JoinAccount
                    header={'Tell us about yourself'}
                    subHeader={'We just need a few details to get your account ready. Nearly there!'}
                    thinking={thinking}
                    onSubmit={this.onUpdateAccount}
                  />
                )
              }
              {
                step === 'change-email' && (
                  <EmailForm
                    thinking={thinking}
                    subHeader={'Please enter your correct email address so we can send you notifications and handy updates.'}
                    onSubmit={this.onChangeEmail}
                  />
                )
              }
            </JoinLayout>
          )
        }

        {
          step === 'verify' && (
            <JoinVerifyLayout thinking={loading}>
              {
                emailStatus === 'verified' && (
                  <Notification
                    open
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    message={'Email address has been verified!'}
                    timeout={3000}
                  />
                )
              }
              <Verify
                email={currentUserEmail}
                emailStatus={emailStatus}
                token={token}
                onVerify={this.onVerify}
                onUpdateEmail={this.onSetStep.bind(this, 'change-email')}
              />
            </JoinVerifyLayout>
          )
        }
      </>
    )
  }
}
