import React, { useState } from 'react'
import styled from 'styled-components'
import {StyledEngineProvider} from '@mui/material/styles'
import {
  Paper,
  Popover,
  TextField,
  Checkbox as MuiCheckbox,
  CircularProgress, Button
} from '@mui/material'
import { Autocomplete } from '@mui/material'

const Checkbox = styled(MuiCheckbox)`
  padding: 0 8px 0 0!important;
  margin-left: 0!important;
`

export const ListContainer = styled.div`
  width: 100%;
  //max-height: 300px;
  overflow: hidden;
`

const Root = styled(Paper)`
  width: 400px;
  box-shadow: none;
`

const Input = styled(TextField)`
  padding: 1em 1em 0;
  display: block;
  width: auto;
`

const Message = styled.div`
  padding: 1em;
  // text-align: center;
  a {
    cursor: pointer;
    color: rgba(1, 99, 250, 1);
  }
`

const AutocompletePopperComponent = styled.div`
  background-color: #fff;
  width: 100%!important;
`

const Option = styled.li`
  // display: inline-block;/**/
  padding: 0.3em 1em;
`

const Actions = styled.div`
  display: flex;
  padding: 10px 15px;
  align-items: center;
  justify-content: end;
`

const AutocompleteOption = ({ optionProps, multiple, selected, option, valueField, labelField, onCheck }) => (
  <StyledEngineProvider injectFirst>
    <Option {...optionProps}>
      {
        multiple && (
          <Checkbox
            size={'small'}
            edge="start"
            checked={selected}
            tabIndex={-1}
            disableRipple
            onChange={onCheck(option[valueField])}
          />
        )
      }
      <span>{option[labelField]}</span>
    </Option>
  </StyledEngineProvider>
)

export default function SelectDropdown ({ thinking, query, open, anchorEl, placeholder, valueField, labelField, options, multiple, ...rest }) {
  const [selected, setSelected] = useState(rest.selected || [])
  const [input, setInput] = useState(false)
  const [think, setThinking] = useState(thinking)
  const [search, setSearch] = useState(query || '')

  const hasChanges = multiple && JSON.stringify(selected) !== JSON.stringify(rest.selected)
  const hasSelection = multiple && selected && selected.length

  const onCheck = (id) => (e) => {
    const index = selected.indexOf(id)
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    if (index < 0) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter(value => value !== id))
    }
  }

  const onSelect = (value) => {
    if (multiple) {
      const index = selected.indexOf(value)
      if (index < 0) {
        const ids = [...selected, value]
        rest.onApply(options.filter(({ [valueField]: id }) => ids.includes(id)))
        rest.onClose()
      } else {
        setSelected(selected.filter(sId => value !== sId))
      }
    } else {
      rest.onApply(value)
      rest.onClose()
    }
  }

  const onApply = () => {
    rest.onApply(options.filter(({ [valueField]: id }) => selected.includes(id)))
    rest.onClose()
  }

  const onReset = () => {
    setSelected([])
    // rest.onApply([])
    // rest.onClose()
  }

  const onKeyPress = ({ key }) => {
    if (key) {
      if (key === 'Escape') {
        setSearch('')
        rest.onClose()
      }
    }
  }

  return (
    <StyledEngineProvider injectFirst>
    <Popover
      open={open || Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={rest.onClose}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top'
      }}
    >
      <Root elevation={0}>
        <Autocomplete
          open
          freeSolo
          handleHomeEndKeys
          blurOnSelect
          clearOnBlur
          selectOnFocus
          options={options}
          inputValue={search}
          filterOptions={() => {
            const filteredOptions = options.filter(({ [labelField]: label }) => label && label.toLowerCase().includes(search.toLowerCase()))
            const __options = search ? filteredOptions : options
            return __options.length ? __options : [{ id: 'empty' }]
          }}
          renderOption={(props, option, state) => {
            if (option.id === 'empty') {
              return <Message>
                Ooops, nothing matches your query!
                <br/>
                <a onClick={(e) => {
                  e.preventDefault()
                  setSearch('')
                }}>Reset</a>
              </Message>
            }

            return <AutocompleteOption
              key={option[valueField]}
              multiple={multiple}
              option={option}
              selected={selected.includes(option[valueField])}
              valueField={valueField}
              labelField={labelField}
              onCheck={onCheck}
              optionProps={props}
            />
          }}
          onInputChange={(e, value, action) => {
            if (action === 'input') {
              setSearch(value)
            } else if (action === 'clear') {
              setSearch('')
            }
          }}
          onChange={({ target: { nodeName } }, option) => {
            if (typeof option === 'string') {
              console.log('onEnter', option)
            } else if (option && !option.id) {
              console.log('onCreate', option)
            } else if (nodeName !== 'INPUT') {
              onSelect(multiple ? option[valueField] : option)
            }
          }}
          renderInput={(params) => (
            <Input
              {...params}
              autoFocus
              inputRef={node => setInput(node)}
              variant={'outlined'}
              placeholder={placeholder || 'Search a something...'}
              // onKeyDown={onKeyPress}
              endAdornment={() => think ? <CircularProgress color="inherit" size={20} /> : null}
            />
          )}
          PaperComponent={ListContainer}
          PopperComponent={AutocompletePopperComponent}
        />

        {
          (Boolean(multiple)) && (
            <Actions>
              <Button variant={'outlined'} onClick={onReset}>Reset</Button>
              &nbsp;&nbsp;
              <Button disabled={!hasChanges} variant={'contained'} onClick={onApply}>Apply</Button>
            </Actions>
          )
        }
      </Root>
    </Popover>
    </StyledEngineProvider>
  )
}

SelectDropdown.defaultProps = {
  selected: [],
  options: [],
  valueField: 'id',
  labelField: 'label'
}
