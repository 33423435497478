import React from 'react'
import styled from 'styled-components'
import {
  FormControlLabel as MuiFormControl,
  Checkbox as MuiCheckbox,
  Grid,
} from '@material-ui/core'
import {Form, Input} from '../Form'
import Button from '../Button'
import Validator from '../../utils/validator'

const FORM_ID = 'user-form'

const ActionsHolder = styled.div`
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
`
const GridInput = styled(Grid)`
  max-width: 300px;
  margin: 0 auto 24px auto;
  width: 100%;
`

const FormControlLabel = styled(MuiFormControl)`
  margin: 0;
  align-items: self-start;
  .MuiFormControlLabel-label {
    color: #3E3E3E;
    font-size: 14px;
    line-height: 16px;
  }
`

const Checkbox = styled(MuiCheckbox)`
  padding: 0;
  margin: 0 16px 0 0;
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #0163FA;
  }
`

const schema = [
  {
    field: 'firstName',
    rules: 'required',
    messages: {
      required: 'First name is required'
    }
  },
  {
    field: 'lastName',
    rules: 'required',
    messages: {
      required: 'Last name is required'
    }
  },
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  }
]

export default class UserForm extends React.Component {
  constructor(props) {
    super(props)
    const {firstName, lastName, email, consent} = this.props
    this.state = {firstName, lastName, email, consent, errors: {}, error: null}
  }

  onChange = name => ({target: {value, checked}}) => {
    this.setState({[name]: name === 'consent' ? checked : value})
    this.resetError(name)
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      async () => {
        const {firstName, lastName, email, consent} = this.state
        const {error} = await this.props.onSubmit({firstName, lastName, email, consent})

        if (error) {
          const message = error === 'emailExists' ? 'User with given email already exist' : error
          this.setState({errors: { email: message }})
        }
      })
  }

  onCancel = () => {
    const {email, consent, firstName, lastName} = this.props
    this.setState({firstName, lastName, email, consent, errors: {}, error: null})
  }

  render() {
    const {firstName, lastName, email, consent, errors} = this.state
    const {
      header,
      subHeader,
      welcome,
      thinking,
      error
    } = this.props

    const Actions = () => <ActionsHolder>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        mb={2}
        disabled={thinking || !consent}
      >
        {welcome ? 'Continue' : 'Sign in'}
      </Button>
      {
        !welcome && <Button
          color="primary"
          mb={2}
          disabled={thinking}
          onClick={this.onCancel}
        >
          Cancel
        </Button>
      }
    </ActionsHolder>

    return (
      <Form
        noValidate
        header={header}
        subHeader={subHeader}
        formId={FORM_ID}
        onSubmit={this.onSubmit}
        actions={Actions}
        error={error}
        thinking={thinking}
      >
        <Grid container>
          <GridInput item xs={12}>
            <Input
              autoFocus
              required
              fullWidth
              placeholder="e.g. Joe"
              margin="none"
              id={'firstName'}
              label={'First name'}
              inputLabel={'First name'}
              variant={'outlined'}
              fieldType='clearable'
              value={firstName}
              disabled={thinking}
              error={errors.firstName}
              onChange={this.onChange('firstName')}
            />
          </GridInput>
          <GridInput item xs={12}>
            <Input
              required
              fullWidth
              placeholder="e.g. Bloggs"
              margin="none"
              id={'lastName'}
              label={'Last name'}
              inputLabel={'Last name'}
              variant={'outlined'}
              fieldType='clearable'
              value={lastName}
              disabled={thinking}
              error={errors.lastName}
              onChange={this.onChange('lastName')}
            />
          </GridInput>
          <GridInput item xs={12}>
            <Input
              margin="none"
              required
              fullWidth
              placeholder="e.g. joe.bloggs@example.com"
              id={'email'}
              fieldType='clearable'
              label={'Email'}
              inputLabel={'Email'}
              variant={'outlined'}
              value={email}
              error={errors.email}
              disabled={thinking}
              onChange={this.onChange('email')}
            />
          </GridInput>
          <GridInput item xs={12}>
            <FormControlLabel
              value={consent}
              control={<Checkbox color="primary" onChange={this.onChange('consent')}/>}
              label="Please tick this box to agree to receive an update about the Breezy launch."
            />
          </GridInput>
        </Grid>
      </Form>
    )
  }
}
