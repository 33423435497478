import React from 'react'
import styled from 'styled-components'
import {Tooltip} from '@material-ui/core'
import {rgba, darken} from 'polished'
import IconFound from '/src/components/Icons/Found'
import IconHasContacts from '/src/components/Icons/HasContacts'
import IconOptedout from '/src/components/Icons/Optedout'
import IconRequested from '/src/components/Icons/Requested'
import IconUnsuccessful from '/src/components/Icons/Unsuccessful'

const IconStatus = styled.div`
  color: ${props => contactStatus[props.variant].color(props.theme)};
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  height: 100%;
  & svg {
    font-size: 1.6em;
  }
`
const Root = styled.div`
  background: ${props => rgba(contactStatus[props.status].color(props.theme), 0.2)};
  color:  ${props => darken(0.1, contactStatus[props.status].color(props.theme))};
  height: 1.85em;
  line-height: 1.85em;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  padding: 0 0.5em 0 0.3em;
  font-size: ${props => props.fontSize ? props.fontSize : '0.83em'};
  & svg {
    position: relative;
    top: 0.16em;
    width: 1em;
    height: 1em;
  }
`

export const contactStatus = {
  requested: {
    icon:  <IconStatus variant={'requested'}><IconRequested/></IconStatus>,
    label: 'Requested',
    color: (theme) => theme.palette.brand.shadow.dark
  },
  found: {
    icon:  <IconStatus variant={'found'}><IconFound /></IconStatus>,
    label: 'Found',
    color: (theme) => theme.palette.success.main
  },
  unsuccessful: {
    icon:  <IconStatus variant={'unsuccessful'}><IconUnsuccessful/></IconStatus>,
    label: 'Unuccessfull',
    color: (theme) => theme.palette.error.main
  },
  optedout: {
    icon:  <IconStatus variant={'optedout'}><IconOptedout/></IconStatus>,
    label: 'Opted out',
    color: (theme) => theme.palette.error.main
  },
  hascontacts: {
    icon:  <IconStatus variant={'requested'}><IconHasContacts/></IconStatus>,
    label: 'Available',
    color: (theme) => theme.palette.success.main
  }
}

export const ContactStatus = ({status, fontSize}) => {
  const statusObject = contactStatus[status]

  if (!statusObject) return null

  return <Tooltip title={`Contacts ${status === 'unsuccessful' ? 'not found' : statusObject.label.toLowerCase()}`}>
    <Root status={status} fontSize={fontSize}>
      {statusObject.icon}&nbsp;{statusObject.label}
    </Root>
  </Tooltip>
}
