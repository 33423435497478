import React from 'react'
import styled, {css} from 'styled-components'
import { DataGridPremium as Grid, DataGridPremiumProps } from '@mui/x-data-grid-premium'

const Root = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  ${props => props.responsive && css`
    height: 100%;
    flex: 1 1 auto;
  `}
  & .MuiDataGrid-columnHeaders {
    background-color: ${props => props.theme.palette.brand.shadow.light};
    color: ${props => props.theme.palette.mutedText};
    font-weight: normal;
    font-size: 0.85em;
    border-radius: 0.5em;
    & .MuiSvgIcon-root {
      width: 0.8em;
      height: 0.8em;
      opacity: 0.75;
      color: ${props => props.theme.palette.mutedText};
    }
  }
`

export interface TableCompactProps extends DataGridPremiumProps {
    responsive?: boolean
}

function TableCompact ({ rows, columns, responsive, ...props }: TableCompactProps) {
    return (
        <Root responsive={responsive}>
          <Grid
              rows={rows}
              columns={columns}
              autoHeight={!responsive}
              disableVirtualization={!responsive}
              disableColumnMenu
              disableColumnSelector
              disableRowSelectionOnClick
              disableDensitySelector
              disableColumnResize
              disableColumnReorder
              rowSelection={false}
              disableColumnPinning
              density="compact"
              hideFooter
              sortingOrder={['desc', 'asc']}
              columnHeaderHeight={32}
              sx={{
                    border: 0,
                    '& .MuiDataGrid-columnSeparator': {
                      visibility: 'hidden!important',
                    },
                    '& .MuiDataGrid-cell': {
                      border: 0,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderBottom: 0,
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-columnHeaderTitleContainer': {
                        lineHeight: '1.5em',
                    },
                  '& .MuiDataGrid-columnHeaders .MuiIconButton-sizeSmall': {
                        width: 22,
                        height: 22,
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0',
                  },
                  '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'transparent',
                  },
                  '.MuiDataGrid-overlayWrapperInner': {
                        margin: '-1em',
                  }

              }}
              {...props}
          />
        </Root>
    )
}

export default TableCompact
