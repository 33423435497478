import React, { useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import {
  TextField,
  CircularProgress
} from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { useLazyQuery } from '@apollo/client'
import styled from 'styled-components'

import { CHANNELS_FILTER } from '../../pages/Intelligence/requests'

const FormTextField = styled(TextField)`
    .MuiFormLabel-root {
      color: #3E3E3E;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      -ms-transform: none;
      transform: none;
      margin: 0 0 5px 0;
      position: relative;
    }
    .MuiInput-root {
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0.2);
      background: #fff;
       box-sizing: border-box;
      border-radius: 3px;
      font-size: 14px;
      padding: 0;
      min-height: 48px;
    }
    .MuiInput-input {
      padding: 4px!important;
      line-height: 16px;
    }
    &.withHelpText {
      .MuiInput-root {
        padding: 25px 18px 5px 18px;
        min-height: 72px;
      }

      .MuiInputBase-adornedStart {
        padding: 25px 18px 5px 18px;
        min-height: 72px;
      }
    }
`

let typingTimeout = null

export default function ChannelsAutocomplete ({value, label, disabled, helpText, defaultValue, onChange, placeholder}) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loadData, { loading }] = useLazyQuery(CHANNELS_FILTER, {})

  const getData = async (search) => {
    const { data } = await loadData({
      variables: {
        ...(search && { search })
      }
    })

    const nodes = data['channelsConnection'].edges.map(({ node: { channelId, domain } }) => ({ id: channelId, label: domain }))

    setOptions(nodes)
  }

  const onToggleOpen = async () => {
    setOpen(!open)
    if (!open) {
      await getData()
    }
  }

  const onSearch = ({ target: { value } }) => {
    clearTimeout(typingTimeout)
    typingTimeout = setTimeout(async () => {
      await getData(value)
    }, 1000)
  }

  return (
    <>
      <MuiAutocomplete
        disableClearable
        // freeSolo
        open={open}
        loading={loading}
        options={options}
        disabled={disabled}
        groupBy={({group}) => group}
        getOptionLabel={option => option.label}
        getOptionDisabled={({ disabled }) => disabled}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onOpen={onToggleOpen}
        onClose={onToggleOpen}
        onInputChange={onSearch}
        renderInput={params => (
          <FormTextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              autocomplete: 'off',
              disableUnderline: true,
              endAdornment: (
                <React.Fragment>
                  { loading ? <CircularProgress color="inherit" size={20}/> : null }
                  { params.InputProps.endAdornment }
                </React.Fragment>
              ),
            }}
            disableUnderline
            classes={ helpText && {
              root: 'withHelpText',
            }}
            variant="standard"
            InputLabelProps={{shrink: true}}
            label={label}
            margin="normal"
            fullWidth
          />
        )}
      />
    </>
  )
}


