import React from 'react'
import { inject, observer } from 'mobx-react'
import Details from './Details'

const _Details = inject(({ SearchStore, SearchesStore, BrandStore }) => ({
  name: SearchStore.name,
  description: SearchStore.description || 'Untitled search',
  country: SearchStore.country,
  products: SearchesStore.products,
  onSubmit: (details) => SearchStore.update(details)
}))(observer(Details))

export default _Details
