import React from 'react'
import WidgetMetricBase, {MetricProps} from '../Base/WidgetMetricBase'
import metrics, {MetricKeysBase, Size} from '../../../configMetrics'

interface WidgetMetricProps extends MetricProps {
    variant: MetricKeysBase
    onClick?: Function
}

export function WidgetMetric ({variant, onClick, ...props}: WidgetMetricProps) {
    return (
        <WidgetMetricBase
            {...props}
            tooltip={metrics[variant]}
            label={metrics[variant].shortName}
            unit={metrics[variant].unit}
            onClick={() => onClick(metrics[variant].target)}
        />
    )
}

WidgetMetric.defaultProps = {
    value: 0,
    onClick: () => void(0)
}

export default WidgetMetric
