import React from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../../layouts/Welcome'
import VerifySimple from '../Welcome/VerifySimple'

export default function VerifySent () {
    const location = useLocation()
    // @ts-ignore
    const state = location.state || {}

    return (
        <Layout discovery autoWidth>
            {/*// @ts-ignore*/}
            <VerifySimple email={state.email || ''} />
        </Layout>
    )
}
