import React from 'react'
import styled from 'styled-components'
import { filter, includes } from 'lodash'
import is from 'is_js'
import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/addon/display/placeholder'
import {InputLabel, Button} from '@material-ui/core'

require('codemirror/lib/codemirror.css');
require('./codemirror.css');

const config = {
  infoPaneHeight: '50px'
}

const Root = styled.div`
  height: 100%;
  position: relative;
  .CodeMirror {
    height: 100%;
  }
  .codeMirror {
    height: 100%;
    .cm-s-material {
      .errorLine {
         color: #E51453;
        .CodeMirror-linenumber {
          color: #E51453;
       }
      } 
    }  
  }
`

const Mirror = styled.div`
   position: relative;
   height: 100%;
   background: #fff;
`;

const Wrapper = styled.div`
  border: 1px solid rgba(0,0,0,0.2);
  height: calc(100% - 30px);
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const FormInputLabel = styled(InputLabel)`
    color: #3E3E3E;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    transform: none;
    margin: 0 0 7px 0;
    position: relative;
    .MuiInputLabel-asterisk {
      display: none;
    }
`;

const ErrorText = styled.div`
  color: ${props => props.theme.palette.error.main};
  font-weight: normal;
  font-size: 14px;
  padding: 0 1em;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${config.infoPaneHeight};
  // background: ${props => props.theme.palette.brand.shadow.main};
  box-sizing: border-box;
  `

const HelpText = styled.p`
  color: #535353;
  font-weight: normal;
  font-size: 12px;
  position: relative;
  margin-bottom: 0;
  z-index: 10;
  padding: 0 120px 0 20px;
  user-select: none;
 
`;

const KeysCount = styled.span`
    position: absolute;
    right: 15px;
    top: 0;
    font-weight: bold;
    white-space: nowrap;
    background: white;
    z-index: 1;
    color: ${props => props.error && '#ec5e5e'};
`

const Message = styled.span`
  white-space: nowrap;
`

const MirrorBody = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: ${props => props.notification && config.infoPaneHeight};
`


const notUniqueIndexes = arr => {
  if (arr && arr.length) {
    const duplicatedItems = filter(arr, (val, i, iteratee) => includes(iteratee, val, i + 1))
    let indexes = []

    arr.forEach((item, index) => {
      if (duplicatedItems.includes(item) && !!item) {
        indexes.push(index)
      }
    })

    return indexes
  }
  return []
}

export default class TextRows extends React.Component {
  static defaultProps = {
    errorText: 'Please enter valid URL',
    values: []
  }

  constructor(props) {
    super(props)
    const {values, errors} = props
    this.state = {
      values: values.length ? values : [''],
      limitsCount: 1,
      errors: errors ? errors : [],
      errorText: '',
      duplicates: []
    }
  }

  errorsMarker = (editor, from) => {
    const {errors} = this.state
    const data = editor.getValue().split('\n')

    data.forEach((value, index) => {
      if (errors.includes(index)) {
        editor.addLineClass(index, '', 'errorLine')
      } else {
        editor.removeLineClass(index, '', 'errorLine')
      }
    })
  }

  validationUrl = (data) => {
    const {editor} = this.state
    let errors = []
    data.forEach((v, i) => {
      if (v.length !== 0) {
        if (!is.url(v)) {
          editor.addLineClass(i, '', 'errorLine')
          errors.push(i)
        }
      }
    })
    return errors
  }

  onChange = (editor) => {
    const {minRow, maxRow, validation, onChange} = this.props
    let data = editor.getValue().split("\n")
    let errors = []
    if (data[0].length < 3 && !['blocklist'].includes(validation)) {
      errors.push(0)
    }
    if (['competitors', 'partners', 'blocklist'].indexOf(validation) > -1) {
      let errorsValid = this.validationUrl(data)
      if (errorsValid.length) {
        errorsValid.forEach(v => {
          errors.push(v)
        })
      }
    }

    if (maxRow && editor.doc.size > maxRow) {
      errors.push('maxRow')
    }

    if (minRow && data.filter(value => !!value).length < minRow) {
      errors.push('minRow')
    }

    const trimmedData = data.map(value => {
      if (value) {
        return value.trim()
      }
      return value
    })

    const duplicatedItems = notUniqueIndexes(trimmedData)
    let duplicates = []


    if (duplicatedItems.length) {
      duplicatedItems.forEach((value) => {
        if (value !== trimmedData.indexOf(trimmedData[value])) {
          duplicates.push(value)
        }
      })
      errors = [...errors, ...duplicates]
      this.setState({ errorText: `There  ${duplicates.length === 1 ? 'is' : 'are'} ${duplicates.length} ${duplicates.length === 1 ? 'duplicate' : 'duplicates'}` })
    } else {
      this.setState({ errorText: '' })
    }

    const activeLine = editor.getCursor().line

    editor.removeLineClass(activeLine, '', 'errorLine')

    if (errors.includes('maxRow')) {
      if (editor.doc.size <= maxRow) {
        errors = errors.filter(item => item !== 'maxRow')
      }
    }

    if (onChange) {
      onChange(data, errors, duplicates)
    }
    this.setState({errors, duplicates}, () => this.errorsMarker(editor))
  }

  onRemoveDuplicates = () => {
    const { editor, duplicates } = this.state
    const data = editor.getValue().split("\n")
    const newValues = data.filter((v, i) => {
      return duplicates.indexOf(i) < 0
    })

    this.setState({ duplicates, values: newValues }, () => this.errorsMarker(editor, 'onRemoveDuplicates'))
  }

  editorDidMount = (editor) => {
    this.setState({editor: editor})
    this.errorsMarker(editor)
  }

  onChangeLimits = (editor) => {
    const rows = editor.getValue().split("\n").filter(r => !!r && r.length)
    this.setState({ limitsCount: rows.length || 1 })
  }

  render() {
    const {values, limitsCount, errors, duplicates} = this.state
    const { helpText, maxRow, minRow, minRowError, title, placeholder } = this.props

    const errorText = this.state.errorText || this.props.errorText


    return (
      <Root>
        {!!title && <FormInputLabel>{title}</FormInputLabel>}
        <Mirror>
          <Wrapper>
          <HelpText>
            <Message>{helpText}</Message>
            {
              !!maxRow && (
                <KeysCount
                  error={limitsCount > maxRow}
                >
                  {limitsCount} of {maxRow} max
                </KeysCount>
              )
            }
          </HelpText>
          <MirrorBody notification={!!duplicates.length || limitsCount > maxRow || errors.length > 0}>
            <CodeMirror
              style={{height: '100%'}}
              className={'codeMirror'}
              value={values}
              onKeyDown={
                (editor, event) => {
                  if (event.keyCode == 13 && editor.doc.size >= maxRow) {
                    event.preventDefault()
                  }
                }
              }
              options={{
                mode: 'application/json',
                theme: 'material',
                viewportMargin: 10,
                lineWrapping: true,
                styleActiveLine: true,
                lineNumbers: true,
                showCursorWhenSelecting: true,
                placeholder: placeholder
              }}
              onChange={(editor) => {
                this.onChange(editor)
                this.onChangeLimits(editor)
              }}
              editorDidMount={(editor) => {
                this.editorDidMount(editor)
                this.onChangeLimits(editor)

                editor.on('keyup', (cm, event) => {
                  if (event.key === ',') {
                    const doc = cm.getDoc()
                    const cursor = doc.getCursor()
                    const line = doc.getLine(cursor.line)
                    const from = {
                      line: cursor.line,
                      ch: 0
                    }
                    const to = {
                      line: cursor.line,
                      ch: cursor.ch
                    }
                    doc.replaceRange(line.replace(',', '') + '\n', from, to)
                  }
                })

                editor.on('paste', (cm, event) => {
                  // Stop data actually being pasted into div
                  event.stopPropagation()
                  event.preventDefault()

                  const doc = cm.getDoc()
                  const cursor = doc.getCursor()
                  const line = doc.getLine(cursor.line)
                  const position = {
                    line: cursor.line + (line.length ? 1 : 0),
                    ch: 0
                  }
                  // Get pasted data via clipboard API
                  const clipboardData = event.clipboardData || window.clipboardData
                  const clipboardText = clipboardData.getData('Text') || ''
                  const pastedData = clipboardText.replace(/,/g, '\n')

                  doc.replaceRange(line.length ? `\n${pastedData}\n` : `${pastedData}\n`, position)

                })
              }}
            />
            <ErrorText>
              { errors.includes('minRow') && minRowError }

              { !errors.includes('minRow') && errors.length > 0 && errorText }

              { limitsCount > maxRow && 'You have exceeded the limit of the lines, max ' + maxRow }

              &nbsp; &nbsp;

              {
                !!duplicates.length && <Button
                  size={'small'}
                  disableElevation
                  style={{background: '#D0D8EC'}}
                  // color={'primary'}
                  variant="contained"
                  mb={2}
                  ml={2}
                  onClick={this.onRemoveDuplicates}
                >
                  Remove duplicates
                </Button>
              }
            </ErrorText>
          </MirrorBody>
          </Wrapper>
        </Mirror>
      </Root>)
  }
}
