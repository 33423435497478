import React from 'react'
import { inject, observer } from 'mobx-react'

import CreatorSettings from './CreatorSettings'


@inject(({ UserStore }) => ({
  user: UserStore.user,
  onResetPassword: details => UserStore.forgotPassword(details),
  onUpdateEmail: email => UserStore.updateEmail(email, 'verify'),
  onSubmitAccount: details => UserStore.update(details),
}))
@observer
export default class _CreatorSettings extends React.Component {

  render () {

    return <CreatorSettings
      {...this.props}
    />
  }
}
