import React, { useState } from 'react'
import { PlusCircle } from 'react-feather'
import styled, {css} from 'styled-components'
import { Button, Tooltip, Typography } from '@material-ui/core'
import TextAvatar from 'react-avatar'
import CountryFlag from '../../components/CountryFlag'
import DiscoveryDetailsModal from '../Discovery/DiscoveryDetailsModal'
import { SkeletonPlaceholder } from '../../components/SkeletonPlaceholder'
import IconLabelSearch from '../IconLabelSearch'
import { onOpenExpiredModal } from '../ExpiredSearchBanner/ExpiredSearchBanner'

import config from '../../config'

const imageKitUrl = process.env.IMAGEKIT_URL


const Root = styled.div`
  //margin: 2em 1em 1em;
  display: flex;
  align-items: center;
  min-height: 48px;
  position: relative;
  white-space: nowrap;
  cursor: ${props => props.editable ? 'pointer' : 'default'}
  ${props => props.editable && css`
    background: transparent;
    border-radius: 5px;
    padding: 0.2em 0.7em;
    margin: -0.2em -0.7em;
    
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
    &:active {
      background: rgba(0, 0, 0, 0.1);
    }
  `}
`

const Title = styled(Typography)`
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  padding: 10px 20px 10px 0;
  font-family: Work Sans, Arial, sans-serif;
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  cursor: ${props => props.editable ? 'pointer' : 'default'};
`

const Discovery = styled.div`
  display: flex;
  flex-direction: column;
  //margin-top: 1em;
  font-family: Roboto, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  cursor: ${props => props.editable ? 'pointer' : 'default'};
  border-left: 1px solid rgba(0,0,0,0.05);
  padding-left: 1em;

`

const DiscoveryTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`

const DiscoveryOwner = styled.div`
  font-size: 12px;
  padding-bottom: 0.4em;
`

const DashboardHeader = ({
                           editable,
                           loading,
                           name,
                           country,
                           products,
                           ownerFullName,
                           ownerName,
                           title,
                           variant,
                           onCreate,
                           onUpdate,
                           ...rest
                         }) => {
  const [open, setOpen] = useState(0)

  const onOpen = () => {
    if (open !== 1 && variant === 'discovery') {
      if (rest.expired) {
        onOpenExpiredModal()
      } else {
        setOpen(1)
      }
    }
  }

  const onClose = () => {
    setOpen(0)
  }


  return (
    <Root editable={editable} onClick={onOpen}>
      <Title editable={editable}>{ loading ? <SkeletonPlaceholder style={{minWidth: '200px'}}  width={'80%'} color={'#93a5c2'}  highlightColor={'#a8bcdc'}/> : editable ? <IconLabelSearch>{title}</IconLabelSearch> : title}</Title>
      {
        variant === 'home' && (
          <Tooltip title={'New search'}>
            <Button
              disableElevation
              style={{marginLeft: '1em', marginTop: '0.2em', fontWeight: 'bold', lineHeight: '1.3', padding: '0 1em 0', height:30 }}
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={onCreate}
            >
              <PlusCircle size={16} />&nbsp;&nbsp;New search
            </Button>
          </Tooltip>
        )
      }

      {
        variant === 'discovery' && (
          <Discovery editable={editable}>
            <DiscoveryTitle>
              {
                loading ? <SkeletonPlaceholder  width={'80%'} color={'#93a5c2'}  highlightColor={'#a8bcdc'}/> : <>
                  {name}
                  &nbsp;
                  <CountryFlag
                    country={country}
                    alt={country}
                    fallbackImageURL={`${imageKitUrl}/flags/${country}.svg`}
                  />
                </>
              }
            </DiscoveryTitle>
            <DiscoveryOwner>
              {
                loading ? <SkeletonPlaceholder  width={'80%'} color={'#93a5c2'}  highlightColor={'#a8bcdc'}/> : <>
                  <TextAvatar
                    name={ownerFullName}
                    textSizeRatio={2}
                    colors={config.avatarColors}
                    fgColor={'#000'}
                    size={15}
                    round={15}
                    variant="circle"/>
                  &nbsp;
                  {ownerName}
                </>
              }
            </DiscoveryOwner>
          </Discovery>
        )
      }
      {
        editable && Boolean(open) && (
          <DiscoveryDetailsModal
            open={Boolean(open)}
            name={name}
            country={country}
            description={title}
            products={products}
            onSubmit={onUpdate}
            onClose={onClose}
          />
        )
      }
    </Root>
  )
}

export default DashboardHeader
