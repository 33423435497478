import React from 'react'
import {
  Grid
} from '@material-ui/core'
import { Autocomplete, Form, Input } from '../Form'
import Validator from '../../utils/validator'
import { classifications } from '../../data/classifications'
import { FormsModal } from './FormsModal'
import TipsText from '../TipsText'

const FORM_ID = 'channel-form'

const schema = [
  {
    field: 'link',
    rules: 'required|valid_url',
    messages: {
      required: 'URL is required',
      valid_url: 'Please enter valid URL'
    }
  }
]

export default class ChannelForm extends React.Component {
  constructor(props) {
    super(props)
    const { link } = this.props
    this.state = { link, errors: {}, error: null }
  }

  componentDidMount () {
    if (this.props.initialValidation) {
      Validator(FORM_ID, schema,
        errors => this.setState({errors}),
        () => {}
      )
    }
  }

  onChange = name => ({target: {value}}) => {
    this.setState({[name]: value})
    this.resetError(name)
    if (this.props.onChange) {
      this.props.onChange({[name]: value})
    }
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    const { link } = this.state
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      () => {
        this.props.onSubmit({ link })
      }
    )
  }

  onCancel = () => {
    const { link } = this.props
    this.setState({ link, errors: {}, error: null})
  }

  render() {
    const { link, errors} = this.state
    const {
      thinking,
      error,
      stepCount,
      step,
      noText,
      fullWidth,
      disableChannel,
      createRef,
      variant
    } = this.props
    const Text = () =>
      <>
        <h2 style={{marginTop: '1em'}}>{variant === 'update' ? 'Update your Brand’s URL' : 'Input your Brand’s URL'}</h2>
        <p className={'prospect'}>This helps us to understand you better, and is the first step to discovering hundreds of potential Partners!</p>
        <TipsText>
          <h3>Tips</h3>
          <ol>
            <li>If you have multiple channels, just pick the main one</li>
          </ol>
        </TipsText>
      </>

    return (
      <FormsModal
        Text={Text}
        noText={noText}
        fullWidth={fullWidth}
      >
        <Form
          noValidate
          step={step}
          stepCount={stepCount}
          formId={FORM_ID}
          // error={error}
          thinking={thinking}
          createRef={createRef}
          onSubmit={this.onSubmit}
        >
          <Grid container>
            <Grid item xs={12}>
              <Input
                fullWidth
                margin="normal"
                placeholder={'e.g. https://example.com'}
                id={'link'}
                label={'Website'}
                inputLabel={variant === 'update' ? 'Update your Brand’s website' : 'Enter your Brand’s website'}
                variant={'outlined'}
                fieldType='clearable'
                value={link}
                disabled={thinking || disableChannel}
                error={errors.link || error}
                onChange={this.onChange('link')}
              />
            </Grid>
          </Grid>
        </Form>

      </FormsModal>
    )
  }
}
