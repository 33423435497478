import React from 'react'
import {
  Grid
} from '@material-ui/core'
import { Autocomplete, Form, Input } from '../Form'
import Validator from '../../utils/validator'
import { classifications } from '../../data/classifications'
import { FormsModal } from './FormsModal'
import TipsText from '../TipsText'
import Text from "../TipsText";

const FORM_ID = 'brand-form'

const schema = [
  {
    field: 'name',
    rules: 'required',
    messages: {
      required: 'Name is required'
    }
  },
  {
    field: 'categories',
    rules: 'categories_required',
    messages: {
      categories_required: 'Category is required'
    }
  }
]

export default class CompanyForm extends React.Component {
  static defaultProps = {
    categories: []
  }

  constructor(props) {
    super(props)
    const {name, categories} = this.props
    this.state = {name, categories, errors: {}, error: null}
  }

  componentDidMount () {
    if (this.props.initialValidation) {
      const {categories} = this.state
      Validator(FORM_ID, schema,
        errors => this.setState({errors}),
        () => {},
        categories
      )
    }
  }

  onChange = name => ({target: {value}}) => {
    this.setState({[name]: value})
    this.resetError(name)
    if (this.props.onChange) {
      this.props.onChange({[name]: value})
    }
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    const {name, categories} = this.state
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      () => {
        this.props.onSubmit({name, categories})
      },
      categories
    )
  }

  onCancel = () => {
    const {name, categories} = this.props
    this.setState({name, categories, errors: {}, error: null})
  }

  render() {
    const {name, categories, errors} = this.state
    const {
      thinking,
      error,
      stepCount,
      step,
      noText,
      fullWidth,
      createRef
    } = this.props
    const Text = () =>
      <>
        <h2 style={{marginTop: '1em'}}>Tell us who you are</h2>
        <p className={'prospect'}>This is the name you publically trade under, rather than your legal name.</p>
        <TipsText>
          <h3>Tips</h3>
          <ol>
            <li>You can use several categories to describe your business.<br/>E.g.:&nbsp;<strong>&nbsp;a hotel, a spa, and a restaurant.</strong></li>
          </ol>
        </TipsText>
      </>

    return (
      <FormsModal
        Text={Text}
        noText={noText}
        fullWidth={fullWidth}
      >
        <Form
          noValidate
          step={step}
          stepCount={stepCount}
          formId={FORM_ID}
          error={error}
          thinking={thinking}
          createRef={createRef}
          onSubmit={this.onSubmit}
        >
          <Grid container>
            <Grid item xs={12}>
              <Input
                autoFocus
                required
                fullWidth
                margin="normal"
                placeholder={'e.g. Breezy'}
                id={'name'}
                label={'Name'}
                inputLabel={'Enter your Brand’s name'}
                variant={'outlined'}
                fieldType='clearable'
                value={name}
                disabled={thinking}
                error={errors.name}
                onChange={this.onChange('name')}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                id={'categories'}
                key={'categories'}
                label={'Enter categories for your company'}
                helpText={'Type to search'}
                placeholder={'Category'}
                disabled={thinking}
                value={categories}
                options={classifications}
                error={errors.categories}
                onChange={this.onChange('categories')}
              />
            </Grid>
          </Grid>
        </Form>

      </FormsModal>
    )
  }
}
