import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import styledContainerQuery from 'styled-container-query'
import Screenshot from '../Screenshot/Screenshot'
import IconLink from '../IconLink/IconLink'
import TagList from '../../new-components/Tags/TagList'
import LanguagesBase from '../Languages/Languages'
import { SkeletonBase as Skeleton } from '../Skeleton/Skeleton'
import TypeLabelBase from '../TypeLabel/TypeLabel'
import { SystemTags as SystemTagsList } from '../../new-components/Tags/SystemTags'

const DomainBase = styled(IconLink)``
const TitleRoot = styled.div`
  color: ${(props) => props.theme.palette.accentDarkText};
  opacity: 0.8;
`
const DescriptionRoot = styled.div`
  color: ${(props) => props.theme.palette.mutedText};
  opacity: 0.6;
  font-size: 0.9em;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
`
const LanguagesRoot = styled.div`
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
`
const SkeletonTagsRoot = styled.div`
  display: flex;
  gap: 0.2em;
`

const ScreenshotWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 0.5em;
`
const TextWrapper = styled.div`
  gap: 0.5em;
  display: flex;
  flex-direction: column;
`

const DomainRoot = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8em;
  font-weight: bold;
  //font-size: 1.2em;
  //line-height: 1.2em;
  color: ${(props) => props.theme.palette.accentDarkText};
`

const TypeLabel = styled(TypeLabelBase)`
  font-size: 0.85em;
  line-height: 1.2em;
  font-weight: normal;
`
const Root = styledContainerQuery.div`
    display: flex;
    font-size: ${(props) => (props.size ? props.size : 'inherit')};
    flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
    justify-content: ${(props) => (props.horizontal ? 'flex-start' : 'center')}
    align-items: flex-start;
    margin: ${(props) => (props.horizontal ? 'none' : '0.5em 1em 0 0')};
    gap: ${(props) => (props.horizontal ? '2em' : '0.5em')};
    & ${ScreenshotWrapper} {
    max-width: ${(props) => (props.horizontal ? '416px' : 'auto')};
    }
    &:container(max-width: 800px){
        flex-direction: column;
        ${ScreenshotWrapper} {
            max-width: 100%;
        }
    }
    ${(props) =>
      props.horizontal &&
      css`
        grid-column: span 2;
      `}
`

type Tag = {
  id: string
  label: string
}

type PrimaryType = string | 'creator' | 'brand'

export interface SummaryProps {
  id?: string
  icon?: string
  domain?: string
  screenshot?: string
  title?: string
  description?: string
  languages?: Array<string>
  type?: PrimaryType
  tags?: Array<Tag>
  swVisits?: number
  systemTags?: Array<Tag>
  loading?: boolean
  horizontal?: boolean
  size?: string
  tagManagement?: object
  tagsLocked?: boolean
  disableTagManagement?: boolean
  setRef?: Function
}

const skeletonMultiline = (
  <>
    <Skeleton width='100%' height={'1.4em'} inline />
    <Skeleton width='50%' height={'1.4em'} inline />
  </>
)

const skeletonSingle = <Skeleton width='100%' height={'1.4em'} inline />

const skeletonTags = (
  <SkeletonTagsRoot>
    <Skeleton width='13ch' height={'1.4em'} inline />
    <Skeleton width='10ch' height={'1.4em'} inline />
    <Skeleton width='6ch' height={'1.4em'} inline />
  </SkeletonTagsRoot>
)

interface DomainProps {
  type?: string
  prefix?: string
  icon?: string
  domain?: string
  loading?: boolean
}

export const Domain = (props: DomainProps) => {
  return (
    <DomainRoot>
      {props.prefix}
      <DomainBase
        icon={props.icon}
        domain={props.domain}
        loading={props.loading}
      />
      {/* {!props.loading && <TypeLabel type={props.type} />} */}
    </DomainRoot>
  )
}

const Tags = (props: any) => {
  return props.loading
    ? skeletonTags
    : props.tags && (
        <TagList
          id={'summary-tags'}
          locked={props.tagsLocked}
          tags={props.tags}
          variant={props.tagsLocked ? '' : 'details'}
          {...props.tagManagement}
        >
          {props.children}
        </TagList>
      )
}

const SystemTags = (props: any) => {
  return props.loading
    ? skeletonTags
    : props.systemTags && (
        <SystemTagsList variant={'manual'} data={props.systemTags} />
      )
}

function Summary({ loading, ...props }: SummaryProps) {
  const Description = ({ children }: { children: string }) => {
    return loading
      ? skeletonMultiline
      : children && <DescriptionRoot>{children}</DescriptionRoot>
  }

  const Title = ({ children }: { children: string }) => {
    return loading
      ? skeletonSingle
      : children && <TitleRoot>{children}</TitleRoot>
  }

  // const Tags = ({tags}:{tags:Array<Tag>}) => {
  //     return loading ? skeletonTags : tags && (
  //         <TagList
  //             id={'summary-tags'}
  //             locked={props.tagsLocked}
  //             tags={tags}
  //             variant={props.tagsLocked ? '' : 'details'}
  //             {...props.tagManagement}
  //         />
  //     )
  // }

  const Languages = ({ data }: { data: Array<string> }) => {
    return !loading && data ? (
      <LanguagesRoot>
        <LanguagesBase data={data} />
      </LanguagesRoot>
    ) : null
  }

  return (
    <Root id={props.id} horizontal={props.horizontal} size={props.size}>
      <ScreenshotWrapper>
        <div>
          <Screenshot
            loading={loading}
            item={{ screenshot: props.screenshot, url: props.domain }}
          />
          <Languages data={props.languages} />
        </div>
      </ScreenshotWrapper>
      <TextWrapper>
        <Domain loading={loading} {...props} />
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>

        <Tags loading={loading} {...props}>
          <SystemTags loading={loading} {...props} />
        </Tags>

        {/*<Tags tags={props.tags} />*/}
        {/*type1Classification: {props.type1Classification} <br/>*/}
      </TextWrapper>
    </Root>
  )
}

Summary.defaultProps = {
  value: "It's a me, Summary!",
  setRef: () => void 0,
}

export default Summary
