import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MailSent } from '../../components/Icons/MailSent'

const Img = styled.div`
  width: 100%;
  max-width: 388px;
  text-align: center;
  @media (max-width: 768px) {
    max-width: 250px;
    padding: 0 1em 2em;
    margin: 0 auto;
  }
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${props => props.alignLeft ? 'flex-end' : 'center'} ;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.padding};
  margin-bottom: 2em;
  h1 {
    font-family: "GT Super Display", serif;
    font-weight: bold;
    font-size: 32px;
    color: #000211;
    margin: 0 0 32px 0;
    max-width: 400px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  p {
    font-size: 16px;
    line-height: 23px;
    color: #3E3E3E;
    margin: 2em 0;
    text-align: center;
    &:last-child {
      margin: 0;
    }
  }
  form {
    padding: 28px 0 0 0;
  }
  button {
    margin: 37px 0 0 0;
  }
  .textContainer {
    width: 30%;
    margin-right: 10%;
    min-width: 350px;
    @media (max-width: 768px) {
      margin: 0;
      min-width: auto;
      width: 100%;
    }
  }
  &.imgRight {
    flex-direction: inherit;
    @media (max-width: 768px) {
      //flex-direction: column;
      flex-direction: column-reverse;
    }
    

    h1, p {
      text-align: left;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
`;

class VerifySimple extends React.Component {
  constructor (props) {
    super(props)

  }

  componentDidMount () {
    const { emailStatus, token, onVerify, onVerified } = this.props

    if (emailStatus !== 'verified' && !!token) {
      onVerify(token)
    } else if (emailStatus === 'verified') {
      onVerified()
    }
  }

  render () {
    const { email } = this.props

    return (
      <Root className="imgRight">
          <div className={'textContainer'}>
            <h1>
              Check your inbox!
            </h1>
            <p>
              Almost There! 🎉 We’ve sent you an email to <strong>{email}</strong>. Simply click on the verification link inside to finalize your account creation.
            </p>
            <p>Can’t find it? Check your spam box and make sure you’ve given us the right email address.</p>
          </div>
          <Img>
            <MailSent />
          </Img>
      </Root>
    )
  }
}

export default VerifySimple
