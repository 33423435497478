import { Get, Post, Put } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import { find } from 'lodash'
import moment from 'moment'
import hasError from './request-message'
import { sendEvent } from '../utils/events'

import GqlClient from '../gql/gql-client'
import { GET_BRAND, CREATE_BRAND, UPDATE_BRAND } from '../gql/brands'
import { SIGN_IN } from '../gql/user'


const RESEARCH_FAILED_TIME = 30

const researchStatusFailed = researchStartAt => {
  return moment().diff(moment(researchStartAt), 'minutes') > RESEARCH_FAILED_TIME
}

const convertToStrings = (data) => data.map(({ value }) => value)

const convertToOptions = (data = [], options) => data.map(value => find(options, ['value', value]))

class WelcomeStore {
  @observable loading = true
  @observable thinking = false
  @observable brand = null
  @observable channel = null
  @observable product = null
  @observable existingPartners = []
  @observable onboardingDone = false

  pollingStarted = false
  pollingTimeout = null

  constructor (brandId) {
    if (brandId) {
      this.load(brandId).then()
    } else {
      this.loading = false
    }
  }

  @action
  async load (brandId) {
    let message = ''
    const { message: brandsMessage, brand } = await Get(`/api/discovery/brands/${brandId}`)

    if (!brandsMessage && brand) {
      this.brand = { ...brand }
      if (brand.channels && brand.channels.length) {
        const { message: channelsMessage, channel } = await Get(`/api/discovery/channels/${brand.channels[0]}`)
        if (!channelsMessage && channel) {
          this.channel = channel
          await this.getDefaultProduct()
        }
      }
    }

    this.loading = false

    return { message }
  }

  @action
  async setBrand ({ name, link, brandId }) {
    this.thinking = true

    const details = { name, link }

    const { success, ...rest } = await GqlClient.mutation({ mutation: CREATE_BRAND, variables: { CreateBrandInput: details } })

    if (success) {
      this.brand = rest.data['createBrand']
    }

    this.thinking = false

    return { success, brand: toJS(this.brand) }
  }

  @action
  async selectSubscription (plan) {
    const { success, message, brand } = await Put(`/api/discovery/brands/${this.brand.id}/select-plan`, { plan })
    if (!hasError(success, message, '')) {
      this.brand = brand
    }
    return { success }
  }

  @action
  async setOnboardingStep (step) {
    const payload = {
      id: this.brand.id,
      patch: {
        onboardingStep: step
      }
    }
    const { success,  ...rest } = await GqlClient.mutation({ mutation: UPDATE_BRAND, variables: { UpdateBrandInput: payload } })

    if (!hasError(success, '', '')) {
      this.brand = rest.data['updateBrand']
    }

    return { success, brand: toJS(this.brand) }
  }

  @action
  async setProduct ({ name, country, link }) {
    this.thinking = true
    if (this.channel) {
      await this.updateChannel({ link })
      return await this.updateProduct({ name, country })
    }

    return await this.createProduct({ name, link, country })
  }

  @action
  async createProduct ({ name, country, link }) {
    this.thinking = true
    const details = {
      link, name, country,
      brand: this.brand.id
    }
    const { success, channel, product } = await Post(`/api/discovery/channels?source=onboarding`, { channel: details })

    if (success) {
      this.channel = channel
      this.product = product
    }

    this.thinking = false

    return { success }
  }

  @action
  async updateChannel ({ link }) {
    const { success, channelCandidate } = await Put(`/api/discovery/channels/${this.channel.id}/update-candidate?source=onboarding`, {
      candidate: { link }, product: this.product.id
    })

    if (success) {
      this.channel = channelCandidate
    }

    return { success }
  }

  @action
  async getDefaultProduct () {
    this.thinking = true
    const { id: brandId } = this.brand
    const { success, product } = await Get(`/api/discovery/products/default?brand=${brandId}`)

    if (success) {
      this.product = { ...product }
    }
    this.thinking = false
  }

  @action
  async updatePartners ({ partners: links }) {
    this.thinking = true
    const { message: error } = await Post(`/api/discovery/existing-partners/import-from-list`, { links })

    this.thinking = false

    return { error }
  }

  @action
  async updateProduct ({ competitors, keywords, name, country }) {
    this.thinking = true
    let details = {
      ...(!!name && { name }),
      ...(!!country && { country })
    }
    if (competitors) {
      details.competitorUrls = competitors.filter(v => !!v) //.slice(0, 15)
    } else if (keywords) {
      details.keywords = keywords.filter(v => !!v) //.slice(0, 400)
    }

    const { success, product } = await Put(`/api/discovery/products/${this.product.id}?source=onboarding`, { product: details })

    if (success) {
      this.product = product
    }
    this.thinking = false

    return { success }
  }

  @action
  async startResearch () {
    if (this.pollingStarted) return null
    const { researchStatus } = this.product
    if (researchStatus === 'pending') {
      this.product.researchStatus = 'progress'

      const { success, message } = await Post(`/api/discovery/products/${this.product.id}/research`, {})

      if (!hasError(success, message, '')) {
        this.startPolling()
      }
    } else if (researchStatus === 'progress') {
      this.startPolling()
    }
  }

  startPolling () {
    this.pollingStarted = true
    this.pollingTimeout = setTimeout(async () => {
      const { success, researchStatus } = await Get(`/api/discovery/products/${this.product.id}/research-status`)

      if (success && researchStatus === 'researched') {
        await this.getDefaultProduct()
        this.pollingStarted = false
      } else {
        this.startPolling()
      }

    }, 5000)
  }

  @action
  async setFirstResearchSeen () {
    const { success, message, brand } = await Put(`/api/discovery/brands/${this.brand.id}`, {
      brand: {
        firstResearchSeen: true
      }
    })
    if (!hasError(success, message, '')) {
      sendEvent(this.brand.id, 'discoveryFirstSeen')
      this.brand = brand
    }
    return { success }
  }

  @action
  async done (callback) {
    await Post(`/api/discovery/brands/onboarding-done`, {})
    this.onboardingDone = true
    setTimeout(() => callback(), 1000)
  }

  @computed
  get brandDetails () {
    let details = {}
    if (this.brand) {
      const { name, link, id: brandId, credits, plan, onboardingStep } = toJS(this.brand)

      details = { brandId, name, link, credits, plan, onboardingStep }
    }
    return details
  }

  @computed
  get competitors () {
    if (this.product) {
      const { competitorUrls } = this.product
      return competitorUrls ? competitorUrls.filter(v => !!v) : []
    }
    return []
  }

  @computed
  get partners () {
    return [] //this.existingPartners.map(({ domain }) => domain)
  }

  @computed
  get keywords () {
    if (this.product) {
      const { keywords } = this.product
      return keywords ? keywords.filter(v => !!v) : []
    }
    return []
  }

  @computed
  get productDetails () {
    if (this.product) {
      const { id, name, country, researchStatus, leadsCount } = this.product
      const { total } = leadsCount || {}
      let link = null
      if (this.channel) {
        link = this.channel.link
      }

      return { id, name, country, link, researchStatus, total }
    }
    return {}
  }

  @computed
  get computedStep () {
    if (this.loading) return null
    const { onboardingStep } = toJS(this.brand) || {}

    return onboardingStep
  }
}

export default WelcomeStore
