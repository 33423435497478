import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import DataGridPro, { renderTitle } from './EcosystemTable'
import {Base as TooltipMetricsBase} from '../../components-ts/TooltipMetrics/TooltipMetricsBase'
import metrics from '../../configMetrics'
import {formatToReadableNumber} from '../../utils/format'

const Root = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  margin: 0 1em 0 0;
  display: flex;
  align-items: center; 
`

const Result = styled.div`
  a { 
    display: inline-block;
    text-decoration: none;
    font-weight: normal;
  }
`

const Heading = styled.div`
  display: flex;
  align-items: center;
`

const Header = ({ total }) => {
  return (
    <div style={{display: 'flex'}}>
      <Root>
        <Result>
          <strong>{formatToReadableNumber(total)} results</strong>
        </Result>
      </Root>
    </div>
  )
}

const createColumns = (total, isBrandList) => {
  const partnershipsColumn = () => {
    return [
      {
        field: isBrandList ? 'partnershipsInCount' : 'partnershipsOutCount',
        headerName: 'Partnerships',
        description: <TooltipMetricsBase title='Partnerships' content='Either Promotes or Promoted by, depends on entity type eg: Creator or Brand' />,
        width: 110,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
          return formatToReadableNumber(params.value)
        }
      }
    ]
  }

  return [
    {
      field: 'domain', headerName: 'Channel', flex: 1, sortable: false,
      renderCell: renderTitle,
      minWidth: 300,
      pin: true,
      renderHeader: () => <Header total={total} />
    },
    {
      field: 'mozDomainAuthority',
      align: 'right',
      headerAlign: 'right',
      headerName: <Heading>{metrics['moz'].heading}</Heading>,
      description: <TooltipMetricsBase title={metrics['moz'].title} content={metrics['moz'].content} poweredBy={metrics['moz'].poweredBy}/>,
      width: 70,
      minWidth: 70,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return formatToReadableNumber(params.value, 'integer')
      }
    },
    {
      field: 'swGlobalRank',
      headerName: <Heading>{metrics['rank'].heading}</Heading>,
      description: <TooltipMetricsBase title={metrics['rank'].title} content={metrics['rank'].content} poweredBy={metrics['rank'].poweredBy} />,
      width: 82,
      minWidth: 82,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        return formatToReadableNumber(params.value)
      }
    },
    {
      field: 'swVisits',
      headerName: <Heading>{metrics['visits'].heading}</Heading>,
      description: <TooltipMetricsBase title={metrics['visits'].title} content={metrics['visits'].content} poweredBy={metrics['visits'].poweredBy}/>,
      width: 90,
      minWidth: 90,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        return formatToReadableNumber(params.value, 'integer')
      }
    },
    {
      field: 'partnershipsOutCount',
      headerName: 'Promotes',
      description: <TooltipMetricsBase title='Promotes'
                                       content='Either Promotes, depends on entity type eg: Creator'/>,
      width: 110,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return formatToReadableNumber(params.value)
      }
    },
    {
      field: 'partnershipsInCount',
      headerName: 'Promoted by',
      description: <TooltipMetricsBase title='Promoted by' content='Either Promoted by, depends on entity type eg: Brand' />,
      width: 110,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return formatToReadableNumber(params.value)
      }
    }
  ]
}

let resizeTimeout = null

const EcosystemGapCompare = ({ data, total, loading, error, sortModel, isBrandList, onSort, onPageChange, onCellClick }) => {
  const [widths, setWidths] = useState({})
  const columns = useMemo(() => {
    return createColumns(total, isBrandList)
  }, [total])

  const onResize = ({ colDef }) => {
    const { field, width } = colDef

    clearTimeout(resizeTimeout)

    resizeTimeout = setTimeout(() => {
      setWidths({...widths, [field]: width })
    }, 1000)
  }

  const columnsWithWidth = columns.map(({ field, flex, width, ...rest }) => {
    const columnWidth = widths[field] || width
    return {
      field,
      ...(!columnWidth && flex && { flex }),
      ...(columnWidth && { width: columnWidth }),
      ...rest,
    }
  })

  return (
    <DataGridPro
      hideFooter
      sortingMode="server"
      paginationMode="server"
      loading={loading}
      error={error}
      rows={data}
      columns={columnsWithWidth}
      onRowsScrollEnd={onPageChange}
      onCellClick={onCellClick}
      onSortModelChange={onSort}
      onColumnResize={onResize}
      sortModel={sortModel}
      initialState={{
        sorting: {
          sortModel,
        },
      }}
    />
  )

}

export default EcosystemGapCompare
