import React from 'react'
import styled from 'styled-components'
import CountryFlag from '../../CountryFlag'
import WidgetMetric from '../../Widget/WidgetMetric'
import WidgetMetricBase from '../../Widget/Base/WidgetMetricBase'
import Table from '../../TableCompact'
import IconLink from '../../IconLink'
import {FileText} from 'react-feather'
import IconLabel from "../../IconLabel";
import EdgeActive from '../../EdgeActive'
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion, {AccordionProps} from '@mui/material/Accordion';
import metrics, {MetricKeysBase} from '../../../configMetrics'
import LabelPosition from '../../LabelPosition'

import PlacementTextAIWrapper from './PlacementTextAIWrapper'


const Root = styled(Accordion)`
  box-shadow: inset 0 0 0 1px ${props => props.loading ? 'transparent' : props.theme.palette.brand.shadow.main}!important;
  border-radius: ${props => props.theme.shape.borderRadius * 2}px!important;
  &:before {
    display: none;
  }
  //display: flex;
  `

const Summary = styled(AccordionSummary)`
  //justify-content: center;
  //align-items: center;
  width: 100%;
 
  padding: 0.5em;

  & .MuiAccordionSummary-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
`

const Details = styled(AccordionDetails)`
  //& > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    //}
  `

const Edge = styled(EdgeActive)`
    margin: 0 -1em 0 -1em;
    width: calc(100% + 2em);
  `

const Path = styled(IconLink)`
    font-weight: bold;
   width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  `

const IconPlacement = styled(FileText)`
    color: ${props => props.theme.palette.mutedText};
    opacity: 0.6;
    width: 2em;
    height: 2em;
    flex: 0 0 auto;
  `

const Metrics = styled.div`
    display: flex;
    gap: 0.5em;
      & > div, & > a {
        max-width: 20ch;
        width: 20ch;
        height: 3.5em;
        min-height: 3em;
        padding: 0.5em 1em;
        border-radius: 0.6em;
      }
`

const Metric = styled(WidgetMetricBase)`
  min-height: 1.8em;
  height: 1.8em;
  padding: 0;
  color: transparent;
  width: 20ch;
  & .WidgetMetricBase__ValueRoot {
    display: none;
  }

`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
`

const Main = styled.div`
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1 1 auto;
  gap: 0.3em;
  overflow: hidden;
`

const Title = styled.div`
    opacity: 0.8;
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const DetailsSectionMain = styled.div`
    flex: 1 1 auto;
`
const DetailsSectionRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`
const DetailsSectionHeader = styled.div`
    display: flex;
  align-items: center;
  justify-content: space-between;
`

const DetailsSectionHeading = styled.div`
    color: ${props => props.theme.palette.mutedText};
`
const DetailsSectionMetrics = styled.div`
  display: flex;
    gap: 0.5em;
  align-items: center;
  justify-content: flex-end;
`

const DetailsSectionValue = styled.div`
    font-weight: bold;
  font-size: 1.2em;
    color: ${props => props.theme.palette.accentDarkText};
`

interface DetailsSectionProps {
    title: string | React.ReactNode
    value: number
    valueMax: number
    variant: MetricKeysBase
    children: React.ReactNode
}

function DetailsSection (props: DetailsSectionProps) {

    const header = <DetailsSectionHeader>
        <DetailsSectionHeading>{props.title}</DetailsSectionHeading>
        <DetailsSectionMetrics>
            <DetailsSectionValue>{props.value}/{props.valueMax}</DetailsSectionValue>
            <Metric value={props.value} valueMax={props.valueMax}/>
        </DetailsSectionMetrics>
    </DetailsSectionHeader>

    return <DetailsSectionRoot>
        {header}
        <DetailsSectionMain>
            {props.children}
        </DetailsSectionMain>
    </DetailsSectionRoot>
}

interface Keyword {
    name: string, position: number, search: string, searchId: string, geo: string
}

interface Competitor {
    icon: string
    domain: string
}

export interface PlacementInterface {
    search: string
    searchId: string
    searchScore: number
    keywordsCount: number
    totalKeywords: number
    competitorsCount: number
    totalCompetitors: number
    competitors: Array<Competitor>
    keywords: Array<Keyword>
    url: string
    path: string
    title: string
}

interface PlacementProps extends PlacementInterface {
    loading?: boolean
    link?: string
}

export default function Placement (props: PlacementProps) {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const _keywords = props.keywords.map((keyword) => {
        const id = '' + keyword.name + keyword.position + keyword.geo
        return {...keyword, id: id}
    })

    const _competitors = props.competitors.map((competitor) => {
        const id = competitor.domain
        return {...competitor, id: id}
    })

    return <Root expanded={expanded === props.path} onChange={handleChange(props.path)}>
            <Summary>
                <Container>
                    <PlacementTextAIWrapper {...props}>
                        <IconPlacement />
                    </PlacementTextAIWrapper>
                    <Main>
                        <Path domain={props.url} path={props.path} noIcon />
                        <Title>{props.title}</Title>
                    </Main>
                    <Metrics>
                        <IconLabel to={props.link + '/' + props.searchId} variant="search" label={props.search} value={props.searchScore} />
                        <WidgetMetric variant={'keywords'} value={props.keywordsCount} valueMax={props.totalKeywords}/>
                        <WidgetMetric variant={'backlinks'} value={props.competitorsCount} valueMax={props.totalCompetitors} />
                    </Metrics>
                </Container>
                <Edge horizontal active={expanded} />
            </Summary>

            <Details>
                <DetailsSection title={metrics.keywords.shortName} value={props.keywordsCount} valueMax={props.totalKeywords} variant={'keywords'}>
                <Table columns={[
                    { headerName: 'Position', field: 'position', width: 50, renderCell: (params) => <LabelPosition value={params.value} /> },
                    { headerName: 'Keyword', field: 'name', flex: 1 },
                    { headerName: 'Country', field: 'geo', width: 100, align: "center", headerAlign: "center", renderCell: (params) => <CountryFlag iso={params.value} /> },
                ]} rows={_keywords} />
                </DetailsSection>

                <DetailsSection title={metrics.backlinks.shortName} value={props.competitorsCount} valueMax={props.totalCompetitors} variant={'backlinks'}>

                <Table columns={[
                    { headerName: 'Competitor', field: 'domain', flex: 1, renderCell: (params) => <IconLink domain={params.row.domain} icon={params.row.icon} /> },
                ]} rows={_competitors} />
                </DetailsSection>

            </Details>
    </Root>
}
