import { gql } from '@apollo/client'
import { Post } from '../../utils/request'
import GqlClient from '../../gql/gql-client'
import { REQUEST_CONTACTS } from '../../gql/contacts'
import { LIST_ITEM_CHANNEL_DETAILS } from '../../gql/fragments'

export const ECOSYSTEM_PRIMARY_DOMAINS = gql`
  query ecosystemBrandsConnection(
    $ecosystemId: String!
    $domains: [String!]
    $take: Int!
  ) {
    ecosystemBrandsConnection(
      ecosystemId: $ecosystemId
      take: $take
      domains: $domains
    ) {
      edges {
        node {
          channelId
          domain
          icon
          id
          lang
          mozDomainAuthority
          partnershipsInCount
          swGlobalRank
          swVisits
          title
          type1Classification
        }
      }
    }
  }
`

export const ECOSYSTEM_LANDSCAPE_COMPARE = gql`
  query ecosystemBrandsConnection(
    $ecosystemId: String!
    $domains: [String!]
    $search: String
    $lang: String
    $countries: [String!]
    $take: Int!
    $skip: Int!
    $orderBy: String
    $byPartner: String
    $swVisitsEnd: Float
    $swVisitsStart: Float
    $swGlobalRankEnd: Int
    $swGlobalRankStart: Int
    $mozDomainAuthorityEnd: Int
    $mozDomainAuthorityStart: Int
    $partnershipsInCountStart: Int
    $partnershipsInCountEnd: Int
    $tagIds: [String!]
    $manualClassifications: [ClassificationByLevelInput!]
  ) {
    ecosystemBrandsConnection(
      ecosystemId: $ecosystemId
      skip: $skip
      take: $take
      domains: $domains
      search: $search
      orderBy: $orderBy
      countries: $countries
      lang: $lang
      byPartner: $byPartner
      swVisitsEnd: $swVisitsEnd
      swVisitsStart: $swVisitsStart
      swGlobalRankEnd: $swGlobalRankEnd
      swGlobalRankStart: $swGlobalRankStart
      mozDomainAuthorityEnd: $mozDomainAuthorityEnd
      mozDomainAuthorityStart: $mozDomainAuthorityStart
      partnershipsInCountStart: $partnershipsInCountStart
      partnershipsInCountEnd: $partnershipsInCountEnd
      tagIds: $tagIds
      manualClassifications: $manualClassifications
    ) {
      totalCount
      edges {
        node {
          channelId
          domain
          icon
          id
          lang
          mozDomainAuthority
          partnershipsInCount
          swGlobalRank
          swVisits
          title
          type1Classification
        }
      }
    }
  }
`

export const ECOSYSTEM_GAP_OVERLAP = gql`
  query partnersWithOverlapConnection(
    $ecosystemId: String!
    $take: Int!
    $skip: Int!
    $orderBy: String
    $existingPartnersFor: [String!]
    $potentialLeadsFor: [String!]
    $search: String
    $creatorAdexes: [Int!]
    $countries: [String!]
    $lang: String
    $contactsFound: Boolean
    $brandId: String
    $hideRelevants: Boolean
    $hidePartners: Boolean
    $hideProspects: Boolean
    $type1Classification: String
    $swVisitsEnd: Float
    $swVisitsStart: Float
    $swGlobalRankEnd: Int
    $swGlobalRankStart: Int
    $mozDomainAuthorityEnd: Int
    $mozDomainAuthorityStart: Int
    $partnershipsOutCountStart: Int
    $partnershipsOutCountEnd: Int
    $tagIds: [String!]
    $manualClassifications: [ClassificationByLevelInput!]
  ) {
    partnersWithOverlapConnection(
      ecosystemId: $ecosystemId
      take: $take
      skip: $skip
      orderBy: $orderBy
      existingPartnersFor: $existingPartnersFor
      potentialLeadsFor: $potentialLeadsFor
      search: $search
      creatorAdexes: $creatorAdexes
      countries: $countries
      lang: $lang
      contactsFound: $contactsFound
      brandId: $brandId
      hideRelevants: $hideRelevants
      hidePartners: $hidePartners
      hideProspects: $hideProspects
      type1Classification: $type1Classification
      swVisitsEnd: $swVisitsEnd
      swVisitsStart: $swVisitsStart
      swGlobalRankEnd: $swGlobalRankEnd
      swGlobalRankStart: $swGlobalRankStart
      mozDomainAuthorityEnd: $mozDomainAuthorityEnd
      mozDomainAuthorityStart: $mozDomainAuthorityStart
      partnershipsOutCountStart: $partnershipsOutCountStart
      partnershipsOutCountEnd: $partnershipsOutCountEnd
      tagIds: $tagIds
      manualClassifications: $manualClassifications
    ) {
      totalCount
      edges {
        node {
          domain
          icon
          id
          lang
          mozDomainAuthority
          overlap
          partnerId
          partnershipsOutCount
          swGlobalRank
          swVisits
          title
          type1Classification
          contactsFoundCount
        }
      }
    }
  }
`

export const ECOSYSTEM_GAP_COMPARE = gql`
  query partnersWithBrandsConnection(
    $ecosystemId: String!
    $domains: [String!]!
    $take: Int!
    $skip: Int!
    $existingPartnersFor: [String!]
    $potentialLeadsFor: [String!]
    $orderBy: String
    $search: String
    $countries: [String!]
    $creatorAdexes: [Int!]
    $contactsFound: Boolean
    $brandId: String
    $hideRelevants: Boolean
    $hidePartners: Boolean
    $hideProspects: Boolean
    $lang: String
    $type1Classification: String
    $swVisitsEnd: Float
    $swVisitsStart: Float
    $swGlobalRankEnd: Int
    $swGlobalRankStart: Int
    $mozDomainAuthorityEnd: Int
    $mozDomainAuthorityStart: Int
    $partnershipsOutCountStart: Int
    $partnershipsOutCountEnd: Int
    $tagIds: [String!]
    $manualClassifications: [ClassificationByLevelInput!]
  ) {
    partnersWithBrandsConnection(
      domains: $domains
      ecosystemId: $ecosystemId
      take: $take
      skip: $skip
      potentialLeadsFor: $potentialLeadsFor
      existingPartnersFor: $existingPartnersFor
      orderBy: $orderBy
      search: $search
      creatorAdexes: $creatorAdexes
      countries: $countries
      lang: $lang
      contactsFound: $contactsFound
      brandId: $brandId
      hideRelevants: $hideRelevants
      hidePartners: $hidePartners
      hideProspects: $hideProspects
      type1Classification: $type1Classification
      swVisitsEnd: $swVisitsEnd
      swVisitsStart: $swVisitsStart
      swGlobalRankEnd: $swGlobalRankEnd
      swGlobalRankStart: $swGlobalRankStart
      mozDomainAuthorityEnd: $mozDomainAuthorityEnd
      mozDomainAuthorityStart: $mozDomainAuthorityStart
      partnershipsOutCountStart: $partnershipsOutCountStart
      partnershipsOutCountEnd: $partnershipsOutCountEnd
      tagIds: $tagIds
      manualClassifications: $manualClassifications
    ) {
      totalCount
      edges {
        node {
          brands {
            domain
            id
            exist
          }
          domain
          icon
          id
          lang
          mozDomainAuthority
          overlap
          partnerId
          partnershipsOutCount
          swGlobalRank
          swVisits
          title
          type1Classification
          contactsFoundCount
        }
      }
    }
  }
`

export const PARTNERSHIPS = gql`
  query channelsConnection(
    $take: Int!
    $skip: Int!
    $orderBy: String
    $search: String
    $countries: [String!]
    $brandAdexes: [Int!]
    $creatorAdexes: [Int!]
    $lang: String
    $type1Classification: String
    $contactsFound: Boolean
    $isBrand: Boolean
    $isCreator: Boolean
    $promotes: String
    $promotedBy: String
    $partnershipsInCountEnd: Int
    $partnershipsInCountStart: Int
    $partnershipsOutCountEnd: Int
    $partnershipsOutCountStart: Int
    $swVisitsEnd: Float
    $swVisitsStart: Float
    $swGlobalRankEnd: Int
    $swGlobalRankStart: Int
    $mozDomainAuthorityEnd: Int
    $mozDomainAuthorityStart: Int
  ) {
    channelsConnection(
      take: $take
      skip: $skip
      orderBy: $orderBy
      search: $search
      countries: $countries
      brandAdexes: $brandAdexes
      creatorAdexes: $creatorAdexes
      lang: $lang
      type1Classification: $type1Classification
      contactsFound: $contactsFound
      isBrand: $isBrand
      isCreator: $isCreator
      promotes: $promotes
      promotedBy: $promotedBy
      partnershipsInCountEnd: $partnershipsInCountEnd
      partnershipsInCountStart: $partnershipsInCountStart
      partnershipsOutCountEnd: $partnershipsOutCountEnd
      partnershipsOutCountStart: $partnershipsOutCountStart
      swVisitsEnd: $swVisitsEnd
      swVisitsStart: $swVisitsStart
      swGlobalRankEnd: $swGlobalRankEnd
      swGlobalRankStart: $swGlobalRankStart
      mozDomainAuthorityEnd: $mozDomainAuthorityEnd
      mozDomainAuthorityStart: $mozDomainAuthorityStart
    ) {
      totalCount
      edges {
        node {
          channelId
          domain
          icon
          id
          isBrand
          lang
          mozDomainAuthority
          partnershipsInCount
          partnershipsOutCount
          swGlobalRank
          swVisits
          title
          type1Classification
          contactsFoundCount
        }
      }
    }
  }
`

export const CHANNELS_FILTER = gql`
  query channelsConnection($search: String) {
    channelsConnection(take: 20, skip: 0, search: $search) {
      edges {
        node {
          channelId
          domain
        }
      }
    }
  }
`

const CREATE_PROSPECT = gql`
  mutation CreateProspect($CreateProspectInput: CreateProspectInput!) {
    createProspect(input: $CreateProspectInput) {
      id
    }
  }
`

const CREATE_PROSPECTS_BY_CHANNEL_IDS = gql`
  mutation CreateProspectsByChannelIds(
    $CreateProspectsInput: CreateProspectsInput!
  ) {
    createProspectsByChannelIds(input: $CreateProspectsInput)
  }
`

export const createProspects = async (brandId, channelIds, kind) => {
  const payload = {
    kind,
    brandId,
    channelIds,
  }

  return await GqlClient.mutation({
    mutation: CREATE_PROSPECTS_BY_CHANNEL_IDS,
    variables: { CreateProspectsInput: payload },
  })
}

export const addToRelevant = async (brandId, domain) => {
  const payload = {
    kind: 'relevant',
    brandId,
    domain,
  }

  return await GqlClient.mutation({
    mutation: CREATE_PROSPECT,
    variables: { CreateProspectInput: payload },
  })
}

export const addToPartner = async (brandId, domain) => {
  const payload = {
    kind: 'partner',
    brandId,
    domain,
  }

  return await GqlClient.mutation({
    mutation: CREATE_PROSPECT,
    variables: { CreateProspectInput: payload },
  })
}

export const requestContact = async (brandId, channelIds, outreachMessage) => {
  const payload = {
    brandId,
    channelIds,
    ...(outreachMessage && { outreachMessage }),
  }
  return await GqlClient.mutation({
    mutation: REQUEST_CONTACTS,
    variables: { RequestContactsInput: payload },
  })
}

export const addToActions = {
  partner: addToPartner,
  relevant: addToRelevant,
}
