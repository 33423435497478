import { getFilterClassifications } from '../configFilters'

export const getRangeFilters = ({
  promotedBy,
  promotes,
  swRank,
  swVisits,
  domainAuthority,
}) => {
  let filters = {}

  if (promotedBy) {
    const { 0: partnershipsInCountStart, 1: partnershipsInCountEnd } =
      promotedBy
    filters = {
      ...filters,
      ...(partnershipsInCountStart && {
        partnershipsInCountStart: parseInt(partnershipsInCountStart),
      }),
      ...(partnershipsInCountEnd && {
        partnershipsInCountEnd: parseInt(partnershipsInCountEnd),
      }),
    }
  }
  if (promotes) {
    const { 0: partnershipsOutCountStart, 1: partnershipsOutCountEnd } =
      promotes
    filters = {
      ...filters,
      ...(partnershipsOutCountStart && {
        partnershipsOutCountStart: parseInt(partnershipsOutCountStart),
      }),
      ...(partnershipsOutCountEnd && {
        partnershipsOutCountEnd: parseInt(partnershipsOutCountEnd),
      }),
    }
  }
  if (swRank) {
    const { 0: swGlobalRankStart, 1: swGlobalRankEnd } = swRank
    filters = {
      ...filters,
      ...(swGlobalRankStart && { swGlobalRankStart }),
      ...(swGlobalRankEnd && { swGlobalRankEnd }),
    }
  }
  if (swVisits) {
    const { 0: swVisitsStart, 1: swVisitsEnd } = swVisits
    filters = {
      ...filters,
      ...(swVisitsStart && { swVisitsStart }),
      ...(swVisitsEnd && { swVisitsEnd }),
    }
  }
  if (domainAuthority) {
    const { 0: mozDomainAuthorityStart, 1: mozDomainAuthorityEnd } =
      domainAuthority
    filters = {
      ...filters,
      ...(mozDomainAuthorityStart && { mozDomainAuthorityStart }),
      ...(mozDomainAuthorityEnd && { mozDomainAuthorityEnd }),
    }
  }

  return filters
}

export const getAdexesFilter = (adexes) => {
  if (!adexes || !adexes.length) return {}
  return { creatorAdexes: adexes.map(({ id }) => parseInt(id)) }
}

export const getFilterKind = (kind) => {
  if (kind) {
    return { [kind]: true }
  }
  return null
}

export const getVariables = (variables = {}) => {
  const {
    brandId,
    gapFilterKind,
    partner,
    comparison,
    primary,
    secondary,
    ecosystemId,
    search,
    byCountries,
    lang,
    type1Classification,
    contactsFound,
    adexes,
    promotes,
    promotedBy,
    kind,
    sortQueries,
    byTags,
    hidePartners,
    hideRelevants,
  } = variables

  const manualClassifications = getFilterClassifications(variables)
  return {
    ...((hideRelevants || hidePartners) && { brandId }),
    ...(hidePartners && !hideRelevants && { hidePartners: true }),
    ...(hideRelevants && !hidePartners && { hideRelevants: true }),
    ...(hideRelevants && hidePartners && { hideProspects: true }),

    ...(ecosystemId && { ecosystemId }),
    ...(sortQueries && { orderBy: sortQueries }),
    ...(search && { search }),
    ...(lang && { lang: lang.value }),
    ...(type1Classification && { type1Classification }),
    ...(byCountries && { countries: byCountries.map(({ value }) => value) }),
    ...(kind && { ...getFilterKind(kind) }),
    ...(promotes && { promotes: promotes.value }),
    ...(promotedBy && { promotedBy: promotedBy.value }),
    ...(contactsFound && { contactsFound }),
    ...(comparison && {
      domains: [
        ...primary.map(({ domain }) => domain),
        ...secondary.map(({ domain }) => domain),
      ],
    }),
    ...(gapFilterKind &&
      primary.length && { [gapFilterKind]: primary.map(({ id }) => id) }),
    ...(partner && { byPartner: partner.value }),
    ...getAdexesFilter(adexes, kind),
    ...getRangeFilters(variables),
    ...(byTags &&
      byTags.length && { tagIds: byTags.map(({ value }) => value) }),
    ...(manualClassifications && { manualClassifications }),
  }
}
