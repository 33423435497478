import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import EcosystemsLockModal from '../../../new-components/Modals/EcosystemsLockModal'
import ProfileSection, { ProfileSectionProps } from '../ProfileSection'
import IconLabel from '../../IconLabel'
import LinkButton from '../../LinkButton'
import pluraliseUnit from '../../../utils/pluraliseUnit'

const Root = styled(ProfileSection)<ProfileSectionProps>`
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  justify-content: space-between;
`

const Main = styled.div`
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
`
const Footer = styled.div`
  flex: 0;
`

const Cover = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  height: 6em;
  width: 100%;
  background: linear-gradient(to top, white 15%, transparent 100%);
  pointer-events: none;
`

const Unlock = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  padding: 0.5em;
  position: relative;
  z-index: 20;
`

const Container = styled.div`
  position: relative;
`

interface EcosystemInterface {
  id: string
  name: string
  description?: string
  disabled?: boolean
}

interface AppearsInEcosystemsInterface {
  loading: boolean
  limit?: number
  to: string
  data: Array<EcosystemInterface>
  type?: string
}

export default function AppearsInEcosystems(
  props: AppearsInEcosystemsInterface
) {
  const { loading, limit, data, to } = props
  const [lockOpen, setLockOpen] = useState(false)

  const availableEcosystems = data.filter((item) => {
    if (!item.disabled) return item
  })
  const lockedEcosystems = data.filter((item) => {
    if (item.disabled) return item
  })
  const treshold =
    lockedEcosystems.length >= limit ? limit : lockedEcosystems.length

  const empty = Boolean(!availableEcosystems.length && !lockedEcosystems.length)

  const onLockOpen = (e) => {
    e.preventDefault()
    setLockOpen(true)
  }

  return (
    <Root title='Appears In ecosystems'>
      <Main>
        {empty && <>Doesn't appear in any ecosystem.</>}
        {!empty &&
          availableEcosystems.map(({ id, name, disabled }) => (
            <IconLabel
              key={id}
              variant={'ecosystem'}
              disabled={disabled}
              label={name}
              to={`${to}?id=${id}`}
            />
          ))}
        {lockedEcosystems.length > 0 && (
          <>
            {lockedEcosystems
              .slice(0, treshold)
              .map(({ id, name, disabled }) => (
                <IconLabel
                  key={id}
                  variant='ecosystem'
                  disabled={disabled}
                  label={name}
                  to={`${to}?id=${id}`}
                />
              ))}
            {/*<IconLabel to={''} label={`+${lockedEcosystems.length}`} disabled />*/}
            <Cover />
            <Unlock onClick={onLockOpen}>
              Unlock {lockedEcosystems.length}{' '}
              {pluraliseUnit('ecosystem', lockedEcosystems.length)}
            </Unlock>
          </>
        )}
      </Main>
      <Footer>
        <LinkButton to={to} target={'_blank'}>
          Explore Ecosystems
        </LinkButton>
      </Footer>
      <EcosystemsLockModal open={lockOpen} onClose={() => setLockOpen(false)} />
    </Root>
  )
}
