import React, { useState } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import Table from '../TableCompact'
import LabelPosition from '../LabelPosition/LabelPosition'

import CountryFlag from '../CountryFlag/CountryFlag'
import IconLabel from '../IconLabel'

import { Keyword } from '../Details/interfaces'

const Name = styled.div`
    color: ${props => props.theme.palette.accentDarkText};
    flex: 1 0 auto;
`

const useStyles = makeStyles((theme) => ({}))

interface TableKeywordsProps {
    loading?: boolean
    data: Array<Keyword>
}

const renderPosition = (params) => {
    const { row: { position } } = params
    return <LabelPosition value={position} />
}

const renderKeyword = (params) => {
    const { row: { name } } = params
    return <Name>{name}</Name>
}

const renderCountry = (params) => {
    const { row: { geo } } = params
    return <CountryFlag iso={geo} />
}

const renderSearch = (params) => {
    // @ts-ignore
    const { brandId } = useParams()
    const { row: { score, search, searchId } } = params

    return <IconLabel value={score} label={search} to={`/brand/${brandId}/discovery/${searchId}`}/>
}

const columns = [
    {
        field: 'position',
        headerName: 'Rank',
        width: 70,
        minWidth: 70,
        renderCell: renderPosition
    },
    {
        field: 'name',
        headerName: 'Keyword',
        flex: 1,
        renderCell: renderKeyword
    },
    {
        field: 'geo',
        headerName: 'Country',
        width: 70,
        renderCell: renderCountry
    },
    {
        field: 'search',
        headerName: 'Search',
        width: 250,
        renderCell: renderSearch
    }
]

function TableKeywords ({ loading, data }: TableKeywordsProps) {
    const classes = useStyles()

    return (
            <Table
                loading={loading}
                rows={data}
                columns={columns}
                responsive
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'position', sort: 'asc' }],
                    },
                }}
            />
    )
}

TableKeywords.defaultProps = {}

export default TableKeywords
