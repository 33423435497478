import React, { useState } from 'react'
import { TextField, CircularProgress } from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'
import GqlClient from '../../gql/gql-client'

import { SYSTEM_TAGS_LIST } from '../../gql/system-tags'

const Autocomplete = styled(MuiAutocomplete)`
  .MuiFormControl-root {
    padding-bottom: 8px;
  }
`

const FormTextField = styled(TextField)`
  .MuiFormLabel-root {
    color: #3e3e3e;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    -ms-transform: none;
    transform: none;
    margin: 0 0 5px 0;
    position: relative;
  }
  /* top | right | bottom | left */
  .MuiInput-root {
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 14px;
    padding: 0;
    min-height: 48px;
  }
  .MuiInput-input {
    padding: 4px !important;
    line-height: 16px;
  }
  &.withHelpText {
    .MuiInput-root {
      padding: 25px 18px 5px 18px;
      min-height: 72px;
    }

    .MuiInputBase-adornedStart {
      padding: 25px 18px 5px 18px;
      min-height: 72px;
    }
  }
`

let typingTimeout = null

export default function ManualClassificationAutocomplete({
  value,
  label,
  disabled,
  level,
  helpText,
  defaultValue,
  onChange,
  placeholder,
}) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])

  const getData = async () => {
    setLoading(true)
    const { data } = await GqlClient.query({
      query: SYSTEM_TAGS_LIST,
      variables: {
        level,
      },
    })

    const nodes = data['classificationsConnection'].edges.map(
      ({ node: { id, name, level } }) => ({ id, label: name, level })
    )
    setOptions(nodes)
    setLoading(false)
  }

  const onToggleOpen = async () => {
    setOpen(!open)
    if (!open) {
      await getData()
    }
  }

  const onSearch = ({ target: { value } }) => {
    clearTimeout(typingTimeout)
    typingTimeout = setTimeout(async () => {
      await getData(value)
    }, 1000)
  }

  return (
    <>
      <Autocomplete
        multiple
        disableClearable
        // freeSolo
        open={open}
        loading={loading}
        options={options}
        disabled={disabled}
        groupBy={({ group }) => group}
        getOptionLabel={(option) => option.label}
        getOptionDisabled={({ disabled }) => disabled}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onOpen={onToggleOpen}
        onClose={onToggleOpen}
        onInputChange={onSearch}
        renderInput={(params) => (
          <FormTextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              autocomplete: 'off',
              disableUnderline: true,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            disableUnderline
            classes={
              helpText && {
                root: 'withHelpText',
              }
            }
            variant='standard'
            InputLabelProps={{ shrink: true }}
            label={label}
            margin='normal'
            fullWidth
          />
        )}
      />
    </>
  )
}
