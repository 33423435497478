import React from 'react'
import { Dialog } from '@material-ui/core'
import styled from 'styled-components'
import ModalLayout from '../../layouts/ModalLayout'
import BookDemo from '../../pages/Welcome/BookDemo'

const breakpoint = 961

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  @media (max-width: ${breakpoint}px) {
    grid-template-columns: calc(100%);
  }
  
  h1 {
    font-family: "GT Super Display", serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 1;
    color: #000021;
    margin: 0 0 32px 0;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #3E3E3E;
    //margin: 0;
    max-width: 400px;
    //text-align: center;
  }
  button {
    margin: 37px 0 0 0;
  }
`

export default class BookDemoModal extends React.Component {
  onToSelfServe = async () => {
    const { success } = await this.props.onTrialSubscribe('Self-serve-GBP-Monthly')
    if (success) {
      this.props.onClose()
    }
  }

  render () {
    const {
      open,
      fullName,
      email,
      brandId,
      billingStatus,
      onClose
    } = this.props

    return (
      <>
        <Dialog
          fullWidth
          // fullScreen
          maxWidth="lg"
          disableEnforceFocus
          open={open}
          ariaLabelledby="dialog-title"
          onEscapeKeyDown={onClose}
        >
          <ModalLayout
            onClose={onClose}
          >
            <Root>
              <BookDemo
                modal
                variant={'billing'}
                fullName={fullName}
                email={email}
                brand={{ id: brandId }}
                billingStatus={billingStatus}
                onToSelfServe={this.onToSelfServe}
              />
            </Root>
          </ModalLayout>
        </Dialog>
      </>
    )
  }
}
