import React, { useImperativeHandle, forwardRef } from 'react'

import ContactsDetailsProvider from './ContactDetailsContext'
import ContactsDetailsView from './ContactsDetailsView'
import DetailsSidebar, { TabPanel, Content } from '../DetailsSidebar'

const ContactsDetails = forwardRef((props: any, ref: any) => {
  useImperativeHandle(ref, () => ({
    tost: () => {},
  }))

  return (
    <ContactsDetailsProvider {...props}>
      <DetailsSidebar
        open={props.open}
        variant={'contacts'}
        onClose={props.onClose}
      >
        {({ tab, expanded, onSetViewState }) => {
          return (
            <TabPanel>
              <Content>
                <ContactsDetailsView
                  tags={props.tags}
                  systemTags={props.systemTags}
                  onSetViewState={onSetViewState}
                  onManageTags={props.onManageTags}
                />
              </Content>
            </TabPanel>
          )
        }}
      </DetailsSidebar>
    </ContactsDetailsProvider>
  )
})

export default ContactsDetails
