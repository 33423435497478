import React from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { find, findIndex, unionBy, uniq } from 'lodash'
import Helmet from 'react-helmet'

import PageMain from '../../layouts/PageMain'
import { CountriesFlag as countries } from '../../components/Leads/CountriesFlag'
import { ProductDetails } from '../../components/Product'
import EmptyListPlaceholder from '../../components/LeadsPlaceholder/EmptyList'
import { Blocker } from '../../components/Blocker'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import RequestContacts from '../../components/Modals/_RequestContactsModal'

import Filter from '../../new-components/Filter'
import LeadDetails from '../../new-components/Details/LeadDetails'
import TagsManagement from '../../new-components/Tags/_TagsManagement'
import ListTable from '../../new-components/List/ListTable'
import { onOpenExpiredModal } from '../../new-components/ExpiredSearchBanner/ExpiredSearchBanner'

import Config from '../../config'
import {
  SEARCH,
  TYPE1CLASSIFICATION,
  LANGUAGES,
  COUNTRIES,
  TAGS,
  COMPETITORS_BY_DOMAIN,
  SCORE,
  COMPETITORS_COUNT,
  KEYWORDS_COUNT,
  VISITS,
  RANK,
  MOZ,
  DIVIDER_TOP,
  DIVIDER_BOTTOM,
  SYSTEM_ENTITY,
  SYSTEM_CATEGORY,
  SYSTEM_NICHE,
  WITHOUT_PARTNERS,
  AFFILIATES_ONLY,
  WITHOUT_RELEVANT,
} from '../../configFilters'
import { sendEvent } from '../../utils/events'
import Details from '../../components-ts/Details'
import { getTagsList } from '../../utils/tags'

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0.5em 1em 0;
`

const computeFiltersConfig = ({ competitors, newCompetitors, maxValues }) => {
  return [
    SEARCH,
    LANGUAGES,
    COUNTRIES,
    TAGS,
    SYSTEM_ENTITY,
    SYSTEM_CATEGORY,
    SYSTEM_NICHE,
    {
      ...COMPETITORS_BY_DOMAIN,
      options: competitors.map((competitor) => {
        return {
          label: competitor
            .replace(/^.*:\/\//i, '')
            .replace(/www./i, '')
            .replace(/\//g, ''),
          value: competitor,
          disabled: newCompetitors.includes(competitor),
        }
      }),
    },
    SCORE,
    { ...COMPETITORS_COUNT, max: maxValues.competitorsCount },
    { ...KEYWORDS_COUNT, max: maxValues.keywordsCount },
    VISITS,
    RANK,
    MOZ,
    WITHOUT_RELEVANT,
    WITHOUT_PARTNERS,
    AFFILIATES_ONLY,
  ]
}

const computeTogglePartnerDirection = ({ kind, listProps, selected }) => {
  let direction = kind === 'partners' ? 'unmark' : 'mark'
  if (kind !== 'partners') {
    const partnerItems = listProps.items.filter(
      ({ id, partner }) => selected.indexOf(id) > -1 && !!partner
    )
    const isAllPartners = partnerItems.length === selected.length
    if (isAllPartners) {
      direction = 'unmark'
    }
  }
  return direction
}

@withRouter
export default class Leads extends React.Component {
  leadDetailsRef = null

  constructor(props) {
    super(props)
    this.state = {
      details: false,
      createProduct: false,
      manageProduct: false,
      manageProductKind: null,
      leadId: null,
      channelId: null,
      detailsKind: null,
      selected: [],

      //Toggle confirmation
      toggleConfirmationOpen: false,
      toggleIds: null,
      toggleSource: null,
      toggleDirection: null,
      toggleMessage: null,
      toggleActionText: null,
      toggleAction: console.log,
      ////////////

      ////Tags management
      tagsManagementOpen: null,
      tagsManagementKind: null,
      tagsManagementLead: null,

      reportReadyClosed: false,

      confirmationBlocklistOpen: false,
      confirmationBlocklistKind: null,

      confirmationFavouritesOpen: false,
      confirmationFavouritesIds: null,

      confirmationPartnersOpen: false,

      openImportPartners: false,

      requestContacts: {},
    }
  }

  get filtersConfig() {
    return computeFiltersConfig({ ...this.props })
  }

  componentDidMount() {
    const { listProps, loading } = this.props
    if (!loading) {
      this.props.onLoad()
    }
  }

  onCreateProduct = () => this.setState({ createProduct: true })

  onUpdateProduct = async (details) => await this.props.onUpdateProduct(details)

  onToWelcome = () => this.props.history.push('/welcome')

  onResetReportSeen = () => this.props.onResetReportSeen()

  onStartDiscovery = () => this.props.onStartDiscovery()

  onOpenLeadDetails = (leadId, detailsKind, selected) => {
    const {
      listProps: { items },
    } = this.props
    const item = items.filter(({ id }) => id === leadId)[0]

    if (item) {
      this.setState({
        details: true,
        leadId,
        channelId: item.channelId,
        detailsKind,
        fake: item.fake,
      })
    }
  }

  onCloseLeadDetails = (selected = []) =>
    this.setState({
      details: false,
      leadId: null,
      channelId: null,
      detailsKind: null,
      fake: false,
    })

  onToggleWithoutRelevant = () => {
    if (this.props.expired) {
      return onOpenExpiredModal()
    }
    this.props.onToggleWithoutRelevant()
  }

  onFiltersChange = (filters) => {
    if (this.props.expired) {
      return onOpenExpiredModal()
    }
    this.onResetReportSeen()
    this.onCloseLeadDetails()
    this.props.onFiltersChange(filters)
    sendEvent(this.props.brandId, 'filtersApplied')
  }

  onListItemClick = async (id, kind) => {
    this.onOpenLeadDetails(id, kind, [id])
    await sendEvent(this.props.brandId, 'showDetails')
  }

  onDragEnd = async (props) => {
    const { target, listId, leadIds } = props

    if (!target) return

    const source = this.props.kind

    if (target === 'irrelevant') {
      return this.onToggleIrrelevant(leadIds)
    }
    if (target === 'competitors') {
      return this.onToggleCompetitors(leadIds)
    }
    if (target === 'partners') {
      return await this.onTogglePartners(leadIds, target)
    }
    if (target === 'relevants') {
      return await this.onToggleProspects(leadIds, 'mark')
    }
    if (source === 'relevants' && target === 'report') {
      return await this.onToggleProspects(leadIds)
    }
    if (source === 'partners' && target === 'report') {
      return await this.onTogglePartners(leadIds)
    }

    if (target === 'report') {
      if (['competitors', 'irrelevant'].includes(source)) {
        return this.onRemoveFromSpecialList(leadIds)
      }
      return this.onRemoveLeadsFromList(this.props.listId, leadIds)
    }

    return this.onAddLeadsToList(listId, leadIds)
  }

  onRemoveFromSpecialList = async (ids) => {
    const selected = Array.isArray(ids) ? ids : this.state.selected
    await this.props.onRemoveFromSpecialList(selected)
    this.props.onUpdateData()
  }

  onAddLeadsToList = (listId, leadIds) => {
    if (['relevants', 'partners', 'with-notes'].indexOf(this.props.kind) < 0) {
      this.props.onRemoveItemsFromCurrent(leadIds)
    }
    this.props.onAddToList(listId, leadIds).then(() => {
      this.props.onUpdateData()
    })

    const { selected } = this.state

    if (
      uniq([...selected, ...leadIds]).length !==
      [...selected, ...leadIds].length
    ) {
      this.onCloseLeadDetails()
    }

    this.setState({ selected: [] })
  }

  onRemoveLeadsFromList = async (listId, leadIds) => {
    if (this.props.kind === 'prospects-list') {
      this.props.onRemoveItemsFromCurrent(leadIds)
    }
    await this.props.onRemoveFromList(listId, leadIds)
    this.props.onUpdateData()
    this.onCloseLeadDetails()
  }

  onSelect = (selected) => this.setState({ selected })

  onUpdateNotesCount = (id, count) => {
    const {
      listProps: { items },
    } = this.props
    const item = find(items, ['id', id])

    if (item && (count === 0 || (!item.notesCount && count === 1))) {
      this.props.onUpdateData()
    }

    this.props.onUpdateNotesCount(id, count)
  }

  ///////////////// Partners & Leads actions
  onTogglePartnersOrLeads = async () => {
    const { kind } = this.props
    const { toggleIds, toggleSource, toggleDirection, leadId, selected } =
      this.state

    if (leadId && this.leadDetailsRef && toggleIds.includes(leadId)) {
      let action = toggleDirection

      if (action === 'mark') {
        action = toggleSource === 'relevants' ? 'toProspects' : 'toPartners'
      }

      this.leadDetailsRef.setRelevantOrPartner(action)
    }

    if (toggleSource === 'relevants') {
      await sendEvent(this.props.brandId, 'leadsAddedToRelevant')
    }
    await this.props.onToggleToPartners(
      toggleSource,
      kind,
      toggleDirection,
      toggleIds
    )
    this.props.onUpdateData()
  }

  onToggleProspects = async (ids, direction) => {
    let leadIds = Array.isArray(ids) ? ids : this.state.selected
    return this.setState(
      {
        toggleIds: leadIds,
        toggleDirection: direction,
        toggleSource: 'relevants',
      },
      this.onTogglePartnersOrLeads
    )
  }

  onTogglePartners = async (ids, target) => {
    let leadIds = Array.isArray(ids) ? ids : this.state.selected
    const { kind, listProps } = this.props

    if (target === 'partners') {
      const partnerItems = listProps.items.filter(
        ({ id, partner }) => leadIds.indexOf(id) >= 0 && !!partner
      )
      if (partnerItems.length === leadIds.length) return
    }
    const direction = computeTogglePartnerDirection({
      kind,
      listProps,
      selected: leadIds,
    })

    return this.setState(
      {
        toggleIds: leadIds,
        toggleDirection: direction,
        toggleSource: 'partners',
      },
      this.onTogglePartnersOrLeads
    )
  }

  onCloseToggleConfirmation = () =>
    this.setState({
      toggleConfirmationOpen: false,
      toggleIds: null,
      toggleSource: null,
      toggleDirection: null,
      toggleMessage: null,
      toggleActionText: null,
    })

  ///////////////// Competitors & blocklist /////////////////
  get confirmationBlocklistProps() {
    const { selected, confirmationBlocklistOpen, confirmationBlocklistKind } =
      this.state
    if (!confirmationBlocklistOpen) {
      return {}
    }
    const prospects =
      selected.length > 1 ? `${selected.length} prospects` : 'prospect'
    const message = `Are you sure you want to add ${prospects} to ${confirmationBlocklistKind}?`
    return {
      message,
      actionText: `Add to ${confirmationBlocklistKind}`,
      onAction:
        confirmationBlocklistKind === 'blockist'
          ? this.onToggleIrrelevantAction
          : this.onToggleCompetitorsAction,
    }
  }

  onToggleIrrelevant = (ids) => {
    const selected = Array.isArray(ids) ? ids : null
    if (this.props.kind === 'irrelevant') {
      this.onRemoveFromSpecialList(selected).then()
    } else {
      this.onToggleIrrelevantAction(selected).then()
    }

    if (ids.includes(this.state.leadId)) {
      this.onCloseLeadDetails()
    }
  }

  onToggleIrrelevantAction = async (selected) => {
    this.onCloseBlocklistConfirmation()
    await this.props.onToggleIrrelevant(selected || this.state.selected)
    this.onRemoveSelection()
    this.props.onUpdateData()
  }

  onToggleCompetitors = (ids) => {
    const selected = Array.isArray(ids) ? ids : null
    if (this.props.kind === 'competitors') {
      this.onRemoveFromSpecialList(selected).then()
    } else {
      this.onToggleCompetitorsAction(selected).then()
    }
    if (ids.includes(this.state.leadId)) {
      this.onCloseLeadDetails()
    }
  }

  onToggleCompetitorsAction = async (selected) => {
    this.onCloseBlocklistConfirmation()
    await this.props.onToggleCompetitors(selected || this.state.selected)
    this.onRemoveSelection()
    this.props.onUpdateData()
  }

  onCloseBlocklistConfirmation = () => {
    this.setState({
      confirmationBlocklistOpen: false,
      confirmationBlocklistKind: null,
    })
  }
  //////////////////////////////////

  onRemoveSelection = () => this.setState({ selected: [] }) //this.onCloseLeadDetails()

  onSelectAll = () => {
    const {
      listProps: { items },
    } = this.props
    this.setState({ selected: items.map(({ id }) => id) })
  }

  onOpenProductDetails = (kind) =>
    this.setState({ manageProduct: true, manageProductKind: kind })

  onCloseProductDetails = () => this.setState({ manageProduct: false })

  onQuickAction = async (id, action, e) => {
    const { selected } = this.state
    let ids = selected

    if (id && !selected.includes(id)) {
      ids = [id]
    }

    if (action === 'toExport') {
      return await this.props.onExportToCSV()
    }

    if (!ids || !ids.length) {
      return false
    } else if (action === 'toIrrelevant') {
      return this.onToggleIrrelevant(ids)
    } else if (action === 'toProspects') {
      return this.onToggleProspects(ids, e /* Direction */)
    } else if (action === 'toPartners') {
      return this.onTogglePartners(ids, 'partners')
    } else if (action === 'toCompetitors') {
      return this.onToggleCompetitors(ids)
    } else if (action === 'toManageTags') {
      this.onManageLeadTags(e, selected.includes(id) ? null : id)
    } else if (action === 'toRequestContacts') {
      if (!id || selected.includes(id)) {
        this.onRequestSelectedContacts()
      } else {
        this.onRequestContacts(id)
      }
    }
  }

  ///////////////// Tags /////////////////
  onManageLeadTags = (e, leadId) => {
    const { target } = e
    if (e.stopPropagation && e.nativeEvent) {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }

    this.setState({
      tagsManagementOpen: target,
      tagsManagementKind: 'lead',
      tagsManagementLead: leadId || null,
    })
  }

  onManageLeadTagsClose = () => {
    this.setState({
      tagsManagementOpen: null,
      tagsManagementKind: null,
      tagsManagementLead: null,
    })
  }

  onUpdateTag = (tag) => {
    this.props.onUpdateTag(tag)
    this.props.onUpdateLeadTags(tag)
    if (this.leadDetailsRef) {
      this.leadDetailsRef.updateTags(tag, 'update')
    }
  }

  onRemoveTag = (tagId, leadId) => {
    const leadIds = leadId ? [leadId] : this.state.selected
    this.props.onRemoveLeadTags(leadIds, [tagId], this.props.brandId)
    if (this.leadDetailsRef && leadId === this.state.leadId) {
      this.leadDetailsRef.updateTags(tagId, 'remove')
    }
  }

  onDeleteTag = (tagId) => {
    this.props.onDeleteTag(tagId)
    this.props.onRemoveLeadTags(
      this.props.listProps.items.map(({ id }) => id),
      [tagId]
    )
    if (this.leadDetailsRef) {
      this.leadDetailsRef.updateTags(tagId, 'remove')
    }
  }

  onAddLeadTag = (tag) => {
    const { tagsManagementLead, selected } = this.state
    const leadIds =
      tagsManagementLead && tagsManagementLead.length
        ? [tagsManagementLead]
        : selected
    this.props.onAddLeadTags(leadIds, [tag], this.props.brandId)
    if (this.leadDetailsRef && leadIds.includes(this.state.leadId)) {
      this.leadDetailsRef.updateTags(tag, 'add')
    }
  }

  //////// Contacts
  onRequestContactsClose = () => {
    this.setState({ requestContacts: {} })
  }

  onRequestContacts = (itemId, outChannelId) => {
    const { selected, leadId } = this.state
    let channelId = outChannelId

    if (!channelId) {
      const lead = find(this.props.listProps.items, ['id', itemId])
      if (lead) {
        channelId = lead.channelId
      } else {
        return
      }
    }

    this.setState({
      requestContacts: {
        open: true,
        itemIds: itemId,
        channelId,
        onRequestSingle: this.props.onRequestSingle,
        callback: (success) => {
          if (selected.includes(itemId) || leadId === itemId) {
            this.leadDetailsRef.setContactRequested(success)
          }
        },
      },
    })
  }

  onRequestSelectedContacts = () => {
    const { selected, leadId } = this.state

    this.setState({
      requestContacts: {
        open: true,
        itemIds: selected,
        onRequestSelected: this.props.onRequestSelected,
        callback: (success) => {
          if (leadId && selected.indexOf(leadId) >= 0 && this.leadDetailsRef) {
            this.leadDetailsRef.setContactRequested(success)
          }
        },
      },
    })
  }

  get prospectsTitle() {
    switch (this.props.kind) {
      case 'report':
        return 'Leads'
      case 'relevants':
        return 'Relevant'
      case 'competitors':
        return 'Competitors'
      case 'irrelevant':
        return 'Irrelevant'
      // case 'prospects-list':
      //   return this.props.listName
      default:
        return 'Breezy'
    }
  }

  get fakeItemIds() {
    const { selected } = this.state
    const {
      kind,
      listProps: { items },
    } = this.props
    if (['irrelevant', 'competitors'].includes(kind)) {
      return items
        .filter(({ id, fake }) => selected.indexOf(id) >= 0 && fake)
        .map(({ id }) => id)
    }
    return []
  }

  get leadDetails() {
    const { leadId } = this.state
    const lead = find(this.props.listProps.items, ['id', leadId])

    if (lead) {
      return {
        notesCount: lead.notesCount || null,
        itemTags: lead.tags || [],
        partner: lead.partner,
        prospect: lead.prospect,
      }
    } else if (Boolean(this.state.details)) {
      this.onCloseLeadDetails()
    }

    return {}
  }

  get leadTags() {
    const { tagsManagementLead, selected } = this.state

    if (tagsManagementLead) {
      const lead = find(this.props.listProps.items, ['id', tagsManagementLead])
      return lead.tags || []
    } else if (selected && selected.length) {
      const leadsTags = this.props.listProps.items
        .filter(({ id }) => selected.includes(id))
        .map(({ tags: t }) => t || [])
      let allTags = []
      leadsTags.forEach((t) => {
        allTags = unionBy(allTags, t, 'id')
      })

      let crossTags = []
      let tagCounter = 0
      allTags.forEach((tag) => {
        leadsTags.forEach((tags) => {
          const t = find(tags, ['id', tag.id])
          if (t) {
            tagCounter += 1
          }
        })
        if (tagCounter === leadsTags.length) {
          crossTags.push(tag)
        }
        tagCounter = 0
      })

      let result = []

      allTags.forEach((tag) => {
        if (!crossTags.some((t) => t.id === tag.id)) {
          result.push({ ...tag, unique: true })
        }
      })

      return [...result, ...crossTags]
    }
    return null
  }

  computeQuickActions = (leadId, isItem) => {
    const { selected } = this.state
    const {
      listProps: { items },
    } = this.props
    const leadInSelected = selected.includes(leadId)
    const selectedAmount = selected.length
    const prospectsAmount = items.filter(
      ({ id, prospect }) => selected.includes(id) && prospect
    ).length

    if (!leadInSelected) {
      return leadId ? {} : { prospect: selectedAmount === prospectsAmount }
    } else {
      if (isItem && leadInSelected) {
        return { prospect: selectedAmount === prospectsAmount }
      } else {
        return {}
      }
    }
  }

  get canRequestContacts() {
    const { selected } = this.state
    const {
      listProps: { items },
    } = this.props
    if (!selected.length) return false
    const itemsRequested = items.filter(({ id, contactStatus }) => {
      return (
        ['requested', 'found', 'optedout'].includes(contactStatus) &&
        selected.indexOf(id) >= 0
      )
    })
    return itemsRequested.length < selected.length
  }

  onSort = (key, value) => {
    this.onCloseLeadDetails()
    this.props.onSort(
      key,
      value,
      async () => {
        await sendEvent(this.props.brandId, 'sortingApplied')
      },
      () => {
        if (this.props.expired) {
          onOpenExpiredModal()
        }
        return this.props.expired
      }
    )
  }

  onPageChange = (page) => {
    // this.onCloseLeadDetails()
    this.props.onPageChange(page)
  }

  onPerPageChange = (perPage) => {
    this.onCloseLeadDetails()
    this.props.onPerPageChange(perPage)
  }

  render() {
    const {
      details,
      detailsKind,
      leadId,
      channelId,
      fake,
      selected,
      manageProduct,
      manageProductKind,
      confirmationBlocklistOpen,
    } = this.state
    const { kind, productLoading, listProps, sortProps, filters, hasFilters } =
      this.props
    const selectedAmount = selected.length

    return (
      <>
        <Blocker />
        <Helmet title={this.prospectsTitle} />
        <PageMain
          open={details && selectedAmount <= 1}
          thinking={productLoading}
        >
          <EmptyListPlaceholder
            kind={kind}
            show={!listProps.loading && listProps.total <= 0 && !hasFilters}
          >
            <Header>
              <Filter
                variant={'leads'}
                placeholder={'Select a filter'}
                filters={filters}
                keys={this.filtersConfig}
                withoutRelevant={this.props.withoutRelevant}
                onChange={this.onFiltersChange}
                onToggleWithoutRelevant={this.onToggleWithoutRelevant}
              />
            </Header>

            <ListTable
              {...listProps}
              items={listProps.items.map((item) => ({
                ...item,
                activeItem: leadId,
                selected, //: selected.indexOf(item.id) >= 0,
                fakeIds: this.fakeItemIds,
                quickActionsProps: this.computeQuickActions(item.id, true),
                view: 'leads',
                kind: 'leads',
                source: kind,
                itemActions: {
                  onDragEnd: this.onDragEnd,
                  onQuickAction: this.onQuickAction,
                  onUpdateTag: this.onUpdateTag,
                  onRemoveTag: this.onRemoveTag,
                  onDeleteTag: this.onDeleteTag,
                  onManageTags: this.onManageLeadTags,
                },
              }))}
              view={'leads'}
              kind={'leads'}
              locked={this.props.paywall || this.props.expired}
              lockedKind={this.props.paywall ? 'paywall' : 'expired'}
              lockedThreshold={
                this.props.paywall
                  ? Config.PAYWALL_LEADS_THRESHOLD
                  : Config.EXPIRED_LEADS_THRESHOLD
              }
              source={kind}
              selected={selected}
              sortProps={sortProps}
              quickActionProps={{
                ...this.computeQuickActions(),
                kind,
                view: 'leads',
                disable: selectedAmount < 1,
                canRequestContacts: this.canRequestContacts,
                onAction: this.onQuickAction,
              }}
              hasFilters={this.props.hasFilters}
              onClick={this.onListItemClick}
              onSelect={this.onSelect}
              onGetAllIds={this.props.onGetAllIds}
              onSort={this.onSort}
              onPageChange={this.onPageChange}
              onPerPageChange={this.onPerPageChange}
              expiredProps={{
                daysLeft: this.props.expiredDaysLeft,
                searchName: this.props.productName,
              }}
            />
          </EmptyListPlaceholder>
        </PageMain>

        <Details
          ref={(node) => (this.leadDetailsRef = node)}
          variant={'lead'}
          kind={kind}
          open={Boolean(leadId && details)}
          channelId={channelId}
          itemId={leadId}
          brandId={this.props.brandId}
          geoFilter={this.props.geoFilter}
          tagManagement={{
            onUpdate: this.onUpdateTag,
            onRemove: this.onRemoveTag,
            onDelete: this.onDeleteTag,
            onManage: this.onManageLeadTags,
          }}
          onClose={this.onCloseLeadDetails}
          onQuickActions={this.onQuickAction}
        />

        {/* Confirm add to competitors or blocklist */}
        <ConfirmationDialog
          open={confirmationBlocklistOpen}
          onClose={this.onCloseBlocklistConfirmation}
          {...this.confirmationBlocklistProps}
        />

        {/* Confirm add prospects with partners to Leads */}
        <ConfirmationDialog
          open={this.state.toggleConfirmationOpen}
          message={this.state.toggleMessage}
          actionText={this.state.toggleAction}
          onAction={this.onTogglePartnersOrLeads}
          onClose={this.onCloseToggleConfirmation}
        />
        {manageProduct && (
          <ProductDetails
            open={manageProduct}
            kind={manageProductKind}
            onClose={this.onCloseProductDetails}
          />
        )}

        {Boolean(this.state.tagsManagementOpen) && (
          <TagsManagement
            itemTags={this.leadTags}
            kind={this.state.tagsManagementKind}
            leadIds={this.state.tagsManagementLead}
            anchorEl={this.state.tagsManagementOpen}
            onClose={this.onManageLeadTagsClose}
            onSelectTag={this.onAddLeadTag}
            onUpdateTag={this.onUpdateTag}
            onRemoveTag={this.onRemoveTag}
            onDeleteTag={this.onDeleteTag}
          />
        )}

        <RequestContacts
          {...this.state.requestContacts}
          onClose={this.onRequestContactsClose}
        />
      </>
    )
  }
}
