import React from 'react'
import styled from 'styled-components'
import WidgetListBase from '../Base/WidgetListBase'
import {WidgetBaseProps} from '../Base/WidgetBase'
import CountryFlag from '../../CountryFlag/CountryFlag'
import Tooltip from '@mui/material/Tooltip'
import metrics from '../../../configMetrics'
import LabelPosition from '../../LabelPosition/LabelPosition'

const Keyword = styled.div`
    display: flex;
    align-items: center;
    gap: 1em;
`


const Name = styled.div`
    color: ${props => props.theme.palette.accentDarkText};
    flex: 1 0 auto;
`

const Geo = styled.div`

`

interface KeywordObject {
    position: number,
    name: string,
    geo: string
}

interface WidgetKeywordsProps extends WidgetBaseProps {
    keywords?: Array<KeywordObject>
    variant: string
}

function WidgetKeywords ({ keywords, variant, ...other }: WidgetKeywordsProps) {
    return (
        <WidgetListBase {...other} list label={metrics[variant].shortName} tooltip={{...metrics[variant]}}>
            {keywords && keywords.map((keyword, index) => (
                <Keyword>
                    <Tooltip title={`Position in Google search`} placement="top">
                    <LabelPosition key={index} value={keyword.position} />
                    </Tooltip>
                    <Name>{keyword.name}</Name>
                    <Geo><CountryFlag iso={keyword.geo} /></Geo>
                </Keyword>
            ))}
        </WidgetListBase>
    )
}

WidgetKeywords.defaultProps = {
    variant: 'keywordsHighest',
    value: 'Coming soon...',
    size: '2x1'
}

export default WidgetKeywords
