import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import {
  Tab,
  Tabs
} from '../Modal'
import { credits } from '../../utils/credits'
import CurrentCost from "../CurrentCost";
import { Container as LoaderOverlay } from '../Loader/LoaderOverlay'
import Running from '../../pages/Welcome/Running'

import InputsForm from '../../new-components/Forms/InputsForm'

const Root = styled.div`
  height: 100%;
  .MuiFormLabel-root.Mui-focused {
    color: #3E3E3E;
  }
  overflow-x: hidden;
  padding: 0 1.6em;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin: 0 2em 0 0;
  min-height: 3em;
  display: flex;
  align-items: center;
`

const FormContainer = styled.div`
  width: 100%;
  //position: relative;
  z-index: 10;
  margin: 0 auto;
  flex: 1;
  margin-bottom: 5em;
`;


export default class ProductDetails extends React.Component {
  constructor(props) {
    super(props)
    const { planLimits } = props
    this.state = {
      kind: props.kind || 'keywords',
      keywords: props.keywords,
      keywordsErrors: planLimits.keywords ? ['maxRow'] : [],
      competitors: props.competitors,
      competitorsErrors: planLimits.competitors ? ['maxRow'] : [],
      blocklist: props.blocklist,
      blocklistErrors: [],
      hasChanges: false
    }
  }

  onChange = kind => (value, errors) => {
    this.setState({[`${kind}Errors`]: errors})
    this.setState({[`${kind}`]: value, hasChanges: true})
  }

  onSave = async (run) => {
    this.setState({thinking: true})
    const {
      keywordsErrors,
      competitorsErrors,
      blocklistErrors,
      keywords,
      competitors,
      blocklist,
      hasChanges
    } = this.state
    if (!!competitorsErrors.length || !!keywordsErrors.length || !!blocklistErrors.length) {
      this.setState({thinking: false})
    } else {
      if (hasChanges) {
        const {error} = await this.props.onUpdate({keywords, competitors, blocklist})
        this.setState({ thinking: false, hasChanges: !!error })
      } else {
        this.setState({ thinking: false, hasChanges: false })
      }

      if (run) {
        this.props.onStartProductResearch()
      }
    }
  }

  onTabChange = (event, kind) => {
    this.setState({kind})
  }

  get credits () {
    const { creditsLeft } = this.props
    const { competitors, keywords } = this.state
    const competitorsCount = competitors.filter(r => !!r && r.length).length
    const keywordsCount =  keywords.filter(r => !!r && r.length).length

    return credits({ competitorsCount, keywordsCount, CREDITS: creditsLeft })
  }

  render () {
    const { variant, inputsChanged, researchStatus, locked } = this.props
    const {
      blocklistErrors,
      competitorsErrors,
      keywordsErrors,
      kind,
      hasChanges,
      thinking,
      keywords,
      competitors,
      blocklist
    } = this.state
    const hasErrors = !!competitorsErrors.length || !!keywordsErrors.length || !!blocklistErrors.length

    return (
      <Root>
        <FormContainer>
          { researchStatus === 'progress' && <LoaderOverlay opaque><Running disableNotification /></LoaderOverlay>}


          <Grid container spacing={8}>
            <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center'}}>
              <Heading>Search Settings</Heading>
            </Grid>
            <Grid item xs={12} md={6} style={{paddingBottom: 10, fontSize: 14, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <div style={{padding: '1.2em 1em 0 0', maxWidth: '28rem', margin: '0 auto',  width: '100%'}}>
                <CurrentCost credits={this.credits} />
              </div>
            </Grid>
          </Grid>


          <Tabs value={kind} onChange={this.onTabChange}>
            <Tab
              error={!!keywordsErrors.length}
              label={<p>Keywords {keywords.filter(i => !!i).length > 0 && <span>{keywords.length}</span>}</p>}
              value={'keywords'}
              disableRipple
              disableTouchRipple
              disableFocusRibble
            />
            <Tab
              error={!!competitorsErrors.length}
              label={<p>Competitors {competitors.filter(i => !!i).length > 0 && <span>{competitors.length}</span>}</p>}
              value={'competitors'}
              disableRipple
              disableTouchRipple
              disableFocusRibble
            />
            <Tab
              error={!!blocklistErrors.length}
              label={<p>Irrelevant {blocklist.filter(i => !!i).length > 0 && <span>{blocklist.length}</span>}</p>}
              value={'irrelevant'}
              disableRipple
              disableTouchRipple
              disableFocusRibble
            />
          </Tabs>

          {
            kind === 'irrelevant' && <InputsForm
              locked={locked}
              kind={'blocklist'}
              variant={variant}
              credits={this.credits}
              values={blocklist}
              thinking={thinking}
              errors={blocklistErrors}
              hasErrors={hasErrors}
              hasChanges={hasChanges}
              hasUpdates={inputsChanged}
              helpText={'Type one URL per line'}
              placeholder={'e.g. https://example.com'}
              onChange={this.onChange('blocklist')}
              onSubmit={this.onSave}
            />
          }


          {
            kind === 'competitors' && <InputsForm
              locked={locked}
              kind={'competitors'}
              variant={variant}
              credits={this.credits}
              values={competitors}
              thinking={thinking}
              errors={competitorsErrors}
              hasErrors={hasErrors}
              hasChanges={hasChanges}
              hasUpdates={inputsChanged}
              helpText={'Type one URL per line'}
              placeholder={'e.g. https://example.com'}
              onChange={this.onChange('competitors')}
              onSubmit={this.onSave}
            />
          }

          {
            kind === 'keywords' && <InputsForm
              locked={locked}
              kind={'keywords'}
              variant={variant}
              credits={this.credits}
              values={keywords}
              thinking={thinking}
              errors={keywordsErrors}
              hasErrors={hasErrors}
              hasChanges={hasChanges}
              hasUpdates={inputsChanged}
              helperText={'Enter one keyword or phrase per line'}
              errorText={'Please enter keyword or phrase'}
              onChange={this.onChange('keywords')}
              onSubmit={this.onSave}
            />
          }

        </FormContainer>
      </Root>
    )
  }
}
