import React, { useState } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import Table from '../TableCompact'

import CountryFlag from '../CountryFlag/CountryFlag'
import IconLabel from '../IconLabel'

import { Competitor } from '../Details/interfaces'
import IconLink from "../IconLink";

const Domain = styled(IconLink)`
    color: ${props => props.theme.palette.accentDarkText};
    font-weight: bold;
`

const useStyles = makeStyles((theme) => ({}))

interface TableCompetitorsProps {
    loading?: boolean
    data: Array<Competitor>
}

const renderCompetitor = (params) => {
    return <Domain icon={params.row.icon} domain={params.row.domain}/>
}

const renderCountry = (params) => {
    const { row: { geo } } = params
    return <CountryFlag iso={geo} />
}

const renderSearch = (params) => {
    // @ts-ignore
    const { brandId } = useParams()
    const { row: { score, search, searchId } } = params

    return <IconLabel value={score} label={search} to={`/brand/${brandId}/discovery/${searchId}`}/>
}

const columns = [
    {
        field: 'domain',
        headerName: 'Competitor',
        flex: 1,
        renderCell: renderCompetitor
    },
    {
        field: 'geo',
        headerName: 'Country',
        width: 70,
        renderCell: renderCountry
    },
    {
        field: 'search',
        headerName: 'Search',
        width: 250,
        renderCell: renderSearch
    }
]

function TableCompetitors ({ loading, data }: TableCompetitorsProps) {
    const classes = useStyles()

    return (
            <Table
                loading={loading}
                rows={data}
                columns={columns}
                responsive
            />
    )
}

TableCompetitors.defaultProps = {}

export default TableCompetitors
