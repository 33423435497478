import React from 'react'
import Helmet from 'react-helmet'
import styled, {createGlobalStyle, css} from 'styled-components'
import '../components/Fonts/fonts.css'
import {CssBaseline, Grid, IconButton, DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {BreezyLogo} from '../components/Icons/BreezyLogo'
import LoaderOverlay from '../components/Loader/LoaderOverlay'
import Discovery from '../components/Discovery'
import { Tab, Tabs } from '../components/Modal'
import pluraliseUnit from "../utils/pluraliseUnit"
import CurrentCost from "../components/CurrentCost";

const BgContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: ${props => props.column ? 'row' : 'column'};
`;

const FormContainer = styled.div`
  width: 100%;
  z-index: 10;
  margin: 0 auto;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const LogoHolder = styled.div`
  //padding: 25px 0 0 21px;
  width: 90px;
  box-sizing: border-box;
`
const Content = styled.div`
  flex: 1 0 auto;
  height: 100%;
`

const Close = styled(IconButton)`
    position: absolute;
    right: 5px;
    top: 5px;
  z-index: 1000;
`;


const WelcomeDiscovery = ({
                            children,
                            title,
                            thinking,
                            variant,
                            ...other
                          }) => {

  /// variant property
  /// run
  /// re-run
  /// contacts
  /// no-credits
  /// plans
  /// inactive-account

  return (
    <>
      <DialogTitle>
        {/*<LogoHolder>*/}
        {/*  <BreezyLogo compact/>*/}
        {/*</LogoHolder>*/}
        <Close onClick={other.onClose}>
          <CloseIcon />
        </Close>
      </DialogTitle>
      <DialogContent style={{padding: other.noPadding && 0}}>
      {thinking && <LoaderOverlay size={35} />}
      {
        !thinking &&
        <BgContainer>
          <FormContainer>
            <Content>
              {children}
            </Content>
          </FormContainer>
        </BgContainer>
      }
      </DialogContent>
    </>
  )
}

export default WelcomeDiscovery
