import { observable, action, computed, toJS } from 'mobx'
import param from 'can-param'

import GqlClient from '../gql/gql-client'
import hasError from './request-message'
import { prepareEdges } from '../utils/gql-data'

import { GET_CONTACT_REQUESTS } from '../gql/contacts'
import { getSorting } from '../utils/sorting'
import { getFilterVariables } from '../configFilters'
import { channelsTags, channelsSystemTags } from '../gql/channels'
import { MARK_CHANNEL_WITH_TAG, REMOVE_TAG_FROM_CHANNELS } from '../gql/tags'
import { contactRequestToListItem } from '../utils/list-items'
import { createCSV, download } from '../utils/download-blod'

class ContactRequestStore {
  brandId = null

  @observable page = 1
  @observable perPage = 25
  @observable total = 0
  @observable order = 'desc'
  @observable orderBy = 'createdAt'
  @observable items = []
  @observable _filters = { search: '' }
  @observable loading = true
  @observable thinking = false

  constructor(brandId) {
    this.brandId = brandId
  }

  @computed
  get pagination() {
    const page = toJS(this.page) - 1 || 0
    const perPage = toJS(this.perPage)
    const take = perPage
    const skip = page * perPage

    return {
      take,
      skip,
    }
  }

  @action
  async load(brandId) {
    this.brandId = brandId || this.brandId

    const variables = {
      ...this.pagination,
      orderBy: getSorting(this.order, this.orderBy),
      ...getFilterVariables(toJS(this._filters)),
      brandId: this.brandId,
    }

    const { success, ...rest } = await GqlClient.query({
      query: GET_CONTACT_REQUESTS,
      variables,
    })

    if (!hasError(success, null, null, () => this.load())) {
      const { totalCount, edges } = rest.data['contactRequestsConnection']
      let items = prepareEdges(edges)
      items = await channelsTags(items, this.brandId)
      items = await channelsSystemTags(items)

      this.items = this.page === 1 ? items : [...toJS(this.items), ...items]
      this.total = this.page === 1 ? totalCount : this.total
    }

    this.loading = false
  }

  @action
  async nextPage() {
    this.page = this.page + 1
    await this.load()
  }

  @action
  async pageChange(page) {
    if (
      toJS(this.items).length < this.total &&
      !this.loading &&
      !this.thinking
    ) {
      this.page = this.page + 1
      await this.load()
    }
  }

  @action
  async perPageChange(perPage) {
    this.page = 1
    this.perPage = perPage
    await this.load()
  }

  @action
  async filtersChange(filters) {
    this.page = 1
    this._filters = filters
    await this.load()
  }

  @action
  async sorting(orderBy, order) {
    this.order = order
    this.orderBy = orderBy
    await this.load()
  }

  @action
  async addTags(channelId, tag) {
    this.items = toJS(this.items).map((item) => {
      if (item.channelId === channelId) {
        const { tags } = item
        return {
          ...item,
          tags: [tag, ...tags].filter((t) => Boolean(t)),
        }
      }
      return item
    })

    const payload = {
      id: tag.id,
      brandId: this.brandId,
      channelIds: [channelId],
    }

    return await GqlClient.mutation({
      mutation: MARK_CHANNEL_WITH_TAG,
      variables: { MarkChannelsWithTagInput: payload },
    })
  }

  @action
  async removeTags(channelId, tagId) {
    this.items = toJS(this.items).map((item) => {
      if (item.channelId === channelId) {
        const { tags } = item
        return {
          ...item,
          tags: tags.filter((tag) => tag.id !== tagId),
        }
      }
      return item
    })

    const payload = {
      id: tagId,
      brandId: this.brandId,
      channelIds: [channelId],
    }

    return await GqlClient.mutation({
      mutation: REMOVE_TAG_FROM_CHANNELS,
      variables: { RemoveTagFromChannelsInput: payload },
    })
  }

  @action
  async updateTags(tag) {
    this.items = toJS(this.items).map((item) => {
      let tags = (item.tags || []).map((t) => {
        if (t.id === tag.id) {
          return tag
        }
        return t
      })
      return { ...item, tags }
    })
  }

  @action
  async deleteTags(tagId) {
    this.items = toJS(this.items).map((item) => {
      const tags = item.tags || []
      return {
        ...item,
        tags: tags.filter((tag) => tag.id !== tagId),
      }
    })
  }

  @action
  async exportToCSV() {
    const variables = {
      skip: 0,
      take: this.total,
      orderBy: getSorting(this.order, this.orderBy),
      ...getFilterVariables(toJS(this._filters)),
      brandId: this.brandId,
    }

    const { success, ...rest } = await GqlClient.query({
      query: GET_CONTACT_REQUESTS,
      variables,
    })

    if (success) {
      const { edges } = rest.data['contactRequestsConnection']
      let requests = prepareEdges(edges)
      requests = await channelsTags(requests, this.brandId)

      requests = requests.map((r) => {
        return contactRequestToListItem(r)
      })

      download(createCSV(requests, 'requests'), 'requests.csv')
    }
  }

  @action
  setInitial(brandId) {
    this._filters = {}
    this.brandId = brandId || null
    this.page = 1
    this.perPage = 25
    this.total = 0
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.items = []

    this.loading = true
    this.thinking = false
  }

  @computed
  get listProps() {
    const { loading, thinking, items, page, perPage, total, kind } = this
    return {
      kind,
      thinking,
      loading,
      items: toJS(items).map(contactRequestToListItem),
      total,
      page,
      perPage,
    }
  }

  @computed
  get sortProps() {
    const { order, orderBy } = this
    return { order, orderBy }
  }

  @computed
  get filters() {
    const { ...rest } = this._filters
    return rest
  }

  @computed
  get queries() {
    const { page, perPage, order, orderBy, _filters } = this

    return param({
      page,
      perPage,
      order: `${order === 'desc' ? '' : '-'}${orderBy}`,
      filters: _filters,
    })
  }
}

export default new ContactRequestStore()
