import React from 'react'
import { ListItemText as MuiListItemText } from '@mui/material'

import SingleValue from '../SelectDropdown/SingleValue'
import Autocomplete from '../Autocomplete/Autocomplete'

const EcosystemsSelect = (props) => {
  const ecosystems = props.ecosystems
  const onSelect = ({ id }) => {
    props.onSetSelected(id)
  }

  return (
    <div style={{marginRight: '2em', position: 'relative'}}>
      <SingleValue
        value={props.selected}
        options={ecosystems}
        onApply={onSelect}
      />
      {/*<Autocomplete*/}
      {/*  clearable*/}
      {/*  single*/}
      {/*  options={ecosystems}*/}
      {/*  value={props.selected}*/}
      {/*  renderOption={params => (*/}
      {/*      <MuiListItemText primary={params.label} secondary={params.description}*/}
      {/*                       sx={{paddingLeft: 0}}*/}
      {/*                       primaryTypographyProps={{sx: {fontSize: 14}}}*/}
      {/*                       secondaryTypographyProps={{sx: {fontSize: 13}}}*/}
      {/*      />*/}
      {/*  )}*/}
      {/*  onChange={(e, a) => {*/}
      {/*    if (a) {*/}
      {/*      onSelect(a)*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  placeholder={(!!props.selected && props.selected.length > 0) ? false : 'Select Ecosystem'}*/}
      {/*/>*/}
    </div>
  )
}

export default EcosystemsSelect
