import React, { Component } from 'react'
import styled from 'styled-components'
import WidgetBase, {Value, Label, WidgetBaseProps} from '../Base/WidgetBase'
import CountryFlag from '../../CountryFlag'
import resolveCountry from '../../../utils/resolveCountry'
import {formatToReadableNumber} from '../../../utils/format'
import DoughnutChart from '../../Charts/DoughnutChart'
import {COMPACT_CHART_COLORS} from '../../../config'
import {find, orderBy, sumBy} from "lodash";
import {TooltipMetricsBase} from '../../TooltipMetrics'
import {metrics, poweredBY} from '../../../configMetrics'
import ButtonBase from '@mui/material/ButtonBase'

const Wrapper = styled.div`
  //display: grid;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3em;
  width: 50%;
`

const Chart = styled.div`
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 0;
  top: -1.6em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ChartLabelRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2em;
  gap: 0.3em;
`

const ChartLabelItem = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  :before {
    content: '';
    position: relative;
    top: 0.1em;
    width: 1em;
    height: 1em;
    display: inline-block;
    border-radius: 1em;
    font-size: 1em;
    background-color: ${props => props.color};
  }
`

const ChartLabelValue = styled.strong`
    font-weight: bold;
`

const ChartLabelCountry = styled.span`
    opacity: 0.9;
  font-weight: normal;
`

const Total = styled.div`
  color: ${props => props.theme.palette.accentDarkText};
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

interface Country {
    code: string;
    visits: number;
}

interface ChartData {
    labels: string[];
    datasets: {
        backgroundColor: string[];
        data: number[];
        hoverOffset: number;
    }[];
}

interface VisitsData {
    geo: Array<string> | undefined;
    total: number;
    visits: number | undefined;
    chartData: ChartData;
    labels: Array<string>;
    data: Array<number>
}

const getVisitsValue = (geo: Array<string> | undefined, visitsByGeo: any, total: number): number | undefined => {
    if (geo && visitsByGeo) return sumBy(visitsByGeo, 'visits')
    if (geo && !visitsByGeo) return undefined
    return total
}

const getVisitsData = (countries: Country[], geo: Array<string>  | undefined, limit: number, total: number): VisitsData => {
    let visitsByGeo = (countries || []).filter(({ code }) => Boolean(geo) && geo.includes(code))

    const sorted = orderBy(countries, ['visits'], ['desc'])
    const first = sorted.slice(0, limit)
    const firstVisits = sumBy(first, 'visits')
    const labels = first.map(({ code }) => code)
    const data = first.map(({ visits }) => Math.round(visits))
    const otherVisits = total - firstVisits

    if (otherVisits > 0) {
        labels.push('other')
        data.push(otherVisits)
    }

    return {
        geo,
        total,
        visits: getVisitsValue(geo, visitsByGeo, total), //geo && visitsByGeo ? Math.round(visitsByGeo.visits) : total,
        chartData: { labels, datasets: [{ backgroundColor: COMPACT_CHART_COLORS, data, hoverOffset: 4 }]},
        labels,
        data
    }
}

interface ChartLabelProps {
    data: Array<number>
    labels: Array<string>
    total: number
}

function ChartLabel ({ data, labels, total }: ChartLabelProps) {
    return (
        <ChartLabelRoot>
            {
                data.map((visits, index) => {
                    const value = formatToReadableNumber(visits, true)
                    const geo = labels[index]
                    const country = geo !== 'other' ? resolveCountry(geo).label : ' Other'

                    return (
                        <ChartLabelItem key={index} color={COMPACT_CHART_COLORS[index]}>
                            <ChartLabelValue>{value}</ChartLabelValue><ChartLabelCountry>{country}</ChartLabelCountry>
                        </ChartLabelItem>
                    )
                })
            }
        </ChartLabelRoot>
    )
}

interface CountriesLabelProps {
    geo: Array<string> | undefined
}

function CountriesLabel ({ geo }: CountriesLabelProps) {
    if (!geo || geo.length === 0) return null
    const geoCount = geo.length
    const otherGeo = geoCount - 1

    return (
        <>
            <CountryFlag iso={geo[0]} />
            { otherGeo > 0 && `+${otherGeo}`}
        </>
    )
}

interface WidgetVisitsProps extends WidgetBaseProps {
    visits?: React.ReactNode
    countries?: any // TODO: type
    total?: number
    geo?: Array<string> | undefined
}

function WidgetVisits ({ countries, geo, onClick, ...other  }: WidgetVisitsProps) {
    const { total, visits, chartData, labels, data } = getVisitsData(countries, geo, 3, other.total)

    return (
        <WidgetBase {...other} component={ButtonBase} onClick={() => onClick(metrics.visits.target)}>
            <Label tooltip={metrics.visits}>{metrics.visits.heading}</Label>
            <Wrapper>
                <Column>
                    <CountriesLabel geo={geo} />
                    {/*{ Boolean(geo) && <CountryFlag iso={geo} /> }*/}
                    <Value value={visits ? formatToReadableNumber(visits, true) : undefined} />
                </Column>
                <Column>
                {!!total && !!chartData &&
                    <TooltipMetricsBase title={<>Total visits: {formatToReadableNumber(total, true)}</>}
                                        poweredBy={poweredBY.similarWeb}
                                        content={<ChartLabel data={data} labels={labels} total={total} />}>
                        <Chart>
                            <Total>{formatToReadableNumber(total, true)}</Total>
                            <DoughnutChart data={chartData} />
                        </Chart>
                    </TooltipMetricsBase>
                }
                </Column>
            </Wrapper>
        </WidgetBase>
    )
}

WidgetVisits.defaultProps = {
    onClick: () => void(0)
}

export default WidgetVisits
