import React, { Component } from 'react'
import styled from 'styled-components'
import StackedRowChart from "../../Charts/StackedRowChart/StackedRowChart";
import ProfileSection from '../ProfileSection'

export const colors = [
    '#65FDD8',
    '#65FD99',
    '#BAFD65',
    '#FDF765',
    '#FDD265',
    '#ffb395',
    '#fc8c8c',
    '#f972a4ff',
    '#ff84d4',
    '#c798fd',
    'rgb(44,56,71)'
]


interface NetworksFullProps {
    loading?: boolean
    networks?: Array<any>
}

function NetworksFull ({loading, networks }: NetworksFullProps) {
    return (
        <ProfileSection title={'Networks Used'} fullWidth>
            <StackedRowChart
                percentage
                loading={loading}
                data={(networks || []).map(({ id, name: title, percent: value }) => ({ id, title, value /*: parseFloat(value.toFixed(0))*/ }))}
                colorScheme={colors}
            />
        </ProfileSection>
    )
}

NetworksFull.defaultProps = {
  value: 'It\'s a me, NetworksFull!'
}

export default NetworksFull
