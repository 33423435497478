import React, { useCallback, useEffect, useRef, useState, createContext, useContext } from 'react'
import { findIndex, sortBy, uniq } from 'lodash'
import {DataGridPremium, useGridApiRef, LicenseInfo, gridClasses, GridRow, GridOverlay} from '@mui/x-data-grid-premium'
import {ThumbsUp, ThumbsDown, MoreHorizontal} from 'react-feather'
import styled, {css} from 'styled-components'
import {formatDateFull} from '../../utils/format'

import Title from '../../components/Leads/Title'
import {
  Container, Content, Domain,
  useStyles, LoadingOverlay
} from './ListTable'

import ListContext from './ListContext'
import config from '../../config'
import { Button, Box } from '@mui/material'
import LeadsPlaceholder from '../../components/LeadsPlaceholder'

LicenseInfo.setLicenseKey(config.DATA_GRID_LICENSE_KEY)

export const Header = styled.div`
  display: flex;
  //align-items: center;
  line-height: normal;
  flex-direction: column;
  gap: 0.2em;
  //justify-content: flex-start;
`

const ActionsRoot = styled.div`
  display: flex;
  opacity: 0;
  visibility: hidden;
  flex-direction: row;
  padding: 10px 40px;
  padding-left: 80px;
  gap: 1em;
  background: rgb(245,245,245);
  background: linear-gradient(270deg, rgba(245,245,245,1) 80%, rgba(245,245,245,0) 100%);
  height: calc(100% - 1px);
  position: absolute;
  z-index: 1;
  top: 0;
  right: -10px;
  align-items: center;
`

const MoreRoot = styled.div`
  display: flex;
  opacity: 0;
  visibility: hidden;
  flex-direction: row;
  padding: 10px 40px;
  padding-left: 80px;
  background: rgb(245,245,245);
  background: linear-gradient(270deg, rgba(245,245,245,1) 80%, rgba(245,245,245,0) 100%);
  gap: 1em; 
  height: calc(100% - 1px);
  position: absolute;
  z-index: 2;
  top: 0;
  right: -10px;
  align-items: center;
`

const Row = styled(GridRow)`
 min-height: 100px;
  overflow: hidden;
 &:hover {
    ${ActionsRoot} {
      opacity: 1;
      visibility: visible;
      right: 0;
      transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out, right 0.2s ease-in-out;
    }
   ${MoreRoot} {
     opacity: 1;
     visibility: visible;
     right: 0;
     transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out, right 0.2s ease-in-out;
   }
 }
`

const Heading = styled.div`
  display: flex;
  gap: 2em;
  //justify-content: center;
  align-items: center;
  font-size: 1.3em;
  width: 100%;
  padding: 0 24px;
  & p {
    opacity: 0.8;
  }
`

export const Body = styled.div`
  flex: 1 1 15em;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  //padding: 0.2em 1em 0.2em 0;
  overflow: hidden;
  height: 100%;
  ${props => props.disable && css`
    pointer-events: none;
  `}
`

function renderTitle (params) {
  const ref = useRef(null)
  const context = useContext(ListContext)
  const { row: item} = params

  return (
    <Body
      ref={ref}
    >
      <Content>
        <Header>
          <Domain icon={item.icon} domain={item.url} />
          <Title obfuscate={item.obfuscate}>{item.title}</Title>
        </Header>
      </Content>
      <More />
    </Body>
  )
}

const Actions = ({ id }) => {
  const { kind, onToggleMessage } = useContext(ListContext)

  const onAction = target => e => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    onToggleMessage(id, target)
  }

  return (
      <ActionsRoot>
        {
          (kind !== 'accepted') && <Button color={'primary'} variant={'outlined'} startIcon={<ThumbsUp size={14} />} onClick={onAction('accepted')}>Accept</Button>
        }
        {
          (kind !== 'rejected') && <Button color={'error'} variant={'outlined'} startIcon={<ThumbsDown size={14} />} onClick={onAction('rejected')}>Reject</Button>
        }
      </ActionsRoot>
  )
}

const More = () => {
  return (
    <MoreRoot>
      <Button variant={'outlined'} size="small" endIcon={<MoreHorizontal size={14} />}>More</Button>
    </MoreRoot>
  )
}

function renderDate (params) {
  const { row: { id, date } } = params
  return (
    <>
      {formatDateFull(date)}
      <Actions id={id} />
    </>
  )
}

const columns = [
  {
    field: 'url',
    headerName: 'Brand',
    editable: false,
    sortable: true,
    disableReorder: true,
    minWidth: 400,
    renderCell: renderTitle,
    flex: 1
  },
  {
    field: 'message',
    headerName: 'Message',
    flex: 2,
    disableReorder: true,
    sortable: false
  },
  {
    field: 'date',
    headerName: 'Date',
    // disableReorder: true,
    minWidth: 200,
    renderCell: renderDate,
  }
]

const message = {
  inbox: {
    title: 'Inbox',
    description: 'By accepting these partnership invitations you will share your contact information with relevant brands.'
  },
  accepted: {
    title: 'Accepted',
    description: 'These are the brands you agreed to share your contact information with.'
  },
  rejected: {
    title: 'Rejected',
    description: 'These are the brands you rejected to share your contact information with.'
  }
}

const NoRowsOverlay = () => {
  return (
    <GridOverlay>
      <LeadsPlaceholder state={'noMessages'}/>
    </GridOverlay>
  )
}

export default function CreatorRequestList (props) {
  const {
    loading, kind, items, thinking,
    total,
    sortProps,
    onToggleMessage
  } = props

  const apiRef = useGridApiRef()
  const classes = useStyles()
  const sortModel = { sort: sortProps.order, field: sortProps.orderBy }

  const onSort = model => {
    if (!loading) {
      if (model && model[0]) {
        const { sort: direction, field } = model[0]
        props.onSort(field, direction)
      }
    }
  }

  return (
    <ListContext.Provider value={{
      kind,
      onToggleMessage
    }} >
      <Heading>
        <h1>{message[kind].title}</h1>
        <p>{message[kind].description}</p>
      </Heading>
      <Container>
        <DataGridPremium
          hideFooter
          apiRef={apiRef}
          columnBuffer={30}
          disableSelectionOnClick
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          disableColumnMenu
          disableColumnReorder={false}
          sortingMode="server"
          paginationMode="server"
          classes={classes}
          loading={loading || thinking}
          rows={items}
          rowHeight={100}
          sortingOrder={['desc', 'asc']}
          sortModel={[sortModel]}
          onSortModelChange={onSort}
          onRowClick={props.onRowClick}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          columns={columns}
          slots={{
            row: Row,
            loadingOverlay: LoadingOverlay,
            noRowsOverlay: NoRowsOverlay
          }}
          onRowsScrollEnd={(a, b) => {
            props.onPageChange()
          }}
          scrollEndThreshold={50}
        />
      </Container>
    </ListContext.Provider>
  )
}
