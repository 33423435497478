import React from 'react'
import {withRouter} from 'react-router-dom'
import {inject, observer} from 'mobx-react'
import qs from 'query-string'
import UniversalCookie from 'universal-cookie'
import UserForm from '../../components/Forms/UserOnboarding'
import styled from 'styled-components'

const Cookies = new UniversalCookie()

const removeTags = () => {
  return Cookies.remove(`tags`)
}

const readTags = () => {
  return Cookies.get(`tags`)
}

const saveTags = (tags) => {
  Cookies.set(`tags`, JSON.stringify(tags), { path: '/' })
}

const UTM_TAGS = ['utm_medium', 'utm_source', 'utm_campaign', 'utm_content']

const Root = styled.div`
  padding: 0 0 50px 0;
`


@inject(({UserStore}) => ({
  thinking: UserStore.thinking,
  user: UserStore.user,
  onSubmit: (details, tags) => UserStore.signUpOnboarding(details, tags)
}))
@observer
@withRouter
export default class WelcomeUser extends React.Component {
  constructor(props) {
    super(props)
    const {firstName, lastName, email, ...rest} = qs.parse(props.location.search)

    let tags = {}

    UTM_TAGS.forEach(tag => {
      if (rest.hasOwnProperty(tag)) {
        tags[tag] = rest[tag]
      }
    })

    saveTags(tags)

    this.state = {firstName, lastName, email, tags}
  }

  onSubmit = async (details) => {
    const tags = readTags() || this.state.tags

    const {success, error} = await this.props.onSubmit(details, tags)

    if (success) {
      removeTags()
      this.props.history.push('/welcome/step/company')
    }

    return {error}
  }

  render() {
    const {firstName, lastName, email: propsEmail, thinking} = this.props
    const {firstName: firstNameState, lastName: lastNameState, email: stateEmail} = this.state

    return (
      <Root>
        <UserForm
          welcome
          thinking={thinking}
          firstName={firstName || firstNameState}
          lastName={lastName || lastNameState}
          email={propsEmail || stateEmail}
          onSubmit={this.onSubmit}
          header={'Sign up'}
          subHeader={`Sign up to create an account and start your journey towards easy-breezy partner discovery.`}
        />
      </Root>
    )
  }
}
