import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import { setCaret } from '../../utils/caret'

const Root = styled.div`
  display: inline-block;
  position: relative;
  z-index: 10;
  padding: ${props => props.contentEditable ? '0 0.2em' : 0};
  white-space: nowrap;
  background: ${props => props.contentEditable ? 'white' : 'transparent' };
  min-width: 60px;
  cursor: ${props => props.contentEditable ? 'text' : 'pointer'};

  -webkit-user-select: text;
  user-select: text;
`

class ContentEditable extends React.Component  {
  contentInput = null

  static propTypes = {
    value: PropTypes.string.isRequired,
    onUpdate: PropTypes.func,
    onEdit: PropTypes.func
  }

  static defaultProps = {
    onUpdate: undefined,
    onEdit: undefined,
  }

  constructor (props) {
    super(props)
    this.state = {
      edit: props.edit,
      value: props.value || ''
    }
  }

  componentDidMount () {
    if (this.props.edit) {
      this.onEdit()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { edit } = this.props

    if (edit && !prevProps.edit) {
      this.onEdit()
    }
  }

  onEdit = () => {
    this.contentInput.focus()
    const { value } = this.props
    this.setState({ value }, () => {
      this.contentInput.innerText = value
      if (value) {
        setCaret(this.contentInput, value ? value.length : 0)
      } else if (this.contentInput) {
        this.contentInput.focus()
      }
      this.contentInput.onblur = () => {
        this.onUpdate()
      }
    })
  }

  onUpdate = (e) => {
    const { value } = this.state
    const { onUpdate } = this.props
    if (this.contentInput) {
      this.contentInput.onblur = () => {}
      onUpdate(value || this.props.value)
    }
  }

  onCancel = () => {
    const { value } = this.props
    if (this.contentInput) {
      this.contentInput.onblur = null
    }
    this.setState({ value })
    this.props.onCancel()
  }

  disableBubbling = (e) => {
    if (this.props.edit) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  updateContent (e) {
    switch (e.key) {
      case 'Escape':
        return this.onCancel()
      case 'Enter':
        return this.onUpdate()
      default:
        return this.setState({ value: e.target.innerText })
    }
  }

  render() {
    const { value } = this.state
    return <Root
      ref={node => this.contentInput = node}
      spellCheck={false}
      suppressContentEditableWarning
      onClick={this.disableBubbling}
      onMouseOver={this.disableBubbling}
      onKeyDown={this.updateContent.bind(this)}
      onInput={this.updateContent.bind(this)}
      contentEditable={this.props.edit}>{this.props.edit ? null : value}</Root>
  }
}

export default ContentEditable
