import React, { useContext } from 'react'
import { DetailsContext } from '../DetailsContext'
import ProfileLayout from '../../Profile/ProfileLayout'
import Contacts from '../../Contacts'
import ProfileAside from '../ProfileView/ProfileAside'

interface ContactsViewProps {
  onSetViewState: Function
  onQuickActions?: () => void
}

export default function ContactsView(props: ContactsViewProps) {
  const { loading, item, contacts, onUpdateItem } = useContext(DetailsContext)

  const onRequest = () => {
    props.onQuickActions(item.id, 'toRequestContacts')
    onUpdateItem('toRequestContacts')
  }

  const { status, contactInfos, entityDetails: entity } = contacts

  return (
    <ProfileLayout aside={<ProfileAside {...props} />}>
      <Contacts
        loading={loading}
        status={status}
        entity={entity}
        contacts={contactInfos || []}
        onRequest={onRequest}
      />
    </ProfileLayout>
  )
}
