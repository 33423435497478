import React from 'react'
import styled, {css} from 'styled-components'
import {TableCell} from '@material-ui/core'

const StickyTableCell = styled(TableCell)`
  position: ${props => props.disabled ? '' : 'sticky'};
  right: -1em;
  z-index: 1;
  ${props => props.disabled ? css`
    background: transparent;`
    : css`
    background: linear-gradient(
      ${props => props.variant === 'left' ? '90deg' : '-90deg'},
      white 80%,
      rgba(255, 255, 255, 0) 100%);
    `
    }
`

export default StickyTableCell