import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import update from 'immutability-helper'
import { FolderPlus } from 'react-feather'
import { Button as MuiButton, List } from '@material-ui/core'

import FolderListItem from './FolderListItem'
import { SkeletonPlaceholder } from '../../components/SkeletonPlaceholder'

const Root = styled.div`
  //margin: 1em 0;
  color: #222222;
`

const EmptyRoot = styled.div`
  padding: 0;
  margin: 0.8em 1em 0.8em 3em;
  font-size: 14px;
`

const Loading = () => (
  <div style={{margin: '0.8em 1em 0.8em 3em'}}>
    <SkeletonPlaceholder width={'30%'} height={'14px'} color={'#c1c8d6'} highlightColor={'#e6efff'}/>
    <SkeletonPlaceholder width={'100%'} height={'14px'} color={'#c1c8d6'} highlightColor={'#e6efff'}/>
    <SkeletonPlaceholder width={'50%'} height={'14px'} color={'#c1c8d6'} highlightColor={'#e6efff'}/>
  </div>
)

const Placeholder = ({ onAddFolder }) => (
  <EmptyRoot>
    <p style={{opacity: 0.8}}>Group relevant leads in folders to keep them organised</p>
    <MuiButton startIcon={<FolderPlus size={14} />} size={'small'} color={'primary'}onClick={onAddFolder} >Add folder</MuiButton>
  </EmptyRoot>
)

const FolderList = (props) => {
  const {
    path, pathname, loading
  } = props

  const [folders, setFolders] = useState(props.folders)
  const [isDragStarted, setDragStarted] = useState(false)

  const onReorder = useCallback((dragIndex, hoverIndex) => {
    const dragFolder = folders[dragIndex]
    setFolders(update(folders, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragFolder],
      ],
    }))
  }, [folders])

  const onStart = () => {
    setDragStarted(true)
  }

  const onEnd = () => {
    setDragStarted(false)
    props.onReorder(folders)
  }

  return (
    <Root>
      { loading && <Loading /> }
      {(!loading && !folders.length) && <Placeholder onAddFolder={props.onAddFolder}/>}
      {
        !loading && (
          <List
            disablePadding
            component="nav"
          >
            {
              folders.map(({ id, name, recordsCount }, index) => (
                <FolderListItem
                  key={id}
                  id={id}
                  index={index}
                  title={name}
                  amount={recordsCount}
                  path={`${path}/folder/${id}`}
                  pathname={pathname}
                  onUpdate={props.onUpdate}
                  onRemove={props.onRemove}
                  onCancel={props.onCancel}

                  isDragStarted={isDragStarted}
                  onStart={onStart}
                  onEnd={onEnd}
                  onReorder={onReorder}
                />
              ))
            }
          </List>
        )
      }
    </Root>
  )
}

export default FolderList
