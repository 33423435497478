import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react'
import { findIndex } from 'lodash'
import {
  DataGridPremium,
  useGridApiRef,
  LicenseInfo,
  GridOverlay,
} from '@mui/x-data-grid-premium'
import { makeStyles } from '@material-ui/styles'
import {
  LinearProgress as MuiLinearProgress,
  Popper,
  Tooltip,
} from '@material-ui/core'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import styled, { css } from 'styled-components'

import Title from '../../components/Leads/Title'
import { MozLogoIcon } from '../../components/Icons/Moz_logo'
import { Clock } from 'react-feather'

import QuickActions from '../QuickActions'
import { ContactStatus } from '../../components/ContactStatus/ContactStatus'
import { FolderLabel } from '../Folder/FolderLabel'
import TagList from '../Tags/TagList'
import { SystemTags } from '../Tags/SystemTags'
import LeadsPlaceholder from '../../components/LeadsPlaceholder'
import ExpiredSearchBanner from '../../new-components/ExpiredSearchBanner'
import PaywallBanner from '../../new-components/PaywallBanner/PaywallBanner'
import { onDragEnd, onDragStart } from '../../utils/dnd'
import chroma from 'chroma-js'
import {
  formatDate,
  formatNumber,
  formatDateShort,
  formatToReadableNumber,
  formatDateFull,
} from '../../utils/format'
import config from '../../config'
import { linearGradient } from 'polished'
import { SimilarWebIcon } from '../../components/Icons/SimilarWebIcon'
import { TypeLabel } from '../TypeLabel/TypeLabel'

import ListContext from './ListContext'
import IconLink from '../../components-ts/IconLink'
import TooltipMetrics from '../../components-ts/TooltipMetrics'
import { Base as TooltipMetricsBase } from '../../components-ts/TooltipMetrics/TooltipMetricsBase'

import metrics from '../../configMetrics'
import SelectAll from './SelectAll'

LicenseInfo.setLicenseKey(config.DATA_GRID_LICENSE_KEY)

const getColorStep = (value, steps, max) => {
  return (Math.ceil(value / (max / steps)) * (max / steps)) / 100
}

const POPPER_PROPS = {
  eventsEnabled: false,
  disableEventListeners: true,
  modifiers: {
    offset: {
      offset: '0, -5px',
    },
    flip: {
      enabled: false,
    },
    preventOverflow: {
      padding: -35,
      boundariesElement: 'scrollParent',
      escapeWithReference: false,
    },
  },
}

const getScoreColors = chroma.scale(config.SCORE_COLORS)

export const Domain = styled(IconLink)`
  font-weight: bold;
  margin-right: 0.5em;
`

export const Container = styled.div`
  width: 100%;
  padding: 0.5em 1em 0;
  height: calc(100vh - 116px);
`

export const Body = styled.div`
  flex: 1 1 15em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding: 0.2em 1em 0.2em 0;
  overflow: hidden;
  height: 100%;
  ${(props) =>
    props.disable &&
    css`
      pointer-events: none;
    `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding: 0.2em 1em 0.2em 0;
  position: relative;
  flex: 1 1 auto;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  line-height: normal;
  //justify-content: flex-start;
`

const Tags = styled.div`
  display: flex;
  column-gap: 0.3em;
  align-items: center;
  padding: 0.3em 0;
`

export const MozLogo = styled(MozLogoIcon)`
  width: 28px;
  height: 12px;
  color: ${(props) => props.color || 'black'};
  position: relative;
  top: 2px;
`

export const SwIcon = styled.span`
  position: relative;
  top: 1px;
  display: inline-flex;
  align-items: center;
`

export const Root = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  margin: 0 1em 0 0;
  display: flex;
  align-items: center;
`

export const Result = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
    font-weight: normal;
  }
`

const Score = styled.div`
  background: ${(props) =>
    props.value === 0
      ? props.theme.palette.grey['200']
      : getScoreColors(
          getColorStep(props.value, config.SCORE_COLOR_STEPS, config.SCORE_MAX)
        ).hex()};
  border-radius: 5px;
  padding: 0.5rem;
  position: relative;
  line-height: normal;
  min-width: 40px;
  display: flex;
  justify-content: center;
`

const OutOfDateBase = styled.div`
  background: ${(props) => props.theme.palette.brand.historical.main};
  position: absolute;
  left: -10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 90%;
  top: 5%;
`

const OutOfDateLabel = styled.div`
  font-size: 8px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
`

const LeadLabel = styled.div`
  position: relative;
  z-index: 1;
  width: 10px;
  height: 100%;
  &:after {
    content: '';
    display: block;
    width: 5px;
    height: 100%;
    ${(props) =>
      props.variant === 'overlap' &&
      css`
        background: ${config.labelColors.overlap};
      `}
    ${(props) =>
      props.variant === 'prospect' &&
      css`
        background: ${config.labelColors.prospect};
      `}
    ${(props) =>
      props.variant === 'partner' &&
      css`
        background: ${config.labelColors.partner};
      `}
  }
`

const LabelContainer = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`

const ActionsHolder = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 10;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  svg {
    margin: 4px 2px;
  }
`

const ActionsBackground = styled.div`
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.1);
  background: white;
  display: flex;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.palette.brand.grey.main};
  overflow: hidden;
  pointer-events: all;
`

const TooltipContent = styled.div`
  a {
    opacity: 0.8;
    display: block;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0.3em 0;
    margin-top: 0.3em;
    text-decoration: none;
    color: white;
  }
`

const SelectionContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none!important',
    '& .locked': {
      pointerEvents: 'none',
    },
    '& .active-details': {
      background: theme.palette.brand.turquoise.light,
      transition: 'background .2s ease',
    },
    '& .active-drag': {
      opacity: 0.4,
    },
    '& .disabled': {
      pointerEvents: 'none',
      userSelect: 'none',
      background: theme.palette.brand.grey.light,
      '& .MuiCheckbox-root': {
        opacity: 0.5,
      },
    },
    '& .MuiDataGrid-row:focus, & .MuiDataGrid-row::focus-within': {
      outline: '1px solid blue!important',
    },
    '& .MuiDataGrid-row': {
      position: 'relative',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
      {
        outline: 'none!important',
      },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      fontSize: 12,
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      padding: '0 8px',
    },
    '& .MuiDataGrid-cell.MuiDataGrid-cell--textRight': {
      padding: '12px 8px',
    },
    '& .MuiDataGrid-columnHeader--numeric': {
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        marginLeft: 20,
        padding: 0,
      },
      '& .MuiDataGrid-iconButtonContainer': {
        left: 3,
        position: 'absolute',
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      '& .MuiSvgIcon-root': {
        width: '0.8em',
        height: '0.8em',
        opacity: '0.75',
        // color: ;
      },
    },
    '& .MuiDataGrid-cell.metrics': {
      alignItems: 'center',
      justifyContent: 'flex-end',
      display: 'flex',
      position: 'relative',
      // '&:first-of-type': {
      overflow: 'visible',
      // }
    },
    '& .MuiDataGrid-cell.muted': {
      // '&:before': {
      //   content: '""',
      //   display: 'block',
      //   position: 'absolute',
      //   width: '100%',
      //   height: '90%',
      //   top: '5%',
      //   zIndex: 0,
      backgroundImage: `linear-gradient(${theme.palette.brand.historical.main}, ${theme.palette.brand.historical.main})`,
      // background: theme.palette.brand.grey.main,
      backgroundSize: '100% 90%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      // }
    },
  },
}))

const OutOfDate = () => (
  <Tooltip
    title={
      <>
        <strong>Historical</strong>
        <br />
        Not returned by a current search.
      </>
    }
  >
    <OutOfDateBase>
      <OutOfDateLabel>
        <Clock size={14} color={'#363636'} />
      </OutOfDateLabel>
    </OutOfDateBase>
  </Tooltip>
)

function useDragLead(props) {
  const context = useContext(ListContext)
  const [dragRef, setDragRef] = useState(null)
  const { item } = props
  const { id, selected, itemActions } = item

  const getSelectedItems = () => (!selected.includes(id) ? [id] : selected)

  const [{ isDragging }, drag, preview] = useDrag({
    item: { ...item, type: 'lead', selected: getSelectedItems() },
    canDrag: !context.locked, //true,
    begin: () => {
      onDragStart()
      props.onSetDraggedRows(getSelectedItems())
    },
    end(item, monitor) {
      const dropResult = monitor.getDropResult()
      itemActions.onDragEnd({ ...dropResult, item })
      onDragEnd()
      props.onSetDraggedRows([])
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const setRef = (ref) => {
    setDragRef(ref)
    drag(ref)
  }

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [item, preview])

  return [dragRef, useCallback((ref) => setRef(ref), [])]
}

function renderTitle(params) {
  const ref = useRef(null)
  const context = useContext(ListContext)
  const [showQuickActions, setShowQuickActions] = useState(null)
  const { row: item } = params
  const { itemActions } = item
  const [dragRef, setRef] = useDragLead({
    item,
    ref,
    onSetDraggedRows: item.onSetDraggedRows,
  })

  const onToggleShowQuickActions = ({
    type: mouseEventType,
    currentTarget,
  }) => {
    if ('mouseleave' === mouseEventType) {
      setShowQuickActions(null)
    } else if ('mouseenter' === mouseEventType) {
      setShowQuickActions(currentTarget)
    }
  }

  const onQuickActions = (id, action, direction) => {
    if (context.locked) {
      if (context.lockedKind === 'expired') {
        context.onOpenExpiredModal()
      }
    } else {
      itemActions.onQuickAction(
        id,
        action,
        action === 'toManageTags' ? { target: ref.current } : direction
      )
    }
  }

  useEffect(() => {
    if (ref && !dragRef) {
      setRef(ref)
    }
  }, [ref])

  const additionalTagsCount =
    (Boolean(item.contactStatus) ? 1 : 0) + (Boolean(item.folderName) ? 1 : 0)

  return (
    <Body
      ref={ref}
      onMouseEnter={onToggleShowQuickActions}
      onMouseLeave={onToggleShowQuickActions}
    >
      <LabelContainer>
        {Boolean(item.partner) && (
          <Tooltip
            title={"Existing partner or a partner you've found with Breezy."}
          >
            <LeadLabel variant={'partner'} />
          </Tooltip>
        )}
        {Boolean(item.relevant) && (
          <Tooltip title={'Relevant to the current Search'}>
            <LeadLabel variant={'prospect'} />
          </Tooltip>
        )}
        {Boolean(item.overlapProspect) && (
          <Tooltip title={'Relevant to another Search.'}>
            <LeadLabel variant={'overlap'} />
          </Tooltip>
        )}
      </LabelContainer>

      {!item.fake && !item.obfuscate && (
        <Popper
          id={`item-qa-${item.id}`}
          placement={'top-start'}
          popperOptions={POPPER_PROPS}
          open={Boolean(showQuickActions)}
          anchorEl={showQuickActions}
        >
          <ActionsHolder>
            <ActionsBackground>
              <QuickActions
                id={item.id}
                view={item.view}
                kind={item.source}
                partner={item.partner}
                prospect={item.relevant}
                onAction={onQuickActions}
                floating
                contactStatus={item.contactStatus}
                {...item.quickActionsProps}
              />
            </ActionsBackground>
          </ActionsHolder>
        </Popper>
      )}

      <Content>
        <Header>
          <Domain
            icon={item.icon}
            domain={item.url}
            obfuscate={item.obfuscate}
          />
          {/*<Icon item={item.obfuscate ? {icon: 'missing'} : item}/>*/}
          {/*<Url active obfuscate={item.obfuscate}>{item.url}</Url>*/}
          <Title obfuscate={item.obfuscate}>{item.title}</Title>
        </Header>
        {!item.obfuscate &&
          (Boolean(item.tags.length > 0) ||
            Boolean(item.systemTags && item.systemTags.length) ||
            Boolean(item.contactStatus) ||
            Boolean(item.folderName)) && (
            <Tags>
              <TagList
                locked={context.locked}
                variant={'item'}
                tags={item.tags}
                maxTags={Boolean(item.contactStatus) ? 2 : 3}
                additionalTagsCount={additionalTagsCount}
                onUpdate={(tag) => itemActions.onUpdateTag(tag, item.id)}
                onRemove={(id) => itemActions.onRemoveTag(id, item.id)}
                onDelete={(id) => itemActions.onDeleteTag(id, item.id)}
                onManage={(e) => itemActions.onManageTags(e, item.id)}
              >
                {Boolean(item.contactStatus) && (
                  <ContactStatus status={item.contactStatus} />
                )}
                {Boolean(item.folderName) && (
                  <FolderLabel name={item.folderName} />
                )}
                {Boolean(item.systemTags && item.systemTags.length) && (
                  <SystemTags
                    variant={'manual'}
                    data={item.systemTags}
                    limit={2}
                  />
                )}
              </TagList>
            </Tags>
          )}
      </Content>
    </Body>
  )
}

function renderScore(params) {
  const {
    field,
    row: { source, outOfDate, obfuscate },
  } = params

  const showOutOfDate = outOfDate && !obfuscate

  if (field === 'mozDomainAuthority') {
    return (
      <>
        {showOutOfDate && source === 'competitors' && <OutOfDate />}
        {params.value === 0 ? 'N/A' : params.value}
      </>
    )
  }

  return (
    <>
      {showOutOfDate && <OutOfDate />}
      <Score value={params.value}>
        {params.value === 0 ? 'N/A' : params.value}
      </Score>
    </>
  )
}

const HeadTitle = (props) => {
  const context = useContext(ListContext)
  const {
    source,
    total,
    selected,
    locked,
    lockedKind,
    quickActionProps,
    onSelect,
    onGetAllIds,
  } = context
  const onActions = (id, action, direction) => {
    if (locked) {
      if (lockedKind === 'expired') {
        context.onOpenExpiredModal()
      }
    } else {
      quickActionProps.onAction(id, action, direction)
    }
  }

  const showTotal = selected.length === 0

  const onSelectAll = async () => {
    const ids = await onGetAllIds()
    onSelect(ids)
  }

  return (
    <div style={{ display: 'flex' }}>
      {showTotal && (
        <>
          <Root>
            <Result>
              <strong>{total} results</strong>
            </Result>
          </Root>
          &nbsp;&nbsp;
        </>
      )}

      <QuickActions header {...quickActionProps} onAction={onActions} />
      {!showTotal && (
        <>
          &nbsp;&nbsp;
          <SelectionContainer>
            <SelectAll
              selected={selected}
              total={total}
              source={source}
              onSelect={onSelectAll}
            />
          </SelectionContainer>
        </>
      )}
    </div>
  )
}

const Heading = styled.div`
  display: flex;
  align-items: center;
`

const LinearProgress = styled(MuiLinearProgress)`
  background: rgba(1, 99, 250, 0.2);
  .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.theme.palette.brand.blue.main};
    border-radius: 3px;
  }
`

export const LoadingOverlay = () => {
  return <LinearProgress />
}

const NoRowsOverlay = () => {
  return (
    <GridOverlay>
      <LeadsPlaceholder state={'emptySearch'} />
    </GridOverlay>
  )
}

const meta = {
  breezyScore: {
    headerName: metrics['score'].heading,
    description: (
      <TooltipMetricsBase
        title={metrics['score'].title}
        content={metrics['score'].content}
      />
    ),
    width: 72,
    renderCell: (params) => renderScore(params),
  },
  avgBreezyScore: {
    headerName: metrics['avgScore'].heading,
    description: (
      <TooltipMetricsBase
        title={metrics['avgScore'].title}
        content={metrics['avgScore'].content}
      />
    ),
    width: 100,
    renderCell: (params) => renderScore(params),
  },
  mozDomainAuthority: {
    headerName: <Heading>{metrics['moz'].heading}</Heading>,
    description: (
      <TooltipMetricsBase
        title={metrics['moz'].title}
        content={metrics['moz'].content}
        poweredBy={metrics['moz'].poweredBy}
      />
    ),
    width: 70,
    renderCell: (params) => renderScore(params),
  },
  swRank: {
    headerName: <Heading>{metrics['rank'].heading}</Heading>,
    description: (
      <TooltipMetricsBase
        title={metrics['rank'].title}
        content={metrics['rank'].content}
        poweredBy={metrics['rank'].poweredBy}
      />
    ),
    width: 82,
    renderCell: (params) => {
      return params.value !== 'N/A'
        ? formatToReadableNumber(params.value)
        : params.value
    },
  },
  swVisits: {
    headerName: <Heading>{metrics['visits'].heading}</Heading>,
    description: (
      <TooltipMetricsBase
        title={metrics['visits'].title}
        content={metrics['visits'].content}
        poweredBy={metrics['visits'].poweredBy}
      />
    ),
    width: 85,
    renderCell: (params) => {
      const value = Boolean(params.value) ? Math.round(params.value) : null
      return Boolean(value) ? formatToReadableNumber(value) : 'N/A'
    },
  },
  keywordSeedsCount: {
    headerName: metrics['keywords'].heading,
    description: (
      <TooltipMetricsBase
        title={metrics['keywords'].title}
        content={metrics['keywords'].content}
      />
    ),
    width: 95,
  },
  totalKeywords: {
    headerName: metrics['keywordsTotal'].heading,
    description: (
      <TooltipMetricsBase
        title={metrics['keywordsTotal'].title}
        content={metrics['keywordsTotal'].content}
      />
    ),
    width: 95,
  },
  competitorSeedsCount: {
    headerName: metrics['backlinks'].heading,
    description: (
      <TooltipMetricsBase
        title={metrics['backlinks'].title}
        content={metrics['backlinks'].content}
      />
    ),
    width: 85,
  },
  totalCompetitors: {
    headerName: metrics['backlinksTotal'].heading,
    description: (
      <TooltipMetricsBase
        title={metrics['backlinksTotal'].title}
        content={metrics['backlinksTotal'].content}
      />
    ),
    width: 85,
  },
  partnershipsOutCount: {
    headerName: metrics['promotes'].heading,
    description: (
      <TooltipMetricsBase
        title={metrics['promotes'].title}
        content={metrics['promotes'].content}
      />
    ),
    width: 95,
    renderCell: (params) => {
      const value = Boolean(params.value) ? Math.round(params.value) : null
      return Boolean(value) ? formatToReadableNumber(value) : 'N/A'
    },
  },
  added: {
    headerName: 'Added',
    description: (
      <TooltipMetricsBase
        title={metrics['added'].title}
        content={metrics['added'].content}
      />
    ),
    renderCell: (params) => {
      return formatDateFull(params.row.createdAt)
    },
    width: 110,
  },
}

const columnsPartner = [
  'avgBreezyScore',
  'mozDomainAuthority',
  'swRank',
  'swVisits',
  'partnershipsOutCount',
  'totalKeywords',
  'totalCompetitors',
  'added',
]
const columnsLead = [
  'breezyScore',
  'mozDomainAuthority',
  'swRank',
  'swVisits',
  'partnershipsOutCount',
  'keywordSeedsCount',
  'competitorSeedsCount',
]
const columnsCompetitors = [
  'mozDomainAuthority',
  'swRank',
  'swVisits',
  'partnershipsOutCount',
  'keywordSeedsCount',
]

const keyPressListener = (element, eventName, callback) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, callback, false)
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, callback)
  } else {
    element['on' + eventName] = callback
  }
}

const getViewColumns = (source) => {
  let columns = columnsPartner

  if (['report', 'irrelevant'].includes(source)) {
    columns = columnsLead
  } else if (source === 'competitors') {
    columns = columnsCompetitors
  }

  return columns.map((key) => ({
    field: key,
    ...meta[key],
    editable: true,
    sortable: true,
    type: 'number',
    width: /* columnWidth[key] || */ meta[key].width,
  }))
}

function ListTable(props) {
  const {
    kind,
    source,
    locked,
    lockedThreshold,
    items,
    selected,
    thinking,
    total,
    sortProps,
    quickActionProps,
    outOfDate /* page, perPage, */,
  } = props
  const apiRef = useGridApiRef()
  const classes = useStyles()
  const [openPaywallModal, setOpenPaywallModal] = useState(false)
  const [openExpiredModal, setOpenExpiredModal] = useState(false)
  const [columnWidth, setColumnWidth] = useState({})
  const [keyCode, setKeyCode] = useState(null)
  const [loading, setLoading] = useState(false)
  const [draggedRows, setDraggedRows] = useState([])
  const [selectedColumns, setSelectedColumns] = useState([
    {
      field: 'title',
      renderHeader: () => {
        const context = useContext(ListContext)
        return (
          <HeadTitle
            total={context.total}
            quickActionProps={context.quickActionProps}
          />
        )
      },
      editable: false,
      sortable: false,
      disableReorder: true,
      minWidth: 400,
      renderCell: (params) => renderTitle(params),
      flex: 1,
    },
    ...getViewColumns(source),
  ])
  const sortModel = { sort: sortProps.order, field: sortProps.orderBy }

  const onSort = (model) => {
    if (!loading) {
      if (model && model[0]) {
        const { sort: direction, field } = model[0]
        props.onSort(field, direction)
      }
    }
  }

  const onSelect = (newSelected) => {
    if (selected.length === total && newSelected.length === items.length) {
      return false
    } else if (keyCode === 16 && selected.length) {
      const newItemId = newSelected.filter((id) => !selected.includes(id))[0]
      const newItemIndex = findIndex(items, ['id', newItemId])
      const lastSelectedIndex = findIndex(items, [
        'id',
        selected[selected.length - 1],
      ])

      const add = newItemIndex > lastSelectedIndex ? 1 : -1
      let index = lastSelectedIndex
      do {
        if (!newSelected.includes(items[index].id)) {
          newSelected.push(items[index].id)
        }
        index += add
      } while (index !== newItemIndex)
      props.onSelect(newSelected)
    } else {
      props.onSelect(newSelected)
    }
  }

  useEffect(() => {
    if (loading !== props.loading) {
      setLoading(props.loading)
      if (loading) {
        apiRef.current.forceUpdate()
      }
    }
  }, [total, /*selected,*/ quickActionProps.prospect, props.loading])

  useEffect(() => {
    keyPressListener(
      window,
      'keydown',
      (e) => e.keyCode === 16 && setKeyCode(e.keyCode)
    )
    keyPressListener(window, 'keyup', (e) => setKeyCode(null))
  }, [])

  return (
    <ListContext.Provider
      value={{
        total,
        quickActionProps,
        selected,
        source,
        locked: props.locked,
        lockedKind: props.lockedKind,
        onSelect: props.onSelect,
        onGetAllIds: props.onGetAllIds,
        openExpiredModal,
        onOpenExpiredModal: () => setOpenExpiredModal(true),
        onCloseExpiredModal: () => setOpenExpiredModal(false),
        // openPaywallModal, onOpenPaywallModal: () => setOpenPaywallModal(true), onClosePaywallModal: () => setOpenPaywallModal(false),
        ...props.expiredProps,
      }}
    >
      <Container>
        <DataGridPremium
          hideFooter={!locked}
          apiRef={apiRef}
          columnBuffer={30}
          checkboxSelection
          disableSelectionOnClick
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnReorder={false}
          sortingMode='server'
          paginationMode='server'
          classes={classes}
          loading={loading || thinking}
          rowHeight={config.ROW_HEIGHT}
          rows={items.map((row, index) => ({
            ...row,
            source,
            obfuscate: locked && index > lockedThreshold - 1,
            onSetDraggedRows: (ids) => setDraggedRows(ids),
          }))}
          columns={selectedColumns}
          sortingOrder={['desc', 'asc']}
          sortModel={[sortModel]}
          onSortModelChange={onSort}
          onRowClick={(item) => {
            if (props.locked) {
              if (props.lockedKind === 'expired') {
                setOpenExpiredModal(true)
              }
            } else if (!item.row.obfuscate /* (item.id, '') */) {
              props.onClick(item.id)
            }
          }}
          // onColumnWidthChange={onColumnWidthChange}
          getCellClassName={(params) => {
            let classNames = []
            if (params.colDef.type === 'number') classNames.push('metrics')
            if (
              params.colDef.type === 'number' &&
              params.row.outOfDate &&
              !params.row.obfuscate
            )
              classNames.push('muted')
            return classNames.join(' ')
          }}
          getRowClassName={(params) => {
            let classNames = []
            const itemId = params.row.id //params.getValue(params.id, 'id')
            const activeItem = params.row.activeItem //params.getValue(params.id, 'activeItem')
            const obfuscatedItem = params.row.obfuscate //params.getValue(params.id, 'obfuscate')

            // if (locked && props.lockedKind === 'expired') {
            //   classNames.push('locked')
            // }

            if (itemId === activeItem) {
              classNames.push('active-details')
            }
            if (draggedRows.includes(itemId)) {
              classNames.push('active-drag')
            }
            if (obfuscatedItem) {
              classNames.push('disabled')
            }

            return classNames.join(' ')
          }}
          slots={{
            footer:
              props.lockedKind === 'paywall'
                ? PaywallBanner
                : ExpiredSearchBanner,
            loadingOverlay: LoadingOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          scrollEndThreshold={50}
          onRowsScrollEnd={(a, b) => {
            props.onPageChange()
          }}
          rowSelectionModel={selected}
          onRowSelectionModelChange={onSelect}
        />
      </Container>
    </ListContext.Provider>
  )
}

export default ListTable
