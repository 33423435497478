const LEADS_HEADER = [
  'Channel',
  'Title',
  'Score',
  'MOZ',
  'Similarweb Rank',
  'Similarweb Visits',
  'Promotes',
  'Keywords',
  'Competitors',
  'Contacts',
  'Tags',
]
const LEADS_FIELDS = [
  'url',
  'title',
  'breezyScore',
  'mozDomainAuthority',
  'swRank',
  'swVisits',
  'partnershipsOutCount',
  'keywordSeedsCount',
  'competitorSeedsCount',
  'contactStatus',
  'tags',
]

const PROSPECT_HEADER = [
  'Channel',
  'Title',
  'Avg. Score',
  'MOZ',
  'Similarweb Rank',
  'Similarweb Visits',
  'Promotes',
  'Keywords',
  'Competitors',
  'Folder',
  'Contacts',
  'Tags',
]
const PROSPECT_FIELDS = [
  'url',
  'title',
  'avgBreezyScore',
  'mozDomainAuthority',
  'swRank',
  'swVisits',
  'partnershipsOutCount',
  'totalKeywords',
  'totalCompetitors',
  'folderName',
  'contactStatus',
  'tags',
]

const CONTACT_HEADER = [
  'First Name',
  'Last Name',
  'Channel',
  'Company',
  'Job Title',
  'LinkedIn',
  'Facebook',
  'X',
  'Instagram',
  'Personal Email',
  'Corporate Email',
  'Phone',
  'Tags',
  'Folder',
  'Requester',
  'Requested',
  'Found',
]
const CONTACT_FIELDS = [
  'firstName',
  'lastName',
  'domain',
  'company',
  'jobTitle',
  'linkedin',
  'facebook',
  'twitter',
  'instagram',
  'emails',
  'companyEmails',
  'phones',
  'tags',
  'folderName',
  'user',
  'createdAtShort',
  'updatedAtShort',
]

const COMPETITORS_LANDSCAPE_HEADER = [
  'Channel',
  'Title',
  'MOZ',
  'Similarweb Rank',
  'Similarweb Visits',
  'Promoted by',
  'Ecosystem Share',
  'Contacts',
  'Tags',
]
const COMPETITORS_LANDSCAPE_FIELDS = [
  'domain',
  'title',
  'mozDomainAuthority',
  'swGlobalRank',
  'swVisits',
  'partnershipsInCount',
  'share',
  'contactStatus',
  'tags',
]

const GAP_ANALYSIS_HEADER = [
  'Channel',
  'Title',
  'MOZ',
  'Similarweb Rank',
  'Similarweb Visits',
  'Promotes',
  'Overlap',
  'Contacts',
  'Tags',
]
const GAP_ANALYSIS_FIELDS = [
  'domain',
  'title',
  'mozDomainAuthority',
  'swGlobalRank',
  'swVisits',
  'partnershipsOutCount',
  'overlap',
  'contactStatus',
  'tags',
]

const REQUEST_HEADER = ['Channel', 'Tags', 'Requester', 'Status', 'Requested']
const REQUEST_FIELDS = ['domain', 'tags', 'user', 'status', 'createdAtShort']

const STRING_FIELDS = ['title', 'jobTitle']
const MULTIPLE_FIELDS = ['emails', 'companyEmails', 'phones']
const SOCIAL_FIELDS = ['linkedin', 'facebook', 'twitter', 'instagram']

const CLEAR_STRING_FIELDS = [
  'firstName',
  'lastName',
  'company',
  'role',
  'folder',
  'jobTitle',
  'company',
]

const getHeader = (kind) => {
  switch (kind) {
    case 'leads':
      return LEADS_HEADER
    case 'prospects':
      return PROSPECT_HEADER
    case 'contacts':
      return CONTACT_HEADER
    case 'requests':
      return REQUEST_HEADER
    case 'competitors-landscape':
      return COMPETITORS_LANDSCAPE_HEADER
    case 'gap-analysis':
      return GAP_ANALYSIS_HEADER
    default:
      return null
  }
}
const getFields = (kind) => {
  switch (kind) {
    case 'leads':
      return LEADS_FIELDS
    case 'prospects':
      return PROSPECT_FIELDS
    case 'contacts':
      return CONTACT_FIELDS
    case 'requests':
      return REQUEST_FIELDS
    case 'competitors-landscape':
      return COMPETITORS_LANDSCAPE_FIELDS
    case 'gap-analysis':
      return GAP_ANALYSIS_FIELDS
    default:
      return null
  }
}

const clearString = (str) => {
  if (!str) return str

  return str
    .replace(/\s{2,}/g, ' ')
    .replace(/\t/g, ' ')
    .trim()
    .replace(/(\r\n|\n|\r)/g, '')
}

const getTags = (value = []) => {
  return `"${value.map(({ name, label }) => name || label).join(',')}"`
}

const getMultiple = (value = []) => {
  return `"${value.join(',')}"`
}

export const createCSV = (data, kind) => {
  const header = getHeader(kind).map((i) => `"${i}"`)
  const fields = getFields(kind)

  let result = data.map((row) => {
    return fields
      .map((field) => {
        const value = CLEAR_STRING_FIELDS.includes(field)
          ? clearString(row[field])
          : row[field]

        if (field === 'tags') {
          return getTags(value)
        } else if (STRING_FIELDS.includes(field)) {
          return `"${value}"`
        } else if (MULTIPLE_FIELDS.includes(field)) {
          return getMultiple(value)
        }

        return value || ''
      })
      .join(',')
  })

  return [header.join(','), ...result].join('\n').replace(/\\/g, '')
}

export const download = (data, file) => {
  const blob = new Blob([data])
  const blobUrl = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = blobUrl
  link.download = file

  document.body.appendChild(link)
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  )
  document.body.removeChild(link)
}
