import React from 'react'
import styled from 'styled-components'
import { find, findIndex } from 'lodash'
import {
  Paper,
  TextField,
  ListItem as MuiListItem,
  Divider as MuiDivider,
  CircularProgress
} from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/core/styles'
import TagList from '../../new-components/Tags/TagList'
import TagManagementItem from './TagManagementItem'
import { findHints } from './hints'
import config from '../../config'

const Divider = styled.div`
  //margin-top: 16px;
  position: relative;
  flex-shrink: 0;
  display: flex;
  white-space: nowrap;
  text-align: center;
  border: 0px;
  width: 100%;
  &:before {
    position: relative;
    width: 10%;
    border-top: thin solid rgba(0, 0, 0, 0.12);
    top: 50%;
    content: "";
    transform: translateY(50%);
  }
  &:after {
    position: relative;
    width: 90%;
    border-top: thin solid rgba(0, 0, 0, 0.12);
    top: 50%;
    content: "";
    transform: translateY(50%);
  }
  span {
    //color: #aaa;
    font-weight: 400;
    display: inline-block;
    padding-left: calc(9.6px);
    padding-right: calc(9.6px);
  }
`

const Header = styled.div`
  padding: 1em;
  border-bottom: 1px solid ${props => props.theme.palette.brand.grey.main};
`

const Autocomplete = withStyles((theme) => ({
  option: {
    padding: 0,
    fontSize: '0.875rem'
  },
  listbox: {
    height: 300,
    position: 'relative'
  }
}))(MuiAutocomplete)


// height: 300px;
// position: relative;

// (List)
export const ListContainer = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
`

const Root = styled(Paper)`
  width: 400px;
  box-shadow: none;
`

const Input = styled(TextField)`
  padding: 1em 1em 0;
  display: block;
  //& > div {
  //  width: 100%;
  //}
`

const Message = styled.div`
  padding: 1em;
  text-align: center;
`

const Hint = styled.div`
  font-style: oblique;
  cursor: pointer;
`

const ListItem = styled(MuiListItem)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const AutocompletePopperComponent = styled.div`
  background-color: #fff;
  width: 100%!important;
`

class TagManagement extends React.Component {
  searchInput = null

  static defaultProps = {
    thinking: false,
    tags: [],
    itemTags: [],
    onFilter: console.log,
    onSelectTag: console.log
  }

  state = { value: null, search: '', navigation: false }

  componentDidMount () {
    this.props.onFilter()
  }

  onClearSearch = () => {
    this.setState({ search: '' })
    if (this.searchInput) {
      setTimeout(() => {
        this.searchInput.value = ''
        this.searchInput.focus()
      }, 100)
    }
    this.props.onFilter('')
  }

  onCreate = async (details) => {
    if (this.props.variant === 'filter') return false

    const { tags } = this.props

    const existingTag = find(tags, ['label', details.label])

    if (existingTag) {
      return this.onSelect(existingTag)
    }

    const { success, tag } = await this.props.onCreateTag(details)

    if (success) {
      this.props.onSelectTag(tag)
      this.onClearSearch()
    }
    return { success, tag }
  }

  onSelect = (tag) => {
    this.onClearSearch()
    this.props.onSelectTag(tag)
  }

  onEnter = async (value) => {
    const brandTags = this.filteredBrandTags.map(({ id, label }) => ({ id, label: label.toLowerCase()}))
    const tagIndex = findIndex(brandTags, ['label', value.toLowerCase()])
    if (tagIndex < 0) {
      await this.onCreate({ label: value })
    } else {
      this.onSelect(this.filteredBrandTags[tagIndex])
    }
  }

  onTyping = async ({ key }) => {
    if (key) {
      if (key === 'Escape') {
        this.onClearSearch()
      }
    }
  }

  get searchStarted () {
    const { search } = this.state
    return search && search.length > 0
  }

  get filteredLastTags () {
    const itemTags = this.props.itemTags || []
    const latestTags = this.props.latestTags || []

    if (this.searchStarted) return []

    return latestTags.filter(({ id }) => {
      return findIndex(itemTags, ['id', id]) < 0
    }).slice(0, config.LATEST_TAGS)
  }

  get filteredBrandTags () {
    const { search } = this.state
    const tags = this.props.tags || []
    const itemTags = this.props.itemTags || []

    return tags.filter(({ id, label }) => {
      const latestTagsIndex = findIndex(this.filteredLastTags, ['id', id])
      const itemTagsIndex = findIndex(itemTags, ['id', id])
      const isInSearch = search ? label.toLowerCase().includes(search.toLowerCase()) : true
      return latestTagsIndex < 0 && itemTagsIndex < 0 && isInSearch
    })
  }

  get filteredHints () {
    if (!this.searchStarted) return []

    const { search } = this.state
    const itemTags = this.props.itemTags || []
    const brandTags = this.props.tags || []

    const hints = findHints(search)
    return hints.filter(({ label }) => {
      const itemTagsIndex = findIndex(itemTags, ['label', label])
      const brandTagsIndex = findIndex(brandTags, ['label', label])
      return brandTagsIndex < 0 && itemTagsIndex < 0
    })
  }

  render () {
    const {
      variant,
      thinking,
      itemTags
    } = this.props

    const latestTags = this.filteredLastTags
    const brandTags = this.filteredBrandTags
    const hintTags = this.filteredHints

    const noTagsFound = this.searchStarted && !brandTags.length && !hintTags.length
    const hasLatestTags = latestTags.length > 0

    const allTags = [
      ...(hasLatestTags ? [{ id: 'recentStart', disabled: true }] : []),
      ...latestTags,
      ...(hasLatestTags ? [{ id: 'recentEnd', disabled: true }] : []),
      ...brandTags,
      ...hintTags
    ]

    return (
      <Root elevation={variant === 'filter' ? 0 : 5}>
        <Header>
          <TagList
            variant={variant}
            tags={itemTags}
            management={false}
            onSelect={this.props.onSelectTag}
            onUpdate={this.props.onUpdateTag}
            onRemove={this.props.onRemoveTag}
            onDelete={this.props.onDeleteTag}
          />
        </Header>

        <Autocomplete
          style={{ width: '100%' }}
          open
          freeSolo
          handleHomeEndKeys
          blurOnSelect
          clearOnBlur
          selectOnFocus
          options={[]}
          inputValue={this.state.search}
          // value={this.state.value}
          onInputChange={(event, value, action) => {
            if (action === 'input') {
              this.setState({ search: value })
              this.props.onFilter(value)
            } else if (action === 'clear') {
              this.onClearSearch()
            }
          }}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              this.onEnter(newValue)
            } else if (newValue && !newValue.id) {
              this.onCreate(newValue)
            } else {
              this.onSelect(newValue)
            }
          }}
          PaperComponent={ListContainer}
          PopperComponent={AutocompletePopperComponent}
          filterOptions={() => {
            return noTagsFound ? [{ id: 'empty', disabled: true }] : allTags
          }}
          getOptionDisabled={option => option.disabled}
          renderOption={(tag) => {
            if (tag.id === 'recentStart') {
              return (
                <Divider>
                  <span>Recent</span>
                </Divider>
              )
            }
            if (tag.id === 'recentEnd') {
              return (
                <MuiDivider style={{ width: '100%' }}/>
              )
            }

            if (!tag.id) {
              return <ListItem
                key={tag.label}
                button
                onClick={() => this.onCreate(tag)}
              >
                <Hint>
                  {tag.label}
                </Hint>
              </ListItem>
            }

            if (tag.id === 'empty') {
              return <Message>
                {
                  variant === 'filter' ? `Tag not found` : 'Press Enter to add new tag'
                }
              </Message>
            }

            return <TagManagementItem
              key={tag.id}
              id={tag.id}
              name={tag.label}
              latest={tag.latest}
              variant={variant}
              onClick={this.onSelect}
              onUpdate={this.props.onUpdateTag}
              onDelete={this.props.onDeleteTag}
            />
          }}
          renderInput={(params) => (
            <Input
              {...params}
              autoFocus
              inputRef={node => this.searchInput = node}
              variant={'outlined'}
              placeholder={'Search or create a tag'}
              onKeyDown={this.onTyping}
              onChange={this.onTyping}
              endAdornment={() => thinking ? <CircularProgress color="inherit" size={20} /> : null}
            />
          )}
        />
      </Root>
    )
  }
}

export default TagManagement
