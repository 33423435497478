import React from 'react'
import {
  Grid
} from '@material-ui/core'
import { Form, Input } from '../Form'
import Validator from '../../utils/validator'
import { FormsModal } from '../Forms/FormsModal'

const FORM_ID = 'brand-form'

const schema = [
  {
    field: 'name',
    rules: 'required',
    messages: {
      required: 'Name is required'
    }
  },
  // {
  //   field: 'link',
  //   rules: 'required|valid_url',
  //   messages: {
  //     required: 'Company website link is required',
  //     valid_url: 'Please enter valid URL'
  //   }
  // }
]

export default class Brand extends React.Component {
  static defaultProps = {
    categories: []
  }

  constructor(props) {
    super(props)
    const {name, link} = this.props
    this.state = {name, link, errors: {}, error: null}
  }

  componentDidMount () {
    if (this.props.initialValidation) {
      Validator(FORM_ID, schema,
        errors => this.setState({errors}),
        () => {}
      )
    }
  }

  onChange = name => ({target: {value}}) => {
    this.setState({[name]: value})
    this.resetError(name)
    if (this.props.onChange) {
      this.props.onChange({[name]: value})
    }
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    const {name, link} = this.state
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      () => {
        this.props.onSubmit({name, link})
      }
    )
  }

  onCancel = () => {
    const {name, link} = this.props
    this.setState({name, link, errors: {}, error: null})
  }

  render() {
    const {name, link, errors} = this.state
    const {
      thinking,
      error,
      stepCount,
      step,
      noText,
      fullWidth,
      createRef,
      disableChannel
    } = this.props

    return (
      <FormsModal
        noText={noText}
        fullWidth={fullWidth}
      >
        <Form
          noValidate
          step={step}
          stepCount={stepCount}
          formId={FORM_ID}
          error={error}
          thinking={thinking}
          createRef={createRef}
          onSubmit={this.onSubmit}
        >
          <Grid container>
            <Grid item xs={12}>
              <Input
                autoFocus
                required
                fullWidth
                margin="normal"
                placeholder={'e.g. Breezy'}
                id={'name'}
                label={'Name'}
                inputLabel={'What is your company’s brand name?'}
                variant={'outlined'}
                fieldType='clearable'
                value={name}
                disabled={thinking}
                error={errors.name}
                onChange={this.onChange('name')}
              />
            </Grid>
            {
              !disableChannel && (
                <Grid item xs={12}>
                  <Input
                    required
                    fullWidth
                    margin="normal"
                    placeholder={'e.g. example.com'}
                    id={'link'}
                    label={'Link'}
                    inputLabel={'What is your company website?'}
                    variant={'outlined'}
                    fieldType='clearable'
                    value={link}
                    disabled={thinking}
                    error={errors.link}
                    onChange={this.onChange('link')}
                  />
                </Grid>
              )
            }
          </Grid>
        </Form>

      </FormsModal>
    )
  }
}
