import React from 'react'
import { TipsPane } from '../../components/TipsText'
import CreditsInfo from '../../components/CreditsInfo'

import config from '../../config'

export const KeywordsTips = ({ variant }) => (
  <>
    <h2 style={{marginTop: 18}}>Add keywords your customers would use to find your Product (or similar Products.)</h2>
    <p>Eg. People looking for a tool like Breezy might search for terms like: <strong>discover partners, find affiliates, partner discovery.</strong></p>
    <p>Sites that rank for these keywords have a decent chance of being future partners.</p>
    <p>Remember to include location-specific keywords like city names or keywords in the language your customers are using.</p>
    <br/>
    <TipsPane>
      {/*Each keyword uses {config.credits.keyword} credit.*/}
      {
        variant === 'onboarding' && <div style={{marginTop: '0.5em'}}>
          <strong>Free Trial tip: </strong>
          We recommend entering 5-20 keywords to leave you enough credits to enter 2-3 competitors.
        </div>
      }
      {
        variant !== 'onboarding' && <div style={{marginTop: '0.5em'}}>
          <strong>Powerful Search Tip: </strong>
          More inputs means a more powerful research, and higher relevance and volume of Leads in your results. Some
          Brands add over 25 URLs and 500 keywords!
        </div>
      }
      <br/>
      {/*<CreditsInfo />*/}
    </TipsPane>
  </>
)

export const CompetitorsTips = ({ variant }) => (
  <>
    <h2 style={{marginTop: 18}}>Enter your competitors’URLs</h2>
    <p>We'll use the URLs you give us to uncover your competitors’ partnerships and identify lucrative lookalike opportunities. Ssssh!</p>
    <p>Try to include some large and well-known brands as they’re likely to have the most partners.</p>
    <p>Don’t have any obvious competitors? Enter the URLs of the brands that are closest to you. Can you think of any that sell similar products or services?</p>
    <br/>
    <TipsPane>
      {/*Each competitor uses {config.credits.competitor} credits.*/}
      {
        variant === 'onboarding' && <div style={{marginTop: '0.5em'}}>
          <strong>Free Trial tip: </strong>
          We recommend entering 2-3 competitors to strike the right balance!
        </div>
      }
      {
        variant !== 'onboarding' && <div style={{marginTop: '0.5em'}}>
          <strong>Powerful Search Tip: </strong>
          More inputs means a more powerful research, and higher relevance and volume of Leads in your results. Some
          Brands add over 25 URLs and 500 keywords!
        </div>
      }
      <br/>
      {/*<CreditsInfo />*/}
    </TipsPane>
  </>
)

export const BlocklistTips = ({ variant }) => (
  <>
    <h2 style={{marginTop: 18}}>Enter and Organise Irrelevant URLs</h2>
    <p>This is the list of the URLs that you marked as Irrelevant to your Search. By keeping them here you ensure that they will be automatically excluded from any future results.</p>
    <br/>
    <TipsPane>
      Irrelevant URL inputs don't require any credits, so you can add as many as as you want for no cost.
    </TipsPane>
  </>
)
