import React from 'react'
import { inject, observer } from 'mobx-react'
import { Route, Redirect } from 'react-router-dom'
import AuthLayout from '../../layouts/Auth'

@inject(({ UserStore }) => ({
  user: UserStore.user,
  brands: UserStore.brands,
  authorized: UserStore.authorized,
  checkAuthorization: UserStore.checkAuthorization
}))
@observer
export default class PublicRoute extends React.Component {
  /////// Temporary authorized redirect for single brand
  get redirectUrl () {
    const { user, brands, redirectToIfAuthorized } = this.props
    if (user && user.role === 'creator') {
      return '/creator/inbox'
    } else if (brands && brands.length) {
      return redirectToIfAuthorized.replace('{brand}', brands[0])
    }
    return redirectToIfAuthorized
  }

  render () {
    const {
      layout: Layout,
      title,
      component: Component,
      redirectToIfAuthorized,
      authorized,
      checkAuthorization
    } = this.props

    const layoutThinking = () => {
      if (authorized && redirectToIfAuthorized) {
        return true
      }
      return checkAuthorization
    }

    if (checkAuthorization && !Layout) {
      return <AuthLayout thinking />
    }

    if (authorized && redirectToIfAuthorized) {
      return <Redirect to={{ pathname: this.redirectUrl }} />
    }

    return !!Layout ? (
      <Layout thinking={layoutThinking()} title={title}>
        <Component {...this.props} />
      </Layout>
    ) : (
      <Component {...this.props} />
    )
  }
}
