import React, { useState, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Edit2 as Edit, Trash } from 'react-feather'
import { Menu, MenuItem, ListItemIcon as ListItemIconMui, ListItemText } from '@material-ui/core'
import { Folder } from 'react-feather'
import { NavigationItem } from '../Navigation/Navigation'
import ContentEditable from '../ContentEditable'

import styled from 'styled-components'

const ListItemIcon = styled(ListItemIconMui)`
  min-width: 30px;
`

const FolderListItem = (props) => {
  let dragRef = useRef(null)
  let previewRef = useRef(null)
  const { id, index, path, pathname, title, amount } = props
  const [edit, setEdit] = useState(props.id === 'new')
  const [open, setOpen] = useState(null)

  const onOpen = ({ currentTarget }) => {
    setOpen(currentTarget)
  }
  const onClose = () => setOpen(null)
  const onEdit = () => {
    setEdit(true)
    onClose()
  }
  const onCancel = () => {
    setEdit(false)
    if (props.onCancel) {
      props.onCancel(props.id)
    }
  }
  const onUpdate = async (name) => {
    setEdit(false)
    const { success } = await props.onUpdate(props.id, name)
    setEdit(!success)
  }
  const onRemove = () => {
    props.onRemove(props.id)
    onClose()
  }

  const previewOptions = {
    offsetX: 0,
    offsetY: 0
  }

  const [{ handlerId }, drop] = useDrop({
    accept: ['folder'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!dragRef.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return
      props.onReorder(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { id, type: 'folder', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    begin: props.onStart,
    end: props.onEnd
  })

  const folderDragStyles = {
    ...(isDragging && {
      'opacity': 0.4,
    })
  }

  return (
    <>
      <NavigationItem
        // disabled
        folderDragStyles={folderDragStyles}
        handlerId={handlerId}
        dragRef={node => {
          // console.log('Drag ref', node)
          // dragRef = { current: node }
          // drag(node)
        }}
        previewRef={node => {
          // console.log('Preview ref', node)
          // previewRef = { current: node }
          dragRef = { current: node }
          drag(node)
          drop(node)
          preview(node, previewOptions)
        }}
        // tempName={title}
        canDrop
        kind={'folder'}
        folderName={title}
        id={id}
        path={path}
        pathname={pathname}
        // amount={amount}
        activeItemId={0}
        draggable={false}
        sortHandle
        button
        disableRipple={edit}
        icon={Folder}
        nested
        hoverAction
        actionsMenu={props.id !== 'new'}
        onActions={onOpen}
        edit={edit}
        title={<ContentEditable
          id={id}
          edit={edit}
          value={title}
          onUpdate={onUpdate}
          onCancel={onCancel}
        />}
        folderDragged={props.isDragStarted}
      />

      <Menu
        anchorEl={open}
        open={Boolean(open)}
        onClose={onClose}
      >
        <MenuItem onClick={onEdit} style={{minWidth: 135}}>
          <ListItemIcon>
            <Edit size={16} />
          </ListItemIcon>
          <ListItemText primary={'Rename'} />
        </MenuItem>
        <MenuItem onClick={onRemove} style={{color: 'rgba(255, 63, 86, 1)'}}>
          <ListItemIcon>
            <Trash size={16} color={'rgba(255, 63, 86, 0.8)'}/>
          </ListItemIcon>
          <ListItemText primary={'Delete'} />
        </MenuItem>
      </Menu>

    </>
  )
}

export default FolderListItem
