import React from 'react'
import Button from '@material-ui/core/Button'
import { onCancelSubscription } from '../../utils/chargebee'

export default class BillingCancelSubscription extends React.Component {
  state = { thinking: false }

  onCancelSubscription = async () => {
    const { plan, brandId } = this.props
    this.setState({ thinking: true })

    await onCancelSubscription(brandId, plan, async () => {
      await this.props.onUpdateCredits({ cancelled: true })
      this.setState({ thinking: false })
    }, () => {
      this.setState({ thinking: false })
    })
  }

  render () {
    const { thinking } = this.state

    return (
      <Button
        size='large'
        variant={'contained'}
        disableElevation
        style={{textTransform: 'none'}}
        disabled={thinking}
        onClick={this.onCancelSubscription}
      >
        Cancel my subscription
      </Button>
    )
  }
}
