import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Tooltip} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {Tag as IconAdd, MoreHorizontal, X } from 'react-feather'
import TagMenu from './TagMenu'
import { setCaret } from '../../utils/caret'

const MAX_CHAR_LENGTH = 15

const Remove = styled(IconButton)`
  align-items: center;
  justify-content: center;
  width: 1.42em;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  visibility: hidden;
  border-radius: 0;
`

const More = styled(IconButton)`
  align-items: center;
  justify-content: center;
  width: 1.42em;
  height: 100%;
  position: absolute;
  right: 1.42em;
  top: 0;
  visibility: hidden;
  display: flex;
  border-radius: 0;
`

const Label = styled.div`
  display: block;
  //position: absolute;
  z-index: 1;
  left: 0.8em;
  white-space: nowrap;
  line-height: 1.8em;
  height: 1.8em;
  text-align: left;
  flex: 1 1 auto;
  visibility: hidden;

  ${props => props.edit && css`
    visibility: visible;
    position: relative;
    left: -0.8em;
    margin-right: -1.6em;
    background: white;
    padding: 0 0.8em;
    user-select: auto;
    cursor: text;
    -webkit-user-select: auto !important;
  `}
`

export const ShadowLabel = styled.div`
  display: block;
  white-space: nowrap;
  line-height: 1.8em;
  height: 1.8em;
  text-align: left;
  flex: 1 1 auto;
  position: absolute;
  z-index: 1;
  left: 0.8em;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
`

export const Root = styled.div`
  box-sizing: border-box;
  min-width: 3.85em;
  background: ${
    props => props.edit ? '' 
              : props.variant === 'view' ? props.theme.palette.brand.grey.main 
                    : props.unique ? props.theme.palette.brand.grey.light : props.theme.palette.brand.blue.light
  };
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: ${props => props.theme.typography.fontWeightLight};
  font-size: 0.85em;
  padding: 0 0.8em;
  line-height: 1.8;
  height: 1.8em;
  display: inline-flex;
  position: relative;
  border-radius: 0.21em;
  white-space: nowrap;
  overflow: hidden;
  ${ShadowLabel} {
    max-width: ${MAX_CHAR_LENGTH}ch;
    text-align: center;
    visibility: ${props => props.edit && 'hidden'};
    width: ${props => (props.variant === 'item' && !props.edit) && 'calc(100% - 1.07em)'};

  }
  ${Label} {
    text-align: center;
    max-width: ${MAX_CHAR_LENGTH}ch;
  }

  ${props => (!props.edit && props.variant !== 'view' && !props.locked) && css`
    &:hover {
      ${ShadowLabel} {
        width: ${props.readonly ? 'calc(100% - 2.14em)': 'calc(100% - 4.3em)'};
        overflow: hidden;
      }
      ${More}, ${Remove} {
        visibility: visible;
      }
    }
  `}
`

class Tag extends React.Component {

  root = null

  tagInput = null

  static propTypes = {
    name: PropTypes.string.isRequired,
    create: PropTypes.bool,
    active: PropTypes.any,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    onAddCategorySubmit: PropTypes.func,
    onAddCategoryCancel: PropTypes.func,
    handleOpenMenu: PropTypes.func
  }

  static defaultProps = {
    create: false,
    active: false,
    onUpdate: undefined,
    onRemove: undefined,
    onAddCategorySubmit: undefined,
    onAddCategoryCancel: undefined,
    handleOpenMenu: undefined,
    onClick: console.log
  }

  constructor (props) {
    super(props)
    this.state = {
      edit: false,
      name: props.name || '',
      anchorEl: null
    }
  }

  componentDidMount () {
    if (this.props.create) {
      this.tagInput.focus()
      this.tagInput.onblur = this.onUpdate
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { edit } = this.state

    if (!edit && this.props.name !== prevProps.name) {
      this.setState({ name: this.props.name })
    }
  }

  onOpenMenu = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.setState({ anchorEl: e.target })
  }

  onCloseMenu = () => this.setState({ anchorEl: null })

  onEdit = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    const { name } = this.props
    this.setState({ edit: true, name }, () => {
      this.tagInput.innerText = name
      setCaret(this.tagInput, name ? name.length : 0)
      this.tagInput.onblur = this.onUpdate
    })
    this.onCloseMenu()
  }

  onUpdate = (e) => {
    const { id } = this.props
    const { name, edit } = this.state
    const { onUpdate } = this.props

    if (edit && e && this.root.contains(e.relatedTarget)) {
      return this.tagInput.focus()
    }

    this.tagInput.onblur = null
    this.setState({ edit: false, name })
    return onUpdate({ id, label: name })
  }

  onDelete = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.props.onDelete(this.props.id)
    this.onCloseMenu()
  }

  onRemove = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.props.onRemove(this.props.id)
  }

  onCancel = () => {
    const { name } = this.props
    const { create, onAddCategoryCancel } = this.props
    if (create) {
      onAddCategoryCancel()
    }
    this.tagInput.onblur = null
    this.setState({ edit: false, name })
  }

  onClick = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    if (this.props.unique) {

      this.props.onSelect({ id: this.props.id, label: this.state.name })
    }
  }

  updateTag (e) {
    switch (e.key) {
      case 'Escape':
        return this.onCancel()
      case 'Enter':
        return this.onUpdate()
      default:
        return this.setState({ name: e.target.innerText })
    }
  }

  render () {
    const {
      locked,
      variant,
      create,
      children,
      onClick,
      ...otherProps
    } = this.props
    ///variant === 'view'  - only view state

    const { name, edit, anchorEl } = this.state
    const showActions = ['filter', 'editable', 'management', 'details', 'item'].includes(variant)

    const shadowLabel = <ShadowLabel size={'small'}>{create || edit ? null : name}</ShadowLabel>

    const label = <Label contentEditable={create || edit}
                         suppressContentEditableWarning
                         ref={node => this.tagInput = node}
                         spellCheck={false}
                         onKeyDown={this.updateTag.bind(this)}
                         onInput={this.updateTag.bind(this)}
                         edit={create || edit}>
                    { create || edit ? null : name }
                  </Label>

    const moreBtn = <Tooltip title={'More'} leaveDelay={0}>
                      <More size={'small'} onClick={this.onOpenMenu}><MoreHorizontal size={'1em'} /></More>
                    </Tooltip>

    const removeBtn = <Tooltip title={'Remove'} leaveDelay={0}>
                        <Remove size={'small'}><X size={'1em'} onClick={this.onRemove} /></Remove>
                      </Tooltip>


    const tagMenu = <TagMenu anchorEl={anchorEl}
                             onEdit={this.onEdit}
                             onDelete={this.onDelete}
                             onClose={this.onCloseMenu} />

    return (
        <Root ref={(node) => { this.root = node }} onClick={this.onClick} {...otherProps}
              locked={locked}
              variant={variant} edit={edit || create}
              unique={this.props.unique}
              name={this.props.name}>
          {label}
          {shadowLabel}
          {showActions && [variant !== 'filter' && moreBtn, removeBtn]}
          {!!anchorEl && tagMenu}
        </Root>
    )
  }
}

export default Tag
