import React, { useContext, useMemo, useState } from 'react'
import { findIndex } from 'lodash'
import styledComponent from 'styled-components'
import { ButtonBase, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium'
import {
  ArrowBackIosNew as CollapseIcon,
  ArrowForwardIos as ExpandIcon,
} from '@mui/icons-material'
import { Check, X, ThumbsUp } from 'react-feather'
import DataGridPro, { renderTitle } from './EcosystemTable'
import { IntelligenceQuickActions } from '../QuickActions/QuickActions'
import ListContext from '../List/ListContext'
import { renderProgress } from './EcosystemOverlap'
import { Base as TooltipMetricsBase } from '../../components-ts/TooltipMetrics/TooltipMetricsBase'
import metrics from '../../configMetrics'
import { formatToReadableNumber } from '../../utils/format'
import SelectAll from '../List/SelectAll'
import { Result, Root as ActionsRoot } from '../List/ListTable'

const SelectionContainer = styledComponent.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Header = () => {
  const context = useContext(ListContext)
  const {
    total,
    selected,
    quickActionProps,
    onQuickActions,
    onSelect,
    onGetAllIds,
  } = context
  const onActions = (id, action, direction, e) => {
    onQuickActions(id, action, undefined, e)
  }

  const showTotal = selected && selected.length === 0

  const onSelectAll = async () => {
    const ids = await onGetAllIds()
    onSelect(ids)
  }

  return (
    <div style={{ display: 'flex' }}>
      {!showTotal && (
        <SelectionContainer>
          <SelectAll
            selected={selected}
            total={total}
            source={'channels'}
            onSelect={onSelectAll}
          />
        </SelectionContainer>
      )}
      {showTotal && (
        <ActionsRoot>
          <Result>
            <strong>{formatToReadableNumber(total)} results</strong>
          </Result>
        </ActionsRoot>
      )}
      &nbsp;&nbsp;
      <IntelligenceQuickActions
        heading
        disable={selected && selected.length < 1}
        header
        {...quickActionProps}
        onAction={onActions}
      />
    </div>
  )
}

const Root = styled('div')(({ theme, secondary, value }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // background: !secondary && value == null && 'rgba(194,59,204,0.65)',
  // color: !secondary && value == null && 'white'
}))

const Relevant = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'relevant',
})(({ relevant, theme }) => ({
  color: relevant ? 'white' : 'rgba(1,99,250,1)',
  background: relevant ? 'rgba(1,99,250,1)' : null,
  display: 'flex',
  justifyContent: 'center',
  fontSize: '0.9em',
  alignItems: 'center',
  textDecoration: 'none',
  padding: '0.3em 0.5em',
  borderRadius: 5,
  border: relevant ? null : '1px solid rgba(1,99,250,0.65)',
  // '&:hover': {
  //   background: relevant ? '#76d192' : 'rgba(1,99,250,1)',
  //   color: 'white'
  // },
  '& svg': {
    width: '1em',
    height: '1em',
    position: 'relative',
    top: '-0.1em',
  },
}))

const PrimaryCell = React.memo(function PrimaryCell(props) {
  const { value, domain, secondary, relevant } = props
  const onAddToRelevant = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    props.onAddToRelevant(domain)
  }
  return (
    <Root secondary={secondary} value={value}>
      {value && !relevant && <Check color={'#51be75'} />}
      {value === false && secondary && <X color={'#fc8c8c'} />}
      {(value === false || relevant) && !secondary && (
        <Relevant
          href='#'
          disabled={relevant}
          relevant={relevant}
          onClick={onAddToRelevant}
        >
          <ThumbsUp />
          &nbsp;Relevant
        </Relevant>
      )}
    </Root>
  )
})

const GroupHeaderCellContainer = styled('div')({
  cursor: 'pointer',
  display: 'contents',
})

const GroupHeaderCellTitle = styled('span')({
  width: '70%',
  color: 'black',
  fontWeight: 600,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const GroupHeadersCell = (props) => {
  const { title, collapsed, onToggleCollapse } = props
  return (
    <GroupHeaderCellContainer onClick={onToggleCollapse}>
      <Tooltip title={title}>
        <GroupHeaderCellTitle>{title}</GroupHeaderCellTitle>
      </Tooltip>
      {collapsed ? (
        <ExpandIcon sx={{ height: 14 }} />
      ) : (
        <CollapseIcon sx={{ height: 14 }} />
      )}
    </GroupHeaderCellContainer>
  )
}

function renderPrimary(params) {
  const { row, field } = params
  const { [field]: brand, kind, domain, onAddToRelevant } = row
  const { exist } = brand || {}

  const isChannelExist = !!exist
  const isRelevant = ['partner', 'relevant'].includes(kind)

  return (
    <PrimaryCell
      value={isChannelExist}
      relevant={isRelevant}
      domain={domain}
      onAddToRelevant={onAddToRelevant}
    />
  )
}

function renderSecondary(params) {
  const { row, field } = params
  const { [field]: brand } = row
  const { exist } = brand || {}

  return <PrimaryCell value={!!exist} secondary />
}

const Heading = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const createColumns = (total) => {
  return [
    {
      field: 'domain',
      headerName: 'Partner',
      flex: 1,
      sortable: false,
      renderCell: renderTitle,
      minWidth: 300,
      pin: true,
      renderHeader: () => <Header total={total} />,
    },
    {
      field: 'mozDomainAuthority',
      align: 'right',
      headerAlign: 'right',
      headerName: <Heading>{metrics['moz'].heading}</Heading>,
      description: (
        <TooltipMetricsBase
          title={metrics['moz'].title}
          content={metrics['moz'].content}
          poweredBy={metrics['moz'].poweredBy}
        />
      ),
      width: 70,
      minWidth: 70,
      valueFormatter: (params) => {
        if (params.value == null) {
          return ''
        }
        return formatToReadableNumber(params.value, 'integer')
      },
    },
    {
      field: 'swGlobalRank',
      headerName: <Heading>{metrics['rank'].heading}</Heading>,
      description: (
        <TooltipMetricsBase
          title={metrics['rank'].title}
          content={metrics['rank'].content}
          poweredBy={metrics['rank'].poweredBy}
        />
      ),
      width: 82,
      minWidth: 82,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        if (params.value == null) {
          return ''
        }

        return formatToReadableNumber(params.value)
      },
    },
    {
      field: 'swVisits',
      headerName: <Heading>{metrics['visits'].heading}</Heading>,
      description: (
        <TooltipMetricsBase
          title={metrics['visits'].title}
          content={metrics['visits'].content}
          poweredBy={metrics['visits'].poweredBy}
        />
      ),
      width: 85,
      minWidth: 85,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        if (params.value == null) {
          return ''
        }

        return formatToReadableNumber(params.value)
      },
    },
    {
      field: 'partnershipsOutCount',
      headerName: metrics['promotes'].heading,
      description: (
        <TooltipMetricsBase
          title={metrics['promotes'].title}
          content={metrics['promotes'].content}
        />
      ),
      width: 95,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        if (params.value == null) {
          return ''
        }

        return formatToReadableNumber(params.value)
      },
    },
    {
      field: 'overlap',
      headerName: 'Overlap',
      headerClassName: 'marginLeft',
      minWidth: 200,
      renderCell: renderProgress,
    },
  ]
}

const isGroupExist = (brands, primaryDomains) => {
  if (!Boolean(brands)) return false

  let exist = true

  brands.map((brand) => {
    if (primaryDomains.includes(brand.domain) && !brand.exist) {
      exist = false
    }
  })

  return exist
}

const getData = (data, relevant, primary) => {
  const primaryDomains = primary.map(({ domain }) => domain)

  return (
    !!data &&
    data.map(({ brands, ...rest }) => {
      let row = { ...rest }
      let { domain } = row

      !!brands &&
        brands.map((brand) => {
          const exist =
            relevant.hasOwnProperty(domain) && relevant[domain] === brand.domain

          row = {
            ...row,
            [brand.domain]: {
              ...brand,
              ...(exist && { exist }),
            },
          }
        })

      return { ...row, group: isGroupExist(brands, primaryDomains) }
    })
  )
}

const getGroupColumn = (domains, collapsed, onToggleCollapse) => {
  const title = domains.map(({ domain }) => domain).join(', ')

  return {
    field: 'group',
    headerName: title,
    width: 100,
    sortable: false,
    headerAlign: 'center',
    cellClassName: 'group',
    headerClassName: 'ellipsis',
    renderHeader: (params) => {
      return (
        <GroupHeadersCell
          title={title}
          collapsed={collapsed}
          onToggleCollapse={onToggleCollapse}
        />
      )
    },
    renderCell: (params) => {
      const { row } = params
      const relevant = row.kind !== 'unexist'
      return (
        <PrimaryCell
          value={row.group}
          domain={row.domain}
          relevant={relevant}
          onAddToRelevant={row.onAddToRelevant}
        />
      )
    },
  }
}

const getDomainColumn = (brand, isPrimary, hideRelevant) => {
  return {
    field: brand.domain,
    headerName: brand.domain,
    width: 100,
    sortable: false,
    cellClassName: isPrimary ? 'group-item' : '',
    headerClassName: '',
    headerAlign: 'center',
    renderCell: isPrimary && !hideRelevant ? renderPrimary : renderSecondary,
  }
}

const getColumns = (primaryDomains, data, collapsed, onToggleCollapse) => {
  if (!data || !data.length) return []

  const { brands } = data[0]

  if (!brands) return []

  const primary = brands
    .filter((brand) => findIndex(primaryDomains, ['domain', brand.domain]) > -1)
    .filter((brand) => !!brand)
  const secondary = brands
    .filter((brand) => findIndex(primaryDomains, ['domain', brand.domain]) < 0)
    .filter((brand) => !!brand)

  const showGroup = Boolean(secondary.length && primary.length > 1)

  let groupColumn = showGroup
    ? [{ ...getGroupColumn(primaryDomains, collapsed, onToggleCollapse) }]
    : []

  let primaryColumns =
    !showGroup || !collapsed
      ? primary.map((brand) => getDomainColumn(brand, true, showGroup))
      : []

  let secondaryColumns = secondary.map((brand) => getDomainColumn(brand, false))

  return [...groupColumn, ...primaryColumns, ...secondaryColumns]
}

let resizeTimeout = null

const EcosystemGapCompare = ({
  primary,
  total,
  compare,
  loading,
  error,
  selected,
  sortModel,
  onSort,
  onPageChange,
  onCellClick,
  onSelect,
  onQuickActions,
  onManageTags,
  onGetAllIds,
  ...props
}) => {
  const [widths, setWidths] = useState({})
  const columns = useMemo(() => {
    return createColumns(total)
  }, [total])

  const relevant = {}
  const data = getData(compare, relevant, primary)
  const domainColumns = getColumns(
    primary,
    compare,
    props.collapsed,
    props.onToggleCollapse
  )

  const onResize = ({ colDef }) => {
    const { field, width } = colDef

    clearTimeout(resizeTimeout)

    resizeTimeout = setTimeout(() => {
      setWidths({ ...widths, [field]: width })
    }, 1000)
  }

  const columnsWithWidth = [...columns, ...domainColumns].map(
    ({ field, flex, width, ...rest }) => {
      const columnWidth = widths[field] || width
      return {
        field,
        ...(!columnWidth && flex && { flex }),
        ...(columnWidth && { width: columnWidth }),
        ...rest,
      }
    }
  )

  return (
    <DataGridPro
      hideFooter
      checkboxSelection
      disableSelectionOnClick
      disableRowSelectionOnClick
      sortingMode='server'
      paginationMode='server'
      pinnedColumns={{
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'domain'],
      }}
      loading={loading}
      error={error}
      rows={data.map((r) => ({ ...r, onQuickActions, onManageTags }))}
      columns={columnsWithWidth}
      sortModel={sortModel}
      onRowsScrollEnd={onPageChange}
      onRowClick={onCellClick}
      onSortModelChange={onSort}
      onColumnResize={onResize}
      total={total}
      selected={selected}
      onSelect={onSelect}
      onQuickActions={onQuickActions}
      onGetAllIds={onGetAllIds}
    />
  )
}

export default EcosystemGapCompare
