import React from 'react'
import styled, {keyframes} from 'styled-components'
import { find } from 'lodash'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import {Typography, IconButton, Tooltip, MenuItem, Menu, TableCell, TableRow as MuiTableRow, Button, ClickAwayListener} from '@material-ui/core'
import Grow from '@material-ui/core/Grow'
import PaperMUI from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'

import MoreIcon from '@material-ui/icons/MoreHoriz'
import CountryFlag from '../CountryFlag'
import CountriesFlag from '../Leads/CountriesFlag'
import {InputsDisplayBase as Inputs, InputsDisplayProgress as Progress} from '../InputsDisplay'
import TextAvatar from "react-avatar"
import StickyTableCell from "../StickyTableCell/StickyTableCell"
import IconLabelSearch from '../../new-components/IconLabelSearch'
import {formatDateFromNow, formatToReadableNumber} from "../../utils/format"
import config from '../../config'
const imageKitUrl = process.env.IMAGEKIT_URL

const Name = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #222222;
  flex: 1 1 auto;
  text-decoration: none;
  padding-bottom: 0.2em;
`

const Link = styled(RouterLink)`
  pointer-events: ${props => props.disabled ? 'none' : ''};
  text-decoration: none;
`

const MoreAction = styled(IconButton)`
  opacity: 0.2;
`

const Description = styled(Typography)`
  font-size: 14px;
  padding: 0 16px 16px;
  max-width: 45em;
`

const TableRow = styled(MuiTableRow)`
  background: ${props => props.disabled ? 'rgba(0, 0, 0, 0.04)' : ''} ;
`

const Wrapper = styled.div`
  white-space: nowrap;
`

const DiscoveryCellRoot = styled.div`
  color: ${props => props.theme.palette.defaultText};
`

const KeyMetricsRoot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 83px;
`

const KeyMetricsHeader = styled.div`
  flex: 1 1 20px;
`

const KeyMetricsFooter = styled.div`
  flex: 1 1 20px;
  font-size: 10px;
  opacity: 0.8;
  text-align: center;
  align-items: center;
`

const KeyMetricsBody = styled.div`
  display: flex;
  justify-content: space-between;
`
const KeyMetricsValue = styled.div`
  font-weight: bold;
  font-size: 18px;
`

const KeyMetricsProgressIndicatorRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ExpiredLabel = styled.div`
  background: ${props => props.theme.palette.error.main};
  border-radius: 30px;
  padding: 0.1em 0.7em;
  color: white;
  display: inline-block; 
  margin-right: 0.8em;
  font-size: 0.7em;
`

const KeyMetricsProgressIndicator = () =>
  <KeyMetricsProgressIndicatorRoot>
    <Loader>
      <Arrow color={'#0163FA'}/>
      <Arrow color={'transparent'}/>
      <Arrow color={'#00DED1'}/>
    </Loader>
  </KeyMetricsProgressIndicatorRoot>

const colorChange = keyframes`
  0% {
    color: #0163FA;
  }
  33.33% {
    color: #00DED1;
  }
  66.66% {
    color: white;
  }
  100% {
    color: #0163FA;
  }
`

const Loader = styled.div`
  width: 50px;
  height: 20px;
  display: flex;
  justify-content: center;
  div:nth-child(3) {
    animation: ${colorChange} 1s  steps(2) -0.5s 3;
  }
  div:nth-child(2) {
    animation: ${colorChange} 1s  steps(2) -0.25s 3;
  }
  div:nth-child(1) {
    animation: ${colorChange} 1s  steps(2) 3;
  }
  
  div:nth-child(2), div:nth-child(3) {
   margin-left: -11px;
  }
`

const Paper = styled(PaperMUI)`
  min-width: 150px;
  margin-right: 1em;
`

const Arrow = styled.div`
  width: 8px;
  height: 20px;
  position: relative;
  color: ${props => props.color};
  &:before {
    content: '';
    display: block;
    position: absolute;
    background: currentColor;
    width: 3px;
    height: 10px;
    transform: skew(25deg);
    top: 0;    
    left: 0;
    transform-origin: top left;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: currentColor;
    width: 3px;
    height: 10px;
    transform: skew(-25deg);
    left: 0;
    bottom: 0;
    transform-origin: bottom left;
  }
`

const DiscoveryProgress = ({full}) =>
  <div>
    <Progress />
    {full && <div>Search is running</div>}
  </div>



const KeyMetrics = ({leads, relevants, state, onResearch}) =>
  <KeyMetricsRoot>
    <KeyMetricsHeader />
    {
      ['newDiscovery', 'newDiscoveryRunning'].indexOf(state) < 0 && (
        <>
          <KeyMetricsBody>
            <KeyMetricsValue>{formatToReadableNumber(leads)}</KeyMetricsValue>
            <KeyMetricsProgressIndicator active />
            <KeyMetricsValue>{formatToReadableNumber(relevants)}</KeyMetricsValue>
          </KeyMetricsBody>
        </>
      )
    }
    { state === 'newDiscovery' && <Button variant={'contained'} color={'primary'} onClick={onResearch} disableElevation>Run Search</Button> }
    <KeyMetricsFooter>
      {/*{state}*/}
      {(state === 'discoveryRunning' || state === 'newDiscoveryRunning') && <DiscoveryProgress full={state === 'newDiscoveryRunning'} />}
    </KeyMetricsFooter>
  </KeyMetricsRoot>


const DiscoveryCell = ({
                         index,
                         description,
                         keywordsCount,
                         keywordsChanged,
                         competitorsCount,
                         competitorUrlsChanged,
                         irrelevantsCount,
                         irrelevantsChanged,
                         hidden,
                         researchStatus,
                         researchSeen,
                         firstResearchSeen
}) => {
  const inputsProps = {
    keywordsCount,
    keywordsChanged,
    competitorsCount,
    competitorUrlsChanged,
    irrelevantsCount,
    irrelevantsChanged
  }
  return <DiscoveryCellRoot style={{padding: '0.3em 0'}}>
    <Name>
      <IconLabelSearch>
        {hidden && <Tooltip title={'This search has expired, contact us to reactivate.'}><ExpiredLabel>Expired</ExpiredLabel></Tooltip>}
        { description || `Untitled search ${index}`}
      </IconLabelSearch>
      {/*{(researchStatus === 'researched' && (!researchSeen || !firstResearchSeen)) && <>FINISHED</>}*/}
    </Name>
    <Inputs {...inputsProps} />
  </DiscoveryCellRoot>
}

const CountryCell = ({ countryCode }) => {
  const countryObject = find(CountriesFlag, ['value', countryCode])
  return countryObject ? (
    <>
      <CountryFlag
        country={countryObject.value}
        alt={countryObject.label}
        fallbackImageURL={`${imageKitUrl}/flags/${countryObject.value}.svg`}
      /> {countryObject.label}
    </>
  ) : null
}

@withRouter
export default class ProductListItem extends React.Component {
  state = { anchorEl: null }

  onOpenMenu = (e) => {
    this.setState({ anchorEl: e.target })
  }

  onCloseMenu = (e) => {
    this.setState({ anchorEl: null })
  }

  onResearch = e => {
    if (e) {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }
    this.props.onResearch(this.props.product.id)
    this.onCloseMenu()
  }

  onRemove = e => {
    this.props.onRemove(this.props.product.id)
    this.onCloseMenu()
  }

  onEdit = e => {
    this.props.onEdit(this.props.product.id)
    this.onCloseMenu()
  }

  onDetails = (e) => {
    const { product } = this.props
    const {
      firstResearchSeen,
      researchStatus
    } = product

    if (researchStatus === 'progress') {
      return e.preventDefault()
    }

    return this.props.onDetails(product.id)
  }

  get productStatus () {
    const {
      product: {
        researchStatus, researchSeen, firstResearchSeen,
        keywordsChanged, competitorUrlsChanged
      }
    } = this.props

    if (researchStatus === 'pending') {
      return 'newDiscovery'
    }

    if (!firstResearchSeen) {
      return researchStatus === 'progress' ? 'newDiscoveryRunning' : 'newDiscoveryReady'
    }

    if (researchStatus === 'progress') {
      return 'discoveryRunning'
    }

    if (!researchSeen) {
      return 'discoveryReady'
    }

    if (keywordsChanged || competitorUrlsChanged) {
      return 'discoveryReRun'
    }

    return null
  }

  render () {
    const { anchorEl } = this.state
    const {
      index,
      product,
      match: { url }
    } = this.props
    const {
      id,
      name,
      user,
      createdAt,
      leads,
      relevants,
      researchDoneAt,
      firstResearchSeen,
      ownerName,
      ownerFullName,
      hidden,
      ...research
    } = product

    const hasChanges = research.competitorUrlsChanged || research.keywordsChanged || research.irrelevantsChanged
    const discoveryUrl = `${url}/discovery/${id}`
    const processing = research.researchStatus === 'progress'

    // disabled={!firstResearchSeen && ['pending', 'progress'].includes(research.researchStatus)}

    return (
      <>
        <TableRow key={id} hover >
          <TableCell>
            <Link to={discoveryUrl} onClick={this.onDetails}>
              <DiscoveryCell {...product} index={index} disabled={hidden}/>
            </Link>
          </TableCell>
          <TableCell colspan="2" style={{position: 'relative', paddingBottom: 0, paddingTop: 0}}>
              <KeyMetrics
                leads={leads}
                relevants={relevants}
                state={this.productStatus}
                onResearch={this.onResearch}
              />
          </TableCell>
          <TableCell>
            <Wrapper>
              <TextAvatar
                maxInitials={2}
                name={ownerFullName}
                textSizeRatio={1.5}
                size={20}
                round={30}
                fgColor={'black'}
                colors={config.avatarColors}
                variant="rounded"/>&nbsp;&nbsp;
              {ownerName || '-'}
              </Wrapper>
            </TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>
            <Wrapper>
              <CountryCell {...product}/>
            </Wrapper>
          </TableCell>
          <TableCell>{formatDateFromNow(researchDoneAt || createdAt)}</TableCell>
          <StickyTableCell>
            <MoreAction size={'small'} onClick={this.onOpenMenu}><MoreIcon /></MoreAction>
          </StickyTableCell>
        </TableRow>

          <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} transition>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.onCloseMenu}>
                    <MenuList>
                      <MenuItem disabled={hidden || processing} onClick={this.onEdit}>Edit</MenuItem>
                      {/*<MenuItem disabled onClick={this.onCloseMenu}>Duplicate</MenuItem>*/}
                      <MenuItem disabled={(!hasChanges && firstResearchSeen) || hidden || processing} onClick={this.onResearch}>
                        { firstResearchSeen ? 'Re-run' : 'Run' }
                      </MenuItem>
                      <MenuItem onClick={this.onRemove} disabled={processing}>Delete</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
      </>
    )
  }
}
