import React from 'react'
import Plans from '../../pages/Welcome/Plans'
import { onSubscribe, onEndTrial, onActivateSubscription } from '../../utils/chargebee'
import config from '../../config'

export default class BillingPlans extends React.Component {
  state = { thinking: null, demo: false }

  onSelectPlan = async (plan, period) => {
    const id = `${plan === 'self' ? 'Self-serve' : 'Assisted'}-GBP-${period === 'monthly' && plan === 'self' ? 'Monthly' : 'Every-3-months'}`
    if (plan === 'assisted') {
      this.props.onBookDemo()
      this.props.onClose()
    } else {
      const { billingStatus } = this.props
      if (!billingStatus) {
        this.setState({ thinking: true })
        const { success } = await this.props.onTrialSubscribe(id)
        this.setState({ thinking: false })
        if (success) {
          this.props.onClose()
        }
      } else {
        this.onSubscribe({ id }, 'plan').then()
      }
    }
  }

  onSubscribe = async ({ id: planId }) => {
    const { plan, brandId, billingStatus } = this.props
    this.setState({ thinking: true })

    const onReject = () => this.setState({ thinking: false })
    const onSuccess = () => {
      this.setState({ thinking: false })
      if (this.props.closeOnSuccess) {
        this.props.onClose()
      }
    }
    if (billingStatus === 'inTrial' && plan === planId) {
      await onEndTrial(brandId, async () => {
        await this.props.onPollingBrand()
        onSuccess()
      }, onReject)
    } else if (plan === planId) {
      await onActivateSubscription(brandId, planId,
        () => {
          this.props.onUpdateCredits({ plan: planId, billingStatus: 'active' })
          onSuccess()
        },
        onReject)
    } else {
      await onSubscribe(brandId, planId,
        () => {
          this.props.onUpdateCredits({ plan: planId, billingStatus: 'active' })
          onSuccess()
        },
        onReject)
    }
  }

  get currentPlanOptions () {
    const { plans } = config
    const { plan } = this.props

    if (!plan) {
      return {}
    }

    const { option, period } = plans[plan]

    return { option, period }
  }

  render () {
    const { thinking } = this.state
    const { billingStatus } = this.props

    return (
      <Plans
        {...this.currentPlanOptions}
        title={!billingStatus ? null : this.props.title}
        noSubTitle={!billingStatus ? null : this.props.noSubTitle}
        billingStatus={billingStatus}
        thinking={thinking || this.props.thinking}
        variant={!billingStatus ? 'onboarding' : 'billing'}
        header={!billingStatus ? null : this.props.header}
        disabled={this.props.disabled}
        onSelectPlan={this.onSelectPlan}
      />
    )
  }
}
