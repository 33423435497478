import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {
  FormControlLabel as MuiFormControlLabel,
  FormHelperText as MuiHelperText,
  Checkbox as MuiCheckbox,
  Grid,
  FormControl as MuiFormControl,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'
import {Form, Input} from '../Form'
import Button from '../Button'
import Validator from '../../utils/validator'

const FORM_ID = 'signin-form'

const ActionsHolder = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
`
const GridInput = styled(Grid)`
  margin: 0 auto 24px auto;
  width: 100%;
`

const FormControlLabel = styled(MuiFormControlLabel)`
  margin: 0;
  align-items: self-start;
  .MuiFormControlLabel-label {
    color: #3E3E3E;
    font-size: 14px;
    line-height: 16px;
  }
`
const RoleSelection = styled(MuiFormControl)`
  width: 100%;
  `
const RoleOption = styled(MuiFormControlLabel)`
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 5px;
  padding: 0.8em 1em;
  width: 100%;
  display: flex;
  margin: 0;
  gap: 0.2em;
`

const RoleSwitcher = styled(RadioGroup)`
  gap: 0.5em;
`

const RoleLabel = styled.div`
  flex: 1;
`

const FormHelperText = styled(MuiHelperText)`
  margin-left: 40px;
  align-items: self-start;
  color: #ca392e;
`

const Checkbox = styled(MuiCheckbox)`
  padding: 0;
  margin: 0 16px 0 0;
  position: relative;
  top: -0.15em;
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #0163FA;
  }
`

const LinksContainer = styled.div`
  padding-top: 1em;
  text-align: center;
  a {
    font-size: 14px;
    font-weight: bold;
  }
`

const creatorSchema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  },
  {
    field: 'password',
    rules: 'required|minLength[8]|doesNotContainWhitespace|regexp[^(?=\.*[A-Za-z])(?=\.*\\d)(?=\.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$]',
    messages: {
      required: 'Password is required',
      minLength: 'Password length must be minimum 8 characters',
      containsLetterAndNumber: 'Password should contain at least one number and one letter',
      doesNotContainWhitespace: 'Password should not contain spaces (or other whitespace characters)',
      regexp: 'Please use a combination of letters, numbers and special characters'
    }
  }
]

const brandSchema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  }
]

export default class SignUpFormRoleSelection extends React.Component {
  // Первый. consent = true
  // Второй. receiveNotifications = true
  constructor(props) {
    super(props)
    const {email, password} = this.props
    this.state = { email, password, firstName: '', lastName: '', role: 'creator', consent: false, receiveNotifications: true, errors: {}, error: null }
  }

  onChange = name => ({target: {value, checked}}) => {
    this.setState({[name]: ['receiveNotifications', 'consent'].includes(name) ? checked : value})
    this.resetError(name)
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    event.preventDefault()
    const { email, password, role, firstName, lastName, consent, receiveNotifications } = this.state
    const signupSchema = role === 'creator' ? creatorSchema : brandSchema

    Validator(FORM_ID, signupSchema,
      errors => this.setState({errors}),
      async () => {
        const { error } = await this.props.onSubmit({ email, password, role, firstName, lastName, consent, receiveNotifications })

        if (error) {
          switch (error) {
            case 'emailExists':
              this.setState({errors: {email: 'User with given email already exist'}})
              break;
            case 'noEmail':
              this.setState({errors: {email: 'Email not found'}})
              break;
            case 'wrongPassword':
              this.setState({errors: {password: 'Your password is not correct'}})
              break;
            default:
              this.setState({errors: {email: error}})
          }
        }
      })
  }

  onSignIn = (e) => {
    if (this.props.onSignIn) {
      e.preventDefault()
      this.props.onSignIn()
    }
  }

  handleRoleChange = (event) => {
    this.setState({role: event.target.value});
  }

  render() {
    const {
      email,
      password,
      errors,
      consent,
      receiveNotifications,
      role,
      firstName,
      lastName
    } = this.state
    const {
      header,
      subHeader,
      thinking,
      error
    } = this.props

    const Actions = () => <ActionsHolder>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{maxWidth: 300, width: '100%'}}
        disabled={thinking || (role === 'creator' && !consent)}
      >
        Continue
      </Button>
      <LinksContainer>
        <p>
          <Link to={'/signin'} onClick={this.onSignIn}>Sign in</Link> if you have an account.
        </p>
      </LinksContainer>
    </ActionsHolder>

    const Role = ({name, description}) =>
      <RoleOption value={name.toLowerCase()} control={<Radio color='primary' />}
                  label={
                    <RoleLabel>
                      <strong>{name}</strong>
                      <br />{description}
                    </RoleLabel>
                  }
      />

    return (
      <Form
        noValidate
        header={header}
        subHeader={subHeader}
        formId={FORM_ID}
        onSubmit={this.onSubmit}
        actions={Actions}
        error={error}
        thinking={thinking}
      >
        <Grid container>
          <GridInput item xs={12}>
            <RoleSelection>
              <RoleSwitcher
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={role}
                onChange={this.handleRoleChange}
              >
                <Role name='Creator' description="I'm a creator looking for opportunities" />
                <Role name='Brand' description="I'm a brand looking for partners" />
              </RoleSwitcher>
            </RoleSelection>

          </GridInput>
          <Grid container spacing={1}>
            <GridInput item xs={6}>
              <Input
                autoFocus
                required
                fullWidth
                placeholder="e.g. Joe"
                margin="none"
                id={'firstName'}
                label={'First name'}
                inputLabel={'First name'}
                variant={'outlined'}
                fieldType='clearable'
                value={firstName}
                disabled={thinking}
                error={errors.firstName}
                onChange={this.onChange('firstName')}
              />
            </GridInput>
            <GridInput item xs={6}>
              <Input
                required
                fullWidth
                placeholder="e.g. Bloggs"
                margin="none"
                id={'lastName'}
                label={'Last name'}
                inputLabel={'Last name'}
                variant={'outlined'}
                fieldType='clearable'
                value={lastName}
                disabled={thinking}
                error={errors.lastName}
                onChange={this.onChange('lastName')}
              />
            </GridInput>
          </Grid>
          <GridInput item xs={12}>
            <Input
              id={'email'}
              type={'email'}
              margin="none"
              required
              fullWidth
              placeholder="e.g. joe.bloggs@example.com"
              fieldType='clearable'
              label={'Email'}
              inputLabel={'Email'}
              variant={'outlined'}
              value={email}
              error={errors.email}
              disabled={thinking}
              onChange={this.onChange('email')}
            />
          </GridInput>
          {role === 'creator' && <>
            <GridInput item xs={12}>
              <Input
                id={'password'}
                type={'password'}
                margin="none"
                required
                fullWidth
                placeholder="Password"
                fieldType='clearable'
                label={'Password'}
                inputLabel={'Password'}
                variant={'outlined'}
                value={password}
                error={errors.password}
                disabled={thinking}
                onChange={this.onChange('password')}
              />
            </GridInput>
            <GridInput item xs={12}>
              <FormControlLabel

                error={errors.receiveNotifications}
                control={<Checkbox id={'receiveNotifications'} color="primary" value={receiveNotifications} onChange={this.onChange('receiveNotifications')}/>}
                label="I agree to receive occasional product news, updates, and relevant content."
              />
              {errors.receiveNotifications && <FormHelperText>{errors.receiveNotifications}</FormHelperText>}
            </GridInput>
            <GridInput item xs={12}>
              <FormControlLabel
                required
                error={errors.consent}
                control={<Checkbox id={'consent'} color="primary" value={consent} onChange={this.onChange('consent')}/>}
                label={<>I accept and agree to Breezy’s <a target="_blank" href="https://breezy.io/website-terms">Website Terms of Use</a> and <a target="_blank" href='https://breezy.io/privacy'>Privacy Policy.</a></>}
              />
              {errors.consent && <FormHelperText>{errors.consent}</FormHelperText>}
            </GridInput>
          </>
          }
        </Grid>
      </Form>
    )
  }
}
