import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'

import StackedRowChart from './StackedRowChart'

const CREATOR_ADEX_DISTRIBUTION = gql`
  query creatorAdexDistribution ($ecosystemId: String!) {
    creatorAdexDistribution(ecosystemId: $ecosystemId) {
      id
      name
      percent
      count
    }
  }
`

const BRAND_ADEX_DISTRIBUTION = gql`
  query brandAdexDistribution ($ecosystemId: String!) {
    brandAdexDistribution(ecosystemId: $ecosystemId) {
      id
      name
      percent
      count
    }
  }
`


export default function AdexDistributionChart ({ kind, ...props }) {
  const [getAdexDistribution, { loading, error }] = useLazyQuery(kind === 'brand' ? BRAND_ADEX_DISTRIBUTION : CREATOR_ADEX_DISTRIBUTION)
  const [ecosystemId, setEcosystemId] = useState(null)
  const [data, setData] = useState([])
  const [loaded, setLoaded] = useState(false)

  const fetchData = async (id) => {
    const result = await getAdexDistribution({ variables: { ecosystemId: id }})

    if (!error) {
      const _data = result.data[`${kind}AdexDistribution`].map(({ id, name, percent}) => ({ id, title: name, value: parseInt(percent) }))
      setData(_data)
    }
  }

  useEffect(() => {
    if (ecosystemId !== props.ecosystemId) {
      setEcosystemId(props.ecosystemId)
      setLoaded(true)
      fetchData(props.ecosystemId).then()
    }
  }, [props.ecosystemId])

  return error ? null : <StackedRowChart loading={loading} data={data} />
}

AdexDistributionChart.defaultProps = {
  kind: 'creator'
}
