import React from 'react'
import styled from 'styled-components'
import {Grid} from '@material-ui/core'
import TextContainer from '../TipsText'
import GradientScroll from 'react-gradient-scroll-indicator'

const Root = styled.div`
  padding: ${props => props.noPadding ? '0' : '0 20px'};
  display: flex;
  height: ${props => props.noPadding ? 'auto' : '100%'};
  //min-height: 250px;
  //margin-bottom: 3em;
  overflow: hidden;
`

const Column = styled.div`
  flex: ${props => props.fullWidth ? '100%' : '50%'};
  padding: 20px 0 0 0;
  width: ${props => props.fullWidth ? '100%' : '50%'};
  &.form {
    padding: ${props => props.fullWidth ? '0' : '20px 40px 0 0'};
  }
`

const ColumnText = styled.div`
  flex: 50%;
  width: 50%;
  height: 100%;
  position: relative;
`

export const FormsModal = (
  {
    children,
    Text,
    Actions,
    noText,
    fullWidth,
    noPadding
  }
) => {
  return (
    <>
    <Root noPadding={noPadding}>
      <Grid container style={{height: '100%'}} spacing={8}>
        <Grid item xs={12} md={6} style={{height: '100%'}}>
          {children}
        </Grid>
      {!noText && <Grid item xs={12} md={6} style={{height: '100%'}}>
          <TextContainer>
            {Text && Text()}
          </TextContainer>
        </Grid>
      }
      </Grid>
    </Root>
    {Actions && Actions()}
    </>
  )
}
