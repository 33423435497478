import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { Footer } from '../../components/Product/Footer'
import SaveRun from '../Buttons/SaveRun'
import ReRun from '../Buttons/ReRun'
import Button from '../../components/Button'

const ActionsHolder = styled.div`
  margin-top: 2em;
`

const Actions = ({
                   thinking, locked, credits, kind, variant, inputs, hasErrors, hasChanges, hasUpdates,
                   onSave, onSaveAndRun, onSubmit, onCancel
                 }) => {
  return (
    <ActionsHolder>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          {
            variant === 'inputs' && (
              <Footer>
                <SaveRun
                  locked={locked}
                  inputs={variant === 'inputs'}
                  thinking={thinking || hasErrors || !hasChanges}
                  hasChanges={hasChanges}
                  hasUpdates={hasUpdates}
                  onSave={onSave}
                  onSaveAndRun={onSaveAndRun}
                />
                { hasUpdates && <ReRun /> }
              </Footer>
            )
          }

          {
            variant !== 'inputs' && (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  mb={2}
                  disabled={locked || thinking}
                  onClick={onCancel}
                >
                  { kind === 'keywords' && 'Return to Product Entry' }
                  { kind === 'competitors' && 'Previous' }
                </Button>
                &nbsp;
                &nbsp;
                {
                  variant !== 'onboarding' && kind === 'competitors' && (
                    <SaveRun
                      inputs={inputs}
                      thinking={locked || thinking || hasErrors || (variant === 'inputs' && !hasChanges)}
                      hasChanges={hasChanges}
                      hasUpdates={hasUpdates}
                      onSave={onSave}
                      onSaveAndRun={onSaveAndRun}
                    />
                  )
                }
                {
                  kind === 'keywords' && (
                    <Button
                      onClick={onSubmit}
                      variant="contained"
                      color="primary"
                      mb={2}
                      disabled={locked || thinking}
                    >
                      Next
                    </Button>
                  )
                }
                {
                  variant === 'onboarding' && kind === 'competitors' && (
                    <Button
                      onClick={onSubmit}
                      variant="contained"
                      color="primary"
                      mb={2}
                      disabled={locked || thinking || credits.creditLimit}
                    >
                      Save and Run Search
                    </Button>
                  )
                }
              </>
            )
          }
        </Grid>
      </Grid>
    </ActionsHolder>
  )
}

export default Actions
