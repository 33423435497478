import numbro from 'numbro'
// @ts-ignore
import moment from 'moment'
import config from '../config'

numbro.registerLanguage(config.LANGUAGE)
numbro.setLanguage(config.LOCALE)

export function formatNumber(number: number | 'N/A') {
  if (number === 'N/A') {
    return number
  }

  if (isNaN(number) || !number) {
    return 0
  }
  return numbro(number).format(config.NUMBER_FORMAT)
}

export function formatCurrency(number: number) {
  return numbro(number).formatCurrency(config.NUMBER_CURRENCY_FORMAT)
}

export function formatPercent(number: number) {
  return numbro(number).format(config.NUMBER_PERCENT_FORMAT)
}

export const formatDateShort = (date: string) =>
  moment(date).format(config.DATE_FORMAT)

export function formatDateFull(date: string) {
  return moment(date).format(config.DATE_MONTH_FORMAT)
}

export function formatDate(date: string) {
  return moment(date).format(config.DATE_PERIOD_FORMAT)
}

export function formatDateTime(date: string) {
  return moment(date).format(config.DATE_TIME_FORMAT)
}

export function formatDateFromNow(date: string) {
  return moment(date).startOf(config.DATE_READABLE_FORMAT).fromNow()
}

function formatWithCleaning(number: number) {
  const str = numbro(number).format(config.NUMBER_READABLE)
  if (str.length > 5) {
    // E.g: 112.3k is 6 chars and 12.3k is 5 chars
    const groups = str.split('.')
    if (groups[0].length >= 3)
      return numbro(number).format(config.NUMBER_READABLE_SHORTER)
    if (groups[0].length === 2)
      return numbro(number).format(config.NUMBER_READABLE_SHORT)
  } else if (str.includes('.')) {
    return str.replace(/0(?=\w)/, '')
  }
  return str
}

export function formatToReadableNumber(
  number: number,
  limit?: boolean
): string | number {
  if (isNaN(number) || !number) return 0
  if (number < 1000) return Math.round(number)
  if (limit) return formatWithCleaning(number)
  return numbro(number).format(config.NUMBER_READABLE)
}
