import React, { useContext } from 'react'

import { DetailsContext } from '../DetailsContext'
import WidgetScore from '../../Widget/WidgetScore'
import WidgetMetric from '../../Widget/WidgetMetric'
import Actions from '../../Actions'
import {lead} from "../../Widget/WidgetsLayout/WidgetsLayout.stories";

export default function ProfileAside (props) {
    const { item, variant, metrics, kind, contacts, onUpdateItem, loading } = useContext(DetailsContext)
    const isIntelligence = ['gap', 'landscape', 'partnerships'].includes(variant)

    const onClick = (id, action, direction) => {
        props.onQuickActions(id, action, direction)
        onUpdateItem(action)
    }

    return <>
        { !isIntelligence && <WidgetScore value={metrics.breezyScore} variant={variant === 'lead' ? 'score' : 'avgScore'} /> }
        <WidgetMetric value={metrics.swGlobalRank} variant="rank" />
        <WidgetMetric value={metrics.domainAuthority} variant="moz" />
        <Actions {...props} onClick={onClick} item={item} variant={variant} contacts={contacts} kind={kind} loading={loading} vertical />
    </>
}
