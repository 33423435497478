import React from 'react'
import { find } from 'lodash'
import { inject, observer } from 'mobx-react'
import AddMoreCreditsModal from './AddMoreCreditsModal'
import BillingEndTrial from '../Billing/_BillingEndTrial'

@inject(({ BrandStore, SearchesStore, SearchStore }) => ({
  role: BrandStore.role,
  plan: BrandStore.plan,
  brandId: BrandStore.id,
  credits: BrandStore.credits,
  billingStatus: BrandStore.billingStatus,
  onShowPlans: () => BrandStore.showPlansModal(),
  onUpdateCredits: details => BrandStore.updateCounters(details)
}))
@observer
export default class _AddMoreCreditsModal extends React.Component {
  state = { thinking: false }

  render () {
    const {
      plan,
      credits,
      brandId,
      billingStatus,
      onUpdateCredits
    } = this.props

    if (!open) {
      return null
    }

    const billingProps = {
      plan,
      credits,
      brandId,
      billingStatus,
      onUpdateCredits
    }

    return <AddMoreCreditsModal
      {...this.props}
      billingProps={billingProps}
      billingEndTrial={BillingEndTrial}
    />
  }
}
