import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import ToolbarMui from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import {Cog} from "../Icons/Cog"
import {Settings} from "react-feather"
import styled from "styled-components"
import {Tooltip, Typography, IconButton} from "@material-ui/core"
import {BreezyLogo} from "../Icons/BreezyLogo"
import CreditsDisplay from "../CreditsDisplay"

const ButtonSetting = styled(IconButton)`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 36px;
  width: 36px;
`;

const Toolbar = styled(ToolbarMui)`
  display: flex;
  justify-content: space-between;
  background: white;
  border-bottom: 1px solid #eee;
  min-height: 56px;
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Navigation = styled.div`
  display: flex;
  height:100%;
  column-gap: 1.5em;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  z-index: 1;
  right: 0;
  padding-left: 1.5em;
  background: linear-gradient(
          -90deg, white 98%, rgba(255, 255, 255, 0) 100%);
  overflow: hidden;
`

const AppBarNew = ({
                     compact,
                     title,
                     menu: Menu,
                     credits,
                     role,
                     inactive,
                     billingStatus,
                     onSettings,
                     onPurchase,
                     onPlans
                   }) => (

  <Toolbar variant={'dense'}>
    <Wrapper>
      {title}
    </Wrapper>

    <Navigation>
      <CreditsDisplay
        role={role}
        creditsLeft={credits}
        inactive={inactive}
        billingStatus={billingStatus}
        onPurchase={onPurchase}
        onPlans={onPlans}
      />
      <Tooltip title={'Settings'}>
        <ButtonSetting onClick={onSettings} size={'small'}>
          <Settings size={18} strokeWidth={1} />
        </ButtonSetting>
      </Tooltip>
      <Menu onOpenSettings={onSettings}/>
    </Navigation>
  </Toolbar>
)

export default AppBarNew
