import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { CssBaseline } from '@material-ui/core'
import Loader from '../components/Loader'
import Helmet from 'react-helmet'


const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Auth = ({ children, title, thinking, ...other }) =>  {
  return (
    <Root>
      <Helmet title={title} />
      <CssBaseline />
      <GlobalStyle />
      { !thinking && children }
      { thinking && <Loader /> }
    </Root>
  )
}

export default Auth
