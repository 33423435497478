import { find } from 'lodash'
import { gql } from '@apollo/client'
import GqlClient from './gql-client'
import { GET_CHANNELS_TAGS } from './tags'
import { GET_CONTACTS_STATUS } from './contacts'

export const CHANNEL_KINDS = gql`
  query ChannelKinds($brandId: String!, $channelIds: [String!]!) {
    prospectKindsOfChannels(brandId: $brandId, channelIds: $channelIds) {
      channelId
      prospectKind
    }
  }
`

export const CHANNELS_CLASSIFICATION = gql`
  query classificationsByChannels($channelIds: [String!]!) {
    classificationsByChannels(channelIds: $channelIds) {
      classifications {
        id
        level
        name
      }
      channelId
    }
  }
`

export const channelsSystemTags = async (channels) => {
  let result = channels
  const channelIds = channels.map(({ channelId }) => channelId)

  const { success, ...rest } = await GqlClient.query({
    query: CHANNELS_CLASSIFICATION,
    variables: { channelIds },
  })

  if (success) {
    const classifications = rest.data['classificationsByChannels']

    result = channels.map((channel) => {
      const c = find(classifications, ['channelId', channel.channelId])
      if (c) {
        return { ...channel, systemTags: c.classifications }
      }
      return channel
    })
  }

  return result
}

export const channelsKind = async (channels, brandId) => {
  let result = channels
  const channelIds = channels.map(({ channelId }) => channelId)
  const variables = { brandId, channelIds }

  if (channelIds.length) {
    const { success, ...rest } = await GqlClient.query({
      query: CHANNEL_KINDS,
      variables,
    })
    if (success) {
      const kinds = rest.data['prospectKindsOfChannels']
      result = channels.map((channel) => {
        const channelKinds = find(kinds, ['channelId', channel.channelId])
        if (channelKinds) {
          return { ...channel, kind: channelKinds.prospectKind }
        }
        return channel
      })
    }
  }
  return result
}

export const channelsTags = async (channels, brandId) => {
  let result = channels
  const channelIds = channels.map(({ channelId }) => channelId)
  const variables = { brandId, channelIds }

  if (channelIds.length) {
    const { success, ...rest } = await GqlClient.query({
      query: GET_CHANNELS_TAGS,
      variables,
    })

    if (success) {
      const tags = rest.data['tagsForChannels']
      result = channels.map((channel) => {
        const channelTags = find(tags, ['channelId', channel.channelId])
        if (channelTags) {
          return {
            ...channel,
            tags: channelTags.tags.map(({ id, name }) => ({ id, label: name })),
          }
        }
        return channel
      })
    }
  }

  return result
}

export const channelsContactStatus = async (channels, brandId) => {
  let result = channels
  const channelIds = channels.map(({ channelId }) => channelId)
  const variables = { brandId, channelIds }

  if (channelIds.length) {
    const { success, ...rest } = await GqlClient.query({
      query: GET_CONTACTS_STATUS,
      variables,
    })

    if (success) {
      const tags = rest.data['contactRequests']
      result = channels.map((channel) => {
        const channelContact = find(tags, ['channelId', channel.channelId])
        if (channelContact) {
          return { ...channel, contactStatus: channelContact.status }
        }
        return channel
      })
    }
  }

  return result
}
