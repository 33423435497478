import React from 'react'
import {ThumbsUp, ThumbsDown, Zap, Users, X, UserPlus} from 'react-feather'


export type ActionKeysBase = 'relevant' | 'irrelevant' | 'competitor' | 'partner' | 'remove' | 'request-contact'
export interface ActionProps {
    name: string
    title: string
    multiple?: string
    icon: React.ComponentType
}

const actions:{[key:string]:ActionProps} = {
    relevant: {
        name: 'Relevant',
        title: 'Add to Relevant',
        multiple: 'Relevant',
        icon: ThumbsUp
    },
    irrelevant: {
        name: 'Irrelevant',
        title: 'Add to Irrelevant',
        icon: ThumbsDown
    },
    competitor: {
        name: 'Competitor',
        title: 'Add to Competitors',
        icon: Zap
    },
    partner: {
        name: 'Partner',
        title: 'Add to Partners',
        multiple: 'Partners',
        icon: Users
    },
    remove: {
        name: 'Remove',
        title: 'Remove',
        icon: X
    },
    'request-contact': {
        name: 'Request contacts',
        title: 'Request contacts',
        icon: UserPlus
    }
}

export default actions
