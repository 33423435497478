import React, {useContext} from 'react'
import { findIndex } from 'lodash'
import {useParams} from 'react-router-dom'

import {CreatorRequestContext} from '../CreatorRequestDetailsContext'
import AppearsInEcosystems from '../../Profile/AppearsInEcosystems'


export default function CreatorRequestProfileAppearsInEcosystems () {
    // @ts-ignore
    const { brandId } = useParams()
    const { loading, ecosystems, summary, brandEcosystems } = useContext(CreatorRequestContext)

    const availableEcosystems = ecosystems.map(({ ecosystemId, ...rest }) => {
        return {
            ...rest,
            id: ecosystemId,
            disabled: findIndex(brandEcosystems, ['id', ecosystemId]) < 0
        }
    })

    const brandEcosystemsList = availableEcosystems.filter(({ disabled }) => !disabled)
    const publishedEcosystems = availableEcosystems.filter(({ disabled, published }) => disabled && published)


    return <AppearsInEcosystems loading={loading} limit={5} data={[...brandEcosystemsList, ...publishedEcosystems]} to={`/brand/${brandId}/intelligence/landscape`} type={summary.type} />
}
