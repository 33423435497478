const fallbackCopyTextToClipboard = (text) => new Promise (resolve => {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    document.body.removeChild(textArea)
    resolve(!!successful)
  } catch (err) {
    document.body.removeChild(textArea)
    resolve(false)
  }
})

const copyTextToClipboard = (text) => new Promise ( async (resolve) => {
  if (!navigator.clipboard) {
    const success = await fallbackCopyTextToClipboard(text)
    return resolve(success)
  }
  navigator.clipboard.writeText(text).then(function() {
    return resolve(true)
  }, function(err) {
    return resolve(false)
  })
})

export default copyTextToClipboard
