import React, { useContext, useImperativeHandle, forwardRef } from 'react'

import CreatorRequestDetailsProvider from './CreatorRequestDetailsContext'
import DetailsSidebar, { TabPanel, Content } from '../DetailsSidebar'
import CreatorRequestDetailsView from './DetailsView/CreatorRequestDetailsView'
import CreatorRequestProfileView from './ProfileView/CreatorRequestProfileView'


const CreatorRequestDetails = forwardRef((props: any, ref: any) => {

    useImperativeHandle(ref, () => ({ }))

    return <CreatorRequestDetailsProvider {...props}>
        <DetailsSidebar open={props.open} variant={'creator'} onClose={props.onClose}>
            {
                ({tab, expanded, onSetViewState}) => {
                    return (
                        <>
                            <TabPanel value={tab} index={'collapsed'}>
                                <Content>
                                    <CreatorRequestDetailsView onQuickActions={console.log} onSetViewState={onSetViewState} />
                                </Content>
                            </TabPanel>
                            <TabPanel value={tab} index={'profile'}>
                                <Content>
                                    <CreatorRequestProfileView variant={'creator'} onQuickActions={console.log} onSetViewState={onSetViewState} />
                                </Content>
                            </TabPanel>
                        </>
                    )
                }
            }
        </DetailsSidebar>
    </CreatorRequestDetailsProvider>
})

export default CreatorRequestDetails
