import React from 'react'
import {Finish} from '../Icons/Finish'
import {CheckEmail} from '../Icons/CheckEmail'
import Button from '../Button'
import styled from 'styled-components'


const Root = styled.div`
  padding: 50px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 24px;
    line-height: 28px;
    color: #000021;
    margin: 0;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #000021;
    margin: 16px 0 16px 0;
  }
  .email {
    margin: 16px 0 0 0;
    color: #0163FA;
  }
`;

export const SuccessRecoverPassword = (props) => {
  const {
    header,
    subHeader,
    counter,
    onClick
  } = props

  return (
    <Root>
      <Finish/>

      <h3>{header}</h3>

      <p>{subHeader}</p>

      <Button
        variant="contained"
        color="primary"
        mb={2}
        onClick={onClick}
      >
        To the App in {counter} seconds...
      </Button>
    </Root>
  )
}

export default class Success extends React.Component {
  render () {
    const {
      header,
      subHeader,
      onClick,
      forgotPasswordSuccess,
      email
    } = this.props

    return (
      <Root>
        {
          forgotPasswordSuccess ? <CheckEmail /> : <Finish/>
        }
        <h3>{header}</h3>
        {
          forgotPasswordSuccess && <p className={'email'}>The email was sent to {email} </p>
        }

        <p>{subHeader}</p>

        <Button
          variant="contained"
          color="primary"
          mb={2}
          onClick={onClick}
        >
          Change
        </Button>
      </Root>
    )
  }
}
