import React from 'react'
import styled, { css }  from 'styled-components'
import chroma from 'chroma-js'
import { Tooltip } from '@mui/material'
import { SkeletonPlaceholder } from '../../../components/SkeletonPlaceholder'

import Config from '../../../config'
import {sumBy} from "lodash";

const Root = styled.div`
  grid-column: span 2;
`
const ChartTitle = styled.div`
  font-size: 1.0rem;
  font-weight: 600;
  padding-bottom: 6px;
`

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10px;
  ${props => !props.loading && css`
    div:first-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 10px 0 0 10px;
    }
    div:last-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 0 10px 10px 0;
      background-color: ${props => props.theme.palette.grey['200']};
    }
  `}
  
`

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* top | right | bottom | left */
  padding: 8px 0 14px 0;
  div:last-child > span {
    background-color: ${props => props.theme.palette.grey['200']};
  }
`

const ChartItemContainer = styled.div`
  height: 10px;
  width: ${props => `${props.value }%`};
  background: ${props => props.color};
`

const LegendItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
  margin-right: 15px;
  strong {
    margin-right: 5px;
  }
`

const Dot = styled.span`
  height: 10px;
  width: 10px;
  background: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`

interface RowItemProps {
  title: string
  value: string
  color: string
  percentage?: boolean
}

function RowItem ({ title, value, color, percentage }: RowItemProps) {
  return <Tooltip title={<span><strong>{title}</strong>&nbsp;&nbsp;{value}{percentage ? '%' : ''}</span>}>
    <ChartItemContainer value={value} color={color}/>
  </Tooltip>
}

interface LegendItemProps extends RowItemProps {}

function LegendItem ({ title, value, color, percentage }: LegendItemProps) {
  return <Tooltip title={`${title}: ${value}${percentage ? '%' : ''}`}>
    <LegendItemContainer>
      <Dot color={color} />
      <strong>{title}</strong>{value}{percentage ? '%' : ''}
    </LegendItemContainer>
  </Tooltip>
}

const normalizeData = (data, limit = 1, percentage) => {
  let chartValue = 0
  let limitCount = 0
  let isGiantNetwork = false

  let normalValues = data.filter((item, index) => {
    const value = percentage ? parseInt(Math.floor(item.value)) : item.value
    if (value >= 1 && limitCount < 5 && index < data.length) {
      limitCount += 1
      chartValue += value
      return true
    }
    return false
  }).map((item) => ({ ...item, value: percentage ? parseInt(Math.floor(item.value)) : item.value }))

  let chart = normalValues

  if (normalValues.length === 1 && normalValues[0].value < 100 && normalValues[0].value > 99 && data.length > 1) {
    isGiantNetwork = true
  }

  if (sumBy(normalValues, 'value') < 100) {
    const value = percentage ? (100 - chartValue).toFixed(0) : chartValue
    chart.push({id: 'other', title: 'Other', value: isGiantNetwork ? 1 : value })
  }

  chart = chart.map(item => {
    let value = isGiantNetwork && item.value > 99 && item.value < 100 ? 99 : item.value
    return { ...item, value }
  }).filter(({ value }) => value !== '0')

  return {
    chart
  }
}

interface  StackedRowChartProps {
  percentage?: boolean
  loading?: boolean
  title?: string
  colorScheme?: Array<string>
  data: Array<{ id: string, title: string, value: number }>
}

export default function StackedRowChart (props: StackedRowChartProps) {
  const { data, colorScheme, loading, percentage } = props
  const { chart } = normalizeData(data, 1, percentage)
  const steps = chart.length
  const colors = chroma.scale(colorScheme || Config.SCORE_COLORS).colors(steps)

  return <Root>
    {/*<ChartTitle>{title}</ChartTitle>*/}

    <ChartContainer loading={loading}>
      { loading && <SkeletonPlaceholder height={'16px'} width={'500px'} color={'#93a5c2'}  highlightColor={'#a8bcdc'}/> }
      {
        !loading && chart.map(({ id, title, value }, index) => <RowItem key={id} title={title} value={value} color={colors[index]} percentage={percentage} />)
      }
    </ChartContainer>


    <LegendContainer>
      {
        !loading && chart.map(({ id, title, value }, index) => (
          <LegendItem key={id} color={colors[index]} title={title} value={value} percentage={percentage} />
        ))
      }
      {
        loading && <SkeletonPlaceholder height={'17px'} width={'200px'} color={'#93a5c2'}  highlightColor={'#a8bcdc'}/>
      }
    </LegendContainer>
  </Root>
}

StackedRowChart.defaultProps = {
  data: [],
  title: 'Network distribution'
}
