import React, { useContext } from 'react'
import { ContactDetailsContext } from '../ContactDetailsContext'
import Contacts from '../../Contacts'
import Summary from '../../Summary'

interface ContactsViewProps {
  tags: { id: number; name: string }
  onSetViewState: Function
  onQuickActions?: () => void
  onManageTags?: (obj: object, action: string) => void
}

export default function ContactsDetailsView(props: ContactsViewProps) {
  const { loading, contacts, summary } = useContext(ContactDetailsContext)

  const onRequest = () => {}

  return (
    <>
      <Summary
        loading={loading}
        {...summary}
        tags={props.tags}
        systemTags={props.systemTags || []}
        horizontal={false}
        tagManagement={{
          onUpdate: (tag) => props.onManageTags({ tag }, 'update'),
          onRemove: (tagId, channelId) =>
            props.onManageTags({ channelId, tagId }, 'remove'),
          onDelete: (tagId) => props.onManageTags({ tagId }, 'delete'),
          onManage: (e, channelId) =>
            props.onManageTags({ channelId: summary.id, e }, 'open'),
        }}
      />

      <Contacts
        loading={loading}
        status={'found'}
        contacts={contacts.contactInfos || []}
        entity={contacts.entityDetails || null}
        onRequest={onRequest}
      />
    </>
  )
}
