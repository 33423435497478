function obfuscateString (str = '') {
  const n = 126
  const key = 1
  const excludedChars = /[\s.\/-]/g

  if (!str) return str

  let chars = str.toString().split('')



  for (let i = 0; i < chars.length; i++) {
    let c = chars[i].charCodeAt(0)

    if (c <= n) {
      if (!chars[i].match(excludedChars)) chars[i] = String.fromCharCode((chars[i].charCodeAt(0) + key) % n)
    }
  }

  return chars.join('')
}

export default obfuscateString
