import React, {useEffect, useState, useRef, createRef} from 'react'
import scrollStartStopHandler from '../../utils/scrollStartStopHandler'
import getScrollParent from '../../utils/getScrollParent'

const hideAnimation = `
  @keyframes br-hideOnScrollAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
`
const showAnimation = `
  @keyframes br-showOnScrollAnimation {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const stickyStyle = {
    background: 'white',
    position: 'sticky',
    top: 0,
    // boxShadow: '0 0 10px rgba(0,0,0,0.1)'
}

interface HideOnScrollProps {
  children?: React.ReactElement
}

function HideOnScroll ({ children }: HideOnScrollProps) {
    const [isVisible, setIsVisible] = useState(true)
    const [isSticky, setSticky] = useState(false)
    const scrollTopRef = useRef<number>(0);
    const childRef = createRef<HTMLElement>()

    useEffect(() => {

        let parent: HTMLElement | null = getScrollParent(childRef.current)

            // scrollStartStopHandler(parent, () => isSticky && setIsVisible(false), () => {
            //     !isSticky && setIsVisible(true)
            // })

            parent.addEventListener('scroll', () => {
                requestAnimationFrame(() => {
                    scrollTopRef.current = parent.scrollTop;
                    scrollTopRef.current > 40 ? setSticky(true) : setSticky(false)
                })
            })
            parent.removeEventListener('scroll', () => {})
    })

    // useEffect(() => {
    //     const styleSheet = document.styleSheets[0] as CSSStyleSheet
    //     styleSheet.insertRule(hideAnimation, styleSheet.cssRules.length)
    //     styleSheet.insertRule(showAnimation, styleSheet.cssRules.length)
    // })

    return React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return child
        }
        const childStyle = {
            animation: `${isSticky && ((isVisible ? 'br-showOnScrollAnimation' : 'br-hideOnScrollAnimation') + ' ' + '0.3s ease-out forwards')}`,
            // position: `${isSticky ? 'sticky' : 'relative'}`,
            // top: 0
            ...isSticky && stickyStyle
        };

        return React.cloneElement(child, {
            // @ts-ignore
            style: {...child.props.style, ...childStyle},
            // children,  //: <>{isSticky ? 'sticky' : 'not sticky'} |  {isVisible ? 'visible' : 'not visible'}</>,
            ref: childRef
        });
    });
}


HideOnScroll.defaultProps = {
  children: 'It\'s a me, HideOnScroll!'
}

export default HideOnScroll
