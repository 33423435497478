import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Table, TableHead as TableHeadMUI, TableBody, TableRow, TableCell,
  TableSortLabel, Typography, Tooltip as TooltipMUI
} from '@material-ui/core'
import { sumBy } from 'lodash'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import Pane from '../../components/Details/Pane'
import CountryFlag from '../../components/CountryFlag'
import { find, orderBy as lodashOrderBy } from 'lodash'
import { CountriesFlag } from '../../components/Leads/CountriesFlag'
import {formatDate, formatToReadableNumber } from '../../utils/format'
import {SimilarWeb} from '../../components/Icons/SimilarWeb'


const imageKitUrl = process.env.IMAGEKIT_URL

const countries = (data, order, orderBy) => {
  const countries = data.map(({iso, ...rest}) => {
    const country = find(CountriesFlag, ['value', iso])
    if (country) {
      return { country: country.label, iso, ...rest}
    }
    return {iso, ...rest}
  })

  return lodashOrderBy((countries || []), [orderBy], [order])
}

const emptyData = (data, field) => {
  let empty = true
  data.forEach(({ [field]: value }) => {
    if (empty && Boolean(value)) {
      empty = false
    }
  })
  return empty
}

const fixedTo = function (number, n) {
  const k = Math.pow(10, n);
  return (Math.round(number * k) / k);
}

/////////////////////////////////////////////
const FooterContainer = styled.div`
  height: 25px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  border-top: 1px solid #f8faff;
  margin: 0 -15px -10px -15px;
  position: relative;
`

const FooterSource = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  opacity: 0.7;
  padding: 0 30px;
  flex: 1 1 50px;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  height: 25px;
  a {
    text-decoration: none;
    color: black;
  }
`

const FooterDate = styled.div``

const Footer = ({ date }) => {
  return <FooterContainer>
    <FooterSource>
      <a href='https://www.similarweb.com' target="_blank">Insights by&nbsp;&nbsp;<SimilarWeb/></a>
    </FooterSource>
  </FooterContainer>
}

/////////////////////////////////////////////
const TrafficContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const TrafficLegendContainer = styled.ul`
  list-style: none;
  flex: 1 0 auto;
  padding-left: 1em;
`

const TrafficLegendItem = styled.li`
  display: flex;
  align-items: center;font-size: 0.9em;
  &:before  {
    content: ''; 
    background: ${props => props.color };
    display: inline-block; 
    width: 1em;
    height: 1em;
    border-radius: 2em;
    margin-left: -1em;
    margin-right: 1em;
  }
`

const TrafficDoughnutContainer = styled.div`
  width: 200px;
  height: 200px;
`

ChartJS.register(ArcElement, Tooltip)

const combineTrafficData = (data) => {
  const labels = data.map(({ iso, code }) => {
    let label = 'Unknown country'
    let countryData = find(CountriesFlag, ['value', iso || code])
    return countryData ? countryData.label : label
  })

  return {
    labels,
    data: data.map(({ share }) => fixedTo(share * 100, 2)),
    colors: [
      '#65FDD8',
      '#65FD99',
      '#BAFD65',
      '#FDF765',
      '#FDD265',
      '#ffb395',
      '#fc8c8c',
      '#f972a4ff',
      '#ff84d4',
      '#c798fd',
      'rgb(44,56,71)'
    ]
  }
}

export const Top10CountriesByTraffic = (props) => {
  const countries = props.data.slice(0, 10);
  const combinedData = combineTrafficData(countries)

  const otherShare = Math.round(100 - sumBy(countries, 'share') * 100)
  const labels = [...combinedData.labels, ...(otherShare ? ['Other'] : [])]
  const data = [...combinedData.data, ...(otherShare ? [otherShare] : [])]
  const colors = combinedData.colors
  const emptyShare = emptyData(countries, 'share')

  return <Pane empty={data.length === 0 || emptyShare} emptyMessage={'No country data is available yet'}>
    <TrafficContainer>
      <TrafficLegendContainer>
        {
          labels.map((label, index) => <TrafficLegendItem color={colors[index]}>{label} - {data[index]}%</TrafficLegendItem>)
        }
      </TrafficLegendContainer>
      <TrafficDoughnutContainer>
        <Doughnut
          data={{
            labels,
            datasets: [{
              data,
              backgroundColor: colors
            }]
          }}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              labels: {
                render: 'percentage',
                precision: 2
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const { label, parsed } = tooltipItem
                    return ` ${label} - ${parsed}%`
                  },
                  title: function () {
                    return null
                  }
                }
              }
            }
          }}
        />
      </TrafficDoughnutContainer>
    </TrafficContainer>
    <Footer />
  </Pane>
}

/////////////////////////////////////////////
const Country = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  span {
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
  }
`;

const CountryLabel = ({ iso }) => {
  let label = 'Unknown country'
  let countryData = find(CountriesFlag, ['value', iso])
  if (countryData) {
    label = countryData.label
  }
  return (
    <Country>
      <CountryFlag
        country={iso}
        alt={label}
        fallbackImageURL={`${imageKitUrl}/flags/${iso}.svg`}
      />
      {label}
    </Country>
  )

}

/////////////////////////////////////////////
const TableCellBody = styled(TableCell)`
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 5px 15px;
  height: 25px;
`;

const TableCellHead = styled(TableCell)`
  font-size: 12px;
  line-height: 12px;
  border: none;
  padding: 5px 15px;
  font-weight: normal;
  background: #E9F3FE;
  &:first-child{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  & .MuiTableSortLabel-root {
    opacity: 0.5;
    font-weight: normal;
  }
`;

const TableHead = styled(TableHeadMUI)`
  margin-bottom: 1em;
`

const TOP_COUNTRIES_COLUMNS = [
  { key: 'country', label: 'Country', align: 'left' },
  { key: 'pageViews', label: 'Visitors', align: 'left' },
  { key: 'rank', label: 'Rank', align: 'right' }
]

export const Top10CountriesByVisitors = (props) => {
  const data = props.data.slice(0, 10)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('users')

  const emptyRank = emptyData(data, 'rank')
  const emptyPageViews = emptyData(data, 'visits')


  return <Pane empty={data.length === 0 || (emptyRank && emptyPageViews)} emptyMessage={'No country data is available yet'}>
    <Table aria-label="Countries table">
      <TableHead>
        <TableRow>
          {
            TOP_COUNTRIES_COLUMNS.map(({ key, label, align, updated }) => (
              <TooltipMUI key={key} title={updated ? `Updated ${formatDate(updated)}` : label}>
                <TableCellHead align={align}>
                  {/*{ label }*/}
                  {/*active={orderBy === key}*/}
                  {/*// direction={orderBy === key ? order : 'asc'}*/}
                  {/*// onClick={onSort(key)}*/}
                  <TableSortLabel hideSortIcon>
                    { label }
                  </TableSortLabel>
                </TableCellHead>
              </TooltipMUI>
            ))
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          countries(data, order, orderBy).map(country => (
            <TableRow key={country.iso}>
              <TableCellBody><CountryLabel iso={country.iso} /></TableCellBody>
              <TableCellBody>{formatToReadableNumber(Math.round(country.visits))}</TableCellBody>
              <TableCellBody align={'right'}>{formatToReadableNumber(country.rank)}</TableCellBody>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
    <Footer />
  </Pane>
}
