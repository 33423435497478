import React from 'react'
import { inject, observer } from 'mobx-react'
import Blocker from './Blocker'


const _Blocker = inject(({ BrandStore }) => ({
  brandId: BrandStore.id,
  inactive: BrandStore.inactive,
  onShow: () => BrandStore.showPlansModal()
}))(observer(props => {
  if (!props.inactive) return null
  return <Blocker brandId={props.brandId} onShow={props.onShow}/>
}))

export default _Blocker
