import React from 'react'
import styled from 'styled-components'
import BillingCredits from '../Billing/BillingCredits'
import BillingPlans from '../Billing/BillingPlans'
import BillingCancelSubscription from '../Billing/BillingCancelSubscription'
import Divider from '../Divider'
import { AssistedLockContent } from '../../new-components/Modals/AssistedLockModal'
import config from '../../config'


const Root = styled.div`
  margin: 0 50px 0 50px;
`

const LockRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 20px 10px;
`

const Header = styled.p`
  font-weight: 400;
  font-size: 24px;
  width: 100%;
`

const Heading = styled.p`
  font-weight: 400;
  font-size: 18px;
  width: 100%;
`

const Credits = (props) => {
  const plansTitle = () => {
    if (!props.plan) return null

    const { plans } = config

    const current = plans[props.plan]

    const title = `Your plan is ${current.name} ${current.period}ly`

    const inTrial = props.billingStatus === 'inTrial'
    const trialExpired = props.trialDaysLeft > 0

    if (trialExpired && inTrial) {
      return `${title}, you have ${props.trialDaysLeft} day${props.trialDaysLeft > 1 ? 's' : ''} left in your trial.`
    }
    return `${title}.`
  }

  if (props.assistedLock) {
    return <LockRoot><AssistedLockContent /></LockRoot>
  }

  return (
    <Root>

      <Header>You have <strong>{props.credits}</strong> credits left</Header>

      <Heading>


        Credits are spent for each search on inputs like <strong>keywords</strong> and <strong>competitors</strong> and
        also on requesting  <strong>contacts in Outreach</strong>.

        {
          props.billingStatus === 'inTrial' ? <>
              To purchase more credits you need to switch to a <strong>Paid plan</strong> which already includes <strong>200</strong> credits
              on a monthly or <strong>600</strong> credits on a quarterly plans respectively.
          </>
            : <>
              Our Paid plans already include <strong>200</strong> credits per month or <strong>600</strong> per quarter respectively.
            </>
        }





      </Heading>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '3em'}}>
        <BillingCredits {...props} disabled={props.billingStatus === 'inTrial'}/>
      </div>
      <Divider mb='2em'/>
      <BillingPlans
        {...props}
        noSubTitle
        title={plansTitle()}
      />
      {
        ['active', 'non_renewing'].includes(props.chargebeeStatusProps.status) && <>
          <Divider mb='2em'/>
          <div style={{textAlign: 'center'}}>
            {props.chargebeeStatusProps.status === 'active' && <BillingCancelSubscription {...props} /> }
            <p style={{fontSize: 16, marginTop: '1em'}}>
              {
                props.chargebeeStatusProps.status === 'non_renewing' ?
                  `Your subscription will expire on ${props.chargebeeStatusProps.cancelledAt}`
                  :
                  'Your subscription will be canceled in the end of your current billing circle.'
              }
            </p>
          </div>
        </>
      }
    </Root>
  )
}

export default Credits
