import React from 'react'
import styled from 'styled-components'
import {Checkbox as MuiCheckbox} from '@material-ui/core'

const CheckboxRoot = styled(MuiCheckbox)`
  color: ${props => props.theme.palette.brand.shadow.dark};
  padding: 5px;
  .MuiSvgIcon-root {
  width: 22px;
  height: 22px;
  }
`

const CheckBox = (props)  => {
  return (
    <CheckboxRoot {...props} />
  )
}

export default CheckBox
