import React from 'react'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import AuthLayout from '../../layouts/Auth'
import Auth from '../../layouts/Auth'

@inject(stores => ({
  user: stores.UserStore.user,
  authorized: stores.UserStore.authorized,
  checkAuthorization: stores.UserStore.checkAuthorization
}))
@observer
export default class PrivateRoute extends React.Component {
  render () {
    const {
      layout: Layout,
      user,
      title,
      component: Component,
      authorized,
      checkAuthorization
    } = this.props

    if (!authorized && !checkAuthorization) {
      return <Redirect to={{ pathname: '/signin' }} />
    }

    if (checkAuthorization && !Layout) {
      return <AuthLayout thinking />
    }

    return Layout ? (
      <Layout thinking={checkAuthorization} title={title} user={user} >
        <Component {...this.props} />
      </Layout>
    ) : <Component {...this.props} />
  }
}
