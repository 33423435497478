import React from 'react'
import styled from 'styled-components'
import {Globe} from 'react-feather'
import MuiImage from 'material-ui-image'
import cleanUrl from '../../utils/cleanUrl'

const imageKitUrl = process.env.IMAGEKIT_URL || 'https://ik.imagekit.io/afill/staging'
const retina = window.devicePixelRatio > 1 // TODO: Optimize this by moving to the app level
const imageWidth = 16

export const Thumbnail = styled.div`
  width: ${imageWidth}px;
  height: auto;
  margin-right: 0.5em;
  flex: 0 0 ${imageWidth}px;
  filter: saturate(0);
  transition: filter 0.3s ease;
  display: flex;
  align-items: center;
  & > div{
    flex: 1 1 auto;
  }
`
const Loader = styled(Globe)`
  width: ${props => props.size || '16px'};
  height: ${props => props.size || '16px'}; 
  color: ${props => props.color || props.theme.palette.grey[300]};
`
function thumbnail(URL) {
  const imagePath = cleanUrl(URL, true)
  return imagePath ? `${imageKitUrl}/${imagePath}?tr=w-${retina ? imageWidth * 2 : imageWidth}` : ''
}

const isImageLoaded = src => {
  const img = new Image()
  img.src = src
  const complete = img.complete
  img.src = ''
  return complete
}

const Icon = ({item, size, color}) => {
  const src = item.icon && item.icon !== 'missing' ? thumbnail(item.icon) : undefined
  const isLoaded = src ? isImageLoaded(src) : true

  return (
    <Thumbnail>
      {
        src ? (
          <MuiImage
            src={src}
            loading={!isLoaded && <Loader size={size} color={color}/>}
            errorIcon={<Loader size={size} color={color}/>}
            disableTransition
          />
        ) : <Loader size={size} color={color}/>
      }

    </Thumbnail>
  )
}


export default Icon
