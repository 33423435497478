function cleanUrl(URL, pathOnly) {
  let result = ''
  if (URL) {
    result = URL.replace(/(^\w+:|^)\/\//, '')
    if (pathOnly) {
      result = result.replace(/(.*?\/)/, '')
    }
  }
  return result
}

export default cleanUrl
