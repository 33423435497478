import React from 'react'
import styled from 'styled-components'
import { Refresh } from '@material-ui/icons'
import { Button } from '@material-ui/core'

const RerunDiscovery = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  padding: 0.5em 3em;
  border-radius: 10px;
`

const Label = styled.div`
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.7em;
    padding: 0.15em 0.2em;
    line-height: 1em;
    border-radius: 3px;
    color: ${props => props.theme.palette.error.main};
    border: 1px solid ${props => props.theme.palette.error.main};
    margin-right: 1.5em;
  
`

const ReRun = ({ onReRun }) => (
  <RerunDiscovery>
    <Label>Changes</Label>
    Search settings are changed. Re-run Search to get new leads.
  </RerunDiscovery>
)

export default ReRun
