import React, {useContext} from 'react'

import {DetailsContext} from '../DetailsContext'
import AppearsInSearches from '../../Profile/AppearsInSearches'
import {useParams} from "react-router-dom";

export default function ProfileAppearsInSearches () {
    // @ts-ignore
    const { brandId } = useParams()
    const { loading, searches } = useContext(DetailsContext)

    if (!loading && !searches.length) return null

    return <AppearsInSearches loading={loading} searches={searches} to={`/brand/${brandId}/discovery`}/>
}
