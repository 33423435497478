import { observable, action, computed, toJS } from 'mobx'
import param from 'can-param'
import { unionBy } from 'lodash'
import UniversalCookie from 'universal-cookie'

import GqlClient from '../gql/gql-client'
import {
  channelsTags,
  channelsContactStatus,
  channelsSystemTags,
} from '../gql/channels'
import { Put } from '../utils/request'
import hasError from './request-message'
import Config from '../config'

import {
  CHANGE_PROSPECTS_KIND,
  GET_PROSPECT_IDS,
  GET_PROSPECTS,
  IMPORT_PARTNERS,
  REMOVE_PROSPECTS,
} from '../gql/prospects'
import { REQUEST_CONTACTS } from '../gql/contacts'
import { MARK_CHANNEL_WITH_TAG, REMOVE_TAG_FROM_CHANNELS } from '../gql/tags'
import { prospectToListItem } from '../utils/list-items'
import { getSorting } from '../utils/sorting'
import { createCSV, download } from '../utils/download-blod'
import { getFilterVariables } from '../configFilters'

const Cookies = new UniversalCookie()

const removeFilters = (brandId, kind) => {
  return Cookies.remove(`${brandId}-${kind}Filters`)
}

const readFilters = (brandId, kind) => {
  return Cookies.get(`${brandId}-${kind}Filters`) || {}
}

const saveFilters = (brandId, kind, filters) => {
  Cookies.set(`${brandId}-${kind}Filters`, JSON.stringify(filters), {
    path: '/',
  })
}

const prepareEdges = (edges = []) => {
  return edges.map(({ node }) => node)
}

const kind = {
  partners: 'partner',
  relevants: 'relevant',
}

class Store {
  folders = {}

  @observable folderId
  @observable brandId = null
  @observable kind = 'partners'
  @observable page = 1
  @observable perPage = Config.PER_PAGE
  @observable total = 0
  @observable totalItems = 100

  @observable orderBy = 'added'
  @observable order = 'desc'

  @observable prospects = []
  @observable _filters = {}
  @observable loading = true
  @observable thinking = false

  allProspects = []

  constructor(kind) {
    this.kind = kind || this.kind
  }

  @action
  setInitial() {
    removeFilters(this.brandId, this.kind)
    this._filters = { type1Classification: 'all' }
    this.page = 1
    this.total = 0
    this.orderBy = 'added'
    this.order = 'desc'
    this.prospects = []

    this.loading = true
    this.thinking = false
  }

  saveFolder(folderId) {
    const {
      page,
      perPage,
      _filters,
      total,
      totalItems,
      items,
      order,
      orderBy,
    } = toJS(this)
    this.folders[folderId] = {
      page,
      perPage,
      _filters,
      total,
      totalItems,
      items,
      order,
      orderBy,
    }
  }

  @action
  readFolder(folderId) {
    const folders = toJS(this.folders)
    if (this.folderId) {
      this.saveFolder(this.folderId)
    }

    this.folderId = folderId

    if (folders.hasOwnProperty(folderId)) {
      for (let key in folders[folderId]) {
        if (folders[folderId].hasOwnProperty(key)) {
          this[key] = folders[folderId][key]
        }
      }
      return false
    } else {
      this.setInitial()
      return true
    }
  }

  @action
  async loadInitial() {
    this.page = 1
    await this.load()
  }

  @computed
  get pagination() {
    const page = toJS(this.page) - 1 || 0
    const perPage = toJS(this.perPage)
    const take = perPage
    const skip = page * perPage

    return {
      take,
      skip,
    }
  }

  @action
  async load(brandId, thinking, folderId, force) {
    if (folderId) {
      this.readFolder(folderId)
    }

    if (force) {
      this.page = 1
    }

    this.thinking = thinking || this.thinking
    this.brandId = brandId || this.brandId
    this.folderId = folderId || this.folderId

    const variables = {
      ...this.pagination,
      kind: kind[this.kind],
      brandId: this.brandId,
      ...(this.orderBy && { orderBy: getSorting(this.order, this.orderBy) }),
      requestFolder: kind[this.kind] === 'relevant',
      ...getFilterVariables(toJS(this._filters)),
      ...(this.folderId && { folderId: this.folderId }),
    }

    const { success, ...rest } = await GqlClient.query({
      query: GET_PROSPECTS,
      variables,
    })

    if (!hasError(success, null, null, () => this.loadInitial())) {
      const { totalCount, edges } = rest.data['prospectsConnection']
      let prospects = prepareEdges(edges).map(
        ({ channel, ...restProspect }) => ({
          channelId: channel.id,
          channel,
          ...restProspect,
        })
      )
      prospects = await channelsTags(prospects, this.brandId)
      prospects = await channelsContactStatus(prospects, this.brandId)
      prospects = await channelsSystemTags(prospects)

      if (this.folderId === folderId || !folderId) {
        this.prospects =
          this.page === 1 || force
            ? prospects
            : [...toJS(this.prospects), ...prospects]
        this.total = this.page === 1 ? totalCount : this.total

        if (!this.hasFilters) {
          this.totalItems = totalCount
        }
      } else {
        this.saveFolder(folderId)
        let folders = toJS(this.folders)
        folders[folderId].prospects = prospects
        folders[folderId].total = totalCount
        folders[folderId].totalItems = totalCount
      }
    }

    this.loading = false
    this.thinking = false
  }

  @action
  async loadIds() {
    const variables = {
      take: this.total,
      kind: kind[this.kind],
      brandId: this.brandId,
      ...(this.orderBy && { orderBy: getSorting(this.order, this.orderBy) }),
      requestFolder: kind[this.kind] === 'relevant',
      ...getFilterVariables(toJS(this._filters)),
      ...(this.folderId && { folderId: this.folderId }),
    }

    const { success, ...rest } = await GqlClient.query({
      query: GET_PROSPECT_IDS,
      variables,
    })

    if (!hasError(success, null, null, () => this.loadInitial())) {
      const { edges } = rest.data['prospectsConnection']
      this.allProspects = prepareEdges(edges)
      return toJS(this.allProspects).map(({ id }) => id)
    }

    return []
  }

  @action
  async exportToCSV() {
    const variables = {
      skip: 0,
      take: this.total,
      kind: kind[this.kind],
      brandId: this.brandId,
      orderBy: getSorting(this.order, this.orderBy),
      requestFolder: kind[this.kind] === 'relevant',
      ...getFilterVariables(toJS(this._filters)),
      ...(this.folderId && { folderId: this.folderId }),
    }
    const { success, ...rest } = await GqlClient.query({
      query: GET_PROSPECTS,
      variables,
    })

    if (success) {
      const { edges } = rest.data['prospectsConnection']
      let prospects = prepareEdges(edges).map(
        ({ channel, ...restProspect }) => ({
          channelId: channel.id,
          channel,
          ...restProspect,
        })
      )
      prospects = await channelsTags(prospects, this.brandId)
      prospects = await channelsContactStatus(prospects, this.brandId)
      prospects = prospects.map(prospectToListItem).filter((p) => Boolean(p))
      download(createCSV(prospects, 'prospects'), `${this.kind}.csv`)
    }
  }

  @action
  updateItems(ids, details) {
    this.prospects = toJS(this.prospects).map((item) => {
      return {
        ...item,
        ...(ids.includes(item.id) && details),
      }
    })
  }

  @action
  updateItemFields(condition) {
    this.prospects = toJS(this.prospects).map(condition)
  }

  @action
  removeItems(ids) {
    this.prospects = toJS(this.prospects).filter(({ id }) => !ids.includes(id))
    if (ids.length) {
      this.total -= ids.length
      if (!this.hasFilters) {
        this.totalItems -= ids.length
      }
    }
  }

  @action
  removeFromFolders(ids) {
    // this.prospects = toJS(this.prospects).filter(({ id }) => !ids.includes(id))
    // let folders = toJS(this.folders)
    // items.forEach(({ folder }) => {
    //   if (folder && folder.id && folders.hasOwnProperty(folder.id)) {
    //     folders[folder.id].prospects = folders[folder.id].prospects.filter(({ id }) => !ids.includes(id))
    //   }
    // })
    // this.folders = folders
  }

  @action
  moveToFolder(ids, folder) {
    let folders = toJS(this.folders)
    const folderId = folder.id
    const prospects = toJS(this.prospects).filter(({ id }) => ids.includes(id))

    if (folders.hasOwnProperty(folderId)) {
      folders[folderId].prospects = [
        ...prospects.map((item) => ({ ...item, folder })),
        ...folders[folderId].prospects,
      ]
      folders[folderId].total += ids.length
    }
    this.folders = folders
  }

  @action
  async importPartners({ partners }) {
    this.thinking = true
    const links = partners
      .map((link) => {
        if (link) {
          return link.replace(/\s/, '')
        }
        return null
      })
      .filter((link) => !!link)

    const { success, ...rest } = await GqlClient.mutation({
      mutation: IMPORT_PARTNERS,
      variables: {
        requestFolder: false,
        ImportPartnersInput: { brandId: this.brandId, links },
      },
    })

    if (success) {
      this.load(null, null, null, true).then()
    }
    return { error: null }
  }

  @action
  async toggleToPartner(ids, direction, kind) {
    const action = direction === 'add' ? `mark-as-${kind}` : `unmark-as-${kind}`
    const { success, message } = await Put(`/api/discovery/leads/${action}`, {
      ids,
      brand: this.brandId,
    })

    if (!hasError(success, message, '')) {
      this.load().then()
    }
  }

  @action
  async toggle(sourceKind, actionKind, ids) {
    this.prospects = toJS(this.prospects).filter(({ id }) => !ids.includes(id))

    const toKind =
      sourceKind === 'partners' ? kind['relevants'] : kind['partners']
    const mutation =
      actionKind === 'mark' ? CHANGE_PROSPECTS_KIND : REMOVE_PROSPECTS
    const input =
      actionKind === 'mark'
        ? 'ChangeProspectsKindInput'
        : 'DeleteProspectsInput'
    const variables = {
      [input]: {
        ids,
        brandId: this.brandId,
        ...(actionKind === 'mark' && { kind: toKind }),
      },
    }

    await GqlClient.mutation({ mutation, variables })
  }

  @action
  async requestPartnerContacts(partnerId, outreachMessage) {
    return await this.requestPartnersContacts([partnerId], outreachMessage)
  }

  @action
  async requestPartnersContacts(partnerIds, outreachMessage) {
    this.selected

    const channelIds =
      partnerIds.length === this.total
        ? toJS(this.allProspects).map(({ channel }) => channel.id)
        : toJS(this.prospects)
            .filter(
              ({ id, contactStatus }) =>
                !contactStatus && partnerIds.includes(id)
            )
            .map(({ channelId }) => channelId)

    const payload = {
      brandId: this.brandId,
      channelIds,
      ...(outreachMessage && { outreachMessage }),
    }

    const { success } = await GqlClient.mutation({
      mutation: REQUEST_CONTACTS,
      variables: { RequestContactsInput: payload },
    })

    if (
      !hasError(success, 'message', 'Contacts have been successfully requested')
    ) {
      this.prospects = toJS(this.prospects).map((item) => {
        if (channelIds.indexOf(item.channelId) >= 0) {
          return { ...item, contactStatus: 'requested' }
        }
        return item
      })
    }

    return { success }
  }

  @action
  async removeFromPartners(ids) {
    // this.prospects = toJS(this.prospects).filter(({ id }) => ids.indexOf(id) < 0)
    //
    // const { success, message } = await Delete(`/api/discovery/existing-partners`, { ids })
    //
    // if (!hasError(success, message, '')) {
    //   this.load().then()
    // }
  }

  @action
  async addTags(partners, tags) {
    let channelIds = []
    this.prospects = toJS(this.prospects).map((partner) => {
      if (partners.includes(partner.id)) {
        partner.tags = partner.tags || []
        partner.tags = unionBy(partner.tags, tags, 'id')
        channelIds.push(partner.channelId)
      }
      return partner
    })

    const [tag] = tags

    if (this.total === partners.length) {
      channelIds = toJS(this.allProspects).map(({ channel }) => channel.id)
    }

    const payload = {
      id: tag.id, //TAG ID
      brandId: this.brandId,
      channelIds,
    }

    return await GqlClient.mutation({
      mutation: MARK_CHANNEL_WITH_TAG,
      variables: { MarkChannelsWithTagInput: payload },
    })
  }

  @action
  async removeTags(partners, tagIds) {
    let channelIds = []
    this.prospects = toJS(this.prospects).map((partner) => {
      if (partners.includes(partner.id)) {
        partner.tags = (partner.tags || []).filter(
          ({ id }) => !tagIds.includes(id)
        )
        channelIds.push(partner.channelId)
      }
      return partner
    })

    const [tagId] = tagIds

    if (this.total === partners.length) {
      channelIds = toJS(this.allProspects).map(({ channel }) => channel.id)
    }

    const payload = {
      id: tagId, //TAG ID
      brandId: this.brandId,
      channelIds,
    }

    return await GqlClient.mutation({
      mutation: REMOVE_TAG_FROM_CHANNELS,
      variables: { RemoveTagFromChannelsInput: payload },
    })
  }

  @action
  async updateTags(tag) {
    this.prospects = toJS(this.prospects).map((partner) => {
      let tags = (partner.tags || []).map((t) => {
        if (t.id === tag.id) {
          return tag
        }
        return t
      })
      return { ...partner, tags }
    })
  }

  @action
  async nextPage() {
    this.page = this.page + 1
    await this.load(null, true)
  }

  @action
  async pageChange(page) {
    if (
      toJS(this.prospects).length < this.total &&
      !this.loading &&
      !this.thinking
    ) {
      this.page = this.page + 1
      await this.load(null, true)
    }
  }

  @action
  async perPageChange(perPage) {
    this.page = 1
    this.perPage = perPage
    await this.load(null, true)
  }

  @action
  async filtersChange(filters) {
    const { searchId } = toJS(this._filters)
    let updatedFilters = filters

    if (!filters.hasOwnProperty('searchId') && searchId) {
      updatedFilters = { ...updatedFilters, searchId }
    }

    saveFilters(this.brandId, this.kind, updatedFilters)

    if (!filters.search && toJS(this._filters).search) {
      this.order = this.order || 'desc'
      this.orderBy = this.orderBy || 'avgBreezyScore'
    } else if (toJS(this._filters).search !== filters.search) {
      this.order = undefined
      this.orderBy = undefined
    }

    this.page = 1
    this._filters = updatedFilters
    await this.load(null, true)
  }

  @action
  async sorting(key, direction, callback) {
    if (this.order !== direction || this.orderBy !== key) {
      this.order = direction
      this.orderBy = key
      this.page = 1

      if (callback) {
        callback()
      }
      await this.load(null, true)
    }
  }

  @action
  updateNotesCount(itemId, count) {
    this.prospects = toJS(this.prospects).map(({ id, ...rest }) => ({
      id,
      ...rest,
      ...(id === itemId && { notesCount: count }),
    }))
  }

  @computed
  get filtersToQuery() {
    let filters = {}
    for (let key in this._filters) {
      if (this._filters.hasOwnProperty(key)) {
        if (key === 'lang' && !!this._filters.lang) {
          filters.lang = this._filters.lang.value
        } else if (
          key === 'type1Classification' &&
          !!this._filters.type1Classification
        ) {
          filters.type1Classification = this._filters.type1Classification
        } else if (key === 'swVisits') {
          filters.swVisitsStart = this._filters[key][0]
          filters.swVisitsEnd = this._filters[key][1]
        } else if (key === 'swRank') {
          filters.swRankStart = this._filters[key][0]
          filters.swRankEnd = this._filters[key][1]
        } else if (key === 'avgBreezyScore') {
          filters.avgBreezyScoreStart = this._filters[key][0]
          filters.avgBreezyScoreEnd = this._filters[key][1]
        } else if (key === 'domainAuthority') {
          filters.domainAuthorityStart = this._filters[key][0]
          filters.domainAuthorityEnd = this._filters[key][1]
        } else if (key === 'totalKeywords') {
          filters.totalKeywordsStart = this._filters[key][0]
          filters.totalKeywordsEnd = this._filters[key][1]
        } else if (key === 'totalCompetitors') {
          filters.totalCompetitorsStart = this._filters[key][0]
          filters.totalCompetitorsEnd = this._filters[key][1]
        } else if (key === 'partnershipsOnly') {
          filters.partnershipsOutCountStart = 1
        } else if (Array.isArray(this._filters[key])) {
          filters[key] = this._filters[key].map(({ id, value }) =>
            key === 'byTags' ? id : value
          )
        } else {
          filters[key] = this._filters[key]
        }
      }
    }

    return {
      ...filters,
      ...(this.folderId && { folder: this.folderId }),
    }
  }

  @computed
  get url() {
    const { page, perPage, orderBy, order, searchId } = this
    const { type1Classification, ...filters } = this.filtersToQuery

    let sort = `${order === 'asc' ? '' : '-'}${orderBy}`

    if (orderBy === 'swRank') {
      sort = order === 'desc' ? '-swRankOp' : 'swRank'
    }

    return param({
      page,
      perPage,
      sort,
      ...(searchId && { product: productId }),
      filters: {
        ...filters,
        ...(type1Classification &&
          type1Classification !== 'all' && { type1Classification }),
      },
    })
  }

  @computed
  get listProps() {
    const { loading, thinking, page, perPage, total, kind } = this

    return {
      kind,
      thinking,
      loading,
      items: toJS(this.prospects)
        .map(prospectToListItem)
        .filter((p) => Boolean(p)), //orderBy(toJS(this.prospects).map(convertPartnerToListItem), [this.orderBy], [this.order]),
      total,
      page,
      perPage,
    }
  }

  @computed
  get sortProps() {
    const { orderBy, order } = this
    return { orderBy, order }
  }

  @computed
  get filters() {
    const { type1Classification, ...rest } = this._filters
    return {
      ...(type1Classification &&
        type1Classification !== 'all' && { type1Classification }),
      ...rest,
    }
  }

  @computed
  get hasFilters() {
    const existingFilterKeys = [
      'searchId',
      'lang',
      'search',
      'domainAuthority',
      'type1Classification',
      'totalKeywords',
      'totalCompetitors',
      'swRank',
      'avgBreezyScore',
      'domainAuthority',
      'affiliatesOnly',
      'partnershipsOnly',
      'type1',
      'type2',
      'type3',
    ]

    let hasFilters = false
    const filters = toJS(this.filters)

    existingFilterKeys.forEach((key) => {
      if (!hasFilters && filters.hasOwnProperty(key)) {
        if (
          key === 'type1Classification' &&
          filters['type1Classification'] !== 'all'
        ) {
          hasFilters = true
        } else if (key !== 'type1Classification') {
          hasFilters = true
        }
      }
    })

    return hasFilters
  }

  @computed
  get geoFilter() {
    const filters = toJS(this._filters)

    if (filters.hasOwnProperty('byCountries')) {
      return filters.byCountries.map(({ value }) => value)
    }

    return undefined
  }
}

export const PartnersStore = new Store('partners')
export const RelevantsStore = new Store('relevants')

export const RelevantsFolderStore = new Store('relevants')
