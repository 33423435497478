import is from 'is_js'

const rulePattern = /(\w+)\[(.*)\]/ //   /(\w+)\[(\w+)]$/

/**
 * @export
 * @param {string} value
 * @returns {boolean}
 */
const containsLetterAndNumber = (value) => {
  if (!value) {
    return false
  }

  const pattern = /(?=.*?[0-9])(?=.*?[A-Za-z]).+/
  const ruleMatch = value.match(pattern)

  return !!ruleMatch
}

/**
 * @export
 * @param {string} value
 * @returns {boolean}
 */
const doesNotContainWhitespace = (value) => {
  const hasNoWhiteCharactersPattern = /^\S*$/
  const noWhiteCharactersMatch = value.match(hasNoWhiteCharactersPattern)

  return !!noWhiteCharactersMatch
}

const removeEmpty = obj => Object.keys(obj).forEach(key => !obj[key] ? delete obj[key] : '');

const parseRule = rule => {
  const ruleMatch = rule.match(rulePattern)
  return ruleMatch ? { name: ruleMatch[1], value: ruleMatch[2] } : { name: rule }
}

const parseRules = rules => rules.split('|').map(rule => parseRule(rule))

const checkRules = (form, config, rules, categories) => {
  let result = null
  rules.forEach(rule => {
    if (!result) {
      result = rulesHandler[rule.name](form, config, rule, categories)
    }
  })

  return result
}

const rulesHandler = {
  required: (form, config, rule) => {
    if (form[config.field] === undefined) return null
    const value = form[config.field].value
    return !!value && value !== 'false' ? null : config.messages[rule.name]
  },
  categories_required: (form, config, rule, categories) => {
    if (form[config.field] === undefined) return null
    return !!categories.length ? null : config.messages[rule.name]
  },
  valid_email: (form, config, rule) => {
    const value = form[config.field].value
    return is.email(value) ? null : config.messages[rule.name]
  },
  valid_url: (form, config, rule) => {
    const value = form[config.field].value
    return is.url(value) ? null : config.messages[rule.name]
  },
  match: (form, config, rule) => {
    const value = form[config.field].value
    const matchValue = form[rule.value].value
    return value === matchValue ? null : config.messages[rule.name]
  },
  minLength: (form, config, rule) => {
    const value = form[config.field].value
    return value && value.length >= parseInt(rule.value) ? null : config.messages[rule.name]
  },
  max: (form, config, rule) => {
    const value = form[config.field].value
    return parseFloat(value) <= config.options.max ? null : config.messages[rule.name]
  },
  containsLetterAndNumber: (form, config, rule) => {
    const value = form[config.field].value
    return containsLetterAndNumber(value) ? null : config.messages[rule.name]
  },
  doesNotContainWhitespace: (form, config, rule) => {
    const value = form[config.field].value
    return doesNotContainWhitespace(value) ? null : config.messages[rule.name]
  },
  regexp: (form, config, rule) => {
    const value = form[config.field].value
    return (new RegExp(rule.value)).test(value) ?  null : config.messages[rule.name]
  }
}


export default (formName, configs, onErrors, onValid, categories) => {
  let errors = {}
  const form = document.forms[formName]
  configs.forEach(config => {
    if (!config.rules) return
    const rules = parseRules(config.rules)
    errors[config.field] = checkRules(form, config, rules, categories)
  })

  removeEmpty(errors)

  return Object.keys(errors).length === 0 ? onValid() : onErrors(errors)
}
