import { sortBy } from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'

export const typeColors = {
  Creator: 'rgb(255,232,142)',
  Brand: 'rgb(102,102,102)',
  1: 'rgba(255,232,142,1)',
  2: 'rgba(255,232,142,0.6)',
  3: 'rgba(255,232,142,0.3)',
  type1: 'rgba(209,135,213,0.8)',
  type2: 'rgba(209,135,213,0.45)',
  type3: 'rgba(209,135,213,0.2)',
}

const Root = styled.div`
  background: ${(props) => typeColors[props.type]};
  color: ${(props) =>
    props.type === 'Brand' ? 'white' : 'rgba(62, 88, 97, 0.9)'};
  height: 1.85em;
  line-height: 1.85em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  padding: 0 0.5em 0 0.3em;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.83em')};
  text-transform: capitalize;

  ${(props) =>
    props.other &&
    css`
      border-radius: 50%;
    `};

  & svg {
    position: relative;
    top: 0.16em;
  }
`

const sortTags = (tags) => {
  if (!tags) return
  return sortBy(tags, ['level'])
}

export const SystemTagLabel = ({ name, type, other, fontSize }) => {
  const computedType = type //type === 1 ? name : type
  return (
    <Root type={computedType} fontSize={fontSize} other={other}>
      {name}
    </Root>
  )
}

export const SystemTags = ({ variant, data, limit }) => {
  const compact = limit > 0
  const list = compact ? sortTags(data).slice(0, limit) : sortTags(data)
  const otherCount = data.length - limit

  return (
    <>
      {list.map(({ name, level }) => (
        <SystemTagLabel name={name} type={level} />
      ))}
      {compact && otherCount > 0 && (
        <SystemTagLabel
          name={`+${otherCount}`}
          type={variant === 'manual' ? 1 : 'type3'}
          other
        />
      )}
    </>
  )
}
