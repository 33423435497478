import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  color: ${props => props.theme.palette.accentDarkText};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3ch;
  padding: 0.1em 0.2em;
  font-size: 1em;
  background: ${props => props.theme.palette.brand.blue.light};
  border-radius: ${props => props.theme.shape.borderRadius}px;
`

interface LabelPositionProps {
  value: number | 'N/A'
}

function LabelPosition ({ value }: LabelPositionProps) {
    return (
        <Root>
          {value}
        </Root>
    )
}

LabelPosition.defaultProps = {
  value: 'N/A'
}

export default LabelPosition
