import language from 'numbro/languages/en-GB'
import languagesList from './data/languages'

//test deploy
export const imageKitUrl = process.env.IMAGEKIT_URL || 'https://ik.imagekit.io/afill/staging/'

export const AVAILABLE_COUNTRIES = [
  "AR",
  "AT",
  "AU",
  "BE",
  "BR",
  "CA",
  "CH",
  "CO",
  "DE",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "HK",
  "IL",
  "IN",
  "IT",
  "JP",
  "MX",
  "MY",
  "NL",
  "PL",
  "PT",
  "RU",
  "SE",
  "TW",
  "UA",
  "US",
  "VN",
  "KR",
  "TH",
  "SA",
  "KW",
  "CL",
  "DK",
  "PA"
]

export const ENGINES = {
  GLOBAL: { engine: 'google.com', location: '' },
  AR: { engine: 'google.com.ar', location: 'Buenos Aires' },
  AT: { engine: 'google.at', location: 'Austria' },
  AU: { engine: 'google.com.au', location: 'Australia' },
  BE: { engine: 'google.be', location: 'Brussels' },
  BR: { engine: 'google.com.br', location: 'Brasil' },
  CA: { engine: 'google.ca', location: 'Canada' },
  CH: { engine: 'google.ch', location: 'Switzerland' },
  CO: { engine: 'google.com.co', location: 'Bogota' },
  DE: { engine: 'google.de', location: 'Germany' },
  ES: { engine: 'google.es', location: 'Spain' },
  FI: { engine: 'google.fi', location: 'Finland' },
  FR: { engine: 'google.fr', location: 'France' },
  GB: { engine: 'google.co.uk', location: 'United Kingdom' },
  GR: { engine: 'google.gr', location: 'Greece' },
  HK: { engine: 'google.com.hk', location: 'Hong Kong' },
  IL: { engine: 'google.co.il', location: 'Israel' },
  IN: { engine: 'google.co.in', location: 'India' },
  IT: { engine: 'google.it', location: 'Italy' },
  JP: { engine: 'google.co.jp', location: 'Japan' },
  MX: { engine: 'google.com.mx', location: 'Mexico' },
  MY: { engine: 'google.com.my', location: 'Malaysia' },
  NL: { engine: 'google.nl', location: 'Netherlands' },
  PA: { engine: 'google.com.pa', location: 'Panama' },
  PL: { engine: 'google.pl', location: 'Warsaw' },
  PT: { engine: 'google.pt', location: 'Portugal' },
  RU: { engine: 'google.ru', location: 'Russia' },
  SE: { engine: 'google.se', location: 'Sweden' },
  TW: { engine: 'google.com.tw', location: 'Taiwan' },
  UA: { engine: 'google.com.ua', location: 'Ukraine' },
  US: { engine: 'google.com', location: 'United States' },
  VN: { engine: 'google.com.vn', location: 'Vietnam' },
  KR: { engine: 'google.co.kr', location: 'Korea' },
  TH: { engine: 'google.co.th', location: 'Thailand' },
  SA: { engine: 'google.com.sa', location: 'Saudi Arabia' },
  KW: { engine: 'google.com.kw', location: 'Kuwait' },
  CL: { engine: 'google.cl', location: 'Chile' },
  DK: { engine: 'google.dk', location: 'Denmark' },
}

export const CHART_COLORS =  [
  '#65FDD8',
  '#65FD99',
  '#BAFD65',
  '#FDF765',
  '#FDD265',
  '#ffb395',
  '#fc8c8c',
  '#f972a4ff',
  '#ff84d4',
  '#c798fd',
  'rgb(44,56,71)'
]

export const COMPACT_CHART_COLORS = [
  '#65FDD8',
  '#FDD265',
  '#c798fd',
  'rgb(44,56,71)'
]

const config = {
  LATEST_TAGS: 5,
  ENGINES,
  LANGUAGES: languagesList,
  LANGUAGE: language,
  LOCALE: 'en-GB',
  NUMBER_FORMAT: '0,0',
  NUMBER_CURRENCY_FORMAT: '$0,0.00',
  NUMBER_PERCENT_FORMAT: { mantissa: 1, average: true },
  NUMBER_CURRENCY_SYMBOL: '£',
  NUMBER_READABLE_SHORTER: {average: true, mantissa: 0, optionalMantissa: true},
  NUMBER_READABLE_SHORT: {average: true, mantissa: 1, optionalMantissa: true},
  NUMBER_READABLE: {average: true, mantissa: 2, optionalMantissa: true},
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_ALT_FORMAT: 'YYYY MM',
  DATE_READABLE_FORMAT: 'YYMMDDHH',
  DATE_MONTH_FORMAT: 'MMM Do YYYY',
  DATE_TIME_FORMAT: 'MMM Do YYYY HH:MM',
  DATE_PERIOD_FORMAT: 'MMM YYYY',
  AWS_S3_URL: process.env.AWS_S3_URL || `https://j3deiryt8k.execute-api.eu-west-2.amazonaws.com/default/s3SignedRequestStage`,
  DEFAULT_FOLDERS: ['Contacted', 'Follow Up', 'In Talks', 'Contracting', 'Declined', 'Postponed'],
  DRAWER_WIDTH: 260,
  SCORE_MAX: 100,
  SCORE_COLOR_STEPS: 6,
  SCORE_COLORS: ['#fc8c8c', '#FDF765', '#65FD99', '#65FDD8'],
  ROW_HEIGHT: 60,
  DATA_GRID_LICENSE_KEY: 'efa1e52471b79ef6209d69a53185d013Tz04NzkwOSxFPTE3NDM5NTA3MTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
  // DATA_GRID_LICENSE_KEY: '77f7fdd91e66026600d5b819effb0a57Tz02MDI4MSxFPTE3MDg1MTA2MzEzMTYsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
  PER_PAGE: 50,
  EXPIRED_LEADS_THRESHOLD: 5,
  PAYWALL_LEADS_THRESHOLD: 15,
  PAYWALL_RELEVANT_THRESHOLD: 10,
  PAYWALL_PARTNERS_THRESHOLD: 5,
  EXPIRED_DAYS: 30,
  CREATOR_EMPTY_CHANNEL_MESSAGE: 'Howdy. Please assist with my website verification.',
  INTELLIGENCE_MESSAGE: 'I\'d like to enable Intelligence feature for my account.',
  EXPIRED_SEARCH_MESSAGE: 'Hey, I would like to reactivate my search ',
  SALES_MESSAGE: 'Hey, I would like to upgrade, please contact me',
  SALES_MESSAGE_ANNUALLY: 'Hey, I would like to upgrade to annual plan, please contact me',
  SALES_MESSAGE_QUARTERLY: 'Hey, I would like to upgrade to quarterly plan, please contact me',
  OUTREACH_MESSAGE: 'Pitch your partnership – This message will be visible to the Creator(s) you\'re approaching',
  avatarColors: [
    '#65FDD8',
    '#65FD99',
    '#BAFD65',
    '#FDF765',
    '#FDD265',
    '#fc8c8c',
    '#ff84d4',
    '#c798fd',

  ],
  labelColors: {
    overlap: '#ff84d4',
    prospect: '#ffea8a',
    partner: '#65FDD8'
  },
  typeColors: {
    creator: '#ffea8a',
    brand: '#465362',
  },
  plans: {
    'Self-serve-GBP-Monthly': {
      price: 99,
      credits: 200,
      name: 'Self-serve',
      option: 'self',
      period: 'month'
    },
    'Self-serve-GBP-Every-3-months': {
      price: 259,
      credits: 600,
      name: 'Self-serve',
      option: 'self',
      period: 'quarter'
    },
    'Assisted-GBP-Every-3-months': {
      price: 899,
      credits: 2000,
      name: 'Assisted',
      option: 'assisted',
      period: 'quarter'
    }
  },
  pricing: {
    selfMonthly: 99,
    selfQuarterly: 259,
    assisted: 899,
    sale: 10,
    perContact: '1.50'
  },
  credits: {
    monthly: 200,
    quarterly: 600,
    assisted: 2000,
    trial: 50,
    competitor: 10,
    keyword: 1,
    contact: 1,
    perContact: 1,

    '250': 125,
    '500': 235,
    '500-off': 6,
    '1000': 450,
    '1000-off': 10
  },
  trialPeriod: 14
}

export default config
