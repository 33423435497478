import { Get, Post, Delete, Put } from '../utils/request'
import { action, computed, observable, toJS } from 'mobx'
import hasError from './request-message'


class PaymentsStore {
  brandId = null

  @observable paymentSource = {}
  @observable invoices = []

  @observable loading = true
  @observable thinking = false

  constructor (brandId) {
    this.brandId = brandId
    this.load().then()
  }

  @action
  async load () {
    this.loading = true

    const { errorCode, card, invoices } = await Get(`/api/discovery/brands/${this.brandId}/payments`)

    if (!errorCode) {
      this.paymentSource = card
      this.invoices = invoices
    }

    this.loading = false
  }

  @computed
  get paymentsDetails () {
    return {
      history: toJS(this.invoices),
      card: toJS(this.paymentSource)
    }
  }
}

export default PaymentsStore
