import React from 'react'

export const CheckEmail = () => (
  <svg width="256" height="215" viewBox="0 0 256 215" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path d="M80.0187 131.855C80.1122 131.855 80.2018 131.818 80.2679 131.752C80.334 131.686 80.3712 131.596 80.3712 131.503C80.3712 131.409 80.334 131.32 80.2679 131.254C80.2018 131.188 80.1122 131.15 80.0187 131.15C79.9252 131.15 79.8355 131.188 79.7694 131.254C79.7033 131.32 79.6662 131.409 79.6662 131.503C79.6662 131.596 79.7033 131.686 79.7694 131.752C79.8355 131.818 79.9252 131.855 80.0187 131.855Z" fill="black"/>
      <path d="M186.338 164.883H62.2655V91.0968L124.302 43.3945L186.338 91.0968V164.883Z" fill="white"/>
      <path d="M181.874 160.508H66.7303V92.0328L124.302 47.7637L181.874 92.0328V160.508Z" fill="#E0E0E0"/>
      <g filter="url(#filter1_dd)">
        <path d="M171.534 49.7377L157.763 39.1641L121.284 86.8023L101.024 71.2459L90.4639 85.0356L117.608 105.846L124.569 111.07L171.534 49.7377Z" fill="#0163FA"/>
      </g>
      <path opacity="0.05" d="M62.853 91.2148L124.889 128.108L62.853 165.001V91.2148Z" fill="black"/>
      <path d="M62.383 91.2148L124.419 128.108L62.383 165.001V91.2148Z" fill="white"/>
      <path opacity="0.05" d="M185.986 91.2148L123.95 128.108L185.986 165.001V91.2148Z" fill="black"/>
      <path d="M186.456 91.2148L124.419 128.108L186.456 165.001V91.2148Z" fill="white"/>
      <path opacity="0.05" d="M62.383 163.825L124.419 115.418L186.456 163.825H62.383Z" fill="black"/>
      <path d="M62.383 164.999L124.419 116.592L186.456 164.999H62.383Z" fill="white"/>
      <path d="M41.3643 67.2678L40.6655 67.609L40.7459 67.4661L40.6845 67.4858L40.7705 67.4222L50.1763 50.6836L54.9844 54.7832L59.1091 58.6019L57.9248 59.1802L59.2562 61.5287L41.3643 67.2678Z" fill="url(#paint0_linear)"/>
      <path d="M54.5082 55.2207L58.2783 58.7164L41.1901 67.2118L50.2838 55.5565L54.5082 55.2207Z" fill="#0163FA"/>
      <path opacity="0.2" d="M54.5082 55.2207L58.2783 58.7164L41.1901 67.2118L50.2838 55.5565L54.5082 55.2207Z" fill="black"/>
      <path d="M50.1106 51.4707L41.1901 67.2115L54.5082 55.2204L50.1106 51.4707Z" fill="#0163FA"/>
      <path d="M58.3883 61.4224L41.2084 67.0973L55.5696 56.377L58.3883 61.4224Z" fill="#0163FA"/>
      <path d="M191.486 37.1534L187.722 43.6787L215.319 48.1724L197.545 35.7949L191.486 37.1534Z" fill="#0163FA"/>
      <path opacity="0.2" d="M191.486 37.1534L187.722 43.6787L215.319 48.1724L197.545 35.7949L191.486 37.1534Z" fill="black"/>
      <path d="M196.018 30L215.319 48.1713L191.486 37.1523L196.018 30Z" fill="#0163FA"/>
      <path d="M188.74 47.5126L215.244 48.019L190.501 39.2305L188.74 47.5126Z" fill="#0163FA"/>
    </g>
    <defs>
      <filter id="filter0_d" x="0.665497" y="0" width="254.653" height="215.001" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="10"/>
        <feGaussianBlur stdDeviation="20"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.129412 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <filter id="filter1_dd" x="87.4639" y="37.1641" width="87.0704" height="77.9059" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear" x1="54.6093" y1="66.9806" x2="43.9972" y2="57.9324" gradientUnits="userSpaceOnUse">
        <stop stop-color="#808080" stop-opacity="0.25"/>
        <stop offset="0.53514" stop-color="#808080" stop-opacity="0.12"/>
        <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
      </linearGradient>
    </defs>
  </svg>
)

