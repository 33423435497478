import React from 'react'
import styled from 'styled-components'
import WidgetBase, {WidgetBaseProps} from '../WidgetBase'

const Root = styled.div`
  position: relative;
`

export const ListWrapper = styled.div`
  margin-top: 0.5em;
  display: flex;
  flex-wrap: ${props => props.list ? 'nowrap' : 'wrap'};
  flex-direction: ${props => props.list ? 'column' : 'row'};
  gap: ${props => props.list ? '0.3em' : '0.6em'};
  position: relative;
  overflow: hidden;
  width: 100%;
`

export interface WidgetListBaseProps extends WidgetBaseProps {
    list?: Boolean
    children?: React.ReactNode
}

function WidgetListBase ({list, children, ...other}: WidgetListBaseProps) {
    return (children || other.loading)
        && <WidgetBase {...other} component={Root}>
            <ListWrapper list={list}>
                {children}
            </ListWrapper>
        </WidgetBase>
}

export default WidgetListBase
