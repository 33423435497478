import { action, computed, observable, toJS } from 'mobx'
import { sendEvent } from '../utils/events'
import Queries from './queries'

class EcosystemStore extends Queries {
  @observable details = {}

  @observable comparePrimary = []
  @observable compareSecondary = []
  @observable compareUntapped = null
  @observable gapFilterKind = 'existingPartnersFor'

  fields = ['comparePrimary', 'compareSecondary', 'compareUntapped']

  constructor () {
    super()
    // this.getQueriesFromSearch()
  }

  @action
  setEcosystem (ecosystem, resetFilters) {
    this.details = ecosystem

    if (resetFilters) {
      this.comparePrimary = []
      this.compareSecondary = []
      this.compareUntapped = null
      this.gapFilterKind = 'existingPartnersFor'
      this.updateQueries()
    }
  }

  @action
  setPrimary (value) {
    this.comparePrimary = value
    this.updateQueries()
  }

  @action
  setSecondary (value) {
    this.compareSecondary = value
    this.updateQueries()
  }

  @action
  setUntapped (value) {
    this.compareUntapped = value
  }

  @action
  setGapFilterKind (value) {
    this.gapFilterKind = value
  }

  @action
  async setComparison (kind, value, brandId) {
    if (kind === 'primary') {
      if (value) {
        const { partnershipsInCount } = value
        this.setUntapped(partnershipsInCount)
      } else {
        this.setUntapped(undefined)
      }
      this.setPrimary(value)
    } else if (kind === 'secondary') {
      if (!value || !value.length) {
        this.setGapFilterKind('existingPartnersFor')
      }
      this.setSecondary(value)
    }
    await sendEvent(brandId, 'brandsAddedToComparison', {
      ...(this.comparePrimary && { primary: toJS(this.comparePrimary)}),
      ...(this.compareSecondary && { secondary: toJS(this.compareSecondary)}),
    })
  }

  getRequestOptions () {
    const hasSecondary = Boolean(this.secondary && this.secondary.length)
    const comparison = Boolean(!!this.primary && this.primary.length && hasSecondary)

    return { comparison, primary: this.primary, secondary: this.secondary, gapFilterKind: this.gapFilterKind }
  }

  @computed
  get untapped () {
    return toJS(this.compareUntapped) || null
  }

  @computed
  get primary () {
    return toJS(this.comparePrimary)
  }

  @computed
  get secondary () {
    return toJS(this.compareSecondary)
  }

  @computed
  get channels () {
    const { channels } = this.details
    return channels || []
  }

  @computed
  get totalBrands () {
    const { channelsCount } = this.details
    return channelsCount
  }

  @computed
  get ecosystem () {
    return toJS(this.details)
  }
}

export default new EcosystemStore()
