import React from 'react'
import styled, { css } from 'styled-components'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Placeholder } from './Details'

const Heading = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  //text-transform: uppercase;
  width: 80%;
`;

const Count = styled(Typography)`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 20%;
  text-align: right;
`;

const SectionHeader = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  ${props => props.noHeader && css` height: 20px ` }
`

const AccordionSummaryMain = styled(AccordionSummary)`
 .MuiAccordionSummary-expandIcon {
    transform: rotate(-90deg);
 }
 .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(0);
  }
`;

const AccordionMain = styled(Accordion)`
  box-shadow: none;
  background-color: transparent;
  border-bottom: 1px solid #e1ecf7;
  border-radius: 0 !important;

  &.Mui-expanded {
    margin: 0;
    min-height: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

const AccordionDetailsMain = styled(AccordionDetails)`
  padding: 0 16px 15px 16px;
  display: block;
  box-sizing: border-box;
`;

const ExpandMoreIconMain = styled(ExpandMoreIcon)`
  //color: #294D83;
`;


class DetailsSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: props.expanded,
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState( { expanded: this.props.expanded })
    }
  }

  handleChange = () => {
    const {
      expanded
    } = this.state
    this.setState({expanded: !expanded})
  }

  render() {
    const {
      loading,
      heading,
      count,
      children,
      noCollapse,
      noHeader
    } = this.props

    const expanded = loading ? true : this.state.expanded

    return (
      !!noCollapse
        ? <>
          <SectionHeader noHeader={noHeader}>
            {
              !noHeader && <>
                <Heading>{heading}</Heading>
                <Count>{count}</Count>
              </>
            }

          </SectionHeader>
          <AccordionDetailsMain>
            { loading ? <Placeholder /> : children }
          </AccordionDetailsMain>
        </>
        : <AccordionMain expanded={expanded} onChange={this.handleChange}>
          <AccordionSummaryMain
            expandIcon={<ExpandMoreIconMain/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Heading>{heading}</Heading>
            <Count>{count}</Count>
          </AccordionSummaryMain>
          <AccordionDetailsMain>
            { loading ? <Placeholder /> : children }
          </AccordionDetailsMain>
        </AccordionMain>
    );
  }
}

export default DetailsSection
