import React from 'react'
import styled from 'styled-components'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import config from "../config";
import CountryFlag from "./CountryFlag";

const imageKitUrl = process.env.IMAGEKIT_URL

const Container = styled.div`
  height: 25px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  border-top: 1px solid #f8faff;
  margin: 0 -15px -10px -15px;
  position: relative;
`

const Total = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  opacity: 0.7;
  padding: 0 30px;
  flex: 1 1 50px;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  height: 25px;
`

const More = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  opacity: 0.7;
  cursor: pointer;
  flex: 1 1 auto;
  justify-content: center;
  position: relative;
  z-index: 2;
`

const ShowAll = ({ amount, total, collapsed, onClick, more, country }) => {
  return (country || more ) ? <Container>
    <Total>
      <span>
        {!!more &&
          <>{collapsed ? amount : total} / {total}</>
        }
      </span>
      <span style={{textAlign: 'right'}}>
        {!!country && <>
          Source: {config.ENGINES[country].engine}
          &nbsp;
          <CountryFlag
            country={country}
            alt={country}
            fallbackImageURL={`${imageKitUrl}/flags/${country}.svg`}
          />
        </>}
      </span>
    </Total>
    {!!more &&
    <More onClick={onClick}>
      {
        collapsed ? <ExpandMore/> : <ExpandLess/>
      }
      {
        collapsed ? 'Show All' : 'Hide All'
      }
    </More>
    }
  </Container>
    : ''
}

export default ShowAll
