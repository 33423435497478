import React from 'react'
import styled, {css} from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import {SkeletonPlaceholder} from "../SkeletonPlaceholder";
import { formatNumber } from '../../utils/format'
import {MozLogoIcon} from '../Icons/Moz_logo'
import {SimilarWeb} from '../Icons/SimilarWeb'

const paddingH = 0.5
const paddingW = 0.5

const Root = styled.div`
  display: grid;
  grid-gap: 0.4vmax;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  //grid-auto-flow:column;
`
const Field = styled.div`
  background: ${props => (props.type === 'other' || props.type === 'hidden') ? 'transparent' : '#E9F3FE'};
  border-radius: 5px;
  padding: ${paddingH}rem ${paddingW}rem;
  position: relative;
  display: flex;
  flex-direction: column;
  ${props => props.type === 'hidden' && css`
    visibility: hidden;
  `}
`
const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  min-height: 12px;
  opacity: 0.7;
`
const Value = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.type === 'other' && css`
    font-weight: normal;
    font-size: 10px;
  `}
`
const MozLogo = styled(MozLogoIcon)`
  width: 21px;
  height: 7px;
  color: black;
  &:hover {
    color: ${props => props.theme.palette.brand.blue.main};
  }
  
`

const columnIcon = {
  moz: <MozLogo />,
  sw: <SimilarWeb />
}

class Metrics extends React.Component {
  constructor(props) {
    super(props)
  }

  fieldMeta = {
    hidden: {
      name: '',
      id: '',
      type: 'hidden',
    },
    breezyScore: {
      name: 'Score',
      id: 'breezyScore',
      type: 'numeric',
    },
    avgBreezyScore: {
      name: 'Avg. Score',
      id: 'avgBreezyScore',
      type: 'numeric',
    },
    domainAuthority: {
      logo: 'moz',
      url: 'https://moz.com/learn/seo/domain-authority',
      name: 'Domain Authority',
      id: 'mozDomainAuthority',
      type: 'numeric',
      thousandsSeparator: false
    },
    pageAuthority: {
      logo: 'moz',
      url: 'https://moz.com/learn/seo/page-authority',
      name: 'Page Authority',
      id: 'mozPageAuthority',
      type: 'numeric',
      thousandsSeparator: false
    },
    swRank: {
      logo: 'sw',
      name: 'SimilarWeb Rank',
      url: 'https://similarweb.com',
      id: 'swRank',
      type: 'numeric',
      thousandsSeparator: true
    },
    keywords: {
      name: 'Keywords',
      id: 'keywords',
      type: 'numeric',
      thousandsSeparator: false
    },
    totalKeywords: {
      name: 'Total Keywords',
      id: 'totalKeywords',
      type: 'numeric',
      thousandsSeparator: false
    },
    competitors: {
      name: 'Competitors',
      id: 'competitors',
      type: 'numeric',
      thousandsSeparator: false
    },
    totalCompetitors: {
      name: 'Total Competitors',
      id: 'totalCompetitors',
      type: 'numeric',
      thousandsSeparator: false
    },
    affiliatePages: {
      name: 'Partnerships',
      id: 'affiliatePartnerships',
      type: 'numeric',
      thousandsSeparator: false
    }
  }

  render() {
    const {fields, loading, actionAffiliate} = this.props
    return (
      <Root>
        {
          fields.map((field) => {
            const meta = this.fieldMeta[field.type]

            if (!meta) return null
            return (
              // <Tooltip title={meta.tooltip} enterDelay={3}>
              <Field onClick={actionAffiliate} type={meta.type} key={meta.name} data-tip  data-for={meta.id}>
                <Label>
                  {loading
                    ? <SkeletonPlaceholder  width={'80%'} height={'10px'} color={'#d5dff0'}  highlightColor={'#e6efff'} />
                    : meta.logo
                      ? (
                        <><a href={meta.url} target="_blank">{columnIcon[meta.logo]}</a>&nbsp;{meta.name}</>
                      )
                      : meta.name
                  }
                </Label>
                <Value type={meta.type}>
                  {loading ? <SkeletonPlaceholder  width={'60%'} height={'12px'} color={'#d5dff0'}  highlightColor={'#e6efff'} /> : meta.thousandsSeparator ? formatNumber(field.value) : field.value}
                </Value>
                {/*{!loading && meta.action && <InfoIcon>i</InfoIcon>}*/}
              </Field>
              // </Tooltip>
            )
          })
        }
      </Root>
    )
  }
}

export default Metrics
