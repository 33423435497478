import React, {useContext} from 'react'
import param from 'can-param'

import {useParams} from "react-router-dom";

import {DetailsContext} from '../DetailsContext'
import Promotes from '../../Profile/Promotes'

export default function ProfilePromotes (props) {
    // @ts-ignore
    const { brandId } = useParams()
    const { loading, promotes, summary } = useContext(DetailsContext)
    const { type } = summary
    const { partnershipsInCount, partnershipsOutCount } = summary
    const total = type === 'creator' ? partnershipsOutCount : partnershipsInCount

    if (!loading && !total) return null

    const link = `/brand/${brandId}/intelligence/partnerships?${param({ order: 'desc', orderBy: type === 'creator' ? 'partnershipsInCount' : 'partnershipsOutCount', _filters: { [type === 'creator' ? 'promotedBy' : 'promotes']: { value: summary.id, label: summary.domain } } })}`

    return <Promotes loading={loading} type={type} total={total || 0} data={promotes} link={link} />
}
