import React, {useState, useEffect, createRef} from 'react'
import styled, {css, keyframes} from 'styled-components'
import {stringToKey} from '../../utils/stringToKey'
import MuiDrawer, {SwipeableDrawerProps} from '@mui/material/SwipeableDrawer'
import IconButton from '@mui/material/IconButton'
import TabPanelMui, {Tab, Tabs} from '../../components-ts/Tabs'
import EdgeActive from '../../components-ts/EdgeActive'
import {X as CloseIcon} from 'react-feather'
import {animated} from '@react-spring/web'

import tabs, { DetailsSidebarKeysBase } from '../../detailsSidebarConfig'

const drawerWidth = 30;
const drawerWidthExpanded = 60;

const Drawer = styled(DrawerBase)<SwipeableDrawerProps>`
    width: ${props => props.expand ? '75%' : '470px'};
    min-width: ${props => props.expand ? '850px' : '470px'};
    max-width: ${props => props.expand ? '1200px' : '470px'};
    transition: width 0.3s ease, min-width 0.3s ease;
    overflow: visible !important;
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 0;
    flex-shrink: 0;
    background: #fff;
    box-shadow: -10px 0 10px rgb(0 0 0 / 10%) !important;
`

const Close = styled(IconButton)`
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.palette.brand.shadow.main};
`

const HeaderNavigation = styled.div`
  //padding: 0 1em;
`

const Body = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`

export const TabPanel = styled(TabPanelMui)`
  position: relative;
  height: 100%;
  flex: 1;
`

interface SidebarTabsProps {
    variant: DetailsSidebarKeysBase
    value?: string
    onChange: (value: string) => void
}

function SidebarTabs({ value, variant, onChange }: SidebarTabsProps) {
    return (
        <Tabs value={value}>
            {
                tabs[variant].map(tab => (
                    <Tab key={stringToKey(tab)} onClick={() => onChange(stringToKey(tab))} value={stringToKey(tab)} label={tab} />
                ))
            }
        </Tabs>
    )
}

const ContentWrapper = styled(animated.div)`
  position: relative;
  display: flex;
  height: 100%;
  flex: 1;
`

function DrawerBase({className, PaperProps, style,  children, ...other}: SwipeableDrawerProps) {
    return <MuiDrawer onOpen={()=>{}} onClose={()=>{}} PaperProps={{...PaperProps, className: className, style: style}} {...other}>{children}</MuiDrawer>
}

interface Props {
    variant: DetailsSidebarKeysBase
    children?: React.ReactNode | Function
    open?: boolean
    expand?: boolean
    onClose?: Function
    className?: string
}

function scrollTo (target) {
    if (!target) return
    const element = document.getElementById(target)
    setTimeout(() => {
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, 600)

}

function DetailsSidebar ({ children, open, expand, variant, ...rest }: Props) {
    const [opened, setOpened] = useState(open)
    const [tab, setTab] = useState(expand ? 'profile' : 'collapsed')
    const [target, setTarget] = useState(undefined)
    const [expanded, setExpanded] = useState(expand)
    const [sidebarWidth, setSidebarWidth] = useState(window.innerWidth)
    const sidebarRef = createRef<HTMLElement>()
    // const [springs, api] = useSpring(() => ({
    //     from: { x: 0, width: 470 },
    // }))

    // const getSize = () => {
    //     const width = (drawerWidthExpanded/100) * sidebarWidth
    //     return width < 470 ? 470 : width
    // }
    //
    // const setSize = () => {
    //     if (!!sidebarRef.current) sidebarRef.current.style.width = `60%`
    // }

    // useEffect(()=>{
    //     if (expanded) {
    //         window.addEventListener('resize', () => {
    //             requestAnimationFrame(() => {
    //                 setSidebarWidth(window.innerWidth)
    //                 setSize()
    //             })
    //         })
    //         window.removeEventListener('resize', () => {})
    //     }
    // })

    const onClose = () => {
        if (rest.onClose) {
            rest.onClose()
        }
        setOpened(false)
    }

    const onTabChange = (value: string, t?: string | undefined) => {
        onToggleExpand(value, t)
    }

    const onToggleExpand = (newTab: string | undefined, t?: string | undefined) => {
        setExpanded(newTab !== 'collapsed')
        setTab(newTab || 'profile')
        scrollTo(t)
        // api.start({
        //     to: [
        //         {width: newTab !== 'collapsed' ? getSize() : 470},
        //     ]
        // })
    }

    useEffect(() => {
        if (!open) {
            onToggleExpand('collapsed', undefined)
        }
    }, [open])

    return (
        <Drawer PaperProps={{ref: sidebarRef}} open={open} expand={expanded} anchor="right" variant="persistent">
            <Header>
                <Close size="medium" onClick={onClose}><CloseIcon /></Close>
                <HeaderNavigation>
                    <SidebarTabs value={tab} onChange={onTabChange} variant={variant}/>
                </HeaderNavigation>
            </Header>
            <Body>
                <EdgeActive width={'calc(1.5rem + 16px)'} active={expanded} onClick={() => onToggleExpand(!expanded ? 'profile' : 'collapsed', undefined)} />

                {children instanceof Function
                    ? children({ tab, expanded, onSetViewState: onToggleExpand })
                    : children
                }
            </Body>
        </Drawer>
    )
}


export default DetailsSidebar
