import React from 'react'
import TinyFlag from 'tiny-flag-react'
import styled from 'styled-components'

const Root = styled.span`
  position: relative;
  top: 0.18em;
  left: ${props => props.padding && '0.18em'};
  margin-right: 0.3em;
`

const CountryFlag = ({country, alt, fallbackImageURL}) =>
  <Root padding={country === 'GLOBAL'}>
    <TinyFlag
      country={country}
      alt={alt}
      fallbackImageURL={fallbackImageURL}
    />
  </Root>

export default CountryFlag