import React from 'react'
import { MenuButton } from '../../components/Button'
import { Menu, MenuItem, AppBar as AppBarMui } from '@material-ui/core'

export default class SaveRun extends React.Component {
  state = { anchorEl: null }

  onSave = () => {
    this.props.onSave()
    this.onCloseMenu()
  }

  onSaveAndRun = () => {
    this.props.onSaveAndRun()
    this.onCloseMenu()
  }

  onOpenMenu = ({ currentTarget }) => this.setState({ anchorEl: currentTarget })
  onCloseMenu = () => this.setState({ anchorEl: null })

  render () {
    const { anchorEl } = this.state
    const { locked, inputs, thinking, hasChanges, hasUpdates } = this.props
    return (
      <>
        <MenuButton
          mb={2}
          color="primary"
          variant="contained"
          disabled={locked || (thinking && !hasUpdates)}
          onPrimaryClick={this.onSaveAndRun}
          onSecondaryClick={this.onOpenMenu}
        >
          {`${hasUpdates && !hasChanges ? '' : 'Save &  '}${inputs ? 'Re-run' : 'Run'} search`}
        </MenuButton>

        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.onCloseMenu}
        >
          <MenuItem onClick={this.onSaveAndRun} >
            {/*<PlayArrow />*/}
            {/*&nbsp;*/}
            {hasUpdates && !hasChanges ? `${inputs ? 'Re-run' : 'Run'} search` : `Save & ${inputs ? 'Re-run' : 'Run'} search`}
          </MenuItem>
          <MenuItem onClick={this.onSave} disabled={!hasChanges && inputs} >
            {/*<Save />*/}
            {/*&nbsp;*/}
            Save
          </MenuItem>
        </Menu>
      </>
    );
  }
}
