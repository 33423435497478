import React from 'react'
import styled from 'styled-components'
import {
  IconButton,
  InputAdornment,
  InputBase,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip as TooltipMUI,
  TableHead as TableHeadMUI, TableSortLabel,
  Menu,
  MenuItem
} from '@material-ui/core'
import { Search as SearchIconMUI, Clear as ClearIconMUI, MoreVert as MoreVertMUI} from '@material-ui/icons'
import Pane from '../Details/Pane'
import {formatNumber} from '../../utils/format'
import icons from '../AffiliateNetworkIcon'
import WebsiteIcon from './Icon'
import { orderBy } from 'lodash'
import ShowAll from '../ShowAll'
import NestedAffiliatesMap from '../../new-components/NestedAffiliatesMap'

import copyToClipboard from '../../utils/clipboard'
import {withStyles} from "@material-ui/core/styles";

const ICON_SIZE = 25

const Tooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: "unset",
    color: 'white'
  },
}))(TooltipMUI);

const TableCellBody = styled(TableCell)`
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 5px 15px;
  position: relative;
`;

const TableCellHead = styled(TableCell)`
  font-size: 12px;
  line-height: 12px;
  border: none;
  padding: 5px 15px;
  font-weight: normal;
  background: #E9F3FE;
  &:first-child{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  & .MuiTableSortLabel-root {
    opacity: 0.5;
    font-weight: normal;
  }
`;

const TableHead = styled(TableHeadMUI)`
`

const Icon = styled.div`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  //padding-right: 20px;
  //margin: 0 auto;
  svg {
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
  }
`;

const Wrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //width: 100%;
  //max-width: ${props => props.width};
`;

const Domain = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Search = styled(InputBase)`
  width: 100%;
  border: 1px solid #E9F3FE;
  box-sizing: border-box;
  border-radius: 5px;
  height: 30px;
  font-size: 14px;
  margin-bottom: 0.4em;
`

const SearchIcon = styled(SearchIconMUI)`
  width: 0.7em;
  margin-left: 0.3em;
  opacity: 0.5;
`

const ClearIcon = styled(ClearIconMUI)`
  width: 0.7em;
  opacity: 0.8;
`

const SortableCell = ({ orderBy, order, value, children, onSort, align }) => (
  <TableCellHead align={align}>
    <TableSortLabel
      active={orderBy === value}
      direction={orderBy === value ? order : 'asc'}
      onClick={onSort(value)}
    >
      { children }
    </TableSortLabel>
  </TableCellHead>
)

const Icons = styled.div`
  position: relative;
  height: ${ICON_SIZE}px;
  width: ${props => props.width}px;
`

const IconHolder = styled.div`
  left: 0;
  top: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid #E9F3FE;
  border-radius: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.2);
  &:last-child {
    box-shadow: none;
  }
`


class AffiliateLinks extends React.Component {
  static defaultProps = {
    minRows: 5
  }

  state = {
    orderBy: 'pages',
    order: 'desc',
    search: '',
    collapsed: true,
    copied: false,
    anchorEl: null
  }

  onSearch = ({ target: { value } }) => {
    this.setState({ search: value })
  }

  onClear = () => this.setState({ search: '' })

  onSort = (orderBy) => (e) => {
    if (this.state.orderBy === orderBy) {
      this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc'})
    } else {
      this.setState({ orderBy, order: 'desc' })
    }
  }

  onToggleCollapsed = () => this.setState({ collapsed: !this.state.collapsed })

  onOpenMenu = partnerId => ({ currentTarget }) => {
    this.setState({ anchorEl: currentTarget, partnerId })
  }

  onCloseMenu = () => {
    this.setState({ anchorEl: null, partnerID: null })
  }

  onCopyToClipboard = async () => {
    const { partnerId } = this.state
    this.setState({ copied: true })
    await copyToClipboard(partnerId)
    this.onCloseMenu()
    setTimeout(() => {
      this.setState({ copied: false })
    }, 1500)
  }

  mapIcons = (networks) => {
    const offset = ICON_SIZE / 2
    function renderIcon(iconArray) {
      return iconArray.map((icon, i) => {
        const localOffset = offset * i
        const zIndex = networks.length - i
        return <IconHolder style={{left: localOffset, zIndex, position: 'absolute'}}><Icon>{icons[icon] || icons['unknown']}</Icon></IconHolder>
      })
    }

    if (typeof networks !== 'string') {
      const containerSize  = ICON_SIZE + offset * networks.length - 1
      return <Icons width={containerSize}>{renderIcon(networks)}</Icons>
    } else {
      return <IconHolder><Icon>{icons[networks] || icons['unknown']}</Icon></IconHolder>
    }
  }

  get links () {
    const { data } = this.props
    return orderBy((data || []), [this.state.orderBy], [this.state.order])
  }

  get filtered () {
    const sorted = this.links
    const { search } = this.state
    if (search) {
      return sorted.filter(({ name }) => name.includes(search))
    }
    return sorted
  }

  get slicedList () {
    const { minRows } = this.props
    const { collapsed } = this.state
    const list = this.filtered

    return collapsed ? list.slice(0, minRows) : list
  }

  render() {
    const {
      data,
      minRows,
      targetWebsite,
      targetWebsiteIcon
    } = this.props
    const {
      order, orderBy, search, collapsed,
      anchorEl, partnerId
    } = this.state
    const listLength = data.length

    return (
      <Pane empty={data.length === 0} emptyMessage={'No Affiliate data is available'}>
        <Search
          value={search}
          onChange={this.onSearch}
          placeholder='Filter Domains'
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={ !!search && (
            <InputAdornment position="end">
              <IconButton onClick={this.onClear} size={'small'} style={{width: 30}}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
          }
        />
        <Table aria-label="Affiliate data table">
          <TableHead>
            <TableRow>
              <SortableCell
                value={'name'}
                order={order}
                orderBy={orderBy}
                onSort={this.onSort}
              >
                Target Website
              </SortableCell>
              <SortableCell
                value={'network'}
                order={order}
                orderBy={orderBy}
                onSort={this.onSort}
                align={'center'}
              >Network</SortableCell>
              <SortableCell
                value={'pages'}
                order={order}
                orderBy={orderBy}
                onSort={this.onSort}
                align='right'
              >
                <TooltipMUI title={'Unique affiliate links'}>
                  <span>Links</span>
                </TooltipMUI>
              </SortableCell>
              {/*<TableCell/>*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.slicedList.map((website, index) => (
                <TableRow key={index}>
                  <TableCellBody style={{width: '70%', maxWidth: 0}}>
                    <Domain>
                      <WebsiteIcon item={website} /><Wrap>{website.name}</Wrap>
                    </Domain>
                  </TableCellBody>
                  <TableCellBody align={'center'} width={'10%'}>
                    {/*<Tooltip title={<> {targetWebsite} &rarr; {this.mapNetworks(website.network)}  {website.name}</>}>*/}
                    <Tooltip title={<NestedAffiliatesMap
                      website={{name: targetWebsite, icon: { icon: targetWebsiteIcon }}}
                      target={{name: website.name, icon: website}}
                      networks={website.network} />
                    }>
                      {this.mapIcons(website.network)}
                    </Tooltip>
                  </TableCellBody>
                  <TableCellBody align='right' width={'19%'}>
                    {formatNumber(website.pages)}
                    {/*<IconButton*/}
                    {/*  disabled={!website.partnerID}*/}
                    {/*  size={'small'} style={{position: 'absolute', right: -10, top: 3, width: 29}}*/}
                    {/*  onClick={this.onOpenMenu(website.partnerID)}*/}
                    {/*>*/}
                    {/*  <MoreIcon />*/}
                    {/*</IconButton>*/}
                  </TableCellBody>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <ShowAll
          more={listLength > minRows}
          amount={minRows}
          total={data.length}
          collapsed={collapsed}
          onClick={this.onToggleCollapsed}
        />
        <Menu
          style={{ zIndex: 100000 }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.onCloseMenu}
        >
          <MenuItem disabled>{partnerId}</MenuItem>
          <MenuItem onClick={this.onCopyToClipboard}>Copy ID</MenuItem>
        </Menu>
      </Pane>
    );
  }
}

export default AffiliateLinks
