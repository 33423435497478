import React from 'react'
import { find } from 'lodash'
import { inject, observer } from 'mobx-react'
import BookDemoModal from './BookDemoModal'

@inject(({ BrandStore, UserStore }) => ({
  brandId: BrandStore.id,
  open: BrandStore.booking,
  billingStatus: BrandStore.billingStatus,
  onCloseBookingModal: () => BrandStore.closeBooking(),
  onTrialSubscribe: (plan) => BrandStore.selectSubscription(plan),

  user: UserStore.user
}))
@observer
export default class _BookingDemoModal extends React.Component {
  render () {
    const { open, billingStatus, onCloseBookingModal } = this.props

    if (!open) {
      return null
    }

    return <BookDemoModal
      {...this.props.user}
      brandId={this.props.brandId}
      open={open}
      billingStatus={billingStatus}
      onTrialSubscribe={this.props.onTrialSubscribe}
      onClose={onCloseBookingModal}
    />
  }
}
