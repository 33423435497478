import React, {useContext} from 'react'
import {DetailsContext} from '../DetailsContext'

import WidgetKeywords from '../../Widget/WidgetKeywords'

interface DetailsHighestRankingKeywordsProps {
  limit: number
  variant?: string
}

export default function DetailsHighestRankingKeywords ({ limit, variant }: DetailsHighestRankingKeywordsProps) {
  const { loading, keywords } = useContext(DetailsContext)

  const keywordsList = Boolean(keywords && keywords.length) ? keywords.slice(0, limit) : undefined

  return <WidgetKeywords loading={loading} variant={variant} size="2x2" keywords={keywordsList} />
}

DetailsHighestRankingKeywords.defaultProps = {
  limit: 3
}
