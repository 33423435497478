import { observable, action, computed, toJS } from 'mobx'
import param from 'can-param'
import { find } from 'lodash'
import { gql } from '@apollo/client'
import GqlClient from '../gql/gql-client'

import hasError from './request-message'
import { GET_CONTACTS } from '../gql/contacts'
import { prepareEdges } from '../utils/gql-data'
import { CONTACT_ENTITY_DETAILS } from '../components-ts/Details/requests'
import {
  convertItems,
  convertItemToDetails,
  contactToListItem,
  getPicturePath,
  contactList,
} from '../utils/list-items'
import { getSorting } from '../utils/sorting'
import { getFilterVariables } from '../configFilters'
import Config from '../config'
import { channelsTags, channelsSystemTags } from '../gql/channels'
import { getChannelFolders } from '../gql/folders'
import { MARK_CHANNEL_WITH_TAG, REMOVE_TAG_FROM_CHANNELS } from '../gql/tags'
import { createCSV, download } from '../utils/download-blod'

const CHANNEL_SIMPLE_FRAGMENT = gql`
  fragment CHANNEL_SIMPLE_FRAGMENT on Channel {
    id
    lang
    icon
    title
    domain
    mozDomainAuthority
    partnershipsInCount
    partnershipsOutCount
    type1Classification
    swVisits
    swGlobalRank
  }
`

const CHANNEL = gql`
  query GetChannel($id: String!) {
    channel(id: $id) {
      ...CHANNEL_SIMPLE_FRAGMENT
    }
  }
  ${CHANNEL_SIMPLE_FRAGMENT}
`

class ContactInfosStore {
  brandId = null

  @observable page = 1
  @observable perPage = Config.PER_PAGE
  @observable total = 0
  @observable order = 'asc'
  @observable orderBy = 'createdAt'
  @observable items = []
  @observable _filters = { search: '' }
  @observable loading = true
  @observable thinking = false

  constructor(brandId) {
    this.brandId = brandId
  }

  @computed
  get pagination() {
    return {
      take: 10000,
      skip: 0,
    }
  }

  @action
  async load(brandId) {
    this.brandId = brandId || this.brandId

    const variables = {
      ...this.pagination,
      orderBy: getSorting(this.order, this.orderBy),
      ...getFilterVariables(toJS(this._filters)),
      brandId: this.brandId,
    }

    const { success, ...rest } = await GqlClient.query({
      query: GET_CONTACTS,
      variables,
    })

    if (!hasError(success, null, null, () => this.load())) {
      let items = rest.data['contacts']

      items = contactList(items)

      items = await channelsTags(items, this.brandId)

      items = await getChannelFolders(items, this.brandId)

      items = await channelsSystemTags(items)

      this.items = items
      this.total = items.length
    }

    this.loading = false
  }

  @action
  async exportToCSV() {
    const contacts = toJS(this.items).map((contact) => {
      const { social, ...converted } = contactToListItem(contact)
      let row = { ...converted }
      if (social && social.length) {
        social.map(({ name, url }) => (row[name] = url))
      }
      return row
    })

    download(createCSV(contacts, 'contacts'), 'contacts.csv')
  }

  @action
  async nextPage() {
    this.page = this.page + 1
    await this.load()
  }

  @action
  async pageChange(page) {
    if (
      toJS(this.items).length < this.total &&
      !this.loading &&
      !this.thinking
    ) {
      this.page = this.page + 1
      await this.load()
    }
  }

  @action
  async perPageChange(perPage) {
    this.page = 1
    this.perPage = perPage
    await this.load()
  }

  @action
  async filtersChange(filters) {
    this.page = 1
    this._filters = filters
    await this.load()
  }

  @action
  async sorting(orderBy, order) {
    this.order = order
    this.orderBy = orderBy
    await this.load()
  }

  @action
  async addTags(channelId, tag) {
    this.items = toJS(this.items).map((item) => {
      if (item.channelId === channelId) {
        const { tags } = item
        return {
          ...item,
          tags: [tag, ...tags].filter((t) => Boolean(t)),
        }
      }
      return item
    })

    const payload = {
      id: tag.id,
      brandId: this.brandId,
      channelIds: [channelId],
    }

    return await GqlClient.mutation({
      mutation: MARK_CHANNEL_WITH_TAG,
      variables: { MarkChannelsWithTagInput: payload },
    })
  }

  @action
  async removeTags(channelId, tagId) {
    this.items = toJS(this.items).map((item) => {
      if (item.channelId === channelId) {
        const { tags } = item
        return {
          ...item,
          tags: tags.filter((tag) => tag.id !== tagId),
        }
      }
      return item
    })

    const payload = {
      id: tagId,
      brandId: this.brandId,
      channelIds: [channelId],
    }

    return await GqlClient.mutation({
      mutation: REMOVE_TAG_FROM_CHANNELS,
      variables: { RemoveTagFromChannelsInput: payload },
    })
  }

  @action
  async updateTags(tag) {
    this.items = toJS(this.items).map((item) => {
      let tags = (item.tags || []).map((t) => {
        if (t.id === tag.id) {
          return tag
        }
        return t
      })
      return { ...item, tags }
    })
  }

  @action
  async deleteTags(tagId) {
    this.items = toJS(this.items).map((item) => {
      const tags = item.tags || []
      return {
        ...item,
        tags: tags.filter((tag) => tag.id !== tagId),
      }
    })
  }

  @action
  setInitial(brandId) {
    this._filters = {}
    this.brandId = brandId || null
    this.page = 1
    this.perPage = 25
    this.total = 0
    this.order = 'desc'
    this.orderBy = 'updatedAt'
    this.items = []

    this.loading = true
    this.thinking = false
  }

  @computed
  get listProps() {
    const { loading, thinking, items, page, perPage, total, kind } = this
    return {
      kind,
      thinking,
      loading,
      items: toJS(items).map(contactToListItem),
      total,
      page,
      perPage,
    }
  }

  @computed
  get sortProps() {
    const { order, orderBy } = this
    return { order, orderBy }
  }

  @computed
  get filters() {
    const { ...rest } = this._filters
    return rest
  }

  @computed
  get queries() {
    const { page, perPage, order, orderBy, _filters } = this

    return param({
      page,
      perPage,
      order: `${order === 'desc' ? '' : '-'}${orderBy}`,
      filters: _filters,
    })
  }
}

export default new ContactInfosStore()

const getChannelKind = (inCount = 0, outCount = 0, type1Classification) => {
  if (!inCount && !outCount && type1Classification) return null //type1Classification === 'Brand' ? 'brand' : 'creator'
  return outCount >= inCount ? 'creator' : 'brand'
}

export class ContactsStore {
  brandId = null

  @observable channelId = null
  @observable contactInfos = []
  @observable entityDetails = null
  @observable channel = {}

  @observable loading = true
  @observable thinking = false

  constructor(brandId) {
    this.brandId = brandId
  }

  defaultData() {
    this.channelId = null
    this.contactInfos = []
    this.entityDetails = null
    this.channel = {}
  }

  @action
  async load(channelId) {
    this.defaultData()

    this.channelId = channelId
    this.loading = true

    const { success, ...rest } = await GqlClient.query({
      query: CONTACT_ENTITY_DETAILS,
      variables: { brandId: this.brandId, channelId, channelIds: [channelId] },
    })

    if (!hasError(success, 'message', '')) {
      const { channelEntityContacts, channelContacts, entity } = rest.data
      this.contactInfos = channelContacts.map((contact) => ({
        id: contact.id,
        ...convertItemToDetails(convertItems(contact.contactData)),
      }))

      this.contactInfos = [
        ...toJS(this.contactInfos),
        ...channelEntityContacts.map((contact) => ({
          id: contact.id,
          ...convertItemToDetails(convertItems(contact.contactData)),
        })),
      ]
      if (entity) {
        const entityDetails = convertItemToDetails(
          convertItems(entity.entityData)
        )

        let entityChannel = find(entity.channels, ['id', this.channelId])

        this.entityDetails = {
          ...entityDetails,
          ...(entityChannel && { channel: entityChannel }),
        }
      }

      await this.loadChannel(channelId)
    }

    this.loading = false
  }

  @action
  async loadChannel(channelId) {
    const { success, ...rest } = await GqlClient.query({
      query: CHANNEL,
      variables: { id: channelId },
    })

    if (!hasError(success, 'message', '')) {
      this.channel = rest.data['channel']
    }
  }

  @computed
  get contacts() {
    return {
      contactInfos: toJS(this.contactInfos),
      entityDetails: toJS(this.entityDetails),
    }
  }

  @computed
  get summary() {
    const {
      id,
      domain,
      title,
      description,
      lang,
      icon,
      type1Classification,
      swVisits,
      partnershipsInCount,
      partnershipsOutCount,
    } = toJS(this.channel)
    const channelKind = getChannelKind(
      partnershipsInCount,
      partnershipsOutCount,
      type1Classification
    )
    return {
      id,
      domain,
      title,
      description,
      languages: lang ? [lang] : [],
      icon,
      screenshot: getPicturePath(domain, 'main.png'),
      type: channelKind,
      partnershipsInCount,
      partnershipsOutCount,
      swVisits,
    }
  }
}
