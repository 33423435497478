import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { LinearProgress } from '@mui/material'
import styled, { css } from 'styled-components'
import { Tooltip, Popper, IconButton, Button } from '@material-ui/core'
import {
  DataGridPremium,
  LicenseInfo,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { makeStyles } from '@material-ui/styles'
import Title from '../../components/Leads/Title'
import { ContactStatus } from '/src/components/ContactStatus/ContactStatus'
import { FolderLabel } from '../Folder/FolderLabel'

import TagList from '../../new-components/Tags/TagList'
import { SystemTags } from '../../new-components/Tags/SystemTags'

import IconLink from '../../components-ts/IconLink'

import { ContactsQuickActions } from '../QuickActions/QuickActions'
import { getPicturePath } from '../../utils/list-items'

import config from '../../config'
import TextAvatar from 'react-avatar'
import { Facebook, Instagram, Linkedin, Twitter } from 'react-feather'
import getSocialHandle from '../../utils/getSocialHandle'

LicenseInfo.setLicenseKey(config.DATA_GRID_LICENSE_KEY)

const POPPER_PROPS = {
  eventsEnabled: false,
  disableEventListeners: true,
  modifiers: {
    offset: {
      offset: '0, -5px',
    },
    flip: {
      enabled: false,
    },
    preventOverflow: {
      padding: -35,
      boundariesElement: 'scrollParent',
      escapeWithReference: false,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .pinned': {
      position: 'sticky',
      zIndex: 3,
      top: 0,
      background: 'rgba(216, 239, 255, 1)!important;',
    },
    '& .group': {
      background: 'rgba(216, 239, 255, 1)!important;',
    },
    '& .group-item': {
      background: 'rgba(216, 239, 255, 0.5)!important;',
    },
    '& .cursor': {
      cursor: 'pointer',
    },
    '& .active-details': {
      background: theme.palette.brand.turquoise.light,
      transition: 'background .2s ease',
    },
    '& .marginLeft': {
      marginLeft: 10,
    },
    border: 'none!important',
    '& .MuiDataGrid-pinnedRows': {
      background: 'rgba(216, 239, 255, 1)!important',
    },
    '& .MuiDataGrid-row:focus': {
      outline: '1px solid blue!important',
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
      {
        outline: 'none!important',
      },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      fontSize: '12px!important',
    },
    '& .MuiDataGrid-columnSeparator': {
      // display: 'none'
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      padding: '0 8px!important',
    },
    '& .MuiDataGrid-cell.MuiDataGrid-cell--textRight': {
      padding: '12px 8px!important',
    },
    '& .MuiDataGrid-columnHeader--numeric': {
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        marginLeft: '20!important',
        padding: '0!important',
      },
      '& .MuiDataGrid-iconButtonContainer': {
        left: '3!important',
        position: 'absolute!important',
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      '& .MuiSvgIcon-root': {
        width: '0.8em',
        height: '0.8em',
        opacity: '0.75',
        // color: ;
      },
    },
    '& .MuiDataGrid-pinnedColumns--left, & .MuiDataGrid-pinnedColumnHeaders--left':
      {
        boxShadow: '0 0 0 0',
        // background: 'red!important'
      },
  },
}))

const Domain = styled(IconLink)`
  font-weight: bold;
  margin-right: 0.5em;
`

const Tags = styled.div`
  display: flex;
  column-gap: 0.3em;
  align-items: center;
  padding: 0.3em 0;
`

const TooltipContent = styled.div`
  a {
    opacity: 0.8;
    display: block;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0.3em 0;
    margin-top: 0.3em;
    text-decoration: none;
    color: white;
  }
`

export const Body = styled.div`
  flex: 1 1 15em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding: 0.2em 1em 0.2em 0;
  overflow: hidden;
  height: 100%;
  ${(props) =>
    props.disable &&
    css`
      pointer-events: none;
    `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding: 0.2em 1em 0.2em 0;
  position: relative;
  flex: 1 1 auto;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  line-height: normal;
  //justify-content: flex-start;
`

const LeadLabel = styled.div`
  position: relative;
  z-index: 1;
  width: 10px;
  height: 100%;
  &:after {
    content: '';
    display: block;
    width: 5px;
    height: 100%;
    ${(props) =>
      props.variant === 'overlap' &&
      css`
        background: ${config.labelColors.overlap};
      `}
    ${(props) =>
      props.variant === 'prospect' &&
      css`
        background: ${config.labelColors.prospect};
      `}
    ${(props) =>
      props.variant === 'partner' &&
      css`
        background: ${config.labelColors.partner};
      `}
  }
`

const LabelContainer = styled.div`
  height: 100%;
  position: absolute;
  left: -8px;
  top: 0;
  display: flex;
  flex-direction: column;
`

const ActionsHolder = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 10;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  svg {
    margin: 4px 2px;
  }
`

const ActionsBackground = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: white;
  display: flex;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.palette.brand.grey.main};
  overflow: hidden;
  pointer-events: all;
`

const Result = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
    font-weight: normal;
  }
`

const icon = {
  twitter: <Twitter size={16} />,
  instagram: <Instagram size={16} />,
  linkedin: <Linkedin size={16} />,
  facebook: <Facebook size={16} />,
}

export const Root = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  margin: 0 1em 0 0;
  display: flex;
  align-items: center;
`

// const Root = MuiStyled('div')(({ theme, secondary, value }) => ({
//   position: 'relative',
//   overflow: 'hidden',
//   width: '100%',
//   height: '100%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
// }));

const SocialItem = ({ variant, url }) => {
  const handle = getSocialHandle(url)
  return url ? (
    <Tooltip title={`${variant}: ${handle}`}>
      <IconButton size='small' target='_blank' href={url}>
        {icon[variant]}
      </IconButton>
    </Tooltip>
  ) : (
    ''
  )
}

const MoreButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
  padding: 0.02em;
  margin-left: 0.5em;
  display: flex;
  justify-content: center;
  height: 21.59px;
  width: 21.59px;
  border-radius: 10px;
  overflow: hidden;
  min-width: auto;
  background-color: ${(props) => props.theme.palette.brand.blue.light};
  opacity: 0.8;
  font-size: 12px;
`

const ListMore = ({ items, ...rest }) => {
  const more = items.length > 1
  return (
    <>
      {items[0]} {more && <MoreButton>+{items.length - 1}</MoreButton>}
    </>
  )
}

const HeadTitle = (props) => {
  const context = useContext(ListContext)
  const { total, onQuickActions } = context
  const onActions = (id, action, direction) => {
    onQuickActions(id, action, direction)
  }

  return (
    <div style={{ display: 'flex' }}>
      <Root>
        <Result>
          <strong>{total} results</strong>
        </Result>
      </Root>
      {props.export && (
        <>
          &nbsp;&nbsp;
          <ContactsQuickActions header onAction={onActions} />
        </>
      )}
    </div>
  )
}

const ListContext = createContext({})

const contactColumns = [
  {
    field: 'domain',
    headerName: 'Channel',
    flex: 1,
    sortable: false,
    minWidth: 400,
    renderHeader: () => {
      return <HeadTitle export={true} />
    },
    renderCell: renderTitle,
  },
  {
    field: 'firstName',
    headerName: 'Name',
    sortable: true,
    renderCell: (params) => {
      const {
        row: { name },
      } = params
      return (
        <>
          <TextAvatar
            name={name}
            textSizeRatio={1.8}
            size={25}
            round={40}
            fgColor={'black'}
            colors={config.avatarColors}
            variant='rounded'
          />{' '}
          &nbsp;&nbsp;
          {name}
        </>
      )
    },
    minWidth: 150,
  },
  {
    field: 'jobTitle',
    headerName: 'Job Title',
    sortable: true,
    minWidth: 150,
  },
  {
    field: 'emails',
    headerName: 'Emails',
    sortable: false,
    valueGetter: (params) => {
      const {
        row: { companyEmails },
      } = params
      return [...params.value, ...companyEmails]
    },
    renderCell: (params) => <ListMore items={params.value} />,
    minWidth: 300,
  },
  {
    field: 'social',
    headerName: 'Social',
    sortable: false,
    renderCell: (params) =>
      (params.value || []).map((item) => (
        <SocialItem variant={item.name} url={item.url} />
      )),
    minWidth: 80,
  },
  {
    field: 'user',
    headerName: 'Requester',
    sortable: false,
    renderCell: (params) => {
      const {
        row: { user },
      } = params
      return (
        <TextAvatar
          name={user}
          textSizeRatio={1.8}
          size={25}
          round={40}
          fgColor={'black'}
          colors={config.avatarColors}
          variant='rounded'
        />
      )
    },
    width: 80,
  },
  {
    field: 'updatedAt',
    headerName: 'Found',
    sortable: true,
    width: 140,
  },
]

const contactRequestColumns = [
  {
    field: 'domain',
    headerName: 'Channel',
    flex: 1,
    sortable: false,
    minWidth: 400,
    renderHeader: () => {
      return <HeadTitle export />
    },
    renderCell: renderTitle,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    renderCell: (params) => (
      <ContactStatus fontSize={'0.9em'} status={params.value} />
    ),
    width: 150,
  },
  {
    field: 'user',
    headerName: 'Requester',
    sortable: false,
    renderCell: (params) => {
      const {
        row: { user },
      } = params
      return (
        <TextAvatar
          name={user}
          textSizeRatio={1.8}
          size={25}
          round={40}
          fgColor={'black'}
          colors={config.avatarColors}
          variant='rounded'
        />
      )
    },
    width: 80,
  },
  {
    field: 'createdAt',
    headerName: 'Requested',
    sortable: true,
    width: 200,
  },
]

export const ColumnTooltipLogo = ({ title, logo, children }) => (
  <Tooltip
    PopperProps={{
      popperOptions: {
        modifiers: {
          offset: {
            offset: '0, 20px',
          },
        },
      },
    }}
    interactive
    title={
      <TooltipContent>
        <strong>{title}</strong>
        <br />
        {children}
      </TooltipContent>
    }
  >
    <span>{logo}</span>
  </Tooltip>
)

export const LoadingOverlay = () => {
  return <LinearProgress />
}

export function renderTitle(params) {
  const ref = useRef(null)
  const [showQuickActions, setShowQuickActions] = useState(null)

  const {
    value,
    row: {
      title,
      type1Classification,
      kind,
      domain,
      id,
      channelId,
      contactStatus,
      folderName,
      tags,
      systemTags,
      ...rest
    },
  } = params

  const icon = getPicturePath(domain, rest.icon)

  const onToggleShowQuickActions = ({
    type: mouseEventType,
    currentTarget,
  }) => {
    if ('mouseleave' === mouseEventType) {
      setShowQuickActions(null)
    } else if ('mouseenter' === mouseEventType) {
      setShowQuickActions(currentTarget)
    }
  }

  const onQuickActions = (id, action) => {
    rest.onQuickActions(
      id,
      action,
      domain,
      action === 'toManageTags' ? { target: ref.current } : null
    )
  }

  const additionalTagsCount = (Boolean(contactStatus) ? 1 : 0) ? 1 : 0

  return (
    <Body
      ref={ref}
      onMouseEnter={onToggleShowQuickActions}
      onMouseLeave={onToggleShowQuickActions}
    >
      <LabelContainer>
        {kind === 'partner' && (
          <Tooltip
            title={"Existing partner or a partner you've found with Breezy."}
          >
            <LeadLabel variant={'partner'} />
          </Tooltip>
        )}
        {kind === 'relevant' && (
          <Tooltip title={'Already added to Relevant.'}>
            <LeadLabel variant={'overlap'} />
          </Tooltip>
        )}
      </LabelContainer>

      <Popper
        style={{ zIndex: 1000 }}
        id={`item-qa-${channelId}`}
        placement={'top-start'}
        popperOptions={POPPER_PROPS}
        open={Boolean(showQuickActions)}
        anchorEl={showQuickActions}
      >
        <ActionsHolder>
          <ActionsBackground>
            <ContactsQuickActions
              id={id}
              domain={domain}
              onAction={onQuickActions}
              contactStatus={contactStatus}
            />
          </ActionsBackground>
        </ActionsHolder>
      </Popper>

      <Content>
        <Header>
          <Domain icon={icon} domain={domain} />
          <Title>{title}</Title>
        </Header>
        <Tags>
          <TagList
            variant={'item'}
            tags={tags}
            maxTags={1}
            additionalTagsCount={additionalTagsCount}
            onUpdate={(tag) => rest.onManageTags({ tag }, 'update')}
            onRemove={(tagId) =>
              rest.onManageTags({ channelId, tagId }, 'remove')
            }
            onDelete={(tagId) => rest.onManageTags({ tagId }, 'delete')}
            onManage={console.log /*e => itemActions.onManageTags(e, item.id)*/}
          >
            {Boolean(folderName) && <FolderLabel name={folderName} />}
            {Boolean(contactStatus) && <ContactStatus status={contactStatus} />}
            {Boolean(systemTags && systemTags.length) && (
              <SystemTags variant={'manual'} data={systemTags} limit={1} />
            )}
          </TagList>
        </Tags>
      </Content>
    </Body>
  )
}

export function ContactsTable(props) {
  const apiRef = useGridApiRef()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading !== props.loading) {
      setLoading(props.loading)
      if (!loading) {
        apiRef.current.forceUpdate()
      }
    }
  }, [props.loading])

  return (
    <ListContext.Provider
      value={{
        total: props.total,
        onQuickActions: props.onQuickActions,
      }}
    >
      <DataGridPremium
        apiRef={apiRef}
        sortingMode='server'
        paginationMode='server'
        sortingOrder={['desc', 'asc']}
        // disableVirtualization
        // disableColumnResize
        classes={classes}
        rowHeight={config.ROW_HEIGHT}
        hideFooter
        disableColumnMenu
        disableSelectionOnClick
        getRowClassName={(params) => {
          let classNames = []
          const activeItem = params.row.active
          if (activeItem) {
            classNames.push('active-details')
          }
          return classNames
        }}
        {...props}
        columns={contactColumns}
        slots={{
          loadingOverlay: LoadingOverlay,
        }}
      />
    </ListContext.Provider>
  )
}

export function ContactRequestsTable(props) {
  const apiRef = useGridApiRef()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading !== props.loading) {
      setLoading(props.loading)
      if (!loading) {
        apiRef.current.forceUpdate()
      }
    }
  }, [props.loading])

  return (
    <ListContext.Provider
      value={{
        total: props.total,
        onQuickActions: props.onQuickActions,
      }}
    >
      <DataGridPremium
        apiRef={apiRef}
        sortingMode='server'
        paginationMode='server'
        sortingOrder={['desc', 'asc']}
        disableVirtualization
        // disableColumnResize
        classes={classes}
        // rowHeight={69}
        hideFooter
        disableColumnMenu
        disableSelectionOnClick
        getRowClassName={(params) => {
          let classNames = []
          const activeItem = params.row.active
          if (activeItem) {
            classNames.push('active-details')
          }
          return classNames
        }}
        {...props}
        columns={contactRequestColumns}
        slots={{
          loadingOverlay: LoadingOverlay,
        }}
      />
    </ListContext.Provider>
  )
}
