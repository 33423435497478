import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { find, unionBy, isEqual } from 'lodash'
import { Button } from '@material-ui/core'

import PageMain from '../../layouts/PageMain'
import LeadsPlaceholder from '../../components/LeadsPlaceholder'
import ImportExistingPartners from '../../components/Partners/Import'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import RequestContacts from '../../components/Modals/_RequestContactsModal'

import { Blocker } from '../../components/Blocker'

import Filter from '../../new-components/Filter'
import ProductFilter from '../../new-components/Filter/ProductFilter'
import TagsManagement from '../../new-components/Tags/_TagsManagement'
import { FoldersMenu } from '../../new-components/Folder'
import ListTable from '../../new-components/List/ListTable'

import Details from '../../components-ts/Details/Details'

import Config from '../../config'
import {
  SEARCH,
  TYPE1CLASSIFICATION,
  LANGUAGES,
  COUNTRIES,
  TAGS,
  AVG_SCORE,
  TOTAL_COMPETITORS_COUNT,
  TOTAL_KEYWORDS_COUNT,
  VISITS,
  RANK,
  MOZ,
  DIVIDER_TOP,
  DIVIDER_BOTTOM,
  SYSTEM_ENTITY,
  SYSTEM_CATEGORY,
  SYSTEM_NICHE,
  PARTNERSHIPS_ONLY,
} from '../../configFilters'
import { sendEvent } from '../../utils/events'
import { getTagsList } from '../../utils/tags'

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 0.5em 1em 0;
`

const computeFiltersConfig = ({ maxValues }) => {
  return [
    SEARCH,
    LANGUAGES,
    COUNTRIES,
    TAGS,
    SYSTEM_ENTITY,
    SYSTEM_CATEGORY,
    SYSTEM_NICHE,
    AVG_SCORE,
    { ...TOTAL_COMPETITORS_COUNT, max: 0 },
    { ...TOTAL_KEYWORDS_COUNT, max: 0 },
    MOZ,
    VISITS,
    RANK,
    PARTNERSHIPS_ONLY,
  ]
}

export default class Prospects extends React.Component {
  partnerDetailsRef = null

  constructor(props) {
    super(props)
    this.state = {
      openImportPartners: false,
      confirmationPartnersOpen: false,
      selected: [],
      draggedItems: null,
      requestContacts: {},
      collapsedId: null,
    }
  }

  loadData = () => {
    const { folderId, brandId } = this.props
    if (folderId) {
      this.props.onLoad(brandId, null, folderId, true)
    } else {
      this.props.onLoad(brandId, null, null, true)
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { folderId } = this.props
    if (folderId !== prevProps.folderId) {
      this.loadData()
    }
  }

  onRemoveSelection = () => this.setState({ selected: [] })

  onSelectAll = () => {
    const {
      listProps: { items },
    } = this.props
    this.setState({ selected: items.map(({ id }) => id) })
  }

  onSelect = (selected /* id, kind */) => this.setState({ selected })

  ///Filters
  get filtersConfig() {
    return computeFiltersConfig({ ...this.props })
  }

  onProductFilterChange = (id) => {
    const { product, ...rest } = this.props.filters

    return this.props.onFiltersChange({ ...rest, searchId: id })
  }

  onFiltersChange = (filters = {}) => {
    this.props.onFiltersChange(filters)
    this.onClosePartnerDetails()
    sendEvent(this.props.brandId, 'filtersApplied')
  }

  // Import Existing Partners
  onImportExistingPartners = async (details) => {
    const { error } = await this.props.onImportExistingPartners(details)
    this.props.onUpdateBrandCounters()
    return { error }
  }
  onOpenImportExistingPartners = () =>
    this.setState({ openImportPartners: true })
  onCloseImportExistingPartners = () =>
    this.setState({ openImportPartners: false })

  // Actions with items
  get confirmationMessage() {
    const { kind } = this.props
    const { actionKind } = this.state
    const target = kind === 'partners' ? 'Relevant' : 'Partners'
    const source = kind === 'relevants' ? 'Relevant' : 'Partners'
    if (actionKind === 'mark') {
      return `You're about to permanently mark these ${source} as ${target}. Are you sure you want to continue?`
    }
    return `You're about to permanently remove one or more Leads from your ${source}. Are you sure you want to continue?`
  }

  get confirmationAction() {
    const { kind } = this.props
    const { actionKind } = this.state
    const target = kind === 'partners' ? 'Relevant' : 'Partners'
    const source = kind === 'relevants' ? 'Relevant' : 'Partners'
    if (actionKind === 'mark') {
      return `Mark as ${target}`
    }
    return `Remove from ${source}`
  }

  get confirmationPartnersProps() {
    const { confirmationPartnersOpen } = this.state
    if (!confirmationPartnersOpen) {
      return {}
    }

    return {
      message: this.confirmationMessage,
      actionText: this.confirmationAction,
      onAction: this.onToggle,
    }
  }

  onToggle = async (ids, action) => {
    const { kind } = this.props
    const { partnerId, actionKind, selected, draggedItems } = this.state
    const toggleIds = Array.isArray(ids) ? ids : draggedItems
    const needToCloseDetails = partnerId && toggleIds.includes(partnerId)

    this.props
      .onToggle(kind, action || actionKind, toggleIds)
      .then(this.props.onUpdateBrandCounters)

    if (this.kind === 'relevants') {
      if (this.props.folderId) {
        this.props.onUpdateRootList(ids, { folder: null })
      } else {
        this.props.onRemoveFromFolders(ids)
      }
      this.props.onRemoveItems(ids)
    }

    let newSelected = selected

    if (isEqual(draggedItems, selected) || isEqual(selected, ids)) {
      newSelected = []
    }

    this.setState({
      confirmationPartnersOpen: false,
      actionKind: null,
      draggedItems: null,
      selected: newSelected,
      ...(needToCloseDetails && {
        details: null,
        detailsKind: null,
        partnerId: null,
        channelId: null,
      }),
    })
  }

  onRemove = (ids) => {
    this.setState({
      confirmationPartnersOpen: true,
      actionKind: `unmark`,
      draggedItems: Array.isArray(ids) ? ids : null,
    })
  }

  onMarkAs = () => {
    this.setState({ actionKind: `mark` }, this.onToggle)
  }

  onCloseConfirmationPartners = () =>
    this.setState({ confirmationPartnersOpen: false })

  onDragEnd = ({ leadIds, target, folderId, folderName }) => {
    if (target) {
      if (target === 'folder') {
        this.onMoveToFolder(leadIds, folderId, folderName)
      } else if (target === 'relevants' && this.props.folderId) {
        this.props.onRemoveFromFolder(leadIds, this.props.folderId)
        this.props.onRemoveItems(leadIds)
        this.props.onUpdateRootList(leadIds, { folder: null })
      } else {
        this.setState({ draggedItems: leadIds }, this.onMarkAs)
      }
    }
  }

  onCloseLeadDetails = () => {
    this.setState({ leadId: null, channelId: null, details: false })
  }

  // Open/Close partner details
  onListItemClick = async (partnerId, detailsKind) => {
    if (detailsKind === 'expand') {
      const { collapsedId } = this.state
      this.setState({
        collapsedId: collapsedId !== partnerId ? partnerId : null,
      })
    } else {
      this.onCloseLeadDetails()
      const {
        listProps: { items },
      } = this.props
      const item = items.filter(({ id }) => id === partnerId)[0]
      this.setState({
        partnerId,
        channelId: item.channelId,
        details: true,
        detailsKind /*, selected: [partnerId] */,
      })
    }
    await sendEvent(this.props.brandId, 'showDetails')
  }

  onClosePartnerDetails = () => {
    this.setState({
      partnerId: null,
      channelId: null,
      details: false,
      detailsKind: null,
    })
  }

  //Notes
  onUpdateNotesCount = (id, count) => {
    this.props.onUpdateNotesCount(id, count)
  }

  //////// Contacts
  onRequestContactsClose = () => {
    this.setState({ requestContacts: {} })
  }

  onRequestContacts = (itemId, outChannelId) => {
    const { selected, partnerId } = this.state
    let channelId = outChannelId

    if (!channelId) {
      const partner = find(this.props.listProps.items, ['id', itemId])
      if (partner) {
        channelId = partner.channelId
      } else {
        return
      }
    }

    this.setState({
      requestContacts: {
        open: true,
        itemIds: itemId,
        channelId,
        onRequestSingle: this.props.onRequestSingle,
        callback: (success) => {
          if (selected.includes(itemId) || partnerId === itemId) {
            this.partnerDetailsRef.setContactRequested(success)
          }
        },
      },
    })
  }

  onRequestSelectedContacts = () => {
    const { selected, partnerId } = this.state

    this.setState({
      requestContacts: {
        open: true,
        itemIds: selected,
        onRequestSelected: this.props.onRequestSelected,
        callback: (success) => {
          if (
            partnerId &&
            selected.indexOf(partnerId) >= 0 &&
            this.partnerDetailsRef
          ) {
            this.partnerDetailsRef.setContactRequested(success)
          }
        },
      },
    })
  }

  ///////////////// Tags /////////////////
  get partnerTags() {
    const { tagsManagementPartner, selected } = this.state

    if (tagsManagementPartner) {
      const lead = find(this.props.listProps.items, [
        'id',
        tagsManagementPartner,
      ])
      return lead.tags || []
    } else if (selected && selected.length) {
      const partnerTags = this.props.listProps.items
        .filter(({ id }) => selected.includes(id))
        .map(({ tags: t }) => t || [])
      let allTags = []
      partnerTags.forEach((t) => {
        allTags = unionBy(allTags, t, 'id')
      })

      let crossTags = []
      let tagCounter = 0
      allTags.forEach((tag) => {
        partnerTags.forEach((tags) => {
          const t = find(tags, ['id', tag.id])
          if (t) {
            tagCounter += 1
          }
        })
        if (tagCounter === partnerTags.length) {
          crossTags.push(tag)
        }
        tagCounter = 0
      })

      let result = []

      allTags.forEach((tag) => {
        if (!crossTags.some((t) => t.id === tag.id)) {
          result.push({ ...tag, unique: true })
        }
      })

      return [...result, ...crossTags]
    }
    return null
  }

  onManagePartnerTags = (e, partnerId) => {
    const { target } = e

    if (e.stopPropagation && e.nativeEvent) {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }
    this.setState({
      tagsManagementOpen: target,
      tagsManagementKind: 'partner',
      tagsManagementPartner: partnerId || null,
    })
  }

  onManagePartnerTagsClose = () => {
    this.setState({
      tagsManagementOpen: null,
      tagsManagementKind: null,
      tagsManagementPartner: null,
    })
  }

  onUpdateTag = (tag) => {
    this.props.onUpdateTag(tag)
    this.props.onUpdatePartnerTags(tag)
    if (this.partnerDetailsRef) {
      this.partnerDetailsRef.updateTags(tag, 'update')
    }
  }

  onRemoveTag = (tagId, partnerId) => {
    const partnerIds = partnerId ? [partnerId] : this.state.selected
    this.props.onRemovePartnerTags(partnerIds, [tagId])
    if (this.partnerDetailsRef && partnerId === this.state.partnerId) {
      this.partnerDetailsRef.updateTags(tagId, 'remove')
    }
  }

  onDeleteTag = (tagId) => {
    this.props.onDeleteTag(tagId)
    this.props.onRemovePartnerTags(
      this.props.listProps.items.map(({ id }) => id),
      [tagId]
    )
    if (this.partnerDetailsRef) {
      this.partnerDetailsRef.updateTags(tagId, 'remove')
    }
  }

  onAddPartnerTag = (tag) => {
    const { tagsManagementPartner, selected } = this.state
    const leadIds =
      tagsManagementPartner && tagsManagementPartner.length
        ? [tagsManagementPartner]
        : selected
    this.props.onAddPartnerTags(leadIds, [tag], this.props.brandId)
    if (this.partnerDetailsRef && leadIds.includes(this.state.partnerId)) {
      this.partnerDetailsRef.updateTags(tag, 'add')
    }
  }

  onQuickAction = async (id, action, e) => {
    const { selected } = this.state
    let ids = selected

    if (id && !selected.includes(id)) {
      ids = [id]
    }

    if (action === 'toExport') {
      return await this.props.onExportToCSV()
    } else if (!ids || !ids.length) {
      return false
    } else if (action === 'toFolder') {
      this.onFolderMenuOpen(e, ids)
    } else if (action === 'toManageTags') {
      this.onManagePartnerTags(e, selected.includes(id) ? null : id)
    } else if (action === 'toPartners') {
      this.onToggle(ids, 'mark').then()
    } else if (action === 'toRequestContacts') {
      if (!id || selected.includes(id)) {
        this.onRequestSelectedContacts()
      } else {
        this.onRequestContacts(id)
      }
    } else if (action === 'toRemove') {
      this.onRemove(ids)
    }
  }

  ///////////////////////
  onMoveToFolder = (partnerIds, folderId, folderName) => {
    const folder = { id: folderId, name: folderName }
    // const items = this.props.listProps.items.filter(item => partnerIds.includes(item.id) && item.folderId !== folderId)
    const ids = partnerIds //items.map(({ id }) => id)

    this.props.onAddToFolder(ids, folderId, this.props.folderId)
    this.props.onMoveToFolder(ids, folder)

    if (this.props.folderId) {
      if (ids.includes(this.state.partnerId)) {
        this.onClosePartnerDetails()
      }
      this.props.onRemoveItems(ids)
      this.props.onUpdateRootList(ids, { folder })
    } else {
      this.props.onUpdateItems(ids, { folder })
    }
  }

  onAddToFolder = async (folderId, folderName) => {
    const { onToFolderIds, selected } = this.state
    const ids = onToFolderIds || selected
    this.onMoveToFolder(ids, folderId, folderName)
    this.onFolderMenuClose()
  }

  onFolderMenuOpen = (e, ids) => {
    this.setState({
      onToFolderTarget: e.target,
      onToFolderIds: ids,
    })
  }

  onFolderMenuClose = () => {
    this.setState({
      onToFolderTarget: null,
      onToFolderIds: null,
    })
  }

  get partnerDetails() {
    const { partnerId } = this.state
    const partner = find(this.props.listProps.items, ['id', partnerId])

    if (partner) {
      return {
        notesCount: partner.notesCount || null,
        itemTags: partner.tags || [],
        itemFolder: partner.folderName,
      }
    } else if (Boolean(this.state.details)) {
      this.onCloseLeadDetails()
    }

    return {}
  }

  get placeholderState() {
    const { kind, counters } = this.props

    if (kind === 'relevants' && !counters.relevantsCount) {
      return null //'emptyLeads'
    } else if (kind === 'partners' && !counters.partnersCount) {
      //TODO: Remove this after fixing the counters
      return null // 'importPartners'
    }
    return null
  }

  get canRequestContacts() {
    const { selected } = this.state
    const {
      listProps: { items },
    } = this.props
    if (!selected.length) return false
    const itemsRequested = items.filter(
      ({ id, contactStatus }) =>
        ['requested', 'found', 'optedout'].includes(contactStatus) &&
        selected.indexOf(id) >= 0
    )
    return itemsRequested.length < selected.length
  }

  onSort = (key, value) => {
    this.onClosePartnerDetails()
    this.props.onSort(key, value, () => {
      sendEvent(this.props.brandId, 'sortingApplied')
    })
  }

  onPageChange = (page) => {
    // this.onClosePartnerDetails()
    this.props.onPageChange(page)
  }

  onPerPageChange = (perPage) => {
    this.onClosePartnerDetails()
    this.props.onPerPageChange(perPage)
  }

  render() {
    const { kind, loading, filters, listProps, sortProps } = this.props
    const {
      selected,
      openImportPartners,
      confirmationPartnersOpen,
      partnerId,
      leadId,
      channelId,
      details,
      detailsKind,
    } = this.state
    const selectedAmount = selected.length
    const total =
      listProps.perPage > listProps.total ? listProps.total : listProps.perPage

    const activeItem = leadId || partnerId

    return (
      <>
        <Helmet title={kind === 'partners' ? 'Partners' : 'Relevant'} />
        <PageMain thinking={loading} open={details && selectedAmount <= 1}>
          <Blocker />
          <LeadsPlaceholder
            thinking={loading}
            state={this.placeholderState}
            onImportPartners={this.onOpenImportExistingPartners}
          >
            <Header>
              {kind === 'relevants' && (
                <ProductFilter
                  value={filters.searchId}
                  options={this.props.products.map(
                    ({ name, description, id }) => ({
                      label: description,
                      value: id,
                    })
                  )}
                  onChange={this.onProductFilterChange}
                />
              )}
              {kind === 'partners' && (
                <Button
                  style={{
                    marginRight: '1em',
                    marginLeft: '1.2em',
                    width: 264,
                    height: 50,
                  }}
                  variant={'outlined'}
                  color={'primary'}
                  size={'medium'}
                  disableElevation
                  onClick={this.onOpenImportExistingPartners}
                >
                  Import existing partners
                </Button>
              )}
              <Filter
                variant={kind}
                placeholder={'Select a filter'}
                filters={filters}
                keys={this.filtersConfig}
                onChange={this.onFiltersChange}
              />
            </Header>

            <ListTable
              {...listProps}
              items={listProps.items.map((item) => ({
                ...item,
                activeItem: partnerId,
                selected, //: selected.indexOf(item.id) >= 0,
                fakeIds: [],
                quickActionsProps: {
                  view: 'brand',
                  kind,
                },
                view: 'brand',
                kind: 'partners',
                source: kind,
                folder: this.props.folderId,
                itemActions: {
                  onDragEnd: this.onDragEnd,
                  onQuickAction: this.onQuickAction,
                  onUpdateTag: this.onUpdateTag,
                  onRemoveTag: this.onRemoveTag,
                  onDeleteTag: this.onDeleteTag,
                  onManageTags: this.onManagePartnerTags,
                },
              }))}
              view={'brand'}
              kind={'partners'}
              locked={this.props.paywall}
              lockedKind={'paywall'}
              lockedThreshold={
                kind === 'relevants'
                  ? Config.PAYWALL_RELEVANT_THRESHOLD
                  : Config.PAYWALL_PARTNERS_THRESHOLD
              }
              source={kind}
              selected={selected}
              sortProps={sortProps}
              quickActionProps={{
                kind,
                view: 'brand',
                disable: selectedAmount < 1,
                canRequestContacts: this.canRequestContacts,
                onAction: this.onQuickAction,
              }}
              hasFilters={this.props.hasFilters}
              onClick={this.onListItemClick}
              onSelect={this.onSelect}
              onGetAllIds={this.props.onGetAllIds}
              onSort={this.onSort}
              onPageChange={this.onPageChange}
              onPerPageChange={this.onPerPageChange}
            />
          </LeadsPlaceholder>
        </PageMain>

        <Details
          ref={(node) => (this.partnerDetailsRef = node)}
          variant={this.props.kind === 'partners' ? 'partner' : 'relevant'}
          open={Boolean(partnerId && details)}
          channelId={channelId}
          itemId={partnerId}
          brandId={this.props.brandId}
          geoFilter={this.props.geoFilter}
          tagManagement={{
            onUpdate: this.onUpdateTag,
            onRemove: this.onRemoveTag,
            onDelete: this.onDeleteTag,
            onManage: this.onManagePartnerTags,
          }}
          onQuickActions={this.onQuickAction}
          onClose={this.onClosePartnerDetails}
        />

        {openImportPartners && (
          <ImportExistingPartners
            open={openImportPartners}
            onClose={this.onCloseImportExistingPartners}
            onUpdate={this.onImportExistingPartners}
          />
        )}

        {/* Confirm remove partners */}
        <ConfirmationDialog
          open={confirmationPartnersOpen}
          onClose={this.onCloseConfirmationPartners}
          {...this.confirmationPartnersProps}
        />

        {Boolean(this.state.tagsManagementOpen) && (
          <TagsManagement
            itemTags={this.partnerTags}
            kind={this.state.tagsManagementKind}
            leadIds={this.state.tagsManagementPartner}
            anchorEl={this.state.tagsManagementOpen}
            onClose={this.onManagePartnerTagsClose}
            onSelectTag={this.onAddPartnerTag}
            onUpdateTag={this.onUpdateTag}
            onRemoveTag={this.onRemoveTag}
            onDeleteTag={this.onDeleteTag}
          />
        )}

        <FoldersMenu
          folderId={this.props.folderId}
          anchorEl={this.state.onToFolderTarget}
          onClick={this.onAddToFolder}
          onClose={this.onFolderMenuClose}
        />

        <RequestContacts
          {...this.state.requestContacts}
          onClose={this.onRequestContactsClose}
        />
      </>
    )
  }
}
