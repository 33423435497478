import React, { Component, useContext } from 'react'
import styled, {css} from 'styled-components'
import { rgba } from 'polished'
import { StyledEngineProvider } from '@mui/material/styles'
import HideOnScroll from '../HideOnScroll'
import {DetailsContext} from '../Details/DetailsContext'
import ActionButton from '../ActionButton'
import actions from '../../actionsConfig'

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 0;
  position: sticky;
  background: white;
  top: 0;
  z-index: 10;
  gap: 0.3em;
  padding: 0.5em 1em 0.5em 0;
  ${props => props.vertical && css`
    flex-direction: column;
    height: auto;
    & > button {
      width: 100%;
      height: 4.7em;
    }
  `
  }
`

interface ItemProps {
    id: string
    partner?: boolean
    prospect?: boolean
    fake?: boolean
    requested?: boolean
}

interface ActionsProps {
    variant?: 'lead' | 'partner' | 'gap' | 'relevant' | 'partnerships'
    kind?: 'irrelevant' | 'competitors' | 'partners' | 'leads'
    item: ItemProps
    className?: string
    onClick?: Function
    loading?: boolean,
    vertical?: boolean
}

function Actions (props: ActionsProps) {
    const { item, variant, contacts, kind, loading, vertical} = props
    const isIntelligence = ['gap', 'landscape', 'partnerships'].includes(variant)

    const onClick = (id: string, action: string, direction?: string) => (e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        return props.onClick(id, action, direction || e)
    }

    const disableAddToRelevant = isIntelligence && item.prospect
    const disableAddToPartners = isIntelligence && item.partner

    const btnRequestContact = <ActionButton variant="request-contact"
                                            disabled={loading || Boolean(contacts.status)}
                                            filled={item.requested}
                                            title={item.requested ? `Already requested` : undefined}
                                            onClick={onClick(item.id, 'toRequestContacts')} />
    const btnRelevant = <ActionButton variant="relevant"
                                      disabled={loading || disableAddToRelevant}
                                      filled={item.prospect}
                                      title={item.prospect ? `Already added to Relevant` : undefined}
                                      onClick={onClick(item.id, 'toProspects', item.prospect ? 'unmark' : 'mark')} />
    const btnIrrelevant = <ActionButton variant="irrelevant" disabled={loading || kind === 'irrelevant'} onClick={onClick(item.id, 'toIrrelevant')} />
    const btnCompetitor = <ActionButton variant="competitor" disabled={loading || kind === 'competitors'} onClick={onClick(item.id, 'toCompetitors')} />
    const btnPartner = <ActionButton variant="partner"
                                     filled={item.partner}
                                     title={item.partner ? `Already added to Partners` : undefined}
                                     disabled={loading || kind === 'partners' || disableAddToPartners}
                                     onClick={onClick(item.id, 'toPartners')} />
    const btnRemove = <ActionButton variant="remove" disabled={loading || kind === 'leads'} onClick={onClick(item.id, 'toRemove')} title={`Remove from ${variant === 'relevant' ? actions['relevant'].multiple : actions['partner'].multiple}`} />
    const variants = <>
        {variant !== 'relevant' && btnRelevant}
        {variant === 'lead' && [btnIrrelevant, btnCompetitor]}
        {variant !== 'partner' && btnPartner}
        {btnRequestContact}
        {variant !== 'lead' && !isIntelligence && btnRemove}
    </>

    return vertical ? variants : (
        //@ts-ignore
        <HideOnScroll>
            <Root vertical={vertical}>
                {variants}
            </Root>
        </HideOnScroll>
    )
}

Actions.defaultProps = {
    variant: 'lead'
}

export const _Actions = (props) => {
    const { item, variant, contacts, kind, onUpdateItem, loading } = useContext(DetailsContext)
    const onClick = (id, action, direction) => {
        props.onQuickActions(id, action, direction)
        onUpdateItem(action)
    }

    return <Actions {...props} onClick={onClick} item={item} contacts={contacts} variant={variant} kind={kind} loading={loading} />
}

export default Actions
