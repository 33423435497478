import { gql } from '@apollo/client'
import { BATCH_RESULT_FIELDS, SUCCESS_FIELDS, TAG_FIELDS } from './fragments'

export const GET_BRAND_TAGS = gql`
  query GetBrandTags (
    $brandId: String!
  ) {
    tagsOfBrand( brandId: $brandId ) {
      ...TAG_FIELDS
    }
  }
  ${TAG_FIELDS}
`

export const GET_CHANNELS_TAGS = gql`
  query GetChannelsTags (
    $brandId: String!
    $channelIds: [String!]!
  ) {
    tagsForChannels (
      brandId: $brandId,
      channelIds: $channelIds
    ) {
      channelId
      tags {
        id
        name
      }
    }
  }
`

export const CREATE_TAG = gql`
  mutation CreateTag ($CreateTagInput: CreateTagInput!) {
    createTag(input: $CreateTagInput) {
      ...TAG_FIELDS
    }
  }
  ${TAG_FIELDS}
`

export const UPDATE_TAG = gql`
  mutation UpdateTag ($UpdateTagInput: UpdateTagInput!) {
    updateTag(input: $UpdateTagInput) {
      ...TAG_FIELDS
    }
  }
  ${TAG_FIELDS}
`

export const REMOVE_TAG = gql`
  mutation RemoveTag (
    $id: String!
    $brandId: String!
    ) {
    deleteTag(
      id: $id
      brandId: $brandId
    ) {
      ...SUCCESS_FIELDS
    }
  }
  ${SUCCESS_FIELDS}
`


export const MARK_CHANNEL_WITH_TAG = gql`
  mutation MarkChannelWithTag ($MarkChannelsWithTagInput: MarkChannelsWithTagInput!) {
    markChannelsWithTag (input: $MarkChannelsWithTagInput) {
      ...BATCH_RESULT_FIELDS
    }
  }
  ${BATCH_RESULT_FIELDS}
`

export const REMOVE_TAG_FROM_CHANNELS = gql`
  mutation RemoveTagFromChannels ($RemoveTagFromChannelsInput: RemoveTagFromChannelsInput!) {
    removeTagFromChannels (input: $RemoveTagFromChannelsInput) {
      ...BATCH_RESULT_FIELDS
    }
  }
  ${BATCH_RESULT_FIELDS}
`
