import React from 'react'
import styled from 'styled-components'
import { Chart as ChartJS, ArcElement, LinearScale} from 'chart.js'
import {Chart} from 'react-chartjs-2'

ChartJS.register(ArcElement, LinearScale);

// const annotation = {
//     type: 'box',
//     backgroundColor: 'transparent',
//     borderWidth: 0,
//     label: {
//         drawTime: 'afterDatasetsDraw',
//         display: true,
//         color: 'rgba(208, 208, 208, 0.2)',
//         content: 'Draft',
//         font: {
//             size: (ctx) => ctx.chart.chartArea.height / 1.5
//         },
//         position: 'center'
//     }
// };

// const options =  {
//     plugins: {
//         annotation: {
//             annotations: {
//                 annotation
//             }
//         }
//     }
// }

const config = {
    type: 'doughnut',
    options: {
        cutout: '70%',
        responsive:true,
        animation: {
            duration: 1,
            // delay: 2000
        },
        plugins: {
            autocolors: false,
            // annotation: {
            //     annotations: {
            //         annotation
            //     }
            // }
        }
    }
};

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 55px;
`

interface DoughnutChartProps {
  data?:any
}

function DoughnutChart ({ data }: DoughnutChartProps) {
    // @ts-ignore
    return (
        <Root>
          {/* @ts-ignore */}
          <Chart {...config} data={data}  />
        </Root>
    )
}

DoughnutChart.defaultProps = {
  value: 'It\'s a me, DoughnutChart!'
}

export default DoughnutChart
