import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  FormControlLabel as MuiFormControl,
  Checkbox as MuiCheckbox,
  Grid,
} from '@material-ui/core'
import {Form, Input} from '../Form'
import Button from '../Button'
import Validator from '../../utils/validator'

const FORM_ID = 'recover-password-form'

const ActionsHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
`
const ContactSupport = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: #666666;
  margin: 40px 0 0 0;
  text-align: center;
  a {
    color: #0163FA;
    text-decoration: none;
  }
`
const GridInput = styled(Grid)`
  max-width: 300px;
  margin: 0 auto 24px auto;
  width: 100%;
`

const FormControlLabel = styled(MuiFormControl)`
  margin: 0;
  align-items: self-start;
  .MuiFormControlLabel-label {
    color: #3E3E3E;
    font-size: 14px;
    line-height: 16px;
  }
`

const Checkbox = styled(MuiCheckbox)`
  padding: 0;
  margin: 0 16px 0 0;
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #0163FA;
  }
`

const schema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  }
]

@withRouter
export default class RecoverPasswordForm extends React.Component {
  constructor(props) {
    super(props)
    const { email } = this.props
    const stateEmail = props.location.state.email
    this.state = { email: stateEmail || email, errors: {}, error: null }
  }

  onChange = name => ({target: {value, checked}}) => {
    this.setState({[name]: name === 'consent' ? checked : value})
    this.resetError(name)
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      async () => {
        const {email} = this.state
        const {error} = await this.props.onSubmit({email})

        if (error) {
          const message = error === 'emailExists' ? 'User with given email already exist' : error
          this.setState({errors: { email: message }})
        }
      })
  }

  render() {
    const {email, errors} = this.state
    const {
      header,
      subHeader,
      thinking,
      error
    } = this.props

    const Actions = () => <ActionsHolder>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        mb={2}
        disabled={thinking}
      >
        Get password
      </Button>
      <Button
        component={Link}
        to="/signin"
        fullWidth
        color="primary"
        style={{ backgroundColor: 'transparent' }}
      >
        Back to login
      </Button>
      <ContactSupport>Have any issues contact <a href="mailto:support@breezy.io">support@breezy.io</a></ContactSupport>
    </ActionsHolder>

    return (
      <Form
        noValidate
        header={header}
        subHeader={subHeader}
        formId={FORM_ID}
        onSubmit={this.onSubmit}
        actions={Actions}
        error={error}
        thinking={thinking}
      >
        <Grid container>
          <GridInput item xs={12}>
            <Input
              margin="none"
              required
              fullWidth
              placeholder="Enter your email address"
              id={'email'}
              fieldType='clearable'
              label={'Email'}
              inputLabel={'Email'}
              variant={'outlined'}
              value={email}
              error={errors.email}
              disabled={thinking}
              onChange={this.onChange('email')}
            />
          </GridInput>
        </Grid>
      </Form>
    )
  }
}
