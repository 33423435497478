import { observable, action, computed, toJS } from 'mobx'
import { Get, Delete, Post, Put } from '../utils/request'
import hasError from './request-message'
import Notification from './notification'

class ProductLists {
  searchId = null

  @observable loading = true
  @observable thinking = false

  @observable _lists = []

  @action
  async load (searchId) {
    this.searchId = this.searchId || searchId

    const { success, message, lists } = await Get(`/api/discovery/leads-lists/${this.searchId}`)

    if (!hasError(success, message)) {
      this._lists = lists
    }

    this.loading = false
  }

  @action
  async create ({ name }) {
    this.thinking = true

    const { success, message, lists } = await Post(`/api/discovery/leads-lists/${this.searchId}`, { leadsList: { name } })

    if (!hasError(success, message, /*'New List successfully created'*/)) {
      this._lists = lists
    }

    return { success }
  }

  @action
  async update ({ id, ...details }) {
    this.thinking = true

    this._lists = this._lists.map(({ id: listId, ...rest}) => ({
      id: listId,
      ...rest,
      ...(id === listId && { ...details })
    }))

    const { success, message, lists } = await Put(`/api/discovery/leads-lists/${this.searchId}/${id}`, { leadsList: details })

    if (!hasError(success, message, ''/*'List successfully updated'*/)) {
      this._lists = lists
    }

    return { success }
  }

  @action
  async remove (id) {
    this.thinking = true

    this._lists = this._lists.filter(({ id: listId }) => listId !== id )

    const { success, message, lists } = await Delete(`/api/discovery/leads-lists/${this.searchId}/${id}`, {})

    if (!hasError(success, message, /*'List successfully removed'*/)) {
      this._lists = lists
    }

    return { success }
  }

  @action
  async reorder (order) {
    this.thinking = true

    this._lists = order

    const ids = order.map(({ id }) => id)

    const { success, message, lists } = await Put(`/api/discovery/leads-lists/${this.searchId}/reorder`, { ids })

    if (!hasError(success, message, ''/*'List order successfully updated'*/)) {
      this._lists = lists
    }

    return { success }
  }

  @action
  async addToList (listId, leadIds) {
    this.thinking = true
    const { success, message, lists } = await Put(`/api/discovery/leads-lists/${this.searchId}/add-to-list/${listId}`, { ids: leadIds })

    const getListName = () => {
      return toJS(this._lists).filter(({ id }) => id === listId)[0].name
    }

    if (!hasError(success, message, ''/*`Leads successfully added to ${getListName()} list`*/)) {
      this._lists = lists
    }
    this.thinking = false
  }

  @action
  async removeFromList (listId, leadIds) {
    this.thinking = true
    const { success, message, lists } = await Put(`/api/discovery/leads-lists/${this.productId}/remove-from-list/${listId}`, { ids: leadIds })

    const getListName = () => {
      return toJS(this._lists).filter(({ id }) => id === listId)[0].name
    }

    if (!hasError(success, message, '' /*`Leads successfully removed from ${getListName()} list`*/)) {
      this._lists = lists
    }
    this.thinking = false
  }

  @action
  setInitial () {
    this.loading = true
    this.thinking = false
    this.productId = null
    this._lists = []
  }

  @computed
  get lists () {
    return toJS(this._lists)
  }
}

export default new ProductLists()
