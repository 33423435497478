import React from 'react'
import styled from 'styled-components'
import { Button as MuiButton, Grid, Menu } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  MenuItem
} from '@material-ui/core'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import { Link } from 'react-router-dom'

const Root = styled.div`
  padding: 0 20px;
`

const Title = styled.h4`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
`

const MoreAction = styled(IconButton)`
  opacity: 0.2;
`

export default class Members extends React.Component {
  constructor(props) {
    super(props)
    this.state = { anchorEl: null, member: {} }
  }

  onOpenMenu = member => (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.setState({ anchorEl: e.target, member })
  }

  onCloseMenu = () => {
    this.setState({ anchorEl: null, member: {} })
  }

  onResendInvitation = () => {
    const { member: { id } } = this.state
    this.props.onResendInvitation(id)
    this.onCloseMenu()
  }

  onDeleteUser = () => {
    const { member: { id } } = this.state
    this.props.onRemoveMember(id)
    this.onCloseMenu()
  }

  render() {
    const { member, anchorEl } = this.state
    const { members } = this.props

    return (
      <Root>
        <Title>Team</Title>

        <Table size="small" aria-label="a dense table">

          {
            members.map(({ id, email, user, status, role }) =>  (
              <TableRow key={id}>
                <TableCell component="th" scope="row">
                  <p style={{marginBottom: 0}}><strong>{(user || {}).fullName}</strong></p>
                  <p>{email}</p>
                </TableCell>
                <TableCell align="right">
                  {role}
                </TableCell>
                <TableCell align="right">
                  {status}
                </TableCell>
                <TableCell align="right">
                  { role !== 'Owner' && <MoreAction size={'small'} onClick={this.onOpenMenu({ id, email, user, status, role })}><MoreIcon /></MoreAction> }
                </TableCell>
              </TableRow>
            ))
          }

        </Table>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.onCloseMenu}
        >
          { member.status === 'Invited' && <MenuItem onClick={this.onResendInvitation}>Re-send Invite</MenuItem> }
          <MenuItem onClick={this.onDeleteUser}>Remove</MenuItem>
        </Menu>

      </Root>
    )
  }
}

//

