import React from 'react'
import styled from 'styled-components'
import {
  Table as TableMUI,
  TableCell,
  TableRow,
  TableSortLabel,
  TableBody,
  TableHead as TableHeadMUI, Typography,
  Tooltip
} from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { find, orderBy as orderList } from 'lodash'
import {TabCompact as Tab, TabsCompact as Tabs} from '../Tabs'
import Pane from '../Details/Pane'
import ShowAll from '../ShowAll'
import Link from '../Link'
import Icon from './Icon'
import IconLabelSearch from '../../new-components/IconLabelSearch'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const Table = styled(TableMUI)`
  table-layout: fixed;
`

const TableHead = styled(TableHeadMUI)`
  margin-bottom: 1em;
`

const TableCellBody = styled(TableCell)`
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 5px 15px;
  height: 25px;
`;

const TableCellHead = styled(TableCell)`
  font-size: 12px;
  line-height: 12px;
  border: none;
  padding: 5px 15px;
  font-weight: normal;
  &:first-child{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  & .MuiTableSortLabel-root {
    opacity: 0.5;
    font-weight: normal;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 8px 0;
  overflow: hidden;
`

const Heading = styled.div`
  font-size: 12px;
`

const Label = styled.div`
  font-size: 12px;
  line-height: 25px;
  color: #828282;
`

const Accordion = styled(MuiAccordion)`
    box-shadow: none;
    border: 0;
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  &:before {
      display: none;
    }
    margin: auto;
  //'&:not(:last-child)': {
  //  borderBottom: 0,
  //},
  //'&:before': {
  //  display: 'none',
  //},
  //'&$expanded': {
  //  margin: 'auto',
  //},
  //},
  //expanded: {},
`

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: auto;
  padding: 0;
  margin: 0;
  overflow: hidden;
  .MuiAccordionSummary-content {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .MuiAccordionSummary-expandIcon {
    padding: 5px;
    margin-right: 3px;
  }
`

const AccordionDetails = styled(MuiAccordionDetails)`
`

const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0.2em 15px 0;
`

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  color: #000000; 
`;

const toRank = str => {
  const matched = str.split(/::/)
  return {
    keyword: matched[0],
    rank: matched[1] ? parseInt(matched[1]) : 'n/a'
  }
}

const toKeywordRank = (list, orderBy, order) => {
  const result = list.map(str => {
    return toRank(str)
  })

  return orderList((result || []), [orderBy], [order])
}

const ListItemsByUrl = ({ items, kind }) => {

  return (
    <>
      {
        items.map(({ id, url, list, icon }) => (
          list && list.length ? (
            <Accordion square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${id}-content`}
                id={`panel${id}-header`}
              >
                <Header>
                  {
                    kind === 'bySearch' && <Label><IconLabelSearch>{url}</IconLabelSearch></Label>
                  }

                  {
                    kind === 'byRelevant' && (
                      <>
                        <Icon item={{ icon }} size={12} color={'#828282'} />
                        <Link
                          disabled
                          pathOnly
                          value={url}
                          // link={url}
                          rtl
                          title={url}
                        />
                      </>
                    )
                  }

                </Header>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {
                    toKeywordRank(list).map(({ keyword, rank }) => <ListItem>
                      <div>{ keyword }</div>
                      <div>{ rank }</div>
                    </ListItem>)
                  }
                </List>
              </AccordionDetails>
            </Accordion>
          ) : null
        ))
      }
    </>
  )
}

class SeedsList extends React.Component {
  static defaultProps = {
    list: [],
    message: '',
    minRows: 5
  }

  state = {
    collapsed: true,
    tab: 'all',
    orderBy: 'rank',
    order: 'asc'
  }

  onToggleCollapsed = () => this.setState({ collapsed: !this.state.collapsed })

  onTabChange = (e, tab) => this.setState({ tab })

  onSort = (orderBy) => (e) => {
    if (this.state.orderBy === orderBy) {
      this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc'})
    } else {
      this.setState({ orderBy, order: 'desc' })
    }
  }

  get slicedList () {
    const { list, listByUrl, listByProduct, minRows } = this.props
    const { collapsed, tab, orderBy, order } = this.state
    let data = []

    if (tab === 'all') {
      data = toKeywordRank(list, orderBy, order)
    } else if (tab === 'byRelevant') {
      data = listByUrl.filter(({ list }) => list && list.length)
      // listByUrl.forEach(({ icon, url, list: keywords }) => {
      //   if (keywords && keywords.length) {
      //     data.push({ icon, url })
      //     data = [...data, ...toKeywordRank(keywords || [], orderBy, order)]
      //   }
      // })
    } else if (tab === 'bySearch') {
      data = listByProduct.filter(({ list }) => list && list.length)
      // listByProduct.forEach(({ icon, url, list: keywords }) => {
      //   if (keywords && keywords.length) {
      //     data.push({ icon, url })
      //     data = [...data, ...toKeywordRank(keywords || [], orderBy, order)]
      //   }
      // })
    }

    return collapsed && tab === 'all' ? data.slice(0, minRows) : data
  }

  render() {
    const {
      kind,
      list,
      listByUrl,
      listByProduct,
      message,
      country,
      minRows
    } = this.props
    const { orderBy, order, collapsed, tab } = this.state
    const listLength = (list || []).length || (listByProduct || []).length

    return (
      <Pane empty={listLength === 0} emptyMessage={message}>
        <Heading>
          <Tabs
            value={tab}
            onChange={this.onTabChange}>
            {/*<Tab value="show" label="Show" disabled/>*/}
            <Tab value="all" label="All" />
            <Tab value="byRelevant" label="By Relevant Pages" />
            {
              kind === 'brand' && <Tab value="bySearch" label="By Search" />
            }

          </Tabs>
        </Heading>

        {
          tab === 'all' &&  <Table aria-label="Keywords table">
            <TableHead>
              <TableRow>
                <TableCellHead align={'left'} width={'79%'}>
                  <TableSortLabel
                    active={orderBy === 'keyword'}
                    direction={orderBy === 'keyword' ? order : 'asc'}
                    onClick={this.onSort('keyword')}
                  >
                    Keyword
                  </TableSortLabel>
                </TableCellHead>
                <Tooltip title={'Position of keyword in search results'}>
                  <TableCellHead align={'right'} width={'50px'}>
                    <TableSortLabel
                      active={orderBy === 'rank'}
                      direction={orderBy === 'rank' ? order : 'asc'}
                      onClick={this.onSort('rank')}
                    >
                      Position
                    </TableSortLabel>
                  </TableCellHead>
                </Tooltip>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.slicedList.map(({ url, icon, keyword, rank }) => {
                  if (!!url) {
                    return (
                      <TableRow key={url}>
                        <TableCellBody colSpan={2}>
                          <Header>
                            {
                              tab === 'bySearch' && <Label>
                                <IconLabelSearch>{url}</IconLabelSearch>
                              </Label>
                            }
                            {
                              tab === 'byRelevant' && (
                                <>
                                  <Icon item={{ icon }} size={12} color={'#828282'} />
                                  <Link
                                    disabled
                                    pathOnly
                                    value={url}
                                    link={url}
                                    title={url}
                                  />
                                </>
                              )
                            }
                          </Header>
                        </TableCellBody>
                      </TableRow>
                    )
                  }

                  return (
                    <TableRow key={`${keyword}-${rank}`}>
                      <TableCellBody>{keyword}</TableCellBody>
                      <TableCellBody align={'right'}>
                        {
                          rank ? rank : <Tooltip title={'Re-run Search to get Keyword position'}><span>n/a</span></Tooltip>
                        }
                      </TableCellBody>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        }

        {
          tab === 'byRelevant' && <ListItemsByUrl kind={tab} items={this.slicedList} />
        }

        {
          tab === 'bySearch' && <ListItemsByUrl kind={tab} items={this.slicedList} />
        }

        <ShowAll
          more={tab === 'all' && listLength > minRows}
          country={country}
          amount={minRows}
          total={listLength}
          collapsed={collapsed}
          onClick={this.onToggleCollapsed}
        />
      </Pane>
    );
  }
}



export default SeedsList
