import { gql } from '@apollo/client'

export const GET_BRAND_ECOSYSTEMS = gql`
  query BrandEcosystems ($id: String!) {
    brand (id: $id) {
      ecosystems {
        ecosystem {
          id
          name
        }
      }
    }
  }
`
