import React, { useContext } from 'react'

import {DetailsContext} from '../DetailsContext'
import NetworksFull from '../../Profile/NetworksFull'

export default function ProfileNetworksUse (props) {
    const { loading, networks } = useContext(DetailsContext)

    if (!loading && (!networks || !networks.length)) return null

    return <NetworksFull loading={loading} networks={networks} />
}
