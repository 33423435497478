import { gql } from '@apollo/client'

export const FOLDER_FIELDS = gql`
  fragment FOLDER_FIELDS on Folder {
    id
    brandId
    createdAt
    kind
    name
    order
    recordsCount
    status
    updatedAt
  }
`

export const SUCCESS_FIELDS = gql`
  fragment SUCCESS_FIELDS on Success {
    success
    errorMessage
  }
`

export const BATCH_RESULT_FIELDS = gql`
  fragment BATCH_RESULT_FIELDS on BatchResult {
    count
  }
`

export const USER_FIELDS = gql`
  fragment USER_FIELDS on User {
    id
    email
    emailStatus
    firstName
    lastName
    role
    status
    createdAt
    hasPassword
    access {
      brandId
      role
    }
  }
`

export const ACCOUNT_USER_FIELDS = gql`
  ${USER_FIELDS}
  fragment ACCOUNT_USER_FIELDS on AccountUser {
    id
    brandId
    email
    role
    createdAt
    status
    updatedAt
    user {
      ...USER_FIELDS
    }
    userId
  }
`

export const BRAND_FIELDS = gql`
  fragment BRAND_FIELDS on Brand {
    id
    name
    paywall
    assisted
    assistedLock
    onboardingStep
    credits
    link
    flags
    folders {
      ...FOLDER_FIELDS
    }
    relevantsCount
    partnersCount
    searchesCount
  }
  ${FOLDER_FIELDS}
`

export const SEARCH_FIELDS = gql`
  fragment SEARCH_FIELDS on Search {
    id
    createdAt
    expiredAt
    name
    leadsCount
    keywords
    keywordsCount
    keywordsChanged
    competitorUrls
    competitorUrlsChanged
    competitorsCount
    irrelevants
    irrelevantsChanged
    irrelevantsCount
    relevantsCount
    researchId
    researchSeen
    researchStatus
    researchDoneAt
    researchStartAt
    hidden
    channel {
      domain
    }
    country {
      code
      id
    }
    user {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`

export const LIST_ITEM_CHANNEL_DETAILS = gql`
  fragment LIST_ITEM_CHANNEL_DETAILS on Channel {
    id
    icon
    title
    isBrand
    isCreator
    swGlobalRank
    swVisits
    mozDomainAuthority
    type1Classification
    partnershipsInCount
    partnershipsOutCount
  }
`

export const LEAD_FIELDS = gql`
  fragment LEAD_FIELDS on Lead {
    leadId
    breezyScore
    campaignsExitsCount
    campaignsOutCount
    channel {
      ...LIST_ITEM_CHANNEL_DETAILS
    }
    list
    domain
    partner {
      id
    }
    relevant {
      id
    }
    internalRelevant
    keywordSeedsCount
    competitorSeedsCount
    partnershipsOutCount
    notesCount
    outOfDate
    createdAt
    updatedAt
  }
  ${LIST_ITEM_CHANNEL_DETAILS}
`

export const PROSPECT_FIELDS = gql`
  fragment PROSPECT_FIELDS on Prospect {
    id
    kind
    domain
    avgBreezyScore
    campaignsExitsCount
    campaignsOutCount
    channel {
      ...LIST_ITEM_CHANNEL_DETAILS
    }
    createdAt
    notesCount
    partnershipsOutCount
    totalCompetitors
    totalKeywords
    updatedAt
    folder @include(if: $requestFolder) {
      name
    }
  }
  ${LIST_ITEM_CHANNEL_DETAILS}
`

export const CONTACT_DATA_FIELDS = gql`
  fragment CONTACT_DATA_FIELDS on ContactData {
    id
    contactId
    createdAt
    data
    kind
    source
    status
    updatedAt
  }
`

export const ENTITY_DATA_FIELDS = gql`
  fragment ENTITY_DATA_FIELDS on EntityData {
    id
    entityId
    createdAt
    data
    kind
    source
    status
    updatedAt
  }
`

export const ENTITY_FIELDS = gql`
  fragment ENTITY_FIELDS on Entity {
    id
    entityData {
      ...ENTITY_DATA_FIELDS
    }
    createdAt
    status
    updatedAt
  }
  ${ENTITY_DATA_FIELDS}
`

export const CONTACT_FIELDS = gql`
  fragment CONTACT_FIELDS on Contact {
    id
    contactData {
      ...CONTACT_DATA_FIELDS
    }
    createdAt
    status
    updatedAt
  }
  ${CONTACT_DATA_FIELDS}
`

export const CONTACT_REQUEST_FIELDS = gql`
  fragment CONTACT_REQUEST_FIELDS on ContactRequest {
    id
    brandId
    channelId
    contactId
    createdAt
    status
    updatedAt
    channel {
      icon
      domain
      title
      type1Classification
    }
    user {
      firstName
      lastName
      email
    }
  }
`

export const TAG_FIELDS = gql`
  fragment TAG_FIELDS on Tag {
    id
    name
    brandId
    createdAt
    favourite
    updatedAt
  }
`

export const CREATOR_CONTACT_REQUEST_FIELDS = gql`
  fragment CREATOR_CONTACT_REQUEST_FIELDS on ContactRequest {
    id
    brand {
      name
      link
    }
    contactId
    outreachMessage
    createdAt
    updatedAt
    status
  }
`
