import React from 'react'
import styled from 'styled-components'
import {Button as MuiButton} from '@material-ui/core'
import { Form, Input } from '../Form'
import {LinearProgress as MuiLinearProgress} from '@material-ui/core'


const LinearProgress  = styled(MuiLinearProgress)`
  max-width: 70%;
  margin: 0 auto;
  background: rgba(208, 216, 236, 0.79);
  border-radius: 3px;
  .MuiLinearProgress-barColorPrimary {
    background: #0041E8;
    border-radius: 3px;
  }
`;

const ProgressWrapper = styled.div`
    position: relative;
    max-width: 600px;
    width: 100%;
    margin-bottom: 2em;
`

const Img = styled.div`
  width: 250px;
  height: 250px;
  margin: 0 0 35px 0;
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${props => props.alignLeft ? 'flex-end' : 'center'} ;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.padding};
  h5 {
    font-family: "GT Super Display", serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000021;
    margin: 0 0 32px 0;
    max-width: 400px;
    text-align: center;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #000021;
    margin: 0;
    max-width: 400px;
    text-align: center;
  }
  form {
    padding: 28px 0 0 0;
  }
  button {
    margin: 37px 0 0 0;
  }
  &.imgRight {
    flex-direction: row-reverse;
    
    h5, p {
      text-align: left;
    }
    ${Img} {
      margin: 0 0 0 80px;
    }
    .textContainer {
      margin: 0 0px 0 80px;
    }
    @media (max-width: 1100px) {
       ${Img} {
      margin: 0 20px 0 20px;
    }
    .textContainer {
      margin: 0 20px 0 20px;
    }
    }    
  }
  
  &.modal {
   background: rgb(255 255 255 / 85%);
   position: absolute;
   left: 0;
   right: 0;
   bottom: 0;
   top: 0;
   z-index: 100;
  }
`;

const Button = styled(MuiButton)`
  background: #0163FA;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;
  color: #fff;
  padding: 13px 18px;
  border-radius: 100px;
  margin: 24px 0 0 0;
  &:hover {
    background: #0163FA;
    color: #fff;
  }
`;

const ButtonGroup = styled.div`
 display: flex;
 margin: 37px 0 0 0;
 a {
    border: 1px solid #0041E8;
    box-sizing: border-box;
    background-color: transparent;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: none;
    color: #0041E8;
    padding: 13px 18px;
    border-radius: 100px;
    margin: 0 21px 0 0;
    text-decoration: none;
    display: block;
 }
 button {
    margin: 0;
 }
 .buttonWithHelpText {
    position: relative;
 }
 .text {
    position: absolute;
    top: 121px;
    right: -220px;
    width: 212px;
    text-align: center !important;
    color: #0163FA;
    transform: rotate(-5deg);
    font-size: 16px;
    font-weight: 500;
 }
 svg {
    position: absolute;
    transform: rotate(5deg);
    left: -90px;
    top: -55px;
 }
`;

class EmptySearchPlaceholder extends React.Component {
  state = { input: null, inputError: null }

  onChange = ({ target: { value } }) => {
    this.setState({ input: value, inputError: null })
  }

  onSubmit = e => {
    if (!this.props.thinking) {
      e.preventDefault()
      if (this.state.input) {
        return this.props.onClick(this.state.input)
      }
      return this.setState({ inputError: 'Product name is required!' })
    }
    return null
  }

  render() {
    const { input, inputError } = this.state
    const {
      state,
      icon,
      title,
      text,
      onClick,
      buttonText,
      imgRight,
      modal,
      additionalButton,
      thinking,
      progress,
      padding = '20px 0 20px',
      alignLeft
    } = this.props

    return (
      <Root className={`${imgRight && 'imgRight'} ${modal && 'modal'}`} padding={padding} alignLeft={alignLeft}>
        {
          !imgRight
            ?
            <>
              <Img>
                {icon}
              </Img>
              <h5 dangerouslySetInnerHTML={{__html: title}}/>
              {progress &&
              <ProgressWrapper>
                <LinearProgress variant="indeterminate"/>
              </ProgressWrapper>
              }
              <p dangerouslySetInnerHTML={{__html: text}}/>
              {!!additionalButton && additionalButton}
              {!!buttonText && <Button onClick={() => onClick()}>{buttonText}</Button>}
            </>
            :
            <>
              <div className={'textContainer'}>
                <h5 dangerouslySetInnerHTML={{__html: title}}/>
                {progress &&
                <ProgressWrapper>
                  <LinearProgress variant="indeterminate"/>
                </ProgressWrapper>
                }
                <p dangerouslySetInnerHTML={{__html: text}}/>
                {/*{*/}
                {/*  state === 'createProduct' && <Form noValidate onSubmit={this.onSubmit}>*/}
                {/*    <Input*/}
                {/*      disabled={thinking}*/}
                {/*      autoFocus*/}
                {/*      required*/}
                {/*      fullWidth*/}
                {/*      id={state}*/}
                {/*      placeholder="New product"*/}
                {/*      margin="none"*/}
                {/*      label={'Product name'}*/}
                {/*      inputLabel={'Product name'}*/}
                {/*      variant={'outlined'}*/}
                {/*      fieldType='clearable'*/}
                {/*      error={inputError}*/}
                {/*      value={input}*/}
                {/*      onChange={this.onChange}*/}
                {/*    />*/}
                {/*    <Button type="submit">{buttonText}</Button>*/}
                {/*  </Form>*/}
                {/*}*/}

                <ButtonGroup>
                  {!!additionalButton && <div className={'buttonWithHelpText'}>{additionalButton}</div>}
                  {!!buttonText && <Button onClick={() => onClick()}>{buttonText}</Button>}
                </ButtonGroup>

              </div>
              <Img>
                {icon}
              </Img>
            </>
        }
      </Root>
    );
  }
}

export default EmptySearchPlaceholder
