import React from 'react'
import Helmet from 'react-helmet'
import styled, {createGlobalStyle, css} from 'styled-components'
import '../components/Fonts/fonts.css'
import {CssBaseline, Grid, IconButton} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {BreezyLogo} from '../components/Icons/BreezyLogo'
import LoaderOverlay from '../components/Loader/LoaderOverlay'
import Discovery from '../components/Discovery'
import { Tab, Tabs } from '../components/Modal'
import pluraliseUnit from "../utils/pluraliseUnit"
import CurrentCost from "../components/CurrentCost";
import Button from '@material-ui/core/Button'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    background: #fff;
  }
`;

const Root = styled.div`
  height: 100%;
  overflow-x: hidden;
  .MuiFormLabel-root.Mui-focused {
    color: #3E3E3E;
  }
`;

const BgContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: ${props => props.column ? 'row' : 'column'};
`;

const FormContainer = styled.div`
  width: 100%;
  //position: relative;
  z-index: 10;
  margin: 0 auto;
  flex: 1;
`;

const Header = styled.div`
  flex: ${props => !props.column && '2 1 auto'};
  //background: #9F9F9F;
  box-sizing: content-box;
  ${props => props.column && css`
    width: 285px;
    display: flex;
    //background: #9F9F9F;
  `}
`;

const Heading = styled.h1`
  font-size: 22px;
  margin: 0 2em 0 0;
  min-height: 3em;
  display: flex;
  align-items: center;
`
const LogoHolder = styled.div`
  padding: 25px 0 0 21px;
  width: 90px;
  box-sizing: border-box;
  //background: #770088;
`
const Content = styled.div`
  flex: 1 0 auto;
  //background: #0055aa;
`
const Footer = styled.div`
  padding: 25px 0 0 21px;
  flex: 2 1 auto;
  //background: #ba32b7;
`;

const Menu = styled.div`
  color: #333333;
  font-weight: normal;
  position: relative;
  margin-top: -0.5em;
  span {
    color: #333333;
    & > span {
      position: relative;
      top: 7px;
      height: 24px;
      background-color: #0041E8;
      width: 1px;
      margin: 0 5px 0 6px;
      border-radius: 0;
      padding: 0;
      display: inline-block;
      transform: rotate(25deg);
    }
  }
`

const Close = styled(IconButton)`
    position: absolute;
    right: 5px;
    top: 5px;
  z-index: 1000;
`;


const WelcomeDiscovery = ({
                            children,
                            title,
                            thinking,
                            discovery,
                            inputs,
                            step,
                            product,
                            create,
                            creditsLeft,
                            credits,
                            ...other
                          }) => {

  return (
    <Root>
      {thinking && <LoaderOverlay size={35} />}
      {
        !thinking &&
        <BgContainer column={inputs}>
          <Helmet title={title}/>
          <CssBaseline/>
          <GlobalStyle/>

          <Header column={inputs}>
            <LogoHolder>
              <BreezyLogo noTitle={inputs} compact/>
            </LogoHolder>
            { !!product && <Discovery {...product} />}
            {
              create && (
                <Close onClick={other.onClose}>
                  <CloseIcon />
                </Close>
              )
            }
          </Header>

          <FormContainer>

            {
              inputs && (
                <Grid container spacing={8}>
                  <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center'}}>
                    <Heading>Search Settings</Heading>
                    <Menu>
                      <span>Step&nbsp;<strong>{step}</strong><span/><strong>{2}</strong></span>
                    </Menu>
                  </Grid>
                  <Grid item xs={12} md={6} style={{fontSize: 14, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div style={{padding: '1.2em 1em 0 0', maxWidth: '28rem', margin: '0 auto',  width: '100%'}}>
                      {/*<div>*/}
                      {/*  You have <strong>{creditsLeft} {pluraliseUnit('credit', creditsLeft)}</strong>*/}

                      {/*  {*/}
                      {/*    create && other.role === 'brandOwner' && <>*/}
                      {/*      &nbsp;&nbsp;*/}
                      {/*      <Button color='primary' size='small' onClick={other.onAddCredits}>Purchase Credits</Button>*/}
                      {/*    </>*/}
                      {/*  }*/}

                      {/*</div>*/}
                      <CurrentCost credits={credits} />
                    </div>
                  </Grid>
                </Grid>
              )
            }

            { !inputs && <Content>{children}</Content>}

            {
              inputs && (
                <>
                  <Tabs value={step} noPadding>
                    <Tab label={<span>{'Keywords'}</span>} value={1} disableRipple disableTouchRipple disableFocusRibble/>
                    <Tab label={<span>{'Competitors'}</span>} value={2} disableRipple disableTouchRipple disableFocusRibble/>
                  </Tabs>
                  {children}
                </>
              )
            }
          </FormContainer>
          {!inputs && <Footer />}
        </BgContainer>
      }
    </Root>
  )
}

export default WelcomeDiscovery
