import UserStore from './user'
import CreatorStore from './creator'
import { CreatorInboxStore, CreatorAcceptedStore, CreatorRejectedStore } from './creator-requests'
import BrandStore from './brand'
import BrandsStore from './brands'
import SearchesStore from './searches'
import ContactInfosStore from './contact-infos'
import ContactRequestsStore from './contact-requests'
import TagsStore from './tags'
import { RelevantFoldersStore } from './folders'

///// Product store & Product Lists stores
import SearchStore from './search'
import ReportStore from './report'
import IrrelevantLeadsStore from './irrelevent-leads'
import CompetitorLeadsStore from './competitor-leads'
import ProspectLeadsStore from './prospect-leads'
import LeadsListStore from './leads-list'
import ProductListsStore from './product-lists'

import IntelligenceStore from './intelligence'
import EcosystemStore from './ecosystem'
import GapAnalysisStore from './gap-analysis'
import CompetitorLandscapeStore from './competitor-landscape'
import PartnershipsStore from './partnerships'


import { PartnersStore, RelevantsStore, RelevantsFolderStore } from './prospects'

import NotificationStore from './notification'

const stores = {
  CreatorStore,
  CreatorInboxStore, CreatorAcceptedStore, CreatorRejectedStore,

  UserStore,
  BrandStore,
  BrandsStore,
  RelevantFoldersStore,
  SearchesStore,
  TagsStore,
  ContactInfosStore,
  ContactRequestsStore,

  PartnersStore,
  RelevantsStore,
  RelevantsFolderStore,

  SearchStore,
  ProductListsStore,
  ReportStore,
  CompetitorLeadsStore,
  IrrelevantLeadsStore,
  ProspectLeadsStore,
  LeadsListStore,

  NotificationStore,

  IntelligenceStore,
  EcosystemStore,
  GapAnalysisStore,
  CompetitorLandscapeStore,
  PartnershipsStore
}

export default stores
