import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import styled from 'styled-components'
import ModalLayout from '../../layouts/ModalLayout'
import {Root, Img, Header, Body } from '../../components/Modals/IntermediateModal'
import comp from '../../components/Icons/Thinking.svg'
import foldersWebm from 'file-loader!./folders-intro.webm'
import foldersMp4 from 'file-loader!./folders-intro.mp4'
const breakpoint = 961

const Main = styled.div`
 display: flex;
  flex-direction: column;

  padding-left: 10%;
  height: 100%;
  //display: grid;
  //grid-template-rows: 1fr 3fr 2fr;
  @media (max-width: ${breakpoint}px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

export default class IntroductionModal extends React.Component {

  render () {
    const { open, onClose } = this.props

    return (
      <Dialog
        open={open}
        aria-labelledby="introduction-modal"
        maxWidth={'md'}
        fullWidth="true"
        onEscapeKeyDown={onClose}
      >
        <ModalLayout
          noPadding
          // variant={noCredits ? 'no-credits' : variant}
          onClose={onClose}
        >

          <Root>
            <Main>
              <Header />
              <Body>
                <h1>Welcome to 👍&nbsp;Relevant</h1>
                <p>
                  This is where relevant leads from all searches are gathered in one place.
                </p><br/>
                <p>You can create folders to better organize relevant leads. Find&nbsp;them in left sidebar.</p> <br />
                <video autoPlay loop muted playsInline height={250} width={250 * 1.33}>
                  <source src={foldersWebm} type="video/webm" />
                  <source src={foldersMp4} type="video/mp4" />
                </video>
                 <br/>
                <p>We’ve created some default folders you might find useful. You can add more or replace with your own.</p>
              </Body>
              {/*<Footer>*/}

              {/*</Footer>*/}
              <DialogActions style={{justifyContent: 'flex-start', paddingBottom: 30}}>
                <Button disableElevation onClick={onClose} size={'large'} variant={'contained'} color="primary" autoFocus>
                  OK, got it.
                </Button>
              </DialogActions>
            </Main>
            <Img><img src={comp} alt="Breezy figure reading"/></Img>
          </Root>
        </ModalLayout>
      </Dialog>
    )
  }
}
