import React from 'react'
import { CircularProgress, Dialog, TextField } from '@material-ui/core'
import styled from 'styled-components'
import ModalLayout from '../../layouts/ModalLayout'
import comp from '../Icons/Reflecting.svg'
import BillingCredits from '../Billing/BillingCredits'
import Button from '../Button'
import CreditsInfo from '../CreditsInfo'
import config from '../../config'
import pluraliseUnit from '../../utils/pluraliseUnit'

const breakpoint = 961

export const Root = styled.div`
  //width: 100%;
  height: 100%;
  min-height: 50vh;
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  @media (max-width: ${breakpoint}px) {
    grid-template-columns: calc(100%);
  }
  
  h1 {
    font-family: "GT Super Display", serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 1;
    color: #000021;
    margin: 0 0 32px 0;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #3E3E3E;
    margin: 0;
    max-width: 430px;
    //text-align: center;
  }
  button {
    margin: 37px 0 0 0;
  }
`

export const Main = styled.main`
  padding-left: 10%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 3fr 2fr;
  @media (max-width: ${breakpoint}px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

export const Img = styled.div`
  flex-basis: 40%;
  height: 100%;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    //height: 50%;
    //top: 20%;
    //right: -32%;
    object-fit: contain;
    object-position: right bottom;
  }
  @media (max-width: ${breakpoint}px) {
    display: none;
  }
`

export const Header = styled.div`
  
`
export const Body = styled.div`

`
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 4em;
`

export const ShortCredits = styled.p`
  color: ${props => props.theme.palette.error.main};
  font-weight: bold;
`

export const ContentWrapper = styled.div`
  max-width: 65ch;
  @media (max-width: ${breakpoint}px) {
    max-width: 100%;
  }
`

export default class IntermediateModal extends React.Component {
  state = { message: '' }

  onChange = field => ({ target: { value } }) => {
    this.setState({ message: value })
  }

  onShowPlans = (e) => {
    e.preventDefault()
    this.props.onShowPlans()
    this.props.onClose()
  }

  get planOptions () {
    const { billingProps: { plan } } = this.props
    return config.plans[plan]
  }

  render () {
    const { message } = this.state
    const {
      open,
      role,
      variant,
      thinking,
      heading,
      subHeading,
      buttonCopy,
      errorMessage,
      currentCost,
      creditsLeft,
      onClose,
      billingProps,
      billingEndTrial: BillingEndTrial
    } = this.props

    const noCredits = currentCost > creditsLeft
    const afterwardsCredits = creditsLeft - currentCost
    const shortCredits = currentCost - creditsLeft
    const { price, credits, plan, period } = this.planOptions

    const onAction = () => {
      this.props.onAction(message)
    }

    return (
      <>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          disableEnforceFocus
          open={open}
          ariaLabelledby="dialog-title"
          onEscapeKeyDown={onClose}
        >
          <ModalLayout
            noPadding
            variant={noCredits ? 'no-credits' : variant}
            onClose={onClose}
          >
            <Root>
              <Main>
                <Header />
                <Body>
                  <ContentWrapper>
                    <h1>
                      {heading}
                    </h1>
                    <p>
                      { subHeading }
                    </p>

                    <p style={{marginTop: '1em'}}>
                      {
                        variant === 'contacts' && (
                          <>
                            { variant === 'contacts' ? 'You are about to spend ' : 'This search '}
                            {currentCost} {pluraliseUnit(variant === 'contacts' ? 'contact request' : 'credit', currentCost)}
                            {
                              afterwardsCredits >= 0 && `, and afterwards you will have ${afterwardsCredits} ${variant === 'contacts' ? 'requests' : 'credit'} left.`
                            }
                            {
                              afterwardsCredits < 0 && <>
                                &nbsp;but you are <strong>{shortCredits} credits short</strong>.
                              </>
                            }
                          </>
                        )
                      }

                    </p>

                    {/*{*/}
                    {/*  noCredits && <p><ShortCredits>You are {currentCost - creditsLeft} credits short</ShortCredits></p>*/}
                    {/*}*/}

                    { noCredits && billingProps.billingStatus === 'inTrial' && <BillingEndTrial /> }
                    {
                      !noCredits && variant === 'contacts' && (
                        <p style={{marginTop: '1em'}}>
                        <TextField
                          multiline
                          rows={6}
                          placeholder={config.OUTREACH_MESSAGE}
                          value={message}
                          style={{background: 'white', width: '100%'}}
                          disabled={thinking}
                          variant="outlined"
                          onChange={this.onChange('note')}
                        />
                        </p>
                      )
                    }
                    {
                      !noCredits && (
                        <Button
                          variant="contained"
                          color="primary"
                          mb={2}
                          startIcon={thinking && <CircularProgress size={'1em'} color={'inherit'}/>}
                          disabled={thinking || !!errorMessage}
                          onClick={onAction}
                        >
                          {buttonCopy}
                        </Button>
                      )
                    }
                    {
                      !noCredits && errorMessage && <>
                        <br/><br/>
                        <p>{errorMessage}</p>
                      </>
                    }
                    {
                      (noCredits && billingProps.billingStatus === 'inTrial') && (
                        <>
                          <br/><br/>
                          <p>You will be activating {plan} for £{price}/{period}</p>
                          <p>Want to change your plan? <a href="#" onClick={this.onShowPlans}>Click here.</a></p>
                        </>
                      )
                    }

                    {
                      (noCredits && role === 'brandOwner' && billingProps.billingStatus !== 'inTrial') && (
                        <BillingCredits {...billingProps} onClose={this.props.onClose}/>
                      )
                    }

                    {
                      noCredits && role === 'user' && (
                        <p>
                          Sorry, you can't buy more Credits or upgrade Plan since you are not an account owner.
                        </p>
                      )
                    }

                  </ContentWrapper>
                </Body>
                <Footer>
                  {/*<CreditsInfo />*/}
                </Footer>
              </Main>
              <Img><img src={comp} alt="Breezy figure reading"/></Img>
            </Root>
          </ModalLayout>
        </Dialog>
      </>
    )
  }
}
