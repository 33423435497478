import React, { useEffect, useState } from 'react'
import {
  MenuItem,
  Slider,
  TextField,
  Popover,
  Select,
  Button as MuiButton,
} from '@material-ui/core'
import styled, { css } from 'styled-components'
import numbro from 'numbro'
import Autocomplete from '../Autocomplete'

import TagsFilter from '../Tags/_TagsFilter'

import { formatToReadableNumber } from '../../utils/format'
import Log from '../../utils/log'
import ManualClassificationAutocomplete from '../Autocomplete/ManualClassificationAutocomplete'
import AdexesAutocomplete from '../Autocomplete/AdexesAutocomplete'
import ChannelsAutocomplete from '../Autocomplete/ChannelsAutocomplete'
import RequesterFilter from './RequesterFilter'

const PopoverMain = styled(Popover)`
  & > .MuiPaper-root {
    min-width: 300px;
    padding: ${(props) => (props.noPadding ? '' : '0.8em 1.5em')};
  }
`

const SliderContainer = styled.div`
  padding: 0 1em;
  ${(props) =>
    props.padded &&
    css`
      padding: 1em;
    `}
`

const SelectMain = styled(TextField)`
  min-width: 200px;
  width: 100%;
  margin: 0 0 10px 0;
  &:after,
  &:before {
    display: none;
  }
  .MuiInputBase-input:focus {
    background: none;
  }
`

const Button = styled(MuiButton)`
  margin: 0 0 10px 0;
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`

const SliderInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
`

const SliderInputBase = styled(TextField)`
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  //& input[type=number] {
  //  -moz-appearance: textfield;
  //}
  & .MuiInputBase-input {
    padding: 5px;
    font-size: 12px;
    ${(props) =>
      props.end &&
      css`
        text-align: right;
      `};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  & .MuiOutlinedInput-notchedOutline:hover {
    border-color: rgba(0, 0, 0, 0.23);
  }
  ${(props) =>
    props.editable &&
    css`
      & input {
        -moz-appearance: textfield;
      }
    `}
`

class SliderInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editable: false,
      value: formatToReadableNumber(props.value),
      error: null,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const readableValue = formatToReadableNumber(prevProps.value)
    if (!this.state.editable && readableValue !== this.state.value) {
      this.setState({ value: readableValue })
    }
  }

  onSetValue = (value) => {
    const newValue = numbro.unformat(value.toLowerCase())

    this.setState({ value, error: null })

    if (newValue === undefined) {
      return this.setState({ error: 'Please enter correct value' })
    }
    this.props.onChange(newValue)
  }

  onChange = ({ target: { value } }) => {
    this.onSetValue(value)
  }

  onFocus = () => {
    this.setState({ editable: true })
  }

  onBlur = () => {
    this.setState({ editable: false })
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onSetValue(this.state.value)
    }
  }

  render() {
    const { error } = this.state

    return (
      <SliderInputBase
        {...this.props}
        type={'text'}
        error={Boolean(error)}
        helperText={error}
        editable={this.state.editable}
        value={this.state.value}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        onChange={this.onChange}
      />
    )
  }
}

const LOGARITHMIC_MAX_POSITION = 1000

const calculateLogStep = (value, min, max) => {
  if (value === min || value === max) return value
  const log = new Log({
    minPos: 0,
    maxPos: LOGARITHMIC_MAX_POSITION,
    minVal: min,
    maxVal: max,
  })

  return Math.round(log.value(value))
}

const calculatePosition = (value, min, max) => {
  const log = new Log({
    minPos: 0,
    maxPos: LOGARITHMIC_MAX_POSITION,
    minVal: min,
    maxVal: max,
  })
  return log.position(value)
}

const calculateRangeValues = (minValue, maxValue, min, max) => {
  return [
    calculateLogStep(minValue, min, max),
    calculateLogStep(maxValue, min, max),
  ]
}

export const LogarithmicRangeFilter = ({
  padded,
  value: incomeValue,
  min,
  max,
  disabled,
  onChange,
}) => {
  const [position, setPosition] = useState([])
  const [value, setValue] = useState(incomeValue || [min, max])

  useEffect(() => {
    const { 0: minValue, 1: maxValue } = incomeValue || [min, max]
    const minCurrentPosition = calculatePosition(minValue, min, max)
    const maxCurrentPosition = calculatePosition(maxValue, min, max)
    setPosition([minCurrentPosition, maxCurrentPosition])
    setValue([minValue, maxValue])
  }, [incomeValue])

  const onChangeRange = (newValue) => {
    const { 0: minValue, 1: maxValue } = newValue
    setValue(calculateRangeValues(minValue, maxValue, min, max))
    setPosition(newValue)
  }

  const onChangeCommitted = () => {
    onChange(value)
  }

  const onChangeInput = (index) => (inputValue) => {
    let v = undefined
    let tempValue = value
    const parsedValue = parseInt(inputValue)
    if (index === 0) {
      tempValue[0] = parsedValue < min ? min : parsedValue
      if (tempValue[0] > tempValue[1]) {
        v = tempValue[0]
        tempValue[0] = tempValue[1]
        tempValue[1] = v
      }
    } else if (index === 1) {
      tempValue[1] = parsedValue > max ? max : parsedValue
      if (tempValue[1] < tempValue[0]) {
        v = tempValue[1]
        tempValue[1] = tempValue[0]
        tempValue[0] = v
      }
    }
    setValue(tempValue)
    onChange(tempValue)
  }

  const disableFilter = max === undefined

  return (
    <SliderContainer padded={padded}>
      <Slider
        disabled={disableFilter || disabled}
        min={0}
        max={LOGARITHMIC_MAX_POSITION}
        value={position}
        valueLabelFormat={formatToReadableNumber}
        onChange={(e, newValue) => onChangeRange(newValue)}
        onChangeCommitted={(e, newValue) => onChangeCommitted(newValue)}
        // valueLabelDisplay="auto"
        aria-labelledby='range-slider'
      />
      {!disableFilter && !disabled && (
        <SliderInputs>
          <SliderInput
            disabled={disableFilter || disabled}
            type='number'
            variant='outlined'
            size='small'
            value={value[0]}
            onChange={onChangeInput(0)}
          />
          <SliderInput
            disabled={disableFilter || disabled}
            type='number'
            variant='outlined'
            size='small'
            value={value[1]}
            onChange={onChangeInput(1)}
            end
          />
        </SliderInputs>
      )}
    </SliderContainer>
  )
}

export const RangeFilter = ({
  padded,
  value: incomeValue,
  min,
  max,
  step,
  marks,
  disabled,
  onChange,
}) => {
  const [value, setValue] = useState(incomeValue || [min, max])

  useEffect(() => {
    setValue(incomeValue || [min, max])
  }, [incomeValue])

  const onChangeInput = (index) => (inputValue) => {
    let v = undefined
    let tempValue = value
    const parsedValue = parseInt(inputValue)
    if (index === 0) {
      tempValue[0] = parsedValue < min ? min : parsedValue
      if (tempValue[0] > tempValue[1]) {
        v = tempValue[0]
        tempValue[0] = tempValue[1]
        tempValue[1] = v
      }
    } else if (index === 1) {
      tempValue[1] = parsedValue > max ? max : parsedValue
      if (tempValue[1] < tempValue[0]) {
        v = tempValue[1]
        tempValue[1] = tempValue[0]
        tempValue[0] = v
      }
    }
    setValue(tempValue)
    onChange(tempValue)
  }

  const disableFilter = max === undefined

  return (
    <SliderContainer padded={padded}>
      <Slider
        disabled={disableFilter || disabled}
        min={min}
        max={max}
        step={1}
        // marks={marks}
        value={value}
        valueLabelFormat={formatToReadableNumber}
        onChange={(e, newValue) => setValue(newValue)}
        onChangeCommitted={(e, newValue) => onChange(newValue)}
        // valueLabelDisplay="auto"
        aria-labelledby='range-slider'
      />
      {!disableFilter && !disabled && (
        <SliderInputs>
          <SliderInput
            disabled={disableFilter || disabled}
            type='number'
            variant='outlined'
            size='small'
            value={value[0]}
            onChange={onChangeInput(0)}
          />
          <SliderInput
            disabled={disableFilter || disabled}
            type='number'
            variant='outlined'
            size='small'
            value={value[1]}
            onChange={onChangeInput(1)}
            end
          />
        </SliderInputs>
      )}
    </SliderContainer>
  )
}

export const NumericFilter = ({ value, min, max, step, onChange }) => (
  <TextField
    type='number'
    fullWidth
    InputProps={{
      inputProps: {
        fullWidth: true,
        value,
        min,
        max,
        step,
        onChange: ({ target: { value: v } }) => onChange(v),
      },
    }}
  />
)

export const SelectFilter = ({
  placeholder,
  value,
  defaultValue,
  options,
  onChange,
  ...rest
}) => {
  return (
    <TextField
      select
      fullWidth
      disableUnderline
      variant='outlined'
      margin='normal'
      InputProps={{
        autocomplete: 'off',
        disableUnderline: true,
      }}
      InputLabelProps={{ shrink: true }}
      value={value || defaultValue || 'placeholder'}
      onChange={({ target: { value } }) => onChange(value)}
    >
      <MenuItem value={'placeholder'} disabled>
        {placeholder}
      </MenuItem>
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export const AutocompleteFilter = ({
  value,
  disabled,
  options,
  onChange,
  placeholder,
}) => {
  return (
    <Autocomplete
      disabled={disabled}
      single
      options={options}
      value={value}
      onChange={(e, a) => onChange(a)}
      placeholder={!!value && value.length > 0 ? false : placeholder}
    />
  )
}

export const MultiselectFilter = ({
  value,
  disabled,
  options,
  onChange,
  placeholder,
}) => {
  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      value={value || []}
      onChange={(e, a) => onChange(a)}
      placeholder={!!value && value.length > 0 ? false : placeholder}
    />
  )
}

export const ChannelsFilter = ({ value, disabled, onChange, placeholder }) => {
  return (
    <ChannelsAutocomplete
      disabled={disabled}
      value={value}
      onChange={(e, a) => onChange(a)}
      placeholder={!!value ? false : placeholder}
    />
  )
}

export const AdexesFilter = ({
  value,
  disabled,
  options,
  onChange,
  placeholder,
}) => {
  return (
    <AdexesAutocomplete
      disabled={disabled}
      value={value || []}
      onChange={(e, a) => onChange(a)}
      placeholder={!!value && value.length > 0 ? false : placeholder}
    />
  )
}

export const ManualClassificationFilter = ({
  value,
  disabled,
  options,
  level,
  onChange,
  placeholder,
}) => {
  return (
    <ManualClassificationAutocomplete
      disabled={disabled}
      value={value || []}
      level={level}
      onChange={(e, a) => onChange(a)}
      placeholder={!!value && value.length > 0 ? false : placeholder}
    />
  )
}

export const BooleanFilter = ({ onChange }) => {
  useEffect(() => {
    onChange(true)
  }, [])
  return <></>
}

const disableApply = (value, kind) => {
  if (['multiselect'].includes(kind)) return Boolean(!value || !value.length)

  return Boolean(!value)
}

const FilterPopover = ({
  edit,
  anchorEl,
  value,
  selected,
  onCancel,
  onChange,
  onApply,
}) => {
  return (
    <PopoverMain
      id={'popover'}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onCancel}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'start',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <label>{selected.label}</label>
      {selected && (
        <div>
          {selected.kind === 'range' && !selected.logarithmic && (
            <RangeFilter
              {...selected}
              value={value}
              onChange={onChange}
              padded
            />
          )}
          {selected.kind === 'range' && selected.logarithmic && (
            <LogarithmicRangeFilter
              {...selected}
              value={value}
              onChange={onChange}
              padded
            />
          )}
          {selected.kind === 'autocomplete' &&
            selected.channels &&
            !selected.requester && (
              <ChannelsAutocomplete
                autoFocus
                {...selected}
                value={value}
                onChange={onChange}
              />
            )}
          {selected.kind === 'autocomplete' &&
            selected.requester &&
            !selected.channels && (
              <RequesterFilter
                {...selected}
                value={value}
                onChange={onChange}
              />
            )}

          {selected.kind === 'multiselect' &&
            !selected.adexes &&
            selected.classification && (
              <ManualClassificationFilter
                autoFocus
                {...selected}
                value={value || []}
                onChange={onChange}
              />
            )}

          {selected.kind === 'multiselect' &&
            !selected.adexes &&
            !selected.classification && (
              <MultiselectFilter
                autoFocus
                {...selected}
                value={value || []}
                onChange={onChange}
              />
            )}
          {selected.kind === 'multiselect' &&
            selected.adexes &&
            !selected.classification && (
              <AdexesFilter
                autoFocus
                {...selected}
                value={value || []}
                onChange={onChange}
              />
            )}
          {selected.kind === 'select' && (
            <SelectFilter {...selected} value={value} onChange={onChange} />
          )}
          {selected.kind === 'autocomplete' &&
            !selected.channels &&
            !selected.requester && (
              <AutocompleteFilter
                {...selected}
                value={value}
                onChange={onChange}
              />
            )}
          {selected.kind === 'numeric' && (
            <NumericFilter {...selected} value={value} onChange={onChange} />
          )}
          {selected.kind === 'bool' && (
            <BooleanFilter {...selected} value={value} onChange={onChange} />
          )}
          {selected.kind === 'tags' && (
            <TagsFilter {...selected} value={value} onChange={onChange} />
          )}
        </div>
      )}
      {selected && (
        <Actions>
          <Button variant='outlined' color='primary' onClick={onCancel}>
            Dismiss
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant='contained'
            color='primary'
            disabled={disableApply(value, (selected || {}).kind)}
            onClick={onApply}
          >
            Apply
          </Button>
        </Actions>
      )}
    </PopoverMain>
  )
}

export default FilterPopover
