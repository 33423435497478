import React, { isValidElement } from 'react'
import styled from 'styled-components'
import {
  FormHelperText,
  FormControl,
  Input as MuiInput,
  InputLabel
} from '@material-ui/core'

const FormErrorText = styled(FormHelperText)`
  color: #ca392e !important;
  position: absolute !important;
  bottom: -20px !important;
  font-size: 0.75rem !important;
  line-height: 1.66 !important;
  text-transform: none;
`;

const FormHelpText = styled(FormHelperText)`
  color: #535353;
  font-size: 12px;
  position: absolute;
  top: 25px;
  left: 18px;
`;

const FormInputLabel = styled(InputLabel)`
    color: #3E3E3E;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    transform: none;
    margin: 0 0 7px 0;
    position: relative;
    text-transform: none;
    .MuiInputLabel-asterisk {
      display: none;
    }
`;

const FormMuiInput = styled(MuiInput)`
    margin: 0 !important;
    border: ${props => props.editable ? '' : `1px solid rgba(0, 0, 0, 0.2)`};
    box-sizing: border-box;
    border-radius: 3px;
    background: #fff;
    font-size: 14px;
    padding: ${props => props.padding ? props.padding : '9px 18px'};
    &.withHelpText {
      padding: 24px 18px 14px 18px;
    }
`;

export default class Input extends React.Component {
  static defaultProps = {
    onKeyDown: () => {},
    inputRef: () => {}
  }

  render () {
    const {
      id,
      type,
      required,
      fullWidth,
      margin,
      label,
      inputLabel,
      inputRef,
      fieldType,
      autoFocus,
      value,
      variant,
      multiline,
      rows,
      placeholder,
      disabled,
      onChange,
      helpText,
      error,
      endAdornment,
      editable,
      padding,
      onKeyDown
    } = this.props

    return (
      <FormControl
        margin={margin}
        required={required}
        fullWidth={fullWidth}
      >
        <FormInputLabel htmlFor={id} shrink>{inputLabel}</FormInputLabel>
        {helpText && <FormHelpText>{helpText}</FormHelpText>}
        <FormMuiInput
          inputRef={inputRef}
          padding={padding}
          editable={editable}
          disableUnderline
          id={id}
          type={type}
          label={label}
          classes={helpText && {
            root: 'withHelpText',
          }}
          endAdornment={endAdornment}
          placeholder={placeholder}
          disabled={disabled}
          fieldType={fieldType}
          value={value}
          margin="none"
          onChange={onChange}
          onKeyDown={onKeyDown}
          error={!!error}
          autoFocus={autoFocus}
          aria-describedby={`error-${id}`}
          multiline={multiline}
          rows={rows}
        />
        <FormErrorText id={`error-${id}`}>{error}</FormErrorText>
      </FormControl>
    )
  }
}
