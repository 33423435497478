import React from "react"
import styled, {css} from 'styled-components'
import check from '../../components/Icons/Check.svg'


const Root = styled.div`
  min-width: 28ch;
  //max-width: 35ch;
  text-align: left;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
  padding: 1.25em 2.5em 2.5em 2.5em;
  font-size: 14px;
  line-height: 1.5556;
  flex: 1 1 0;
  flex-direction: column;
  display: flex;
  border-radius: 1em;
  background-color: white;
  box-sizing: border-box;
   //border: 2px solid rgb(1,99,250);
`
const Heading = styled.div`
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: 700;
  box-sizing: border-box;
`
const Price = styled.div`
  font-size: 1.8em;
  line-height: 1.2778;
  font-weight: 700;
`
const Period = styled.sup`
  color: rgb(160,174,192);
  font-size: 0.6em;
  line-height: 1.5556;
  font-weight: 400;
  top: -0.3em;
  position: relative;
  vertical-align: baseline;
`
const Description = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
  flex: 1 1 auto;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.125em;
  line-height: 1.5556;
`

const OptionsItem = styled.li`
  margin-left: 2em;
  line-height: 1.2;
  padding-bottom: 1em;
  position: relative;
  font-weight: bold;
  &:before {
    content: "";
    width: 1.2em;
    height: 1.2em;
    position: absolute;
    left: -2em;
    top: -0.1em;
    background: url(${check}) no-repeat center;
    background-size: contain;
  }
`

export const Options = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.1em;
`

const OptionExtra = styled.div`
  font-weight: 400;
  color: rgb(113,128,150);
  display: block;
`
const Actions = styled.div`
  justify-content: center;
  display: flex;

`
const Button = styled.a`
  transition-duration: .5s;
  color: rgb(1,99,250);
  padding: 0.5em 3em;
  font-size: 1.125em;
  line-height: 1.5556;
  font-weight: 700;
  display: inline-block;
  cursor: pointer;
  border-width: 4px;
  border-radius: 9999px;
  border-color: rgb(1,99,250);
  background-color: white;
  text-decoration: none;
`

export default function Plan({title, price, description, cc, sc, ccextra, scextra, }) {
  return (
    <Root>
      <Heading>{title}</Heading>
      <Price>£{price} <Period>/Quarter</Period></Price>
      <Description >{description}</Description>
      <Options >
        <OptionsItem >{sc} Search Credit{sc > 1 ? 's' : ''}
          <OptionExtra >+ £{scextra} per 1 extra</OptionExtra>
        </OptionsItem>
        <OptionsItem >{cc} Contact Credits
          <OptionExtra>+ £{ccextra} per 10 extra</OptionExtra>
        </OptionsItem>
      </Options>
      {/*<Actions>*/}
      {/*  <Button href="https://breezy.io/book-a-demo">Book a Demo</Button>*/}
      {/*</Actions>*/}
    </Root>
  )
}
