import React, { useContext } from 'react'

import { CreatorRequestContext } from '../CreatorRequestDetailsContext'
import WidgetScore from '../../Widget/WidgetScore'
import WidgetMetric from '../../Widget/WidgetMetric'
import Actions from '../../Actions'

export default function CreatorRequestProfileAside (props) {
    const { item, variant, metrics, kind, contacts, onUpdateItem, loading } = useContext(CreatorRequestContext)
    const isIntelligence = ['gap', 'landscape', 'partnerships'].includes(variant)

    const onClick = (id, action, direction) => {
        props.onQuickActions(id, action, direction)
        onUpdateItem(action)
    }

    return <>
        <WidgetMetric value={metrics.swGlobalRank} variant="rank" />
        <WidgetMetric value={metrics.domainAuthority} variant="moz" />
        {/*<Actions {...props} onClick={onClick} item={item} variant={variant} contacts={contacts} kind={kind} loading={loading} vertical />*/}
    </>
}
