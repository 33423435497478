import { gql } from '@apollo/client'
import { CREATOR_CONTACT_REQUEST_FIELDS } from './fragments'

export const GET_CREATOR = gql`
  query GetCreator {
    creator {
      id
      inboxCount
      acceptedCount
      rejectedCount
      status
      updatedAt
      channel {
        id
        domain
        icon
        title
      }
    }
  }
`

export const GET_CREATOR_REQUESTS = gql`
  query CreatorRequests (
      $status: String
    ) {
    contactRequestsForCreator (
      creatorStatus: $status
    ) {
      contactRequest {
        ...CREATOR_CONTACT_REQUEST_FIELDS
      }
      brandChannel {
        id
        title
        domain
        icon
      }
    }
  }
  ${CREATOR_CONTACT_REQUEST_FIELDS}
`;

export const ACCEPT_CREATOR_REQUEST = gql`
  mutation acceptContactRequest ($id: String!) {
    acceptContactRequest(id: $id) {
      id
    }
  }
`

export const REJECT_CREATOR_REQUEST = gql`
  mutation rejectContactRequest ($id: String!) {
    rejectContactRequest(id: $id) {
      id
    }
  }
`
