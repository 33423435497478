/* eslint-disable no-use-before-define */
import React from 'react'
import {
  TextField,
  CircularProgress
} from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'
import {Get} from '../utils/request'

const StyledOption = styled.ul`
  .MuiAutocomplete-groupUl li {
    padding-left: 0.8em;
  }
`

const FormTextField = styled(TextField)`
    .MuiFormLabel-root {
      color: #3E3E3E;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      -ms-transform: none;
      transform: none;
      margin: 0 0 5px 0;
      position: relative;
    }
    .MuiAutocomplete-endAdornment { right: 0.5em }
  .MuiInput-root {
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 14px;
    padding: 0 0.5em;
    min-height: 48px;
  }
    .MuiInput-input {
      padding: 4px!important;
      line-height: 16px;
    }
    &.withHelpText { 
      .MuiInput-root {
        padding: 25px 18px 5px 18px;
        min-height: 72px;
      }
      .MuiInputBase-adornedStart {
        padding: 25px 18px 5px 18px;
        min-height: 72px;
      }
`;

export class Asynchronous extends React.Component {
  state = {
    open: false,
    options: [],
    loading: false
  }

  typingTimeout = null

  onToggleOpen = async () => {
    const {open} = this.state
    this.setState({open: !open}, async () => {
      if (!open) {
        await this.onGetData(null)
      }
    })
  }

  onSearchChange = ({target: {value}}) => {
    clearTimeout(this.typingTimeout)
    this.typingTimeout = setTimeout(() => this.onGetData(value), 1000)
  }

  onGetData = async (value) => {
    this.setState({loading: true})

    const filtersQuery = () => {
      let queries = ''
      const {additionalFilter, filter} = this.props
      if (additionalFilter) {
        queries = `?${additionalFilter}${value ? `&${filter}=${value}` : ''}`
      } else {
        queries = `${value ? `?${filter}=${value}` : ''}`
      }

      return queries
    }

    const url = `${this.props.url}${filtersQuery()}`
    const result = await Get(url)
    const options = result[this.props.dataField].map(i => ({
      id: i.id,
      n: i.n,
      label: i[this.props.dataLabelField]
    }))
    this.setState({loading: false, options})
  }

  render() {
    const {loading, options, open} = this.state
    const {id, name, disabled, value, helpText, placeholder, error, label, onChange} = this.props
    return (
      <MuiAutocomplete
        id={id}
        name={name}
        open={open}
        multiple
        onOpen={this.onToggleOpen}
        onClose={this.onToggleOpen}
        onChange={onChange}
        getOptionLabel={({n, label}) => `${n ? `${n} - ` : ``}${label}`}
        renderOption={(option) => <StyledOption>
          <div>{option.label}</div>
          <div style={{color: 'slategrey'}}>{`${option.n ? `ID ${option.n}` : ''}`}</div>
        </StyledOption>}
        options={options}
        loading={loading}
        disabled={disabled}
        value={value}
        // defaultValue={defaultValue}
        renderInput={params => (
          <FormTextField
            {...params}
            fullWidth
            error={!!error}
            helperText={error}
            variant="standard"
            placeholder={placeholder}
            onChange={this.onSearchChange}
            InputProps={{
              ...params.InputProps,
              autocomplete: 'off',
              disableUnderline: true,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20}/> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            disableUnderline
            classes={ helpText && {
              root: 'withHelpText',
            }}
            InputLabelProps={{shrink: true}}
            label={label}
            margin="normal"
          />
        )}
      />
    )
  }
}

class Autocomplete extends React.Component {
  static defaultProps = {
    value: []
  }

  onSearch = (e, search) => {
    if (this.props.onSearch) {
      this.props.onSearch(search)
    }
  }

  render() {
    const {
      id,
      single,
      value,
      disabled,
      defaultValue,
      label,
      options,
      helpText,
      onChange,
      placeholder,
      onInputChange,
      renderOption,
      getOptionLabel,
      classes
    } = this.props

    return <MuiAutocomplete
      multiple={!single}
      // disableClearable
      freeSolo
      id={id}
      options={options}
      disabled={disabled}
      groupBy={({group}) => group}
      getOptionLabel={option => getOptionLabel ? getOptionLabel(option) : option.label}
      getOptionDisabled={({ disabled }) => disabled}
      renderOption={renderOption ? renderOption : null}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      ListboxComponent={StyledOption}
      onInputChange={this.onSearch}
      renderInput={params => (
        <FormTextField
          {...params}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
          disableUnderline
          classes={ helpText && {
            root: 'withHelpText',
          }}
          variant="standard"
          InputLabelProps={{shrink: true}}
          label={label}
          margin="normal"
          fullWidth
        />
      )}
    />
  }
}

export default Autocomplete
