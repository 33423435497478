import React, { useContext } from 'react'

import { CreatorRequestContext } from '../CreatorRequestDetailsContext'
import WidgetsGrid from '../../Widget/WidgetsLayout'
import DetailsMetrics from './CreatorRequestDetailsMetrics'
import DetailsNetworks from './CreatorRequestDetailsNetworks'
import AppearsIn from './ContactRequestDetailsAppearsIn'
import LinkButton from '../../LinkButton'
import Summary from '../../Summary'

interface DetailsViewProps {
    tagManagement?: any
    onSetViewState: Function
    onQuickActions: () => void
}

export default function CreatorRequestDetailsView (props: DetailsViewProps) {
    const { loading, summary } = useContext(CreatorRequestContext)

    return (
        <>
            {/*<Actions onQuickActions={props.onQuickActions} />*/}
            <Summary
                disableTagManagement
                loading={loading}
                {...summary}
                horizontal={false}
            />
            <WidgetsGrid>
                <DetailsMetrics onClick={props.onSetViewState}/>
                <DetailsNetworks onClick={props.onSetViewState} />
                <AppearsIn />
                <LinkButton loading={loading} fullWidth onClick={() => props.onSetViewState('profile')} to={'#'}>Explore full profile</LinkButton>
            </WidgetsGrid>
        </>
    )
}
