import React from 'react'
import WidgetListBase from '../Base/WidgetListBase'
import {WidgetBaseProps} from '../Base/WidgetBase'
import metrics from '../../../configMetrics'

function WidgetAppears (props: WidgetBaseProps) {
    return (
        <WidgetListBase {...props} label={metrics['appearsIn'].shortName} tooltip={{...metrics['appearsIn']}} />
    )
}

WidgetAppears.defaultProps = {
  value: 'Lead does not appear in any of ecosystems yet',
  size: '2x1'
}

export default WidgetAppears
