import React from 'react'
import WidgetBase, {WidgetBaseProps} from '../Base/WidgetBase'
import Networks, {NetworksProps} from '../../Networks/Networks'
import ButtonBase from '@mui/material/ButtonBase'
import metrics from '../../../configMetrics'

interface WidgetNetworksProps extends WidgetBaseProps, NetworksProps {
    compact?: boolean
}

function WidgetNetworks ({ ...props }: WidgetNetworksProps) {
    return (
        <WidgetBase {...props} component={ButtonBase} label={metrics['networks'].shortName} tooltip={{...metrics['networks']}}>
            {props.data && <Networks {...props} />}
        </WidgetBase>
    )
}

export default WidgetNetworks
