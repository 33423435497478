import { gql } from '@apollo/client'
import { USER_FIELDS } from './fragments'

export const CURRENT = gql`
  query current {
    me {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`;


export const SIGN_IN = gql`
  mutation signinUser ($SigninUserInput: SigninUserInput!) {
    signinUser(input: $SigninUserInput) {
      token
      user {
        ...USER_FIELDS
      }
    }
  }
  ${USER_FIELDS}
`


export const SIGN_UP = gql`
  mutation signupUser ($SignupUserInput: SignupUserInput!) {
    signupUser(input: $SignupUserInput) {
      id
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser ($UpdateUserInput: UpdateUserInput!) {
    updateUser(input: $UpdateUserInput) {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`

export const VERIFY_USER = gql`
  mutation verifyUser {
    verifyUser {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`
