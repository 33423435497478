import React from 'react'
import styled from 'styled-components'
import {Tooltip} from '@material-ui/core'
import {rgba, darken} from 'polished'

const typeColors = {
  Content: 'rgb(255,232,142)',
  Business: 'rgb(102,102,102)',
}

const Root = styled.div`
  background: ${props => typeColors[props.type]};
  color:  ${props => props.type === 'Content' ? 'black' : 'white'};
  height: 1.85em;
  line-height: 1.85em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  padding: 0 0.5em 0 0.3em;
  font-size: ${props => props.fontSize ? props.fontSize : '0.83em'};
  & svg {
    position: relative;
    top: 0.16em;
  }
`

export const TypeLabel = ({type, fontSize}) => {
  let label = undefined
  if (type === 'Content') {
    label = 'Creator'
  } else if (type === 'Business') {
    label = 'Brand'
  }
  return (type === 'Content' || type === 'Business') ?
      // <Tooltip title={`Contacts ${status === 'unsuccessful' ? 'not found' : status}`}>
       <Root type={type} fontSize={fontSize}>
          {label}
        </Root>
      // </Tooltip>
    : ''
}
