import AccessControl from 'accesscontrol'
import brandOwner from './brandOwner'
import user from './brandUser'

const grantsObject = {
  brandOwner,
  user
}

export default new AccessControl(grantsObject)
