import { gql } from '@apollo/client'

export const SYSTEM_TAGS_LIST = gql`
  query SystemTagsList($level: Int!) {
    classificationsConnection(take: 2, skip: 0, level: $level, takeAll: true) {
      totalCount
      edges {
        node {
          id
          level
          name
        }
      }
    }
  }
`
