import { gql } from '@apollo/client'
import { BRAND_FIELDS } from './fragments'

export const GET_BRAND = gql`
  query brand ($id: String!) {
    brand (id: $id) {
      ...BRAND_FIELDS
    }
  }
  ${BRAND_FIELDS}
`

export const GET_BRANDS = gql`
  query brands {
    brands {
      ...BRAND_FIELDS
    }
  }
  ${BRAND_FIELDS}
`

export const CREATE_BRAND = gql`
  mutation createBrand ($CreateBrandInput: CreateBrandInput!) {
    createBrand(input: $CreateBrandInput) {
      ...BRAND_FIELDS
    }
  }
  ${BRAND_FIELDS}
`

export const UPDATE_BRAND = gql`
  mutation updateBrand ($UpdateBrandInput: UpdateBrandInput!) {
    updateBrand(input: $UpdateBrandInput) {
      ...BRAND_FIELDS
    }
  }
  ${BRAND_FIELDS}
`
