import { gql } from '@apollo/client'

import GqlClient from '../../gql/gql-client'
import { CONTACT_FIELDS, ENTITY_FIELDS } from '../../gql/fragments'
import { GET_CHANNELS_TAGS } from '../../gql/tags'
import { CHANNELS_CLASSIFICATION } from '../../gql/channels'
import { prepareEdges } from '../../utils/gql-data'
import { convertItems, convertItemToDetails } from '../../utils/list-items'
import { channel } from 'diagnostics_channel'
import { assertWhile } from '@babel/types'
import { constants } from 'crypto'
import { find } from 'lodash'

const LEAD_PLACEMENT_FIELDS = gql`
  fragment LEAD_PLACEMENT_FIELDS on LeadPlacement {
    id
    url
    title
    subdomain
    breezyScore
    externalPagesToPage
    externalPagesToSubdomain
    competitors {
      searchCompetitor {
        channel {
          icon
          domain
        }
      }
    }
    keywords {
      rank
      searchKeyword {
        name
      }
    }
  }
`

const SEARCH_FIELDS = gql`
  fragment SEARCH_FIELDS on Search {
    id
    name
    competitorsCount
    keywordsCount
    country {
      id
      code
    }
  }
`

const LEAD_FIELDS = gql`
  fragment LEAD_FIELDS on Lead {
    leadId
    domain
    breezyScore
    keywordSeedsCount
    competitorSeedsCount
    partner {
      id
    }
    relevant {
      id
    }
    internalRelevant
    competitors {
      searchCompetitor {
        id
        channel {
          icon
          domain
        }
      }
    }
    keywords {
      rank
      searchKeyword {
        name
      }
    }
    leadPlacements {
      ...LEAD_PLACEMENT_FIELDS
    }
    search {
      ...SEARCH_FIELDS
    }
  }
  ${LEAD_PLACEMENT_FIELDS}
  ${SEARCH_FIELDS}
`

const CHANNEL_SIMPLE_FRAGMENT = gql`
  fragment CHANNEL_SIMPLE_FRAGMENT on Channel {
    id
    lang
    icon
    title
    domain
    mozDomainAuthority
    partnershipsInCount
    partnershipsOutCount
    type1Classification
    swVisits
    swGlobalRank
  }
`

const CHANNEL_FRAGMENT = gql`
  fragment CHANNEL_FRAGMENT on Channel {
    id
    lang
    icon
    title
    domain
    mozDomainAuthority
    partnershipsInCount
    partnershipsOutCount
    type1Classification
    swVisits
    swGlobalRank
    swGeoMetrics {
      position
      countryRank
      share
      visits
      country {
        code
      }
    }
  }
`

const CONTACTS = gql`
  query GetContact(
    $brandId: String!
    $channelId: String!
    $channelIds: [String!]!
  ) {
    contact(brandId: $brandId, channelId: $channelId) {
      ...CONTACT_FIELDS
    }
    contactRequests(brandId: $brandId, channelIds: $channelIds) {
      status
    }
  }
  ${CONTACT_FIELDS}
`

export const CONTACT_ENTITY_DETAILS = gql`
  query GetContactDetails(
    $brandId: String!
    $channelId: String!
    $channelIds: [String!]!
  ) {
    entity(brandId: $brandId, channelId: $channelId) {
      channels {
        id
        domain
        title
      }
      ...ENTITY_FIELDS
    }
    channelEntityContacts(brandId: $brandId, channelId: $channelId) {
      ...CONTACT_FIELDS
    }
    channelContacts(brandId: $brandId, channelId: $channelId) {
      ...CONTACT_FIELDS
    }
    contactRequests(brandId: $brandId, channelIds: $channelIds) {
      status
    }
  }
  ${ENTITY_FIELDS}
  ${CONTACT_FIELDS}
`

export const CONTACTS_DETAILS = gql`
  query GetContactDetails(
    $brandId: String!
    $channelId: String!
    $channelIds: [String!]!
  ) {
    entity(brandId: $brandId, channelId: $channelId) {
      channels {
        id
        domain
        title
      }
      ...ENTITY_FIELDS
    }
    channelContacts(brandId: $brandId, channelId: $channelId) {
      ...CONTACT_FIELDS
    }
    contactRequests(brandId: $brandId, channelIds: $channelIds) {
      status
    }
  }
  ${ENTITY_FIELDS}
  ${CONTACT_FIELDS}
`

const CHANNEL = gql`
  query GetChannel($id: String!) {
    channel(id: $id) {
      ...CHANNEL_FRAGMENT
    }
  }
  ${CHANNEL_FRAGMENT}
`

const BRAND_PARTNERS_OF_CHANNEL = gql`
  query GetBrandPartnersOfChannelConnection($channelId: String!) {
    brandPartnersOfChannelConnection(channelId: $channelId) {
      edges {
        node {
          ...CHANNEL_FRAGMENT
        }
      }
      totalCount
    }
  }
  ${CHANNEL_FRAGMENT}
`

const CREATOR_PARTNERS_OF_CHANNEL = gql`
  query GetCreatorPartnersOfChannelConnection($channelId: String!) {
    creatorPartnersOfChannelConnection(channelId: $channelId) {
      edges {
        node {
          ...CHANNEL_FRAGMENT
        }
      }
      totalCount
    }
  }
  ${CHANNEL_FRAGMENT}
`

const LEAD = gql`
  query GetLead($id: String!) {
    lead(id: $id) {
      ...LEAD_FIELDS
    }
  }
  ${LEAD_FIELDS}
`

const PROSPECT = gql`
  query GetProspect($id: String!) {
    prospect(id: $id) {
      id
      kind
      domain
      swVisits
      swGlobalRank
      avgBreezyScore
      mozDomainAuthority
      totalCompetitors
      totalKeywords
      partnershipsOutCount
      relevantLeads {
        ...LEAD_FIELDS
      }
      partnerLeads {
        ...LEAD_FIELDS
      }
      channel {
        ...CHANNEL_SIMPLE_FRAGMENT
      }
    }
  }
  ${CHANNEL_SIMPLE_FRAGMENT}
  ${LEAD_FIELDS}
`

const APPEARS_IN_SEARCHES = gql`
  query GetAppearsInSearches($brandId: String!, $channelId: String!) {
    channelAppearsInSearches(brandId: $brandId, channelId: $channelId) {
      lead {
        leadId
        domain
        breezyScore
        competitorSeedsCount
        keywordSeedsCount
      }
      search {
        ...SEARCH_FIELDS
      }
    }
  }
  ${SEARCH_FIELDS}
`

export const fetchChannelDetails = async (channelId: string) => {
  const { success, mozDomainAuthority, ...rest } = await GqlClient.query({
    query: CHANNEL,
    variables: { id: channelId },
  })

  if (success) {
    const { domain, swGeoMetrics, ...channel } = rest.data['channel']
    return {
      success,
      channel: {
        domain,
        link: domain,
        countries: swGeoMetrics.map(
          ({ country, countryRank, ...restCountry }) => ({
            rank: countryRank,
            code: country.code,
            ...restCountry,
          })
        ),
        ...channel,
      },
    }
  }
  return { success, channel: {} }
}

export const fetchAppearsInSearches = async (
  channelId: string,
  brandId: string
) => {
  const { success, ...rest } = await GqlClient.query({
    query: APPEARS_IN_SEARCHES,
    variables: { brandId, channelId },
  })
  if (success) {
    let tags = []
    let systemTags = []
    const { success: successTags, ...restTags } = await GqlClient.query({
      query: GET_CHANNELS_TAGS,
      variables: { brandId, channelIds: [channelId] },
    })
    const { success: successSystemTags, ...restSystemTags } =
      await GqlClient.query({
        query: CHANNELS_CLASSIFICATION,
        variables: { channelIds: [channelId] },
      })

    if (successSystemTags) {
      const channelSystemTags =
        restSystemTags.data['classificationsByChannels'][0]
      systemTags =
        (channelSystemTags && channelSystemTags.classifications) || []
    }

    if (successTags) {
      const channelTags = restTags.data['tagsForChannels'][0] || {}
      tags = (channelTags.tags || []).map(({ id, name }) => ({
        id,
        label: name,
      }))
    }

    const data = rest.data['channelAppearsInSearches']

    const appearsInSearches = data.map(({ search, lead }) => {
      const { breezyScore, competitorSeedsCount, keywordSeedsCount } = lead
      const {
        id: searchId,
        name,
        country,
        competitorsCount,
        keywordsCount,
      } = search

      return {
        name,
        searchId,
        breezyScore,
        competitorSeedsCount,
        competitorsCount,
        keywordSeedsCount,
        keywordsCount,
        country: country.code,
      }
    })

    return { success, appearsInSearches, tags, systemTags }
  }
  return { success, appearsInSearches: [], tags: [], systemTags: [] }
}

export const fetchChannelContacts = async (
  channelId: string,
  brandId: string
) => {
  // constants= await GqlClient.query({
  //   query: CONTACTS,
  //   variables: { brandId, channelId, channelIds: [channelId] },
  // })

  const { success, ...rest } = await GqlClient.query({
    query: CONTACT_ENTITY_DETAILS,
    variables: { brandId, channelId, channelIds: [channelId] },
  })

  if (success) {
    let contactInfos = []
    let entityDetails = null
    const { channelContacts, channelEntityContacts, contactRequests, entity } =
      rest.data
    const contactRequest = contactRequests[0]
      ? contactRequests[0]
      : { status: undefined }

    if (contactRequest.status === 'found') {
      contactInfos = channelContacts.map((contact) => ({
        id: contact.id,
        ...convertItemToDetails(convertItems(contact.contactData)),
      }))

      contactInfos = [
        ...contactInfos,
        ...channelEntityContacts.map((contact) => ({
          id: contact.id,
          ...convertItemToDetails(convertItems(contact.contactData)),
        })),
      ]

      if (entity) {
        entityDetails = convertItemToDetails(convertItems(entity.entityData))
        let entityChannel = find(entity.channels, ['id', channelId])
        entityDetails = {
          ...entityDetails,
          ...(entityChannel && { channel: entityChannel }),
        }
      }
    }

    return { success, contactRequest, contactInfos, entityDetails }
  }
  return { success: true, contactRequest: {}, contactInfos: [] }
}

export const fetchItemDetails = async (
  itemId: string,
  brandId: string,
  variant: string
) => {
  const query = variant === 'lead' ? LEAD : PROSPECT
  const dataField = variant === 'lead' ? 'lead' : 'prospect'

  const { success, ...rest } = await GqlClient.query({
    query,
    variables: { id: itemId },
  })

  if (success) {
    const item = rest.data[dataField]
    const leads = variant === 'lead' ? [item] : item[`${variant}Leads`]

    return {
      success,
      ...(variant === 'lead'
        ? { item: { ...item, id: item.leadId } }
        : { item }),
      leads,
    }
  }

  return { success, item: {}, search: {}, leads: {} }
}

export const fetchPromotes = async (channelId: string, kind: string) => {
  const query =
    kind === 'creator' ? BRAND_PARTNERS_OF_CHANNEL : CREATOR_PARTNERS_OF_CHANNEL
  const dataField = `${
    kind === 'creator' ? 'brand' : 'creator'
  }PartnersOfChannelConnection`
  const { success, ...rest } = await GqlClient.query({
    query,
    variables: { channelId },
  })
  if (success) {
    const { edges } = rest.data[dataField]
    const channels = prepareEdges(edges)
    return { success, channels }
  }
  return { success }
}
