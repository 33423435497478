import { omitBy, isNil } from 'lodash'
import Config from './config'
import { countriesOnly } from './components/Leads/CountriesFlag'
import { typeColors } from './new-components/Tags/SystemTags'

export const SEARCH = {
  id: 'search',
  kind: 'search',
  label: `Search for this text`,
}

export const DIVIDER_TOP = {
  label: '',
  id: 'manual-upper',
  kind: 'divider',
}

export const DIVIDER_BOTTOM = {
  id: 'manual-down',
  kind: 'divider',
}

export const TYPE1CLASSIFICATION = {
  label: 'Type',
  id: 'type1Classification',
  defaultValue: 'all',
  kind: 'select',
  options: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Creator',
      value: 'Content',
    },
    {
      label: 'Brand',
      value: 'Business',
    },
    {
      label: 'Unclassified',
      value: 'Unclassified',
    },
  ],
  placeholder: 'Creator vs Brand',
}

export const LANGUAGES = {
  label: 'Language',
  id: 'lang',
  kind: 'autocomplete',
  options: Config.LANGUAGES,
  placeholder: 'E.g.: English',
}

export const COUNTRIES = {
  label: 'Countries',
  id: 'byCountries',
  kind: 'multiselect',
  options: countriesOnly,
  placeholder: 'E.g.: United Kingdom',
}

export const TAGS = {
  label: 'Tags',
  id: 'byTags',
  kind: 'tags',
}

export const REQUESTER = {
  label: 'Requester',
  id: 'requester',
  kind: 'autocomplete',
  requester: true,
}

export const COMPETITORS_BY_DOMAIN = {
  label: 'Competitors by Domain',
  id: 'byCompetitors',
  kind: 'multiselect',
  options: [],
  placeholder: 'E.g.: site.com',
}

export const SCORE = {
  label: 'Score',
  id: 'breezyScore',
  kind: 'range',
  min: 0,
  max: 100,
  step: 1,
}

export const AVG_SCORE = {
  label: 'Average Score',
  id: 'avgBreezyScore',
  kind: 'range',
  min: 0,
  max: 100,
  step: 1,
}

export const MOZ = {
  label: 'Domain Authority',
  id: 'domainAuthority',
  kind: 'range',
  min: 0,
  max: 100,
  step: 1,
}

export const COMPETITORS_COUNT = {
  label: 'Competitors Count',
  id: 'competitorSeedsCount',
  kind: 'range',
  min: 0,
  max: 0,
  step: 1,
}

export const TOTAL_COMPETITORS_COUNT = {
  label: 'Total Competitors',
  id: 'totalCompetitors',
  kind: 'range',
  min: 0,
  max: 1000,
  step: 1,
}

export const KEYWORDS_COUNT = {
  label: 'Keywords Count',
  id: 'keywordSeedsCount',
  kind: 'range',
  min: 0,
  max: 0,
  step: 1,
}

export const TOTAL_KEYWORDS_COUNT = {
  label: 'Total Keywords',
  id: 'totalKeywords',
  kind: 'range',
  min: 0,
  max: 1000,
  step: 1,
}

export const VISITS = {
  label: 'Similarweb Visits',
  id: 'swVisits',
  kind: 'range',
  min: 0,
  max: 90751889638,
  step: 1,
  logarithmic: true,
}

export const RANK = {
  label: 'Similarweb Rank',
  id: 'swRank',
  kind: 'range',
  min: 0,
  max: 30000000,
  step: 1,
  logarithmic: true,
}

export const WITHOUT_PARTNERS = {
  label: 'Hide existing partners',
  id: 'withoutPartners',
  kind: 'bool',
}

export const WITHOUT_RELEVANT = {
  label: 'Hide Relevant',
  id: 'withoutRelevant',
  kind: 'bool',
}

export const AFFILIATES_ONLY = {
  label: 'With partnerships only',
  id: 'affiliatesOnly',
  kind: 'bool',
  groupBy: 'Apply filter',
}

export const PARTNERSHIPS_ONLY = {
  label: 'With partnerships only',
  id: 'partnershipsOnly',
  kind: 'bool',
  groupBy: 'Apply filter',
}

export const SYSTEM_ENTITY = {
  label: 'Entity',
  id: 'type1',
  kind: 'multiselect',
  level: 1,
  classification: true,
  placeholder: 'E.g.: Creator or Brand',
  background: typeColors[1],
  color: 'rgba(62, 88, 97, 0.9)',
}

export const SYSTEM_CATEGORY = {
  label: 'Category',
  id: 'type2',
  kind: 'multiselect',
  level: 2,
  classification: true,
  placeholder: 'E.g.: Blog, Reviews, Coupons, News, etc.',
  background: typeColors[2],
  color: 'rgba(62, 88, 97, 0.9)',
}

export const SYSTEM_NICHE = {
  label: 'Niche',
  id: 'type3',
  kind: 'multiselect',
  level: 3,
  classification: true,
  placeholder: 'E.g.: Fashion, Politics, Movies, Sports, etc.',
  background: typeColors[3],
  color: 'rgba(62, 88, 97, 0.9)',
}

export const getFilterClassifications = (types) => {
  const type1 = types.type1
    ? types.type1.map(({ level, label }) => ({
        level: parseInt(level),
        name: label,
      }))
    : []
  const type2 = types.type2
    ? types.type2.map(({ level, label }) => ({
        level: parseInt(level),
        name: label,
      }))
    : []
  const type3 = types.type3
    ? types.type3.map(({ level, label }) => ({
        level: parseInt(level),
        name: label,
      }))
    : []
  const filter = [...type1, ...type2, ...type3]

  return filter && filter.length ? filter : null
}

const getRangeFilters = (filters) => {
  let result = {}
  const {
    breezyScore,
    avgBreezyScore,
    domainAuthority,
    competitorSeedsCount,
    totalCompetitors,
    keywordSeedsCount,
    totalKeywords,
    swVisits,
    swRank,
  } = filters

  if (breezyScore) {
    const [breezyScoreStart, breezyScoreEnd] = breezyScore
    result = { ...result, breezyScoreStart, breezyScoreEnd }
  }
  if (avgBreezyScore) {
    const [avgBreezyScoreStart, avgBreezyScoreEnd] = avgBreezyScore
    result = { ...result, avgBreezyScoreStart, avgBreezyScoreEnd }
  }
  if (domainAuthority) {
    const [mozDomainAuthorityStart, mozDomainAuthorityEnd] = domainAuthority
    result = { ...result, mozDomainAuthorityStart, mozDomainAuthorityEnd }
  }
  if (competitorSeedsCount) {
    const [competitorSeedsCountStart, competitorSeedsCountEnd] =
      competitorSeedsCount
    result = { ...result, competitorSeedsCountStart, competitorSeedsCountEnd }
  }
  if (keywordSeedsCount) {
    const [keywordSeedsCountStart, keywordSeedsCountEnd] = keywordSeedsCount
    result = { ...result, keywordSeedsCountStart, keywordSeedsCountEnd }
  }
  if (totalCompetitors) {
    const [totalCompetitorsStart, totalCompetitorsEnd] = totalCompetitors
    result = { ...result, totalCompetitorsStart, totalCompetitorsEnd }
  }
  if (totalKeywords) {
    const [totalKeywordsStart, totalKeywordsEnd] = totalKeywords
    result = { ...result, totalKeywordsStart, totalKeywordsEnd }
  }
  if (swRank) {
    const [swGlobalRankStart, swGlobalRankEnd] = swRank
    result = { ...result, swGlobalRankStart, swGlobalRankEnd }
  }
  if (swVisits) {
    const [swVisitsStart, swVisitsEnd] = swVisits
    result = { ...result, swVisitsStart, swVisitsEnd }
  }

  return omitBy(result, isNil)
}

export const getFilterVariables = (filters = {}) => {
  const {
    search,
    searchId,
    lang,
    byCountries,
    type1Classification,
    partnershipsOnly,
    byTags,
    byCompetitors,
    withoutRelevant,
    withoutPartners,
    affiliatesOnly,
    requester,
  } = filters

  const manualClassifications = getFilterClassifications(filters)

  return {
    ...(searchId && searchId !== 'all' && { searchId }),
    ...(search && { search }),
    ...(lang && { lang: lang.value }),
    ...(type1Classification &&
      type1Classification !== 'all' && { type1Classification }),
    ...(byCountries && { countries: byCountries.map(({ value }) => value) }),
    ...(partnershipsOnly && { isCreator: true }),
    ...(byTags &&
      byTags.length && { tagIds: byTags.map(({ value }) => value) }),
    ...getRangeFilters(filters),
    ...(byCompetitors &&
      byCompetitors.length && {
        competitors: byCompetitors.map(({ value }) => value),
      }),
    ...(withoutPartners && { withoutPartners }),
    ...(withoutRelevant && { withoutRelevants: true }),
    ...(affiliatesOnly && { partnershipsOutCountStart: 1 }),
    ...(requester && { requesterId: requester.value }),
    ...(manualClassifications && { manualClassifications }),
  }
}
