import React, { Component } from 'react'
import styled from 'styled-components'
import TinyFlag from 'tiny-flag-react'
import Tooltip from '@mui/material/Tooltip'
import resolveCountry from '../../utils/resolveCountry'
import { imageKitUrl } from '../../config'

const Root = styled.span`
  position: relative;
  top: ${props => !props.padding && '0.05em'};
  // left: ${props => props.padding && '0.18em'};
  height: 1.2em;
  font-size: 1.5em;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
`

interface CountryData {
    value: string
    label: string
    labelEmoji: string
}

function CountryFlag ({iso}: { iso: string }) {
    // TODO: Consider Refactor resolveCountry to React hook to be able to use for label and flag in one row without double resolving
    const countryData: CountryData = resolveCountry(iso)
    const fallbackImageURL: string =`${imageKitUrl}/flags/${iso}.svg`
    return (
        <Tooltip arrow PopperProps={{
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, -10],
                    }
                }
            ]
        }} title={countryData.label} placement="top">
            <Root padding={countryData.value === 'GLOBAL'}>
                <TinyFlag
                    country={iso}
                    alt={countryData.label}
                    fallbackImageURL={fallbackImageURL}
                />
            </Root>
        </Tooltip>
    )
}

CountryFlag.defaultProps = {
  iso: 'GLOBAL'
}

export default CountryFlag
