import React from 'react'
import styled, {css} from 'styled-components'
import cleanUrl from '../../utils/cleanUrl'
import obfuscateString from '../../utils/obfuscateString'

export const Root = styled.div`
  display: inline-block;
  //font-size: 12px;
  //line-height: 25px;
  color: black;
  font-weight: 600;
  white-space: nowrap;
  //text-overflow: ellipsis;
  //overflow: hidden;
  margin-right: 0.5em;
  svg {
    width: 100px;
    height: 25px;
  }
  a {
    color: black;
    text-decoration: none;
    transition: .2s;
    &:hover {
      color: #0041E8;  
    }
  }
  ${props => props.obfuscate && css`
        filter: blur(8px);
  `}
`

const Url = ({children, active, obfuscate}) => {
  return (
    <Root obfuscate={obfuscate}>{active && !obfuscate ? <a target="_blank"
                                     href={'http://' + cleanUrl(children)}>{cleanUrl(children)}</a> : obfuscate ? obfuscateString(cleanUrl(children)) : cleanUrl(children)}</Root>
  )
}

export default Url
