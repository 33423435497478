import React from 'react'

export const DragIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 67 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.4" x="18" y="10" width="49" height="17" rx="3" fill="white"/>
        <path opacity="0.6" d="M9 8C9 6.34315 10.3431 5 12 5H55C56.6569 5 58 6.34315 58 8V19C58 20.6569 56.6569 22 55 22H12C10.3431 22 9 20.6569 9 19V8Z" fill="white"/>
        <rect width="49" height="17" rx="3" fill="white"/>
  </svg>
)

