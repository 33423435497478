import React from 'react'
import styled from 'styled-components'
import pluraliseUnit from "../../utils/pluraliseUnit";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  opacity: 0;
  :hover {
    transition-delay: 1s;
    opacity: 1;
  }
`
const Warning = styled.div`
  color: #FF3F56;
  position: absolute;
  bottom: -1.8em;
`

const CurrentCost = ({credits})  => {
  return (
    <Root>
      <div>Current cost: <strong>{credits.currentCost} {pluraliseUnit('credit', credits.currentCost)}</strong></div>
      {credits.creditLimit && <Warning>You are <strong>{credits.creditsShort} {pluraliseUnit('credit', credits.creditsShort)}</strong> short</Warning>}
    </Root>
  )
}

export default CurrentCost
