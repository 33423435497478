import React from 'react'
import IntermediateModal from './IntermediateModal'
import config from '../../config'

export default class RequestContactsModal extends React.Component {
  state = { openCredits: false }
  get credits () {
    const { perContact } = config.credits
    const { itemIds } = this.props
    if (Array.isArray(itemIds)) {
      return { currentCost: perContact * itemIds.length }
    }
    return { currentCost: perContact }
  }

  get needUpgrade () {
    const { creditsLeft } = this.props
    const { currentCost } = this.credits
    return creditsLeft < currentCost
  }

  get heading () {
    return !this.needUpgrade ?
      'Do you want to request contact information?'
      :
      'Uh-oh! You’re short on credits.'
  }

  get button () {
    return !this.needUpgrade ?
      'Request Contacts'
      :
      'Upgrade my account to get more contacts'
  }

  render () {
    const {
      open,
      role,
      thinking,
      creditsLeft,
      onRequest,
      onClose,
      onShowPlans,
      billingProps,
      billingEndTrial
    } = this.props
    const { currentCost } = this.credits

    return <IntermediateModal
      open={open}
      role={role}
      thinking={thinking}
      variant={'contacts'}
      currentCost={currentCost}
      creditsLeft={creditsLeft}
      heading={this.heading}
      subHeading={"Say the word and we'll dig out relevant contact information for these Prospects."}
      buttonCopy={this.button}
      billingProps={billingProps}
      onAction={onRequest}
      onClose={onClose}
      onShowPlans={onShowPlans}
      billingEndTrial={billingEndTrial}
    />
  }
}
