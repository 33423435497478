import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
// @ts-ignore
import { gql, useLazyQuery } from '@apollo/client'

import {
  fetchAppearsInSearches,
  fetchChannelContacts,
  fetchChannelDetails,
  fetchItemDetails,
  fetchPromotes,
} from './requests'
import {
  handleFetchResults,
  handleIntelligenceData,
  handlePromotes,
} from './handlers'
import {
  DetailsContextInterface,
  DetailsContextPropsInterface,
} from './interfaces'

export const DetailsContext = React.createContext<DetailsContextInterface>({
  loading: true,
})

const INTELLIGENCE_QUERY = gql`
  query Ecosystems($channelId: String!) {
    brandChannelEcosystems(channelId: $channelId) {
      id
      name
      ecosystemId
      published
    }
    partnerChannelEcosystems(channelId: $channelId) {
      id
      name
      ecosystemId
      published
    }
    adexDistributionForChannelAsBrand(channelId: $channelId) {
      id
      name
      count
      percent
    }
    adexDistributionForChannelAsCreator(channelId: $channelId) {
      id
      name
      count
      percent
    }
  }
`

const DetailsContextProvider = (props: DetailsContextPropsInterface) => {
  const { channelId, itemId, brandId, variant, kind } = props
  const isIntelligence = ['gap', 'landscape', 'partnerships'].includes(variant)

  const [fetchIntelligenceData] = useLazyQuery(INTELLIGENCE_QUERY)
  const [loading, setLoading] = useState(true)
  const [geo, setGeo] = useState(undefined)
  const [item, setItem] = useState({
    prospect: false,
    partner: false,
    requested: undefined,
  })
  const [contacts, setContacts] = useState({})
  const [tags, setTags] = useState([])
  const [systemTags, setSystemTags] = useState([])
  const [promotes, setPromotes] = useState([])
  const [summary, setSummary] = useState({})
  const [metrics, setMetrics] = useState({})
  const [countries, setCountries] = useState([])
  const [networks, setNetworks] = useState([])
  const [searches, setSearches] = useState([])
  const [keywords, setKeywords] = useState([])
  const [competitors, setCompetitors] = useState([])
  const [ecosystems, setEcosystems] = useState([])
  const [placements, setPlacements] = useState([])
  const [keywordCompetitorRatio, setKeywordCompetitorRatio] = useState({})

  const fetchData = async () => {
    const promises = [
      fetchIntelligenceData({ variables: { channelId } }),
      fetchChannelDetails(channelId),
      fetchChannelContacts(channelId, brandId),
      fetchAppearsInSearches(channelId, brandId),
      ...(!isIntelligence ? [fetchItemDetails(itemId, brandId, variant)] : []),
    ]
    return await Promise.allSettled(promises)
  }

  const setDefaultValues = () => {
    setGeo(undefined)
    setItem({ prospect: false, partner: false })
    setContacts({})
    setTags([])
    setSystemTags([])
    setPromotes([])
    setSummary({})
    setMetrics({})
    setCountries([])
    setNetworks([])
    setSearches([])
    setKeywords([])
    setEcosystems([])
    setPlacements([])
    setKeywordCompetitorRatio({})
  }

  const setCoreApiData = (results): undefined => {
    if (!results) return

    setSummary(results.summary)
    setMetrics(results.metrics)
    setCountries(results.countries)
    setItem(results.item)
    setContacts(results.contacts)
    setTags(results.tags)
    setSystemTags(results.systemTags)

    if (!isIntelligence) {
      setGeo(results.geo)
      setSearches(results.searches)
      setKeywords(results.keywords)
      setCompetitors(results.competitors)
      setPlacements(results.placements)
      setKeywordCompetitorRatio(results.keywordCompetitorRatio)
    }
  }

  const setIntelligenceApiData = (results): undefined => {
    if (!results) return
    setNetworks(results.networks)
    setEcosystems(results.ecosystems)
  }

  useEffect(() => {
    if (channelId) {
      setLoading(true)

      setDefaultValues()

      fetchData().then(async (results) => {
        const [intelligence, channel, contacts, searches, view] = results

        const coreApiResults = handleFetchResults(
          [channel, searches, view],
          variant,
          contacts,
          props.intelligenceItem
        )
        const intelligenceApiResult = handleIntelligenceData(
          intelligence,
          coreApiResults.summary.type
        )

        console.log('coreApiResults', coreApiResults)
        setCoreApiData(coreApiResults)
        setIntelligenceApiData(intelligenceApiResult)

        if (coreApiResults.summary.type) {
          fetchPromotes(channelId, coreApiResults.summary.type).then(
            (result) => {
              if (!Boolean(result.message)) {
                const handledPromotes = handlePromotes(
                  result.channels,
                  coreApiResults.summary.type
                )
                setPromotes(handledPromotes)
              }
            }
          )
        }
        setLoading(false)
      })
    }
  }, [channelId, itemId])

  const onUpdateItem = (action: string, success) => {
    const { prospect } = item
    if (action === 'unmark') {
      setItem({ ...item, prospect: false, partner: false })
    } else if (action === 'toPartners') {
      setItem({ ...item, prospect: false, partner: true })
    } else if (action === 'toProspects') {
      setItem({ ...item, prospect: !prospect, partner: false })
    } else if ('setContactRequested') {
      setContacts({
        ...contacts,
        ...(success && { status: 'requested' }),
      })
    }
  }

  const onUpdateTags = (tag, action) => {
    if (action === 'add') {
      setTags([...tags, tag])
    } else if (action === 'remove') {
      setTags(tags.filter(({ id }) => id !== tag))
    } else if (action === 'update') {
      setTags(
        tags.map(({ id, ...rest }) => ({
          id,
          ...(id === tag.id && tag),
          ...(id !== tag.id && rest),
        }))
      )
    }
  }

  return (
    <DetailsContext.Provider
      value={{
        kind,
        variant,
        loading,
        geo,
        tags,
        systemTags,
        item,
        metrics,
        countries,
        networks,
        searches,
        contacts,
        promotes,
        placements,
        // @ts-ignore
        brandEcosystems: props.brandEcosystems,
        ecosystems,
        keywords,
        competitors,
        summary,
        keywordCompetitorRatio,
        geoFilter: props.geoFilter,
        onUpdateItem,
        onUpdateTags,
      }}
    >
      {props.children}
    </DetailsContext.Provider>
  )
}

export default inject(({ IntelligenceStore: store }) => ({
  brandEcosystems: store.brandEcosystems,
}))(observer(DetailsContextProvider))
