import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import { orderBy } from 'lodash'
import {Button, Tooltip} from '@material-ui/core'
import {Tag as TagIcon} from 'react-feather'
import Tag from './Tag'

const MAX_TAGS = 3

export const TagManagementAction = styled(Button)`
  color: ${props => props.theme.palette.primary.main};
  padding: 0.02em;
  display: flex;
  justify-content: center;
  height: 21.59px;
  width: 21.59px;
  border-radius: 10px;
  overflow: hidden;
  min-width: auto;
  background-color: ${props => props.theme.palette.brand.blue.light};
  opacity: 0.8;
  font-size: 12px;
`

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.3em;
  row-gap: 0.2em;
`

const TagManagement = (props) => (
  <Tooltip title={props.tooltipMessage} leaveDelay={0} enterNextDelay={500} enterDelay={500}>
    <TagManagementAction {...props} size={'small'} disabled={props.locked}>
       {props.moreAmount > 0 ? '+' + props.moreAmount : <TagIcon size={'1em'} />}
    </TagManagementAction>
  </Tooltip>
)

class TagList extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    filter: PropTypes.bool,
    onManage: PropTypes.func.isRequired
  }

  static defaultProps = {
    tags: [],
    additionalTagsCount: 0
  }

  constructor (props) {
    super(props)
    this.state = {}
  }

  get tags () {
    const { variant, tags, additionalTagsCount, maxTags } = this.props
    const sortedTags = orderBy(tags, ['label'],['asc'])
    return variant === 'item' ? sortedTags.slice(0, (maxTags || MAX_TAGS) - additionalTagsCount) : sortedTags
  }

  render() {
    const {
      maxTags,
      locked,
      tags,
      children,
      additionalTagsCount,
      onManage,
      onSelect,
      onUpdate,
      onRemove,
      onDelete,
      variant
    } = this.props

    const tagsAmount = additionalTagsCount + tags.length
    const showMore = variant === 'item' && tagsAmount > (maxTags || MAX_TAGS)
    const showManagement = (variant === 'item' && showMore) || variant === 'details'

    return (
      <Root>
        {children}
        {
          this.tags.map(({ id, label, unique }) => (
            <Tag
              locked={locked}
              variant={variant}
              id={id}
              key={id}
              name={label}
              unique={unique}
              onSelect={onSelect}
              onUpdate={onUpdate}
              onRemove={onRemove}
              onDelete={onDelete}
            />
          ))
        }
        {
          showManagement && <TagManagement
            size={'small'}
            locked={locked}
            variant={variant}
            moreAmount={variant === 'details' ? undefined : tagsAmount - (maxTags || MAX_TAGS)}
            tagsLength
            onClick={onManage}
            tooltipMessage={'Manage tags'}
          />
        }
      </Root>
    )
  }
}

export default TagList
