import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText';

import {Trash2, Edit2} from 'react-feather'

function TagMenu({onClose, onEdit, onDelete, anchorEl}) {

  return (
      <Menu
        id="tag-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        style={{width: 135}}
      >
        <MenuItem onClick={onEdit}>
          <ListItemIcon fontSize={'small'} style={{minWidth: 32}}><Edit2 size={16} /></ListItemIcon>
          <ListItemText primary={'Edit'} />
        </MenuItem>
        <MenuItem onClick={onDelete} style={{color: 'rgba(255, 63, 86, 1)'}}>
          <ListItemIcon fontSize={'small'} style={{minWidth: 32}}><Trash2 size={16} color={'rgba(255, 63, 86, 0.8)'} /></ListItemIcon>
          <ListItemText primary={'Delete'} />
        </MenuItem>
      </Menu>
  );
}

export default TagMenu
