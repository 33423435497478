import React from 'react'
import { Dialog } from '@material-ui/core'
import WelcomeDiscoveryLayout from '../../layouts/WelcomeDiscovery'

import Discovery from '../../pages/Welcome/Discovery'

import InputsForm from '../../new-components/Forms/InputsForm'

import { credits } from '../../utils/credits'

const WrappedDiscovery = ({ variant, loading, name, country, link, products, onSubmit, onCancel}) => {
  if (loading) return null

  return (
    <>
      <Discovery
        variant={variant}
        name={name}
        country={country}
        link={link}
        products={products}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  )
}

const WrappedKeywords = ({ loading, credits, thinking, keywords, keywordsErrors, onSubmit, onCancel, onChange}) => {
  if (loading) return null

  return (
    <InputsForm
      kind={'keywords'}
      variant={'create'}
      credits={credits}
      thinking={thinking}
      values={keywords}
      errors={keywordsErrors}
      helperText={'Enter one keyword or phrase per line'}
      errorText={'Please enter keyword or phrase'}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onChange={onChange('keywords')}
    />
  )
}

const WrappedCompetitors = ({ loading, credits, thinking, competitors, competitorsErrors, onSubmit, onCancel, onChange}) => {
  if (loading) return null

  return (
    <InputsForm
      kind={'competitors'}
      variant={'create'}
      credits={credits}
      thinking={thinking}
      values={competitors}
      errors={competitorsErrors}
      helpText={'Type one URL per line'}
      placeholder={'e.g. https://example.com'}
      onChange={onChange('competitors')}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}

class ProductOnboarding extends React.Component {
  static defaultProps = {
    step: 'discovery',
    steps: ['discovery', 'keywords', 'competitors'],
    name: '',
    country: 'none',
    competitors: [],
    keywords: []
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: props.loading,
      thinking: false,
      name: props.name,
      country: props.country,
      link: props.link,

      keywords: props.keywords || [],
      keywordsErrors: [],

      competitors: props.competitors || [],
      competitorsErrors: [],

      step: props.step,
      steps: ['discovery', 'keywords', 'competitors'],
      hasChangeCompetitors: props.step === 'competitors'
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.loading && !this.props.loading) {
      this.setState({
        name: this.props.name,
        country: this.props.country,
        link: this.props.link,
        keywords: this.props.keywords,
        competitors: this.props.competitors,
        loading: false
      })
    }
  }

  get nextStep() {
    const {step, steps} = this.state
    const stepIndex = steps.indexOf(step)
    if (stepIndex < steps.length - 1) {
      return steps[stepIndex + 1]
    }
    return null
  }

  get previousStep() {
    const {step, steps} = this.state
    const stepIndex = steps.indexOf(step)
    if (stepIndex === 0) {
      return step
    }
    return steps[stepIndex - 1]
  }

  onPreviousStep = () => {
    this.setState({step: this.previousStep})
  }

  onSubmitProduct = async ({ name, country, link }) => {
    this.setState({ step: this.nextStep, name, country, link })
    return { error: null }
  }

  onChange = kind => (value, errors) => {
    this.setState({[`${kind}Errors`]: errors})
    this.setState({[`${kind}`]: value})
    if(kind === 'competitors') {
      this.setState({ hasChangeCompetitors: false })
    }
  }

  onSetKeywords = async () => {
    const keywords = this.state.keywords

    let keywordsErrors = this.state.keywordsErrors
    if (keywords.length) {
      if (keywords[0].length < 3) {
        keywordsErrors.push(0)
      }
    } else {
      keywordsErrors.push(0)
    }

    this.setState({keywordsErrors})

    if (!keywordsErrors.length) {
      this.setState({ step: this.nextStep })
    }

    return { error: keywordsErrors.length }
  }


  onSetCompetitors = async (run) => {
    const competitors = this.state.competitors

    let competitorsErrors = this.state.competitorsErrors

    if (competitors && competitors.length) {
      if (competitors[0].length < 3) {
        competitorsErrors.push(0)
      }
    } else {
      competitorsErrors.push(0)
    }
    this.setState({competitorsErrors})
    if (!competitorsErrors.length) {
      await this.onSubmit(run === true)
    }
    return {error: competitorsErrors.length}
  }

  onSubmit = async (run) => {
    this.setState({thinking: true})

    const { name, country, link, keywords, competitors } = this.state

    const { error } = await this.props.onSubmit({ name, country, link, keywords, competitors, run })

    if (error) {
      return this.setState({thinking: false})
    }

    return this.setState({thinking: false}, this.props.onClose)
  }

  get credits () {
    const { creditsLeft } = this.props
    const { competitors, keywords } = this.state
    const competitorsCount = competitors.filter(r => !!r && r.length).length
    const keywordsCount =  keywords.filter(r => !!r && r.length).length

    return credits({ competitorsCount, keywordsCount, CREDITS: creditsLeft })
  }

  render() {
    const {
      loading,
      step,
      thinking,
      name,
      country,
      link,
      competitorsErrors,
      keywordsErrors,
      keywords,
      competitors
    } = this.state

    const {
      open,
      products,
      creditsLeft,
      variant
    } = this.props

    return (

      <Dialog
        fullScreen
        fullWidth
        maxWidth={false}
        open={open}
        ariaLabelledby="dialog-title"
        onEscapeKeyDown={this.props.onClose}
      >
        <WelcomeDiscoveryLayout
          thinking={loading}
          create
          role={this.props.role}
          creditsLeft={creditsLeft}
          credits={this.credits}
          inputs={step !== 'discovery'}
          step={step === 'keywords' ? 1 : 2}
          product={step !== 'discovery' && { name, country }}
          onClose={this.props.onClose}
          onAddCredits={this.props.onAddCredits}
        >
          {
            !loading && (
              <>
                {
                  step === 'discovery' && (
                    <WrappedDiscovery
                      variant={variant}
                      name={name}
                      country={country}
                      link={link}
                      products={products}
                      onSubmit={this.onSubmitProduct}
                      onCancel={this.props.onClose}
                    />
                  )
                }
                {
                  step === 'keywords' && (
                    <WrappedKeywords
                      loading={loading}
                      variant={'create'}
                      credits={this.credits}
                      thinking={thinking}
                      keywords={keywords}
                      errors={keywordsErrors}
                      header={'Your Keywords'}
                      subHeader={'Input at least 10 Keywords that customers use when searching for your Product. We\'ll find opportunities you can only dream of.'}
                      onSubmit={this.onSetKeywords}
                      onCancel={this.onPreviousStep}
                      onChange={this.onChange}
                    />
                  )
                }
                {
                  step === 'competitors' && (
                    <WrappedCompetitors
                      loading={loading}
                      variant={'create'}
                      credits={this.credits}
                      thinking={thinking}
                      competitors={competitors}
                      errors={competitorsErrors}
                      onChange={this.onChange}
                      header={'Your Competitors'}
                      subHeader={'Input at least 3 Competitors. We\'ll sneakily use this list to identify their Partners and spot lookalike opportunities. Ssssh!'}
                      onSubmit={this.onSetCompetitors}
                      onCancel={this.onPreviousStep}
                    />
                  )
                }
              </>
            )
          }
        </WelcomeDiscoveryLayout>
      </Dialog>
    )
  }
}

export default ProductOnboarding
