import React, { isValidElement } from 'react'
import styled from 'styled-components'
import {
  FormHelperText,
  FormControl,
  TextField as MuiInput,
  InputLabel
} from '@material-ui/core'

const FormErrorText = styled(FormHelperText)`
  color: #ca392e;
  position: absolute;
  bottom: -18px;
`;

export default class TextField extends React.Component {
  render () {
    const {
      id,
      type,
      required,
      fullWidth,
      margin,
      label,
      inputLabel,
      fieldType,
      autoFocus,
      value,
      variant,
      disabled,
      onChange,
      error
    } = this.props

    return (
      <FormControl
        margin={margin}
        required={required}
        fullWidth={fullWidth}
      >
        {/*<InputLabel htmlFor={id}>{inputLabel}</InputLabel>*/}
        <MuiInput
          id={id}
          multiline
          rows="4"
          rowsMax="4"
          type={type}
          label={label}
          disabled={disabled}
          variant={variant}
          fieldType={fieldType}
          value={value}
          onChange={onChange}
          error={!!error}
          autoFocus={autoFocus}
          inputProps={{
            id: id,
            'aria-describedby': `error-${id}`
          }}
          aria-describedby={`error-${id}`}
        />
        <FormErrorText id={`error-${id}`}>{error}</FormErrorText>
      </FormControl>
    )
  }
}
