import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { find } from 'lodash'
import { OpenInNew, FilterNone as CopyIcon } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import Details from './Details'
import DetailsSection from './DetailsSection'
import { SeedsList } from '../../components/Leads'
import { Top10CountriesByVisitors, Top10CountriesByTraffic } from '../Widgets/AudienceWidget'
import PartnershipsWidget from '../Widgets/PartnershipsWidget'
import KeywordsList from '../../components/Leads/KeywordsList'
import MostRelevantPages from '../../components/Leads/MostRelevantPages'
import HomePage from '../../components/Leads/HomePage'
import AffiliateNetworksDistribution from '../../components/Leads/AffiliateNetworksDistribution'
import { Notes } from '../../components/Notes'
import Contacts from '../Contacts'
import LeadStore from '../../stores/lead'
import LanguagesWidget from '../Widgets/LanguagesWidget'

import copyToClipboard from '../../utils/clipboard'
import styled from 'styled-components'

// 57 Best Vibrators for Women According to Very Happy Customers | Glamour
// (https://www.earnforex.com/forum/threads/fundednext-halloween-offer-200-refund-on-all-funding-plans.47784)
// is ranking for 11 keywords we are targeting (e.g. propfirms ranking 1, the best prop firm ranking 10, list of prop firms ranking 10).
// This page already has links to 3 our competitors including fundednext.com, xxx.site.

const TEXT_PATTERN = `{title}({url}) is ranking for {keywordsCount} keywords we are targeting (e.g. {keywords}). This page already has links to {competitorsCount} our competitors including {competitors}.`

const getRelevantInfoBlock = (info) => {
  let result = []
  const { title, url, keywordsCount, competitorsCount, source } = info

  if (title) {
    result = [...result, title]
  }
  if (url) {
    result = [...result, url]
  }
  if (competitorsCount) {
    result = [...result, `Competitors count ${competitorsCount}`, `Competitors ${info.competitors.join(', ')}` ]
  }
  if (keywordsCount) {
    result = [...result, `Keywords count ${keywordsCount}`, `Top 3 Keywords ${info.keywords.join(', ')}` ]
  }
  if (source) {
    result = [...result, source]
  }
  return result.join('\n')
}

const uglifiedPattern = () => {
  const words = TEXT_PATTERN.split(/\s/)
  return words.map(w => Math.random().toString(36).substring(2,7)).join(' ')
}

const getRelevantInfoTextBlock = (info) => {
  const { title, url, keywordsCount, competitorsCount, source } = info
  if (!keywordsCount || !competitorsCount) return uglifiedPattern()

  return TEXT_PATTERN
    .replace('{title}', title || '')
    .replace('{url}', url)
    .replace('{keywordsCount}', keywordsCount)
    .replace('{keywords}', info.keywords.join(', '))
    .replace('{competitorsCount}', competitorsCount)
    .replace('{competitors}', info.competitors.join(', '))
}

const copyRelevantInfos = async (relevantInfos) => {
  if (relevantInfos && relevantInfos.length) {
    await copyToClipboard(
      relevantInfos.map(info => {
        return `${getRelevantInfoBlock(info)}
        
        ${getRelevantInfoTextBlock(info)}`
      }).join('\n\n\n')
    )
  }
}

const MOST_RELEVANT_HEADING_TEXT = `Most relevant pages`

const HeadingText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
`

const HeadingAction = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const HeadingIcon = styled(CopyIcon)`
  color: ${props => props.theme.palette.secondary.main}
  width: 15px;
  height: 15px;
  flex: 0 0 15px;
  filter: grayscale(100%);
`

const HeadingWrapper = styled.a`
  display: block;
  max-width: 170px;
  padding-right: 25px;
  color: inherit;
  text-decoration: inherit;
  position: relative;
  &:hover ${HeadingText} {
    mask-image: linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,0));
  }
  &:hover ${HeadingAction} {
    opacity: 1;
  }
  &:hover ${HeadingIcon} {
    filter: none;
  }
`

const MostRelevantHeading = ({ infos }) => {
  const [copied, setCopied] = useState(false)

  const onCopy = async (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    await copyRelevantInfos(infos)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  if (!infos || !infos.length) return MOST_RELEVANT_HEADING_TEXT

  return <HeadingWrapper onClick={onCopy}>
    <HeadingText>{MOST_RELEVANT_HEADING_TEXT}</HeadingText>
    <Tooltip title={copied ? 'Copied to clipboard' : `Copy relevant infos`}>
      <HeadingAction><HeadingIcon /></HeadingAction>
    </Tooltip>
  </HeadingWrapper>
}

@observer
export default class leadDetails extends React.Component {
  static defaultProps = {
    selected: [],
    details: {}
  }

  constructor (props) {
    super(props)
    this.store = new LeadStore()
    this.state = { tabId: 'info' }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { leadId, channelId, kind, open } = this.props
    if (prevProps.leadId !== leadId && !this.props.fake && leadId) {
      if (open === true && prevProps.open === false) {
        this.setState({ tabId: kind === 'notes' ? 'notes' : 'info' })
      }
      this.store.load(leadId, channelId).then()
    }
  }

  onClose = () => {
    this.setState({ kind: null, competitorSeeds: null, keywordSeeds: null, hasSeedTab: false, relevantUrl: null })
    this.props.close()
  }

  onTabSelect = tabId => {
    this.setState({ tabId })
  }

  onUpdateRequestContactStatus = () => {
    this.store.updateContactRequestStatus()
  }

  onRequest = async () => {
    const { channelId, leadId } = this.props
    this.props.onRequestContacts(leadId, channelId)
  }

  render () {
    const {
      loadingView,
      loadingChannel,
      details: {
        root,
        relevant,
        targetWebsite,
        country,
        countries,
        keywordsByRelevant,
        competitorsByRelevant,
        contactRequestStatus,
        keywords, competitors
      },
      languages,
      contacts,
      affiliatesLinks,
      affiliateNetworksDistribution,
      campaignsAmount,
      relevantCopy
    } = this.store
    const {
      partner,
      prospect,
    } = this.props
    const { tabId } = this.state

    const quickActionProps = {
      id: this.props.leadId,
      view: 'leads',
      kind: this.props.source,
      partner,
      prospect,
      contactStatus: contactRequestStatus,
      onAction: this.props.onQuickAction
    }

    return (
      <Details
        hide={this.props.fake}
        hideMessage={'No data is available yet.'}
        tabId={tabId}
        {...this.props}
        close={this.onClose}
        onTabSelect={this.onTabSelect}
        quickActionProps={quickActionProps}
      >
        {
          tabId === 'info' && (
            <>
              <DetailsSection loading={loadingChannel} expanded heading={'Lead summary'}>
                <HomePage
                  root={root}
                  action={()=>{}}
                  itemTags={this.props.itemTags}
                  onRemoveTag={(tagId) => this.props.onRemoveTag(tagId, this.props.leadId)}
                  onUpdateTag={this.props.onUpdateTag}
                  onDeleteTag={this.props.onDeleteTag}
                />
              </DetailsSection>

              <DetailsSection loading={loadingView} expanded heading={<MostRelevantHeading infos={relevantCopy}/>} count={relevant.length}>
                <MostRelevantPages fieldAction={()=>{}} pages={relevant} />
              </DetailsSection>

              <DetailsSection loading={loadingView} expanded={keywords.length > 0} heading={'Keywords'} count={keywords.length}>
                <KeywordsList
                  country={country}
                  list={keywords}
                  listByUrl={keywordsByRelevant}
                  message='No keywords matching the input seeds'
                />
              </DetailsSection>
              <DetailsSection loading={loadingView} expanded={competitors.length > 0} heading={'Competitors'} count={competitors.length}>
                <SeedsList
                  list={competitors}
                  listByUrl={competitorsByRelevant}
                  message='No competitors matching the input seeds'
                />
              </DetailsSection>
            </>
          )
        }

        {
          tabId === 'audience' && (
            <>
              <DetailsSection loading={loadingChannel} expanded heading={'Top 10 countries by monthly traffic'}>
                <Top10CountriesByTraffic data={countries}/>
              </DetailsSection>
              <DetailsSection loading={loadingChannel} expanded heading={'Top 10 countries by monthly visitors & rank'}>
                <Top10CountriesByVisitors data={countries}/>
              </DetailsSection>
              <DetailsSection loading={loadingChannel} expanded heading={'Languages'}>
                <LanguagesWidget data={languages}/>
              </DetailsSection>
            </>
          )
        }

        {
          tabId === 'partnerships' && (
            <>
              <DetailsSection loading={loadingChannel}
                expanded={affiliateNetworksDistribution.length > 0}
                heading={'Affiliate Networks Distribution'}
                count={affiliateNetworksDistribution.length}>
                <AffiliateNetworksDistribution data={affiliateNetworksDistribution} />
              </DetailsSection>

              <DetailsSection loading={loadingChannel}
                expanded={affiliatesLinks.length > 0}
                heading={'Partnerships'}
                count={campaignsAmount}>
                <PartnershipsWidget
                  targetWebsite={targetWebsite}
                  targetIcon={root.icon}
                  data={affiliatesLinks}
                />
              </DetailsSection>

            </>
          )
        }

        {
          tabId === 'contacts'  && (
            <>
              <DetailsSection loading={loadingView} noCollapse noHeader>
                <Contacts
                  status={contactRequestStatus}
                  contacts={contacts}
                  onRequest={this.onRequest}
                />
              </DetailsSection>
            </>
          )
        }

        {
          tabId === 'notes' && (
            <Notes
              ownerName={root.url}
              id={this.props.leadId}
              onUpdateNotesCount={this.props.onUpdateNotesCount}/>
          )
        }
      </Details>
    )
  }
}
