import React from 'react'
import { inject, observer } from 'mobx-react'
import Notes from './Notes'
import NotesStore from '../../stores/notes'

@inject(({ BrandStore }) => ({ brandId: BrandStore.id }))
@observer
class _Notes extends React.Component {
  constructor (props) {
    super(props)
    const { id, kind, brandId } = props
    this.store = new NotesStore(id, kind, brandId)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.store.load(this.props.id).then()
    }
  }

  onCreate = details => this.store.create(details)
  onUpdate = details => this.store.update(details)
  onRemove = id => this.store.remove(id)

  render () {
    const { id } = this.props
    const {
      notes,
      thinking
    } = this.store

    return <Notes
      id={id}
      notes={notes}
      thinking={thinking}
      onCreate={this.onCreate}
      onUpdate={this.onUpdate}
      onRemove={this.onRemove}
      {...this.props}
    />
  }
}

export default _Notes
