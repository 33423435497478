import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import check from '../../components/Icons/Check.svg'
import {CalloutArrow as ArrowImage} from '../../components/Icons/Callout-arrow'
import {Flame} from '../../components/Icons/Flame'
import config from '../../config'
import {CircularProgress, Button as MuiButton} from "@material-ui/core";

const { pricing, credits, trialPeriod } = config

export const Root = styled.div`
  //text-align: center;
  display: flex;
  align-items: center;
  text-align: left;
  flex-direction: column;
  h3 {
    font-size: 24px;
    color: #000021;
  }
  h1 {
    text-align: center;
    max-width: 28ch;
    margin: 0 auto;    
    font-family: "GT Super Display", serif;
    font-size: 3em;
    strong {
      text-decoration: underline;
    }
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #000021;
    margin: 16px 0 0 0;
  }
`;

export const CheckedList = styled.ul`
  list-style: none;
  padding: ${props => props.disablePadding ? '0' : '1em 0' };
  margin: ${props => props.disablePadding && '0' };
  
  li {
    margin-left: 2em;
    line-height: 1.2;
    padding-bottom: 1em;
    position: relative;
    font-weight: ${props => props.bold ? 'bold' : 'regular'};
  }
  li:before {
    content: "";
    width: 1.2em;
    height: 1.2em;
    position: absolute;
    left: -2em;
    top: -0.1em;

    background: url(${check}) no-repeat center;
    background-size: contain;
  }
`

export const Option = styled.label`
  position: relative;
  border-radius: 5px;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding ? props.padding : '2em 3em 6em'};
  //align-items: center;
  justify-content: space-between;
  background: ${props => props.active ? '#E9F3FE' : 'white'};
`
export const OptionsContainer = styled.div`
  margin: 1em auto 0;
  background: white;
  border-radius: 1em;
  padding: ${props => props.padding ? props.padding : '2em'};
  width: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : '100ch'};
`
// grid grid-flow-row auto-rows-max lg:grid-cols-2 lg:grid-rows-1 gap-4
export const Options = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 1em;
`
export const OptionsAction = styled.div`
  margin: 6em 0 0;
  text-align: center;
`
export const RadioGroup = styled.div`
  margin: 1em 0;
  display: flex;
  align-items: center;
`
export const OptionHeading = styled.h2`
  flex: 1 0 auto;
  margin: 0;
  font-size: 2em;
`
export const RadioButton = styled.input`
  flex: 0;
  margin-right: 1.2em;
`
export const SmallText = styled.div`
  margin-top: 1em;
`
export const Button = styled(MuiButton)`
  border-radius: 12.4789px;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  padding: 0.6em 2em;
  border: none;
  max-width: 60ch;
  margin: 0 auto;
  cursor: pointer;
  &
  ${props => props.disabled && css`
    cursor: not-allowed;
    `
  }
`
export const PriceContainer = styled.div`
  position: relative;
`
export const Price = styled.div``
export const PriceLabel = styled.strong`
font-weight: bold;
`
export const PriceValue = styled.strong`
font-size: 3em;
`
export const PricePeriod = styled.small`
font-size: 1em;
`
export const OptionsFooter = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
`
export const PeriodSwitcher = styled.div`
  //grid grid-cols-2 rounded-md overflow-hidden mt-4 mx-auto w-full
  display: grid;
  grid-template-columns: 2fr 2fr;
  border-radius: 5px;
  overflow: hidden;
  margin: 1.5em auto 0;
  width: 100%;
  max-width: 30ch;
`
export const PeriodOption = styled.div`
  background: ${props => props.active ? 'blue' : props.inverted ? 'white' : '#E9F3FE'};
  color: ${props => props.active ? 'white' : 'black'};
  padding: 0.5em 1em;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
`
export const Features = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 3em;
  margin: 0 2em;
`
export const FeaturesColumn = styled.div`
text-align: left;
`

export const BestOptionRoot = styled.div`
  display: flex;
  color: #FF3F56;
  fill: #FF3F56;
  background: white;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  position: absolute;
  right: 0;
  top: ${props => props.offset ? props.offset : '3.3em'};
`
export const BestOptionBody = styled.div`
  font-weight: bold;
  margin-left: 0.8em;
`

export const BestOption = ({offset}) =>
  <BestOptionRoot offset={offset}>
    <Flame />
    <BestOptionBody>Most popular</BestOptionBody>
  </BestOptionRoot>


export const CalloutArrow = styled(ArrowImage)`
  width: 4em;
  height: 4em;
  fill: currentColor;
  transform-origin: center;
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 0;
`
export const CalloutBody = styled.div`
  color: currentColor;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  transform-origin: center;
  margin-top: 0.2em;
  transform: rotate(2deg);
`
export const CalloutRoot = styled.div`
  display: inline-block;
  position: relative;
  transform-origin: center;
  padding-top: 4em;
  max-width: 25ch;
  color: #0163FA;
  left: 20%;
  @media (max-width: 940px) {
    display: none!important;
  }
`

export const Callout = ({children, style}) =>
  <CalloutRoot style={style}>
    <CalloutArrow />
    <CalloutBody>{children}</CalloutBody>
  </CalloutRoot>

export const Content = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const WelcomePlans = (props) => {
  const styles = {}
  const { variant, trial, thinking, disabled, header, title, billingStatus, noSubTitle } = props
  const [option, setOption] = useState(props.option || 'self');
  const [period, setPeriod] = useState(props.period ? `${props.period}ly` : 'monthly');

  function handleOptionChange({ target: { value } }) {
    setOption(value);
  }
  function handlePeriodChange({ target: { value } }) {
    setPeriod(value);
  }

  function onSelect (e) {
    e.preventDefault()
    props.onSelectPlan(option, period)
  }

  function buttonText () {
    if (variant === 'onboarding') {
      return option === 'self' ? 'Try it for Free' : 'Book a demo call'
    }

    const forPlan = `£${period === 'monthly' ? pricing.selfMonthly : pricing.selfQuarterly}/${period === 'monthly' ? 'month' : 'quarter'}`

    if (props.option === option &&  `${props.period}ly` === period) {
      return `${billingStatus === 'inTrial' ? 'End Trial. ' : ''}Activate for ${forPlan}`
    }

    return option === 'self' ? `Subscribe for ${forPlan}`
      : 'Book a demo call'
  }

  function showButton () {
    return !(billingStatus === 'active' && props.option === option && `${props.period}ly` === period)
  }

  return (
    <Root variant={variant}>
      <Content>
        {title ? <h1>{title}</h1> : <h1>Begin your <strong>{trialPeriod} Day Trial</strong> <br/>with {credits.trial} free Breezy credits</h1>}
        { !noSubTitle && <h2 style={{marginTop: '1em'}}>Select your plan</h2> }
        <OptionsContainer>
          <Options>
            <Option active={option === 'self'}>
              <div>
                <BestOption />
                <RadioGroup>
                  <RadioButton
                    checked={option === 'self'}
                    type={'radio'}
                    name={'option'}
                    value={'self'}
                    onChange={handleOptionChange}
                  />
                  <OptionHeading>Self-serve</OptionHeading>
                </RadioGroup>
                <p>You want to do search yourself.</p>
                <p>Tailor researches and organise leads into prospects to grow your ultimate partner base.</p>
                <br/><br/>
                <CheckedList bold>
                  <li>{period === 'monthly' ? `${credits.monthly} credits (${credits.trial} in free trial)`
                    : `${credits.quarterly} credits (${credits.trial} in free trial)`}
                  </li>
                  <li>Top-up credits as needed</li>
                </CheckedList>
              </div>
              <PriceContainer>
                <PriceLabel>Try {trialPeriod} days for £0,<br/>then begin your paid subscription for</PriceLabel>
                {period === 'monthly'
                  ? <Price aria-label={`£${pricing.selfMonthly} per month`}><PriceValue>£{pricing.selfMonthly}</PriceValue><PricePeriod>/month</PricePeriod></Price>
                  : <Price aria-label={`£${pricing.selfQuarterly} per month`}><PriceValue>£{pricing.selfQuarterly}</PriceValue><PricePeriod>/quarter</PricePeriod></Price>
                }

                <OptionsFooter>
                  <PeriodSwitcher onChange={handlePeriodChange}>
                    <label>
                      <input hidden aria-hidden type="radio" name="period" id="period-monthly" value="monthly" />
                      <PeriodOption active={period === 'monthly'} inverted={option === 'self'}>Monthly</PeriodOption>
                    </label>
                    <label>
                      <input hidden aria-hidden type="radio" name="period" id="period-quarterly" value="quarterly"/>
                      <PeriodOption active={period === 'quarterly'} inverted={option === 'self'}>Quarterly</PeriodOption>
                    </label>
                  </PeriodSwitcher>
                  <Callout className="absolute mt-2">
                    Save over {pricing.sale}% on quarterly Self-serve subscription.
                  </Callout>
                </OptionsFooter>

              </PriceContainer>
            </Option>
            <Option active={option === 'assisted'}>
              <div>
                <RadioGroup>
                  <RadioButton
                    checked={option === 'assisted'}
                    type={'radio'}
                    name={'option'}
                    value={'assisted'}
                    onChange={handleOptionChange}
                  />
                  <OptionHeading>Assisted</OptionHeading>
                </RadioGroup>
                <p>You want our partnership experts to assist your search.</p>
                <p>Let our team of experts do the heavy lifting for you. Get thousands of qualified Leads and automated Prospecting.</p>
                <CheckedList bold>
                  <li>Assisted Search</li>
                  <li>Search Success Manager</li>
                  <li>Priced by volume of validated prospects</li>
                </CheckedList>
              </div>
              <PriceContainer>
                <PriceLabel>Starts at</PriceLabel>
                <Price aria-label={`£${pricing.assisted} per quarter`}><PriceValue>£{pricing.assisted}</PriceValue><PricePeriod className={"text-3"}>/quarter</PricePeriod></Price>
              </PriceContainer>
            </Option>
          </Options>
          <OptionsAction>
            {
              showButton() && (
                <Button
                  startIcon={thinking && <CircularProgress size={'1em'} color={'inherit'}/>}
                  disabled={thinking || disabled}
                  href={'#'}
                  color={'primary'}
                  variant={'contained'}
                  disableElevation
                  onClick={onSelect}>
                  {buttonText()}
                </Button>
              )
            }

            { variant === 'onboarding' && <SmallText>No credit card required. {option !== 'self' && 'Free Self-serve trial included.'}</SmallText> }
            <br/><br/>
            {header}
          </OptionsAction>
        </OptionsContainer>

        {
          variant === 'onboarding' && (
            <Features style={{maxWidth: '120ch'}}>
              <FeaturesColumn>
                <h3>Breezy Self-serve</h3>
                <CheckedList>
                  <li>Credit-based Search tailored to your budget</li>
                  <li>Sort, filter & categorise Leads into Prospects</li>
                  <li>Size, Audience & overlap data on every Lead</li>
                  <li>Find affiliate, content marketing & PR Prospects</li>
                  <li>Find relevant Brands to partner with</li>
                  <li>Uncover a Lead’s current partners</li>
                  <li>Analyse competitors’ existing partnerships</li>
                  <li>Target partners by geography</li>
                  <li>View top pages visited by your target market</li>
                  <li>Prospect contact information (£{pricing.perContact} per contact)</li>
                  <li>Access to live chat email support</li>
                </CheckedList>
              </FeaturesColumn>
              <FeaturesColumn>
                <h3>Breezy Assisted</h3>
                <h4>Search</h4>
                <CheckedList>
                  <li>Dedicated Search Success Manager</li>
                  <li>Advanced searches tailored to your needs</li>
                  <li>Bespoke search dashboard built for you</li>
                  <li>Prospect identified to meet requested volume targets</li>
                  <li>Discover top players & rising stars</li>
                </CheckedList>
                <h4>Outreach</h4>
                <CheckedList>
                  <li>Contact information provided for Prospects</li>
                  <li>Email Setup</li>
                  <li>Sequence Creation</li>
                  <li>Outreach automation & optimisation</li>
                </CheckedList>

              </FeaturesColumn>

            </Features>
          )
        }
        </Content>

    </Root>
  )
}

export default WelcomePlans
