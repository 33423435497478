import { gql } from'@apollo/client'
import GqlClient from '../gql/gql-client'

const CREATE_EVENT = gql`
  mutation CreateEvent ($CreateEventInput: CreateEventInput!) {
    createEvent (input: $CreateEventInput) {
      id
    }
  }
`

export const sendEvent = (brandId, kind, data) => GqlClient.mutation({ mutation: CREATE_EVENT, variables: { CreateEventInput: { brandId, kind } } })

