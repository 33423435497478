import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Inbox, ThumbsUp, ThumbsDown } from 'react-feather'

import CreatorSettings from '../../components/Settings/_CreatorSettings'
import Menu from '../../components/Brand/Menu'
import Layout from '../../new-components/Layout/Main'
import LeadsPlaceholder from '../../components/LeadsPlaceholder'


import { InboxRequests, AcceptedRequests, RejectedRequests } from './_Requests'

import Config from '../../config'

interface CreatorProps extends RouteComponentProps {
    loading: Boolean
    counts: {
        inbox: number
        accepted: number
        rejected: number
    }
    hasChannel?: Boolean
    userName?: string
    onLoad: Function
}

interface CreatorState {}


function messageSales () {
    //@ts-ignore
    return window.Intercom('showNewMessage', Config.CREATOR_EMPTY_CHANNEL_MESSAGE)
}


const Heading = styled.div`
  display: flex;
  gap: 2em;
  //justify-content: center;
  align-items: center;
  font-size: 1.3em;
  width: 100%;
  padding: 0 24px;
  & p {
    opacity: 0.8;
  }
`

const CreatorMenu = inject(({ UserStore }) => ({
    firstName: UserStore.user.firstName,
    fullName: UserStore.user.fullName,
    email: UserStore.user.email,
    onLogout: () => UserStore.signout()
}))(observer(({ loading, email, details, brands, firstName, fullName, onLogout,...rest }) => {
    return <Menu
        role={'creator'}
        loading={loading}
        firstName={firstName}
        fullName={fullName}
        brands={brands}
        details={details}
        email={email}
        onLogout={onLogout}
        {...rest}
    />
}))

@inject(({ UserStore, CreatorStore }) => ({
    userName: UserStore.fullName,

    loading: CreatorStore.loading,
    counts: CreatorStore.counts,
    hasChannel: CreatorStore.hasChannel,
    onLoad: () => CreatorStore.load()
}))
@observer
@(withRouter as any)
class Creator extends React.Component<CreatorProps, CreatorState> {
    state = { settings: false }

    componentDidMount() {
        this.props.onLoad().then()
    }

    onOpenSettings = () => {
        this.setState({ settings: true })
    }

    get navigation () {
        const { match: { url: path }, counts } = this.props

        return [
            {
                id: 'inbox',
                kind: 'inbox',
                titleText: 'Inbox',
                title: 'Inbox',
                icon: Inbox,
                path: `${path}/inbox`,
                amount: counts.inbox,
            },
            {
                id: 'accepted',
                kind: 'accepted',
                titleText: 'Accepted',
                title: 'Accepted',
                icon: ThumbsUp,
                path: `${path}/accepted`,
                amount: counts.accepted,
            },
            {
                id: 'rejected',
                kind: 'rejected',
                titleText: 'Rejected',
                title: 'Rejected',
                icon: ThumbsDown,
                path: `${path}/rejected`,
                amount: counts.rejected,
            },
        ]
    }

    render(): React.ReactNode {
        const {
            match: { path },
            location,
            loading,
            hasChannel
        } = this.props

        const appBarProps = {
            role: 'creator',
            // title: <Heading>
            //     <h1>Inbox</h1>
            //     <p>By accepting these partnership invitations you will share your contact information with relevant brands.</p>
            // </Heading>,
            onSettings: this.onOpenSettings,
        }

        return <Layout
            loading={loading}
            menu={CreatorMenu}
            navigation={this.navigation}
            appBarProps={appBarProps}
        >
            <LeadsPlaceholder
                thinking={loading}
                state={hasChannel ? null : 'noChannel'}
                name={this.props.userName}
                onContactAccountManager={() => {
                    console.log('INTERCOM')
                    messageSales()
                }}
            >
                <Switch location={location}>
                    <Route exact path={`${path}/inbox`}>
                        <InboxRequests />
                    </Route>
                    <Route exact path={`${path}/accepted`}>
                        <AcceptedRequests />
                    </Route>
                    <Route exact path={`${path}/rejected`}>
                        <RejectedRequests />
                    </Route>
                </Switch>
            </LeadsPlaceholder>

            <CreatorSettings open={this.state.settings} onClose={() => this.setState({ settings: false })}/>
        </Layout>
    }
}

export default Creator


