import React, { useEffect, useState } from 'react'
import numbro from 'numbro'
import clsx from 'clsx'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'
import DataGridPro, { renderTitle, ColumnTooltipLogo, MozLogo, SwIcon } from './EcosystemTable'
import { SimilarWebIcon } from '../../components/Icons/SimilarWebIcon'
import { SimilarWeb } from '../../components/Icons/SimilarWeb'

import { formatToReadableNumber } from '../../utils/format'
import metrics from '../../configMetrics'
import {Base as TooltipMetricsBase} from '../../components-ts/TooltipMetrics/TooltipMetricsBase'


const Root = styled('div')(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 23,
  borderRadius: 20,
  backgroundColor: 'rgba(1,99,250,0.13)',
}))

const Bar = styled('div')({
  height: '100%',
  '&.low': {
    backgroundColor: 'rgba(194,59,204,0.35)',
  },
  '&.medium': {
    backgroundColor: 'rgba(117,59,204,0.35)',
  },
  '&.high': {
    backgroundColor: 'rgba(1,99,250,0.35)',
  },
})

const Value = styled('div')({
  position: 'absolute',
  lineHeight: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

const ProgressBar = React.memo(function ProgressBar(props) {
  const { value, total } = props;
  const valueInPercent = numbro(value).format({mantissa: 2, average: true});

  return (
    <Root>
      <Value>{value}/{total}</Value>
      <Bar
        className={clsx({
          low: valueInPercent < 30,
          medium: valueInPercent >= 30 && valueInPercent <= 70,
          high: valueInPercent > 70,
        })}
        style={{ maxWidth: `${valueInPercent}%` }}
      />
    </Root>
  );
});

export function renderProgress(params) {
  if (params.value == null) {
    return '';
  }

  if (params.aggregation && !params.aggregation.hasCellUnit) {
    return null;
  }

  const { value, row: { totalBrands }} = params

  return <ProgressBar value={value} total={totalBrands} />
}

const Heading = styled('div')({
  display: 'flex',
  alignItems: 'center'
})


const columns = [
  {
    field: 'domain', headerName: 'Partner', flex: 1, sortable: false,
    renderCell: renderTitle,
    minWidth: 300
  },
  {
    field: 'mozDomainAuthority',
    headerName: <Heading>{metrics['moz'].heading}</Heading>,
    description: <TooltipMetricsBase title={metrics['moz'].title} content={metrics['moz'].content} poweredBy={metrics['moz'].poweredBy}/>,
    width: 70
  },
  {
    field: 'swVisits',
    headerName: <Heading>{metrics['visits'].heading}</Heading>,
    description: <TooltipMetricsBase title={metrics['visits'].title} content={metrics['visits'].content} poweredBy={metrics['visits'].poweredBy}/>,
    width:90,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (params) => formatToReadableNumber(params.value)
  },
  {
    field: 'partnershipsOutCount',
    headerName: metrics['promotes'].heading,
    description: <TooltipMetricsBase title={metrics['promotes'].title} content={metrics['promotes'].content} />,
    width: 95,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }

      return formatToReadableNumber(params.value)
    }
  },
  {
    field: 'overlap',
    headerName: 'Overlap',
    minWidth: 200,
    renderCell: renderProgress
  },
]

const EcosystemOverlap = ({overlap, error, onSort, onPageChange, ...props}) => {
  const apiRef = useGridApiRef()

  return (
      <DataGridPro
        apiRef={apiRef}
        sortingMode="server"
        // hideFooter
        columnBuffer={30}
        scrollEndThreshold={50}
        error={error}
        loading={props.loading}
        rows={overlap}
        columns={columns}
        // pinnedColumns={{left: ['domain']}}
        // paginationMode="server"
        onRowClick={console.log}
        onRowsScrollEnd={onPageChange}
        onSortModelChange={onSort}
      />
  )

}

export default EcosystemOverlap
