import React from 'react'
import { inject, observer } from 'mobx-react'
import Onboarding from './Onboarding'

@inject(({ PartnersStore, SearchesStore, BrandStore, UserStore }) => ({
  products: SearchesStore.products,
  onLoadProducts: () => SearchesStore.load(),
  onCreateChannel: (details) => SearchesStore.createChannel(details),
  onUpdateProduct: (details) => SearchesStore.updateProduct(details),

  brandDetails: BrandStore.details,
  onCreateBrand: details => BrandStore.create(details),

  onImportPartners: (details) => PartnersStore.importPartners(details)
}))
@observer
class _Onboarding extends React.Component {
  constructor (props) {
    super(props)
  }

  onCreateBrand = async (details) => {
    const { success } = await this.props.onCreateBrand(details)
    return { success }
  }

  onCreateChannel = async (details) => {
    const { id: brand } = this.props.brandDetails
    const { channel, id: searchId } = this.product
    return await this.props.onCreateChannel({ brand, channel, searchId, ...details })
  }

  onUpdateProduct = async (details) => {
    const { id } = this.product
    return await this.props.onUpdateProduct({ id, ...details, create: true })
  }

  get product () {
    const { products } = this.props
    const product = products[0] || {}
    const { id, name, channel, channelLink, competitorUrls, keywords } = product
    return { id, name, channel, link: channelLink, competitors: competitorUrls, keywords }
  }

  render () {
    const {
      open,
      step,
      brandDetails,
      onClose
    } = this.props

    return  <Onboarding
      open={open}
      step={step}
      company={brandDetails}
      {...this.product}
      onCreateCompany={this.onCreateBrand}
      onCreateChannel={this.onCreateChannel}
      onImportPartners={this.props.onImportPartners}
      onUpdate={this.onUpdateProduct}
      onClose={onClose}
    />
  }
}

export default _Onboarding
