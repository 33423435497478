const checkStatus = async (response) => {
  let json = {}
  try {
    json = await response.json()
  } catch (e) {}

  return new Promise(resolve => { resolve({ json, response }) }, reject => { reject(response) })
}

const parseJSON = ({ json, response }) => response.ok === false ? { errorCode: response.status, ...json } : json

export const Get = url =>
  fetch(url, {
    credentials: 'include'
  })
    .then(checkStatus)
    .then(parseJSON)

export const Put = (url, data) =>
  fetch(url,
    {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(data || {}),
      credentials: 'include'
    })
    .then(checkStatus)
    .then(parseJSON)

export const Post = (url, data) =>
  fetch(url,
    {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(data || {}),
      credentials: 'include'
    })
    .then(checkStatus)
    .then(parseJSON)

export const Delete = (url, data) =>
  fetch(url,
    {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'DELETE',
      body: JSON.stringify(data || {}),
      credentials: 'include'
    })
    .then(checkStatus)
    .then(parseJSON)
