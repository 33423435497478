import React from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  Tooltip,
  IconButton,
  unstable_composeClasses as composeClasses,
} from '@mui/material'
import clsx from 'clsx'
import numbro from 'numbro'
import DataGridPro, { HeadTitle, renderTitle } from './EcosystemTable'
import {
  getDataGridUtilityClass,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-premium'

import { formatToReadableNumber } from '../../utils/format'
import styledComponent from 'styled-components'

import { Base as TooltipMetricsBase } from '../../components-ts/TooltipMetrics/TooltipMetricsBase'
import metrics from '../../configMetrics'

const Root = styled('div')(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 23,
  borderRadius: 20,
  backgroundColor: 'rgba(1,99,250,0.13)',
}))

const Bar = styled('div')({
  height: '100%',
  '&.low': {
    backgroundColor: 'rgba(194,59,204,0.35)',
  },
  '&.medium': {
    backgroundColor: 'rgba(117,59,204,0.35)',
  },
  '&.high': {
    backgroundColor: 'rgba(1,99,250,0.35)',
  },
})

const Value = styled('div')({
  position: 'absolute',
  lineHeight: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

const GroupTitle = styled('div')({
  display: 'inline-block',
  color: 'black',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  marginRight: '0.5em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const ProgressBar = React.memo(function ProgressBar(props) {
  const { value } = props
  const valueInPercent = numbro(value).format({ mantissa: 1, average: true })

  return (
    <Root>
      <Value>{valueInPercent}%</Value>
      <Bar
        className={clsx({
          low: valueInPercent < 30,
          medium: valueInPercent >= 30 && valueInPercent <= 70,
          high: valueInPercent > 70,
        })}
        style={{ maxWidth: `${valueInPercent}%` }}
      />
    </Root>
  )
})

const Heading = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export function renderProgress(params) {
  if (params.value == null) {
    return ''
  }

  if (params.aggregation && !params.aggregation.hasCellUnit) {
    return null
  }
  return <ProgressBar value={params.value} />
}

const columns = [
  {
    field: 'mozDomainAuthority',
    align: 'right',
    headerAlign: 'right',
    headerName: <Heading>{metrics['moz'].heading}</Heading>,
    description: (
      <TooltipMetricsBase
        title={metrics['moz'].title}
        content={metrics['moz'].content}
        poweredBy={metrics['moz'].poweredBy}
      />
    ),
    width: 70,
    minWidth: 70,
    valueFormatter: (params) => {
      if (params.value == null) {
        return ''
      }
      return formatToReadableNumber(params.value, 'integer')
    },
  },
  {
    field: 'swGlobalRank',
    headerName: <Heading>{metrics['rank'].heading}</Heading>,
    description: (
      <TooltipMetricsBase
        title={metrics['rank'].title}
        content={metrics['rank'].content}
        poweredBy={metrics['rank'].poweredBy}
      />
    ),
    width: 82,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (params) => {
      if (params.value == null) {
        return ''
      }

      return formatToReadableNumber(params.value)
    },
  },
  {
    field: 'swVisits',
    headerName: <Heading>{metrics['visits'].heading}</Heading>,
    description: (
      <TooltipMetricsBase
        title={metrics['visits'].title}
        content={metrics['visits'].content}
        poweredBy={metrics['visits'].poweredBy}
      />
    ),
    width: 90,
    minWidth: 90,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (params) => {
      if (params.value == null) {
        return ''
      }
      return formatToReadableNumber(params.value, 'integer')
    },
  },
  {
    field: 'count',
    headerName: metrics['promotedBy'].heading,
    description: (
      <TooltipMetricsBase
        title={metrics['promotedBy'].title}
        content={metrics['promotedBy'].content}
      />
    ),
    width: 120,
    minWidth: 120,
    valueFormatter: (params) => formatToReadableNumber(params.value),
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'partners',
    headerName: metrics['ecosystemShare'].heading,
    description: (
      <TooltipMetricsBase
        title={metrics['ecosystemShare'].title}
        content={metrics['ecosystemShare'].content}
      />
    ),
    flex: 1,
    align: 'center',
    renderCell: renderProgress,
  },
]

const TITLE_CELL = {
  field: 'domain',
  headerName: 'Brands',
  flex: 2,
  sortable: false,
  minWidth: '300',
  renderCell: renderTitle,
}

const EcosystemLandscape = ({
  ecosystem,
  data,
  loading,
  error,
  onSort,
  onPageChange,
}) => {
  const { affiliatePartnersCount } = ecosystem

  const rows = (data || []).map(
    ({
      id,
      domain,
      partnershipsInCount,
      title,
      icon,
      mozDomainAuthority,
      swGlobalRank,
      swVisits,
      channelId,
    }) => ({
      id,
      domain,
      title,
      icon,
      channelId,
      mozDomainAuthority,
      swGlobalRank,
      swVisits,
      partners: partnershipsInCount
        ? (partnershipsInCount * 100) / affiliatePartnersCount
        : 0,
      count: partnershipsInCount,
      share: partnershipsInCount
        ? (partnershipsInCount * 100) / affiliatePartnersCount
        : 0,
    })
  )

  return (
    <DataGridPro
      loading={loading}
      error={error}
      hideFooter
      columns={[TITLE_CELL, ...columns]}
      rows={rows}
      onRowsScrollEnd={onPageChange}
      onSortModelChange={onSort}
    />
  )
}

const getTreeDataPath = (row) => row.group

const useUtilityClasses = (ownerState) => {
  const { classes } = ownerState

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  }

  return composeClasses(slots, getDataGridUtilityClass, classes)
}

const GroupingCell = (props) => {
  const { id, field, rowNode, row, formattedValue } = props

  if (!row.root && !row.children) {
    return renderTitle(props)
  }

  const rootProps = useGridRootProps()
  const apiRef = useGridApiContext()
  const classes = useUtilityClasses({ classes: rootProps.classes })

  const Icon = rowNode.childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon

  const onClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box className={classes.root}>
      {!row.children && (
        <div
          className={classes.toggle}
          style={{ flex: '0 0 18px', marginLeft: 8, marginRight: 2 }}
        >
          <IconButton
            size='small'
            onClick={onClick}
            tabIndex={-1}
            aria-label={
              rowNode.childrenExpanded
                ? apiRef.current.getLocaleText('treeDataCollapse')
                : apiRef.current.getLocaleText('treeDataExpand')
            }
          >
            <Icon fontSize='inherit' />
          </IconButton>
        </div>
      )}

      {row.children && renderTitle(props)}

      {row.root && (
        <Tooltip
          title={
            formattedValue === undefined ? rowNode.groupingKey : formattedValue
          }
        >
          <GroupTitle>
            {formattedValue === undefined
              ? rowNode.groupingKey
              : formattedValue}
          </GroupTitle>
        </Tooltip>
      )}
    </Box>
  )
}

const RootHeader = styledComponent.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  margin: 0 1em 0 0;
  display: flex;
  align-items: center; 
`

const RootHeaderResult = styledComponent.div`
  a { 
    display: inline-block;
    text-decoration: none;
    font-weight: normal;
  }
`

const Header = ({ total }) => {
  return (
    <div style={{ display: 'flex' }}>
      <RootHeader>
        <RootHeaderResult>
          <strong>{formatToReadableNumber(total)} results</strong>
        </RootHeaderResult>
      </RootHeader>
    </div>
  )
}

const CUSTOM_GROUPING_COL_DEF = (total) => ({
  field: 'domain',
  headerName: 'Brands',
  flex: 2,
  sortable: false,
  minWidth: '300',
  renderHeader: () => <HeadTitle />,
  renderCell: (params) => <GroupingCell {...params} />,
})

const getRows = (data, path, partnersCount, untapped) => {
  const isGroup = Boolean(path)
  let countSum = 0
  let swVisitsSum = 0
  const isSingle = data.length === 1 && isGroup

  const rows = (data || []).map((row) => {
    const {
      id,
      domain,
      partnershipsInCount,
      title,
      icon,
      kind,
      mozDomainAuthority,
      swGlobalRank,
      swVisits,
      ecosystemId,
      onAddToRelevant,
      contactStatus,
      contactsFoundCount,
      type1Classification,
      tags,
      systemTags,
      channelId,
    } = row

    if (isGroup) {
      countSum += partnershipsInCount || 0
      swVisitsSum += swVisits || 0
    }

    return {
      id,
      channelId,
      domain,
      title,
      icon,
      ecosystemId,
      kind,
      group: isGroup && !isSingle ? [path, domain] : [domain],
      children: isGroup,
      pinned: isGroup || isSingle,
      mozDomainAuthority,
      swGlobalRank,
      swVisits,
      partners: partnershipsInCount
        ? (partnershipsInCount * 100) / partnersCount
        : 0,
      count: partnershipsInCount,
      share: partnershipsInCount
        ? (partnershipsInCount * 100) / partnersCount
        : 0,
      contactStatus,
      contactsFoundCount,
      type1Classification,
      tags,
      systemTags,
      onAddToRelevant,
    }
  })

  const groupRow = {
    id: 0,
    group: [path],
    root: true,
    pinned: true,
    count: countSum,
    swVisits: swVisitsSum,
    ...(untapped && {
      partners: ((partnersCount - untapped) * 100) / partnersCount,
    }),
  }

  if (isGroup && !isSingle) {
    return [groupRow, ...rows]
  }

  return rows //.filter(({ domain, children }) => children || rows[0].domain !== domain)
}

export const EcosystemLandscapeTree = ({
  ecosystem,
  data,
  primary,
  untapped,
  primaryDomains,
  loading,
  total,
  error,
  sortModel,
  onSort,
  onPageChange,
  onRowClick,
  onQuickActions,
  onItemQuickActions,
  onManageTags,
  selected,
  onSelect,
  onGetAllIds,
}) => {
  const { affiliatePartnersCount } = ecosystem

  const primaryDomainsPath = primary.map(({ domain }) => domain).join(', ')

  const rows = getRows(data, null, affiliatePartnersCount)
  const filteredRows = rows.filter(
    ({ domain }) => !primaryDomainsPath.includes(domain)
  )

  const groupRows = getRows(
    primaryDomains,
    primaryDomainsPath,
    affiliatePartnersCount,
    untapped
  )

  const treeRows = Boolean(primary && primary.length)
    ? [...groupRows, ...filteredRows]
    : filteredRows

  return (
    <DataGridPro
      treeData
      hideFooter
      loading={loading}
      error={error}
      rows={treeRows.map((r) => ({ ...r, onQuickActions, onManageTags }))}
      sortingMode='server'
      columns={columns}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={CUSTOM_GROUPING_COL_DEF(total)}
      total={total}
      selected={selected}
      onSelect={onSelect}
      onQuickActions={onQuickActions}
      onRowsScrollEnd={onPageChange}
      onSortModelChange={onSort}
      onRowClick={onRowClick}
      onGetAllIds={onGetAllIds}
      getRowClassName={({ row }) => {
        if (row.root) {
          return 'pinned'
        }
        if (row.children) {
          return 'group-item'
        }
        return null
      }}
      initialState={{
        sorting: {
          sortModel,
        },
      }}
    />
  )
}

export default EcosystemLandscape
