import { Get, Delete, Post, Put } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import { find, findIndex } from 'lodash'
import moment from 'moment'

import GqlClient from '../gql/gql-client'
import hasError from './request-message'
import UserStore from './user'
import SearchesStore from './searches'
import { RelevantFoldersStore } from './folders'
import TagsStore from './tags'
import ContactInfosStore from './contact-infos'
import ContactRequestsStore from './contact-requests'
import IntelligenceStore from './intelligence'
import BrandsStore from './brands'

import config from '../config'
import { GET_BRAND, UPDATE_BRAND } from '../gql/brands'
import { sendEvent } from '../utils/events'


const convertToStrings = (data) => data.map(({ value }) => value)

const convertToOptions = (data = [], options) => data.map(value => find(options, ['value', value]))

class BrandStore {
  @observable loading = true
  @observable thinking = false

  @observable id = null
  @observable brand = {}
  @observable channel = {}
  @observable brandFlags = {}

  @observable reRunDiscovery = {}
  @observable requestContacts = {}

  @observable openAssistedLock = false

  @observable subscription = {}

  @observable booking = false

  @observable latestUsedTags = null

  constructor () {}

  fillBrand (brand) {
    const { assisted, plan, billingStatus } = brand

    try {
      this.brandFlags = JSON.parse(brand.flags)
    } catch (e) {}


    this.brand = {
      ...brand,
      plan: assisted ? 'Assisted-GBP-Every-3-months' : plan,
      billingStatus: assisted ? 'active' : billingStatus
    }
  }

  @action
  async load (id, noLoading, callback) {
    this.id = id || this.id

    if (!this.id) {
      return callback('company')
    }

    let message = 'Brand is not created'

    if ('no_brand' !== this.id) {
      const { success, ...rest } = await GqlClient.query({ query: GET_BRAND, variables: { id: this.id } })
      if (!success) {
        return callback({ redirect: true })
      }
      const brand = rest.data['brand']
      this.id = brand.id
      this.fillBrand({...brand, firstResearchSeen: true})

      RelevantFoldersStore.load(brand.id).then()

      if (brand.onboardingStep !== 'done') {
        this.loading = false
        return callback({ step: brand.onboardingStep || 'company' })
      }

      // this.loadChargebeeStatus().then()

      // if (!brand.firstResearchSeen) {
      //   this.setFirstResearchSeen().then()
      // }
    }

    TagsStore.load(this.id).then()
    //
    ContactInfosStore.setInitial(this.id)
    ContactRequestsStore.setInitial(this.id)
    //
    await IntelligenceStore.load(this.id)
    //
    await SearchesStore.load(this.id, noLoading)

    this.loading = false

    return { message }
  }

  @action
  async loadChargebeeStatus () {
    const { message, subscription } = await Get(`/api/discovery/brands/${this.id}/subscription`)
    if (!message) {
      this.subscription = subscription
    }
  }

  @action
  async updateCounters (details) {
    const { errorCode, brand } = await Get(`/api/discovery/brands/${this.id}`)

    if (!errorCode) {
      if (!details) {
        this.fillBrand(brand)
      } else  if (details.hasOwnProperty('plan')) {
        this.fillBrand({ ...brand, ...(!!details && details) })
        const { plans } = config
        const { plan } = details

        const actionText = plan === brand.plan ? 'activate' : 'switched to'

        if (details.billingStatus === 'active') {
          this.fillBrand({...toJS(this.brand), credits: (toJS(this.brand).credits || 0) + plans[plan].credits})
        }
        await this.loadChargebeeStatus()
        hasError(true, '', `You’ve successfully ${actionText} ${plans[plan].name} for £${plans[plan].price}/${plans[plan].period}`)
      } else if (details.hasOwnProperty('credits')) {
        this.fillBrand({ ...brand, credits: details.credits })
        hasError(true, '', `You’ve successfully added ${details.added} credits`)
      } else if (details.hasOwnProperty('cancelled')) {
        await this.loadChargebeeStatus()
        hasError(true, '', `Your subscription has been cancelled`)
      }
    }
  }

  @action
  async loadChannel (channelId) {
    this.channelId = channelId
    const { message, channel } = await Get(`/api/discovery/channels/${channelId}`)
    if (!message) {
      this.channel = channel
    }
  }

  @action
  async create ({ name, categories }) {
    if (!this.id || this.id === 'no_brand') {
      const details = { brand: { name, categories: convertToStrings(categories) } }
      const { success, brand } = await Post(`/api/discovery/brands?source=onboarding`, details)
      if (success) {
        this.id = brand.id
        this.fillBrand(brand)
      }
      this.loading = false
      return { success, ...(success && { id: brand.id }) }
    }
    this.loading = false
    return await this.update({ name, categories, noMessage: true })
  }

  @action
  async update ({ name, noMessage }) {
    const payload = {
      id: this.id,
      patch: {
        name
      }
    }

    const { success,  ...rest } = await GqlClient.mutation({ mutation: UPDATE_BRAND, variables: { UpdateBrandInput: payload } })

    if (!hasError(success, '', noMessage ? '' : 'Brand details successfully updated')) {
      const brand = rest.data['updateBrand']

      this.id = brand.id
      this.fillBrand(brand)

      BrandsStore.updateBrandById(brand)
    }

    return { success }
  }

  @action
  async updateFlags (details) {
    const flags  = toJS(this.brandFlags)
    const payload = {
      id: this.id,
      patch: {
        flags: JSON.stringify({...flags, ...details})
      }
    }

    this.brandFlags = {...flags, ...details}

    const { success,  ...rest } = await GqlClient.mutation({ mutation: UPDATE_BRAND, variables: { UpdateBrandInput: payload } })

    if (!hasError(success, '')) {
      const brand = rest.data['updateBrand']
      this.id = brand.id
      this.fillBrand(brand)
    }
  }

  @action
  async updateLatestTags (tag, action) {
    let tags = this.latestTags

    if (action === 'remove') {
      tags = tags.filter(({ id }) => id !== tag)
    } else {
      const index = findIndex(tags, ['id', tag.id])

      if (action === 'update' && index >= 0) {
        tags[index] = tag
      } else if (action === 'select') {
        if (index >= 0) {
          tags.splice(index, 1)
        }
        tags = [tag, ...tags]
      }
    }
    await this.updateFlags({ tags })
  }

  @action
  async updateCompany({ name, link }) {
    const { success: successBrand } = await this.update({ name, noMessage: true })
    const { success: successChannel, message } = await Put(`/api/discovery/channels/${this.channel.id}/update-candidate`,
      { candidate: { link } })
    if (!hasError(successChannel, message, '')) {
      this.channel = {...this.channel, link }
    }
    return { success: successBrand && successChannel }
  }

  @action
  async setFirstResearchSeen () {
    const { success, message, brand } = await Put(`/api/discovery/brands/${this.id}`, {
      brand: {
        firstResearchSeen: true
      }
    })
    if (!hasError(success, message, '')) {
      sendEvent(this.brand.id, 'discoveryFirstSeen')
      this.fillBrand(brand)
    }
    return { success }
  }

  @action
  setInitial () {
    this.loading = true
    this.thinking = true

    this.id = null
    this.channelId = null
    this.brand = {}
    this.channel = {}
    this.reRunDiscovery = {}
    this.requestContacts = {}
    SearchesStore.setInitial()
    TagsStore.setInitial()
    ContactInfosStore.setInitial()
    ContactRequestsStore.setInitial()
  }

  @action
  openBooking () {
    this.booking = true
  }

  @action
  closeBooking () {
    this.booking = false
  }

  @action
  initReRun (details) {
    this.reRunDiscovery = {
      open: true,
      creditsLeft: this.credits,
      ...details
    }
  }

  closeReRun () {
    this.reRunDiscovery = {}
    this.updateCounters().then()
  }

  @computed
  get reRunDetails () {
    return toJS(this.reRunDiscovery)
  }

  @action
  initRequestContacts (kind, partnersIds, channelId, callback) {
    this.requestContacts = {
      kind,
      open: true,
      creditsLeft: this.credits,
      partnersIds, channelId, callback
    }
  }

  closeRequestContacts () {
    this.requestContacts = {}
    this.updateCounters().then()
  }

  timeout (time) {
    return new Promise(resolve => {
      setTimeout(() => resolve(), time)
    })
  }

  @action
  async pollingBillingStatus () {
    const { brand } = await Get(`/api/discovery/brands/${this.id}`)
    if (brand.billingStatus !== 'active') {
      await this.timeout(1500)
      return await this.pollingBillingStatus()
    }
    this.fillBrand(brand)
    const { plan } = brand
    const { plans } = config
    hasError(true, '', `You’ve successfully activated ${plans[plan].name} for £${plans[plan].price}/${plans[plan].period}`)
    await this.loadChargebeeStatus()
    return { success: true }
  }

  @action
  async selectSubscription (plan) {
    const { success, message, brand } = await Put(`/api/discovery/brands/${this.brand.id}/select-plan`, { plan })
    const { plans } = config
    if (!hasError(success, message, `You’ve successfully activated Self-serve for £${plans['Self-serve-GBP-Monthly'].price}/${plans['Self-serve-GBP-Monthly'].period}`)) {
      this.fillBrand(brand)
      await this.loadChargebeeStatus()
    }
    return { success }
  }

  @computed
  get requestContactsDetails () {
    return toJS(this.requestContacts)
  }

  @computed
  get details () {
    const { id, name, link, firstResearchSeen } = toJS(this.brand)
    // const { link } = toJS(this.channel)
    return { id, name, link, firstResearchSeen }
  }

  @computed
  get credits () {
    const { credits } = toJS(this.brand)
    return credits
  }

  @computed
  get plan () {
    return toJS(this.brand).plan || 'Assisted-GBP-Every-3-months'
  }

  @computed
  get chargebeeStatus () {
    const { status, cancelled_at } = toJS(this.subscription)
    return {
      status,
      cancelledAt: cancelled_at ? moment(cancelled_at * 1000).format('MMMM Do YYYY') : null
    }
  }

  @computed
  get billingStatus () {
    return toJS(this.brand).billingStatus
  }

  @observable showPlans = false

  @action
  showPlansModal () {
    this.showPlans = true
  }

  @action
  hidePlansModal () {
    this.showPlans = false
  }

  @computed
  get inactive () {
    const { assisted } = toJS(this.brand)

    if (assisted) {
      return false
    }

    const INACTIVE_STATUSES = ['paused', 'cancelled', 'nonRenewing']
    return false //!this.billingStatus || INACTIVE_STATUSES.includes(this.billingStatus) || (this.trialDaysLeft < 0 && this.billingStatus === 'inTrial')
  }

  @computed
  get trialDaysLeft () {
    const { subscribedUntil } = toJS(this.brand)
    const daysLeft = moment(subscribedUntil).diff(moment(), 'days')
    const minutesLeft = moment(subscribedUntil).diff(moment(), 'minutes')

    if (daysLeft === 0 && minutesLeft > 0) {
      return 1
    }

    return daysLeft;
  }

  @computed
  get counters () {
    const { relevantsCount, partnersCount, searchesCount } = toJS(this.brand)
    return { relevantsCount, partnersCount, searchesCount }
  }

  @computed
  get isCompanyNotCreated () {
    return false
  }

  @computed
  get role () {
    if (!UserStore.access) return null
    return UserStore.access[this.id]
  }

  @computed
  get maxValues () {
    const { partnersMaxValues, prospectsMaxValues } = toJS(this.brand)

    return {
      partnersMaxValues: {
        ...(partnersMaxValues || {}),
        swRank: 30000000
      },
      prospectsMaxValues: {
        ...(prospectsMaxValues || {}),
        swRank: 30000000
      }
    }
  }

  @computed
  get flags () {
    return toJS(this.brandFlags) || {}
  }

  @computed
  get latestTags () {
    const brandFlags = toJS(this.brandFlags)
    const flags = brandFlags || {}
    return (flags.tags || []).map(tag => ({ ...tag, latest: true })) //.slice(0, config.LATEST_TAGS)
  }

  @computed
  get paywall () {
    return toJS(this.brand).paywall
  }

  @computed
  get assistedLock () {
    const { assisted, assistedLock } = toJS(this.brand)
    return assisted && assistedLock
  }

  @computed
  get intelligenceEnabled () {
    const flags = toJS(this.brandFlags)
    const { intelligenceEnabled } = flags || {}
    return intelligenceEnabled
  }

  @action
  openAssistedLockModal () {
    this.openAssistedLock = true
  }

  @action
  closeAssistedLockModal () {
    this.openAssistedLock = false
  }
}

export default new BrandStore()
