import React from 'react'
import styled from 'styled-components'
import {
  Tooltip,
  Paper,
  IconButton,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core'
import { Close as CloseIcon, Help as HelpIcon } from '@material-ui/icons'
import {
  MultiselectFilter,
  RangeFilter,
  SelectFilter,
  AutocompleteFilter,
  LogarithmicRangeFilter,
  AdexesFilter,
  ManualClassificationFilter,
} from './Filters'
import CheckBox from '../../components/CheckBox'
import TagsFilter from '../Tags/_TagsFilter'

const Container = styled(Paper)`
  min-width: 600px;
  max-width: 700px;
  box-sizing: border-box;
  position: relative;
`

const Header = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-end;
  background: white;
`
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1em 2em;
`
const Section = styled.div`
  padding: 0 0 0.5em;
`
const Heading = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 10px;
`
const Filter = styled.div`
  display: flex;
  //width: 90%;

  & label {
    color: #676767;
    display: inline-flex;
    align-items: center;
  }
`
const LabelRoot = styled.div`
  display: inline-flex;
  align-items: center;
  width: 35%;
  padding-bottom: 1em;
`
const LabelName = styled.label`
  display: inline-flex;
  align-items: center;
`
const Value = styled.div`
  padding-left: 10%;
  width: 65%;
`
const LabelHelp = styled(HelpIcon)`
  width: 0.6em;
  height: 0.6em;
  color: #9d9d9d;
  margin-left: 0.3em;
`
const Multiselect = styled.div`
  margin-top: -16px;
  margin-bottom: -8px;
`
const Close = styled(IconButton)`
  //position: absolute;
  //right: 4px;
  //top: 4px;
  height: 35px;
  width: 35px;
`

const Body = styled.div`
  overflow-x: hidden;
  max-height: 72vh;
  padding: 0 2em 1em;
`

const Label = ({ name, id, tooltip }) => (
  <LabelRoot for={id}>
    <LabelName>
      {name}{' '}
      {!!tooltip && (
        <Tooltip title={tooltip}>
          <LabelHelp />
        </Tooltip>
      )}
    </LabelName>
  </LabelRoot>
)

const FilterDropdown = (props) => {
  const { variant, intelligenceKind } = props

  const byCountries = props.onGetFilter('byCountries')
  const lang = props.onGetFilter('lang')

  const breezyScore = props.onGetFilter(
    variant === 'leads' ? 'breezyScore' : 'avgBreezyScore'
  )
  const competitors = props.onGetFilter(
    variant === 'leads' ? 'competitorSeedsCount' : 'totalCompetitors'
  )
  const keywords = props.onGetFilter(
    variant === 'leads' ? 'keywordSeedsCount' : 'totalKeywords'
  )

  const swRank = props.onGetFilter('swRank')
  const swVisits = props.onGetFilter('swVisits')
  const domainAuthority = props.onGetFilter('domainAuthority')

  const contactsFound = props.onGetFilter('contactsFound')
  const withoutRelevant = props.onGetFilter('withoutRelevant')
  const withoutPartners = props.onGetFilter('withoutPartners')
  const affiliatesOnly = props.onGetFilter('affiliatesOnly')
  const partnershipsOnly = props.onGetFilter('partnershipsOnly')
  const hidePartners = props.onGetFilter('hidePartners')
  const hideRelevants = props.onGetFilter('hideRelevants')

  const type1 = props.onGetFilter('type1')
  const type2 = props.onGetFilter('type2')
  const type3 = props.onGetFilter('type3')

  const byTags = props.onGetFilter('byTags')
  const adexes = props.onGetFilter('adexes')
  const promotes = props.onGetFilter('promotes')
  const promotedBy = props.onGetFilter('promotedBy')

  const onChange = (filter) => (value) => props.onChange({ ...filter, value })

  return (
    <Container elevation={5}>
      <Header>
        <Close aria-label='close' onClick={props.onApply}>
          <CloseIcon />
        </Close>
      </Header>

      <Body>
        <Section>
          <Heading>Tags</Heading>
          <Filter>
            <TagsFilter
              {...byTags.key}
              value={byTags.value}
              onChange={onChange(byTags)}
            />
          </Filter>
        </Section>
        {variant === 'intelligence' && intelligenceKind === 'gap' && (
          <Section>
            <Heading>Options</Heading>
            <Filter>
              <>
                <label>
                  <CheckBox
                    disabled={hidePartners.key && hidePartners.key.disabled}
                    checked={hidePartners.value}
                    onChange={(target, value) => onChange(hidePartners)(value)}
                  />
                  Hide existing partners
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <CheckBox
                    disabled={hideRelevants.key && hideRelevants.key.disabled}
                    checked={hideRelevants.value}
                    onChange={(target, value) => onChange(hideRelevants)(value)}
                  />
                  Hide existing relevants
                </label>
              </>
            </Filter>
          </Section>
        )}

        <Section>
          <Heading>Classification</Heading>
          <Filter>
            <Label name='Entity' />
            <Value>
              <Multiselect>
                <ManualClassificationFilter
                  {...type1.key}
                  value={type1.value}
                  onChange={onChange(type1)}
                />
              </Multiselect>
            </Value>
          </Filter>
          <Filter>
            <Label name='Category' />
            <Value>
              <Multiselect>
                <ManualClassificationFilter
                  {...type2.key}
                  value={type2.value}
                  onChange={onChange(type2)}
                />
              </Multiselect>
            </Value>
          </Filter>
          <Filter>
            <Label name='Niche' />
            <Value>
              <Multiselect>
                <ManualClassificationFilter
                  {...type3.key}
                  value={type3.value}
                  onChange={onChange(type3)}
                />
              </Multiselect>
            </Value>
          </Filter>
        </Section>

        {variant !== 'intelligence' && (
          <Section>
            <Heading>Options</Heading>
            <Filter>
              {variant === 'leads' && (
                <>
                  <label>
                    <CheckBox
                      disabled={
                        affiliatesOnly.key && affiliatesOnly.key.disabled
                      }
                      checked={affiliatesOnly.value}
                      onChange={(target, value) =>
                        onChange(affiliatesOnly)(value)
                      }
                    />
                    With partnerships only
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </>
              )}

              {variant === 'leads' && (
                <label>
                  <CheckBox
                    disabled={
                      withoutPartners.key && withoutPartners.key.disabled
                    }
                    checked={withoutPartners.value}
                    onChange={(target, value) =>
                      onChange(withoutPartners)(value)
                    }
                  />
                  Hide existing partners
                </label>
              )}

              {variant === 'leads' && (
                <label>
                  <CheckBox
                    disabled={
                      withoutRelevant.key && withoutRelevant.key.disabled
                    }
                    checked={withoutRelevant.value}
                    onChange={(target, value) =>
                      onChange(withoutRelevant)(value)
                    }
                  />
                  Hide relevant
                </label>
              )}
              {variant !== 'leads' && (
                <label>
                  <CheckBox
                    disabled={
                      partnershipsOnly.key && partnershipsOnly.key.disabled
                    }
                    checked={partnershipsOnly.value}
                    onChange={(target, value) =>
                      onChange(partnershipsOnly)(value)
                    }
                  />
                  With partnerships only
                </label>
              )}
              {variant === 'intelligence' && (
                <label>
                  <CheckBox
                    disabled={contactsFound.key && contactsFound.key.disabled}
                    checked={contactsFound.value}
                    onChange={(target, value) => onChange(contactsFound)(value)}
                  />
                  Contacts Found
                </label>
              )}
            </Filter>
          </Section>
        )}
        <br />
        <Section>
          <Heading>Audience</Heading>
          <Filter>
            <Label name='Country' />
            <Value>
              <Multiselect>
                <MultiselectFilter
                  {...byCountries.key}
                  value={byCountries.value}
                  onChange={onChange(byCountries)}
                />
              </Multiselect>
            </Value>
          </Filter>
          <Filter>
            <Label name='Language' />
            <Value>
              <AutocompleteFilter
                {...lang.key}
                value={lang.value}
                onChange={onChange(lang)}
              />
            </Value>
          </Filter>
        </Section>

        <Section>
          <Heading>Relevancy</Heading>
          <Filter>
            <Label name={variant === 'leads' ? 'Score' : 'Breezy Score'} />
            <Value>
              <RangeFilter
                {...breezyScore.key}
                value={breezyScore.value}
                onChange={onChange(breezyScore)}
              />
            </Value>
          </Filter>
          <Filter>
            <Label
              name={variant === 'leads' ? 'Competitors' : 'Total Competitors'}
            />
            <Value>
              <RangeFilter
                {...competitors.key}
                value={competitors.value}
                onChange={onChange(competitors)}
              />
            </Value>
          </Filter>
          <Filter>
            <Label name={variant === 'leads' ? 'Keywords' : 'Total Keywords'} />
            <Value>
              <RangeFilter
                {...keywords.key}
                value={keywords.value}
                onChange={onChange(keywords)}
              />
            </Value>
          </Filter>
        </Section>

        <Section>
          <Heading>Scale</Heading>
          <Filter>
            <Label name='Similarweb Rank' />
            <Value>
              <LogarithmicRangeFilter
                {...swRank.key}
                value={swRank.value}
                onChange={onChange(swRank)}
              />
            </Value>
          </Filter>

          <Filter>
            <Label name='Similarweb Visits' />
            <Value>
              <LogarithmicRangeFilter
                {...swVisits.key}
                value={swVisits.value}
                onChange={onChange(swVisits)}
              />
            </Value>
          </Filter>

          <Filter>
            <Label name='MOZ Domain  Authority' />
            <Value>
              <RangeFilter
                {...domainAuthority.key}
                value={domainAuthority.value}
                onChange={onChange(domainAuthority)}
              />
            </Value>
          </Filter>
        </Section>

        {variant === 'intelligence' && (
          <Section>
            <Heading>Partnerships</Heading>
            <Filter>
              <Label name='Adexes' />
              <Value>
                <Multiselect>
                  <AdexesFilter
                    disabled={
                      variant !== 'channels' && intelligenceKind !== 'gap'
                    }
                    {...adexes.key}
                    value={adexes.value}
                    onChange={onChange(adexes)}
                  />
                </Multiselect>
              </Value>
            </Filter>

            {intelligenceKind === 'gap' && (
              <Filter>
                <Label name='Promotes Count' />
                <Value>
                  <RangeFilter
                    {...promotes.key}
                    value={promotes.value}
                    onChange={onChange(promotes)}
                  />
                </Value>
              </Filter>
            )}

            {intelligenceKind === 'landscape' && (
              <Filter>
                <Label name='Promoted by Count' />
                <Value>
                  <RangeFilter
                    {...promotedBy.key}
                    value={promotedBy.value}
                    onChange={onChange(promotedBy)}
                  />
                </Value>
              </Filter>
            )}
          </Section>
        )}
      </Body>

      <Footer>
        <Button
          color='primary'
          variant='outlined'
          mb={2}
          mr={4}
          onClick={props.onCancel}
        >
          Clear
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          variant='contained'
          color='primary'
          mb={2}
          onClick={props.onApply}
        >
          &nbsp;&nbsp;Search&nbsp;&nbsp;
        </Button>
      </Footer>
    </Container>
  )
}

export default FilterDropdown
