import React from 'react'
import Helmet from 'react-helmet'
import styled, { createGlobalStyle } from 'styled-components'
import { Button } from '@mui/material'
import {
  CssBaseline
} from '@material-ui/core'
import LoaderOverlay from '../../components/Loader/LoaderOverlay'
import AppBarNew from '../../components/AppBarNew/AppBarNew'
import Sidebar from '../Sidebar/Sidebar'
import Navigation from '../Navigation/Navigation'
import config from '../../config'


const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    width: 100vw;
    min-width: 1024px;
    overflow-y: hidden;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Main = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
  overflow: hidden;
`;

const Drawer = styled.div`
  flex: 0 0 auto;
  width: ${config.DRAWER_WIDTH}px;
  border-right: 1px solid rgba(0,0,0,0.02);
  background: ${props => props.theme.palette.brand.grey.light};
  //background: red;
  > .MuiDrawer-paper {
    z-index: unset;
  }
  // > div {
  //   //padding: 18px 12px;
  //   border-right: 0;
  //   background: ${props => props.theme.palette.brand.grey.light};
  //   display: flex;
  // }
`;

const AppContent = styled.div`
  width: calc(100% - ${config.DRAWER_WIDTH}px);;
  background: #fff;
  display: flex;
  flex-direction: column;
`;

const Layout = ({
                  loading,
                  title,
                  navigation,
                  children,
                  menu: Menu,
                  appBarProps
                }) => {

  return (
    <Root>
      <Helmet title={title}/>
      <CssBaseline />
      <GlobalStyle />
      {loading && <LoaderOverlay size={35} />}

      {
        !loading && (
          <Main>
            <Drawer >
              <Sidebar>
                {/*<Button variant={'contained'}>I'm Button from MUI 5</Button>*/}
                <Navigation items={navigation} />
              </Sidebar>
            </Drawer>
            <AppContent>

              <AppBarNew
                loading={loading}
                menu={Menu}
                {...appBarProps}
              />

              { children }

            </AppContent>
          </Main>
        )
      }
    </Root>
  )
}

export default Layout
