// Leads
// breezyScore (score asc, score desc)
// domainAuthority (moz asc, moz desc)
// swRank (rank asc, rank desc)
// swVisits (visits asc, visits desc)
// keywordSeedsCount (keywords asc, keywords desc)
// competitorSeedsCount (competitors asc, competitors desc)
// partnershipsOutCount ( partnershipsOut asc, partnershipsOut desc)
// Prospects
// avgBreezyScore (score asc, desc)
// domainAuthority (moz)
// swRank (visits)
// swVisits (rank)
// totalKeywords (keywords)
// totalCompetitors (competitors)
// partnershipsOutCount (partnershipsOut)

const fields = {
  breezyScore: 'score',
  avgBreezyScore: 'score',
  mozDomainAuthority: 'moz',
  swRank: 'rank',
  swVisits: 'visits',
  totalKeywords: 'keywords',
  keywordSeedsCount: 'keywords',
  totalCompetitors: 'competitors',
  competitorSeedsCount: 'competitors',
  partnershipsOutCount: 'partnershipsOut',

  name: 'firstName',
  channel: 'domain',
  jobTitle: 'jobTitle',
  status: 'status'
}

const getField = (field) => {
  return fields[field] || field
}

export const getSorting = (order, orderBy) => {
  return `${getField(orderBy)} ${order}`
}
