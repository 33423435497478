import React from 'react'
import { inject, observer } from 'mobx-react'
import Button from "../../components/Button"
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import ModalLayout from '../../layouts/ModalLayout'
import {Header } from '../../components/Modals/IntermediateModal'
import {LightningColor} from '../../components/Icons/LightningColor'
import config from '../../config'

import { sendEvent } from '../../utils/events'

export const Root = styled.div`
  //width: 100%;
  height: 100%;
  position: relative;
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`


export const Body = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 150px;
  margin-bottom: 2em;
  padding: 5em 6em 5em;
`

export const Image = styled.div`
  width: 124px;
  height: 124px;
  margin-right: 2em;
`

export const Message = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-left: ${props => props.noPadding ? 0 : '4em'};
`

function messageSales () {
  return window.Intercom('showNewMessage', config.SALES_MESSAGE)
}

export const AssistedLockContent = ({ onClose, brandId, disableImage }) => {
  const onContact = () => {
    messageSales()
    sendEvent(brandId, 'contactSalesButton')
    if (Boolean(onClose)) {
      onClose()
    }
  }
  return (
    <Root>
      <Main>
        <Header />
        <Body>
          {!disableImage && <Image><LightningColor /></Image>}
          <Message noPadding={disableImage}>
            Your account is managed by our Customer Success team. Please contact us to add additional searches to your account.<br/><br/>
            <Button variant={'contained'} disableElevation color={'primary'} size={'large'} onClick={onContact}>Contact Us</Button>
          </Message>
        </Body>
      </Main>
    </Root>
  )
}

@inject(({ BrandStore, SearchesStore, SearchStore }) => ({
  brandId: BrandStore.id,
  onClose: () => BrandStore.closeAssistedLockModal()
}))
@observer
export default class AssistedLockModal extends React.Component {
  render () {
    const { open, onClose } = this.props

    return (
      <Dialog
        open={open}
        aria-labelledby="assisted-lock-modal"
        maxWidth={'md'}
        fullWidth="true"
        onEscapeKeyDown={onClose}
      >
        <ModalLayout
          noPadding
          onClose={onClose}
        >

          <AssistedLockContent onClose={onClose} brandId={this.props.brandId} />

        </ModalLayout>
      </Dialog>
    )
  }
}
