import React from 'react'
import { inject, observer } from 'mobx-react'
import Menu from './Menu'

const _LogoutDropdown = inject(({ UserStore, BrandStore, BrandsStore }) => ({
  details: BrandStore.details,
  brands: BrandsStore.brands,
  firstName: UserStore.user.firstName,
  fullName: UserStore.user.fullName,
  email: UserStore.user.email,
  onClearBrand: () => BrandStore.setInitial(),
  onLogout: () => UserStore.signout(),
}))(observer(({
                                              loading,
                                              email,
                                              details,
                                              brands,
                                              firstName,
                                              fullName,
                                              onLogout,
                                              onClearBrand,
                                              ...rest
              }) => {

  return <Menu
    loading={loading}
    firstName={firstName}
    fullName={fullName}
    brands={brands}
    details={details}
    email={email}
    onLogout={onLogout}
    onClearBrand={onClearBrand}
    {...rest}
  />
}))

export default _LogoutDropdown
