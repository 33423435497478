import React from 'react'
import { Dialog } from '@material-ui/core'
import styled from 'styled-components'
import ModalLayout from '../../layouts/ModalLayout'
import comp from '../Icons/Reflecting.svg'
import BillingCredits from '../Billing/BillingCredits'
import CreditsInfo from '../CreditsInfo'

import config from '../../config'

const { plans } = config

const breakpoint = 961

const Root = styled.div`
  //width: 100%;
  height: 100%;
  min-height: 50vh;
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  @media (max-width: ${breakpoint}px) {
    grid-template-columns: calc(100%);
  }
  
  h1 {
    font-family: "GT Super Display", serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 1;
    color: #000021;
    margin: 0 0 32px 0;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #3E3E3E;
    margin: 0;
    max-width: 400px;
    //text-align: center;
  }
  button {
    margin: 37px 0 0 0;
  }
`

const Main = styled.main`
  padding-left: 10%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 3fr 2fr;
  @media (max-width: ${breakpoint}px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const Img = styled.div`
  flex-basis: 40%;
  height: 100%;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    //height: 50%;
    //top: 20%;
    //left: -3%;
    object-fit: contain;
    object-position: right bottom;
  }
  @media (max-width: ${breakpoint}px) {
    display: none;
  }
`

const Header = styled.div`
  
`
const Body = styled.div`

`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 4em;
`
const Info = styled.div`
  display: flex;
  align-items: center;
  .MuiSvgIcon-root {
    margin-right: 0.3em;
    height: 1em;
    width: 1em;
    opacity: 0.8;
  }
`

const ShortCredits = styled.p`
  color: ${props => props.theme.palette.error.main}
`

const ContentWrapper = styled.div`
  max-width: 62ch;
  @media (max-width: ${breakpoint}px) {
    max-width: 100%;
  }
`

export default class IntermediateModal extends React.Component {
  onShowPlans = (e) => {
    e.preventDefault()
    this.props.onShowPlans()
    this.props.onClose()
  }
  render () {
    const {
      open,
      onClose,
      billingProps,
      billingEndTrial: BillingEndTrial
    } = this.props

    const { plan } = billingProps

    return (
      <>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          disableEnforceFocus
          open={open}
          ariaLabelledby="dialog-title"
          onEscapeKeyDown={onClose}
        >
          <ModalLayout
            onClose={onClose}
            noPadding
          >
            <Root>
              <Main>
                <Header />
                <Body>
                  <ContentWrapper>
                    <h1>
                      Purchase Credits
                    </h1>

                    {
                      billingProps.billingStatus !== 'inTrial' && (
                        <BillingCredits
                          disabled={billingProps.billingStatus === 'inTrial'}
                          {...billingProps}
                          onSuccess={onClose}
                        />
                      )
                    }

                    {
                      billingProps.billingStatus === 'inTrial' && (
                        <>
                          <BillingEndTrial onSuccess={onClose} />
                          <br/>
                          <br/>
                        </>
                      )
                    }

                    {
                      billingProps.billingStatus === 'inTrial' && (
                        <>
                          To purchase more credits you need to switch to a <strong>Paid plan</strong> which already includes <strong>200</strong> credits
                          on a monthly or <strong>600</strong> credits on a quarterly plans respectively.
                          <br/>
                          <br/>
                          <p>You will be activating {plans[plan].plan} for £{plans[plan].price}/{plans[plan].period}</p>
                          <p>Want to change your plan? <a href="#" onClick={this.onShowPlans}>Click here.</a></p>
                        </>
                      )
                    }
                  </ContentWrapper>
                </Body>
                <Footer>
                  <CreditsInfo />
                </Footer>
              </Main>
              <Img><img src={comp} alt="Breezy figure reading"/></Img>
            </Root>
          </ModalLayout>
        </Dialog>
      </>
    )
  }
}
