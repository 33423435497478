import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const themeOptions = {
  palette: {
    primary: {
      light: 'rgba(59, 99, 204, 1)',
      main: 'rgba(1, 99, 250, 1)',
      dark: 'rgba(0, 86, 205, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    success: {
      light: 'rgba(3, 255, 196, 1)',
      main:  'rgba(3, 201, 154, 1)',
      dark:  'rgba(1, 148, 114, 1)',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    error: {
      main: 'rgba(255, 63, 86, 1)'
    },
    brand: {
      blue: {
        light: 'rgba(216, 239, 255, 1)',
        main: 'rgba(1, 99, 250, 1)',
        dark: 'rgba(0, 86, 205, 1)'
      },
      turquoise: {
        light: 'rgba(229, 252, 250, 1)',
        main: 'rgba(0, 222, 209, 1)',
      },
      grey: {
        light: 'rgba(244, 248, 255, 1)',
        main: 'rgba(233, 243, 254, 1)',
        dark: 'rgba(209, 226, 255, 1)',
        darker: 'rgb(188,205,230)',
      },
      shadow: {
        light: 'rgba(244, 245, 250, 1)',
        main: 'rgba(224, 230, 240, 1)',
        dark: 'rgba(169, 184, 205, 1)',
        darkest: 'rgba(145, 167, 207, 1)',
        // darkest: 'rgba(41, 61, 82, 1)'
      },
      historical: {
        main: '#f6f6f6'
      }
      // /* Text/Default */
      // background: #222222;
    },
    defaultText: 'rgba(0, 0, 0, 0.87)',
    mutedText: 'rgba(41, 61, 82, 1)',
    accentDarkText: 'rgba(5, 3, 76, 1)'
  },
  typography: {
    "fontFamily": 'Roboto, Arial, sans-serif',
  },
  shape: {
    borderRadius: 5,
  },
  // spacing: {
  //   gap: '0.6em'
  // },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline' : {
          borderColor: 'rgba(217, 224, 236, 1)'
        }
      }

    },
    MuiButton: {
      root: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'capitalize'
      }
    },
    MuiAccordion: {
      rounded: {
        borderRadius: '5px',
      },
      root: {
        marginBottom: '0.5em',
        '&:not(.Mui-expanded):not(:last-child)': {
          // borderBottom: 'transparent'
        },
        '&:before': {
          display: 'none'
        }
      }

    }
    ,
    MuiChip: {
      root: {
        flex: 0,
        border: 0,
        backgroundColor: 'rgba(216, 239, 255, 1)',
        color: 'rgba(1, 99, 250, 1)',
        boxSizing: 'border-box',
        borderRadius: 3,
        '& svg': {
          width: 16,
          margin: '0 5px 0 -9px',
          color: 'rgba(1, 99, 250, 1)',
        }
      },
      label: {
        paddingLeft: 7,
        paddingRight: 12
      },
      clickable: {
        '&:hover': {
          backgroundColor: 'rgba(220, 244, 255, 1)'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12
      }
    }
  }
}

const themeFix = createTheme(themeOptions);

const theme = responsiveFontSizes(themeFix)

export default theme
