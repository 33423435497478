import React from 'react'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import ChangePasswordForm from '../../components/Forms/ChangePassword'
import { SuccessRecoverPassword } from '../../components/Success/Success'

@inject(({ UserStore }) => ({
  thinking: UserStore.thinking,
  user: UserStore.user,
  onSubmit: details => UserStore.changePassword(details),
  onSignin: details => UserStore.signin(details)
}))
@observer
@withRouter
export default class RecoverPassword extends React.Component {
  interval = null

  constructor (props) {
    super(props)
    const { token } = qs.parse(props.location.search)
    this.state = { success: false, token, counter: 10, email: null, password: null }
  }

  onSignin = async () => {
    const { email, password } = this.state
    clearInterval(this.interval)
    const { success } = await this.props.onSignin({ email, password })
    if (success) {
      this.props.history.push({ pathname: '/brand' })
    }
  }

  onSubmit = async (details) => {
    const { token } = this.state
    const { message, success, email, password } = await this.props.onSubmit({ ...details, token })

    if (success) {
      this.setState({ success: true, email, password })
      this.interval = setInterval(() => {
        const { counter } = this.state
        if (counter > 0) {
          this.setState({ counter: counter - 1 })
        } else {
          this.onSignin()
        }
      }, 1000)
    }

    return { error: message }
  }

  render () {
    const { success, counter } = this.state
    const { thinking } = this.props

    if (success) {
      return (
        <SuccessRecoverPassword
          header={'Password successfully changed'}
          subHeader={'If you can’t find it, please, check the spam or verify the email that you’ve provided.'}
          counter={counter}
          onClick={this.onSignin}
        />
      )
    }

    return (
      <ChangePasswordForm
        thinking={thinking}
        onSubmit={this.onSubmit}
        header={'Set New Password'}
        subHeader={`Enter your new password and we’ll get you up and running at soon as we can`}
      />
    )
  }
}
