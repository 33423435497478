import React from 'react'
import { Grid } from '@material-ui/core'
import TextRows from '../TextRows'
import { FormsModal } from './FormsModal'

export default class CompetitorsForm extends React.Component {
  render() {
    const {
      errors,
      competitors,
      onChange,
      variant
    } = this.props

    const Text = () =>
      <>
        <h2 style={{marginTop: 18}}>Add your Competitors'&nbsp;URLs</h2>
        <p className={'prospect'}>Input at least 3 Competitors. We'll sneakily use this list of your competitors to identify who they've partnered with and spot new opportunities.</p>
        <h3>Tips</h3>
        <ol>
          <li>The larger and more well-known the better. If you don’t have any obvious competitors, who is closest to you?
          </li>
          <li><strong>Copy a column of URLs</strong> from a spreadsheet or list and <strong>paste it into the textbox.</strong></li>
        </ol>
      </>

    return (
      <FormsModal
        Text={Text}
      >
        <Grid container style={{marginTop: '16px'}}>
          <Grid item xs={12}>
        <TextRows
          // maxRow={15}
          title={variant === 'update' ? 'Update Competitors URLs' :'Enter Competitors URLs'}
          validation={'competitors'}
          placeholder={'e.g. https://example.com'}
          helpText={'Type one URL per line'}
          values={competitors}
          errors={errors}
          onChange={onChange}
        />
          </Grid>
        </Grid>
      </FormsModal>
    )
  }
}
