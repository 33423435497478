import React, { useContext, useImperativeHandle, forwardRef } from 'react'

import DetailsProvider, {DetailsContext} from './DetailsContext'
import DetailsSidebar, { TabPanel, Content } from '../DetailsSidebar'

import DetailsView from './DetailsView'
import ProfileView from './ProfileView'
import ContactsView from './ContactsView'
import RelevantPlacementsView from './RelevantPlacementsView'
import KeywordsView from './KeywordsView'
import CompetitorsView from './CompetitorsView'

import { DetailsSidebarKeysBase } from '../../detailsSidebarConfig'

interface DetailIds {
    itemId?: string
    brandId: string
    channelId: string
}

interface DetailsProps extends DetailIds {
    open: boolean
    variant: DetailsSidebarKeysBase
    geoFilter: Array<string> | undefined
    tagManagement?: object
    onQuickActions: () => void
    onClose: () => void
}

const Details = forwardRef((props: DetailsProps, ref) => {


    return (
        <DetailsProvider {...props}>
            <DetailsSidebar open={props.open} variant={props.variant} onClose={props.onClose}>
                {
                    ({ tab, expanded, onSetViewState }) => {
                        const { onUpdateItem, onUpdateTags } = useContext(DetailsContext)
                        useImperativeHandle(ref, () => ({
                            setRelevantOrPartner (action) {
                                onUpdateItem(action)
                            },
                            setContactRequested (success) {
                                onUpdateItem('setContactRequested', success)
                            },
                            updateTags (tag, action) {
                                onUpdateTags(tag, action)
                            }
                        }));

                        return (
                            <>
                                <TabPanel value={tab} index={'collapsed'}>
                                    <Content>
                                        <DetailsView tagManagement={props.tagManagement} onQuickActions={props.onQuickActions} onSetViewState={onSetViewState} />
                                    </Content>
                                </TabPanel>
                                <TabPanel value={tab} index={'profile'}>
                                    <Content>
                                        <ProfileView variant={props.variant} tagManagement={props.tagManagement} onQuickActions={props.onQuickActions} onSetViewState={onSetViewState} />
                                    </Content>
                                </TabPanel>
                                <TabPanel value={tab} index={'contacts'}>
                                    <Content>
                                        <ContactsView onSetViewState={onSetViewState} onQuickActions={props.onQuickActions} />
                                    </Content>
                                </TabPanel>
                                <TabPanel value={tab} index={'placements'}>
                                    <Content>
                                        <RelevantPlacementsView onSetViewState={onSetViewState} onQuickActions={props.onQuickActions} />
                                    </Content>
                                </TabPanel>
                                <TabPanel value={tab} index={'keywords'}>
                                    <Content>
                                        {
                                            tab === 'keywords' && <KeywordsView onSetViewState={onSetViewState} onQuickActions={props.onQuickActions} />
                                        }
                                    </Content>
                                </TabPanel>
                                <TabPanel value={tab} index={'competitors'}>
                                    <Content>
                                        {
                                            tab === 'competitors' && <CompetitorsView onSetViewState={onSetViewState} onQuickActions={props.onQuickActions} />
                                        }
                                    </Content>
                                </TabPanel>
                            </>
                        )
                    }
                }
            </DetailsSidebar>
        </DetailsProvider>
    )
})

Details.defaultProps = {}

export default Details
