
import { Get, Post } from './request'

const CHARGEBEE_SITE = process.env.CHARGEBEE_SITE

export const onHasPaymentMethod = async (brandId) => {
  return await Get(`/api/discovery/brands/${brandId}/has-payment-source`)
}

const getPortal = async (brandId) => {
  const {portalSession} = await Post(
    `/api/discovery/brands/${brandId}/init-portal-session`,
    {redirectUrl: 'https://breezy.io'}
  )

  const cbInstance = window.Chargebee.init({
    site: CHARGEBEE_SITE
  });

  cbInstance.setPortalSession(() => {
    return new Promise(resolve => {
      return resolve(portalSession)
    })
  })

  return { cbInstance, cbPortal: cbInstance.createChargebeePortal() };
}

export const onManagePaymentMethod = async (brandId, onPaymentAdded, onReject, close) => {
  const { cbInstance, cbPortal } = await getPortal(brandId)

  cbPortal.open({
      paymentSourceAdd: () => {
        if (onPaymentAdded) {
          onPaymentAdded()
          cbInstance.closeAll()
        }
      },
      close: onReject
    },
    {
      sectionType: window.Chargebee.getPortalSections()['PAYMENT_SOURCES']
    }
  )
}

export const onEndTrial = async (brandId, onSuccess, onReject) => {
  const { has } = await onHasPaymentMethod(brandId)
  if (has) {
    await Post(`/api/discovery/brands/${brandId}/end-trial`, {})
    onSuccess()
  } else {
    await onManagePaymentMethod(brandId, () => onEndTrial(brandId, onSuccess, onReject), onReject)
  }
}

export const onActivateSubscription = async (brandId, subscriptionId, onSuccess, onReject, close) => {
  const { cbInstance, cbPortal } = await getPortal(brandId)

  cbPortal.open({
      close: onReject,
      subscriptionReactivated: () => {
        onSuccess()
        cbInstance.closeAll()
      }
    },
    {
      sectionType: window.Chargebee.getPortalSections()['EDIT_SUBSCRIPTION'],
      params: { subscriptionId }
    }
  )
}

export const onSubscribe = async (brandId, planId, onSuccess, onReject) => {
  const cbInstance = window.Chargebee.init({ site: CHARGEBEE_SITE });

  const { hostedPage } = await Post(
    `/api/discovery/brands/${brandId}/init-change-subscription`,
    { planId }
  )

  cbInstance.openCheckout({
    hostedPage: () => new Promise(resolve => resolve(hostedPage)),
    success: () => {
      onSuccess()
      cbInstance.closeAll()
    },
    close: () => {
      if (onReject) {
        onReject()
      }
    }
  });
}

export const onCharge = async (brandId, chargeId, onSuccess) => {
  const cbInstance = window.Chargebee.init({ site: CHARGEBEE_SITE });

  const { hostedPage } = await Post(`/api/discovery/brands/${brandId}/init-buy-credits`, { chargeId })

  cbInstance.openCheckout({
    hostedPage: () => new Promise(resolve => resolve(hostedPage)),
    success: () => {
      onSuccess()
      cbInstance.closeAll()
    }
  });
}

export const onCancelSubscription = async (brandId, subscriptionId, onSuccess, onReject, close) => {
  const { cbInstance, cbPortal } = await getPortal(brandId)

  cbPortal.open({
      close: onReject,
      subscriptionCancelled: () => {
        onSuccess()
        cbInstance.closeAll()
      }
    },
    {
      sectionType: window.Chargebee.getPortalSections()['SUBSCRIPTION_CANCELLATION'],
      params: { subscriptionId }
    }
  )
}
