import React from 'react'
import actions, {ActionKeysBase} from '../../actionsConfig'
import ActionButtonBase, {ActionButtonBaseProps} from './ActionButtonBase'

interface ActionButtonProps extends ActionButtonBaseProps {
    variant: ActionKeysBase
}

export function ActionButton ({variant, ...props}: ActionButtonProps) {
    return (
        <ActionButtonBase {...props} icon={actions[variant].icon} title={props.title || actions[variant].title}  />
    )
}

export default ActionButton