import React from 'react'
import {CornerDownRight} from 'react-feather'
import styled, {css} from 'styled-components'
import {SkeletonPlaceholder} from '../SkeletonPlaceholder'
import IconLabelSearch from '../../new-components/IconLabelSearch'

const Root = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0.3em 16px;
  width: 100%;
  ${props => props.disabled && css`
    opacity: 0.7;
  `};
`
const Icon = styled(CornerDownRight)`
  opacity: 0.7;
  flex: 0 0 auto;
`
const Name = styled.div`
  margin-left: 0.6em;
  white-space: nowrap;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Work Sans, Arial, sans-serif;
  font-weight: 600;
  width: 100%;
`

const DiscoveryName = ({children, loading, disabled}) =>
  <Root disabled={disabled}>
    <Icon size={16} />
    <Name>{loading ? <SkeletonPlaceholder style={{position: 'relative', top: '3px'}} width={'100%'} height={'14px'} color={'#c1c8d6'} highlightColor={'#e6efff'}/> : children}</Name>

  </Root>

export default DiscoveryName