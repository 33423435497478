import React from 'react'
import styled from 'styled-components'
import {Pane} from '../Details'
import Url from './Url'
import Title from './Title'
import Metrics from './Metrics'
import Image from 'material-ui-image'
import CategoryIcon from '@material-ui/icons/Category'
import {SkeletonPlaceholder} from '../SkeletonPlaceholder'
import Link from '../Link'
import Icon from './Icon'
import IconLabelSearch from '../../new-components/IconLabelSearch'
import {Thumbnail as FavIcon} from './Icon'

const ThumbnailTitle = styled.div`
  display: flex;
  margin: 0.5em 0 0.8em;
`

const Thumbnail = styled.div`
  width: 100px;
  height: 56.25px;
  flex: 0 0 100px;
  margin-right: 0.8em;
`
const Loader = styled(CategoryIcon)`
  width: 48px;
  height: 48px; 
  color: ${props => props.theme.palette.grey[300]}
`

const TitlePlaceholderContainer = styled.div`
  width: 100%;
  height: 15px;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Description = styled.div`
  display: flex;
  margin: 0.5em 0 0.8em;
  align-items: center;
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
`

const Wrapper = styled.div`
  &:hover {
    ${FavIcon} {
      filter: saturate(1);
    }
  }
`

const PlaceholderTitle = () => (
  <TitlePlaceholderContainer>
    <SkeletonPlaceholder loading={false} offAnimationLoaded width={'80%'} height={'15px'} color={'#d8e0f0'}  highlightColor={'#e6efff'} />
  </TitlePlaceholderContainer>
)

const ResourceCard = ({ item, fieldAction, thumbnail, link, relevantPage, partnerLeads, naked, variant }) => {

  const Contents = () => <Wrapper>
    {/* TODO: Search name*/}
    {
      partnerLeads  && (
        <Header>
          <IconLabelSearch>
            <strong>{item.searchName}</strong>
          </IconLabelSearch>
        </Header>
      )
    }
    {
      !!link && (
        <Header>
          <Icon item={{ item }} size={12} color={'#828282'} />
          <Link
            pathOnly={relevantPage}
            value={item.url}
            link={item.url}
            title={item.url}
            // rtl
          />
        </Header>
      )
    }
    {
      !!thumbnail && <ThumbnailTitle>
        {
          !relevantPage && !partnerLeads && <Thumbnail>
            <Image src={item.screenshot} aspectRatio={(16 / 9)} loading={<Loader/>}/>
          </Thumbnail>
        }
        <Title>
            {!item.title ? PlaceholderTitle() : item.title}
        </Title>


      </ThumbnailTitle>
    }

    <Metrics fields={item.fields} action={fieldAction} id={item.id} relevantPage={relevantPage}/>
  </Wrapper>
  return naked ? <Contents/> : <Pane><Contents /></Pane>

}

ResourceCard.defaultProps = {
  link: true,
  thumbnail: true
}

export default ResourceCard
