import React from 'react'
import styled, {css} from 'styled-components'
import {
  IconButton,
  Typography,
  SwipeableDrawer as MuiDrawer
} from '@material-ui/core'
import {X as CloseIcon} from 'react-feather'
import { SkeletonPlaceholder } from '../../components/SkeletonPlaceholder'
import TabPanel, { TabSimple as Tab, TabsSimple as Tabs } from '../../components/Tabs'
import QuickActions from "../QuickActions";

const drawerWidth = 30;

const Drawer = styled(MuiDrawer)`
  > div {
    overflow: visible;
    position: absolute;
    width: ${drawerWidth}%;
    min-width: 460px;
    transition: ${props => props.theme.transitions.create('', {
      easing: props.theme.transitions.easing.easeOut,
      duration: props.theme.transitions.duration.enteringScreen,
    })};
    border: 0;
    flex-shrink: 0;
    background: #F8FAFF;
    //@media (max-width: 1270px) {
    box-shadow: -10px 0 10px rgb(0 0 0 / 10%);
    //}
  }
`;

const Close = styled(IconButton)`
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
`;

const Count = styled.div`
    background: #0041E8;
    border-radius: 5px;
    font-weight: bold;
    font-size: 9px;
    line-height: 9px;
    color: #fff;
    padding: 3px 6px 2px;
    display: inline-block;
    margin: 0 0 0 0.5em;
`;

const Heading = styled(Typography)`
  color: #3E3E3E;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

const DrawerTop = styled.div`
  padding: 12px 0 13px;
  border-bottom: ${props => props.hide ? '' : '1px solid #D9E0EC'};
`;

const DrawerContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 7em;
`;

const PlaceholderContainer = styled.div`
  padding: 20px 16px;
  svg {
    width: 100%;
    height: 40px;
  }
`;

const OverflowContent = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  width: 80%;
  direction: rtl;
  text-align: left;
`

const TabContent = styled.div`
display: flex;
align-items: center;
`

const HideMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  position: absolute;
  right: calc(100% + 3px);
  top: 0.4em;
  visibility: hidden;

  svg {
    width: 20px;
    height: 30px;
    margin: 0 2px;
  }
  ${props => props.active && css`
    visibility: visible;
  `}
`

const ActionWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: inherit;
  box-shadow: -10px 0 10px rgb(0 0 0 / 10%);
  align-items: center;
  justify-content: center;
`

export const Placeholder = () => (
  <PlaceholderContainer>
    <SkeletonPlaceholder  width={'100%'} height={'15px'} color={'#d8e0f0'}  highlightColor={'#e6efff'} />
    <div style={{margin: '10px 0 0 0'}}>
      <SkeletonPlaceholder  width={'80%'} height={'15px'} color={'#d8e0f0'}  highlightColor={'#e6efff'} />
    </div>
  </PlaceholderContainer>
)


class Details extends React.Component {
  render() {
    const {
      hide,
      hideMessage,
      variant,
      open,
      loading,
      children,
      tabId,
      notesCount,
      onTabSelect,
      close,
      quickActionProps
    } = this.props

    return (
      <Drawer
        variant="persistent"
        anchor="right"
        open={open}
        onClose={()=>{}}
        onOpen={()=>{}}
      >
        <DrawerTop hide={hide}>
          <Actions active={open}>
            <ActionWrapper>
              <Close size={'small'} aria-label="close" onClick={close}>
                <CloseIcon/>
              </Close>
            </ActionWrapper>

            {
              !loading && !['contact', 'channel'].includes(variant) && !hide && (
                <ActionWrapper>
                  <QuickActions {...quickActionProps} />
                </ActionWrapper>
              )
            }

          </Actions>
          <Heading>


            {
              !hide && (
                <Tabs value={tabId} onChange={(e, id) => onTabSelect(id)}   indicatorColor={'primary'}>
                  { variant !== 'contact' && <Tab label={'Relevance'} value={'info'} wrap='false'/> }

                  { variant !== 'channel' && <Tab label="Contacts" value={'contacts'}/> }


                  { variant !== 'contact' && <Tab label="Audience" value={'audience'}/> }
                  { variant !== 'contact' && <Tab label="Partnerships" value={'partnerships'}/> }
                  { !['contact', 'channel'].includes(variant) && <Tab label={<TabContent>Notes{!!notesCount && <Count>{notesCount}</Count>}</TabContent>} value={'notes'}/> }

                </Tabs>
              )
            }
            {/*<Button size={'small'} style={{marginRight: '0.5em'}} color={'primary'}>Details</Button><Button size={'small'}>Matching seeds</Button>*/}
          </Heading>
        </DrawerTop>
        <DrawerContainer>
          {
            hide && hideMessage && <HideMessage>
              {hideMessage}
            </HideMessage>
          }
          {
            !hide && (
              <TabPanel index={0} value={tabId}>
                { loading ? Placeholder() : children }
              </TabPanel>
            )
          }

        </DrawerContainer>
      </Drawer>
    );
  }
}

export default Details
