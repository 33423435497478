import React from 'react'
import Helmet from 'react-helmet'
import styled, {createGlobalStyle, css} from 'styled-components'
import '../components/Fonts/fonts.css'
import {CssBaseline} from '@material-ui/core'
import {BreezyLogo} from '../components/Icons/BreezyLogo'
import BgRight from '../components/Icons/BgRight.svg'
import BgLeft from '../components/Icons/BgLeft.svg'
import LoaderOverlay from '../components/Loader/LoaderOverlay'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    background: #fff;
  }
`;

const Root = styled.div`
  height: 100%;
  .MuiFormLabel-root.Mui-focused {
    color: #3E3E3E;
  }
`;

const BgContainer = styled.div`
  min-height: 100%;
  ${props => props.autoWidth && css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `}
  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${BgRight});
    background-repeat: no-repeat;
    background-position: 70% 0%;
    background-size: cover;
    @media (max-width: 600px) {
      display: none;
    }
  }
  &:after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${BgLeft});
    background-repeat: no-repeat;
    background-position: 55% 0%;
    background-size: cover;
    @media (max-width: 600px) {
      display: none;
    }
  }
`;

const FormContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: ${props => props.autoWidth ? 'auto' : '460px'};
  position: relative;
  z-index: 10;
  margin: 0 auto;
`;

const BreezyLogoContainer = styled.div`
  padding: 60px 0 50px 0;
  text-align: center;
  svg {
   margin: 0 0 0 -35px;
  }
`;

const LoaderContainer = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;


const Welcome = ({
                   children,
                   title,
                   thinking,
                   discovery,
                   inputs,
                   autoWidth,
                   ...other
                 }) => {

  const logo = !discovery && !inputs

  return (
    <>
      <Root>
        {thinking && <LoaderOverlay size={35} />}
        {
          !thinking &&
          <BgContainer autoWidth={autoWidth}>
            <Helmet title={title}/>
            <CssBaseline/>
            <GlobalStyle/>
            {
              logo && (
                <BreezyLogoContainer>
                  <BreezyLogo/>
                </BreezyLogoContainer>
              )
            }
            <FormContainer autoWidth={autoWidth}>
              {children}
            </FormContainer>
          </BgContainer>
        }
      </Root>
    </>
  )
}

export default Welcome
