import { orderBy, sumBy } from 'lodash'
import { ItemMetricsInterface } from './interfaces'
import { getPicturePath } from '../../utils/list-items'

const getItemDetails = (item) => {
  const { id, partner, relevant, internalRelevant } = item

  return {
    id,
    partner: Boolean(partner),
    prospect: Boolean(internalRelevant || relevant),
    fake: false,
  }
}

const gerGapItemDetails = (item) => {
  const { id, kind, overlap, totalBrands, share, ecosystemId } = item

  return {
    id,
    partner: kind === 'partner',
    prospect: ['prospect', 'relevant'].includes(kind),
    fake: false,
    brandsCount: overlap,
    totalBrands,
    share,
    ecosystemId,
  }
}

const getAppearsInSearches = (appearsInSearches = []) => {
  return appearsInSearches.map(
    ({
      breezyScore,
      competitorSeedsCount,
      competitorsCount,
      keywordSeedsCount,
      keywordsCount,
      name,
      searchId,
      country,
    }) => {
      return {
        id: searchId,
        name,
        geo: country,
        score: breezyScore,
        leadCompetitors: competitorSeedsCount,
        leadKeywords: keywordSeedsCount,
        searchKeywords: keywordsCount,
        searchCompetitors: competitorsCount,
      }
    }
  )
}

const getItemMetrics = (item: ItemMetricsInterface) => {
  const {
    avgBreezyScore,
    breezyScore,
    mozDomainAuthority,
    partnershipsOutCount,
    swGlobalRank,
    swVisits,
    countries,
  } = item

  return {
    metrics: {
      breezyScore: avgBreezyScore || breezyScore,
      domainAuthority: mozDomainAuthority,
      partnershipsOutCount,
      swGlobalRank,
      swVisits,
    },
    countries,
  }
}

const getCompetitors = (competitorSeeds, search) => {
  let competitors = {}

  competitorSeeds.forEach((competitor) => {
    const {
      searchCompetitor: {
        channel: { icon, domain },
      },
    } = competitor

    if (!competitors.hasOwnProperty(domain)) {
      competitors[domain] = {
        id: domain,
        icon: getPicturePath(domain, icon),
        domain,
        search: search.name,
        searchId: search.id,
        geo: search.country ? search.country.code : undefined,
        score: search.score,
      }
    }
  })

  return competitors
}

const getRelevantCompetitors = (
  leads: Array<{
    competitors: Array<any>
    search: { country: string; name: string; id: string }
  }>,
  appearsIn
) => {
  if (!leads || !leads.length) return []

  let competitors = {}
  let searchScore = {}

  appearsIn.forEach(
    ({ searchId, breezyScore }) => (searchScore[searchId] = breezyScore)
  )

  leads.forEach(({ competitors: competitorSeeds, search }) => {
    const score = searchScore[search.id]

    competitors = {
      ...competitors,
      ...getCompetitors(competitorSeeds, { ...search, score }),
    }
  })

  return Object.keys(competitors).map((key) => ({
    ...competitors[key],
  }))
}

const getKeywords = (keywordSeeds, search) => {
  let keywords = {}

  keywordSeeds.forEach((keywordWithRank) => {
    if (Boolean(keywordWithRank)) {
      const {
        rank,
        searchKeyword: { name },
      } = keywordWithRank

      if (/* position &&  */ name && !keywords.hasOwnProperty(name)) {
        keywords[name] = {
          id: name,
          position: rank || 'n/a',
          search: search.name,
          searchId: search.id,
          geo: search.country ? search.country.code : undefined,
          score: search.score,
        }
      }
    }
  })

  return keywords
}

const getRelevantKeywords = (
  leads: Array<{
    keywords: Array<any>
    search: { country: string; name: string; id: string }
  }>,
  appearsIn
) => {
  if (!leads || !leads.length) return []

  let keywords = {}
  let searchScore = {}

  appearsIn.forEach(
    ({ searchId, breezyScore }) => (searchScore[searchId] = breezyScore)
  )

  leads.forEach(({ keywords: keywordSeeds, search }) => {
    const score = searchScore[search.id]
    keywords = {
      ...keywords,
      ...getKeywords(keywordSeeds, { ...search, score }),
    }
  })

  const keywordsArray = Object.keys(keywords).map((key) => ({
    name: key,
    ...keywords[key],
  }))

  return orderBy(keywordsArray, ['position'], ['asc'])
}

const getChannelKind = (inCount = 0, outCount = 0, type1Classification) => {
  if (!inCount && !outCount && type1Classification) return null //type1Classification === 'Brand' ? 'brand' : 'creator'
  return outCount >= inCount ? 'creator' : 'brand'
}

const getChannelDetails = ({
  id,
  domain,
  title,
  description,
  lang,
  icon,
  screenshot,
  type1Classification,
  swVisits,
  partnershipsInCount,
  partnershipsOutCount,
}) => {
  const channelKind = getChannelKind(
    partnershipsInCount,
    partnershipsOutCount,
    type1Classification
  )
  return {
    id,
    domain,
    title,
    description,
    languages: lang ? [lang] : [],
    icon: getPicturePath(domain, icon),
    screenshot: getPicturePath(domain, 'main.png'),
    type: channelKind,
    partnershipsInCount,
    partnershipsOutCount,
    swVisits,
  }
}

const getPlacements = (pages = [], appearsIn = []) => {
  let searches = {}
  let placements = []

  appearsIn.map(
    ({
      name,
      searchId,
      breezyScore,
      country,
      competitorsCount,
      keywordsCount,
    }) => {
      searches[searchId] = {
        search: name,
        searchId: searchId,
        searchScore: breezyScore,
        geo: country,
        totalKeywords: keywordsCount,
        totalCompetitors: competitorsCount,
      }
    }
  )

  pages.map((page) => {
    const searchId = page.search.id
    const search = searches[searchId]

    page.leadPlacements.map((placement) => {
      placements.push({
        ...search,
        ...getLeadRelevantPlacement(placement, search, appearsIn),
      })
    })
  })

  return placements
}

const getLeadRelevantPlacement = (page, search, appearsIn) => {
  const {
    url,
    subdomain,
    title,
    competitors,
    competitorSeedsCount,
    keywords,
    keywordSeedsCount,
  } = page
  const [protocol, path] = url.split(subdomain)

  return {
    url,
    path,
    title,
    competitors: (competitors || []).map(
      ({ searchCompetitor: { channel } }) => ({
        icon: getPicturePath(channel.domain, channel.icon),
        domain: channel.domain,
      })
    ),
    keywords: getRelevantKeywords([{ keywords, search }], appearsIn),
    keywordsCount: keywordSeedsCount || keywords.length, //keywordSeeds.length,
    competitorsCount: competitorSeedsCount || competitors.length, //competitorSeeds.length,
  }
}

const averageByField = (items, field) => {
  if (!items || !field) return 0

  const count = items.length
  const sum = sumBy(items, field)

  return count > 0 ? Math.round(sum / count) : 0
}

const getKeywordCompetitorRatio = (lead, searches, variant) => {
  if (variant === 'lead') {
    const { keywordSeedsCount, competitorSeedsCount, search } = lead

    return {
      itemKeywordsCount: keywordSeedsCount,
      totalKeywords: search.keywordsCount,
      itemCompetitorsCount: competitorSeedsCount,
      totalCompetitors: search.competitorsCount,
    }
  }

  return {
    itemKeywordsCount: averageByField(searches, 'keywordSeedsCount'),
    totalKeywords: averageByField(searches, 'keywordsCount'),
    itemCompetitorsCount: averageByField(searches, 'competitorSeedsCount'),
    totalCompetitors: averageByField(searches, 'competitorsCount'),
  }
}

const handleResult = (result) => {
  if (result && result.status === 'fulfilled') {
    const { success, ...rest } = result.value
    return {
      success,
      ...(success && { ...rest }),
    }
  }
  return { success: false }
}

export const handleIntelligenceData = (result, type: string) => {
  if (result.status !== 'fulfilled') return null

  const {
    brandChannelEcosystems,
    partnerChannelEcosystems,
    adexDistributionForChannelAsBrand,
    adexDistributionForChannelAsCreator,
  } = result.value.data

  return {
    ecosystems:
      type === 'brand' ? brandChannelEcosystems : partnerChannelEcosystems,
    ...(type === 'brand' && { networks: adexDistributionForChannelAsBrand }),
    ...(type === 'creator' && {
      networks: adexDistributionForChannelAsCreator,
    }),
  }
}

const handleChannelResult = (result, item, isIntelligence) => {
  const { channel } = handleResult(result)
  return {
    ...getItemMetrics({
      ...channel,
      ...item,
      ...(!isIntelligence && { domainAuthority: item.domainAuthority }),
    }),
    channel: getChannelDetails(channel),
    summary: getChannelDetails(channel),
  }
}

const handleIntelligenceItemResult = (result) => {
  const { appearsInSearchesResult } = result
  const { tags, systemTags } = handleResult(appearsInSearchesResult)

  return { tags, systemTags }
}

const handleItemResult = (result, variant) => {
  const { appearsInSearchesResult, itemResult } = result
  const { item, leads } = handleResult(itemResult)
  const { appearsInSearches, tags, systemTags } = handleResult(
    appearsInSearchesResult
  )

  const itemLeads = leads
  const geo = variant === 'lead' ? item.search.country.code : undefined
  const isIntelligence = ['gap', 'landscape', 'partnerships'].includes(variant)

  return {
    geo,
    itemOriginal: item,
    searches: getAppearsInSearches(appearsInSearches),
    keywords: getRelevantKeywords(itemLeads, appearsInSearches),
    competitors: getRelevantCompetitors(itemLeads, appearsInSearches),
    item: getItemDetails(item),
    keywordCompetitorRatio: getKeywordCompetitorRatio(
      item,
      appearsInSearches,
      variant
    ),
    tags,
    systemTags,
    placements: !isIntelligence
      ? getPlacements(itemLeads, appearsInSearches)
      : [],
  }
}

export const handleContacts = (result) => {
  const { contactRequest, contactInfos, entityDetails } = handleResult(result)

  return {
    status: contactRequest ? contactRequest.status : undefined,
    ...(contactInfos && { contactInfos }),
    ...(entityDetails && { entityDetails }),
  }
}

export const handlePromotes = (channels = [], type) => {
  const promotes = channels
    .map((channel) => ({
      id: channel.id,
      kind: channel.kind,
      title: channel.title,
      domain: channel.domain,
      icon: getPicturePath(channel.domain, channel.icon),
      count:
        type !== 'creator'
          ? channel.partnershipsOutCount
          : channel.partnershipsInCount,
    }))
    .filter(({ count }) => count > 0)

  return orderBy(promotes, ['count'], ['desc']).slice(0, 6)
}

export const handleCreatorFetchResult = (results: Array<any>) => {
  const [channelResult] = results
  return handleChannelResult(channelResult, null, true)
}

export const handleFetchResults = (
  results: Array<any>,
  variant: string,
  contacts: object,
  intelligenceItem?: object
) => {
  let item = intelligenceItem
  let itemDetailsResult = null
  const [channelResult, appearsInSearchesResult, itemResult] = results
  const isIntelligence = ['gap', 'landscape', 'partnerships'].includes(variant)

  if (!isIntelligence) {
    const { itemOriginal, ...restItemDetails } = handleItemResult(
      { itemResult, appearsInSearchesResult },
      variant
    )
    item = itemOriginal
    itemDetailsResult = restItemDetails
  }

  return {
    contacts: handleContacts(contacts),
    ...handleChannelResult(channelResult, item, isIntelligence),
    ...itemDetailsResult,
    ...(isIntelligence && { item: gerGapItemDetails(intelligenceItem) }),
    ...(isIntelligence && {
      ...handleIntelligenceItemResult({ appearsInSearchesResult }),
    }),
  }
}
