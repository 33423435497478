import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { useIntelligenceQuery } from '../../../graphql/RequestWrapper'

import {
  Container,
  HeaderContainer,
  Header,
} from '../../../new-components/Ecosystem/EcosysctemStyledComponents'
import EcosystemComparisonSelect from '../../../new-components/Ecosystem/EcosystemComarisonSelect'
import EcosystemGapCompare from '../../../new-components/Ecosystem/EcosystemGapCompare'
import Filter from '../../../new-components/Filter'
import AdexDistributionChart from '../../../new-components/Chart/AdexDistributionChart'

import Details from '../../../components-ts/Details'
import RequestContacts from '../../../components/Modals/_RequestContactsModal'

import TagsManagement from '../../../new-components/Tags/_TagsManagement'

import { sendEvent } from '../../../utils/events'
import { getVariables } from '../../../graphql/handler'
import { gapFilters } from '../filtersConfig'
import {
  ECOSYSTEM_GAP_COMPARE,
  ECOSYSTEM_GAP_OVERLAP,
  addToRelevant,
  addToActions,
  createProspects,
  requestContact,
} from '../requests'
import { getTagsList } from '../../../utils/tags'

const keyPressListener = (element, eventName, callback) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, callback, false)
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, callback)
  } else {
    element['on' + eventName] = callback
  }
}

const GapAnalysis = inject(
  ({ IntelligenceStore, EcosystemStore, GapAnalysisStore }) => ({
    ecosystemId: IntelligenceStore.ecosystemId,

    channels: EcosystemStore.channels,
    totalBrands: EcosystemStore.totalBrands,
    primary: EcosystemStore.primary,
    secondary: EcosystemStore.secondary,
    gapFilterKind: EcosystemStore.gapFilterKind,
    setComparison: (kind, value, brandId) =>
      EcosystemStore.setComparison(kind, value, brandId),
    setGapFilterKind: (kind, brandId) =>
      EcosystemStore.setGapFilterKind(kind, brandId),
    getEcosystemRequestOptions: () => EcosystemStore.getRequestOptions(),

    filters: GapAnalysisStore.filters,
    geoFilter: GapAnalysisStore.geoFilter,
    sortQueries: GapAnalysisStore.sortQueries,
    order: GapAnalysisStore.order,
    orderBy: GapAnalysisStore.orderBy,
    loadIds: (details) => GapAnalysisStore.loadIds(details),
    exportToCsv: (details) => GapAnalysisStore.exportToCSV(details),
    setSort: (model, brandId) => GapAnalysisStore.setSort(model, brandId),
    setFilters: (filters, brandId) =>
      GapAnalysisStore.setFilters(filters, brandId),
    updateQueries: () => GapAnalysisStore.updateQueries(),
    getQueriesFromSearch: () => GapAnalysisStore.getQueriesFromSearch(),
    getRequestOptions: () => GapAnalysisStore.getRequestOptions(),
  })
)(
  observer((props) => {
    const { data, total, error, loading, onLoad, ...queryProps } =
      useIntelligenceQuery(props.brandId)
    const detailsRef = useRef(null)
    const [keyCode, setKeyCode] = useState(null)
    const [tagsManagement, setTagsManagement] = useState(null)
    const [channelDetailsId, setChannelDetailsId] = useState(null)
    const [channel, setChannel] = useState(null)
    const [requestContacts, setRequestContacts] = useState({})
    const [selected, setSelected] = useState([])
    const {
      channels,
      ecosystemId,
      filters,
      relevant,
      totalBrands,
      primary,
      secondary,
      gapFilterKind,
      setGapFilterKind,
    } = props
    const [collapsed, setCollapsed] = useState(true)
    const hasSecondary = Boolean(secondary && secondary.length)
    const comparison = Boolean(!!primary && primary.length) && hasSecondary

    const options = () => {
      const gapVariables = props.getRequestOptions()
      const ecosystemVariables = props.getEcosystemRequestOptions()
      const variables = getVariables({
        brandId: props.brandId,
        ...gapVariables,
        ecosystemId,
        comparison,
        ...props.getEcosystemRequestOptions(),
      })
      return {
        query: ecosystemVariables.comparison
          ? ECOSYSTEM_GAP_COMPARE
          : ECOSYSTEM_GAP_OVERLAP,
        dataField: ecosystemVariables.comparison
          ? 'partnersWithBrandsConnection'
          : 'partnersWithOverlapConnection',
        variables,
      }
    }

    const onChangeComparison = (kind) => (value) => {
      onCloseChannelDetails()
      props.setComparison(kind, value, props.brandId)
    }

    const onOpenChannelDetails = async (rowDetails) => {
      const {
        row: { partnerId },
      } = rowDetails
      setChannel(rowDetails.row)
      setChannelDetailsId(partnerId)
      sendEvent(props.brandId, 'showDetails')
    }

    const onCloseChannelDetails = () => {
      setChannelDetailsId(null)
    }

    const onAddToRelevant = () => {
      const { domain } = channel
      channel.onAddToRelevant(domain)
    }

    const onLoadMore = () => {
      return queryProps.onLoadMore(options(), props.onError)
    }

    const onFiltersChanged = (filters) => {
      onCloseChannelDetails()
      props.setFilters(filters, props.brandId)
      setSelected([])
      return onLoad(options(), undefined, props.onError)
    }

    const onSort = (model) => {
      if (!model || !model.length) return
      if (channelDetailsId) {
        onCloseChannelDetails()
      }
      props.setSort(model, props.brandId)
      return onLoad(options(), undefined, props.onError)
    }

    const getChannelTags = (ids) => {
      const id = ids && ids.length === 1 ? ids[0] : null
      const list = getTagsList(id, selected, data, 'channelId')
      return list.filter(({ unique }) => !unique)
    }

    const getTagsManagementDetails = (channelIds, target) => {
      setTagsManagement({
        anchorEl: target,
        leadIds: channelIds,
      })
    }

    const onCloseTagsManagement = () => {
      setTagsManagement(null)
    }

    const onQuickActions = async (id, action, d, e) => {
      let kind = null
      let multiple = !id || selected.includes(id)

      let channelIds = multiple ? selected : [id]

      console.log('Is Multiple', multiple, action, id)

      if (action === 'toExport') {
        return onExportToCsv()
      } else if (action === 'toManageTags') {
        getTagsManagementDetails(channelIds, (e || d).target)
      } else if (action === 'toRequestContacts') {
        return onRequestContactOpen(channelIds)
      } else if (action === 'toProspects') {
        kind = 'prospect'
      } else if (action === 'toPartners') {
        kind = 'partner'
      }

      if (kind) {
        let _kind = kind === 'prospect' ? 'relevant' : 'partner'
        const { success } = await createProspects(
          props.brandId,
          channelIds,
          _kind
        )
        if (success) {
          const shouldReload =
            (filters.hasOwnProperty('hideRelevants') && kind === 'prospect') ||
            (filters.hasOwnProperty('hidePartners') && kind === 'partner') ||
            (filters.hasOwnProperty('hideRelevants') &&
              filters.hasOwnProperty('hidePartners'))

          queryProps.onUpdateItems(channelIds, 'channelId', { kind: _kind })

          if (shouldReload) {
            onLoad(options(), undefined, props.onError)
          }
        }
      }

      return 'added'
    }

    const onRequestContactOpen = (ids) => {
      setRequestContacts({
        open: true,
        itemIds: ids || selected,
        onRequestSelected: (ids, message) => {
          return requestContact(props.brandId, ids || selected, message)
        },
        callback: (success) => {
          if (success) {
            queryProps.onUpdateItems(ids, 'channelId', {
              contactStatus: 'requested',
            })
          }
          if (success && detailsRef && ids.includes(channelDetailsId)) {
            detailsRef.current.setContactRequested(success)
          }
        },
      })
    }
    const onRequestContactsClose = () => setRequestContacts({})

    useEffect(() => {
      if (!props.getQueriesFromSearch()) {
        props.updateQueries()
      }
    }, [])

    useEffect(() => {
      onLoad(options(), undefined, props.onError).then()
    }, [primary, secondary, gapFilterKind])

    //TODO: Set exist for all primary brands for gapFilterKind === 'existingPartnersFor' ?!?!
    const brands =
      primary && primary.length
        ? primary.map((brand, index) => ({
            id: index,
            domain: brand.domain,
            exist: gapFilterKind === 'existingPartnersFor',
          }))
        : null

    const sortModel = [{ field: props.orderBy, sort: props.order }]

    const onManageTags = ({ channelIds, tag, tagId }, action) => {
      queryProps.onManageTags({ channelIds, tag, tagId }, action)
      if (detailsRef) {
        if (['delete', 'remove'].includes(action)) {
          detailsRef.current.updateTags(tagId, 'remove')
        } else if (action === 'select') {
          detailsRef.current.updateTags(tag, 'add')
        } else {
          detailsRef.current.updateTags(tag, 'update')
        }
      }
    }

    useEffect(() => {
      keyPressListener(
        window,
        'keydown',
        (e) => e.keyCode === 16 && setKeyCode(e.keyCode)
      )
      keyPressListener(window, 'keyup', (e) => setKeyCode(null))
    }, [])

    const onSelect = (newSelected) => {
      if (!newSelected) return false
      if (selected.length === total && newSelected.length === data.length) {
        return false
      } else if (keyCode === 16 && selected.length) {
        const newItemId = newSelected.filter((id) => !selected.includes(id))[0]
        const newItemIndex = findIndex(data, ['id', newItemId])
        const lastSelectedIndex = findIndex(data, [
          'id',
          selected[selected.length - 1],
        ])

        const add = newItemIndex > lastSelectedIndex ? 1 : -1
        let index = lastSelectedIndex
        do {
          if (!newSelected.includes(data[index].id)) {
            newSelected.push(data[index].id)
          }
          index += add
        } while (index !== newItemIndex)
        setSelected(newSelected)
      } else {
        setSelected(newSelected)
      }
    }

    const onGetAllIds = async () => {
      const { query, variables, dataField } = options()
      const newSelection = await props.loadIds({
        query,
        variables: { ...variables, skip: 0, take: total },
        dataField,
      })
      setSelected(newSelection)
    }

    const onExportToCsv = async () => {
      const { query, variables, dataField } = options()
      await props.exportToCsv({
        query,
        variables: { ...variables, skip: 0, take: total },
        dataField,
        brandId: props.brandId,
      })
    }

    return (
      <>
        <HeaderContainer>
          <Header>
            <AdexDistributionChart kind={'creator'} ecosystemId={ecosystemId} />
            <Filter
              variant={'intelligence'}
              placeholder={'Select a filter'}
              filters={filters}
              intelligenceKind={'gap'}
              intelligenceProps={{
                showFilterKind: !!primary,
                disableAllFilter: !!primary && !hasSecondary,
                filterKind: gapFilterKind,
                onFilterKindChange: setGapFilterKind,
              }}
              keys={gapFilters}
              withoutRelevant={false}
              onChange={(f) => onFiltersChanged(f)}
            />
          </Header>

          <EcosystemComparisonSelect
            channels={channels}
            primary={primary}
            secondary={secondary}
            onChange={onChangeComparison}
          />
        </HeaderContainer>
        <Container>
          <EcosystemGapCompare
            relevant={relevant}
            primary={primary}
            compare={data.map((row) => ({
              ...row,
              id: row.partnerId,
              totalBrands,
              ecosystemId,
              active: row.partnerId === channelDetailsId,
              // ...(brands && { brands }),
              onAddToRelevant: async (domain) => {
                const { success } = await addToRelevant(props.brandId, domain)
                if (success) {
                  queryProps.onUpdateItem(row.partnerId, 'partnerId', {
                    kind: 'relevant',
                  })
                }
                sendEvent(props.brandId, 'gapAddedToRelevant')
              },
            }))}
            total={total}
            collapsed={collapsed}
            loading={loading}
            error={error.message}
            sortModel={sortModel}
            selected={selected}
            onSelect={onSelect}
            onPageChange={onLoadMore}
            onCellClick={onOpenChannelDetails}
            onSort={onSort}
            onToggleCollapse={() => setCollapsed(!collapsed)}
            onManageTags={onManageTags}
            onQuickActions={onQuickActions}
            onGetAllIds={onGetAllIds}
          />
        </Container>

        <Details
          ref={detailsRef}
          variant={'gap'}
          open={Boolean(channelDetailsId)}
          channelId={channelDetailsId}
          intelligenceItem={channel}
          brandId={props.brandId}
          geoFilter={props.geoFilter}
          onQuickActions={onQuickActions}
          onClose={onCloseChannelDetails}
          tagManagement={{
            onUpdate: (tag) => onManageTags({ tag }, 'update'),
            onRemove: (tagId, channelId) =>
              onManageTags({ channelIds: [channelId], tagId }, 'remove'),
            onDelete: (tagId) => onManageTags({ tagId }, 'delete'),
            onManage: (e, channelId) =>
              getTagsManagementDetails([channelId], e.target),
          }}
        />

        <RequestContacts
          {...requestContacts}
          onClose={onRequestContactsClose}
        />

        {Boolean(tagsManagement) && (
          <TagsManagement
            {...tagsManagement}
            itemTags={getChannelTags(tagsManagement.leadIds)}
            onClose={onCloseTagsManagement}
            onSelectTag={(tag) =>
              onManageTags(
                { channelIds: tagsManagement.leadIds, tag },
                'select'
              )
            }
            onUpdateTag={(tag) => onManageTags({ tag }, 'update')}
            onRemoveTag={(tagId, channelIds) =>
              onManageTags({ channelIds, tagId }, 'remove')
            }
            onDeleteTag={(tagId) => onManageTags({ tagId }, 'delete')}
          />
        )}
      </>
    )
  })
)

export default GapAnalysis
