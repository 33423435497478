import React from 'react'
import styled, {css} from 'styled-components'
import {LinearProgress as MuiLinearProgress} from "@material-ui/core";

const InputsDisplayProgress = styled(MuiLinearProgress)`
  margin: 5px 9px;
  background: ${props => props.theme.palette.brand.turquoise.main};
  border-radius: 6px;
  .MuiLinearProgress-barColorPrimary {
    background: ${props => props.theme.palette.brand.blue.main};
    border-radius: 3px;
  }
`;

export default InputsDisplayProgress