import React, {useContext} from 'react'
import param from 'can-param'
import {DetailsContext} from '../DetailsContext'
import WidgetScore from '../../Widget/WidgetScore'
import WidgetMetric from '../../Widget/WidgetMetric'
import WidgetVisits from '../../Widget/WidgetVisits'
import {useParams, useHistory} from "react-router-dom";

interface DetailsMetricsProps {
    onClick: Function
}

export default function DetailsMetrics (props: DetailsMetricsProps) {
    // @ts-ignore
    const { brandId } = useParams()
    const history = useHistory()
    const {
        loading, variant,
        metrics, countries, keywordCompetitorRatio,
        summary, geoFilter, item
    } = useContext(DetailsContext)

    const {
        itemKeywordsCount, itemCompetitorsCount,
        totalKeywords, totalCompetitors
    } = keywordCompetitorRatio

    const onPromotesClick = () => {
        props.onClick('profile', 'promotes-brands')
        // const link = `/brand/${brandId}/intelligence/partnerships?${param({ order: 'desc', orderBy: 'partnershipsInCount', _filters: { ['promotedBy']: { value: summary.id, label: summary.domain } } })}`
        // window.open(link, '_blank')
    }

    const onPromotedByClick = () => {
        props.onClick('profile', 'promoted-creators')
        // const link = `/brand/${brandId}/intelligence/partnerships?${param({ order: 'desc', orderBy: 'partnershipsOutCount', _filters: { ['promotes']: { value: summary.id, label: summary.domain } } })}`
        // window.open(link, '_blank')
    }

    const onEcosystemShareClick = () => {
        const link = `/brand/${brandId}/intelligence/gap?${param({ ['comparePrimary']: [{ id: summary.id, domain: summary.domain }] })}`
        window.open(link, '_blank')
    }

    // @ts-ignore
    const { type, partnershipsInCount, partnershipsOutCount } = summary
    const showPromotes = Boolean(type && type === 'creator')
    const showPromotedBy = Boolean(type && type === 'brand' )
    const promotesWidgetSize = ['gap', 'landscape', 'partnerships'].includes(variant) ? '1x1' : '2x1'

    const score = <WidgetScore variant={variant === 'lead' ? 'score' : 'avgScore'} loading={loading} value={metrics.breezyScore} onClick={props.onClick} />
    const visits = <WidgetVisits loading={loading} countries={countries} geo={geoFilter} total={summary.swVisits} onClick={props.onClick} />
    const moz = <WidgetMetric loading={loading} variant={'moz'} value={metrics.domainAuthority} onClick={props.onClick} />
    const rank = <WidgetMetric loading={loading} variant={'rank'} value={metrics.swGlobalRank} onClick={props.onClick}/>
    const keywords = <WidgetMetric loading={loading} variant={variant === 'lead' ? 'keywords' : 'keywordsTotal'} value={itemKeywordsCount} valueMax={totalKeywords} onClick={props.onClick} />
    const backlinks = <WidgetMetric loading={loading} variant={variant === 'lead' ? 'backlinks': 'backlinksTotal'} value={itemCompetitorsCount} valueMax={totalCompetitors} onClick={props.onClick} />

    return  <>
        { variant === 'landscape' && <WidgetMetric variant={'ecosystemShare'} loading={loading} value={item.share || 0.001} size={'2x1'} onClick={onEcosystemShareClick} /> }
        { variant === 'gap' && [moz, visits, rank] }
        { variant === 'landscape' && [moz, visits, rank] }
        { variant === 'partnerships' && [moz, visits, rank] }

        { variant === 'lead' && [score, visits, moz, rank, keywords, backlinks] }
        { variant === 'relevant' && [score, visits, moz, rank, keywords, backlinks] }
        { variant === 'partner' && [score, visits, moz, rank, keywords, backlinks] }

        { showPromotes && <WidgetMetric loading={loading} size={promotesWidgetSize} variant={'promotes'} value={partnershipsOutCount} onClick={onPromotesClick}/> }
        { showPromotedBy && <WidgetMetric loading={loading} size={promotesWidgetSize} variant={'promotedBy'} value={partnershipsInCount} onClick={onPromotedByClick} /> }
    </>
}
