import { action, computed, observable, toJS } from 'mobx'
import Queries from './queries'

const fields = {
  'swVisits': 'visits',
  'partnershipsOutCount': 'partnerships_out',
  'partnershipsInCount': 'partnerships_in',
  'mozDomainAuthority': 'moz',
  'overlap': 'overlap',
  'swGlobalRank': 'rank'
}

class PartnershipsStore extends Queries {
  @observable kind = null
  @observable _filters = {}
  @observable order = 'desc'
  @observable orderBy = 'partnershipsOutCount'
  @observable orderByGQL

  fields = ['order', 'orderBy', '_filters', 'kind']

  constructor () {
    super()
  }

  @action
  setFilters (value) {
    this._filters = value
    this.updateQueries()
  }

  @action
  setFilterKind (kind) {
    this.kind = kind
    this.updateQueries()
  }

  @action
  setSort (model, preventUpdateQueries) {
    if (!model || !model[0]) return null

    const { field, sort } = model[0]

    this.orderBy = field

    let direction = sort

    if (this.orderBy === 'rank') {
      direction = sort === 'desc' ? 'asc' : 'desc'
    }

    this.order = direction

    if (!preventUpdateQueries) this.updateQueries()
  }

  @action
  getRequestOptions () {
    return { ...this.filters, sortQueries: this.sortQueries }
  }

  @computed
  get filters () {
    return toJS(this._filters)
  }

  @computed
  get sortQueries () {
    const orderByGQL = fields[this.orderBy]
    return orderByGQL ? `${orderByGQL} ${toJS(this.order)}` : null
  }

}

export default new PartnershipsStore()
