import React, {useContext} from 'react'
import { CreatorRequestContext } from '../CreatorRequestDetailsContext'
import WidgetNetworks from '../../Widget/WidgetNetworks'
import metrics from '../../../configMetrics'

interface DetailsNetworksProps {
    onClick: Function
}

export default function CreatorRequestDetailsNetworks (props: DetailsNetworksProps) {
    const { loading, networks, summary } = useContext(CreatorRequestContext)
    const hideWidget = Boolean(!summary.type) || !networks.length

    if (!loading && hideWidget) return null

    const onClick = () => {
        const { target, anchor } = metrics['networks']
        props.onClick(target, anchor)

    }

    return <WidgetNetworks loading={loading} size="2x1" data={networks} limit={8} onClick={onClick} />
}
