import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Button from '../Button'
import {Form, Input} from '../Form'
import Validator from '../../utils/validator'

const FORM_ID = 'brand-form'

const ActionsHolder = styled.div`
  max-width: 300px;
  margin: 32px auto 0 auto;
  text-align: center;
  width: 100%;
`
const Skip = styled(Link)`
  font-size: 16px;
  line-height: 19px;
  padding: 5px;
  margin: 10px;
  color: #003CD5;
  text-decoration: none;
  display: inline-block;
`
const Root = styled.div`
  padding: 0 0 50px 0;
`

const schema = [
  {
    field: 'firstName',
    rules: 'required',
    messages: {
      required: 'First name is required'
    }
  },
  {
    field: 'lastName',
    rules: 'required',
    messages: {
      required: 'Last name is required'
    }
  }
]

export default class JoinAccount extends React.Component {
  static defaultProps = {
    categories: []
  }

  constructor(props) {
    super(props)
    const {firstName, lastName} = this.props
    this.state = {firstName, lastName, errors: {}, error: null}
  }

  onChange = name => ({target: {value}}) => {
    this.setState({[name]: value})
    this.resetError(name)
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    const {firstName, lastName} = this.state
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      async () => {
        const {success} = this.props.onSubmit({firstName, lastName})
      }
    )
  }

  onCancel = () => {
    const {firstName, lastName} = this.props
    this.setState({firstName, lastName, errors: {}, error: null})
  }

  render() {
    const {firstName, lastName, errors} = this.state
    const {
      header,
      subHeader,
      thinking,
      error,
    } = this.props
    const Actions = () => <ActionsHolder>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        mb={2}
        disabled={thinking}
        style={{minWidth: 300}}
      >
        {'Continue'}
      </Button>
    </ActionsHolder>

    return (
      <Root>
        <Form
          noValidate
          header={header}
          subHeader={subHeader}
          formId={FORM_ID}
          onSubmit={this.onSubmit}
          actions={Actions}
          error={error}
          thinking={thinking}
        >

          <Input
            autoFocus
            required
            fullWidth
            placeholder="e.g. Joe"
            margin="none"
            id={'firstName'}
            label={'First name'}
            inputLabel={'First name'}
            variant={'outlined'}
            fieldType='clearable'
            value={firstName}
            disabled={thinking}
            error={errors.firstName}
            onChange={this.onChange('firstName')}
          />
          <Input
            required
            fullWidth
            placeholder="e.g. Bloggs"
            margin="normal"
            id={'lastName'}
            label={'Last name'}
            inputLabel={'Last name'}
            variant={'outlined'}
            fieldType='clearable'
            value={lastName}
            disabled={thinking}
            error={errors.lastName}
            onChange={this.onChange('lastName')}
          />
        </Form>
      </Root>
    )
  }
}
