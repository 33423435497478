import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {
  FormControlLabel as MuiFormControl,
  Checkbox as MuiCheckbox,
  Grid,
  Button as MuiButton
} from '@material-ui/core'
import {Form, Input} from '../Form'
import Button from '../Button'
import Validator from '../../utils/validator'

const FORM_ID = 'signin-form'

const ActionsHolder = styled.div`
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
`
const GridInput = styled(Grid)`
  max-width: 300px;
  margin: 0 auto 24px auto;
  width: 100%;
`

const LinksContainer = styled.div`
  padding-top: 1em;
  text-align: center;
  a {
    font-size: 14px;
    font-weight: bold;
  }
`

const schema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  },
  {
    field: 'password',
    rules: 'required',
    messages: {
      required: 'Password is required'
    }
  }
]

export default class SignInForm extends React.Component {
  constructor(props) {
    super(props)
    const {email, password} = this.props
    this.state = {email, password, errors: {}, error: null}
  }

  onChange = name => ({target: {value, checked}}) => {
    this.setState({[name]: name === 'consent' ? checked : value})
    this.resetError(name)
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      async () => {
        const {email, password, consent} = this.state
        const {error} = await this.props.onSubmit({email, password})

        if (error) {
          switch (error) {
            case 'emailExists':
              this.setState({errors: {email: 'User with given email already exist'}})
              break;
            case 'noEmail':
              this.setState({errors: {email: 'Email not found'}})
              break;
            case 'wrongPassword':
              this.setState({errors: {password: 'Your password is not correct'}})
              break;
            default:
              this.setState({errors: {email: error}})
          }
        }
      })
  }

  onSignUp = (e) => {
    if (this.props.onSignUp) {
      e.preventDefault()
      this.props.onSignUp()
    }
  }

  render() {
    const {email, password, errors} = this.state
    const {
      header,
      subHeader,
      thinking,
      error,
      signupLink
    } = this.props

    const isInviteState = Boolean(this.props.onSignUp)

    const Actions = () =><ActionsHolder>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        mb={2}
        fullWidth
        disabled={thinking}
      >
        Sign in
      </Button>
      <LinksContainer>
        <p>
          {/* Old signup link '/signup'*/}
          <Link to={{
            pathname: '/signup'
          }}>
            Sign up
          </Link>
          &nbsp;
          if you need an account.
          {/*<a href={process.env.BOOK_A_DEMO} onClick={this.onSignUp}>{isInviteState ? 'Sign up' : 'Book a Demo'}</a> if you need an account.*/}
        </p>
        <p><Link to={{
          pathname: '/forgot-password',
          state: { email }
        }}>I forgot my password</Link></p>
      </LinksContainer>
    </ActionsHolder>
    // console.log(errors.email)
    return (
      <Form
        noValidate
        header={header}
        subHeader={subHeader}
        formId={FORM_ID}
        onSubmit={this.onSubmit}
        actions={Actions}
        error={error}
        thinking={thinking}
      >
        <Grid container>
          <GridInput item xs={12}>
            <Input
              type={'email'}
              margin="none"
              required
              fullWidth
              placeholder="e.g. joe.bloggs@example.com"
              id={'email'}
              fieldType='clearable'
              label={'Email'}
              inputLabel={'Email'}
              variant={'outlined'}
              value={email}
              error={errors.email}
              disabled={thinking}
              onChange={this.onChange('email')}
            />
          </GridInput>
          <GridInput item xs={12}>
            <Input
              type={'password'}
              margin="none"
              required
              fullWidth
              placeholder="Password"
              id={'password'}
              fieldType='clearable'
              label={'Password'}
              inputLabel={'Password'}
              variant={'outlined'}
              value={password}
              error={errors.password}
              disabled={thinking}
              onChange={this.onChange('password')}
            />
          </GridInput>
        </Grid>
      </Form>
    )
  }
}
