import React from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel, TableContainer as MuiTableContainer
} from '@material-ui/core'
import styled from 'styled-components'

import ProductListItem from './ProductListItem'
import StickyTableCell from "../StickyTableCell/StickyTableCell";

const TableContainer = styled(MuiTableContainer)`
  padding: 1em 1em 5em 1em;
  overflow: auto;
  height: calc(100vh - 56px);
`

const COLUMNS = [
  { key: 'description', label: 'Name', width: '100%', minWidth: 250},
  { key: 'inbox', label: 'Leads', align: 'left', minWidth: '90px', denseRight:true  },
  { key: 'relevants', label: 'Relevant', align: 'right', minWidth: '90px', denseLeft:true  },
  { key: 'ownerName', label: 'Owner', align: 'left', minWidth: '120px' },
  { key: 'name', label: 'Product', align: 'left', minWidth: '120px' },
  { key: 'country', label: 'Country', align: 'left', minWidth: '120px' },
  { key: 'researchDoneAt', label: 'Updated', align: 'left', minWidth: '80px' },
]

const ListHead = ({ order, orderBy, onSort }) => (
  <TableHead>
    <TableRow>
      {
        COLUMNS.map(({ key, label, align, width, denseLeft, denseRight, minWidth }) => (
          <TableCell align={align} style={{width: width, minWidth: minWidth, paddingLeft: denseLeft && '0', paddingRight: denseRight && '0' }}>
            <TableSortLabel
              style={{minWidth: minWidth}}
              active={orderBy === key}
              direction={orderBy === key ? order : 'asc'}
              onClick={onSort(key)}
            >
              {label}
            </TableSortLabel>
          </TableCell>
        ))
      }
      <StickyTableCell style={{width: '50px'}} />
    </TableRow>
  </TableHead>
)

export default class ProductsList extends React.Component {
  onSort = orderBy => e => {
    this.props.onSort(orderBy)
  }

  render () {
    const {
      order,
      orderBy,
      products,
      onEdit,
      onDetails,
      onRemove,
      onResearch
    } = this.props

    return (
      <TableContainer>
        <Table size="small"  aria-label="a dense table">
          <ListHead
            order={order}
            orderBy={orderBy}
            onSort={this.onSort}
          />
          <TableBody>
            {
              products.map((product, index) => (
                <ProductListItem
                  key={product.id}
                  index={index + 1}
                  product={product}
                  onResearch={onResearch}
                  onRemove={onRemove}
                  onEdit={onEdit}
                  onDetails={onDetails}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>

    )
  }
}
