import React, { Component } from 'react'
import styled from 'styled-components'

const Root = styled.div`
  display: grid;
  //background: #9F9F9F;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  //grid-template-rows: repeat(auto-fit, 4.71em);
  //grid-template-rows: repeat(auto-fit, 4.71em);
  grid-gap: ${props => props.theme.spacing(1)}px;
  margin: 1em 1em 1em 0;
`

interface WidgetsGridProps {
  children?: React.ReactNode
}

function WidgetsLayout ({ children }: WidgetsGridProps) {
    return (
        <Root>
          {children}
        </Root>
    )
}

WidgetsLayout.defaultProps = {
  children: 'It\'s a me, WidgetsGrid!'
}

export default WidgetsLayout
