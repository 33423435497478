import { gql } from '@apollo/client'
import { SEARCH_FIELDS, SUCCESS_FIELDS } from './fragments'

export const GET_SEARCH = gql`
  query Search ($id: String!) {
    search (id: $id) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const GET_SEARCHES = gql`
  query SearchesOfBrand ($brandId: String!) {
    searchesOfBrand (brandId: $brandId) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const CREATE_SEARCH = gql`
  mutation CreateSearch ($CreateSearchInput: CreateSearchInput!) {
    createSearch(input: $CreateSearchInput) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const UPDATE_SEARCH = gql`
  mutation UpdateSearch ($UpdateSearchInput: UpdateSearchInput!) {
    updateSearch(input: $UpdateSearchInput) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const REMOVE_SEARCH = gql`
  mutation RemoveSearch ($id: String!) {
    deleteSearch(id: $id) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`

export const RUN_SEARCH = gql`
  mutation RunSearch ($id: String!) {
    runResearch (id: $id) {
      ...SEARCH_FIELDS
    }
  }
  ${SEARCH_FIELDS}
`
