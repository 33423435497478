import React from 'react'
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import ModalLayout from '../../layouts/ModalLayout'
import Button from '../../components/Button'
import config from '../../config'


const IntelligenceLockModal = ({ open, daysLeft, onClose }) => {
  const onContact = () => {
    window.Intercom('showNewMessage', `${config.INTELLIGENCE_MESSAGE}"`)
    onClose()
  }

  return <Dialog
    open={open}
    aria-labelledby="introduction-modal"
    maxWidth={'sm'}
    fullWidth="true"
    onEscapeKeyDown={onClose}
  >
    <ModalLayout
      noPadding
      onClose={onClose}
    >
      <DialogContent>
        <Typography variant="h6" gutterBottom>Coming soon: Breezy Intelligence</Typography>
        <Typography variant="subtitle1" gutterBottom>
          Stay tuned for the launch of this exciting new feature, which will allow you to compare your digital strategy to your competitors and discover untapped partnership leads through powerful opportunity analysis. To know more, contact your Customer Success Manager.
        </Typography>
      </DialogContent>

      <DialogActions style={{justifyContent: 'center',  paddingBottom: 20, paddingTop: 20, flexDirection: 'column'}}>
        <Button variant={'contained'} color={'primary'} size={'large'} onClick={() => onContact()}>Contact Customer Success</Button>
      </DialogActions>
    </ModalLayout>
  </Dialog>
}

export default IntelligenceLockModal
