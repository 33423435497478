import React, { useContext } from 'react'

import { CreatorRequestContext } from '../CreatorRequestDetailsContext'
import ProfileAudience from './CreatorRequestProfileAudience'
import ProfileAppearsInEcosystems from './CreatorRequestProfileAppearsInEcosystems'
import ProfileNetworksUse from './CreatorRequestsProfileNetworksUse'
import ProfilePromotes from './CreatorRequestProfilePromotes'
import ProfileLayout from '../../Profile/ProfileLayout'
import ProfileAside from './CreatorRequestProfileAside'
import Summary from '../../Summary'

interface ProfileViewProps {
    variant: string
    tagManagement?: any
    onSetViewState: Function
    onQuickActions?: () => void
}

export default function CreatorRequestProfileView(props: ProfileViewProps) {
    const { loading, summary } = useContext(CreatorRequestContext)

    return (
        <ProfileLayout aside={<ProfileAside {...props} />}>
            <Summary
                horizontal
                size={'large'}
                disableTagManagement
                loading={loading}
                {...summary}
            />
            <ProfileAudience />
            <ProfileAppearsInEcosystems />
            <ProfileNetworksUse />
            <ProfilePromotes />
        </ProfileLayout>
    )
}
