import React from 'react'
import styled, {css} from 'styled-components'
import obfuscateString from '../../utils/obfuscateString'

export const Root = styled.div`
  font-size: 14px;
  line-height: 20px;
  //display: flex;
  //flex-direction: column;
  //display: -webkit-box;
  //-webkit-line-clamp: 2;
  //-webkit-box-orient: vertical;
  //align-items: flex-start;
  //justify-content: center;
  color: #454545;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.obfuscate && css`
        filter: blur(8px);
  `}
`

const Title = ({children, obfuscate}) => (
  <Root obfuscate={obfuscate}>{obfuscate ? obfuscateString(children) : children}</Root>
)

export default Title
