import React from 'react'
import { find } from 'lodash'
import styled, { css } from 'styled-components'
import {
  Popper,
  Paper,
  Fade,
  Input,
  List as MuiList,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemIcon,
  ClickAwayListener,
  Chip as MuiChip,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import FilterPopover from './Filters'
import Divider from '../../components/Divider'
import { formatToReadableNumber } from '../../utils/format'

const Chip = styled(MuiChip)`
  max-width: 100%;
  ${(props) =>
    props.background &&
    css`
      background: ${(props) => props.background};
    `}
  ${(props) =>
    props.color &&
    css`
      color: ${(props) => props.color};
    `}
`

const GroupWrapper = styled.div`
  padding: 2px;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  //background: ${(props) => props.color};
  display: flex;
  ${(props) =>
    props.variant === 'initial' &&
    css`
      flex: 1 0 auto;
    `}
  ${(props) =>
    props.variant === 'set' &&
    css`
      flex: 0;
    `}
`

const List = styled(MuiList)`
  overflow-y: auto;
  max-height: 55vh;
`

const AutocompleteWrapper = styled.div`
  flex: 1 0 auto;
  min-width: 3em;
  box-sizing: border-box;
`

const ListIcon = styled(ListItemIcon)`
  min-width: 10px;
`

const ChipWrapper = ({
  children,
  value,
  variant,
  refNode,
  onClick,
  onDelete,
  color,
  background,
}) => {
  if (!value) {
    return <AutocompleteWrapper ref={refNode}>{children}</AutocompleteWrapper>
  }

  console.log(value, color, background)
  return (
    <Chip
      label={value}
      onClick={onClick}
      onDelete={onDelete}
      clickable
      color={color}
      background={background}
    />
  )
}

ChipWrapper.defaultProps = {
  onClick: () => {},
}

export class Filter extends React.Component {
  node = null

  typingTimeout = null

  constructor(props) {
    super(props)
    this.state = { anchorEl: null, value: props.filter.value || '' }
  }

  onInput = ({ target: { value } }) => {
    const { value: stateValue } = this.state
    if (stateValue !== value) {
      this.setState({ value, anchorEl: this.node })
    }

    clearTimeout(this.typingTimeout)

    this.typingTimeout = setTimeout(() => {
      if (!value && !!stateValue) {
        this.props.onDelete(true)
      }
    }, 1000)
  }

  onOpen = () => {
    this.setState({ anchorEl: this.node })
  }

  onClose = (e) => {
    if (e && e.target && e.target !== this.node) {
      this.setState({ anchorEl: null })
    } else if (!e) {
      this.setState({ anchorEl: null })
    }
  }

  onSelect = ({ id, ...rest }) => {
    const { value } = this.state
    this.props.onChange({ id, ...rest }, value)
    this.onClose()
  }

  onShowAll = () => {
    this.props.onShowAll()
    this.onClose()
  }

  get options() {
    const { options } = this.props
    const { value } = this.state
    return (!value ? options : [...options]).filter(({ label }) => {
      return /* value ? label.toLowerCase().indexOf(value.toLowerCase()) >= 0 : */ true
    })
  }

  render() {
    const { index, placeholder, onDelete, disablePopover } = this.props
    const { value, anchorEl } = this.state

    return (
      <>
        <Input
          inputRef={(node) => {
            this.node = node
          }}
          autoFocus={index > 0}
          disableUnderline
          fullWidth
          style={{ fontSize: 14 }}
          defaultValue={value}
          placeholder={placeholder}
          onClick={this.onOpen}
          onFocus={this.onOpen}
          onKeyUp={this.onInput}
          onKeyDown={(ev) => {
            if (!ev.target.value && 'Backspace' === ev.key) {
              onDelete()
            } else if (ev.target.value && 'Enter' === ev.key) {
              this.onSelect(this.options[0])
            }
          }}
        />
        <ClickAwayListener onClickAway={this.onClose}>
          <Popper
            disablePortal
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement={'bottom-start'}
            transition
            style={{ zIndex: 10 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper style={{ minWidth: 200 }} elevation={3}>
                  <List dense>
                    <ListItem
                      button
                      onClick={this.onSelect.bind(this, this.options[0])}
                    >
                      <ListIcon>
                        <Search /> &nbsp;
                      </ListIcon>
                      <ListItemText
                        secondary={
                          value.length > 0
                            ? 'Search for this text'
                            : 'Type to search for text'
                        }
                      />
                    </ListItem>
                  </List>
                  <Divider />
                  <List
                    style={{ maxHeight: '55vh', overflowY: 'auto' }}
                    dense
                    component='nav'
                    aria-labelledby='nested-list-subheader'
                    subheader={
                      <ListSubheader
                        disableSticky
                        component='div'
                        id='nested-list-subheader'
                        style={{ color: '#999999' }}
                      >
                        Filters
                      </ListSubheader>
                    }
                  >
                    {this.options
                      .filter(({ hidden }) => !hidden)
                      .map(
                        ({ id, label, disabled, hidden, ...rest }, index) =>
                          index > 0 && (
                            <ListItem
                              button
                              disabled={disabled}
                              onClick={this.onSelect.bind(this, {
                                id,
                                label,
                                ...rest,
                              })}
                            >
                              <ListItemText primary={label} />
                            </ListItem>
                          )
                      )}
                    {this.props.variant !== 'profile' && !disablePopover && (
                      <>
                        <Divider />
                        <ListItem button onClick={this.onShowAll}>
                          <ListItemText
                            primary={
                              <span style={{ color: '#0056CD' }}>
                                Show all filters
                              </span>
                            }
                          />
                        </ListItem>
                      </>
                    )}
                  </List>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      </>
    )
  }
}

class FilterGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      filter: props.filter,
      edit: false,
      search: '',
      searchFilter: null,
    }
  }

  node = null

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.filter.value !== prevProps.filter.value) {
      this.setState({ filter: this.props.filter })
    }
  }

  onChange = (key) => (value, search) => {
    const { filter } = this.state

    if (filter.id === 'search') {
      this.setState({ searchFilter: filter })
    }

    if (key === 'key') {
      const { id } = value
      if (value.kind === 'search') {
        this.setState(
          { filter: { ...filter, id, [key]: value, value: search } },
          this.onApply
        )
      } else if (value.kind === 'bool') {
        this.setState(
          { filter: { ...filter, id, [key]: value, value: true } },
          this.onApply
        )
      } else {
        this.setState({
          anchorEl: this.node,
          edit: true,
          filter: { ...filter, id, [key]: value, value: null },
        })
      }
    } else if (key === 'value') {
      this.setState({ filter: { ...filter, [key]: value } })
    }
  }

  onDelete = (replace) => {
    this.setState({ anchorEl: null, edit: false })
    this.props.onDelete(this.props.index, replace)
  }

  onEdit = () => {
    this.setState({ edit: true, anchorEl: this.node })
  }

  onApply = () => {
    const { searchFilter } = this.state
    this.props.onChange(this.props.index, this.state.filter, searchFilter)
    this.setState({ searchFilter: null })
    this.onClose()
  }

  onCancel = () => {
    if (!this.state.edit) {
      this.onDelete()
    } else {
      this.setState({ filter: this.props.filter })
    }
    this.onClose()
  }

  onClose = () => {
    this.setState({ anchorEl: null, edit: false })
  }

  get chipValue() {
    const {
      filter: { key, value },
    } = this.state
    if (key && key.kind !== 'search') {
      const { options, kind, label } = key
      const hasValue = kind !== 'bool' && value
      let chipValue = ''
      if (hasValue) {
        if (kind === 'range') {
          chipValue = `${formatToReadableNumber(
            value[0]
          )} - ${formatToReadableNumber(value[1])}`
        } else if (kind === 'select') {
          chipValue = `${find(options, ['value', value]).label}`
        } else if (kind === 'multiselect') {
          chipValue = value.map(({ label }) => label).join(', ')
        } else if (kind === 'autocomplete') {
          chipValue = value.label
        } else if (kind === 'tags') {
          chipValue = value.map(({ label }) => label).join(', ')
        } else {
          chipValue = value
        }
      }
      return `${label}${hasValue ? ` : ${chipValue}` : ''}`
    }

    return null
  }

  render() {
    const { index, placeholder, keyOptions, disablePopover } = this.props
    const { anchorEl, filter } = this.state
    const fullWidth = index === 0 && !filter.key

    const canDelete = filter.key && !!filter.value
    const canEdit = filter.key && !!filter.value && filter.value.kind !== 'bool'
    const notEditable = filter.key && filter.key.notEditable

    const { key } = filter || {}
    const { color, background } = key || {}

    return (
      <GroupWrapper
        ref={(node) => (this.node = node)}
        fullWidth={fullWidth}
        variant={
          filter.key && filter.value && filter.key.kind !== 'search'
            ? 'set'
            : 'initial'
        }
      >
        <ChipWrapper
          value={this.chipValue}
          variant={'end'}
          kind={'label'}
          color={color}
          background={background}
          onClick={canEdit && !notEditable ? this.onEdit : null}
          onDelete={canDelete ? this.onDelete : null}
        >
          <Filter
            disablePopover={disablePopover}
            variant={this.props.variant}
            index={index}
            filter={filter}
            options={keyOptions}
            onChange={this.onChange('key')}
            onDelete={this.onDelete}
            placeholder={fullWidth && placeholder}
            onShowAll={this.props.onShowAll}
          />
        </ChipWrapper>

        {filter.key &&
          filter.key.kind !== 'bool' &&
          filter.key.kind !== 'search' && (
            <FilterPopover
              anchorEl={anchorEl}
              value={filter.value}
              placeholder={placeholder}
              selected={filter.key}
              onApply={this.onApply}
              onCancel={this.onCancel}
              onChange={this.onChange('value')}
            />
          )}
      </GroupWrapper>
    )
  }
}

export default FilterGroup
