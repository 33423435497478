import { observable, action, computed, toJS } from 'mobx'
import param from 'can-param'
import UniversalCookie from 'universal-cookie'
import { isEmpty, unionBy } from 'lodash'

import GqlClient from '../gql/gql-client'
import {
  channelsTags,
  channelsContactStatus,
  channelsSystemTags,
} from '../gql/channels'
import { prepareEdges } from '../utils/gql-data'
import { Post, Put } from '../utils/request'
import hasError from './request-message'
import Config from '../config'
import { leadToListItem } from '../utils/list-items'
import { getSorting } from '../utils/sorting'
import {
  GET_LEADS,
  MARK_LEADS_AS_PARTNERS,
  MARK_LEADS_AS_RELEVANTS,
  UNMARK_LEADS_AS_PARTNERS,
  UNMARK_LEADS_AS_RELEVANTS,
  MOVE_LEADS_TO_INBOX,
  ADD_LEADS_TO_COMPETITORS,
  ADD_LEADS_TO_IRRELEVANTS,
  GET_LEAD_IDS,
} from '../gql/leads'
import { REQUEST_CONTACTS } from '../gql/contacts'
import { MARK_CHANNEL_WITH_TAG, REMOVE_TAG_FROM_CHANNELS } from '../gql/tags'
import { getFilterVariables } from '../configFilters'
import { capitalize } from '../utils/string'
import { createCSV, download } from '../utils/download-blod'

const Cookies = new UniversalCookie()

const removeFilters = (searchId, kind) => {
  return Cookies.remove(`${searchId}${kind}Filters`, { path: '/' })
}

const readFilters = (searchId, kind) => {
  return Cookies.get(`${searchId}${kind}Filters`, { path: '/' })
}

const saveFilters = (searchId, kind, filters) => {
  Cookies.set(`${searchId}${kind}Filters`, JSON.stringify(filters), {
    path: '/',
  })
}

const SPECIAL_LISTS = ['competitors', 'irrelevant']

const getToggleRequest = (actionKind, kind) => {
  if (actionKind === 'mark') {
    return kind === 'relevants'
      ? MARK_LEADS_AS_RELEVANTS
      : MARK_LEADS_AS_PARTNERS
  }
  return kind === 'relevants'
    ? UNMARK_LEADS_AS_RELEVANTS
    : UNMARK_LEADS_AS_PARTNERS
}

const kinds = {
  competitors: 'competitor',
  irrelevant: 'irrelevant',
}

class LeadsStore {
  @observable searchId
  @observable brandId
  @observable listId
  @observable kind
  @observable page = 1
  @observable perPage = Config.PER_PAGE
  @observable total = 0
  @observable orderBy = 'breezyScore'
  @observable order = 'desc'
  @observable leads = []
  @observable _filters = { type1Classification: 'all' }
  @observable loading = true
  @observable thinking = false

  @observable withoutProspects = false
  @observable withoutFavourites = false
  @observable withoutListed = false
  @observable withoutRelevant = false
  @observable withNotes = false
  @observable partnersOnly = false
  @observable prospectsOnly = false
  @observable competitorsOnly = false
  @observable irrelevantOnly = false

  allLeads = []

  constructor(kind) {
    this.kind = kind
  }

  setFilters() {
    const savedFilters = readFilters(this.searchId, this.kind)
    this._filters = {
      ...(this.kind === 'leads-list' && { list: this.listId }),
      ...savedFilters,
    }
    this.withoutListed = this.kind === 'report'
    this.withNotes = this.kind === 'with-notes'
    this.partnersOnly = this.kind === 'partners'
    this.prospectsOnly = this.kind === 'relevants'
  }

  @action
  toggleWithoutRelevant() {
    this.withoutRelevant = !this.withoutRelevant
    this.load().then()
  }

  @action
  async setProductId(searchId) {
    this.searchId = searchId || this.searchId
    if (this.listId) {
      this._filters = {
        ...this._filters,
        list: this.listId,
      }
      await this.load()
    }
  }

  @action
  async setListId(listId) {
    this.listId = listId || this.listId
    this.loading = true
    if (this.searchId) {
      this._filters = {
        ...this._filters,
        list: this.listId,
      }
      await this.load()
    }
  }

  @action
  setInitial() {
    removeFilters(this.searchId, this.kind)
    this._filters = {
      ...(this.kind === 'leads-list' && { list: this.listId }),
    }
    this.searchId = null
    this.page = 1
    this.total = 0
    this.orderBy = 'breezyScore'
    this.order = 'desc'
    this.leads = []
    this.withoutFavourites = false
    // this.partnersOnly = false
    // this.prospectsOnly = false

    this.loading = true
    this.thinking = false
  }

  @computed
  get specialList() {
    return SPECIAL_LISTS.includes(this.kind) ? `/special-list/${this.kind}` : ''
  }

  @computed
  get pagination() {
    const page = toJS(this.page) - 1 || 0
    const perPage = toJS(this.perPage)
    const take = perPage
    const skip = page * perPage

    return {
      take,
      skip,
    }
  }

  @action
  async load(searchId, brandId, noLoading, listId, initialFilters, force) {
    this.thinking = true
    this.searchId = searchId || this.searchId
    this.brandId = brandId || this.brandId
    this.listId = listId || this.listId

    this.setFilters(this.searchId, this.kind)

    this._filters = isEmpty() ? this._filters : initialFilters

    const specialList = this.kind !== 'report'

    const variables = {
      ...this.pagination,
      searchId: this.searchId,
      ...(this.orderBy && { orderBy: getSorting(this.order, this.orderBy) }),
      ...getFilterVariables({
        ...toJS(this._filters),
        ...(this.withoutRelevant && { withoutRelevant: this.withoutRelevant }),
      }),
      ...(specialList && { list: `!${kinds[this.kind]}` }),
    }

    const { success, ...rest } = await GqlClient.query({
      query: GET_LEADS,
      variables,
    })

    if (!hasError(success, 'message')) {
      const { totalCount, edges } = rest.data['leadsConnection']
      let leads = prepareEdges(edges).map(({ leadId, channel, ...lead }) => ({
        id: leadId,
        leadId,
        channel,
        ...lead,
        channelId: channel.id,
      }))

      leads = await channelsTags(leads, this.brandId)
      leads = await channelsContactStatus(leads, this.brandId)
      leads = await channelsSystemTags(leads)

      this.leads =
        this.page === 1 || force ? leads : [...toJS(this.leads), ...leads]
      this.total = this.page === 1 ? totalCount : this.total
    }

    this.loading = false
    this.thinking = false
  }

  @action
  async exportToCSV() {
    const specialList = this.kind !== 'report'
    const variables = {
      skip: 0,
      take: this.total,
      searchId: this.searchId,
      ...(this.orderBy && { orderBy: getSorting(this.order, this.orderBy) }),
      ...getFilterVariables({
        ...toJS(this._filters),
        ...(this.withoutRelevant && { withoutRelevant: this.withoutRelevant }),
      }),
      ...(specialList && { list: `!${kinds[this.kind]}` }),
    }

    const { success, ...rest } = await GqlClient.query({
      query: GET_LEADS,
      variables,
    })

    if (!hasError(success, 'message')) {
      const { edges } = rest.data['leadsConnection']
      let leads = prepareEdges(edges).map(({ leadId, channel, ...lead }) => ({
        id: leadId,
        leadId,
        channel,
        ...lead,
        channelId: channel.id,
      }))

      leads = await channelsTags(leads, this.brandId)
      leads = await channelsContactStatus(leads, this.brandId)
      leads = leads.map(leadToListItem).filter((l) => Boolean(l))

      download(createCSV(leads, 'leads'), `${this.kind}.csv`)
    }
  }
  @action
  async loadIds() {
    const specialList = this.kind !== 'report'

    const variables = {
      take: this.total,
      searchId: this.searchId,
      ...(this.orderBy && { orderBy: getSorting(this.order, this.orderBy) }),
      ...getFilterVariables({
        ...toJS(this._filters),
        ...(this.withoutRelevant && { withoutRelevant: this.withoutRelevant }),
      }),
      ...(specialList && { list: `!${kinds[this.kind]}` }),
    }

    const { success, ...rest } = await GqlClient.query({
      query: GET_LEAD_IDS,
      variables,
    })

    if (!hasError(success, 'message')) {
      const { edges } = rest.data['leadsConnection']

      this.allLeads = prepareEdges(edges)
      return toJS(this.allLeads).map(({ leadId }) => leadId)
    }

    return []
  }

  @action
  async nextPage() {
    this.page = this.page + 1
    await this.load()
  }

  @action
  async pageChange(page) {
    if (
      toJS(this.leads).length < this.total &&
      !this.loading &&
      !this.thinking
    ) {
      this.page = this.page + 1
      await this.load(null, null, true)
    }
  }

  @action
  async perPageChange(perPage) {
    this.page = 1
    this.perPage = perPage
    await this.load()
  }

  @action
  async filtersChange(filters) {
    saveFilters(this.searchId, this.kind, filters)
    if (!filters.search && toJS(this._filters).search) {
      this.order = this.order || 'desc'
      this.orderBy = this.orderBy || 'breezyScore'
    } else if (toJS(this._filters).search !== filters.search) {
      this.order = undefined
      this.orderBy = undefined
    }
    this.page = 1
    this._filters = {
      ...(this.kind === 'leads-list' && { list: this.listId }),
      ...filters,
    }
    await this.load()
  }

  @action
  async toggleShowFavorites() {
    this.withoutFavourites = !this.withoutFavourites
    await this.load()
  }

  @action
  markAsPartner(ids, direction) {
    this.leads = toJS(this.leads).map(({ id, favourite, ...other }) => {
      return {
        id,
        favourite: direction === 'add' ? false : favourite,
        ...other,
        ...(ids.indexOf(id) >= 0 && { partner: direction === 'add' }),
      }
    })
  }

  @action
  async sorting(key, direction, callback, reject) {
    if ((this.order !== direction || this.orderBy !== key) && !reject()) {
      this.order = direction
      this.orderBy = key
      this.page = 1

      if (callback) {
        callback()
      }
      await this.load(null, null, true)
    }
  }

  @action
  async toggleFavorite(ids, direction, kind) {
    return { success: true }
  }

  @action
  async toggleToPartners(kind, kindView, actionKind, ids) {
    this.leads = toJS(this.leads).map(({ id, ...rest }) => {
      if (ids.includes(id)) {
        if (kind === 'relevants') {
          if (actionKind === 'mark') {
            rest.relevant = Math.random()
            rest.internalRelevant = true
          } else {
            delete rest.internalRelevant
          }
        } else {
          if (actionKind === 'mark') {
            rest.partner = Math.random()
          } else {
            delete rest.partner
          }
        }
      }
      return { id, ...rest }
    })

    const variables = {
      leadIds: ids,
      searchId: this.searchId,
    }

    const mutation = getToggleRequest(actionKind, kind)

    await GqlClient.mutation({ mutation, variables })

    hasError(true, null, `Successfully added to ${kind}`)
    // await Put(`/api/discovery/leads/${toggleAction}`, { ids, product: this.searchId })
  }

  isAllSelected(ids) {
    if (ids.length <= toJS(this.leads).length) {
      return false
    }
    return this.total === ids.length
  }

  specialListToggleDetails(ids) {
    const leads = this.isAllSelected(ids)
      ? toJS(this.allLeads)
      : toJS(this.leads)
    const leadIds = leads
      .filter(({ id, leadId, fake }) => ids.includes(leadId) && !fake)
      .map(({ leadId }) => leadId)
    // const links = leads
    //   .filter(({ leadId, fake }) => ids.includes(leadId) && !fake)
    //   .map(({ domain }) => domain)

    return {
      searchId: this.searchId,
      leadIds,
    }
  }

  @action
  async removeFromSpecialList(ids) {
    const { links, ...variables } = this.specialListToggleDetails(ids)

    const { success } = await GqlClient.mutation({
      mutation: MOVE_LEADS_TO_INBOX,
      variables,
    })

    if (!hasError(success, 'message', `Removed from ${this.kind} list`)) {
      this.removeItemsFromList(ids)
    }
    return success
  }

  @action
  async toCompetitors(ids) {
    const variables = this.specialListToggleDetails(ids)

    const { success } = await GqlClient.mutation({
      mutation: ADD_LEADS_TO_COMPETITORS,
      variables,
    })

    if (!hasError(success, 'message', 'Successfully added to competitors')) {
      this.removeItemsFromList(ids)
    }
    return success
  }

  @action
  async toBlocklist(ids) {
    console.log('Store', ids)
    const variables = this.specialListToggleDetails(ids)

    const { success } = await GqlClient.mutation({
      mutation: ADD_LEADS_TO_IRRELEVANTS,
      variables,
    })

    if (!hasError(success, 'message', 'Successfully added to irrelevants')) {
      this.removeItemsFromList(ids)
    }
    return success
  }

  @action
  removeItemsFromList(ids) {
    this.leads = toJS(this.leads).filter(({ id }) => ids.indexOf(id) < 0)
  }

  @action
  updateNotesCount(prospectId, count) {
    this.leads = toJS(this.leads).map(({ id, ...rest }) => ({
      id,
      ...rest,
      ...(id === prospectId && { notesCount: count }),
    }))
  }

  @action
  async addTags(leads, tags) {
    let channelIds = []
    this.leads = toJS(this.leads).map((lead) => {
      if (leads.includes(lead.id)) {
        lead.tags = lead.tags || []
        lead.tags = unionBy(lead.tags, tags, 'id')
        channelIds.push(lead.channelId)
      }
      return lead
    })

    const [tag] = tags

    if (this.total === leads.length) {
      channelIds = toJS(this.allLeads).map(({ channel }) => channel.id)
    }

    const payload = {
      id: tag.id, //TAG ID
      brandId: this.brandId,
      channelIds,
    }

    return await GqlClient.mutation({
      mutation: MARK_CHANNEL_WITH_TAG,
      variables: { MarkChannelsWithTagInput: payload },
    })
  }

  @action
  async removeTags(leads, tagIds) {
    let channelIds = []
    this.leads = toJS(this.leads).map((lead) => {
      if (leads.includes(lead.id)) {
        lead.tags = (lead.tags || []).filter(({ id }) => !tagIds.includes(id))
        channelIds.push(lead.channelId)
      }
      return lead
    })

    const [tagId] = tagIds

    if (this.total === leads.length) {
      channelIds = toJS(this.allLeads).map(({ channel }) => channel.id)
    }

    const payload = {
      id: tagId, //TAG ID
      brandId: this.brandId,
      channelIds,
    }

    return await GqlClient.mutation({
      mutation: REMOVE_TAG_FROM_CHANNELS,
      variables: { RemoveTagFromChannelsInput: payload },
    })
  }

  @action
  async updateTags(tag) {
    this.leads = toJS(this.leads).map((lead) => {
      let tags = (lead.tags || []).map((t) => {
        if (t.id === tag.id) {
          return tag
        }
        return t
      })
      return { ...lead, tags }
    })
  }

  @action
  async requestLeadContacts(leadId, outreachMessage) {
    return await this.requestLeadsContacts([leadId], outreachMessage)
  }

  @action
  async requestLeadsContacts(leadIds, outreachMessage) {
    const channelIds =
      leadIds.length === this.total
        ? toJS(this.allLeads).map(({ channel }) => channel.id)
        : toJS(this.leads)
            .filter(
              ({ id, contactStatus }) => !contactStatus && leadIds.includes(id)
            )
            .map(({ channelId }) => channelId)

    const payload = {
      brandId: this.brandId,
      channelIds,
      ...(outreachMessage && { outreachMessage }),
    }

    const { success } = await GqlClient.mutation({
      mutation: REQUEST_CONTACTS,
      variables: { RequestContactsInput: payload },
    })

    if (
      !hasError(success, 'message', 'Contacts have been successfully requested')
    ) {
      this.leads = toJS(this.leads).map((lead) => {
        if (channelIds.indexOf(lead.channelId) >= 0) {
          return { ...lead, contactStatus: 'requested' }
        }
        return lead
      })
    }

    return { success }
  }

  @computed
  get exportCSVUrl() {
    return `/api/discovery/leads/csv?product=${this.searchId}&${this.url}`
  }

  @computed
  get filtersToQuery() {
    let filters = {}
    for (let key in this._filters) {
      if (this._filters.hasOwnProperty(key)) {
        if (key === 'lang' && !!this._filters.lang) {
          filters.lang = this._filters.lang.value
        } else if (
          key === 'type1Classification' &&
          !!this._filters.type1Classification
        ) {
          filters.type1Classification = this._filters.type1Classification
        } else if (key === 'swVisits') {
          filters.swVisitsStart = this._filters[key][0]
          filters.swVisitsEnd = this._filters[key][1]
        } else if (key === 'swRank') {
          filters.swRankStart = this._filters[key][0]
          filters.swRankEnd = this._filters[key][1]
        } else if (key === 'breezyScore') {
          filters.breezyScoreStart = this._filters[key][0]
          filters.breezyScoreEnd = this._filters[key][1]
        } else if (key === 'domainAuthority') {
          filters.domainAuthorityStart = this._filters[key][0]
          filters.domainAuthorityEnd = this._filters[key][1]
        } else if (key === 'competitorSeedsCount') {
          filters.competitorSeedsCountStart = this._filters[key][0]
          filters.competitorSeedsCountEnd = this._filters[key][1]
        } else if (key === 'keywordSeedsCount') {
          filters.keywordSeedsCountStart = this._filters[key][0]
          filters.keywordSeedsCountEnd = this._filters[key][1]
        } else if (Array.isArray(this._filters[key])) {
          filters[key] = this._filters[key].map(({ id, value }) =>
            key === 'byTags' ? id : value
          )
        } else {
          filters[key] = this._filters[key]
        }
      }
    }

    return {
      ...filters,
      ...(this.withoutRelevant && { withoutRelevant: true }),
      ...(this.withoutProspects && { withoutProspects: true }),
      ...(this.withoutListed && { withoutListed: true }),
      ...(this.withNotes && { withNotes: true }),
      ...(this.partnersOnly && { partnersOnly: true }),
      ...(this.prospectsOnly && { prospectsOnly: true }),
      ...(this.competitorsOnly && { competitorsOnly: true }),
      ...(this.irrelevantOnly && { irrelevantOnly: true }),
    }
  }

  @computed
  get url() {
    const { page, perPage, orderBy, order } = this
    const { type1Classification, ...filters } = this.filtersToQuery

    let sort = `${order === 'asc' ? '' : '-'}${orderBy}`

    if (orderBy === 'swRank') {
      sort = order === 'desc' ? '-swRankOp' : 'swRank'
    }

    return param({
      page,
      perPage,
      sort,
      filters: {
        ...filters,
        ...(type1Classification &&
          type1Classification !== 'all' && { type1Classification }),
      },
    })
  }

  @computed
  get listProps() {
    const { loading, thinking, leads, page, perPage, total, kind } = this
    return {
      kind,
      thinking,
      loading,
      items: toJS(leads).map(leadToListItem),
      total,
      page,
      perPage,
    }
  }

  @computed
  get sortProps() {
    const { orderBy, order } = this
    return { orderBy, order }
  }

  @computed
  get filters() {
    const { list, type1Classification, ...rest } = this._filters
    return {
      ...(type1Classification &&
        type1Classification !== 'all' && { type1Classification }),
      ...rest,
    }
  }

  @computed
  get hasFilters() {
    const existingFilterKeys = [
      'lang',
      'tlds',
      'byCountries',
      'search',
      'type1Classification',
      'byCompetitors',
      'byTags',
      'competitors',
      'keywords',
      'swRank',
      'breezyScore',
      'domainAuthority',
      'competitorSeedsCount',
      'keywordSeedsCount',
      'type1',
      'type2',
      'type3',
    ]

    const filters = toJS(this.filters)
    let hasFilters = false

    existingFilterKeys.forEach((key) => {
      if (!hasFilters && filters.hasOwnProperty(key)) {
        if (
          key === 'type1Classification' &&
          filters['type1Classification'] !== 'all'
        ) {
          hasFilters = true
        } else if (key !== 'type1Classification') {
          hasFilters = true
        }
      }
    })

    return hasFilters
  }

  @computed
  get geoFilter() {
    const filters = toJS(this._filters)

    if (filters.hasOwnProperty('byCountries')) {
      return filters.byCountries.map(({ value }) => value)
    }

    return undefined
  }
}

export default LeadsStore
