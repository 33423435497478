import React from 'react'
import {Folder, FolderPlus} from 'react-feather'
import { Menu, MenuItem, ListItemIcon as ListItemIconMui, Divider } from '@material-ui/core'
import styled from 'styled-components'

const ListItemIcon = styled(ListItemIconMui)`
  min-width: 30px;
`

const FoldersMenu = ({ folders, anchorEl, onClose, ...rest }) => {
  const onClick = (id, name) => e => {
    rest.onClick(id, name)
    // onClose()
  }

  return (
    <Menu
      style={{maxHeight: 350}}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={onClick()}>
        <ListItemIcon>
          <FolderPlus size={16} />
        </ListItemIcon>
        Move to a new folder
      </MenuItem>
      <Divider />
      {
        folders.map(({ id, name }) => (
          <MenuItem onClick={onClick(id, name)}>
            <ListItemIcon>
              <Folder size={16} />
            </ListItemIcon>
            {name}
          </MenuItem>
        ))
      }
    </Menu>
  )

}

export default FoldersMenu
