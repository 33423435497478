import { Get, Post, Delete, Put } from '../utils/request'
import { action, computed, observable, toJS } from 'mobx'
import hasError from './request-message'
import GqlClient from '../gql/gql-client'
import { GET_ACCOUNT_USERS, INVITE_ACCOUNT_USER, RE_INVITE_ACCOUNT_USER } from '../gql/members'
import { UPDATE_BRAND } from '../gql/brands'

const STATUSES = {
  joined: 'Joined',
  invited: 'Invited'
}

class MembersStore {
  brandId = null

  @observable users = []

  @observable loading = true
  @observable thinking = false

  constructor (brandId) {
    this.brandId = brandId
    this.load().then()
  }

  @action
  async load () {
    const { success, ...rest } = await GqlClient.query({ query: GET_ACCOUNT_USERS, variables: { brandId: this.brandId } })

    if (success) {
      this.users = rest.data['accountUsers']
    }

    this.loading = false
  }

  @action
  async invite (email) {
    this.thinking = true

    const payload =  { email, brandId: this.brandId }

    const { success,  ...rest } = await GqlClient.mutation({ mutation: INVITE_ACCOUNT_USER, variables: { InviteUserInput: payload } })

    if (!hasError(success, '', `Invitation sent successfully`)) {
      const user = rest.data['inviteUser']
      this.users = [...toJS(this.users), user]
    }

    this.thinking = false

    return { success, message: null }
  }

  @action
  async reInvite (id) {
    this.thinking = true

    const payload =  { id, brandId: this.brandId }

    const { success,  ...rest } = await GqlClient.mutation({ mutation: RE_INVITE_ACCOUNT_USER, variables: payload })

    hasError(success, 'message', `Invitation sent successfully`)

    this.thinking = false

    return { success, message: '' }
  }

  @action
  async remove (id) {
    this.thinking = true
    this.users = toJS(this.users).filter(m => m.id !== id)
    const { success, message } = await Delete(`/api/discovery/account-users/${id}`, {})
    hasError(success, message, `Team member successfully removed`)
    this.load().then()
    this.thinking = false
  }

  @computed
  get members () {
    return toJS(this.users).map(({ id, user, role, email, status }) => ({
      role: role ? 'Owner' : 'User',
      status: STATUSES[status],
      email: status === 'joined' ? (user || {}).email || email : email,
      user,
      id
    }))
  }
}

export default MembersStore
