import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {
  FormControlLabel as MuiFormControl,
  FormHelperText as MuiHelperText,
  Checkbox as MuiCheckbox,
  Grid,
} from '@material-ui/core'
import {Form, Input} from '../Form'
import Button from '../Button'
import Validator from '../../utils/validator'

const FORM_ID = 'signin-form'

const ActionsHolder = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
`
const GridInput = styled(Grid)`
  margin: 0 auto 24px auto;
  width: 100%;
`

const FormControlLabel = styled(MuiFormControl)`
  margin: 0;
  align-items: self-start;
  .MuiFormControlLabel-label {
    color: #3E3E3E;
    font-size: 14px;
    line-height: 16px;
  }
`
const FormHelperText = styled(MuiHelperText)`
  margin-left: 40px;
  align-items: self-start;
  color: #ca392e;
`

const Checkbox = styled(MuiCheckbox)`
  padding: 0;
  margin: 0 16px 0 0;
  position: relative;
  top: -0.15em;
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #0163FA;
  }
`

const LinksContainer = styled.div`
  padding-top: 1em;
  text-align: center;
  a {
    font-size: 14px;
    font-weight: bold;
  }
`

const schema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  },
  {
    //N)qarPj7C§}bnhJ&
    field: 'password',
    rules: 'required|minLength[8]|doesNotContainWhitespace',
    messages: {
      required: 'Password is required',
      minLength: 'Password length must be minimum 8 characters',
      containsLetterAndNumber: 'Password should contain at least one number and one letter',
      doesNotContainWhitespace: 'Password should not contain spaces (or other whitespace characters)'
    }
  }
]

export default class SignUpForm extends React.Component {
  // Первый. consent = true
  // Второй. receiveNotifications = true
  constructor(props) {
    super(props)
    const {email, password} = this.props
    this.state = {email, password, consent: false, receiveNotifications: true, errors: {}, error: null}
  }

  onChange = name => ({target: {value, checked}}) => {
    this.setState({[name]: ['receiveNotifications', 'consent'].includes(name) ? checked : value})
    this.resetError(name)
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      async () => {
        const {email, password, consent, receiveNotifications} = this.state
        const { error } = await this.props.onSubmit({email, password, consent, receiveNotifications})

        if (error) {
          switch (error) {
            case 'emailExists':
              this.setState({errors: {email: 'User with given email already exist'}})
              break;
            case 'noEmail':
              this.setState({errors: {email: 'Email not found'}})
              break;
            case 'wrongPassword':
              this.setState({errors: {password: 'Your password is not correct'}})
              break;
            default:
              this.setState({errors: {email: error}})
          }
        }
      })
  }

  onSignIn = (e) => {
    if (this.props.onSignIn) {
      e.preventDefault()
      this.props.onSignIn()
    }
  }

  render() {
    const {
      email,
      password,
      errors,
      consent,
      receiveNotifications
    } = this.state
    const {
      header,
      subHeader,
      thinking,
      error
    } = this.props

    const Actions = () => <ActionsHolder>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{maxWidth: 300, width: '100%'}}
        disabled={thinking || !consent}
      >
        Continue
      </Button>
      <LinksContainer>
        <p>
          <Link to={'/signin'} onClick={this.onSignIn}>Sign in</Link> if you have an account.
        </p>
      </LinksContainer>
    </ActionsHolder>

    return (
      <Form
        noValidate
        header={header}
        subHeader={subHeader}
        formId={FORM_ID}
        onSubmit={this.onSubmit}
        actions={Actions}
        error={error}
        thinking={thinking}
      >
        <Grid container>
          <GridInput item xs={12}>
            <Input
              id={'email'}
              type={'email'}
              margin="none"
              required
              fullWidth
              placeholder="e.g. joe.bloggs@example.com"
              fieldType='clearable'
              label={'Email'}
              inputLabel={'Email'}
              variant={'outlined'}
              value={email}
              error={errors.email}
              disabled={thinking}
              onChange={this.onChange('email')}
            />
          </GridInput>
          <GridInput item xs={12}>
            <Input
              id={'password'}
              type={'password'}
              margin="none"
              required
              fullWidth
              placeholder="Password"
              fieldType='clearable'
              label={'Password'}
              inputLabel={'Password'}
              variant={'outlined'}
              value={password}
              error={errors.password}
              disabled={thinking}
              onChange={this.onChange('password')}
            />
          </GridInput>
          <GridInput item xs={12}>
            <FormControlLabel

              error={errors.receiveNotifications}
              control={<Checkbox id={'receiveNotifications'} color="primary" value={receiveNotifications} onChange={this.onChange('receiveNotifications')}/>}
              label="I agree to receive occasional product news, updates, and relevant content."
            />
            { errors.receiveNotifications && <FormHelperText>{errors.receiveNotifications}</FormHelperText> }
          </GridInput>
          <GridInput item xs={12}>
            <FormControlLabel
              required
              error={errors.consent}
              control={<Checkbox id={'consent'} color="primary" value={consent} onChange={this.onChange('consent')}/>}
              label={<>I accept and agree to Breezy’s <a target="_blank" href="https://breezy.io/website-terms">Website Terms of Use</a> and <a target="_blank" href='https://breezy.io/privacy'>Privacy Policy.</a></>}
            />
            { errors.consent && <FormHelperText>{errors.consent}</FormHelperText> }
          </GridInput>
        </Grid>
      </Form>
    )
  }
}
