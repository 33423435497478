import React from 'react'
import { Route, Switch, withRouter, matchPath } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import {IconButton, Tooltip} from '@material-ui/core'
import {Inbox, ThumbsUp, ThumbsDown, Zap, Users, BookOpen, CornerDownRight, Compass, Activity, Settings as IconSettings, FolderPlus} from 'react-feather'
import Discovery from '../Discovery'
import styled, {css} from 'styled-components'
import Name from '../../components/Discovery/DiscoveryName'
import { Discoveries } from '../Discoveries'
import {
  PartnersList as Partners,
  ProspectsList as Relevant,
  RelevantFolder
} from '../Prospects'
import Contacts from '../Contacts/Infos'
import Requests from '../Contacts/Requests'
import Intelligence from '../Intelligence'
import Partnerships from '../Partnerships'
import { ProductCreate as DiscoveryCreate } from '../../components/Product'
import { BrandMenu } from '../../components/Brand'
import { Settings } from '../../components/Settings'
import Layout from '../../new-components/Layout/Main'
import InputsBase from '../../new-components/Navigation/NavigationInputs'
import DashboardHeader from '../../new-components/Dashboard/DashboardHeader'
import { RelevantFolderList } from '../../new-components/Folder'
import IntroductionModal from '../../new-components/Modals/IntroductionModal'

import config from '../../config'
import { sendEvent } from '../../utils/events'

////////// Connected Modals
import RunDiscovery from '../../components/Modals/_RunDiscoveryModal'
import RequestContactsModal from '../../components/Modals/_RequestContactsModal'
import SubscriptionInactiveModal from '../../components/Modals/_SubscriptionInactiveModal'
import BookingDemoModal from '../../components/Modals/_BookingDemoModal'
import AddMoreCredits from '../../components/Modals/_AddMoreCreditsModal'
import AssistedLockModal from '../../new-components/Modals/AssistedLockModal'
import IntelligenceLockModal from '../../new-components/Modals/IntelligenceLockModal'

const Inputs = inject(({ SearchStore, BrandStore }) => ({
  inputsProps: SearchStore.inputsProps,
  researchStatus: SearchStore.researchStatus,
  firstResearchSeen: SearchStore.firstResearchSeen,
  onReRun: () => BrandStore.initReRun({ searchId: SearchStore.id, kind: 'product' }),
}))(InputsBase)

const Divider = styled.div`
  height: 1px;
  border-top: 1px solid rgba(0,0,0,0.05);
`

const EndIcon = styled.div`
  width: 14px;
  height: 14px;
  //border: 2px solid white;
  border-radius: 100%;
  position: relative;
  background: ${props => props.variant === 'partner'
          ? config.labelColors.partner
          : config.labelColors.prospect
  };
  ${props => props.variant === 'prospect' && css`
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: ${config.labelColors.overlap};
      left: -10px;
      z-index: -1;
    }
  `}
`

@inject(({ UserStore, BrandStore, BrandsStore, SearchStore, SearchesStore, RelevantFoldersStore, ProspectsStore }) => ({
  authorization: UserStore.checkAuthorization,

  id: BrandStore.id,
  flags: BrandStore.flags,
  isCompanyNotCreated: BrandStore.isCompanyNotCreated,
  loading: BrandStore.loading,
  details: BrandStore.details,
  counters: BrandStore.counters,
  credits: BrandStore.credits,
  plan: BrandStore.plan,
  billingStatus: BrandStore.billingStatus,
  inactive: BrandStore.inactive,
  firstResearchSeen: BrandStore.firstResearchSeen,
  role: BrandStore.role,
  assistedLock: BrandStore.assistedLock,
  openAssistedLock: BrandStore.openAssistedLock,
  intelligenceEnabled: BrandStore.intelligenceEnabled,
  load: (id, noLoading, callback) => BrandStore.load(id, noLoading, callback),
  updateCounters: (details) => BrandStore.updateCounters(details),
  onShowPlans: () => BrandStore.showPlansModal(),
  onUpdateFlags: (details) => BrandStore.updateFlags(details),
  onOpenAssistedLock: () => BrandStore.openAssistedLockModal(),

  brands: BrandsStore.brands,
  loadBands: () => BrandsStore.load(),

  productLoading: SearchStore.loading,
  searchId: SearchStore.id,
  totals: SearchStore.totals,
  productDetails: SearchStore.details,
  country: SearchStore.country,
  description: SearchStore.description,
  inputsProps: SearchStore.inputsProps,
  researchStatus: SearchStore.researchStatus,
  onUpdateProduct: details => SearchStore.update(details),
  onUnmountProduct: () => SearchStore.setInitial(),

  products: SearchesStore.products,

  folders: RelevantFoldersStore.folders,
  addNewRelevantFolder: () => RelevantFoldersStore.addNew(),
  onCreateDefaultFolders: () => RelevantFoldersStore.createDefault(),

  updateRelevantList: () => ProspectsStore.load()
}))
@observer
@withRouter
export default class _Brand extends React.Component {
  state = { settings: false, create: false, billing: false, openIntelligenceEnabledModal: false }

  componentDidMount () {
    const {
      match: { params: { brandId } },
      brands
    } = this.props

    if (!brandId) {
      const brand = brands[0]
      if (brand) {
        this.props.history.push({ pathname: `/brand/${brand.id}`})
      } else {
        this.props.history.push({ pathname: `/welcome/company`})
      }
    } else {
      this.props.load(brandId, false, ({ step, redirect }) => {
        if (step) {
          this.props.history.push({ pathname: `/welcome/${step}`})
        } else if (redirect) {
          window.location.href = '/brand'
        }
      })
      this.props.loadBands()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { match: { params: { brandId } } } = this.props
    const { match: { params: { brandId: prevBrandId } } } = prevProps

    if (brandId !== prevBrandId) {
      this.props.onUnmountProduct()
      this.props.load(brandId, false, ({ step, redirect }) => {
        if (step) {
          this.props.history.push({ pathname: `/welcome/${step}`})
        } else if (redirect) {
          window.location.href = '/brand'
        }
      })
    }
  }

  onOpenSettings = () => this.setState({ settings: true })
  onCloseSettings = () => this.setState({ settings: false })

  onOpenCreate = () => {
    if (!this.props.assistedLock) {
      this.setState({ create: true })
    } else {
      this.props.onOpenAssistedLock()
    }
    sendEvent(this.props.id, 'newSearchButton')
  }
  onCloseCreate = () => this.setState({ create: false })

  onOpenPurchase = () => {
    if (!this.props.assistedLock) {
      this.setState({ billing: true })
    } else {
      this.props.onOpenAssistedLock()
    }
    sendEvent(this.props.id, 'purchaseCreditsButton')
  }
  onClosePurchase = () => this.setState({ billing: false })

  onCreateRelevantFolders = () => {
    this.props.onCreateDefaultFolders()
    this.props.onUpdateFlags({ relevantFoldersCreated: true })
  }

  get isDiscoveryLevel () {
    const { location: { pathname } } = this.props
    return pathname.indexOf(`/discovery/`) > -1
  }

  get discoveryNavigation () {
    const {
      plan,
      searchId,
      productLoading: loading,
      totals: { leads, competitors, irrelevant, prospects },
      match: { url: path },
      location: { pathname },
      inputsProps,
      description
    } = this.props

    if (pathname.includes(`${path}/discovery`)) {
      const onSettingsClick = e => {
        //Disable Assisted Modal
        // if (this.props.assistedLock) {
        //   e.preventDefault()
        //   this.props.onOpenAssistedLock()
        // }
      }

      return [
        {
          id: 'discoveryName',
          component: (props) =>  <Name disabled={props.disabled} loading={loading}>{description}</Name>,
          loading
        },
        {
          id: 'inputs',
          component: (props) => <Inputs
            disabled={props.disabled}
            name={'Settings'}
            icon={IconSettings}
            pathname={`${path}/discovery/${searchId}/inputs`}
            inputsProps={inputsProps}
            onClick={onSettingsClick}
            {...props}
          />,
          path: `${path}/discovery/${searchId}/inputs`,
          loading
        },
        {
          id: 'spacerInputs',
          component: Divider
        },
        {
          id: 'leads',
          kind: 'report',
          title: 'Leads',
          canDrop: this.isDiscoveryLevel,
          icon: Inbox,
          amount: leads,
          path: `${path}/discovery/${searchId}`,
          loading
        },
        {
          id: 'competitors',
          kind: 'competitors',
          title: 'Competitors',
          canDrop: this.isDiscoveryLevel,
          icon: Zap,
          amount: competitors,
          path: `${path}/discovery/${searchId}/competitors`,
          loading
        },
        {
          id: 'irrelevant',
          kind: 'irrelevant',
          title: 'Irrelevant',
          canDrop: this.isDiscoveryLevel,
          icon: ThumbsDown,
          amount: irrelevant,
          path: `${path}/discovery/${searchId}/irrelevant`,
          loading
        },
      ]
    }

    return null

  }

  get contactsNavigation () {
    const {
      match: { url: path },
      location: { pathname }
    } = this.props

    if (pathname.includes(`${path}/contacts`)) {
      return [
        {
          id: 'contact-infos',
          kind: 'contact-infos',
          title: 'All contacts',
          canDrop: false,
          path: `${path}/contacts`,
          noIcon: true
        },
        {
          id: 'contact-requests',
          kind: 'contact-requests',
          title: 'Requests',
          canDrop: false,
          path: `${path}/contacts/requests`,
          noIcon: true
        },
      ]
    }
    return null
  }

  get intelligenceNavigation () {
    const {
      match: { url: path },
      location: { pathname },
      intelligenceEnabled
    } = this.props

    if (pathname.includes(`${path}/intelligence`)) {
      if (!intelligenceEnabled && !this.props.loading) {
        this.props.history.push({ pathname: `${path}` })
        this.onOpenIntelligenceEnabledModal()
        return null
      }

      return [
        {
          id: 'landscape',
          kind: 'landscape',
          title: 'Competitor landscape',
          canDrop: false,
          path: `${path}/intelligence/landscape`,
          noIcon: true
        },
        {
          id: 'gap',
          kind: 'gap',
          title: 'Gap analysis',
          canDrop: false,
          path: `${path}/intelligence/gap`,
          noIcon: true
        },
        // {
        //   id: 'partnerships',
        //   kind: 'partnerships',
        //   title: 'Partnerships',
        //   canDrop: false,
        //   path: `${path}/intelligence/partnerships`,
        //   noIcon: true
        // }
      ]
    }
    return null
  }

  get navigation () {
    const { match: { url: path }, location, intelligenceEnabled } = this.props

    const relevantOpen = location.pathname.includes(`${path}/relevant`)
    const intelligenceOpen = intelligenceEnabled && location.pathname.includes(`${path}/intelligence`)

    return [
      {
        id: 'intelligence',
        titleText: 'Intelligence',
        title: <>Intelligence&nbsp;&nbsp;<small style={{color: 'rgba(169, 184, 205, 1)', borderRadius: 3, fontSize:'0.6em', position: 'relative', top: '-0.2em', padding:'0 0.3em', display: 'inline-block', border: '1px solid rgba(169, 184, 205, 1)'}}>Alpha</small></>,
        icon: Activity,
        canDrop: false,
        disableActive: true,
        path: `${path}/intelligence/landscape`,
        open: intelligenceOpen,
        items: this.intelligenceNavigation,
        onClick: (e) => {
          if (!intelligenceEnabled) {
            e.preventDefault()
            this.onOpenIntelligenceEnabledModal()
          }
        }
      },
      {
        id: 'home',
        title: 'Searches',
        icon: Compass,
        canDrop: false,
        path: path,
        items: this.discoveryNavigation,
        open: !!this.discoveryNavigation,
        // onClick: this.props.onUnmountProduct
      },
      {
        id: 'outreach',
        kind: 'relevants',
        titleText: 'Relevant',
        title: <div style={{display: 'flex', gap: '2em', alignItems: 'center'}}><span>Relevant</span><div style={{position: 'relative', zIndex: 1}}><Tooltip title="Items with this color are Relevant to one or more Searches"><EndIcon variant={'prospect'} /></Tooltip></div></div>,
        icon: ThumbsUp,
        // endIcon: () => <Tooltip title="Items with this color are Relevant to several Discoveries"><EndIcon variant={'prospect'} /></Tooltip>,
        endIcon: () => relevantOpen && <Tooltip title="Add folder"><IconButton onClick={this.props.addNewRelevantFolder}><FolderPlus size={16} /></IconButton></Tooltip>,
        // hoverAction: true,
        canDrop: true,
        path: `${path}/relevant`,
        open: relevantOpen,
        items: relevantOpen ? [
          {
            id: 'folders',
            component: () => <RelevantFolderList path={`${path}/relevant`} pathname={location.pathname} onAddFolder={this.props.addNewRelevantFolder}/>
          }
        ] : null
      },
      {
        id: 'partners',
        kind: 'partners',
        titleText: 'Partners',
        title: <div style={{display: 'flex', gap: '2em', alignItems: 'center'}}><span>Partners</span><div style={{position: 'relative', zIndex: 1}}><Tooltip title="Items with this color are Partners"><EndIcon variant={'partner'} /></Tooltip></div></div>,
        icon: Users,
        //endIcon: () => <Tooltip title="Items with this color are Partners"><EndIcon variant={'partner'} /></Tooltip>,
        canDrop: true,
        path: `${path}/partners`
      },
      {
        id: 'contacts',
        kind: 'contacts',
        title: 'Contacts',
        icon: BookOpen,
        canDrop: false,
        disableActive: true,
        items: this.contactsNavigation,
        path: `${path}/contacts`,
        // onClick: () => {
        //   this.props.history.push({ pathname: `${path}/contacts` })
        // },
        open: !!this.contactsNavigation
      }
    ]
  }

  get pageTitle () {
    const { location: { pathname }, productDetails } = this.props
    const root = this.navigation.filter(({ path }) => pathname === path)[0]

    if (!!root) {
      return (root.titleText || root.title)
    }

    if (pathname.includes('/discovery/')) {
      return productDetails.name
    }

    if (pathname.includes('/contacts')) {
      const nav = this.contactsNavigation.filter(({ path }) => pathname === path)[0]
      return (nav.titleText || nav.title)
    }

    return 'Breezy'
  }

  get appBarTitle () {
    const { location: { pathname }, match: { path }, productDetails, productLoading, products, folders, intelligenceEnabled } = this.props
    const currentNavigation = this.navigation.filter(({ path }) => pathname === path)[0]

    let props = {}

    if (pathname.includes('/discovery/')) {
      const { name, description, ownerName, ownerFullName, country, expired } = productDetails
      props = {
        editable: true,
        loading: productLoading,
        title: description,
        variant: 'discovery',
        ownerName, ownerFullName, country, name, products,
        onUpdate: this.props.onUpdateProduct,
        expired
      }
    } else if (pathname.includes('/contacts')) {
      const nav = this.contactsNavigation.filter(({ path }) => pathname === path)[0]
      props = { title: nav.title, variant: 'brand' }
    }  else if (pathname.includes('/intelligence')) {
      if (intelligenceEnabled) {
        const nav = this.intelligenceNavigation.filter(({ path }) => pathname === path)[0]
        props = { title: nav.title, variant: 'brand' }
      } else {
        props = {}
      }
    } else if (pathname.includes('/folder')) {
      const { params: { folderId } } = matchPath(pathname, { path: `${path}/relevant/folder/:folderId` })
      const folder = find(folders, ['id', folderId])

      if (folder) {
        props = { title: folder.name, variant: 'brand' }
      } else {
        props = { title: 'Folder', variant: 'brand' }
      }
    } else if (currentNavigation.id !== 'home') {
      props = { title: (currentNavigation.titleText || currentNavigation.title), variant: 'brand' }
    } else {
      props = {title: 'Searches', variant: 'home', onCreate: this.onOpenCreate}
    }

    return <DashboardHeader {...props} />
  }

  get showIntroduction () {
    //TODO: Comment this to show modal
    // const { flags, match: { url: path }, location, inactive } = this.props
    // return location.pathname === `${path}/relevant` && !flags.relevantFoldersCreated && !inactive
    //TODO: Uncomment this to show hide modal
    //return false
  }

  onOpenIntelligenceEnabledModal = () => this.setState({ openIntelligenceEnabledModal: true })
  onCloseIntelligenceEnabledModal = () => this.setState({ openIntelligenceEnabledModal: false })

  render () {
    const {
      id: brandId,
      loading,
      location,
      match: { path },
      inactive,
      billingStatus,
      role,
      credits,
      openAssistedLock
    } = this.props
    const { settings, create, billing } = this.state

    const appBarProps = {
      role,
      credits,
      billingStatus,
      inactive,
      title: this.appBarTitle,
      onSettings: this.onOpenSettings,
      onPurchase: this.onOpenPurchase,
      onPlans: this.props.onShowPlans
    }

    return (
      <Layout
        loading={loading}
        menu={BrandMenu}
        title={this.pageTitle}
        navigation={this.navigation}
        appBarProps={appBarProps}
      >
        <Switch location={location}>
          <Route exact path={`${path}/intelligence/:kind`}>
            <Intelligence
              brandId={brandId}
              onUpdateBrandCounters={() => this.props.updateCounters()}
              onUpdateRelevantList={() => this.props.updateRelevantList()}
            />
          </Route>
          <Route exact path={`${path}`}>
            <Discoveries />
          </Route>
          <Route exact path={`${path}/contacts`}>
            <Contacts brandId={brandId} />
          </Route>
          <Route exact path={`${path}/contacts/requests`}>
            <Requests brandId={brandId} />
          </Route>
          <Route exact path={`${path}/relevant`}>
            <Relevant />
          </Route>
          <Route exact path={`${path}/relevant/folder/:folderId`}>
            <RelevantFolder />
          </Route>
          <Route exact path={`${path}/partners`}>
            <Partners />
          </Route>
          <Route path={`${path}/discovery/:searchId`}>
            <Discovery />
          </Route>
        </Switch>

        { settings && <Settings open={settings} onClose={this.onCloseSettings} /> }
        { create && <DiscoveryCreate open={create} step={'channel'} onClose={this.onCloseCreate} /> }
        { billing && <AddMoreCredits open={billing} onClose={this.onClosePurchase} /> }
        { openAssistedLock && <AssistedLockModal open={openAssistedLock} />}

        { this.showIntroduction && <IntroductionModal open onClose={this.onCreateRelevantFolders} />}
        { this.state.openIntelligenceEnabledModal && <IntelligenceLockModal open onClose={this.onCloseIntelligenceEnabledModal}/> }

        <RunDiscovery />
        {/*<RequestContactsModal />*/}
        <SubscriptionInactiveModal />
        <BookingDemoModal />

      </Layout>
    )
  }
}
