import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Tooltip,
  LinearProgress as MuiLinearProgress,
} from '@material-ui/core'
import styled from 'styled-components'
import { Refresh } from '@material-ui/icons'
import { InputsDisplayBase } from '../../components/InputsDisplay'
import {ListItemText, ListItemIcon, ListItem as NavigationInputsRoot} from '../../new-components/Navigation/Navigation'
import pluraliseUnit from "../../utils/pluraliseUnit"



const Link = styled(RouterLink)`
  display: block;
  text-decoration: none;
  font-size: 14px;
  &:link, &:visited, &:active, &:hover {
    color: rgba(0, 0, 0, 0.87);
  }
`
const LinearProgress = styled(MuiLinearProgress)`
  margin: 5px 9px;
  background: #a9ffd9;
  border-radius: 6px;
  position: absolute;
  top: 16px;
  width: 90px;
  right: 7px;
  .MuiLinearProgress-barColorPrimary {
    background: ${props => props.theme.palette.brand.blue.main};
    border-radius: 3px;
  }
`;

const UpdateWrapper = styled.div`
  font-size: 14px;
  color: ${props => props.theme.palette.defaultText};
`
const Heading = styled.div`
  display: flex;
  align-items: center;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
`

const NavigationInputs = (props) => {
  const { name, pathname, inputsProps, researchStatus, active, icon:ItemIcon } = props
  const {
    changed,
    keywords,
    competitors,
    blocklist,
    keywordsChanged,
    competitorsChanged,
    blocklistChanged,
  } = inputsProps

  const showProgress = researchStatus === 'progress'

  return (
    <Tooltip enterDelay={1200} enterNextDelay={1200} placement={'right'} title={<>
      <strong>Search settings</strong>
      <div>Tweak settings to get better leads</div><br />
      {!showProgress && <>
        <div>{keywords} {pluraliseUnit('Keyword', keywords)}</div>
        <div>{competitors} {pluraliseUnit('Competitor', competitors)}</div>
        <div>{blocklist} Irrelevant</div>
      </>
      }
      {!showProgress && (keywordsChanged || competitorsChanged) && <strong><br />Search settings are changed. Re-run Search to get new leads.</strong>}
      {showProgress && <strong>The search process is running, grab a coffee and we’ll let you know as soon as your report is ready.</strong>}
    </>}>
      <Link
        active={active.toString()}
        to={{ pathname }}
      >
        <NavigationInputsRoot
          button
          nested
          selected={active}

          {...props}
        >
          <Body>
            <UpdateWrapper>
              <Heading>
                <ListItemIcon>
                  <ItemIcon size={14}  />
                </ListItemIcon>
                <ListItemText>{name}</ListItemText>
              </Heading>

              {/*{*/}
              {/*  changed && !showProgress && (*/}
              {/*    <ButtonRerunDiscovery onClick={props.onReRun} color={'primary'} >*/}
              {/*          <ReRunIcon /> Re-run*/}
              {/*    </ButtonRerunDiscovery>*/}
              {/*  )*/}
              {/*}*/}
              {
                showProgress && (
                <LinearProgress variant="indeterminate" />
                )
              }
            </UpdateWrapper>
            <InputsDisplayBase
              disableTooltip
              keywordsCount={keywords}
              competitorsCount={competitors}
              irrelevantsCount={blocklist}
              keywordsChanged={keywordsChanged}
              competitorUrlsChanged={competitorsChanged}
              irrelevantsChanged={blocklistChanged}
            />
          </Body>
        </NavigationInputsRoot>
      </Link>
    </Tooltip>
  )
}

export default NavigationInputs
