import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import qs from 'query-string'
import OnboardingStore from '../../stores/onboarding'

import WelcomeLayout from '../../layouts/Welcome'
import WelcomeDiscoveryLayout from '../../layouts/WelcomeDiscovery'
import EmailForm from '../../components/Forms/Email'
import BrandForm from '../../components/Forms/CompanyOnboarding'
import Plans from './Plans'
import Discovery from './Discovery'
import Verify from './Verify'
import Running from './Running'
import Done  from './Done'
import BookDemo  from './BookDemo'
import { Intercom } from '../../components/Intercom/Intercom'

import InputsForm from '../../new-components/Forms/InputsForm'

import { credits } from '../../utils/credits'

const STEPS = ['company'] //, 'plans', 'discovery', 'keywords', 'competitors', 'verify', 'running', 'ready']

@inject(({ UserStore }) => ({
  checkAuthorization: UserStore.checkAuthorization,
  user: UserStore.user
}))
@observer
export default class Wrapper extends React.Component {

  render () {
    const {
      user,
      checkAuthorization
    } = this.props
    return checkAuthorization ? <WelcomeLayout thinking /> : <Welcome brandId={user.brands && user.brands.length ? user.brands[0] : null} />
  }
}

@withRouter
@inject(({ UserStore }) => ({
  checkAuthorization: UserStore.checkAuthorization,
  user: UserStore.user,
  userThinking: UserStore.thinking,
  onVerify: (token) => UserStore.verify(token),
  onUpdateEmail: (email) => UserStore.updateEmail(email, 'welcome/verify'),
  onUpdateUserDetails: details => UserStore.update(details, true),

  onFirstResearchSeen: () => UserStore.setFirstResearchSeen()
}))
@observer
class Welcome extends React.Component {
  constructor (props) {
    super(props)
    const {
      location: { search },
      match: { params: { step } },
      brandId
    } = this.props

    const { token } = qs.parse(search)

    this.store = new OnboardingStore(brandId)

    this.state = {
      step,
      token,
      booked: false,
      verify: false,
      keywords: [],
      keywordsErrors: [],
      competitors: [],
      competitorsErrors: [],
      partnersErrors: [],
      loading: true
    }
  }

  get stepTitle () {
    const { step } = this.state
    switch (step) {
      case 'email':
        return 'Update your email'
      case 'book-a-demo':
        return 'Book a Demo'
      case 'company':
        return 'Share your information, we\'ll share your results'
      case 'plans':
        return 'Plans'
      case 'discovery':
        return 'Search name & GEO'
      case 'competitors':
        return 'Help us identify potential partners'
      case 'partners':
        return 'Existing partners'
      case 'keywords':
        return 'Keywords'
      case 'verify':
        return 'Verify you email'
      case 'running':
        return 'Search is Running'
      case 'ready':
        return 'First Search is Ready'
      default:
        return 'Welcome'
    }
  }

  get nextStep () {
    const { step } = this.state
    const stepIndex = STEPS.indexOf(step)
    if (stepIndex < STEPS.length - 1) {
      return STEPS[stepIndex + 1]
    }
    return step
  }

  get previousStep () {
    const { step } = this.state
    const stepIndex = STEPS.indexOf(step)
    if (stepIndex === 0) {
      return step
    }
    return STEPS[stepIndex - 1]
  }

  get credits () {
    const { credits: creditsLeft } = this.store.brandDetails
    const { competitors, keywords } = this.state
    const competitorsCount = competitors.filter(r => !!r && r.length).length
    const keywordsCount =  keywords.filter(r => !!r && r.length).length

    return credits({ competitorsCount, keywordsCount, CREDITS: creditsLeft })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { computedStep, loading } = this.store
    const { match: { params: { step } } } = this.props

    if (this.state.loading && !this.store.loading) {
      const { competitors, keywords } = this.store
      this.setState({ loading: false, competitors, keywords })

      if (!computedStep) {
        this.setState({ step: 'company' })
        this.props.history.push(`/welcome/company`)
      } else if (step !== computedStep && computedStep !== 'done') {
        /// Remove this HACK
        const matchedStep = computedStep.split('?')
        let _step = null
        let booked = false
        if (matchedStep && matchedStep.length) {
          _step = matchedStep[0]
          booked = matchedStep[1] && matchedStep[1].includes('booked=true')
        }

        this.setState({ step: _step || computedStep, booked })

        this.props.history.push(`/welcome/${computedStep}`)
      } else if (computedStep === 'done') {
        const { productDetails, brand: { id: brandId } } = this.store
        this.props.history.push({ pathname: `/brand/${brandId}/discovery/${productDetails.id}` })
      }
    }

    if (step === 'running' && this.store.productDetails.researchStatus === 'researched') {
      this.onNextStep()
    }
    //
    // if (prevStateLoading && !stateLoading) {
    //   if (computedStep && computedStep !== step && !['plans', 'email', 'book-a-demo', 'company'].includes(step)) {
    //     this.setState({ step: computedStep }, () => {
    //       this.props.history.push(`/welcome/${computedStep}`)
    //     })
    //   }
    // }
  }

  onNextStep = () => {
    const nextStep = this.nextStep
    this.setState({ step: nextStep })
    this.store.setOnboardingStep(nextStep).then()
    this.props.history.push(nextStep)
  }

  onPreviousStep = () => {
    this.setState({ step: this.previousStep })
    this.props.history.push(this.previousStep)
  }

  onSetBrand = async (details) => {
    const { brandId } = this.store.brandDetails
    const { firstName, lastName, ...brandDetails } = details
    const create = await this.store.setBrand({ brandId, ...brandDetails })

    if (create.success) {
      this.props.onUpdateUserDetails({ firstName, lastName })
      const update = await this.store.setOnboardingStep('done')
      if (update.success) {
        const { id } = update.brand
        this.props.history.push({ pathname: `/brand/${id}` })
      }
      return { error: !update.success }
    }

    // const { success } = await this.props.onUpdateUserDetails({ firstName, lastName })

    return { error: !create.success }
  }

  onSetCompetitors = async () => {
    const competitors = this.state.competitors || this.store.competitors

    let competitorsErrors = this.state.competitorsErrors
    if (competitors && competitors.length) {
      if (competitors[0].length < 3) {
        competitorsErrors.push(0)
      }
    } else {
      competitorsErrors.push(0)
    }
    this.setState({competitorsErrors})
    if (!competitorsErrors.length) {
      const { success } = await this.store.updateProduct({competitors})
      if (success) {
        this.props.onUpdateEmail().then()
        this.onNextStep()
      }

      return { success }
    }
  }

  onSetKeywords = async () => {
    const keywords = this.state.keywords || this.store.keywords

    let keywordsErrors = this.state.keywordsErrors
    if (keywords.length) {
      if (keywords[0].length < 3) {
        keywordsErrors.push(0)
      }
    } else {
      keywordsErrors.push(0)
    }

    this.setState({keywordsErrors})

    if (!keywordsErrors.length) {

      const { success } = await this.store.updateProduct({keywords})

      if (success) {
        this.onNextStep()
      }
      return { success }
    }
  }

  onSetProduct = async (details) => {
    const { success } = await this.store.setProduct(details)
    if (success) {
      this.onNextStep()
    }
    return { success }
  }

  onChange = kind => (value, errors) => {
    this.setState({[`${kind}`]: value, [`${kind}Errors`]: errors})
  }

  onVerify = async (token) => {
    const { success } = await this.props.onVerify(token)

    this.setState({ token: null })

    if (success) {
      this.setState({ verify: true })
      this.onNextStep()
    }
  }

  onStartResearch = async () => {
    await this.store.startResearch()
  }

  onUpdateEmail = () => {
    this.setState({ step: 'email' })
    this.props.history.push('email')
  }

  // onChangeEmail = async ({ email }) => {
  //   const { error } = await this.props.onUpdateEmail(email)
  //   if (!error) {
  //     this.setState({ step: 'verify' })
  //     this.props.history.push('verify')
  //   }
  //   return { error }
  // }
  //
  // onFirstResearchSeen = async () => {
  //   const { productDetails, brand: { id: brandId } } = this.store
  //   await this.store.setFirstResearchSeen()
  //   this.store.setOnboardingStep('done').then()
  //   this.props.history.push({ pathname: `/brand/${brandId}/discovery/${productDetails.id}` })
  // }
  //
  // onSelectPlan = async (plan, period) => {
  //   const selectedPlan = `${plan === 'self' ? 'Self-serve' : 'Assisted'}-GBP-${period === 'monthly' && plan === 'self' ? 'Monthly' : 'Every-3-months'}`
  //   if (plan === 'assisted') {
  //     this.onBookDemo()
  //     return { success: true }
  //   }
  //   const { success } = await this.store.selectSubscription(selectedPlan)
  //
  //   if (success) {
  //     this.onNextStep()
  //   }
  //   return { success }
  // }
  //
  // onBookDemo = () => {
  //   this.setState({ step: 'book-a-demo' })
  //   this.store.setOnboardingStep('book-a-demo').then()
  //   this.props.history.push('book-a-demo')
  // }
  //
  // onBackToPlans = (e) => {
  //   e.preventDefault()
  //   this.setState({ step: 'plans' })
  //   this.props.history.push({ pathname: '/welcome/plans'})
  // }
  //
  // onToSelfServe = async (e) => {
  //   e.preventDefault()
  //   if (!this.store.brandDetails.plan) {
  //     const { success } = await this.store.selectSubscription('Self-serve-GBP-Monthly')
  //     if (success) {
  //       this.setState({ step: 'discovery' })
  //       this.props.history.push({ pathname: '/welcome/discovery'})
  //     }
  //   } else {
  //     this.setState({ step: 'discovery' })
  //     this.props.history.push({ pathname: '/welcome/discovery'})
  //   }
  // }

  render () {
    const { user, userThinking } = this.props
    const {
      loading,
      thinking,
      brandDetails,
      productDetails,
    } = this.store

    const {
      booked,
      step,
      token,
      verify,
      competitors,
      keywords,
      competitorsErrors,
      keywordsErrors,
    } = this.state

    return (
      <>
        {/*{*/}
        {/*  ['email', 'book-a-demo', 'discovery', 'keywords', 'competitors', 'verify', 'running', 'ready'].includes(step) && (*/}
        {/*    <Intercom*/}
        {/*      onboarding*/}
        {/*      authorized={!loading}*/}
        {/*      loading={loading}*/}
        {/*      brand={brandDetails}*/}
        {/*      user={user}*/}
        {/*    />*/}
        {/*  )*/}
        {/*}*/}
        {/*{*/}
        {/*  step === 'email' &&*/}
        {/*  <WelcomeLayout*/}
        {/*    title={this.stepTitle}*/}
        {/*    thinking={loading}>*/}
        {/*    <EmailForm*/}
        {/*      thinking={userThinking}*/}
        {/*      subHeader={'Please enter your correct email address so we can send you notifications and handy updates.'}*/}
        {/*      onSubmit={this.onChangeEmail}*/}
        {/*    />*/}
        {/*  </WelcomeLayout>*/}
        {/*}*/}
        {/*{*/}
        {/*  step === 'book-a-demo' &&*/}
        {/*  <WelcomeDiscoveryLayout*/}
        {/*    title={this.stepTitle}*/}
        {/*    thinking={loading}*/}
        {/*  >*/}
        {/*    <BookDemo*/}
        {/*      {...user}*/}
        {/*      booked={booked}*/}
        {/*      brand={brandDetails}*/}
        {/*      onBackToPlans={this.onBackToPlans}*/}
        {/*      onToSelfServe={this.onToSelfServe}*/}
        {/*      onDemoBooked={() => this.store.setOnboardingStep('book-a-demo?booked=true')}*/}
        {/*    />*/}
        {/*  </WelcomeDiscoveryLayout>*/}
        {/*}*/}

        {
          step === 'company' &&
          <WelcomeLayout
            title={this.stepTitle}
            thinking={loading}>
            <BrandForm
              welcome
              thinking={thinking}
              {...brandDetails}
              {...user}
              header={'Tell us about yourself'}
              subHeader={'We just need a few details to get your account ready. Nearly there!'}
              onSubmit={this.onSetBrand}
            />
          </WelcomeLayout>
        }

      {/*  {*/}
      {/*    step === 'plans' && (*/}
      {/*      <WelcomeDiscoveryLayout*/}
      {/*        title={this.stepTitle}*/}
      {/*        thinking={loading}*/}
      {/*      >*/}
      {/*        <Plans*/}
      {/*          variant={'onboarding'}*/}
      {/*          onSelectPlan={this.onSelectPlan}*/}
      {/*        />*/}
      {/*      </WelcomeDiscoveryLayout>*/}
      {/*    )*/}
      {/*  }*/}

      {/*  {*/}
      {/*    step === 'discovery' && (*/}
      {/*      <WelcomeDiscoveryLayout*/}
      {/*        title={this.stepTitle}*/}
      {/*        thinking={loading}*/}
      {/*      >*/}
      {/*        <Discovery*/}
      {/*          variant={'onboarding'}*/}
      {/*          title={this.stepTitle}*/}
      {/*          loading={loading}*/}
      {/*          thinking={thinking}*/}
      {/*          {...user}*/}
      {/*          {...productDetails}*/}
      {/*          link={brandDetails.link}*/}
      {/*          onSubmit={this.onSetProduct}*/}
      {/*        />*/}
      {/*      </WelcomeDiscoveryLayout>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  {*/}
      {/*    step === 'keywords' && (*/}
      {/*      <WelcomeDiscoveryLayout*/}
      {/*        product={productDetails}*/}
      {/*        inputs*/}
      {/*        credits={this.credits}*/}
      {/*        creditsLeft={brandDetails.credits}*/}
      {/*        step={1}*/}
      {/*        title={this.stepTitle}*/}
      {/*        thinking={loading}*/}
      {/*      >*/}
      {/*        <InputsForm*/}
      {/*          kind={'keywords'}*/}
      {/*          variant={'onboarding'}*/}
      {/*          credits={this.credits}*/}
      {/*          thinking={thinking}*/}
      {/*          values={keywords}*/}
      {/*          errors={keywordsErrors}*/}
      {/*          helperText={'Enter one keyword or phrase per line'}*/}
      {/*          errorText={'Please enter keyword or phrase'}*/}
      {/*          onSubmit={this.onSetKeywords}*/}
      {/*          onCancel={this.onPreviousStep}*/}
      {/*          onChange={this.onChange('keywords')}*/}
      {/*        />*/}
      {/*      </WelcomeDiscoveryLayout>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  {*/}
      {/*  step === 'competitors' && (*/}
      {/*    <WelcomeDiscoveryLayout*/}
      {/*      product={productDetails}*/}
      {/*      inputs*/}
      {/*      credits={this.credits}*/}
      {/*      creditsLeft={brandDetails.credits}*/}
      {/*      step={2}*/}
      {/*      title={this.stepTitle}*/}
      {/*      thinking={loading}*/}
      {/*    >*/}
      {/*      <InputsForm*/}
      {/*        kind={'competitors'}*/}
      {/*        variant={'onboarding'}*/}
      {/*        credits={this.credits}*/}
      {/*        thinking={thinking}*/}
      {/*        values={competitors}*/}
      {/*        errors={competitorsErrors}*/}
      {/*        onChange={this.onChange('competitors')}*/}
      {/*        onSubmit={this.onSetCompetitors}*/}
      {/*        onCancel={this.onPreviousStep}*/}
      {/*      />*/}
      {/*    </WelcomeDiscoveryLayout>*/}
      {/*  )*/}
      {/*}*/}
      {/*  {*/}
      {/*    step === 'verify' && (*/}
      {/*      <WelcomeDiscoveryLayout*/}
      {/*        title={this.stepTitle}*/}
      {/*        thinking={loading}*/}
      {/*      >*/}
      {/*        <Verify*/}
      {/*          token={token}*/}
      {/*          {...user}*/}
      {/*          onVerify={this.onVerify}*/}
      {/*          onVerified={this.onNextStep}*/}
      {/*          onUpdateEmail={this.onUpdateEmail}*/}
      {/*        />*/}
      {/*      </WelcomeDiscoveryLayout>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  {*/}
      {/*    step === 'running' && (*/}
      {/*      <WelcomeDiscoveryLayout*/}
      {/*        title={this.stepTitle}*/}
      {/*        thinking={loading}*/}
      {/*      >*/}
      {/*        <Running*/}
      {/*          verified={verify}*/}
      {/*          {...user}*/}
      {/*          {...productDetails}*/}
      {/*          onStartResearch={this.onStartResearch}*/}
      {/*        />*/}
      {/*      </WelcomeDiscoveryLayout>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  {*/}
      {/*    step === 'ready' && (*/}
      {/*      <WelcomeDiscoveryLayout*/}
      {/*        title={this.stepTitle}*/}
      {/*        thinking={loading}*/}
      {/*      >*/}
      {/*        <Done*/}
      {/*          {...productDetails}*/}
      {/*          onFirstResearchSeen={this.onFirstResearchSeen}*/}
      {/*        />*/}
      {/*      </WelcomeDiscoveryLayout>*/}
      {/*    )*/}
      {/*  }*/}

      </>
    )

  }
}
