import { action, computed, observable, toJS } from 'mobx'
import { sendEvent } from '../utils/events'
import Queries from './queries'
import GqlClient from '../gql/gql-client'
import { channelsTags, channelsContactStatus } from '../gql/channels'
import { createCSV, download } from '../utils/download-blod'

const fields = {
  swVisits: 'visits',
  partnershipsOutCount: 'partnershipsOut',
  mozDomainAuthority: 'moz',
  overlap: 'overlap',
  swGlobalRank: 'rank',
}
const prepareEdges = (edges = []) => {
  return edges.map(({ node }) => node)
}

class GapAnalysisStore extends Queries {
  @observable _filters = {}
  @observable order = 'desc'
  @observable orderBy = 'overlap'
  @observable orderByGQL = 'overlap'

  fields = ['_filters']
  allChannels = []

  async loadIds({ query, dataField, variables }) {
    const { success, ...rest } = await GqlClient.query({
      query,
      variables,
    })

    if (success) {
      const { edges } = rest.data[dataField]
      const allIds = prepareEdges(edges)

      return allIds.map(({ partnerId }) => partnerId)
    }
    return []
  }

  async exportToCSV({ query, dataField, variables, brandId }) {
    const { success, ...rest } = await GqlClient.query({
      query,
      variables,
    })

    if (success) {
      const { edges } = rest.data[dataField]
      let prospects = prepareEdges(edges)
      prospects = await channelsTags(prospects, brandId)
      prospects = await channelsContactStatus(prospects, brandId)

      download(createCSV(prospects, 'gap-analysis'), `gap-analysis.csv`)
    }
  }

  @action
  setFilters(value, brandId) {
    const { search } = toJS(this._filters)

    if (!value.search && search) {
      this.order = this.order || 'desc'
      this.orderBy = this.orderBy || 'overlap'
      this.orderByGQL = this.orderByGQL || 'overlap'
    } else if (search !== value.search) {
      this.order = undefined
      this.orderBy = undefined
      this.orderByGQL = undefined
    }

    if (value && value.hasOwnProperty('search') && search !== value.search) {
      sendEvent(brandId, 'gapSearch', { search: value.search })
    } else if (value) {
      const { search, ...restFilters } = value
      sendEvent(brandId, 'gapFiltersApplied', { ...restFilters })
    }

    this.updateQueries()

    this._filters = value
  }

  @action
  setSort(model, brandId) {
    if (!model || !model[0]) return null

    const { field, sort } = model[0]

    this.orderBy = field
    this.orderByGQL = fields[field]

    let direction = sort

    if (this.orderBy === 'rank') {
      direction = sort === 'desc' ? 'asc' : 'desc'
    }

    this.order = direction
  }

  @action
  getRequestOptions() {
    return { ...this.filters, sortQueries: this.sortQueries }
  }

  @computed
  get filters() {
    return toJS(this._filters)
  }

  @computed
  get sortQueries() {
    return this.orderBy ? `${toJS(this.orderByGQL)} ${toJS(this.order)}` : null
  }

  @computed
  get geoFilter() {
    const filters = toJS(this._filters)

    if (filters.hasOwnProperty('byCountries')) {
      return filters.byCountries.map(({ value }) => value)
    }

    return undefined
  }
}

export default new GapAnalysisStore()
