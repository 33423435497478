import React from 'react'
import {Zombieing} from '../Icons/Zombieing'
import {Favourites} from '../Icons/Favourites'
import {Coffee} from '../Icons/Coffee'
import {ManWithBox} from '../Icons/ManWithBox'
import {FirstReportReady} from '../Icons/FirstReportReady'
import EmptySearchPlaceholder from '../EmptySearchPlaceholder'
import {Link} from 'react-router-dom'

const config = {
  'noMessages': {
    icon: <Coffee />,
    title: 'No messages',
    text: 'You have no messages in this list yet.',
    // buttonText: 'Setup your first Product',
    // imgRight: true,
    // onClick: 'onCreateProduct'
  },
  'noChannel': {
    icon: <ManWithBox />,
    title: 'Hi {name},<br/> Welcome to Breezy!',
    text: 'You have no verified websites yet. Please contact support to get started.',
    buttonText: 'Verify',
    imgRight: true,
    onClick: 'onContactAccountManager'
  },
  'emptySearch': {
    icon: <Zombieing />,
    title: 'No results',
    text: 'We couldn\'t find a match for your query.',
    // buttonText: 'Reset search',
    // onClick: 'onResetFilters'
  },
  'emptyLeadsList': {
    icon: <Zombieing />,
    title: 'Empty {list}',
    text: 'You haven\'t added any Leads yet.'
  },
  'emptyLeads': {
    icon: <Favourites />,
    title: 'All Relevant items across discoveries live here',
    text: 'This is where you can find all the Relevant from across your Discoveries in one place. It\'s also where you request and store their Contact Information '
  },
  'createProduct': {
    icon: <ManWithBox />,
    title: 'Hi {name},<br/> Welcome to Breezy!',
    text: 'Setup your first Product, and help us tailor the Partner search to your needs.',
    buttonText: 'Setup your first Product',
    imgRight: true,
    onClick: 'onCreateProduct'
  },
  'importPartners': {
    icon: <ManWithBox />,
    title: 'Your partners',
    text: 'Promote your Leads from the Relevant section to Partners or import your existing Partners to improve search relevancy.',
    buttonText: 'Import existing partners',
    imgRight: true,
    onClick: 'onImportPartners'
  },
  'startDiscovery': {
    icon: <ManWithBox />,
    title: 'Our search engine<br/> is ready for you',
    text: 'You can review your settings to make sure you’re happy with them, or just click <strong>Run search</strong> to create your first report.',
    // buttonText: 'Run Research',
    imgRight: true,
    onClick: 'onStartDiscovery',
    padding: '0 16px 1em',
    alignLeft: true
  },
  'discoveryRunning': {
    icon: <Coffee />,
    title: 'Our Engine Is Running',
    text: 'We’re busy searching the web for you which can take a little time. So grab a coffee and we’ll let you know as soon as your report is ready.',
    imgRight: true,
    padding: '0 16px 1em',
    alignLeft: true
  },
  'productNotCompletelyFilled': {
    icon: <Coffee />,
    title: 'Hi {name},<br/> welcome to Breezy',
    text: 'Your product is not completely filled',
    buttonText: 'Return to onboarding',
    imgRight: true,
    onClick: 'onToWelcome'
  },
  'reportReady': {
    icon: <FirstReportReady />,
    title: 'Your first report is ready!',
    text: 'Your results are now ready, and we\'ve discovered <strong>{amount}</strong> leads for you to review for <strong>{productName}!</strong>!',
    // buttonText: 'See your report',
    // modal: true,
    // onClick: 'onResearchSeen',
    imgRight: true,
    padding: '0 16px 1em',
    alignLeft: true
  }
}

const LeadsPlaceholder = ({ state, thinking, folder, name, kind, productName, amount, children, ...rest }) => {
  const noop = () => {}

  const AdditionalButton = () => {
    if (state === 'startDiscovery') {
      return <Link to={'#'} onClick={() => rest.onOpenProductDetails('competitors')}>Review your inputs</Link>
    }
    return null
  }

  return !!state ? (
    <>
      <EmptySearchPlaceholder
        alignLeft={config[state].alignLeft}
        padding={config[state].padding}
        thinking={thinking}
        state={state}
        {...config[state]}
        title={
          config[state].title
            .replace('{name}', name)
            .replace('{kind}', kind === 'partners' ? 'Partners' : 'Prospects')
            .replace('{list}', folder ? 'Folder' : 'List')
        }
        text={
          config[state].text
            .replace('{amount}', amount)
            .replace('{productName}', productName || 'My product or service')
            .replace('{target}', kind === 'partners' ? 'Partners' : 'Prospects')
            .replace('{opposite}', kind !== 'partners' ? 'Partners' : 'Prospects')
        }
        onClick={rest[config[state].onClick] || noop}
        additionalButton={AdditionalButton()}
      />
      { state === 'reportReady' && children }
    </>
  ) : children || null
}

export default LeadsPlaceholder
