import { observable, action, computed, toJS } from 'mobx'
import param from 'can-param'
import deparam from 'can-deparam'
import { isEmpty } from 'lodash'

const isEmptyValue = value => {
  if (!value) return true
  if (Array.isArray(value) && !value.length) return true
  return isEmpty(value)
}

export default class Queries {
  fields = null

  constructor () {}

  getQueriesFromSearch () {
    const { search } = window.location

    if (!search) return false

    let hasFields = false

    const queries = deparam(search)

    if (this.fields) {
      this.fields.forEach(field => {
        const value = queries[field]
        if (value) {
          this[field] = value
          hasFields = true
        }
      })
    }

    return hasFields
  }

  readQueries () {
    const { search } = window.location
    return deparam(search)
  }

  updateQueries () {
    if (!this.fields) return null

    let queries = this.readQueries()

    for (let i = 0; i < this.fields.length; i++) {
      const field = this.fields[i]
      const value = toJS(this[field])

      if (isEmptyValue(value)) {
        delete queries[field]
      } else {
        queries[field] = value
      }
    }


    const search = param(queries)

    if (search) {
      window.history.replaceState(null, null, `?${search}`)
    }

    return queries
  }
}
