import React from 'react'
import TextRows from '../TextRows'
import { FormsModal } from './FormsModal'
import {Grid} from "@material-ui/core";
import {Form} from "../Form";

export default class CompetitorsForm extends React.Component {
  render() {
    const {
      errors,
      competitors,
      onChange
    } = this.props
    const Text = () =>
      <>
        <h2 style={{marginTop: '18px'}}>Input your existing list of Partners</h2>
        <p className={'prospect'}>
          Breezy will highlight these in your results list and let you hide them easily, so that you can focus on finding new partners.</p>
        <h3>Tips</h3>
        <ol>
          <li><strong>Copy a column of URLs</strong> from a spreadsheet or list and <strong>paste it into the textbox.</strong></li>
        </ol>
      </>
    return (
      <FormsModal
        Text={Text}
      >
        <Grid container style={{marginTop: '16px'}}>
          <Grid item xs={12} style={{height: 'calc(60vh)', position: 'relative'}}>
            <TextRows
              // maxRow={15}
              title={'Enter Partner URLs'}
              validation={'competitors'}
              placeholder={'e.g. https://example.com'}
              helpText={'Type one URL per line'}
              values={competitors}
              errors={errors}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </FormsModal>
    )
  }
}
