import React, {useContext, useState} from 'react'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import { findIndex } from 'lodash'

import EcosystemsLockModal from '../../../new-components/Modals/EcosystemsLockModal'
import { CreatorRequestContext } from '../CreatorRequestDetailsContext'
import WidgetAppears from '../../Widget/WidgetAppears'
import IconLabel from '../../IconLabel'
import pluraliseUnit from '../../../utils/pluraliseUnit'


const Cover = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  height: 6em;
  width: 100%;
  background: linear-gradient(to top, white 15%, transparent 100%);
  pointer-events: none;
`

const Unlock =  styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.main};
  width: 100%;
  font-size: 0.8em;
  display: flex;
  align-items: center; 
  padding: 0.5em;
  position: relative;
  z-index: 20;
`

export default function DetailsAppearsIn () {
    const { loading, ecosystems, brandEcosystems } = useContext(CreatorRequestContext)

    const allAvailableEcosystems = ecosystems.map(({ ecosystemId, ...rest }) => {
        return {
            ...rest,
            id: ecosystemId,
            disabled: findIndex(brandEcosystems, ['id', ecosystemId]) < 0
        }
    })

    const availableEcosystems = allAvailableEcosystems.filter((item) => !item.disabled)

    const lockedEcosystems = allAvailableEcosystems.filter((item) => item.disabled && item.published)

    const treshold = lockedEcosystems.length >= 5 ? 5 : lockedEcosystems.length

    const isEmpty = Boolean(!availableEcosystems.length)

    const onLockOpen = (e) => {
        e.preventDefault()
    }

    if (!loading && isEmpty) return null

    return <WidgetAppears loading={loading} size="2x2">
        {
            availableEcosystems.map(({ id, name, disabled }) => <IconLabel key={id} variant="ecosystem" disabled={disabled} label={name} to={`#`}/>)
        }
        {
            lockedEcosystems.length > 0 && (
                <>
                    {
                        lockedEcosystems.slice(0, treshold).map(({id, name, disabled}) => (
                                <IconLabel key={id} variant="ecosystem"
                                           disabled={disabled} label={name}
                                           to={`#`}/>
                            )
                        )
                    }
                    <Cover/>
                    <Unlock onClick={onLockOpen}>Unlock {lockedEcosystems.length} {pluraliseUnit('ecosystem', lockedEcosystems.length)}</Unlock>
                </>
            )
        }
    </WidgetAppears>
}

