import { observable, action, computed, toJS } from 'mobx'

import GqlClient from '../gql/gql-client'
import hasError from './request-message'
import { GET_CREATOR } from '../gql/creator'

class CreatorStore {
  @observable loading = true
  @observable thinking = false

  @observable creator = {}

  constructor () {}

  @action
  async load () {
    const { success, ...rest } = await GqlClient.query({ query: GET_CREATOR })

    if (success) {
      this.creator = rest.data['creator']
    }

    this.loading = false

    return { success }

  }

  @action
  updateCounters (source, target) {
    const creator = toJS(this.creator)
    const { [`${source}Count`]: sourceCount, [`${target}Count`]: targetCount } = creator

    if (sourceCount - 1 >= 0) {
      this.creator = { ...creator, [`${source}Count`]: sourceCount - 1, [`${target}Count`]: targetCount + 1 }
    }
  }

  @computed
  get hasChannel () {
    return !this.loading && Boolean(this.creator.channel)
  }

  @computed
  get counts () {
    const { inboxCount, acceptedCount, rejectedCount } = toJS(this.creator)

    return { inbox: inboxCount, accepted: acceptedCount, rejected: rejectedCount }
  }

  @computed
  get details () {
    return toJS(this.creator)
  }
}

export default new CreatorStore()
