import qs from 'query-string'
import { gql } from '@apollo/client'
import { observable, action, computed, toJS } from 'mobx'

import { Post, Put } from '../utils/request'
import hasError from './request-message'
import BrandStore from './brand'
import BrandsStore from './brands'

import GqlClient from '../gql/gql-client'
import { CURRENT, SIGN_IN, SIGN_UP, UPDATE_USER, VERIFY_USER } from '../gql/user'
import { JOIN_TO_BRAND } from '../gql/join'

const userFields = ['id', 'firstName', 'lastName', 'brands', 'createdAt', 'email', 'emailStatus', 'hasPassword', 'role', 'status']

class UserStore {
  @observable checkAuthorization = true
  @observable authorized = false
  @observable thinking = false

  @observable id
  @observable firstName
  @observable lastName
  @observable brands = []
  @observable createdAt
  @observable email
  @observable role
  @observable emailStatus
  @observable hasPassword
  @observable status
  @observable access = {}

  @observable reports = 0
  @observable leads = 0


  updateUserFields (user) {
    for (let key in user) {
      if (user.hasOwnProperty(key)) {
        if (key === 'access') {
          this.access = {}

          user.access.forEach(({ brandId, role }) => {
            this.access[brandId] = role
          })

        } else if (key !== 'brands') {
          this[key] = user[key]
        }
      }
    }
  }

  constructor() {
    this.current().then()
  }

  @action
  loadBrand (user) {}

  @action
  async current () {
    const { token, admin } = qs.parse(window.location.search)

    if (token) {
      GqlClient.updateToken(token)
    }

    const { success, ...rest } = await GqlClient.query({ query: CURRENT })

    if (success) {
      const user = rest.data['me']
      this.updateUserFields(user)
      if (user.role !== 'creator') {
        await BrandsStore.load()
      }
    } else {
      GqlClient.removeToken()
    }

    this.authorized = success

    this.checkAuthorization = false

    return { success, role: toJS(this.role) }
  }

  @action
  async signin (details) {
    this.thinking = true

    GqlClient.removeToken()

    const { success, ...rest } = await GqlClient.mutation({ mutation: SIGN_IN, variables: { SigninUserInput: details } })

    if (success) {
      const { user, token } = rest.data['signinUser']
      GqlClient.updateToken(token)
      this.updateUserFields(user)
      if (user.role !== 'creator') {
        await BrandsStore.load()
      }
    }

    this.authorized = success

    this.thinking = false

    return { success }
  }

  @action
  async signinByToken (token) {
    GqlClient.removeToken()
    this.checkAuthorization = true
    this.authorized = false

    GqlClient.updateToken(token)
    return this.current().then()
  }

  @action
  async signUpOnboarding (details, tags) {
    this.thinking = true

    const { success, user, message } = await Post(`/api/discovery/users/signup`, {
      user: details,
      ...(tags && Object.keys(tags).length > 0 && { utm: tags })
    })

    if (success) {
      this.updateUserFields(user)
    }

    this.authorized = success

    this.thinking = false

    return { success, error: message }
  }

  @action
  async signup (details) {
    this.thinking = true

    GqlClient.removeToken()

    const { success, ...rest } = await GqlClient.mutation({ mutation: SIGN_UP, variables: { SignupUserInput: details } })

    if (success && !details.isCreator) {
      const { email, password } = details
      return await this.signin({ email, password })
    }

    return { success }
  }

  @action
  async forgotPassword (details) {
    this.thinking = true
    const { message } = await Put(`/api/users/forgot-password`, details)
    this.thinking = false
    return { error: message }
  }

  @action
  async changePassword ({ password, token }) {
    this.thinking = true

    const { errorCode, user, message, success } = await Put(`/api/users/change-password`, { password, token })

    this.thinking = false

    if (success) {
      const { email } = user
      // await this.signin({ password, email })
      return { password, email, success }
    }

    return { error: message, success }
  }

  @action
  async signout () {
    this.checkAuthorization = true
    this.authorized = false

    BrandStore.setInitial()

    GqlClient.removeToken()

    userFields.forEach(key => this[key] = null)

    this.checkAuthorization = false
  }

  @action
  async update ({ email, ...details }, noMessage) {
    this.thinking = true

    const payload = {
      id: this.id,
      patch: details
    }

    const { success, ...rest } = await GqlClient.mutation({ mutation: UPDATE_USER, variables: { UpdateUserInput: payload } })

    if (!hasError(success, 'message')) {
      this.updateUserFields(details)
    }

    this.thinking = false

    return { error: !success, success }
  }

  @action
  async join (id) {
    const { success,  ...rest } = await GqlClient.mutation({ mutation: JOIN_TO_BRAND, variables: { id } })

    if (!hasError(success, 'message')) {
      const user = rest.data['joinToBrandViaInvite']
      // this.updateUserFields(user)
      return { success, user }
    }
    return { success }
  }

  @action
  updateDataCounts ({ leads, reports }) {
    this.reports = reports || this.reports
    this.leads = leads || this.leads
  }

  @action
  async updateEmail (email, path) {
    this.thinking = true
    const { user, success, message } = await Put(`/api/discovery/users/update-email`, {
      ...(email && { email }),
      ...(path && { path }),
    })
    if (success) {
      this.updateUserFields(user)
    }
    this.thinking = false
    return { error: message }
  }

  @action
  async verify (token) {
    GqlClient.removeToken()

    const { success } = await GqlClient.mutation({ mutation: VERIFY_USER, variables: {}, authToken: token })

    hasError(success, null, '')

    return { success }
  }

  @action
  async suspend (token) {
    this.thinking = true

    const { user, success, message } = await Put(`/api/users/suspend`, { token })

    if (!hasError(success, message, 'You’ve been unsubscribed.')) {
      this.updateUserFields(user)
    }

    this.thinking = false

    return { success }
  }

  @computed
  get user () {
    if (this.authorized) {
      const { id, firstName, lastName, email, brands, leads, reports, role, hasPassword, emailStatus } = this
      const fullName = !firstName && !lastName ? email : `${firstName ? firstName : ''} ${lastName ? lastName : ''}`.replace(/\s+/g, ' ').trim()

      return { id, firstName, lastName, email, fullName, leads, reports, brands: toJS(brands), role, hasPassword, emailStatus }
    }
    return {}
  }

  @computed
  get fullName () {
    if (!this.firstName && !this.lastName) return this.email

    return `${this.firstName || ''}${this.lastName ? ` ${this.lastName}` : ''}`
  }
}

export default new UserStore()
