import React from 'react'
import { Grid } from '@material-ui/core'
import TextRows from '../TextRows'
import { FormsModal } from './FormsModal'

export default class KeywordsForm extends React.Component {
  render() {
    const {
      keywords,
      errors,
      onChange,
      variant
    } = this.props
    const Text = () =>
      <>
        <h2 style={{marginTop: 18}}>Add the Keywords your customers use to find you or similar Products.</h2>
        <p className={'prospect'}>Input at least 10 relevant Keywords. We recommend even more for smarter results!</p>
        <p>Sites that rank for these Keywords have a decent chance of being future partners. They may be content partners or complimentary businesses.</p>
        <br/>
        <h3>Tips</h3>
        <ol>
          <li>People searching for a tool like Breezy would search things like: <strong>find marketing
            partners,</strong> <strong>discover
            affiliates,</strong> <strong>get blogs to promote&nbsp;me.</strong></li>
          <li>Ask your PPC team for <strong>100-250 keywords</strong> related to your product. Copy and paste the column straight into the textbox.
          </li>
        </ol>
      </>

    return (
      <FormsModal
        Text={Text}
      >
        <Grid container style={{marginTop: '16px'}}>
          <Grid item xs={12}>
        <TextRows
          // maxRow={400}
          title={variant === 'update' ? 'Update Keywords' :'Enter Keywords'}
          validation={'keywords'}
          helpText={'One keyword or phrase per line'}
          errorText={'Please enter keyword or phrase'}
          values={keywords}
          errors={errors}
          onChange={onChange}
        />
          </Grid>
        </Grid>
      </FormsModal>
    )
  }
}
