import { observable, action, computed, toJS } from 'mobx'
import GqlClient from '../gql/gql-client'
import { GET_BRANDS } from '../gql/brands'

class BrandsStore {
  @observable loading = true
  @observable thinking = false
  @observable list = []
  @observable currentBrand = null

  constructor () {
    // this.load().then()
  }

  @action
  async load () {
    this.loading = true

    const { success, ...rest } = await GqlClient.query({ query: GET_BRANDS })

    if (success) {
      this.list = rest.data['brands']
    }

    this.loading = false
    return { message: '' }
  }

  @action
  updateBrandById (brand) {
    const { id } = brand

    this.list = toJS(this.list).map(b => {
      if (b.id === id) {
        return brand
      }
      return b
    })
  }

  @computed
  get brands () {
    return toJS(this.list)
  }
}

export default new BrandsStore()
