import React from 'react'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { LoaderOverlay } from '../../components/Loader'
import { Finish } from '../../components/Icons/Finish'
import { CheckEmail } from '../../components/Icons/CheckEmail'

const Root = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 104px);
  margin: -50px auto 0 auto;
  h3 {
    font-family: "GT Super Display", serif;
    font-size: 24px;
    line-height: 28px;
    color: #000021;
    margin: 20px 0 0 0;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #000021;
    margin: 16px 0 0 0;
  }
`

@inject(({ UserStore }) => ({
  thinking: UserStore.thinking,
  onVerify: token => UserStore.verify(token),
  onSignin: token => UserStore.signinByToken(token)
}))
@observer
@withRouter
export default class Verify extends React.Component {
  constructor (props) {
    super(props)
    const {
      location: { search }
    } = this.props

    const { token } = qs.parse(search)

    this.state = { token, loading: true }
  }

  componentDidMount () {
    this.onVerify().then()
  }

  onVerify = async () => {
    const { token } = this.state
    const { success } = await this.props.onVerify(token)

    this.setState({ success, loading: false })

    if (success) {
      setTimeout(async () => {
        const { role } = await this.props.onSignin(token)

        if (role) {
          const pathname = role === 'creator' ? '/creator/inbox' : '/brand'
          this.props.history.push(pathname)
        }

      }, 3000)
    }

  }

  render () {
    const { success, loading } = this.state

    return (
      <div>
        { loading && <LoaderOverlay /> }

        {
          !loading && (
            <Root>
              {/*<div style={{flex: '1 1 auto'}} />*/}
              <div style={{flex: '1 1 auto', margin: '4em 0', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}>
                { success && <CheckEmail /> }
                {/*: <Finish/> }*/}


                <h3>{ success ? 'Welcome to Breezy' : 'Something went wrong.' }</h3>
                <p>
                  {
                    success ? 'Your email is verified now. Proceeding to your account...' : 'Our team has been notified about this issue. If you need any help, please feel free to get in touch with our customer success team at any time.'
                  }
                </p>
              </div>
              <div style={{flex: '2 1 auto'}} />
            </Root>
          )
        }
      </div>
    )
  }
}
