import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import ChannelForm from '../Forms/Channel'
import CompanyForm from '../Forms/Company'
import ProductNameForm from '../Forms/ProductName'
import CompetitorsForm from '../Forms/Competitors'
import PartnersForm from '../Forms/Partners'
import KeywordsForm from '../Forms/Keywords'

import {
  Tab,
  Tabs,
  Content,
  Close,
  TitleDialog,
  Title,
  ActionsHolder,
  DialogTitle,
  Modal
} from '../Modal'

class ProductOnboarding extends React.Component {
  static defaultProps = {
    steps: ['company', 'name', 'partners', 'competitors', 'keywords'],
    name: '',
    competitors: [],
    keywords: [],
    partners: []
  }

  constructor(props) {
    super(props)
    this.state = {
      thinking: false,
      name: props.name,
      description: props.description,
      keywords: props.keywords,
      keywordsErrors: [],
      competitors: props.competitors,
      competitorsErrors: [],
      partners: props.partners,
      partnersErrors: [],
      step: props.step,
      steps: props.create ?
        ['channel', 'name', 'competitors', 'keywords']
        :
        ['company', 'channel', 'name', 'partners', 'competitors', 'keywords'],
      hasChangeCompetitors: props.step === 'keywords'
    }
    this.companyForm = null
    this.channelForm = null
    this.productNameForm = null
  }

  get nextStep() {
    const {step, steps} = this.state
    const stepIndex = steps.indexOf(step)
    if (stepIndex < steps.length - 1) {
      return steps[stepIndex + 1]
    }
    return null
  }

  get previousStep() {
    const {step, steps} = this.state
    const stepIndex = steps.indexOf(step)
    if (stepIndex === 0) {
      return step
    }
    return steps[stepIndex - 1]
  }

  createRef = formName => node => this[formName] = node

  onPreviousStep = () => {
    this.setState({step: this.previousStep})
  }

  onNextStep = () => {
    this.setState({step: this.nextStep})
  }

  onSubmitCompany = async (details) => {
    this.setState({thinking: true})
    const {error} = await this.props.onCreateCompany({...details, noMessage: true})
    const nextStep = this.nextStep
    if (nextStep) {
      this.setState({thinking: false, step: nextStep})
    }
  }

  onSubmitChannel = async (details) => {
    this.setState({thinking: true})
    const {error} = await this.props.onCreateChannel({...details, noMessage: true})
    const nextStep = this.nextStep
    if (nextStep) {
      this.setState({thinking: false, step: nextStep})
    }
  }

  onSubmitProductName = async (details) => {
    this.setState({ thinking: true })
    const { error } = await this.props.onUpdate({...details, noMessage: true })
    if (!error) {
      const nextStep = this.nextStep
      if (nextStep) {
        this.setState({ thinking: false, step: nextStep })
      } else {
        this.props.onClose()
      }
    } else {
      this.setState({ thinking: false })
    }
  }

  onSubmitPartners = async () => {
    const nextStep = this.nextStep
    const { partners } = this.state

    this.setState({thinking: true})

    const { error } = await this.props.onImportPartners({ partners })

    if (!error) {
      this.setState({ step: nextStep })
    }

    this.setState({thinking: false })
  }

  onChange = kind => (value, errors) => {
    this.setState({[`${kind}Errors`]: errors})
    this.setState({[`${kind}`]: value})
    if(kind === 'competitors') {
      this.setState({hasChangeCompetitors: false})
    }
  }

  onSubmit = async () => {
    this.setState({thinking: true})
    const {
      keywords,
      competitors,
    } = this.state
    let competitorsErrors = this.state.competitorsErrors
    let keywordsErrors = this.state.keywordsErrors
    const nextStep = this.nextStep
    let errorOnUpdate
    if (nextStep) {
      if (competitors.length) {
        if (competitors[0].length < 3) {
          competitorsErrors.push(0)
        }
      } else {
        competitorsErrors.push(0)
      }
      if (!!competitorsErrors.length) {
        this.setState({competitorsErrors})
        this.setState({thinking: false})
        return null
      } else {
        errorOnUpdate = await this.props.onUpdate({competitors, noMessage: true})
      }
    } else {
      if (keywords.length) {
        if (keywords[0].length < 3) {
          keywordsErrors.push(0)
        }
      } else {
        keywordsErrors.push(0)
      }
      if (!!keywordsErrors.length) {
        this.setState({keywordsErrors})
        this.setState({thinking: false})
      } else {
        errorOnUpdate = await this.props.onUpdate({keywords, noMessage: true})
      }
    }
    if (errorOnUpdate) {
      const {error} = errorOnUpdate
      if (!error) {
        if (nextStep) {
          this.setState({thinking: false, step: nextStep})
          this.setState({hasChangeCompetitors: true})
        } else {
          this.props.onClose()
        }
      } else {
        this.setState({thinking: false})
      }
    }
  }

  onSubmitStep = () => {
    const { step } = this.state
    const event = new Event('submit', { 'bubbles': true, 'cancelable' : true })

    if (step === 'company') {
      this.companyForm.dispatchEvent( event )
    } else if (step === 'channel') {
      this.channelForm.dispatchEvent( event )
    } else if (step === 'name') {
      this.productNameForm.dispatchEvent( event )
    } else if (step === 'partners') {
      this.onSubmitPartners().then()
    } else {
      this.onSubmit().then()
    }
  }

  render() {
    const {
      step,
      steps,
      thinking,
      partnersErrors,
      competitorsErrors,
      keywordsErrors,
      keywords,
      competitors,
      partners
    } = this.state
    const {
      create,
      open,
      name,
      description,
      link,
      company
    } = this.props

    const currentStepIndex = steps.indexOf(step)

    return (
      <>
        <Modal
          fullWidth="true"
          maxWidth="md"
          open={open}
          ariaLabelledby="dialog-title"
          onBackdropClick={this.props.onClose}
          onEscapeKeyDown={this.props.onClose}
        >
          <DialogTitle>
            <Close aria-label="close" onClick={this.props.onClose}>
              <CloseIcon/>
            </Close>
            <TitleDialog>
              Setup Partner Search for your { create ? '' : 'first ' } Product
            </TitleDialog>
            <Tabs>
              {
                !create && (
                  <Tab label={<p>{'Brand'}</p>}
                       disabled={step !== 'company'}
                       value={'company'} disableRipple disableTouchRipple disableFocusRibble/>
                )
              }

              <Tab label={<p>{'Website'}</p>}
                   disabled={step !== 'channel'}
                   value={'channel'} disableRipple disableTouchRipple disableFocusRibble/>

              <Tab label={<p>Product</p>}
                   disabled={step !== 'name'} value={'name'} disableRipple disableTouchRipple disableFocusRibble/>

              {
                !create && (
                  <Tab label={<p>Partners {!!partners.length && <span>{partners.length}</span>}</p>}
                       disabled={step !== 'partners'}
                       value={'partners'} disableRipple disableTouchRipple disableFocusRibble/>
                )
              }

              <Tab label={<p>Competitors {!!competitors.length && <span>{competitors.length}</span>}</p>}
                   disabled={step !== 'competitors'}
                   value={'competitors'} disableRipple disableTouchRipple disableFocusRibble/>
              <Tab label={<p>Keywords {!!keywords.length && <span>{keywords.length}</span>}</p>}
                   disabled={step !== 'keywords'} value={'keywords'} disableRipple disableTouchRipple
                   disableFocusRibble/>
              <Tab className={'steps'}
                   label={<p>Step&nbsp;<strong>{currentStepIndex+1}</strong><span></span><strong>{steps.length}</strong></p>}
                   disabled disableRipple disableTouchRipple disableFocusRibble/>
            </Tabs>
          </DialogTitle>
          <Content>
            {
              step === 'company' && (
                <CompanyForm
                  onboarding
                  {...company}
                  thinking={thinking}
                  createRef={this.createRef('companyForm')}
                  onSubmit={this.onSubmitCompany}
                />
              )
            }
            {
              step === 'channel' && (
                <ChannelForm
                  link={link}
                  thinking={thinking}
                  createRef={this.createRef('channelForm')}
                  onSubmit={this.onSubmitChannel} />
              )
            }
            {
              step === 'name' && (
                <ProductNameForm
                  onboarding
                  thinking={thinking}
                  name={name}
                  description={description}
                  createRef={this.createRef('productNameForm')}
                  onSubmit={this.onSubmitProductName}
                />
              )
            }
            {
              step === 'competitors' && (
                <CompetitorsForm
                  onboarding
                  thinking={thinking}
                  competitors={competitors}
                  errors={competitorsErrors}
                  onChange={this.onChange('competitors')}
                />
              )
            }
            {
              step === 'partners' && (
                <PartnersForm
                  onboarding
                  thinking={thinking}
                  competitors={partners}
                  errors={partnersErrors}
                  onChange={this.onChange('partners')}
                />
              )
            }
            {
              step === 'keywords' && (
                <KeywordsForm
                  onboarding
                  thinking={thinking}
                  keywords={keywords}
                  errors={keywordsErrors}
                  onChange={this.onChange('keywords')}
                />
              )
            }
          </Content>
          <ActionsHolder borderTop right>
            {/*{*/}
            {/*  step === 'partners' && (*/}
            {/*    <Button*/}
            {/*      disabled={thinking}*/}
            {/*      color="primary"*/}
            {/*      variant="outlined"*/}
            {/*      mb={2}*/}
            {/*      mr={4}*/}
            {/*      onClick={this.onNextStep}*/}
            {/*    >*/}
            {/*      Skip*/}
            {/*    </Button>*/}
            {/*  )*/}
            {/*}*/}
            {
              currentStepIndex !== 0 && (
                <Button
                  disabled={thinking}
                  color="primary"
                  variant="outlined"
                  mb={2}
                  mr={4}
                  onClick={this.onPreviousStep}
                >
                  Previous
                </Button>
              )
            }
            <Button
              onClick={this.onSubmitStep}
              variant="contained"
              color="primary"
              mb={2}
              disabled={thinking}
            >
              { step !== 'keywords' ? 'Continue' : 'Done' }
            </Button>
          </ActionsHolder>
        </Modal>
      </>
    )
  }
}

export default ProductOnboarding
