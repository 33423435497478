import { Get } from '../utils/request'
import { action, computed, observable, toJS } from 'mobx'
import { orderBy, sumBy, sortBy, findIndex, groupBy, chain } from 'lodash'
import config from '../config'

const convertRootToListItem = (leadId, channel) => {
  const {
    leadId: id,
    link,
    mainTitle,
    screenshot,
    icon,
    type1Classification
  } = channel

  return {
    id,
    url: link,
    title: mainTitle,
    screenshot: screenshot,
    icon,
    type1Classification
  }
}

const convertRelevantToListItem = lead => {
  const { url, title, icon, breezyScore, pageAuthority, keywordSeeds, competitorSeeds } = lead
  return {
    id: url,
    url,
    title,
    icon,
    fields: [
      {type: 'breezyScore', value: breezyScore },
      {type: 'pageAuthority', value: pageAuthority},
      {type: 'keywords', value: (keywordSeeds || []).length},
      {type: 'competitors', value: (competitorSeeds || []).length}
    ],
    keywordSeeds,
    competitorSeeds
  }
}

const toKeywordAndRank = (keywords) => {
  if (!keywords && !keywords.length) return []

  const result = keywords.map(keyword => {
    const splited = keyword.split(' ::')
    return {
      keyword: splited[0],
      position: splited[1]
    }
  })

  return orderBy(result, ['position'], ['asc']).slice(0, 3).map(({ keyword, position }) => `${keyword} ranking ${position}`)
}

const initialProspect = {
  channelId: null,
  leadId: null,
  channel: {},
  lead: {},
  product: {},
  campaigns: [],

  loading: true
}

class ProspectStore {
  leadId
  channelId

  @observable channel = {}
  @observable lead = {}
  @observable product = {}
  @observable contactRequest = {}
  @observable contactInfos = []
  @observable campaigns = []

  @observable loading = true
  @observable loadingChannel = true
  @observable loadingView = true
  @observable thinking = false

  constructor () {}

  updateProspect (lead) {
    for( let key in lead) {
      if (lead.hasOwnProperty(key)) {
        this[key] = lead[key]
      }
    }
  }

  @action
  async load (leadId, channelId) {
    this.loading = true

    this.updateProspect(initialProspect)
    this.leadId = leadId
    this.channelId = channelId

    this.loadChannel().then()
    this.loadView().then()

    this.loading = false
  }

  @action
  async loadView () {
    this.loadingView = true
    const { message, lead, product, contactRequest, contactInfos } = await Get(`/api/discovery/leads/${this.leadId}`)
    if (!message) {
      this.lead = lead
      this.product = product
      this.contactRequest = contactRequest || {}
      this.contactInfos = contactInfos || []
    }
    this.loadingView = false
  }

  @action
  async loadChannel () {
    this.loadingChannel = true
    const { message, channel, campaigns } = await Get(`/api/discovery/channels/${this.channelId}/details`)
    if (!message) {
      this.channel = channel
      this.campaigns = campaigns
    }
    this.loadingChannel = false
  }

  @action
  updateContactRequestStatus () {
    this.contactRequest = {
      status: 'requested'
    }
  }

  @computed
  get contacts () {
    return toJS(this.contactInfos)
  }

  @computed
  get details () {
    const root = convertRootToListItem(this.leadId, this.channel)
    const relevant = toJS(this.lead.urls || []).map(url => convertRelevantToListItem({ ...url, icon: this.channel.icon }))
    const keywordsByRelevant = toJS(this.lead.urls || []).map(({ url, keywordSeeds }) => ({
      url,
      list: keywordSeeds,
      icon: this.channel.icon
    }))
    const competitorsByRelevant = toJS(this.lead.urls || []).map(({ url, competitorSeeds }) => ({
      url,
      list: competitorSeeds,
      icon: this.channel.icon
    }))

    return {
      root,
      relevant,
      targetWebsite: this.channel.link,
      prospect: !!this.lead.prospectId && this.lead.internalRelevant,
      partner: !!this.lead.partner,
      countries: orderBy(toJS(this.channel.countries || []).map(({ code, ...rest }) => {

        return { ...rest, iso: code }
      }), ['users'], ['desc']),
      competitors: toJS(this.lead.competitorSeeds || []),
      keywords: toJS(this.lead.keywordSeeds || []),
      keywordsByRelevant,
      competitorsByRelevant,
      country: toJS(this.product).country,
      contactRequestStatus: toJS(this.contactRequest).status
    }
  }

  @computed
  get relevantCopy () {
    const { urls } = toJS(this.lead)
    const { country } = toJS(this.product)

    if (!urls || !urls.length) return null

    return urls.map(({ title, url, keywordSeeds, competitorSeeds }) => {
      const keywordsCount = keywordSeeds.length
      const competitorsCount = competitorSeeds.length
      const source = config.ENGINES[country] ? config.ENGINES[country].engine : null

      return {
        title,
        url,
        keywordsCount,
        competitorsCount,
        ...(keywordsCount && { keywords: toKeywordAndRank(keywordSeeds) }),
        ...(competitorsCount && { competitors: competitorSeeds }),
        ...(source && { source })
      }
      //
      // return [
      //   url,
      //   ...(competitorSeeds.length ? [
      //     `Competitors count ${competitorSeeds.length}`,
      //     `Competitors ${competitorSeeds.join(', ')}`
      //   ] : []),
      //   ...(keywordSeeds.length ? [
      //     `Keywords count ${keywordSeeds.length}`,
      //     `Top 3 Keywords ${toKeywordAndRank(keywordSeeds).join(', ')}`
      //   ] : []),
      //   ...(config.ENGINES[country] ? [`Source: ${config.ENGINES[country].engine}`] : [])
      // ]
    })
  }

  @computed
  get affiliatesLinks () {
    const campaigns = Object
      .entries(groupBy(toJS(this.campaigns), 'inChannelLink'))
      .map(([name, rows]) => {
        const [firstPartnership] = rows
        return {
          id: firstPartnership.inChannel,
          name,
          icon: firstPartnership.inChannelIcon,
          links: sumBy(rows, 'count'),
          total: rows.length,
          rows: rows.map(({ id, adex, count, partnerId, icon, ...rest }) => {
            let networks = adex ? adex.split(/:/) : []
            const amazonIndex = networks.indexOf('Amazon')

            if (amazonIndex >= 0) {
              networks[amazonIndex] = 'Amazon Associates'
            }

            return {
              id,
              name: rest.inChannelLink,
              networks,
              links: count,
              partnerId,
              icon
            }
          })
        }
      })

    return sortBy(campaigns, 'links').reverse()
  }

  @computed
  get campaignsAmount () {
    return toJS(this.channel).partnershipsOutCount || 0
  }

  @computed
  get affiliateNetworksDistribution () {
    const total = toJS(this.campaigns).length //sumBy(toJS(this.campaigns), 'count')
    let data = []

    toJS(this.campaigns).forEach(({ adex }) => {
      const splitedAdex = adex ? adex.split(/:/) : []
      const firstAdex = splitedAdex[0]

      if (firstAdex) {
        const index = findIndex(data, ['name', firstAdex])
        if (index >= 0) {
          data[index].count += 1
        } else {
          data.push({ name: firstAdex, count: 1 })
        }
      }
    })

    return sortBy(data.map(({ name, count }) => ({
      name,
      count,
      share: ((count / total) * 100)
    })), 'share').reverse()
  }

  @computed
  get languages () {
    const { lang } = toJS(this.channel)
    return lang && lang !== 'undetected' ? [lang] : []
  }
}

export default ProspectStore
