import React, { useEffect, useState } from 'react'
import { MenuItem, TextField } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import GqlClient from '../../gql/gql-client'
import { GET_ACCOUNT_USERS } from '../../gql/members'
import Autocomplete from '../Autocomplete'

const getUserName = (user) => {
  const { firstName, lastName, email } = user

  if (!firstName && !lastName) return email

  return `${firstName} ${lastName}`
}

function RequesterFilter ({ brandId, disabled, value, placeholder, onChange }) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    GqlClient.query({ query: GET_ACCOUNT_USERS, variables: { brandId } }).then(({ success, ...rest }) => {
      if (success) {
        setOptions(rest.data['accountUsers'].filter(({ status }) => 'joined' === status).map(({ user}) => ({
          value: user.id,
          label: getUserName(user)
        })))
      }
    })

  }, [])

  return (
    <Autocomplete
      disabled={disabled}
      single
      options={options}
      value={value}
      onChange={(e, a) => onChange(a)}
      placeholder={(!!value && value.length > 0) ? false : placeholder}
    />
  )

}

export default inject(({ BrandStore }) => ({
  brandId: BrandStore.id
}))(observer(RequesterFilter))


