import React from 'react'
import styled from 'styled-components'

import SearchSummary, { SearchInterface } from './SearchSummary'
import ProfileSection from '../ProfileSection'

const Root = styled(ProfileSection)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  //width: 100px;
  gap: 0.5em;
`

interface AppearsInSearchesProps {
  loading?: boolean
  searches: Array<SearchInterface>
  to: string
}

export default function AppearsInSearches(props: AppearsInSearchesProps) {
  const { loading, searches, to } = props

  return (
    <Root title={'Appears in searches'} fullWidth>
      {searches.map((search) => (
        <SearchSummary
          key={search.id}
          to={`${to}/${search.id}`}
          loading={loading}
          {...search}
        />
      ))}
    </Root>
  )
}
