import React from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import FolderList from './FolderList'

@inject(({ RelevantFoldersStore, RelevantsStore }) => ({
  loading: RelevantFoldersStore.loading,
  folders: RelevantFoldersStore.folders,
  onCreate: (name) => RelevantFoldersStore.create(name),
  onUpdate: (id, name) => RelevantFoldersStore.update(id, name),
  onRemove: (id) => RelevantFoldersStore.remove(id),

  onReorder: (folders) => RelevantFoldersStore.reorder(folders),

  onUpdateItemFields: callback => RelevantsStore.updateItemFields(callback)
}))
@observer
@withRouter
class _RelevantFolderList extends React.Component {
  constructor (props) {
    super(props)
  }

  onCancel = id => {
    if (id === 'new') {
      this.props.onRemove(id)
    }
  }

  onRemove = (id) => {
    const { path, pathname } = this.props
    this.props.onRemove(id)
    if (pathname.includes(id)) {
      this.props.history.push(path)
    }
    this.props.onUpdateItemFields(item => {
      const { folder, ...rest } = item
      if (!folder || folder.id === id) return rest
      return item
    })
  }

  onUpdate = async (id, name) => {
    if (id === 'new') {
      return await this.props.onCreate(name)
    } else {
      const { success } = await this.props.onUpdate(id, name)
      if (success) {
        this.props.onUpdateItemFields(item => {
          const { folder, ...rest } = item
          if (!folder || folder.id !== id) return rest
          return {
            ...rest,
            folder: { ...folder, name }
          }
        })
      }
      return { success }
    }
  }

  render () {
    const{ loading, folders, path, pathname } = this.props

    return <FolderList
      path={path}
      pathname={pathname}
      loading={loading}
      folders={folders}
      kind={'relevant'}
      onUpdate={this.onUpdate}
      onRemove={this.onRemove}
      onCancel={this.onCancel}
      onAddFolder={this.props.onAddFolder}

      onReorder={this.props.onReorder}
    />

  }
}

export default _RelevantFolderList
