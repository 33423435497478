import React, { useContext, useState } from 'react'
import { DetailsContext } from '../DetailsContext'
import Summary from '../../Summary'

interface ItemSummaryProps {
  disableTagManagement?: boolean
  horizontal?: boolean
  size?: string
  tagManagement?: {
    onUpdate: Function
    onRemove: Function
    onDelete: Function
    onManage: Function
  }
  onQuickActions?: () => void
}

export default function ItemSummary(props: ItemSummaryProps) {
  const { loading, tags, systemTags, summary, item, variant } =
    useContext(DetailsContext)

  const itemId = (item || {}).id

  const onManageTags = (e) => {
    props.tagManagement.onManage({ target: e.target }, itemId)
  }

  const onUpdateTag = (tag) => {
    props.tagManagement.onUpdate(tag)
  }

  const onRemoveTag = (tagId) => {
    props.tagManagement.onRemove(tagId, itemId)
  }
  const onDeleteTag = (tagId) => {
    props.tagManagement.onDelete(tagId)
  }

  return (
    <Summary
      disableTagManagement={props.disableTagManagement}
      id={`${props.horizontal ? 'horizontal-' : ''}-summary`}
      loading={loading}
      {...summary}
      tags={tags}
      systemTags={systemTags}
      horizontal={props.horizontal}
      size={props.size}
      tagManagement={{
        onUpdate: onUpdateTag,
        onRemove: onRemoveTag,
        onDelete: onDeleteTag,
        onManage: onManageTags,
      }}
    />
  )
}
