import React from 'react'
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import ModalLayout from '../../layouts/ModalLayout'
import Button from '../../components/Button'


const ExpiredSearchModal = ({ open, daysLeft, onClose, onContact }) => {
  return <Dialog
    open={open}
    aria-labelledby="introduction-modal"
    maxWidth={'sm'}
    fullWidth="true"
    onEscapeKeyDown={onClose}
  >
    <ModalLayout
      noPadding
      onClose={onClose}
    >
      <DialogContent>
        <Typography variant="h6" gutterBottom>This search has expired</Typography>
        <Typography variant="subtitle1" gutterBottom>
          We store your results for 30 days after a search expires. You have {daysLeft} days left to reactivate your search before the data is deleted.
        </Typography>
      </DialogContent>

      <DialogActions style={{justifyContent: 'center',  paddingBottom: 20, paddingTop: 20, flexDirection: 'column'}}>
        <Button variant={'contained'} color={'primary'} size={'large'} onClick={() => onContact()}>Contact us</Button>
      </DialogActions>
    </ModalLayout>
  </Dialog>
}

export default ExpiredSearchModal
