import React from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import DetailsSection from '../DetailsSection'

const Root = styled(Paper)`
padding: 1em;
font-size: 14px;
margin-bottom: 0.5em;
`
const Message = styled.div`
  padding: 23px 27px;
  display: flex;
  justify-content: center;
  color: #828282;
`

const Pane = ({children, empty, emptyMessage}) => (
  <Root elevation={0}>{empty ? <Message>{emptyMessage}</Message> : children}</Root>
)

Pane.defaultProps = {
  emptyMessage: 'No data'
}

export default Pane