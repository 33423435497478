import React from 'react'
import styled, {css} from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import pluraliseUnit from "../../utils/pluraliseUnit";
import Button from '@material-ui/core/Button'

const Root = styled.div`
  display: inline-flex;
  align-items: center;
  ${props => props.active && css`
    &:after {
      content: 'Changes';
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.7em;
      padding: 0.15em 0.2em;
      line-height: 1em;
      border-radius: 3px;
      color: ${props.theme.palette.error.main};
      border: 1px solid ${props.theme.palette.error.main};
    }
  `}
`

const Key = styled.span`
  padding-left: 0.1em;
`
const Value = styled.span`
  font-weight: bold;
`

const Group = styled.span`
  font-size: 12px;
  line-height: 150%;
  margin-right: 0.8em;
  color: ${props => props.active 
          ? (props.inverse 
                  ? props.theme.palette.primary.contrastText 
                  : props.theme.palette.primary.main
          ) 
          : 'currentColor'
  };
  ${Key} {
    opacity: ${props => props.active ? 1 : 0.5};
  }
  ${Value} {
    opacity: ${props => props.active ? 1 : 0.75};
  }
`

const Inputs = ({inverse, disableTooltip, keywordsCount, keywordsChanged, competitorsCount, competitorUrlsChanged, irrelevantsCount, irrelevantsChanged}) =>
  <Tooltip interactive enterDelay={300} title={ disableTooltip ? ''
    : <>
      <strong>Search settings</strong>
      <div>Tweak settings to get better leads</div><br />
      <div>{keywordsCount} {pluraliseUnit('Keyword', keywordsCount)}</div>
      <div>{competitorsCount} {pluraliseUnit('Competitor', competitorsCount)}</div>
      <div>{irrelevantsCount} Irrelevant</div>
      {(keywordsChanged || competitorUrlsChanged) && <strong><br/>Search settings are changed. Re-run Search to get new leads.</strong>}
      {/*{(keywordsChanged || competitorUrlsChanged) && <Button style={{marginTop: '0.5em'}} color="primary" variant="contained" size="small">Re-run</Button>}*/}
    </>
  }>
<Root active={keywordsChanged || competitorUrlsChanged} inverse={inverse}>
  <Group active={keywordsChanged} inverse={inverse}><Value>{keywordsCount}</Value> <Key>KW</Key></Group>
  <Group active={competitorUrlsChanged} inverse={inverse}><Value>{competitorsCount}</Value> <Key>CP</Key></Group>
  <Group><Value>{irrelevantsCount || 0}</Value> <Key>IR</Key></Group>
</Root>
</Tooltip>

export default Inputs
