import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Favicon from '../FavIcon/FavIcon'
import { OpenInNew } from '@material-ui/icons'
import { ArrowRightCircle } from 'react-feather'
import cleanUrl from '../../utils/cleanUrl'
import {SkeletonBase} from '../Skeleton'


const Root = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const LinkTextActionRoot = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: opacity 0.3s ease;
  color: currentColor;
`
const LinkTextWrapper = styled.div`
    width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ${props => props.rtl && 'rtl'};
  transition: mask-image 0.3s ease;
  line-height: inherit;
  font-weight: inherit;
`
const LinkIcon = styled(ArrowRightCircle)`
  stroke: currentColor;
  width: 15px;
  height: 15px;
  flex: 0 0 15px;
  opacity: 0.5;
  transform: rotate(-45deg);
`

const LinkTextRoot = styled.div`
  position: relative;
  display: inline-block;
  min-width: 50px;
  max-width: 100%;
  height: 1.2em;
  line-height: 1.2em;
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  &:hover ${LinkTextWrapper} {
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 2), rgba(0, 0, 0, 0));
  }
  &:hover ${LinkTextActionRoot} {
    opacity: 1;
  }
  &:hover ${LinkIcon} {
    opacity: 1;
  }
  ${props => props.obfuscate && css`
        filter: blur(8px);
  `}
`

function LinkTextAction ({ href }: {href?: string}) {
    const onClick = (e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
    }

    return (
        <LinkTextActionRoot onClick={onClick} target="_blank" href={/^https?:\/\//.test(href) ? href : `http://${href}`}>
            <LinkIcon />
        </LinkTextActionRoot>
    )
}

function LinkText ({ href, path,  loading, obfuscate }: {href?: string, path?:string, loading?: boolean, obfuscate?: boolean}) {
    return (
        <LinkTextRoot obfuscate={obfuscate}>
            {loading
                ? <SkeletonBase width="15ch" height="1.2em" />
                : <>
                    <LinkTextWrapper>{path || cleanUrl(href)}</LinkTextWrapper>
                    <LinkTextAction href={href}/>
                </>}
        </LinkTextRoot>
    )
}

interface IconLinkProps {
    icon?: string
    domain?: string
    loading?: boolean
    className?: string
    path?: string,
    noIcon?: boolean,
    obfuscate?: boolean
}

function IconLink ({ icon, domain, loading, obfuscate, path, className, noIcon }: IconLinkProps) {
    return (
        <Root className={className}>
            {noIcon ? '' : <Favicon src={icon} domain={domain} size={1.2} loading={loading} />}
            <LinkText href={domain} path={path} loading={loading} obfuscate={obfuscate}/>
        </Root>
    )
}

export default IconLink
