import React from 'react'
import styled from 'styled-components'
import { Domain } from '../Summary'

const Header = styled.div`
  // color: ${props => props.theme.palette.accentDarkText};
  // font-weight: bold;
  // font-size: 1.2em;
  // display: flex;
  // gap: 0.5em;
`

const Search = styled.div`
  position: relative;
`
const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  flex: 1 1 auto;
  overflow: hidden;
`

const Root = styled.div`
    min-height: 10vh;
  display: flex;
  gap: 1em;
  flex-direction: column;
  width: 100%;
  grid-column: span 2;
`


function LayoutSearchableList ({ children, title, filter, domain }) {
    return (
        <Root>
            <Header><Domain prefix={title} {...domain} /></Header>
            <Search>
                {filter}
            </Search>
            <List >
                {children}
            </List>
        </Root>
    )
}

export default LayoutSearchableList
