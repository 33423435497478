import React, { useContext } from 'react'

import {CreatorRequestContext} from '../CreatorRequestDetailsContext'
import NetworksFull from '../../Profile/NetworksFull'

export default function CreatorRequestsProfileNetworksUse (props) {
    const { loading, networks } = useContext(CreatorRequestContext)

    if (!loading && (!networks || !networks.length)) return null

    return <NetworksFull loading={loading} networks={networks} />
}
