function titleToId(title) {
  // Remove any non-word characters and convert to lowercase
  const slug = title.toLowerCase().replace(/[^\w\s]/g, '');

  // Replace whitespace with hyphens
  const hyphenatedSlug = slug.replace(/\s+/g, '-');

  // Remove short words (less or equal than 3 characters) and numbers
  const words = hyphenatedSlug.split('-').filter(word => {
    const isShort = word.length <= 3;
    const isNumber = !isNaN(parseInt(word));
    return !isShort && !isNumber;
  });

  // Join the remaining words with hyphens
  return words.join('-');
}

export default titleToId;