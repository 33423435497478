import React from 'react'
import styled from 'styled-components'
import { Button as MuiButton, DialogTitle, Tabs } from '@material-ui/core'
import { ActionsHolder, Close, Content, Modal, Tab, TitleDialog } from '../Modal'
import CloseIcon from '@material-ui/icons/Close'
import Account from './Account'
import Email from './Email'
import ResetPassword from './ResetPassword'

const Button = styled(MuiButton)`
  text-transform: none;
  font-weight: 600;
`

const TemporaryTabsHolder = styled.div`
  padding: 0 20px;
`

function TabPanel(props) {
  const {children, value, index} = props;
  return (
    value === index && (
      <>
        {children}
      </>
    )
  )
}

const DISABLE_BUTTONS = ['team', 'credits', 'billing']

export default class CreatorSettings extends React.Component {
  constructor (props) {
    super(props)
    const {tab, role} = props

    this.state = {
      tab: tab || role === 'brandOwner' ? 'brand' : 'account',
      company: null,
      user: null,
      tabsError: {},
      removeMember: false,
      memberId: null
    }
    this.companyForm = null
    this.accountForm = null
  }

  createRef = formName => node => this[formName] = node

  onTabChange = (event, value) => {
    this.setState({tab: value});
  }

  onInputsChange = kind => details => {
    if (kind === 'account') {
      const { user: stateAccount, tabsError } = this.state
      const { user: propsAccount } = this.props
      const user = stateAccount ? { ...stateAccount, ...details } : {
        firstName: propsAccount.firstName,
        lastName: propsAccount.lastName,
        email: propsAccount.email,
        ...details
      }
      this.setState({ user, tabsError: { ...tabsError, user: false } })
    }
  }

  accountValid = () => {
    const { user } = this.state
    return !user ? true : !!user.firstName && !!user.lastName
  }

  onSubmitAccount = async (details) => {
    this.setState({thinking: true})

    if (this.accountValid()) {
      const { success } = await this.props.onSubmitAccount(details)
      this.setState({thinking: false})
      return { success }
    }
    this.setState({thinking: false})
    return { success: false }
  }

  onSave = (e) => {
    const { tab } = this.state
    const event = new Event('submit', { 'bubbles': true, 'cancelable' : true })
    if (tab === 'brand') {
      this.companyForm.dispatchEvent( event )
    } else if (tab === 'account') {
      this.accountForm.dispatchEvent( event )
    }
  }

  onClose = () => {
    this.setState({
      company: null,
      user: null,
      tabsError: {}
    })
    this.props.onClose()
  }

  render() {
    const {
      tab,
      tabsError,
    } = this.state

    const {
      open,
      thinking,
    } = this.props

    const userDetails = this.state.user || this.props.user

    return (
      <Modal
        fullWidth="true"
        maxWidth="md"
        open={open}
        ariaLabelledby="dialog-setting"
        onBackdropClick={this.onClose}
        onEscapeKeyDown={this.onClose}
      >
        <DialogTitle disableTypography>
          <Close aria-label="close" onClick={this.onClose}>
            <CloseIcon/>
          </Close>
          <TitleDialog>Settings</TitleDialog>
          {/*TODO: REMOVE THIS HACK*/}
          {/*<TemporaryTabsHolder>*/}
          {/*  <Tabs value={tab} onChange={this.onTabChange}>*/}
          {/*    <Tab label="Account" value={'account'} error={tabsError.user} disableRipple disableTouchRipple disableFocusRibble/>*/}
          {/*  </Tabs>*/}
          {/*</TemporaryTabsHolder>*/}
        </DialogTitle>
        <TabPanel value={tab} index={'account'}>
          <Content>
            <Account
              initialValidation
              noAction
              noText
              fullWidth
              {...userDetails}
              disableEmail
              thinking={thinking}
              createRef={this.createRef('accountForm')}
              onChange={this.onInputsChange('account')}
              onSubmit={this.onSubmitAccount}
            />
            <Email
              {...userDetails}
              disable
              onSubmit={({ email }) => this.props.onUpdateEmail(email)}
            />
            {/*<ResetPassword*/}
            {/*  {...userDetails}*/}
            {/*  onReset={this.props.onResetPassword}*/}
            {/*/>*/}
          </Content>
        </TabPanel>

        {
          !DISABLE_BUTTONS.includes(tab) && (
            <ActionsHolder borderTop right>
              <Button onClick={this.onClose} disabled={thinking} variant="outlined"  color="primary">Dismiss</Button>
              <Button style={{margin: '0 0 0 25px'}} disabled={thinking} onClick={this.onSave} variant="contained" color="primary">Save</Button>
            </ActionsHolder>
          )
        }
      </Modal>
    )
  }
}
