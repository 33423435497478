import React from 'react'
import styled from "styled-components";
import LoaderOverlay from '../components/Loader/LoaderOverlay'

const Root = styled.div`
  opacity: 1!important;
  transform: none!important;
  z-index: 0!important;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  overflow-y: auto;
  background: ${props => props.transparent && '#F4F8FF'};
  transition: ${props => props.theme.transitions.create('', {
    easing: props.theme.transitions.easing.easeOut,
    duration: props.theme.transitions.duration.enteringScreen,
  })};
  padding: 0;
  //&.openRightBar {
  // margin: 0 calc(30% + 50px) 0 0;
  //  @media (max-width: 1270px) {
  //    margin: 0;
  //  }
  //}

`;
export default class PageMain extends React.Component {
  render () {
    const {
      open,
      thinking,
      children,
      transparent
    } = this.props

    return (
      <Root className={open && 'openRightBar'} transparent={transparent}>
        { thinking && <LoaderOverlay/> }
        { !thinking && children }
      </Root>
    )
  }
}
