import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { PrivateRoute, PublicRoute } from './components/Route'
import { Notification } from './components/Notification'

import AuthLayout from './layouts/Auth'
import Welcome from './layouts/Welcome'

import SignIn from './pages/SignIn'
import { SignUp } from './pages/SignUp'
import VerifySent from './pages/VerifySent'
import { RegistrationBrand } from './pages/RegistrationBrand'
import ForgotPassword from './pages/ForgotPassword'
import RecoverPassword from './pages/RecoverPassword'
import Verify from './pages/Verify'
import Suspend from './pages/Suspend'

import WelcomeSteps from './pages/Welcome/Welcome'
import WelcomeUser from './pages/Welcome/User'

import Page404 from './pages/404'

import Dashboard from './pages/Dashboard'
import Creator from './pages/Creator'
import Join from './pages/Join'
import Intercom from './components/Intercom/Intercom'

import ItemPreview from './new-components/Item/ItemPreview'


const privateWelcomeRoutes = [
  {
    id: 'WelcomeUser',
    path: '/welcome/:step',
    title: 'Share your information, we\'ll share your results',
    component: WelcomeSteps
  },
]

const publicRoutes = [
  {
    id: 'SigninRoot',
    path: '/',
    component: SignIn,
    layout: Welcome,
    title: 'Sign In',
    redirectToIfAuthorized: '/brand'
  },
  {
    id: 'Signin',
    path: '/signin',
    component: SignIn,
    layout: Welcome,
    title: 'Sign In',
    redirectToIfAuthorized: '/brand'
  },
  {
    id: 'Signup',
    path: '/signup',
    component: SignUp,
    layout: Welcome,
    title: 'Sign Up',
    // redirectToIfAuthorized: '/welcome/company'
  },
  {
    id: 'VerifySent',
    path: '/verify-sent',
    component: VerifySent,
    title: 'Check your email',
    // redirectToIfAuthorized: '/welcome/company'
  },
  {
    id: 'Register Brand',
    path: '/register-brand',
    component: RegistrationBrand,
    layout: Welcome,
    title: 'Brand Registration',
    redirectToIfAuthorized: '/welcome/company'
  },
  {
    id: 'ForgotPassword',
    path: '/forgot-password',
    component: ForgotPassword,
    layout: Welcome,
    title: 'Forgot Password',
    redirectToIfAuthorized: '/brand'
  },
  {
    id: 'RecoverPassword',
    path: '/recover-password',
    component: RecoverPassword,
    layout: Welcome,
    title: 'Recover Password',
    // redirectToIfAuthorized: '/brand'
  },
  {
    id: 'VerifyEmail',
    path: '/verify',
    component: Verify,
    layout: Welcome,
    title: 'Verify email'
  },
  {
    id: 'Suspend',
    path: '/suspend',
    component: Suspend,
    layout: Welcome,
    title: 'Suspend',
    // redirectToIfAuthorized: '/brand'
  }
]

const GA = process.env.GA

@withRouter
class Routes extends React.Component {
  unlistenRouteChange = null

  componentDidMount () {
    if (GA) {
      ReactGA.initialize(GA)
      this.unlistenRouteChange = this.props.history.listen(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
      })
    }
  }

  render () {

    return (
      <React.Fragment>
        <Intercom />
        <Notification />
        <ItemPreview />
        <Switch>
          {
            publicRoutes.map(({ id, path, ...routeProps}) => (
              <Route
                key={id}
                path={path}
                exact
                render={props => <PublicRoute {...routeProps} {...props} />}
              />
            ))
          }

          {
            privateWelcomeRoutes.map(({ id, path, ...routeProps}) => (
              <Route
                key={id}
                path={path}
                exact
                render={props => <PrivateRoute {...routeProps} {...props} />}
              />
            ))
          }

          <Route
            path="/creator"
            render={props => <PrivateRoute
              component={Creator}
              {...props}
            />}
          />


          <Route
            path="/brand/:brandId?"
            render={props => <PrivateRoute
              component={Dashboard}
              {...props}
            />}
          />

          <Route
            path="/join/:brandId/:accountUserId/:step?"
            render={props => <PublicRoute
              component={Join}
              {...props}
            />}
          />

          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </React.Fragment>
    )
  }
}


export default Routes
