import React from 'react'
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import ModalLayout from '../../layouts/ModalLayout'
import Button from '../../components/Button'
import config from '../../config'


const EcosystemsLockModal = ({ open, onClose }) => {
  const onContact = () => {
    window.Intercom('showNewMessage', `Hi there! I'm interested in unlocking Ecosystems to explore its full potential. Can you help me with that? Thanks!`)
    onClose()
  }

  return <Dialog
    open={open}
    aria-labelledby="introduction-modal"
    maxWidth={'sm'}
    fullWidth="true"
    onEscapeKeyDown={onClose}
  >
    <ModalLayout
      noPadding
      onClose={onClose}
    >
      <DialogContent>
        <Typography variant="h6" gutterBottom>Unlock Limitless Possibilities with Ecosystems</Typography>
        <Typography variant="subtitle1" gutterBottom>
          Discover our carefully curated ecosystems, where kindred brands and creators converge, fostering impactful alliances and extending influence. Elevate your brand presence, engage with exceptional talents, and unleash the boundless possibilities within our Ecosystems.
        </Typography>
      </DialogContent>

      <DialogActions style={{justifyContent: 'center',  paddingBottom: 20, paddingTop: 20, flexDirection: 'column'}}>
        <Button variant={'contained'} color={'primary'} size={'large'} onClick={() => onContact()}>Contact Customer Success</Button>
      </DialogActions>
    </ModalLayout>
  </Dialog>
}

export default EcosystemsLockModal
