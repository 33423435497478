import React from 'react'
import styled from 'styled-components'
import { find } from 'lodash'
import { withRouter } from 'react-router-dom'
import PageMain from '../../layouts/PageMain'
import { ProductsList, ProductDetails as ProductUpdateDetails } from '../../components/Product'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import ProductDetails from '../../components/Discovery/Details'
import { Blocker } from '../../components/Blocker'

const List = styled.div`
  padding: 0;
  overflow: hidden;
  flex: 1 1 auto;
`

@withRouter
class Discoveries extends React.Component {
  constructor (props) {
    super(props)
    this.state = { onboarding: false, create: false, details: false, remove: false, product: null, edit: null }
  }

  componentWillUnmount () {
    this.props.onStopPollingResearchStatuses()
  }

  onCreateOpen = () => this.setState({ create: true })
  onCreateClose = () => this.setState({ create: false })

  onDetailsOpen = (id) => {
    if (this.props.assistedLock) {
      this.props.onOpenAssistedLock()
    } else {
      this.setState({ details: id })
    }
  }
  onDetailsClose = () => this.setState({ details: null })

  onEditOpen = (id) => {
    if (this.props.assistedLock) {
      this.props.onOpenAssistedLock()
    } else {
      this.setState({ edit: id })
    }
  }
  onEditClose = () => this.setState({ edit: null })
  onEditSubmit = (details) => this.props.onUpdateProduct(this.state.edit, details)


  onRemoveOpen = (id) => {
    if (this.props.assistedLock) {
      this.props.onOpenAssistedLock()
    } else {
      this.setState({ remove: true, product: { id } })
    }
  }

  onRemoveClose = () => this.setState({ remove: false, product: null })

  onRemove = () => {
    const { id } = this.state.product
    this.onRemoveClose()
    this.props.onRemoveProduct(id)
    this.props.onUpdateBrandCounters()
  }

  onResearch = async (id) => {
    if (this.props.assistedLock) {
      this.props.onOpenAssistedLock()
    } else {
      return await this.props.onStartProductResearch(id)
    }
  }

  render () {
    const {
      edit,
      create,
      details,
      remove
    } = this.state
    const {
      loading,
      order,
      orderBy,
      products,
      brandDetails,
      createProduct: CreateProduct,
      detailsProduct: DetailsProduct,
      onSort
    } = this.props

    return (
      <PageMain
        thinking={loading}
      >
        <Blocker />

        {/*<div style={{display: 'flex', flexDirection: 'column'}}>*/}
          <List>
            <ProductsList
              order={order}
              orderBy={orderBy}
              products={products}
              onResearch={this.onResearch}
              onRemove={this.onRemoveOpen}
              onEdit={this.onEditOpen}
              onDetails={this.onDetailsOpen}
              onSort={onSort}
            />
          </List>

        {/*</div>*/}

        { create && <CreateProduct open={create} step={'channel'} onClose={this.onCreateClose} /> }

        {
          edit && <ProductDetails
            {...find(products, ['id', edit])}
            open={!!edit}
            products={products}
            onSubmit={this.onEditSubmit}
            onClose={this.onEditClose}
          />
        }
        {
          details && <ProductUpdateDetails
            id={details}
            open={Boolean(details)}
            onClose={this.onDetailsClose}
          />
        }
        {
          remove && <ConfirmationDialog
            open={remove}
            onClose={this.onRemoveClose}
            message={'You are about to delete your search. Are you sure?'}
            actionText={'Delete'}
            onAction={this.onRemove}
          />
        }

      </PageMain>
    )
  }
}

export default Discoveries
