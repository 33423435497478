import React from 'react'
import { Dialog } from '@material-ui/core'
import styled from 'styled-components'
import ModalLayout from '../../layouts/ModalLayout'
import { ManWithBox } from '../Icons/ManWithBox'
import BillingPlans from '../Billing/BillingPlans'


const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center ;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: ${props => props.padding};
  h1 {
    font-family: "GT Super Display", serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 1;
    color: #000021;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #3E3E3E;
    margin: 0;
    max-width: 400px;
    //text-align: center;
  }
  button {
    margin: 37px 0 0 0;
  }
  &.imgRight {
    flex-direction: inherit;

    h5, p {
      text-align: left;
    }
    ${Img} {
      margin: 0 0 0 80px;
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      //margin: 0 0 0 0;
      //max-width: 37em;
    }
    @media (max-width: 1100px) {
      ${Img} {
        margin: 0 20px 0 20px;
      }
      .textContainer {
        margin: 0 20px 0 20px;
      }
    }
  }
`

const Img = styled.div`
  width: 407px;
  //height: 250px;
  margin: 0 0 35px 0;
`

export default class PlansModal extends React.Component {

  render () {
    const {
      open,
      role,
      onClose,
      thinking
    } = this.props

    return (
      <Dialog
        disableEnforceFocus
        maxWidth={false}
        open={open}
        ariaLabelledby="dialog-title"
        onEscapeKeyDown={onClose}
      >
        <ModalLayout
          variant={role === 'user' ? 'inactive-account' : 'plans'}
          onClose={onClose}
        >
          <Root>
            {/*<div style={{ maxWidth: 400, margin: '0 auto', textAlign: 'center'}}>*/}
            {/*  <h1>*/}
            {/*    Your subscription is no longer active*/}
            {/*  </h1>*/}
            {/*</div>*/}

            <BillingPlans
              {...this.props}
              closeOnSuccess
              title={'Your subscription has expired'}
              disabled={role === 'user'}
              header={ role === 'user' && (
                <div style={{margin: '0 auto', maxWidth: '45ch', fontSize: '16px'}}>
                  Sorry, this account has no active subscription and access is limited. Please contact your account owner to renew the subscription.
                </div>
                )
              }
              onClose={onClose}
            />
          </Root>
        </ModalLayout>
      </Dialog>
    )
  }
}
