import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDrop, useDrag } from 'react-dnd'
import styled, {css} from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';

import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  ListItemText as MuiListItemText,
  Collapse as MuiCollapse, IconButton,
  Tooltip
} from '@material-ui/core'
import {FiberManualRecord} from '@material-ui/icons'
import { MoreHorizontal, MoreVertical } from 'react-feather'
import { SkeletonPlaceholder  } from '../../components/SkeletonPlaceholder'

import { formatToReadableNumber } from '../../utils/format'

export const ListItemIcon = styled(MuiListItemIcon)`
  font-size: 14px;
  min-width: 24px;
  > svg {
    font-size: 1em;
  }
`
export const ListItemText = styled(MuiListItemText)`
  & span {
    font-size: inherit;
    font-weight: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const List = styled(MuiList)`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ListItemSecondaryAction = styled(MuiListItemSecondaryAction)`
  font-size: 14px;
`

const SortHandle = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  left: 11px;
  opacity: 0;
  align-items: center;
  //visibility: hidden;
  cursor: move;
`

export const ListItem = styled(MuiListItem)`
  //-webkit-user-drag: none;
  //-khtml-user-drag: none;
  //-moz-user-drag: none;
  //-o-user-drag: none;
  //user-drag: none;
  position: relative;
  font-size: 0.875rem;
  padding-left: ${props => props.nested
  ? '3em'
  : props.list ? '2.8em' : ''};
  &.Mui-selected {
    background: white;
  }
  background: ${props => props.$isOver && props.theme.palette.brand.grey.dark};
  box-shadow: inset 4px 0 0 ${props => props.selected ?props.theme.palette.primary.main : 'transparent'};
  svg {
    opacity: ${props => props.selected ? 1 : 0.7};
    color: ${props => props.selected ?props.theme.palette.primary.main : 'black'};
  }
  font-family: Work Sans, Arial, sans-serif;
  font-weight: ${props => props.selected ? 'bold': 'normal'};
  ${props => props.list && css`
      height: 30px;
  `}
  &:hover ${SortHandle} {
    visibility: visible;
    opacity: 0.3;
  }
`

const Collapse = styled(MuiCollapse)`
  //height: 100%;
  overflow-y: auto;
  border-top: 1px solid rgba(0,0,0,0.05);
  border-bottom: 1px solid rgba(0,0,0,0.05);
  background: rgba(0,5,10,0.01);
`

const useStyles = makeStyles({
  container: props => ({
    '& .MuiListItemSecondaryAction-root': props.hoverAction && {
      visibility: 'hidden',
      opacity: 0
    },
    '&:hover .MuiListItemSecondaryAction-root': props.hoverAction && {
      visibility: 'visible',
      opacity: 1
    }
  })
});


const SPECIAL_LISTS = ['competitors', 'irrelevant']

export const NavigationItem = (props) => {
  const {
    id,
    kind,
    loading,
    nested,
    title,
    list,
    items,
    amount,
    path,
    edit,
    pathname,
    subheader,
    open,
    hoverAction,
    disableRipple,
    sortHandle,
    noIcon,
    icon: Icon,
    endIcon: IconEnd,
    component: Component,
    onClick
  } = props

  const classes = useStyles({ hoverAction, edit })
  const active = !props.disableActive && path === pathname //activeItemId === id

  // kind === 'list' when we use navigation in list
  const EndIcon = !!IconEnd ? <IconEnd /> : <FiberManualRecord />
  const ItemIcon = noIcon ? null : !!Icon ? <Icon size={16} /> : <FiberManualRecord />
  const itemProps = {
    component: Link,
    to: path,
    ...(!!onClick && { onClick })
  }

  const [{ isOver, canDrop, isDragging, dragItem }, drop] = useDrop({
    accept: ['lead'],
    drop: (item) => {
      const leadIds = item.selected && item.selected.length ? item.selected : [item.id]
      return {
        ...(kind === 'list' && { listId: id }),
        ...(kind === 'folder' && { folderId: id }),
        ...(kind === 'folder' && { folderName: props.folderName }),
        leadIds,
        target: kind
      }
    },
    canDrop(item) {
      const { fakeIds, selected, source, fake, type } = item

      if (SPECIAL_LISTS.includes(source) && !SPECIAL_LISTS.includes(kind)) {
        if (selected.length === 1 && fake) {
          return false
        }
        if (selected.length === fakeIds.length) {
          return false
        }
      }
      if (kind === 'list') {
        return props.canDrop ? (!!item && item.list !== id) : false
      }
      if (kind === 'folder') {
        return props.canDrop ? (!!item && item.folder !== id) : false
      }
      return props.canDrop ? !active : false
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.internalMonitor.isDragging(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        dragItem: monitor.getItem()
      }
    },
  })

  if (Component) {
    return <Component disabled={!canDrop && isDragging} active={path === pathname} />
  }

  const getItemRef = node => {
    drop(node)
    if (props.previewRef) {
      props.previewRef(node)
    }
  }

  const getDragRef = node => {
    if (props.dragRef) {
      props.dragRef(node)
    }
  }

  const isDisabled = () => {
    if (props.disabled) return true
    return !canDrop && isDragging
  }

  const isFoldersReorder = dragItem && dragItem.type === 'folder' && kind === 'folder'

  const folderDragStyles = {
    ...(isFoldersReorder && {
      opacity: 1,
    })
  }

  return (
    <>
      <ListItem
        data-handler-id={props.handlerId}
        style={{ ...folderDragStyles, ...props.folderDragStyles}}
        edit={edit}
        classes={classes}
        draggable={false}
        list={list}
        button
        disableRipple={disableRipple}
        disabled={isDisabled()}
        $isOver={isOver && canDrop}
        innerRef={getItemRef}
        nested={nested}
        selected={active}
        {...itemProps}
      >
        {
          sortHandle && (
            <SortHandle ref={getDragRef}
                        test={2}>
              <MoreVertical size={14}/>
            </SortHandle>
          )
        }

        {
          !noIcon && <ListItemIcon>
            {ItemIcon}
          </ListItemIcon>
        }

        <ListItemText primary={title} />
        {
          props.hasOwnProperty('amount') && (
            <ListItemSecondaryAction>
              {
                loading ? <SkeletonPlaceholder  width={'80%'} color={'#93a5c2'}  highlightColor={'#a8bcdc'}/> : formatToReadableNumber(amount)
              }
            </ListItemSecondaryAction>
          )
        }
        {
          (!!IconEnd || props.actionsMenu) && (
            <ListItemSecondaryAction>
              {!!IconEnd && EndIcon}
              { props.actionsMenu && <Tooltip title="Actions"><IconButton onClick={props.onActions}><MoreHorizontal style={{ pointerEvents: 'none' }} size={14} /></IconButton></Tooltip>}
            </ListItemSecondaryAction>
          )
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <NavigationList
          nested
          items={items || []}
          subheader={subheader}
          listProps={{
            component: 'div',
            disablePadding: true
          }}
        />
      </Collapse>
    </>
  )
}

const getActiveItemId = (items, pathname) => (items.filter(({ path }) => pathname === path)[0] || {}).id

export const NavigationList = ({ list, items, nested, listProps, subheader: Subheader }) => {
  const { pathname } = useLocation()
  const activeItemId = getActiveItemId(items, pathname)


  //list - property then we use navigations for discovery lists


  return (
    <List
      disablePadding
      component="nav"
      aria-labelledby="Current search"
      subheader={Subheader}
      {...listProps}
    >
      {
        items.map((item) => {
          const { component: Component } = item

          // if (Component) {
          //   return <Component active={item.path === pathname}/>
          // }

          return (
            <NavigationItem
              list={list}
              nested={nested}
              key={item.id}
              activeItemId={activeItemId}
              pathname={pathname}
              {...item}
            />
          )
        })
      }
    </List>
  )
}

const Navigation = ({ loading, list, items, listProps }) => (
  <NavigationList
    list={list}
    items={items}
    loading={loading}
    {...listProps}
  />
)

Navigation.defaultProps = {
  items: []
}

export default Navigation
