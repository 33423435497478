import React from 'react'
import styled from 'styled-components'
import { Grid, Button as MuiButton, TextField as MuiTextField } from '@material-ui/core'
import { Form, Input } from '../Form'
import {
  Tab,
  Tabs,
  Content,
  Close,
  TitleDialog,
  ActionsHolder,
  DialogTitle,
  Modal
} from '../Modal'
import Validator from '../../utils/validator'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { find } from 'lodash'
import countries from '../Leads/CountriesFlag'
import CountryFlag from '../CountryFlag'

const imageKitUrl = process.env.IMAGEKIT_URL

const getCountry = country => {
  const countryObject = find(countries, ['value', country])

  return countryObject ? countryObject : {}
}

const Root = styled.div`
  padding: 0 20px;
`

const Button = styled(MuiButton)`
  text-transform: none;
  font-weight: 600;
`

const TextField = styled(MuiTextField)``

const FORM_ID = 'product-form'

const schema = [
  {
    field: 'name',
    rules: 'required',
    messages: {
      required: 'Product name is required'
    }
  }
]

const filter = createFilterOptions();

export const SelectCreatable = ({ id, value, options, disabled, onChange }) => {
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        const { name } = (newValue || {})
        onChange({ target: { value: name } })
      }}
      filterOptions={(options, params) => filter(options, params)}
      selectOnFocus
      handleHomeEndKeys
      id={id}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name
      }}
      renderOption={(option) => option.name}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          disabled={disabled}
          placeholder="Product name"
          margin="normal"
          label={'Product name'}
          inputLabel={'Product name'}
          variant={'outlined'}
          fieldType='clearable'
          onChange={onChange}
        />
      )}
    />
  )
}

export default class Details extends React.Component {
  constructor(props) {
    super(props)
    const {name, description} = props
    this.state = {name, description, thinking: false, errors: {}, error: null}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      const {name, description} = this.props
      this.state = {name, description, thinking: false, errors: {}, error: null}
    }
  }

  onChange = name => ({target: {value}}) => {
    this.setState({[name]: value})
    this.resetError(name)
    if (this.props.onChange) {
      this.props.onChange({[name]: value })
    }
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    this.setState({ thinking: true })
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors, thinking: false}),
      async () => {
        const {name, description} = this.state
        const {error} = await this.props.onSubmit({ name, description })

        if (error) {
          this.setState({errors: {name: message}})
        }
        this.setState({ thinking: false })

        if (!error) {
          this.props.onClose()
        }
      })
  }

  onCancel = () => {
    const {name, description} = this.props
    this.setState({name, description, errors: {}, error: null})
    this.props.onClose()
  }

  render() {
    const {name, description, errors} = this.state
    const {
      open,
      thinking,
      products,
      error,
      createRef,
      country
    } = this.props

    const countryObject = getCountry(country.code)

    return (
      <Modal
        disableMediaQuery
        open={open}
        ariaLabelledby="dialog-title"
        onEscapeKeyDown={this.onCancel}
      >
        <Form
          noValidate
          formId={FORM_ID}
          error={error}
          thinking={thinking}
          createRef={createRef}
          onSubmit={this.onSubmit}
        >
        <DialogTitle disableTypography>
          <Close aria-label="close" onClick={this.onCancel}>
            <CloseIcon/>
          </Close>
          <TitleDialog>Search details</TitleDialog>
        </DialogTitle>
        <Content>

          <Root>
              <br/>
              <Grid container>
                <Grid item xs={12}>
                  <Input
                    required
                    fullWidth
                    placeholder="Search name"
                    margin="normal"
                    id={'description'}
                    label={'Search name'}
                    inputLabel={'Search name'}
                    variant={'outlined'}
                    fieldType='clearable'
                    value={description}
                    disabled={thinking}
                    error={errors.description}
                    onChange={this.onChange('description')}
                  />
                </Grid>
                <Grid item xs={12}>
                  {
                    open && (
                      <SelectCreatable
                        id={'name'}
                        value={name}
                        options={products}
                        disabled={thinking}
                        onChange={this.onChange('name')}
                      />
                    )
                  }
                </Grid>
                <Grid item xs={12} style={{marginBottom: '1em'}}>
                  <strong>Search country:&nbsp;&nbsp;</strong>
                  <CountryFlag
                    country={countryObject.value}
                    alt={countryObject.label}
                    fallbackImageURL={`${imageKitUrl}/flags/${countryObject.value}.svg`}
                  />
                  &nbsp;
                  {countryObject.label}
                </Grid>
              </Grid>
          </Root>

        </Content>
        <ActionsHolder borderTop right>
          <Button onClick={this.onCancel} disabled={thinking} variant="outlined"  color="primary">Dismiss</Button>
          <Button style={{margin: '0 0 0 25px'}} disabled={thinking} type={'submit'} variant="contained" color="primary">Save</Button>
        </ActionsHolder>
        </Form>
      </Modal>
    )
  }
}

