import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import { ContactsStore } from '../../stores/contact-infos'

let store = null

export const ContactDetailsContext = React.createContext<any>({ loading: true })

class ContactDetailsContextProvider extends React.Component<any, any> {
  store = null

  constructor(props) {
    super(props)
    this.store = new ContactsStore(props.brandId)
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (this.props.channelId && this.props.channelId !== prevProps.channelId) {
      this.store.load(this.props.channelId)
    }
  }

  render() {
    const { loading, contacts, summary } = this.store

    return (
      <ContactDetailsContext.Provider
        value={{
          loading,
          contacts,
          summary,
          item: { id: this.props.channelId },
        }}
      >
        {this.props.children}
      </ContactDetailsContext.Provider>
    )
  }
}

export default observer(ContactDetailsContextProvider)
