import React, { Component } from 'react'
import styled from 'styled-components'
import ButtonBase from '@mui/material/ButtonBase'
import {Link} from 'react-router-dom'
import {ChevronRight} from 'react-feather'
import {StyledEngineProvider} from '@mui/material/styles'

const Root = styled(ButtonBase)`
  display: ${props => props.fullWidth ? 'flex' : 'inline-flex'};
  grid-column: ${props => props.fullWidth ? 'span 2' : null};
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.primary.main};
  border-radius: ${props => props.theme.shape.borderRadius * 2}px;
  padding: 0.6em 1em;
  min-width: 30ch;
`

interface LinkButtonProps {
    children: React.ReactNode
    icon?: React.ReactNode
    loading?: boolean
    disabled?: boolean
    to?: string
    target?: string
    fullWidth?: boolean
    onClick?: () => void
}

function LinkButton ({ children, icon:IconBase, loading, ...props }: LinkButtonProps) {
    const Icon = styled(IconBase)`
      color: currentColor;
      opacity: 0.8;
      width: 1.3em;
      height: 1.3em;
    `
    return !loading ? (
        <StyledEngineProvider injectFirst>
            <Root component={Link} {...props}>
              {children} <Icon />
            </Root>
        </StyledEngineProvider>
    ) : null
}

LinkButton.defaultProps = {
  children: 'It\'s a me, LinkButton!',
  icon: ChevronRight
}

export default LinkButton
