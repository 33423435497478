import React from 'react'

export const Finish = () => (
  <svg width="115" height="109" viewBox="0 0 115 109" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: 50}}>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Vector-1">
        <path d="M62.6193,3.05013e-06 C88.1368,0.42858 105.548,14.5789 112.755,37.1056 C116.891,50.0303 115.097,62.5707 109.142,74.4248 C103.127,86.397 94.1561,95.9561 81.9416,102.079 C76.8396,104.637 71.3705,106.504 65.9989,108.502 C63.9042,109.281 61.6797,109.09 59.476,108.318 C56.6769,107.354 53.7192,106.917 50.7564,107.028 C44.2266,107.246 38.3928,105.185 32.9858,101.903 C29.3418,99.6922 25.4079,97.9039 22.1445,95.1041 C12.0047,86.4031 4.9543,75.7195 0.993199,63.0532 C-0.509842,58.2867 0.017065,53.415 0.485273,48.596 C0.755205,45.8189 1.85682,43.0741 2.29826,40.4108 C2.64359,38.3402 4.35841,36.837 4.22316,35.4557 C4.01884,33.3678 4.88504,32.0481 5.73858,30.5927 C13.7631,16.9033 26.3354,9.13762 41.148,4.30928 C46.3941,2.59952 51.7136,1.16659 57.1793,0.358798 C59.2898,0.046798 61.4533,0.0697775 62.6193,3.05013e-06 Z" id="Path" fill="#0163FA" fill-rule="nonzero"></path>
        <polyline id="Path" stroke="#FFFFFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" points="32 59.6316 45.7308 74 83 35"></polyline>
      </g>
    </g>
  </svg>
)

