import React from 'react'
import styled from 'styled-components'
import {
  FormControl,
  FormHelperText,
  Button as MuiButton,
  Typography
} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import Loader from '../Loader/LoaderOverlay'
import {Input} from '../Form'
import Validator from '../../utils/validator'

const FORM_ID = 'user-form'

const Button = styled(MuiButton)(spacing)

const Header = styled(Typography)`
    margin: 0 0 16px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000021;
    font-family: "GT Super Display", Times, serif;
`

const SubHeader = styled(Typography)`
    //font-size: 16px;
    //font-family: 'Work Sans', Arial, sans-serif;
    line-height: 19px;
    color: #000021;
    margin: 0 0 1.5em 0;
    text-align: center;
`

const Wrapper = styled.div`
   width: 100%;
   height: 100%;
   form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
   }
`;

export default class Form extends React.Component {
  static defaultProps = {
    formId: 'current-form',
    header: null,
    subHeader: null,
    thinking: false,
    error: null,
    children: null,
    actions: null,
    onSubmit: console.log,
    createRef: () => {}
  }

  render() {
    const {
      formId,
      header,
      subHeader,
      thinking,
      error,
      children,
      actions: Actions,
      step,
      stepCount,
      onSubmit,
      createRef
    } = this.props

    return (
      <Wrapper>
        {header && <Header component={'h1'}>{header}</Header>}
        {subHeader && <SubHeader>{subHeader}</SubHeader>}
        <form
          noValidate
          ref={createRef}
          name={formId}
          onSubmit={onSubmit}
        >
          {/*{ thinking && <Loader /> }*/}

          {children}

          {
            !!error && <FormControl error={!!error}>
              <FormHelperText>{error}</FormHelperText>
            </FormControl>
          }

          {Actions && <Actions/>}
        </form>
      </Wrapper>
    )
  }
}
