import React, { useState } from 'react'
import styled from 'styled-components'
import ResourceCard from './ResourceCard'
import ShowAll from '../ShowAll'

const Page = styled.div`
  padding-bottom: 0.2em;
  width: 100%;
  &:last-of-type {
    padding-bottom: 0;
  }
`

const MostRelevantPages = ({ fieldAction, pages, minRows, variant, partnerLeads }) => {
  const pagesLength = pages.length
  const [collapsed, setCollapsed] = useState(true)

  const onToggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const getPages = () => {
    return collapsed ? pages.slice(0, minRows) : pages
  }

  return (
    <>
      {
        getPages().map(page => (
          <Page>
            <ResourceCard fieldAction={fieldAction} item={page} variant={variant} relevantPage partnerLeads={partnerLeads} />
          </Page>
        ))
      }
      <ShowAll
        more={pagesLength > minRows}
        amount={minRows}
        total={pagesLength}
        collapsed={collapsed}
        onClick={onToggleCollapsed}
      />
    </>
  )
}

MostRelevantPages.defaultProps = {
  minRows: 2,
  pages: []
}

export default MostRelevantPages
