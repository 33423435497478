import { countriesOnly as countries } from '../../components/Leads/CountriesFlag'
import Config from '../../config'
import {
  SYSTEM_ENTITY,
  SYSTEM_NICHE,
  SYSTEM_CATEGORY,
} from '../../configFilters'

export const partnershipsFilters = [
  {
    id: 'search',
    kind: 'search',
    label: `Search for this text`,
  },
  {
    label: 'Type',
    id: 'type1Classification',
    defaultValue: 'all',
    kind: 'select',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Creator',
        value: 'Content',
      },
      {
        label: 'Brand',
        value: 'Business',
      },
      {
        label: 'Unclassified',
        value: 'Unclassified',
      },
    ],
    placeholder: 'Content vs Business',
  },
  {
    label: 'Language',
    id: 'lang',
    kind: 'autocomplete',
    options: Config.LANGUAGES,
    placeholder: 'E.g.: English',
  },
  {
    label: 'Promotes',
    id: 'promotes',
    kind: 'autocomplete',
    channels: true,
    notEditable: true,
    hidden: true,
    placeholder: 'E.g.: Promotes',
  },
  {
    label: 'Promoted by',
    id: 'promotedBy',
    kind: 'autocomplete',
    channels: true,
    notEditable: true,
    hidden: true,
    placeholder: 'E.g.: Promoted by',
  },
  {
    label: 'Brand',
    id: 'promotedBy',
    kind: 'autocomplete',
    options: [],
    placeholder: 'E.g.: Promoted',
  },
  {
    label: 'Countries',
    id: 'byCountries',
    kind: 'multiselect',
    options: countries,
    placeholder: 'E.g.: United Kingdom',
  },
  {
    label: 'Adexes',
    id: 'adexes',
    kind: 'multiselect',
    adexes: true,
    placeholder: 'E.g.: Amazon Associates',
  },
  {
    label: 'Tags',
    id: 'byTags',
    kind: 'tags',
    disabled: true,
  },
  {
    label: 'Competitors by Domain',
    id: 'byCompetitors',
    kind: 'multiselect',
    options: [],
    placeholder: 'E.g.: site.com',
    disabled: true,
  },
  {
    label: 'Score',
    id: 'breezyScore',
    kind: 'range',
    min: 0,
    max: 0,
    step: 1,
    disabled: true,
  },
  {
    label: 'Contacts Found',
    id: 'contactsFound',
    kind: 'bool',
    groupBy: 'Apply filter',
  },
  {
    label: 'Competitors Count',
    id: 'competitorSeedsCount',
    kind: 'range',
    min: 0,
    max: 0,
    step: 1,
    disabled: true,
  },
  {
    label: 'Keywords Count',
    id: 'keywordSeedsCount',
    kind: 'range',
    min: 0,
    max: 0,
    step: 1,
    disabled: true,
  },
  {
    label: 'Similarweb Rank',
    id: 'swRank',
    kind: 'range',
    min: 0,
    max: 30000000,
    step: 1,
    logarithmic: true,
  },
  {
    label: 'Similarweb Visits',
    id: 'swVisits',
    kind: 'range',
    min: 0,
    max: 90751889638,
    step: 1,
    logarithmic: true,
  },
  {
    label: 'Domain Authority',
    id: 'domainAuthority',
    kind: 'range',
    min: 0,
    max: 100,
    step: 1,
  },
  {
    label: 'Partnerships In',
    id: 'partnershipsIn',
    kind: 'range',
    min: 0,
    max: 3000000,
    step: 100,
  },
  {
    label: 'Partnerships Out',
    id: 'partnershipsOut',
    kind: 'range',
    min: 0,
    max: 30000,
    step: 100,
  },
  {
    label: 'With partnerships only',
    id: 'affiliatesOnly',
    kind: 'bool',
    groupBy: 'Apply filter',
    disabled: true,
  },
]

export const gapFilters = [
  {
    id: 'search',
    kind: 'search',
    label: `Search for this text`,
  },
  {
    label: 'Language',
    id: 'lang',
    kind: 'autocomplete',
    options: Config.LANGUAGES,
    placeholder: 'E.g.: English',
  },
  {
    label: 'Countries',
    id: 'byCountries',
    kind: 'multiselect',
    options: countries,
    placeholder: 'E.g.: United Kingdom',
  },
  {
    label: 'Adexes',
    id: 'adexes',
    kind: 'multiselect',
    adexes: true,
    placeholder: 'E.g.: Amazon Associates',
  },
  {
    label: 'Tags',
    id: 'byTags',
    kind: 'tags',
  },
  SYSTEM_ENTITY,
  SYSTEM_NICHE,
  SYSTEM_CATEGORY,
  {
    label: 'Competitors by Domain',
    id: 'byCompetitors',
    kind: 'multiselect',
    options: [],
    placeholder: 'E.g.: site.com',
    disabled: true,
  },
  {
    label: 'Score',
    id: 'breezyScore',
    kind: 'range',
    min: 0,
    max: 0,
    step: 1,
    disabled: true,
  },
  {
    label: 'Contacts Found',
    id: 'contactsFound',
    kind: 'bool',
    groupBy: 'Apply filter',
  },
  {
    label: 'Competitors Count',
    id: 'competitorSeedsCount',
    kind: 'range',
    min: 0,
    max: 0,
    step: 1,
    disabled: true,
  },
  {
    label: 'Keywords Count',
    id: 'keywordSeedsCount',
    kind: 'range',
    min: 0,
    max: 0,
    step: 1,
    disabled: true,
  },
  {
    label: 'Similarweb Rank',
    id: 'swRank',
    kind: 'range',
    min: 0,
    max: 30000000,
    step: 1,
    logarithmic: true,
  },
  {
    label: 'Similarweb Visits',
    id: 'swVisits',
    kind: 'range',
    min: 0,
    max: 90751889638,
    step: 1,
    logarithmic: true,
  },
  {
    label: 'Domain Authority',
    id: 'domainAuthority',
    kind: 'range',
    min: 0,
    max: 100,
    step: 1,
  },
  {
    label: 'Hide existing partners',
    id: 'hidePartners',
    kind: 'bool',
    groupBy: 'Apply filter',
  },
  {
    label: 'Hide relevant',
    id: 'hideRelevants',
    kind: 'bool',
    groupBy: 'Apply filter',
  },
  {
    label: 'Promotes Count',
    id: 'promotes',
    kind: 'range',
    min: 0,
    max: 700000,
    step: 100,
  },
]

export const landscapeFilters = [
  {
    id: 'search',
    kind: 'search',
    label: `Search for this text`,
  },
  {
    label: 'Promoted by',
    id: 'partner',
    kind: 'autocomplete',
    options: [],
    placeholder: 'E.g.: Partner',
    hidden: true,
  },
  {
    label: 'Language',
    id: 'lang',
    kind: 'autocomplete',
    options: Config.LANGUAGES,
    placeholder: 'E.g.: English',
  },
  {
    label: 'Countries',
    id: 'byCountries',
    kind: 'multiselect',
    options: countries,
    placeholder: 'E.g.: United Kingdom',
  },
  {
    label: 'Competitors by Domain',
    id: 'byCompetitors',
    kind: 'multiselect',
    options: [],
    placeholder: 'E.g.: site.com',
    disabled: true,
  },
  {
    label: 'Tags',
    id: 'byTags',
    kind: 'tags',
  },
  SYSTEM_ENTITY,
  SYSTEM_NICHE,
  SYSTEM_CATEGORY,
  {
    label: 'Score',
    id: 'breezyScore',
    kind: 'range',
    min: 0,
    max: 0,
    step: 1,
    disabled: true,
  },
  {
    label: 'Competitors Count',
    id: 'competitorSeedsCount',
    kind: 'range',
    min: 0,
    max: 0,
    step: 1,
    disabled: true,
  },
  {
    label: 'Keywords Count',
    id: 'keywordSeedsCount',
    kind: 'range',
    min: 0,
    max: 0,
    step: 1,
    disabled: true,
  },
  {
    label: 'Similarweb Visits',
    id: 'swVisits',
    kind: 'range',
    min: 0,
    max: 90751889638,
    step: 1,
    logarithmic: true,
  },
  {
    label: 'Similarweb Rank',
    id: 'swRank',
    kind: 'range',
    min: 0,
    max: 30000000,
    step: 1,
    logarithmic: true,
  },
  {
    label: 'Domain Authority',
    id: 'domainAuthority',
    kind: 'range',
    min: 0,
    max: 100,
    step: 1,
  },
  {
    label: 'With partnerships only',
    id: 'affiliatesOnly',
    kind: 'bool',
    groupBy: 'Apply filter',
    disabled: true,
  },
  {
    label: 'Adexes',
    id: 'adexes',
    kind: 'multiselect',
    adexes: true,
    placeholder: 'E.g.: Amazon Associates',
    disabled: true,
  },
  {
    label: 'Promoted By Count',
    id: 'promotedBy',
    kind: 'range',
    min: 0,
    max: 700000,
    step: 100,
  },
]
