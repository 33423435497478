import { useState } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import { findIndex } from 'lodash'
import {
  channelsKind,
  channelsContactStatus,
  channelsTags,
  channelsSystemTags,
} from '../gql/channels'
import GqlClient from '../gql/gql-client'
import { MARK_CHANNEL_WITH_TAG, REMOVE_TAG_FROM_CHANNELS } from '../gql/tags'

const PER_PAGE = 50

const edgesToData = (response) => {
  const { totalCount, edges } = response

  return {
    totalCount,
    data: (edges || []).map(({ node }) => node),
  }
}

const updateChannelDetails = async (brandId, channels) => {
  if (!channels || !channels.length) return channels || []

  let result = channels.map(({ channelId, partnerId, ...channel }) => ({
    channelId: channelId || partnerId,
    ...(partnerId && { partnerId }),
    ...channel,
  }))
  result = await channelsKind(result, brandId)
  result = await channelsContactStatus(result, brandId)
  result = await channelsSystemTags(result)
  return await channelsTags(result, brandId)
}

const onDeleteTag = (data, tagId) => {
  return data.map((item) => {
    const { tags } = item
    if (tags && tags.length) {
      return {
        ...item,
        tags: tags.filter((t) => t.id !== tagId),
      }
    }
    return item
  })
}

const onUpdateTag = (data, tag) => {
  return data.map((item) => {
    const { tags } = item
    if (tags && tags.length) {
      return {
        ...item,
        tags: tags.map((t) => {
          if (t.id === tag.id) {
            return tag
          }
          return t
        }),
      }
    }
    return item
  })
}

const onSelectTag = (data, channelIds, tag, brandId) => {
  GqlClient.mutation({
    mutation: MARK_CHANNEL_WITH_TAG,
    variables: {
      MarkChannelsWithTagInput: {
        id: tag.id,
        brandId,
        channelIds,
      },
    },
  }).then()
  return data.map((item) => {
    if (channelIds.includes(item.channelId)) {
      return { ...item, tags: [tag, ...(item.tags || [])] }
    }
    return item
  })
}

const onRemoveTag = (data, channelIds, tagId, brandId) => {
  GqlClient.mutation({
    mutation: REMOVE_TAG_FROM_CHANNELS,
    variables: {
      RemoveTagFromChannelsInput: {
        id: tagId,
        brandId,
        channelIds,
      },
    },
  }).then()

  return data.map((item) => {
    if (channelIds.includes(item.channelId)) {
      return {
        ...item,
        tags: item.tags.filter(({ id }) => id !== tagId),
      }
    }
    return item
  })
}

export const useIntelligenceQuery = (brandId) => {
  const client = useApolloClient()
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [requestLoading, setRequestLoading] = useState(true)

  const onManageTags = ({ channelIds, tagId, tag }, action) => {
    if (action === 'select') {
      setData(onSelectTag(data, channelIds, tag, brandId))
    } else if (action === 'update') {
      setData(onUpdateTag(data, tag))
    } else if (action === 'remove') {
      setData(onRemoveTag(data, channelIds, tagId, brandId))
    } else if (action === 'delete') {
      setData(onDeleteTag(data, tagId))
    }
  }

  const onUpdateItem = (id, field, details) => {
    const itemIndex = findIndex(data, [field, id])

    setData((currentData) =>
      currentData.map((dataItem, index) => {
        return {
          ...dataItem,
          ...(index === itemIndex && details),
        }
      })
    )
  }

  const onUpdateItems = (ids, field, details) => {
    setData((currentData) =>
      currentData.map((dataItem) => {
        if (!ids.includes(dataItem[field])) return dataItem
        return {
          ...dataItem,
          ...details,
        }
      })
    )
  }

  const onCompleted = async (dataField, result, newPage) => {
    const { totalCount, data: edges } = edgesToData(result[dataField])
    const channels = brandId
      ? await updateChannelDetails(brandId, edges)
      : edges
    let newData = []

    if (!newPage) {
      setTotal(totalCount)
      newData = channels
    } else {
      newData = [...data, ...channels]
      setPage(newPage)
    }

    setData(newData)

    setRequestLoading(false)
  }

  const onLoad = async (options, newPage = 0, onError) => {
    setRequestLoading(true)
    const { dataField, variables, query } = options
    let result = null
    try {
      result = await client.query({
        query,
        variables: {
          ...variables,
          take: PER_PAGE,
          skip: newPage * PER_PAGE,
        },
        fetchPolicy: 'no-cache',
      })

      await onCompleted(dataField, result.data, newPage)
    } catch (e) {
      onError()
    }
  }

  const onLoadMore = async (options, onError) => {
    if (requestLoading || !data.length) return

    return await onLoad(options, page + 1, onError)
  }

  return {
    loading: requestLoading,
    error: '',
    data,
    total,
    onLoad,
    onLoadMore,
    onUpdateItem,
    onUpdateItems,
    onManageTags,
  }
}

export const RequestWrapper = ({ request, variables, dataField, children }) => {
  const { data, loading, error } = useQuery(request, {
    ...(variables && { variables }),
  })

  return children({
    data: dataField ? (data || {})[dataField] || [] : data || {},
    loading,
    error: error || {},
  })
}
