import { observable, action, computed, toJS } from 'mobx'
import { Delete, Get, Post, Put } from '../utils/request'
import hasError from './request-message'

export default class Notes {
  itemId = null
  brandId = null
  url = null
  @observable items = []

  @observable thinking = true

  constructor (itemId, kind, brandId) {
    this.url = `/api/discovery/${kind === 'partners' ? 'existing-partner-notes' : 'lead-notes'}`
    this.itemId = itemId
    this.brandId = brandId
    this.load().then()
  }

  @action
  async load (itemId) {
    this.itemId = itemId || this.itemId
    this.thinking = true
      // /discovery/existing-partner-notes/
    const { errorCode, notes, message } = await Get(`${this.url}/${this.itemId}${this.brandId ? `?brand=${this.brandId}` : ''}`)

    if (!hasError(!errorCode, message)) {
      this.items = notes
    }

    this.thinking = false
  }

  @action
  async create (details) {
    this.thinking = true
    const { success, message } = await Post(`${this.url}/${this.itemId}`, {
      note: {...details },
      ...(this.brandId && { brand: this.brandId })
    })
    this.load()
    this.thinking = false
    return { success: !hasError(success, message, `Note successfully created`) }
  }

  @action
  async update ({ id, ...details }) {
    this.thinking = true
    const { success, message } = await Put(`${this.url}/${this.itemId}/${id}`, {
      note: details,
      ...(this.brandId && { brand: this.brandId })
    })
    this.load()
    this.thinking = false
    return { success: !hasError(success, message, `Note successfully updated`) }
  }

  @action
  async remove (id) {
    this.thinking = true
    const { success, message } = await Delete(`${this.url}/${this.itemId}/${id}`, {
      ...(this.brandId && { brand: this.brandId })
    })
    this.load()
    this.thinking = false
    return { success: !hasError(success, message, `Note successfully removed`) }
  }

  @computed
  get notes () {
    return toJS(this.items)
  }
}
