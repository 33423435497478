import chroma from 'chroma-js'
import config from '../config'

const getColorStep = (value, steps, max) => {
    return (Math.ceil(value / (max / steps)) * (max / steps)) / 100
}

const getScoreColors = chroma.scale(config.SCORE_COLORS)

export default function scoreColor (value, theme) {
    const defaultColor = theme.palette.brand.shadow.light
    if (value === 0) return defaultColor
    else return getScoreColors(getColorStep(value, config.SCORE_COLOR_STEPS, config.SCORE_MAX)).hex()
}