import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider as MobxProvider } from 'mobx-react'

import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'

import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles'
import { ThemeProvider as ThemeProviderV4, StylesProvider, createGenerateClassName } from '@material-ui/core/styles'


import { ThemeProvider } from 'styled-components'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { IntercomProvider } from 'react-use-intercom'
import './components/Favicon'

import stores from './stores'
import themeV4 from './theme'
import themeV5 from './theme/themeV5'
import Routes from './routes'

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID

const client = new ApolloClient({
  uri: process.env.GQL_API_URL,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
})

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  // disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  // seed: 'mui-jss',
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <IntercomProvider appId={INTERCOM_APP_ID}>
    <ApolloProvider client={client}>
      <MobxProvider {...stores}>
        <StylesProvider injectFirst>
          <ThemeProviderV5 theme={themeV5}>
            <ThemeProviderV4 theme={themeV4}>
              <ThemeProvider theme={themeV4}>
                <DndProvider backend={HTML5Backend}>
                  <Router>
                    <Routes/>
                  </Router>
                </DndProvider>
              </ThemeProvider>
            </ThemeProviderV4>
          </ThemeProviderV5>
        </StylesProvider>
      </MobxProvider>
    </ApolloProvider>
  </IntercomProvider>
)
