import React from 'react'
import styled from 'styled-components'

import Placement, { PlacementInterface } from './Placement'
import ProfileSection from '../ProfileSection'


const Root = styled(ProfileSection)`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`
interface PlacementsProps {
    loading?: boolean
    limit?: number
    link?: string
    placements: Array<PlacementInterface>
}

export default function Placements (props: PlacementsProps) {
    return <Root title={'Relevant placements'} fullWidth>
        {
            props.placements.slice(0, props.limit).map(placement => <Placement {...placement} link={props.link} summary={props.summary}/>)
        }
    </Root>
}

Placements.defaultProps = {
    placements: [],
    limit: 5
}
