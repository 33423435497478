import React from 'react'
import styled from 'styled-components'

import WidgetScore from '../../Widget/WidgetScore'
import CountryFlag from '../../CountryFlag'
import WidgetMetric from '../../Widget/WidgetMetric'
import ButtonBase from '@mui/material/ButtonBase'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { Link, LinkProps } from 'react-router-dom'

const Root = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0.5em;
  border-radius: 0.8em;
  gap: 1em;
  background: white;
  box-shadow: inset 0 0 0 1px
    ${(props) =>
      props.loading ? 'transparent' : props.theme.palette.brand.shadow.main};
`

const Score = styled(WidgetScore)`
  max-width: 200px;
  height: 3.5em;
  min-height: 3em;
  padding: 0.5em 1em;
  border-radius: 0.6em;
`

const Main = styled.div`
  flex: 1 1 auto;
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: flex-start;
`

const Metrics = styled.div`
  display: flex;
  gap: 0.5em;
  & > div {
    max-width: 20ch;
    width: 20ch;
    height: 3.5em;
    min-height: 3em;
    padding: 0.5em 1em;
    border-radius: 0.6em;
  }
`

export interface SearchInterface extends LinkProps {
  id: string
  geo: string
  name: string
  score: number
  leadCompetitors: number
  leadKeywords: number
  searchKeywords: number
  searchCompetitors: number
}

interface SearchSummaryProps extends SearchInterface {
  loading?: boolean
}

export default function SearchSummary(props: SearchSummaryProps) {
  return (
    <StyledEngineProvider injectFirst>
      <Root component={Link} {...props} target={'_blank'}>
        <Score loading={props.loading} variant={'score'} value={props.score} />
        <Main>
          {props.name} <CountryFlag iso={props.geo} />
        </Main>
        <Metrics>
          <WidgetMetric
            variant={'keywords'}
            value={props.leadKeywords}
            valueMax={props.searchKeywords}
          />
          <WidgetMetric
            variant={'backlinks'}
            value={props.leadCompetitors}
            valueMax={props.searchCompetitors}
          />
        </Metrics>
      </Root>
    </StyledEngineProvider>
  )
}
