import React from 'react'

export const BreezyLogo = ({ noTitle, compact, version }) => (
  <svg role="img" aria-label={`Breezy v${version}`} xmlns="http://www.w3.org/2000/svg" height={compact? 24 : 35} viewBox={noTitle ? '0 0 44 35' : '0 0 144 35'} fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{`Breezy v${version}`}</title>
        { !noTitle && <path d="M41.5 8.2h11.34c2.5.02 4.36.47 5.58 1.35 1.22.86 1.83 2.17 1.83 3.93 0 1.22-.38 2.24-1.14 3.06-.74.8-1.85 1.3-3.33 1.5v.03c1.68.18 2.93.68 3.75 1.5a4.34 4.34 0 011.23 3.21c0 1.74-.59 3.05-1.77 3.93-1.18.86-2.97 1.29-5.37 1.29H41.5V8.2zm9.75 7.68c1.24 0 1.86-.48 1.86-1.44S52.49 13 51.25 13h-3.06v2.88h3.06zm.51 7.56c1.24 0 1.86-.49 1.86-1.47 0-.94-.62-1.41-1.86-1.41h-3.57v2.88h3.57zm21.3-10.74c.7 0 1.22.12 1.56.36l-.57 5.64a3.92 3.92 0 00-1.89-.48c-.92 0-1.65.22-2.19.66-.52.44-.78 1.08-.78 1.92V28h-6.9V13h6.21l.15 3.75c.72-2.7 2.2-4.05 4.41-4.05zm18.28 7.32a8.5 8.5 0 01-.15 1.8h-9.54c.1.74.31 1.24.63 1.5s.8.39 1.44.39c.98 0 1.58-.43 1.8-1.29l5.55 1.5a4.89 4.89 0 01-2.52 3.27 9.74 9.74 0 01-4.83 1.11c-2.84 0-5-.67-6.48-2.01-1.46-1.34-2.19-3.27-2.19-5.79 0-2.48.72-4.4 2.16-5.76 1.46-1.36 3.51-2.04 6.15-2.04 2.58 0 4.55.66 5.91 1.98 1.38 1.32 2.07 3.1 2.07 5.34zm-7.95-2.76c-.52 0-.92.15-1.2.45-.28.3-.46.82-.54 1.56h3.24a2.96 2.96 0 00-.45-1.5c-.22-.34-.57-.51-1.05-.51zm25.44 2.76a8.5 8.5 0 01-.15 1.8h-9.54c.1.74.31 1.24.63 1.5s.8.39 1.44.39c.98 0 1.58-.43 1.8-1.29l5.55 1.5a4.89 4.89 0 01-2.52 3.27 9.74 9.74 0 01-4.83 1.11c-2.84 0-5-.67-6.48-2.01-1.46-1.34-2.19-3.27-2.19-5.79 0-2.48.72-4.4 2.16-5.76 1.46-1.36 3.51-2.04 6.15-2.04 2.58 0 4.55.66 5.91 1.98 1.38 1.32 2.07 3.1 2.07 5.34zm-7.95-2.76c-.52 0-.92.15-1.2.45-.28.3-.46.82-.54 1.56h3.24a2.96 2.96 0 00-.45-1.5c-.22-.34-.57-.51-1.05-.51zm9.21 6.78l4.68-5.16 1.41-1.38-2.19.06h-3.9V13h13.71v3.96l-4.59 5.1-1.47 1.44 2.46-.06h3.9V28h-14v-3.96zm28.11 2.28a19.44 19.44 0 01-2.22 4.77 7.1 7.1 0 01-2.88 2.55c-1.12.54-2.5.81-4.14.81-1.86 0-3.4-.33-4.62-.99l1.26-4.35c.32.18.67.31 1.05.39.38.08.92.12 1.62.12.64 0 1.11-.06 1.41-.18.32-.12.54-.33.66-.63l.1-.24-1.9-4.41L124.25 13h7.53l1.35 7.74.57 3.51.66-3.54 1.5-7.71h6.75l-4.4 13.32z" fill="#000021"/> }
        <path d="M2.1 17.8L.02 29.42a2 2 0 001.63 2.32l14.82 2.6c4.22.74 8.45-1.27 10.52-5.01a9.96 9.96 0 00-1.35-11.54H2.09z" fill="#00DED1"/>
        <path d="M25.65 17.8a9.96 9.96 0 00-6.56-15.46L6.89.2l-.04.03A2 2 0 004.9 1.88L2.09 17.8h23.56z" fill="#0163FA"/>
        <path d="M15.6 22.02L1.78 19.59l1.4-7.87 16.8 2.95a10 10 0 015.68 3.13 10.05 10.05 0 01-10.04 4.22z" fill="#0056CD"/>
  </svg>
)
