import React, {useState, createRef, useEffect, useRef} from 'react'
import styled, {css} from 'styled-components'
import {Globe} from 'react-feather'
import MuiImage from 'material-ui-image'
import cleanUrl from '../../utils/cleanUrl'
import {SkeletonBase} from '../Skeleton'


const imageKitUrl = process.env.IMAGEKIT_URL || 'https://ik.imagekit.io/afill/staging'
const retina = window.devicePixelRatio > 1 // TODO: Optimize this by moving to the app level

export const Root = styled.div`
  width: ${props => props.size}em;
  height: ${props => props.size}em;
  margin-right: 0.5em;
  flex: 0 0 ${props => props.size}em;
  filter: ${props => props.grey && 'saturate(0)'};
  transition: filter 0.3s ease;
  display: flex;
  align-items: center;
  position: relative;
  & > div{
    flex: 1 1 auto;
  }
  ${props => props.hideImage && css`
    & img {
        display: none;
    }
  `}
`
const Fallback = styled(Globe)`
  width: ${props => props.size}em;
  height: ${props => props.size}em; 
  color: currentColor;
  opacity: 0.5;
`

function calcPx (baseSize:number, inputSize: number) {
    return baseSize * inputSize
}

function thumbnail(URL, size, domain) {
    const imagePath = cleanUrl(URL, true)
    return imagePath ? `${imageKitUrl}/${domain && !imagePath.includes(domain) ? `${domain}/` : ''}${imagePath}?tr=w-${retina ? Math.round(size * 2) : Math.round(size)}` : ''
}

interface FavIconProps {
    src?: React.ReactNode
    domain?: string
    size?: number
    loading?: boolean
    grey?: boolean
}

function FavIcon ({ src, domain, size, loading, grey }: FavIconProps) {
    const [isError, setIsError] = useState(false)
    const [targetSize, setTargetSize] = useState<number>(16)
    const rootRef = createRef<HTMLElement>()
    let _src = ''
    if (src && src !== 'missing') _src = thumbnail(src, targetSize, domain)

    useEffect(() => {
        const _baseFontSize = window.getComputedStyle(rootRef.current).getPropertyValue('font-size')
        setTargetSize(calcPx(parseFloat(_baseFontSize.replace('px', '')), size || 1))
    })

    const imageProps = {
        src: _src,
        loading: <SkeletonBase />,
        errorIcon: <Fallback size={size}/>,
        onError: () => setIsError(true),
        disableTransition: true,
        style: {visibility: loading ? 'hidden' : 'visible', borderRadius: '3px', overflow: 'hidden'}
    }
    return (
        <Root grey={grey} size={size} hideImage={isError} ref={rootRef}>
            {loading && <SkeletonBase />}
            {_src.length > 0 ? <MuiImage {...imageProps} /> : <Fallback size={size}/>}
        </Root>
    )
}



FavIcon.defaultProps = {
  size: 1
}

export default FavIcon
