import React from 'react'
import styled, {css} from 'styled-components'
import {Tooltip} from "@material-ui/core";

const Root = styled.div`
  display: inline-flex;
  column-gap: 0.5em;
  align-items: center;
  width: ${props => props.fullWidth && '100%'};
`
const Icon = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: currentColor;
    height: 1em;
    width: 1em;
  }
`
const Label = styled.div`
  ${props => props.ellipsis && css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
`

const IconLabel = ({icon, children, ellipsis}) => {

  const Body = () =>
    <Root fullWidth={ellipsis}>
      <Icon>{icon}</Icon>
      <Label ellipsis={ellipsis}>{children}</Label>
    </Root>

  return ellipsis
    ? <Tooltip title={children}><div><Body /></div></Tooltip>
    : <Body />
}

export default IconLabel