import React from 'react'

export const Amazon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><path fill="#f90" d="M106 103.7c-39.6 18.8-64.1 3-79.9-6.5-1-.6-2.6 0-1.2 1.7 5.3 6.4 22.5 21.7 44.9 21.7s35.7-12.2 37.4-14.4.5-3.2-1.2-2.5zm11.1-6.2c-1-1.4-6.4-1.6-9.8-1.2s-8.6 2.5-8.2 3.7c.3.5.7.3 3 0s9-1 10.3.7-2 10.4-2.7 11.8.2 1.7 1.4.8a18 18 0 004.6-6.7c1.3-3.4 2.2-8.1 1.3-9.2z"/><path fillRule="evenodd" d="M79.3 65.2c0 5 .2 9-2.3 13.5-2 3.5-5.2 5.7-8.8 5.7-4.9 0-7.7-3.7-7.7-9.2 0-10.8 9.7-12.7 18.9-12.7zm12.8 31a2.7 2.7 0 01-3 .2c-4.2-3.5-5-5-7.2-8.4-7 7-11.9 9.2-20.9 9.2-10.7 0-19-6.6-19-19.8 0-10.3 5.6-17.3 13.5-20.7 7-3 16.5-3.6 23.9-4.4v-1.6c0-3 .2-6.6-1.6-9.2-1.5-2.4-4.5-3.3-7-3.3-5 0-9.2 2.4-10.3 7.6a2.7 2.7 0 01-2.2 2.3L46 46.8a2.3 2.3 0 01-1.9-2.7C47 29.2 60.4 24.7 72.5 24.7c6.1 0 14.2 1.7 19 6.3 6.2 5.8 5.6 13.5 5.6 21.8v19.8c0 6 2.5 8.6 4.8 11.8.8 1.1 1 2.5 0 3.4-2.6 2.1-7.2 6.1-9.7 8.4"/><path fill="#f90" d="M106 103.7c-39.6 18.8-64.1 3-79.9-6.5-1-.6-2.6 0-1.2 1.7 5.3 6.4 22.5 21.7 44.9 21.7s35.7-12.2 37.4-14.4.5-3.2-1.2-2.5zm11.1-6.2c-1-1.4-6.4-1.6-9.8-1.2s-8.6 2.5-8.2 3.7c.3.5.7.3 3 0s9-1 10.3.7-2 10.4-2.7 11.8.2 1.7 1.4.8a18 18 0 004.6-6.7c1.3-3.4 2.2-8.1 1.3-9.2z"/><path fillRule="evenodd" d="M79.3 65.2c0 5 .2 9-2.3 13.5-2 3.5-5.2 5.7-8.8 5.7-4.9 0-7.7-3.7-7.7-9.2 0-10.8 9.7-12.7 18.9-12.7zm12.8 31a2.7 2.7 0 01-3 .2c-4.2-3.5-5-5-7.2-8.4-7 7-11.9 9.2-20.9 9.2-10.7 0-19-6.6-19-19.8 0-10.3 5.6-17.3 13.5-20.7 7-3 16.5-3.6 23.9-4.4v-1.6c0-3 .2-6.6-1.6-9.2-1.5-2.4-4.5-3.3-7-3.3-5 0-9.2 2.4-10.3 7.6a2.7 2.7 0 01-2.2 2.3L46 46.8a2.3 2.3 0 01-1.9-2.7C47 29.2 60.4 24.7 72.5 24.7c6.1 0 14.2 1.7 19 6.3 6.2 5.8 5.6 13.5 5.6 21.8v19.8c0 6 2.5 8.6 4.8 11.8.8 1.1 1 2.5 0 3.4-2.6 2.1-7.2 6.1-9.7 8.4"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const Impact = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><circle cx="70.9" cy="36" r="19.7" fill="#e40046"/><path fill="#a9b4bd" d="M70.9 82.8a23 23 0 0117.8 8.4 19.8 19.8 0 10-35.7 0A23 23 0 0171 82.8z"/><circle cx="70.9" cy="105.9" r="19.7" fill="#4b5e6a"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const FintelConnect = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><path fill="#f78d2d" d="M77 52L57 64l-15 8V56l35-20zm-35 60V89l15-9 14-8 6-3V52l21 12v24l-21 12-14 8-14 8 4 2 10 6 14-8 35-20V56L77 36V19L53 34 28 48v56z"/><path fill="none" d="M0 0h142v142H0z"/></svg>
export const RevenueWire = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><g fill="none" strokeMiterlimit="10"><path stroke="#000" strokeWidth="6.8" d="M23 71h19l10 17 16-25 17 25 13-32h19"/><circle cx="70.9" cy="70.9" r="46.1" stroke="#020202" strokeWidth="6.7"/></g><path fill="none" d="M0 0h142v142H0z"/></svg>
export const Pepperjam = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><g><circle cx="70.1" cy="71.1" r="50.1" fill="#db553f"/><path fill="#fff" d="M80 48H68v11H57v12h11v11H57V71H46v23h23V83h11V71H69V60h11v11h11V48H80z"/></g><path fill="none" d="M0 0h142v142H0z"/></svg>
export const PaidOnResults = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><path fill="#3b85c2" d="M80.2 93.5l6.1 20.9s-20.2 0-33.2-7.6c-12-7-18.2-12.1-23-19.1a52.2 52.2 0 01-8.6-21c-6.7-44 36.7-48.3 59-39.5l-1.2 4.1-11.7 2-18 7.5L42 53s-2.3 12 9.6 26.3l.6.7a39.9 39.9 0 0028 13.4z"/><path fill="#336c97" d="M42 53s1.7-4.5 8.1-8.7a29 29 0 0111-3.7c12-.5 14.7.7 14.7.7L73.4 50l15-9.5-6-19.6-3.2 10.3S73.5 30 63.6 31a31.3 31.3 0 00-11 3.5 22.4 22.4 0 00-8.7 9.7 19.4 19.4 0 00-1.9 9zM86.3 114.4l-4.9 8.2c-31-1.9-63.7-19-60.4-65.5 0 28.4 33.6 57.3 65.3 57.3z"/><path fill="#a6c350" d="M95.5 40l1.5-1.8S107.2 44 113.3 54a46.3 46.3 0 017.7 29.7 44 44 0 01-12.2 25.2c-5.1 4.4-16 11.8-16 11.8l5.4-7L91.8 94s13.4-2.8 14.7-13-3.6-27.6-6-31.7-5-9.2-5-9.2z"/><path fill="#418b46" d="M89.7 49.9l5.8-9.8c9.9 6.7 16.4 15.9 16.4 27.9 0 13.6-10.8 21.8-10.8 21.8s4.7-9.7 2.6-20.5a26 26 0 00-14-19.4zM91.8 94l-5.5 5.6 6.5 21.1 5.4-6.9L91.8 94z"/><circle cx="73.3" cy="68.2" r="15.1" fill="#6d6e71"/><ellipse cx="73.3" cy="62.1" fill="#a7a9ac" rx="8.8" ry="6.8"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const LinkConnector = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g fill="#b2a7c2" fillRule="evenodd"><path d="M97.2 113.4a50.1 50.1 0 01-57.6-82 9.2 9.2 0 004 3.8v40.2h20.6A46 46 0 0063 86.8c0 9.9 3.3 16 7.7 20a23.7 23.7 0 0014 5.3h1.7l1.7-.1a66.3 66.3 0 006.9-1 9.2 9.2 0 002.2 2.4z"/><path d="M120.7 70.9a49.8 49.8 0 01-10.3 30.4 9.1 9.1 0 00-16.9 3.8l-1.8.4a19.5 19.5 0 01-4 .3h-1.5a13.3 13.3 0 01-1.4-.2 16.1 16.1 0 01-9.3-4.8c-2.8-2.7-5-7.5-5-14.4 0-7.2 2-12.4 4.5-15.5A13 13 0 0186 66a28.2 28.2 0 014 .3 18.3 18.3 0 014.4 1.1l2 1v-6.6a32.5 32.5 0 00-11.2-2.3 21.7 21.7 0 00-12 3.5 19.8 19.8 0 00-3 2.5l-.4.3a13.6 13.6 0 00-2.2 2.3H50.9V35.4A9.1 9.1 0 0055.8 23a50.1 50.1 0 0165 47.9z"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const CommissionFactory = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><path fill="#4bb0d1" d="M116 68l6-6-2-8-9-2-3-6 3-8-6-6-9 2-5-2-2-9-9-2-6 6h-6l-6-6-9 2-2 9-5 3-8-3-7 6 3 8-3 6-9 1-2 9 6 6v6l-6 5 2 9 9 2 3 5-3 8 6 6 9-2 5 3 2 8 8 3 7-7h6l6 7 8-2 3-9 5-3 8 3 7-6-3-9 3-5 9-2 2-8-6-6zm-45 35a32 32 0 1132-32 32 32 0 01-32 32z"/><path fill="none" d="M0 0h142v142H0z"/></svg>
export const ClickBank = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><path fill="none" d="M0 0h141.7v141.7H0z"/><path fill="#fff" stroke="#041196" strokeMiterlimit="10" strokeWidth="5.3" d="M26.6 26.5h88.6v88.6H26.6z"/><path fill="#2b2ca2" d="M62.6 60.3a9.3 9.3 0 00-3.6-2.8 10.6 10.6 0 00-4.4-1 12 12 0 00-5.1 1 11.6 11.6 0 00-3.9 2.9 13.1 13.1 0 00-2.5 4.2 15.3 15.3 0 00-.8 5.4 14.5 14.5 0 00.8 5 12.5 12.5 0 002.4 4 11.5 11.5 0 004 2.8 12.6 12.6 0 005.1 1 10.4 10.4 0 005-1.2 10.8 10.8 0 003.6-3.2l3.8 2.9a14.9 14.9 0 01-1.3 1.4 13.3 13.3 0 01-2.5 2 17.3 17.3 0 01-3.7 1.6 16 16 0 01-5 .7 16.3 16.3 0 01-7-1.4 17.2 17.2 0 01-5.4-4 17.4 17.4 0 01-3.4-5.4 17.2 17.2 0 01-1.2-6.2 19.4 19.4 0 011.3-7 16.7 16.7 0 013.5-5.7 15.9 15.9 0 015.5-3.6 18.2 18.2 0 017-1.3 17.1 17.1 0 016.4 1.2 12 12 0 015.1 3.9z"/><path fill="#a6a9ac" d="M76.3 53.2h13.1a19.2 19.2 0 014.6.5 11.3 11.3 0 013.6 1.5 7.1 7.1 0 013.3 6.2 6.3 6.3 0 01-1.8 4.7 10.8 10.8 0 01-4.7 2.6 9 9 0 013.2.9 9 9 0 012.5 1.7A8.6 8.6 0 01102 74a7.8 7.8 0 01.6 3.1 7.4 7.4 0 01-1 4 8.8 8.8 0 01-2.9 2.8 14.3 14.3 0 01-4.1 1.8 21.2 21.2 0 01-5.2.6h-13zm5.1 13.7h7a13.2 13.2 0 002.7-.3 6.6 6.6 0 002.3-.8 4.6 4.6 0 001.5-1.6 4.4 4.4 0 00.6-2.3 4.7 4.7 0 00-1.5-3.4 6.1 6.1 0 00-4.5-1.4h-8zm0 15.4H89a17.6 17.6 0 002.6-.2 8.4 8.4 0 002.6-.9 6.2 6.2 0 002.1-1.7 4.5 4.5 0 00.9-2.8 5 5 0 00-2.2-4.4 10.5 10.5 0 00-5.9-1.5h-7.6z"/></g></svg>
export const AvantLink = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><path fill="#256db3" d="M106.6 86.8a12.2 12.2 0 00-4.8.7 47.8 47.8 0 01-17 2.4c-6.3-.3-13-2-16.2-3.6l-.3-.1c-.1 0 0 0 0 0a12 12 0 00-4.8-1.2 12.7 12.7 0 00-1.1 25.3 12.1 12.1 0 004.8-.7h.1l.3-.1a46.8 46.8 0 0116.5-2.3 48.2 48.2 0 0116.7 3.8 12 12 0 004.7 1.1 12.7 12.7 0 101-25.3z"/><path fill="#256db3" d="M43 104a11.8 11.8 0 001.9-4.6 48.2 48.2 0 016.3-15.8 47.6 47.6 0 0111.2-12.3l.4-.2a12 12 0 003.3-3.5 12.7 12.7 0 00-21.3-13.7 11.8 11.8 0 00-1.8 4.6v.3c-.5 3.5-2.9 10.1-6.3 15.5A48.2 48.2 0 0125 86.8a12 12 0 00-3.4 3.5A12.7 12.7 0 0043.1 104z"/><path fill="#514a4b" d="M60.6 42.2a12.6 12.6 0 003.2 3.7 48.2 48.2 0 0111 13 47.2 47.2 0 015.7 15.6 1.6 1.6 0 000 .4 12.3 12.3 0 001.6 4.7 12.7 12.7 0 0022-12.7 12.5 12.5 0 00-3.2-3.7.1.1 0 00-.1 0 1.2 1.2 0 00-.3-.2c-2.8-2-7.5-7.2-10.7-12.7A49.1 49.1 0 0184 34.2a12.2 12.2 0 00-1.6-4.7 12.7 12.7 0 00-22 12.7z"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const Avangate = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><path fill="#ed192e" d="M96 85c0 6-3 8-8 8H50c-4 0-8-2-8-8 0-3 2-6 7-7l47-1v8zm-9-45H28v14h57c7 0 11-1 11 8v3H47c-16 0-22 7-22 19v3c0 11 8 20 21 20h46c14 0 21-9 21-20V61c0-18-11-21-26-21"/><path fill="none" d="M0 0h142v142H0z"/></svg>
export const Refersion = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><g><path fill="#7cde08" fillRule="evenodd" d="M117 56L94 70l13 9a26 26 0 015 4 7 7 0 012 5v1l-1 2-1 2a10 10 0 000 2l-4 1q-11 0-21-9v7q0 4 8 15v3l-9 4q-5 0-10-9l-5-9q-2-5-6-8l-13 12q-8 7-13 7a13 13 0 01-4-1 44 44 0 01-4-2v-5l18-19a24 24 0 00-6-5 25 25 0 00-10-2l-8-2a13 13 0 01-5-2q3-10 12-13a28 28 0 017 0h14a11 11 0 00-1-3 19 19 0 01-1-2 15 15 0 00-2-2 15 15 0 01-1-3 10 10 0 00-2-3q-5-6-5-8 0-4 4-7t7-4q5 0 11 14l11 21 34-23a5 5 0 014 2 16 16 0 013 4 19 19 0 011 6 39 39 0 011 5z"/></g><path fill="none" d="M0 0h142v142H0z"/></svg>
export const Adcell = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="#65779a" d="M74.9 97.5v-58c15.2 0 30.4-.3 45.5.2 5.5.2 8.5 4.1 8.4 10.3-.3 12-.2 24-.2 36 0 7.2-3.4 11.2-10.6 11.4-14.3.3-28.5 0-43.1 0zm39.4-13.1V52.3H87.8v32zM71.9 97.5H58.6l-5.3-11.6H25.7l-5.1 11.6H6.9l18.2-40.6c2-4.6 4-9.2 6.2-13.6C34.7 37 44 36.5 47 43 55.5 60.8 63.5 79 71.9 97.5zM49 76.2l-9.6-21.3L30 76.2z"/><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const Admitad = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="#643ab5" fillRule="evenodd" d="M71.8 35a6.8 6.8 0 00-4.7 2L12.7 89.6a6.4 6.4 0 00-1.5 7.1 6.7 6.7 0 006.3 4h29.4a6.5 6.5 0 100-13H33.7l31.4-30.5V94a6.7 6.7 0 006.7 6.5H96c18.6 0 33.8-14.7 33.8-32.7S114.5 35 96 35a6.5 6.5 0 100 13.1c11.2 0 20.3 8.8 20.3 19.7S107 87.4 96 87.4H78.6V41.5a6.5 6.5 0 00-4.2-6 6.8 6.8 0 00-2.6-.5z"/><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const AffiliateFuture = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="#1e4f96" d="M84 63.7l23.7.2h2.5a5.4 5.4 0 015.5 5.4 5.2 5.2 0 01-5.2 5.6c-5.7.2-11.5 0-17.2 0H22.8a5.4 5.4 0 00-5.6 5.6c0 3.1 2.4 5.5 5.9 5.6h44.2v11.7H27a48.7 48.7 0 01-9.7-.9C10.6 95.5 7 91.5 6.2 84.6a29 29 0 01.2-7.7c1.1-8 6-12 14.9-12.7 11.1-.7 22.3-.1 33.5-.3h2.7c.9-5.8-2-10-7.6-11.3-8.5-2-17.2-1-25.8-.6-5.8.2-7.9 2.6-9 7.6H6.4C5.3 54 8.8 48 15 45.1a25 25 0 0110.2-2.4H47a24.8 24.8 0 018.8 1.7 17 17 0 0111.7 15c0 1.3.2 3 .4 4.3z"/><path fill="#050506" d="M84 63.7H74c0-8.5 4-16.7 12-19.4a26.4 26.4 0 018.9-1.6c8.7 0 17.4-.9 26 .5 9.7 1.6 14.9 7.5 14.8 16.4h-9c-1.4-5.4-3.5-7.3-9.4-7.7a168.3 168.3 0 00-23 0c-7.1.4-9.8 3.8-10.5 11.8zM83.9 86.2v11.5h-9.4V86.2z"/><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const Awin = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><path fill="#f60" fillRule="evenodd" d="M105 43L73 4a1 1 0 00-2 1l3 38-23-20a1 1 0 00-2 1l9 37-22-14a1 1 0 00-1 2l39 88a1 1 0 002 0l8-46 22-36a10 10 0 00-1-12z"/><path fill="#e74030" fillRule="evenodd" d="M74 43L51 23a1 1 0 00-2 1l9 37 18 10-2-28z"/><path fill="#fab418" fillRule="evenodd" d="M74 118a1 1 0 01-2-1L58 61 36 47a1 1 0 00-2 2l40 88a1 1 0 001 0l9-45-10 26z"/><path fill="#de0732" fillRule="evenodd" d="M74 118l10-26-4 7a1 1 0 01-2-1l-2-27-18-10 14 56a1 1 0 002 1"/><path fill="#f60" fillRule="evenodd" d="M105 43L73 4a1 1 0 00-2 1l3 38 16 13a10 10 0 013 12l-9 23v1l22-37a10 10 0 00-1-12"/><path fill="#de0733" fillRule="evenodd" d="M81 74a10 10 0 014 10l-1 7 9-23a10 10 0 00-3-12L74 43l2 28 5 3z"/><path fillRule="evenodd" d="M84 92v-1M84 92v-1"/><path fill="#cc1d46" fillRule="evenodd" d="M81 74l-5-3 2 27a1 1 0 002 1l4-7v-1l1-7a10 10 0 00-4-10"/><path fill="none" d="M0 0h142v142H0z"/></svg>
export const Belboon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><path fill="#2e718a" d="M110 121h-8c2-6 5-11 6-17 10-34-13-70-48-76a70 70 0 00-11-1H36v-7h15c31 0 59 23 65 53a67 67 0 01-6 47v1z"/><path fill="#2e718a" d="M44 113v7h-8V48h8v27c12-13 31-11 41 0a29 29 0 01-1 39c-10 10-29 13-40-1zm39-19c0-11-8-20-19-20-12 0-20 8-20 20 0 11 9 20 20 20s19-9 19-20z"/><path fill="none" d="M0 0h142v142H0z"/></svg>
export const CommissionJunction = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="#4baa6d" d="M70.9 22.6c-27.4 0-49.6 22-49.6 49.2S43.5 121 70.9 121s49.5-22 49.5-49.2S98.3 22.6 71 22.6z"/><path fill="#fff" d="M64.8 68h18.6l-3.9 19.4a6.7 6.7 0 01-2.3 3.4v.2a7 7 0 01-4.5 1.4H59.6l-2 9.4H70a20.4 20.4 0 0020.8-15.3l5.8-28H66.7l-2 9.5z"/><path fill="#fff" d="M72.2 84.9l2-9.4H61.8a6.7 6.7 0 01-3.7-1.4 4.7 4.7 0 01-.7-.6 5 5 0 01-.8-4l2.8-13.4a6.8 6.8 0 012.2-3.4l.3-.2a7 7 0 014.3-1.4h13.1l2-9.3H69c-17.3 0-20.7 14.6-20.8 15.3l-2.8 13.3s-1.1 6.2 2.3 10.4c2.3 2.7 6.1 4 11.4 4z"/><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const FinanceQuality = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><path fill="#409bf7" d="M25 25h92v92H25z"/><path fill="#fff" d="M117 117v-1l-11-12a22 22 0 0011-19v-7a22 22 0 00-23-23h-3a22 22 0 00-17 9v-7H45v49h12V87h12a22 22 0 0022 20h2l8 10zM57 67h14a22 22 0 00-2 10H57zm26 19v-9c0-5 4-11 10-11s10 6 10 11v9a10 10 0 11-20 0z"/><path fill="none" d="M0 0h142v142H0z"/></svg>
export const Groupon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="#669f36" d="M64.6 65.5h53.8a47.2 47.2 0 01-31.8 52.1 48.3 48.3 0 01-62.2-29.8 48.8 48.8 0 0130.8-63 48.3 48.3 0 0160.8 30H89.7a32.4 32.4 0 00-9.4-6.9A24.7 24.7 0 0046 65.7a26.5 26.5 0 0018.6 30.7 23.7 23.7 0 0026.8-12H64.6z"/><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const NetAffiliation = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="#d5337d" d="M27.1 24.9h87.3v87.3H27.1z"/><path fill="#fdfcfc" d="M82.3 45.5a2.5 2.5 0 012.5-2.4h4.4a2.5 2.5 0 012.5 2.4v44.6c0 2.7-1.2 4-4 4-8.5.1-8.6 0-12.5-7.4L59.8 57l-1-1.8V90.8a2.9 2.9 0 01-2.8 2.8h-3.7a2.8 2.8 0 01-2.9-2.7V54l.1-7.7c.1-2.6 1.4-3.6 3.9-3.7a53.7 53.7 0 017 .1 6.2 6.2 0 015 3.6L81 76l1.5 2.7-.1-33.2z"/><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const Partnerize = () => <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 142 142"><defs><linearGradient id="ac051922-6510-41e9-b529-64c9f7281965" x1="20.5" x2="45.2" y1="117.4" y2="40.5" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#64cc9c"/><stop offset="1%" stopColor="#3680b9"/></linearGradient><linearGradient id="b7817165-e57f-4ad5-a2f4-fef238632b54" x1="54.1" x2="78.6" y1="127.5" y2="51.4" xlinkHref="#ac051922-6510-41e9-b529-64c9f7281965"/><linearGradient id="bec6fd73-cb0b-4eb7-bcfb-2eadaed24fe9" x1="87.3" x2="111.9" y1="138.8" y2="61.9" xlinkHref="#ac051922-6510-41e9-b529-64c9f7281965"/></defs><g id="afac6b46-e364-4564-9900-25fb7fd95812"><path fill="url(#ac051922-6510-41e9-b529-64c9f7281965)" d="M38 29l-25 89h18l19-71h1L38 29z"/><path fill="url(#b7817165-e57f-4ad5-a2f4-fef238632b54)" d="M49 118h18l10-35-13-18-15 53z"/><path fill="url(#bec6fd73-cb0b-4eb7-bcfb-2eadaed24fe9)" d="M86 118h17l-13-17-4 17z"/><path fill="#575561" d="M127 29H38l13 18h71l5-18zM117 65H64l13 18h36l4-18zM108 101H90l13 17 5-17z"/></g><path id="a5049790-b900-418e-896b-3b6b8fe47d8e" fill="none" d="M0 0h142v142H0z"/></svg>
export const Rakuten = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><g fill="#bf0000"><path d="M129 112H30l14 12 85-12z"/><path d="M60 103V77h11l20 26h20L87 71a30 30 0 00-17-53H44v85h16zm0-69h11a13 13 0 110 27H60z"/></g><path fill="none" d="M0 0h142v142H0z"/></svg>
export const RewardStyle = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><path d="M93.6 87.2a12.7 12.7 0 01-3.6 4.4 14.7 14.7 0 01-5.4 2.7 25.4 25.4 0 01-6.9.9 32.2 32.2 0 01-5.5-.4 21.3 21.3 0 01-3.8-1 13.2 13.2 0 01-2.3-1 3 3 0 00-1-.5l-.8.8-1.2 1.6h-.6l-.2-13.5h.7a20.8 20.8 0 001.5 3.5 29 29 0 002 3.3 18 18 0 002.3 2.7 11.7 11.7 0 002.3 1.7 18 18 0 003 1.2 11 11 0 003.6.7 11.3 11.3 0 007.8-2.8 9.2 9.2 0 003-7.4 9.5 9.5 0 00-.5-3.3 7.8 7.8 0 00-1.6-2.5 11.2 11.2 0 00-2.7-1.7 25.9 25.9 0 00-3-1.4 71.5 71.5 0 00-5-1.7l-2.2-.8c-.7-.2-1.6-.5-2.6-1a14.7 14.7 0 01-3.3-1.8 14 14 0 01-2.9-2.8 15 15 0 01-2-3.5 11.6 11.6 0 01-.8-4 13.7 13.7 0 011.1-6.3 12.3 12.3 0 013.4-4.4 14.1 14.1 0 015-2.6 21.2 21.2 0 016-.8 22.6 22.6 0 015 .4 20 20 0 013.6 1.2 11.7 11.7 0 012 1 4.8 4.8 0 00.8.6c.2 0 .4-.3.8-.8l1.2-1.7h.7l.3 12.5H91a28.9 28.9 0 00-2.2-4.4 20.5 20.5 0 00-3-4 14.6 14.6 0 00-3.8-2.7 10.3 10.3 0 00-4.7-1 11.8 11.8 0 00-3.9.5 8.5 8.5 0 00-3 1.9 8 8 0 00-2 3 10.1 10.1 0 00-.8 4 8 8 0 002.3 5.8 15.4 15.4 0 005.5 3.4c1 .3 1.8.6 2.5 1l2.3.7 2.3.8 2.4.8a15.7 15.7 0 017 5 12.4 12.4 0 012.8 8 13.3 13.3 0 01-1.2 5.7zM46.3 71.2v-6h2.9l.1 4.7a8.2 8.2 0 017.7-5.3v3c-4.6.3-7.6 3.1-7.6 8.6v11.2h-3z"/><path d="M70.2 23.4a47.3 47.3 0 11-33.4 13.9 47 47 0 0133.4-13.9m0-2.4A49.7 49.7 0 10120 70.7 49.7 49.7 0 0070.2 21z"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const ShareASale = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><circle cx="70.2" cy="70.9" r="49.5" fill="#24282c"/><path fill="#f0b544" d="M74.8 34.5L84 53.8l18.8 2.8a5 5 0 012.8 8.7L92.5 78l2.8 22.3a5 5 0 01-8 4.8l-17-12.2-17 12.2a5 5 0 01-8-4.8L47.9 78 35 65.3a5 5 0 012.7-8.7l18.8-2.8 9.2-19.3a5 5 0 019.2 0z"/><path fill="#fff" d="M70.2 33.1a3.5 3.5 0 013.2 2l9.2 19.3.4.8.8.1 18.8 2.8a3.6 3.6 0 012 6L91.4 77l-.5.5v.8l2.8 22.3a3.5 3.5 0 01-.8 2.8 3.7 3.7 0 01-2.7 1.2 3.5 3.5 0 01-2-.7l-17-12.1-1-.6-.8.6-17 12.1a3.4 3.4 0 01-2 .7 3.7 3.7 0 01-2.8-1.2 3.5 3.5 0 01-.8-2.8l2.7-22.3v-.8L49 77 35.9 64.2a3.6 3.6 0 012-6.1l18.8-2.8h.8l.3-.8L67 35.2a3.5 3.5 0 013.2-2m0-1.5a5 5 0 00-4.6 2.9l-9.2 19.2-18.8 2.8a5 5 0 00-2.8 8.7L48 78l-2.7 22.3a5 5 0 005 5.7 5 5 0 003-1l17-12.2 17 12.2a5 5 0 003 1 5.1 5.1 0 005-5.7L92.5 78l13.1-12.7a5 5 0 00-2.8-8.7L84 53.8l-9.2-19.3a5 5 0 00-4.6-2.9z"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const ShopStyle = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><path d="M71 117a61 61 0 01-13-1 43 43 0 01-10-3 40 40 0 01-9-7l4-9a44 44 0 0013 7 43 43 0 0015 3q9 0 15-4a13 13 0 005-11 10 10 0 00-3-7 18 18 0 00-7-4 110 110 0 00-10-3 112 112 0 01-13-3 38 38 0 01-9-5 20 20 0 01-7-8 23 23 0 01-2-10 25 25 0 014-14 26 26 0 0111-9 39 39 0 0117-4 51 51 0 0111 2 42 42 0 0110 3 35 35 0 019 6l-5 10a39 39 0 00-12-7 35 35 0 00-13-3q-9 0-14 4a13 13 0 00-6 11 11 11 0 003 7 16 16 0 006 5 78 78 0 0011 3l12 4a47 47 0 0110 5 19 19 0 019 17 24 24 0 01-4 13 26 26 0 01-11 9 42 42 0 01-17 3z"/><path fill="none" d="M0 0h142v142H0z"/></svg>
export const Skimlinks = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g fill="#00a9c4"><path d="M22.2 70.8a49 49 0 0139.5-48.1l1-.2c.3-.1.8-.1 1 .3s-.3.6-.6.8a24.3 24.3 0 00-7 5.4c-3.9 4.5-4.3 9.5-1 14.6 2.2 3.6 5.4 6.2 8.8 8.6 5.3 3.8 11.2 6.5 17 9.3 7.7 3.8 15.1 8 21.2 14.2 3.5 3.5 6.2 7.5 7.1 12.5a18.3 18.3 0 01-2.2 12.6 34.4 34.4 0 01-18.6 15.3 46.6 46.6 0 01-17.3 3.2 47.8 47.8 0 01-33.3-13.1 48 48 0 01-15-28.9 54.1 54.1 0 01-.6-6.5z"/><path d="M119 75.3a4.2 4.2 0 01-.3-1.8 28 28 0 00-8.2-14 76.9 76.9 0 00-19.4-13.7c-4.2-2.2-8.4-4.1-12.7-6a18.7 18.7 0 01-5-3.2 6.4 6.4 0 01.6-10.2c4-2.9 8.4-3 12.8-1.7A43.9 43.9 0 01104.2 35a48.2 48.2 0 0115 29.5 49.6 49.6 0 01.2 9.5 1.8 1.8 0 01-.3 1.3z"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const Stackcommerce = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="#4aacc5" d="M75.3 91.3l5.4-9.9a80.8 80.8 0 004.8 7.2A21.7 21.7 0 0099.2 96c13.5 2.2 24.5-7 26.8-19 2.7-13.2-4.5-26.9-17.5-30.2A23.2 23.2 0 0080 64.5c-1.9 9-4.8 17.6-10.2 25.1A36 36 0 0145 105c-10 1.6-19-.4-27-6.9A32.3 32.3 0 016 75.7a33.2 33.2 0 0113.5-31.8c7-5.4 15.1-7 23.9-6a34 34 0 0119 8.8 5 5 0 011 6.4 4.6 4.6 0 01-6.4 1.2c-1.8-1.1-3.4-2.7-5.1-3.9-13-9-31.2-3-35.4 14.2-2.2 9-.4 17.4 6 24.4 5.3 6 12.2 8.4 20.2 7.2 11-1.6 18-8.6 22.8-18 2.5-4.9 3.4-10.2 4.8-15.4a32 32 0 0126.9-24.7 33.2 33.2 0 0138.3 24 34 34 0 01-59.6 30 8.2 8.2 0 01-.6-.8z"/><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const TargetCircle = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><path fill="#fff" stroke="#d32d30" strokeWidth="16.6" d="M70 29a42 42 0 101 0zm1 34a8 8 0 11-1 0z"/><path fill="none" d="M0 0h142v142H0z"/></svg>
export const TradeTracker = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><path fill="#5d90c6" d="M56.4 71.5a30 30 0 00-3.8 11 31 31 0 01-27-17 31.4 31.4 0 1158.7-8.4 30 30 0 00-11.5 1.3 20.5 20.5 0 00-4-20.8 19.5 19.5 0 00-14.5-6.9A20.5 20.5 0 0033 50.1a19.9 19.9 0 002.8 11.5 20.2 20.2 0 008.7 8 20.4 20.4 0 0011.9 2z"/><path fill="#80b145" d="M69.7 109Q68.5 97 57 93A26.4 26.4 0 1169.7 109zm12.9-40a17.2 17.2 0 1017.2 16.8 17.2 17.2 0 00-17.2-16.9z"/><path fill="#d13c27" d="M88.2 55.4v-5.2a29.6 29.6 0 00-1.9-10L84 34.8a1.4 1.4 0 010-1A18.8 18.8 0 11117.2 51a18.8 18.8 0 01-18.7 9.3 18.1 18.1 0 01-10.3-4.9zm25-13.8A12.3 12.3 0 10101 54a11.6 11.6 0 008.8-3.9 12.2 12.2 0 003.4-8.4z"/><path fill="#e59945" d="M52 94.4a15.4 15.4 0 110 30.8 15.4 15.4 0 010-30.8zm10 15.4a10 10 0 00-10-10 10 10 0 1010 10z"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const Tradedoubler = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><g fill="#3b8dc4"><path d="M24 55h10v43h12V55h15l2-10H30c8-16 33-29 59-19a49 49 0 11-39 90 49 49 0 01-26-61zm44-10v53l26-1c14-1 23-12 23-26s-7-24-22-26H68z"/><path d="M80 55c8-1 16-1 21 6 4 6 4 15 0 20-5 8-13 7-21 7z"/></g><path fill="none" d="M0 0h142v142H0z"/></svg>
export const VigLink = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><path fill="#0c68ff" d="M113.3 26.7l10.4 19.5-23.6 68.8-8.6-23.5 21.8-64.8zM54.8 26.7l16.7 40L64 91.5 47.1 50.9l.4-.9 7.3-23.3z"/><path fill="#1d1d1b" d="M115.2 23.6H83.5L71.3 58 57 23.6H23.3l-8.7 27.1 26.1 67.4h61.7L127 46zm-20.4 68l19.2-57 6.3 11.8-20.3 59.3zM88 29.7h21L89.3 88.4H68.2l6.2-20.7zm-32.8 6l13 31.1-4.7 15.4-13-31.4zm-27.3-6h22.6l-5.6 18H22.1zM22.5 54H45l16.9 40.7h27.4l6.4 17.2H45z"/></g><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const Webgains = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><defs><linearGradient id="ad5b886d-61d6-4574-a7a9-64de8c4ebbbc" x1="11" x2="130.8" y1="76" y2="76" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#d0383e"/><stop offset="1" stopColor="#d33871"/></linearGradient></defs><path id="eb6d4a1a-af08-4823-99f3-267186cb149e" fill="url(#ad5b886d-61d6-4574-a7a9-64de8c4ebbbc)" d="M48 113L17 69a36 36 0 01-6-14V41h11c-4 11 1 20 8 29l20 28 7-10-15-20-6-8c-5-8 1-19 11-19l57-1a7 7 0 011 1l-6 9H54c-3 0-6 0-6 3a10 10 0 001 6l15 20 7-9-7-1-6-9h24c4 0 5 5 3 9L71 89l7 9 42-59h11l-5 8-45 63-2 3h-4L64 99l-12 14z"/><path id="b1068161-cdaf-42b6-a416-b02c35704ae5" fill="none" d="M0 0h142v142H0z"/></svg>
export const Zanox = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><g><circle cx="70.2" cy="70.9" r="48.9" fill="#e07e32"/><path fill="#fff" d="M48 49l47-1-21 32h19v13H45l25-31H48V49z"/></g><path fill="none" d="M0 0h142v142H0z"/></svg>
export const eBayPartnerNetwork = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="#010101" d="M115.2 113h-90V96.1h90zM68.8 22.2H72l1.2.2a22.7 22.7 0 0114.7 7.8c4 4.4 5.7 9.8 6 15.7h21.3v10.6H69.6a.5.5 0 00-.5 0H25.2V45.9h21.3c.8-12.8 9.2-22.2 20.7-23.5zm19.5 23.6c-.2-6.7-3-12-8.6-15.4-6.7-4-13.5-3.8-19.9.5A17.4 17.4 0 0052 46z"/><path fill="#5b7ab2" d="M67.2 22.4v-.2h1.6zM73.2 22.2v.2l-1.2-.2z"/><path fill="#d34340" d="M25.2 96.1V56.5h22.5a.7.7 0 000 .4V96z"/><path fill="#eaaf3d" d="M70 56.5h22.6V96H70V56.6z"/><path fill="#8fb33d" d="M92.6 96V56.5h22.6V96.1z"/><path fill="#2566c9" d="M47.8 96V57H67a15.5 15.5 0 002.1-.4.5.5 0 01.4 0v39.6z"/><path fill="#5b7ab2" d="M69.5 96V56.6h.5v39.6zM69.1 56.5a15.5 15.5 0 01-2.1.3l-19.2.1a.7.7 0 01-.1-.4z"/><path fill="none" d="M0 0h141.7v141.7H0z"/></svg>
export const Geniuslink = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g><circle cx="70.2" cy="71.1" r="56.7" fill="#02b8ed"/><path d="M49 98.5a60 60 0 0 0 20.7 2.3 38.4 38.4 0 0 0 5.4-.7c5.4-1.1 7.2-3.4 7.3-9 0-2-.3-2.7-2.5-2.3a47 47 0 0 1-14.2.2c-13.3-2-21.5-10-23.3-23.3a44.2 44.2 0 0 1 .7-17c2.7-10.2 8.9-17 19.4-19.4a35.7 35.7 0 0 1 28 3.9 17 17 0 0 1 8 15.1v41.3c0 13.6-6.6 21.4-20 23.6a72.2 72.2 0 0 1-27.4-1c-1.6-.4-2.2-1-2.2-2.7.2-3.6.1-7.2.1-11Zm33.4-36.8v-11c0-6.6-2-8.7-8.5-9.2h-3.5c-5.5 0-9 2.3-10.7 7.6a33.2 33.2 0 0 0-.1 21c1.2 3.7 3.9 5.9 7.7 6.7 4.6 1 9 .5 13.6 0 1.4-.1 1.5-1 1.5-2.2V61.7Z" fill="#fcfdfe"/></g></svg>
export const Tune = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g fill="#016bff"><path d="M70.2 14.4A56.8 56.8 0 1 0 127 71.2V18.6a4.2 4.2 0 0 0-4.2-4.2Zm0 102a45.2 45.2 0 1 1 45.1-45.2 45.2 45.2 0 0 1-45.1 45.2Z"/><path d="M92.7 47.4H47.9A1.8 1.8 0 0 0 46 49V57a1.8 1.8 0 0 0 1.8 1.8h15a1.7 1.7 0 0 1 1.7 1.7v41.9a1.8 1.8 0 0 0 1.8 1.7h7.8a1.8 1.8 0 0 0 1.8-1.8V60.5a1.7 1.7 0 0 1 1.8-1.8h15a1.8 1.8 0 0 0 1.6-1.7v-7.9a1.8 1.8 0 0 0-1.7-1.7Z"/></g></svg>
export const WalmartAffiliates = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path d="M51.3 21.3H89c17.2 0 31.1 14 31.2 31.1v37.9c0 17.2-14 31.1-31.2 31.2H51.3c-17.2 0-31.1-14-31.2-31.2V52.5c0-17.2 14-31.2 31.2-31.2Z" fill="#1d7ad9" fillRrule="evenodd"/><path d="M70.2 38.3c-2.5 0-4.5 1.4-4.5 3l1.5 17.4c.2 1 1.5 1.8 3 1.8s2.8-.8 3-1.8l1.5-17.3c0-1.7-2-3.1-4.5-3.1ZM45.2 52c-1.5.2-3 1.2-3.7 2.7-1.2 2.2-1 4.6.5 5.4l15.7 7.4c1 .4 2.4-.3 3.1-1.6.9-1.4.8-2.9 0-3.6l-14.3-10c-.4-.2-.8-.3-1.2-.2Zm50 0c-.5 0-1 0-1.3.3l-14.2 10c-.8.7-.9 2.2-.1 3.6.8 1.3 2 2 3 1.6l15.9-7.4c1.4-.8 1.7-3.2.4-5.4a4.9 4.9 0 0 0-3.8-2.7ZM58.2 74.9l-.6.1L42 82.4c-1.5.9-1.7 3.3-.5 5.5s3.5 3.2 5 2.4l14.2-10c.9-.7 1-2.2.1-3.5-.4-1-1.4-1.6-2.5-1.8Zm23.8 0c-1 .2-2 .8-2.5 1.8-.8 1.3-.7 2.9 0 3.5l14.3 10c1.5.9 3.7-.1 5-2.3 1.3-2.2 1-4.6-.5-5.5l-15.7-7.3-.6-.1Zm-11.9 7.3c-1.5 0-2.8.8-3 1.8l-1.5 17.4c0 1.7 2 3 4.5 3s4.5-1.3 4.5-3L73.3 84c-.2-1-1.4-1.9-3-1.9Z" fill="#ffc220"/></svg>
export const Branch = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path d="M70.2 14.4a56.7 56.7 0 1 0 0 113.4 56.7 56.7 0 0 0 0-113.4Zm5.5 106-.5-14.4 14-8.6a10.7 10.7 0 1 0-4.3-7l-9.9 6-.7-20.2 13.8-8.5a12.4 12.4 0 1 0-4.6-7l-9.5 6-.6-16.2a11.3 11.3 0 1 0-8.1 0l-1 33.5-10.4-6.6a13 13 0 1 0-4.4 7.1L64 93.6l-.8 26.7a49.8 49.8 0 1 1 12.5.2Z" fill="#333e48"/><circle fill="#4da3dc" cx="69.1" cy="40" r="6.8"/><circle fill="#4da3dc" cx="95" cy="88.8" r="6.2"/><circle fill="#4da3dc" cx="95.6" cy="58.2" r="7.3"/><circle fill="#4da3dc" cx="40.9" cy="74.5" r="7.9"/></svg>
export const Indoleads = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path d="M37.6 52.8c-1-6.4 0-13.3 2.8-19.2 13.4-28.2 61.7-22.8 62 14.5 0 1.4 0 2.9-.2 4.3a35.6 35.6 0 0 1 15.7 12c18.6 25.3-10.4 63.1-43.9 44.7a46 46 0 0 1-3.8-2.3c-5.1 4-11.9 6.6-18.5 7.2-32 3-51.2-40.4-18-59.2 1.2-.7 2.5-1.4 3.9-2Zm61 15.9c-3 8-7.5 15.6-12.8 22.3a61 61 0 0 1-13-8c7.8-9.4 14.6-22.7 12.8-34.5-2.5-19-28.2-18.4-31.7-.8a90.3 90.3 0 0 1 26.3-.4 56 56 0 0 1-.6 14.8c-12.4-1.8-27.6-.8-37.3 6.5-15.7 11.6-2.3 33 15.2 27a84.5 84.5 0 0 1-13.6-21.8c4-2.8 9.2-5.3 13.6-6.8C62 78.2 70.5 90.5 82 94.9c18.2 7.4 30.6-14.5 16.5-26.2Z" fill="#2549ff" fillRule="evenodd"/></svg>
export const Ltk = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="#000000" d="M70.3 127.3c-6.3 0-12.5-1-18.4-3a.6.6 0 0 1-.4-.9l.2-.2 14.7-14.8.5-.2c9.8.8 19.5-2.2 27-8.4.4-.3.8-.2 1 0l.1.3 4.8 18.2c0 .3 0 .6-.2.7a56 56 0 0 1-29.4 8.3ZM124.6 60.3h-.1l-20.3-5a.6.6 0 0 1-.4-.4 37.6 37.6 0 0 0-20.6-18.8.6.6 0 0 1-.3-.8l.1-.2 13.3-13.4c.2-.2.5-.2.7 0a56.3 56.3 0 0 1 28.2 37.8c.1.4-.1.7-.5.8ZM106.3 114h-.2a.6.6 0 0 1-.5-.4l-5.3-20.2.1-.5c6-8.2 8.4-19.1 6.4-29a.6.6 0 0 1 .7-.8l18.4 4.5c.2 0 .4.3.4.6a56.4 56.4 0 0 1-20 45.8ZM45.9 42.6h-.2a.6.6 0 0 1-.4-.4L40.2 24c-.1-.2 0-.5.2-.7 14.4-9 32.6-10.9 48.6-5.2.3 0 .5.4.4.8l-.1.2-14.6 14.7c-.1.2-.3.2-.5.2a37.5 37.5 0 0 0-28.3 8.6ZM44.1 120.8H44A56.7 56.7 0 0 1 15 81.7a.6.6 0 0 1 .8-.8L36 86.3l.4.4c4 8.8 12.1 16.3 21.3 19.5.4 0 .6.5.4.8l-.1.2-13.4 13.4c-.1.2-.3.2-.4.2ZM33 78.4h-.3l-18.2-4.9a.6.6 0 0 1-.5-.6 55.9 55.9 0 0 1 19.4-44.4c.3-.2.7-.2 1 .1v.2l5.6 20v.5c-5.9 8-8.3 18.7-6.5 28.4 0 .2 0 .4-.2.5l-.4.2ZM70.3 94.2l-.5-.2-21.6-19.5a.6.6 0 0 1-.2-.5V61.3c0-.2 0-.4.2-.5l9.7-8.7c.2-.2.6-.2.8 0l11.6 10.3 11.5-10.3c.3-.2.6-.2.9 0l9.7 8.7.2.5V74l-.2.5L70.7 94l-.4.2Z"/></svg>
export const TinyUrl = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path fill="none" d="M0 0h141.7v141.7H0z"/> <path d="M90 46.2H65l-18.3.1c-4 0-6 4.9-3.1 7.7l12.9 12.9-13.6 13.5a11.9 11.9 0 1 0 16.8 16.8l13.6-13.5 12.9 12.9c2.8 2.8 7.6.8 7.6-3.2V75.2l.1-25a4 4 0 0 0-4-4Z" fill="#92b33f"/><path fill="#1c3169" d="m79.7 103.2-4.8-4.8-7.5 7.6a22.8 22.8 0 0 1-32.2 0l-1-1a22.8 22.8 0 0 1 0-32.2l7.6-7.5-4.8-4.8c-2.6-2.6-4-6.2-4-9.8l-9.6 9.6a39.8 39.8 0 1 0 56.2 56.2l9.4-9.3c-3.5-.1-6.8-1.5-9.3-4ZM113.5 26.5a39.8 39.8 0 0 0-56.2 0L46.7 37H65l5-.1c9-8.4 23-8.2 31.7.5l1 1c8.7 8.7 8.8 22.7.5 31.6v5.1l-.1 17.9 10.4-10.4a39.8 39.8 0 0 0 0-56.2Z"/></svg>
export const Bitly = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><path d="M126.3 71.4c0 26.4-17.7 42-30 49a39.6 39.6 0 0 1-21 6.7c-17 0-24-10.4-24-19.4.4-32.7-.4-45.4-.5-46.3 0-1.2 0-3-1.2-3-.6-.1-1 0-1.9 1-1.1 1.6-3.5 2.2-4.9 1.4-1.7-1-2-3.2-1.1-5.3 2.4-5.4 6.2-7.8 11.4-7.7 9 .1 11.6 6 11.6 13.4l-.2 17.2c3.2-2.6 8-5.4 15.7-5.4 10.9 0 20.2 8 20.2 22.7 0 8.6-2.3 13-2.3 13 11-9.5 17-24.4 17-36.8a44.4 44.4 0 0 0-45.2-44.2 46 46 0 0 0-45.7 44.5c0 13.5 7 27 17.1 35.9 2.6 2.3 2.8 5.6 1.4 7-1.7 1.5-4.6 1.7-7-.2A55.8 55.8 0 0 1 69.8 14.7c31 0 56.4 23.7 56.4 56.6Zm-39 25.9c0-9-4.6-12.2-11.5-10.9-5.5 1.2-11.2 7-11.8 15v5.6c.3 5.5 4.9 7.5 11.2 7.4 10.4-.1 12.1-11 12.1-17.1Z" fill="#ee6123"/></svg>
export const Everflow = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.7 141.7"><g fill="#016bff"><path d="M70.2 14.4A56.8 56.8 0 1 0 127 71.2V18.6a4.2 4.2 0 0 0-4.2-4.2Zm0 102a45.2 45.2 0 1 1 45.1-45.2 45.2 45.2 0 0 1-45.1 45.2Z"/><path d="M92.7 47.4H47.9A1.8 1.8 0 0 0 46 49V57a1.8 1.8 0 0 0 1.8 1.8h15a1.7 1.7 0 0 1 1.7 1.7v41.9a1.8 1.8 0 0 0 1.8 1.7h7.8a1.8 1.8 0 0 0 1.8-1.8V60.5a1.7 1.7 0 0 1 1.8-1.8h15a1.8 1.8 0 0 0 1.6-1.7v-7.9a1.8 1.8 0 0 0-1.7-1.7Z"/></g></svg>
export const UnknownNetwork = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142"><g><path fill="none" d="M1 0h142v142H1z"/><path fill="#5b5b56" d="M72 21a49 49 0 1049 50 50 50 0 00-49-50zm-5 89a39 39 0 01-34-48l24 24v5a10 10 0 0010 10zm34-12a10 10 0 00-10-7h-5V76a5 5 0 00-5-5H52V61h10a5 5 0 005-5V46h9a10 10 0 0010-10v-2a40 40 0 0115 64z"/></g></svg>
