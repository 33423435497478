import React from 'react';
import CheckIcon from '@material-ui/icons/Check'
import ToggleButtonMui from '@mui/material/ToggleButton'
import {Eye, EyeOff} from 'react-feather'
import styled from 'styled-components'

const Toggle = styled(ToggleButtonMui)`
  margin-right: 0.5em!important;
  height: 36px;
  color: ${props => props.theme.palette.primary.main};
`

const Content = styled.span`
  font-size: 12px;
  text-transform: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
`

export default function ToggleRelevant({ active, onChange }) {
  return (
    <Toggle
      value="show-relevant"
      selected={active}
      onChange={onChange}
    ><Content><EyeOff size={16} />&nbsp;&nbsp;Hide Relevant</Content>
    </Toggle>
  )
}
