import React from 'react'
import styled from 'styled-components'
import { Button as MuiButton } from '@material-ui/core'

const Root = styled.div`
  padding: 0 20px;
`

const Button = styled(MuiButton)`
  text-transform: none;
  font-weight: 600;
`

const Title = styled.h4`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
`

const Text = styled.p`
  font-size: 14px;
  color: #494949;
  margin: 20px 0;
`

export default class ResetPassword extends React.Component {
  state = { thinking: false, sent: false }

  onReset = async () => {
    const { email } = this.props
    this.setState({ thinking : true })
    const { error } = await this.props.onReset({ email })
    this.setState({ thinking : false, sent: !error })
  }

  render() {
    const { sent, thinking } = this.state
    return (
      <Root>
        <Title>Password</Title>
        { !sent && <Text>An email with instructions will be sent to your inbox to reset your password.</Text> }
        { !sent && <Button disabled={thinking} variant="outlined" color="primary" onClick={this.onReset}>Reset your password</Button> }
        { sent && <Text>An email with instructions was sent to your inbox to reset your password.</Text> }
      </Root>
    )
  }
}

