import React, { useContext, useState } from 'react'
import { isEmpty } from 'lodash'

import {DetailsContext} from '../DetailsContext'
import Filter from '../../../new-components/Filter'
import TableCompetitors from '../../TableCompetitors'
import {Competitor} from "../interfaces";
import ProfileLayout from "../../Profile/ProfileLayout";
import ProfileAside from "../ProfileView/ProfileAside";
import LayoutSearchableList from "../LayoutSearchableList";


interface CompetitorsViewProps {
    onSetViewState: Function
    onQuickActions?: () => void
}

const getFiltersConfig = (searches) => {
    return [
        {
            id: 'search',
            kind: 'search',
            label: `Search for this text`
        },
        {
            label: 'Searches',
            id: 'searches',
            kind: 'multiselect',
            options: searches || [],
            placeholder: "E.g.: Search name"
        }
    ]
}

interface Filter {
    search?: string
    searches?: Array<{ value: string, label: string }>
}

const filter = (data: Array<Competitor>, filters: Filter): Array<Competitor> => {
    if (isEmpty(filters)) return data

    const { searches, search } = filters

    const pattern = search ? new RegExp(search, 'i') : null
    const searchIds = searches ? searches.map(({ value }) => value) : null

    return data.filter(({ domain, searchId }) => {
        const bySearch = pattern ? pattern.test(domain) : true
        const bySearches = searches ? searchIds.includes(searchId) : true

        return bySearch && bySearches
    })
}

export default function CompetitorsView (props: CompetitorsViewProps) {
    const [filters, setFilters] = useState({})
    const { loading, competitors, searches, summary } = useContext(DetailsContext)

    const search = <Filter variant={'profile'} placeholder={'Search and Filter'} filters={filters}
        keys={getFiltersConfig(searches.map(({ id, name }) => ({ value: id, label: name })))}
        onChange={setFilters} />
    const title = 'Competitors'
    const domain = {...summary, loading: loading}

    return (
        <ProfileLayout aside={<ProfileAside {...props} fullHeight />}>
            <LayoutSearchableList filter={search} title={title} domain={domain}>
                <TableCompetitors data={filter(competitors, filters)} loading={loading} />
            </LayoutSearchableList>
        </ProfileLayout>
    )
}
