import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import ac from './permissions'

const withAccess = (Component, customName, props) => {
  @inject(stores => {
    return { role: stores.BrandStore.role }
  })
  @observer
  class WrapperComponent extends Component {
    render () {
      let name = customName || Component.displayName || Component.name

      const { role } = this.props

      if (!role) return null

      const access = (operation, field = null) => {
        if (!field) {
          return ac.can(role)[operation](name).granted
        }


        const result = ac.can(role)[operation](name).filter({ [field] : true })

        return result.hasOwnProperty(field)
      }

      const attributes = (operation) => ac.can(role)[operation](name).attributes

      return <Component {...this.props} access={access} accessAttributes={attributes} {...props}/>
    }
  }
  return WrapperComponent
}

export default withAccess
