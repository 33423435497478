import styled from 'styled-components'

export const Container = styled.div`
  flex: 1 0 auto;
  margin: 0 0 -25px 0;
`

export const HeaderContainer = styled.div`
  flex: 0;
`

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0.5em 0 0;
`
