import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '../Button'
import {Lightning} from '../Icons/LightningBW'
import PaywallModal from '../../new-components/Modals/PaywallModal'
import { sendEvent } from '../../utils/events'

const style = theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.2) 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 11,
    userSelect: 'none'
  },
  badge: {
    background: '#F8FAFF',
    fontSize: 16,
    textAlign: 'center',
    padding: '3em 3em',
    borderRadius: '10px',
    position: 'relative',
    boxSizing: 'content-box',
    zIndex: 2,
    marginTop: 60,
  },
  heading: {
    fontFamily: 'GT Book Display, serif',
    fontWeight: 'bold',
  },
  image: {
    width: 125,
    margin: '0 auto'
  },
  bw: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    left: 0,
    top: 0,
    zIndex: 10,
    // opacity: 0.8,
    background: '#ffffff',
    'mix-blend-mode': 'color'
  }
})

const NoDataOverlay = props => {
  const [open, setOpen] = useState(false)
  const onOpen = () => {
    setOpen(true)
    sendEvent(props.brandId, 'unlockPaywallButton')
  }
  const onClose = () => {
    setOpen(false)
  }

  return <React.Fragment>
    {/*<div className={props.classes.bw} />*/}
    <div className={props.classes.root}>
      <div className={props.classes.badge}>
        <div className={props.classes.image}>
          <Lightning />
        </div>
        <h1 className={props.classes.heading}>Your subscription is inactive</h1>
        <p>Please subscribe to view your data.</p>
        <br/>
        {/*props.onShow*/}
        <Button variant="contained" color="primary" onClick={onOpen}>Add Subscription</Button>
      </div>
    </div>
    <PaywallModal open={open} brandId={props.brandId} onClose={onClose} />
  </React.Fragment>
}

export default withStyles(style)(NoDataOverlay)
