import React from 'react'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import {
  Button as MuiButton,
} from '@material-ui/core'
import {
  Tab,
  Tabs,
  Content,
  Close,
  TitleDialog,
  ActionsHolder,
  DialogTitle,
  Modal
} from '../Modal'
import Brand from './Brand'
import Account from './Account'
import Email from './Email'
import Invite from './Invite'
import Members from './Members'
import ResetPassword from './ResetPassword'
import Credits from './Credits'
import Billing from './Billing'
import ConfirmationDialog from '../ConfirmationDialog'

const Button = styled(MuiButton)`
  text-transform: none;
  font-weight: 600;
`

const TemporaryTabsHolder = styled.div`
  padding: 0 20px;
`

function TabPanel(props) {
  const {children, value, index} = props;
  return (
    value === index && (
      <>
        {children}
      </>
    )
  )
}

const companyFields = ['name', 'link', 'categories']
const accountFields = ['firstName', 'lastName']

const TABS = ['brand', 'account', 'team', 'credits', 'billing']
const DISABLE_BUTTONS = ['team', 'credits', 'billing']

class Settings extends React.Component {
  constructor(props) {
    super(props)
    const { tab, role } = props

    this.state = {
      tab: tab || role === 'brandOwner' ? 'brand' : 'account',
      company: null,
      user: null,
      tabsError: {},
      removeMember: false,
      memberId: null
    }
    this.companyForm = null
    this.accountForm = null
  }

  createRef = formName => node => this[formName] = node

  onTabChange = (event, value) => {
    this.setState({tab: value});
  }

  onInputsChange = kind => details => {
    if (kind === 'company') {
      const { company: stateBrand, tabsError } = this.state
      const { company: propsBrand } = this.props
      const company = stateBrand ? { ...stateBrand, ...details } : {
        name: propsBrand.name,
        link: propsBrand.link,
        categories: propsBrand.categories,
        ...details
      }
      this.setState({ company, tabsError: { ...tabsError, company: false }  })
    }
    if (kind === 'account') {
      const { user: stateAccount, tabsError } = this.state
      const { user: propsAccount } = this.props
      const user = stateAccount ? { ...stateAccount, ...details } : {
        firstName: propsAccount.firstName,
        lastName: propsAccount.lastName,
        email: propsAccount.email,
        ...details
      }
      this.setState({ user, tabsError: { ...tabsError, user: false } })
    }
  }

  onSubmitBrand = async (details) => {
    this.setState({thinking: true})

    if (this.companyValid()) {
      const { success } = await this.props.onSubmitBrand(details)
      this.setState({thinking: false})
      return { success }
    }
    this.setState({thinking: false})
    return { success: false }
  }

  onSubmitAccount = async (details) => {
    this.setState({thinking: true})

    if (this.accountValid()) {
      const { success } = await this.props.onSubmitAccount(details)
      this.setState({thinking: false})
      return { success }
    }
    this.setState({thinking: false})
    return { success: false }
  }

  accountValid = () => {
    const { user } = this.state
    return !user ? true : !!user.firstName && !!user.lastName
  }

  companyValid = () => {
    const { company } = this.state
    return !company ? true : !!company.name && !!company.link
  }

  onSave = (e) => {
    const { tab } = this.state
    const event = new Event('submit', { 'bubbles': true, 'cancelable' : true })
    if (tab === 'brand') {
      this.companyForm.dispatchEvent( event )
    } else if (tab === 'account') {
      this.accountForm.dispatchEvent( event )
    }
  }

  onClose = () => {
    this.setState({
      company: null,
      user: null,
      tabsError: {}
    })
    this.props.onClose()
  }

  onRemoveMember = () => {
    const { memberId } = this.state
    this.props.onRemoveMember(memberId)
    this.onRemoveMemberClose()
  }
  onRemoveMemberOpen = (id) => this.setState({ removeMember: true, memberId: id })
  onRemoveMemberClose = () => this.setState({ removeMember: false, memberId: null })

  render() {
    const {
      tab,
      tabsError,
      removeMember
    } = this.state

    const {
      open,
      members,
      thinking,
      access,
      billingStatus,
      assistedLock
    } = this.props

    const companyDetails = this.state.company || this.props.company
    const userDetails = this.state.user || this.props.user

    return (
      <Modal
        fullWidth="true"
        maxWidth="md"
        open={open}
        ariaLabelledby="dialog-setting"
        onBackdropClick={this.onClose}
        onEscapeKeyDown={this.onClose}
      >
        <DialogTitle disableTypography>
          <Close aria-label="close" onClick={this.onClose}>
            <CloseIcon/>
          </Close>
          <TitleDialog>Settings</TitleDialog>
          {/*TODO: REMOVE THIS HACK*/}
          <TemporaryTabsHolder>
            <Tabs value={tab} onChange={this.onTabChange}>
              {
                access('readAny', 'brand') &&
                <Tab label="Brand" value={'brand'} error={tabsError.company} disableRipple disableTouchRipple disableFocusRibble/>
              }
              {
                access('readAny', 'account') &&
                <Tab label="Account" value={'account'} error={tabsError.user} disableRipple disableTouchRipple disableFocusRibble/>
              }
              {
                access('readAny', 'team') &&
                <Tab label="Team" value={'team'} disableRipple disableTouchRipple disableFocusRibble/>
              }
              {/*{*/}
              {/*  access('readAny', 'credits') && !!billingStatus &&*/}
              {/*  <Tab label="Credits & Plans" value={'credits'} disableRipple disableTouchRipple disableFocusRibble/>*/}
              {/*}*/}
              {/*{*/}
              {/*  access('readAny', 'billing') && !!billingStatus &&*/}
              {/*  <Tab label="Billing" value={'billing'} disableRipple disableTouchRipple disableFocusRibble/>*/}
              {/*}*/}
            </Tabs>
          </TemporaryTabsHolder>

        </DialogTitle>
        {/*<TabPanel value={tab} index={'billing'}>*/}
        {/*  <Content>*/}
        {/*    <Billing*/}
        {/*      //Show assisted lock view*/}
        {/*      assistedLock*/}
        {/*      // assistedLock={assistedLock}*/}
        {/*      brandId={this.props.brandId}*/}
        {/*      {...this.props.paymentsDetails}*/}
        {/*      onUpdatePayments={this.props.onUpdatePayments}*/}
        {/*    />*/}
        {/*  </Content>*/}
        {/*</TabPanel>*/}
        {/*<TabPanel value={tab} index={'credits'}>*/}
        {/*  <Content>*/}
        {/*    <Credits*/}
        {/*      //Show assisted lock view*/}
        {/*      assistedLock*/}
        {/*      // assistedLock={assistedLock}*/}
        {/*      plan={this.props.plan}*/}
        {/*      brandId={this.props.brandId}*/}
        {/*      credits={this.props.credits}*/}
        {/*      billingStatus={this.props.billingStatus}*/}
        {/*      chargebeeStatusProps={this.props.chargebeeStatusProps}*/}
        {/*      trialDaysLeft={this.props.trialDaysLeft}*/}
        {/*      onUpdateCredits={this.props.onUpdateCredits}*/}
        {/*      onPollingBrand={this.props.onPollingBrand}*/}
        {/*      onBookDemo={this.props.onBookDemo}*/}
        {/*      onClose={this.onClose}*/}
        {/*    />*/}
        {/*  </Content>*/}
        {/*</TabPanel>*/}
        <TabPanel value={tab} index={'brand'}>
          <Content>
            <Brand
              initialValidation
              noAction
              noText
              fullWidth
              disableChannel
              {...companyDetails}
              thinking={thinking}
              createRef={this.createRef('companyForm')}
              onChange={this.onInputsChange('company')}
              onSubmit={this.onSubmitBrand}
            />
          </Content>
        </TabPanel>
        <TabPanel value={tab} index={'account'}>
          <Content>
            <Account
              initialValidation
              noAction
              noText
              fullWidth
              disableEmail
              {...userDetails}
              thinking={thinking}
              createRef={this.createRef('accountForm')}
              onChange={this.onInputsChange('account')}
              onSubmit={this.onSubmitAccount}
            />
            <Email
              {...userDetails}
              onSubmit={({ email }) => this.props.onUpdateEmail(email)}
            />
            <ResetPassword
              {...userDetails}
              onReset={this.props.onResetPassword}
            />
          </Content>
        </TabPanel>
        <TabPanel value={tab} index={'team'}>
          <Content>
            <Invite onSendInvitation={this.props.onSendInvitation}/>
            <Members
              members={members}
              onRemoveMember={this.onRemoveMemberOpen/*this.props.onRemoveMember*/}
              onResendInvitation={this.props.onResendInvitation}
            />
          </Content>
        </TabPanel>

        {
          !DISABLE_BUTTONS.includes(tab) && (
            <ActionsHolder borderTop right>
              <Button onClick={this.onClose} disabled={thinking} variant="outlined"  color="primary">Dismiss</Button>
              <Button style={{margin: '0 0 0 25px'}} disabled={thinking} onClick={this.onSave} variant="contained" color="primary">Save</Button>
            </ActionsHolder>
          )
        }

        {
          removeMember && <ConfirmationDialog
            open={removeMember}
            onClose={this.onRemoveMemberClose}
            message={'Are you sure you want to remove this user? By doing so you end their access to the Brand account. If you wish to re-add them in future you will have to send them a new invite.'}
            actionText={'Remove'}
            onAction={this.onRemoveMember}
          />
        }
      </Modal>
    );
  }
}

export default Settings
