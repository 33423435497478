import React from 'react'
import styled from 'styled-components'

const Skeleton = styled.div`
    box-sizing: inherit;
    display: block;
`
const Loader = styled.div`
@keyframes loadingSkeleton {
    0% {
        opacity: .25
    }

    100% {
        opacity: 1
    }
  }
  animation-direction:alternate;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-name:loadingSkeleton;
  animation-timing-function:steps(10);
  display: inline-block;
  line-height: 1; 
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: ${props => props.radius ? props.radius+'px' : props.circle ? '100%' : '100px'};
  background: ${props => props.color} ;
  &.stop {
    animation-play-state: paused;
    background: #d5dff0;
  }
`

const SkeletonPlaceholder = props => {
  return (
    <Skeleton>
      <Loader {...props} className={ !props.loading && props.offAnimationLoaded && 'stop' } />
    </Skeleton>
  )
}


export default SkeletonPlaceholder
