import React from 'react'
import styled, {css} from 'styled-components'
import { find, findIndex, isEqual, isEmpty } from 'lodash'
import { Search as FeatherSearchIcon, X as FeatherX, Sliders as FeatherSliders } from 'react-feather'
import {
  ClickAwayListener, Fade,
  IconButton as MuiIconButton,
  Popper,
  Tooltip
} from '@material-ui/core'
import { styled as MuiStyled } from '@mui/material/styles';
import {Button as ButtonMui} from '@mui/material'
import FilterGroup from './FilterGroup'
import FilterDropdown from './FilterDropdown'
import ToggleRelevant from '../../new-components/ToggleRelevant/ToggleRelevant'
import {formatToReadableNumber} from '../../utils/format'

const SearchIcon = styled(FeatherSearchIcon)`
  color: #9E9E9E;
  width: 22px;
  height: 22px;
`
const Sliders = styled(FeatherSliders)`
  color: ${props => props.theme.palette.primary.main};
`

const ResetIcon = styled(FeatherX)`
  color: #9E9E9E;
  width: 22px;
  height: 22px;
`

export const SearchContainer = styled.div`
  border-radius: 3px;
  padding: 2px 50px 2px 80px;
  min-height: 50px;
  background-color: ${props => props.theme.palette.brand.grey.light};
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${props => props.theme.palette.brand.grey.darker};
  transition: background-color 0.3s ease;
  &:hover {
    border: 1px solid ${props => props.theme.palette.brand.grey.darker};
    background-color: white;
  }
`;

export const InputAdornment = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  
 ${props => props.variant === 'start' && css`
   width: 80px;
   left: 0;
  `}
 
  ${props => props.variant === 'end' && css`
    right: 0;
    min-width: 50px;
    //max-width: 100px;
    justify-content: flex-end;
  `}    
`;

export const SearchArea = styled.div`
  //background: aqua;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;
  box-sizing: border-box;
`

const Button = MuiStyled(ButtonMui)(({ theme }) => ({
  height: 40,
  marginRight: 5
}))

class FiltersPopover extends React.Component {
  render () {
    const {
      anchorEl,
      ...rest
    } = this.props

    return !!anchorEl ? (
      <ClickAwayListener onClickAway={this.props.onApply}>
        <Popper
          disablePortal
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement={'bottom-start'}
          transition
          style={{zIndex: 10}}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <FilterDropdown {...rest} />
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    ) : null
  }
}

const getInitialFilters = (props, initialFilter) => {
  let normalizedFilters = []
  const { filters } = props
  const keys = createMarks(props.keys)
  for (let key in filters) {
    if (filters.hasOwnProperty(key)) {
      const filter = find(keys, ['id', key])
      if (filter) {
        normalizedFilters = [...normalizedFilters, { key: filter, id: filter.id, value: filters[key] }]
      }
    }
  }

  const lastFilter = normalizedFilters[normalizedFilters.length - 1]

  if (!!lastFilter && lastFilter.id === 'search') {
    return normalizedFilters
  }
  return [ ...normalizedFilters, initialFilter ]
}

const removeFilter = filter => {
  if (filter && filter.key) {
    const { key: { kind, min, max }, value} = filter
    if (kind === 'range') {
      return value[0] === min && value[1] === max
    } else if (kind === 'multiselect') {
      return !value || !value.length
    }
  }
  return false
}

const createMarks = keys => {
  return keys.map(key => {
    if (key.kind === 'range') {
      const { min, max } = key
      const delta = max - min
      const step = delta <= 20 ? 1 : Math.round(Math.abs((delta) / 10))
      const midValue = Math.round(Math.abs((delta) / 2))
      const marks = [
        {
          value: min,
          label: formatToReadableNumber(min)
        },
        {
          value: midValue,
          label: formatToReadableNumber(midValue)
        },
        {
          value: max,
          label: formatToReadableNumber(max)
        }
      ]
      return {...key, marks, step}
    }
    return key
  })
}

class Filter extends React.Component {
  static defaultProps = {
    filters: {}
  }

  constructor (props) {
    super(props)
    const filters = getInitialFilters(props, this.initialFilter)
    this.state = { filters }
  }

  container = null

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { variant, intelligenceKind } = this.props

    if (variant === 'intelligence' && intelligenceKind !== prevProps.intelligenceKind || (isEmpty(prevProps.filters) && !isEmpty(this.props.filters))) {
      const filters = getInitialFilters(this.props, this.initialFilter)
      this.setState({ filters })
    }

  }

  isLastFilterComplete = () => {
    const { filters } = this.state

    if (filters.length - 1 < 0) return true

    const { key, value } = filters[filters.length - 1]
    return !!key && !!value && key.kind !== 'search'
  }

  onDelete = (index, replace, withoutApply) => {
    const { filters } = this.state

    if (true === replace) {
      filters[index] = this.initialFilter
    } else if (!filters[index].key && index > 0) {
      filters.splice(index - 1, 1)
    } else {
      filters.splice(index, 1)
    }
    this.setState({ filters })
    if (!filters.length || this.isLastFilterComplete()) {
      this.setState({ filters: [ ...filters, this.initialFilter ] })
    }

    if (!withoutApply) {
      this.onApply(filters)
    }
  }

  onChange = (index, filter) => {
    const { filters } = this.state
    const searchFilter = {...find(filters, ['id', 'search'])}
    const { key, value, id } = filter

    if (filter.id === 'type1Classification' && filter.value === 'all') {
      return this.onDelete(index)
    }

    filters[index]['id'] = id
    filters[index]['key'] = key
    filters[index]['value'] = value

    if (removeFilter(filters[index])) {
      filters.splice(index, 1)
    }

    if (searchFilter && searchFilter.hasOwnProperty('id') && filter.id !== 'search') {
      filters.push(searchFilter)
    }

    this.setState({ filters })



    if (this.isLastFilterComplete()) {
      this.setState({ filters: [ ...filters, this.initialFilter ]})
    }

    this.onApply(filters)
  }

  onReset = () => {
    this.setState({ filters: [ this.initialFilter ]})
    this.onApply([])
  }

  onApply = filters => {
    let result = {}

    filters.forEach(({ id, value }) => {
      if (!!value) {
        result[id] = value
      }
    })

    if (!isEqual(result, this.props.filters)) {
      this.props.onChange(result)
    }
  }

  ///////////// Filters Dropdown
  onGetFilter = (name) => {
    const { filters } = this.state
    const keys = this.filtersKeys
    return find(filters, ['id', name]) || { id: name, value: undefined, key: find(keys, ['id', name]) }
  }

  onChangeDropdownFilters = filter => {
    const { filters } = this.state
    const index = findIndex(filters, ['id', filter.id])

    if (filter.id === 'type1Classification' && filter.value === 'all') {
      return this.onDelete(index, false, true)
    }

    if (index >= 0) {
      if (filter.key.kind === 'bool' && !filter.value) {
        filters.splice(index, 1)
      } else {
        filters[index] = filter
      }
      if (removeFilter(filters[index])) {
        filters.splice(index, 1)
      }
      this.setState({ filters })
    } else {
      if (!removeFilter(filters[index])) {
        this.setState({ filters: [ filter, ...filters ]})
      }
    }
  }

  onApplyDropdownFilters = () => {
    const { filters } = this.state
    this.onApply(filters)
    this.onCloseDropdown()
  }

  onCancelDropdownFilters = () => {
    this.onReset()
  }

  onOpenDropdown = () => {
    this.setState({ anchorEl: this.container })
  }

  onCloseDropdown = () => {
    this.setState({ anchorEl: null })
  }
  ///////////// Filters Dropdown

  get initialFilter () {
    return { key: null, value: null, id: Math.random(), edit: true }
  }

  get filtersKeys () {
    return createMarks(this.props.keys)
  }

  get availableFilters () {
    return this.filtersKeys.filter(option => {
      const filter = this.state.filters.filter(({ key }) => {
        return key && key.id === option.id && key.id !== 'search'
      })[0]
      return !filter
    })
  }

  onMoveSearch = filter => {
    const { filters } = this.state
    this.setState({ filters: [...filters, filter] })
  }

  render () {
    const {
      filters,
      anchorEl
    } = this.state
    const {
      variant,
      intelligenceKind,
      intelligenceProps,
      disablePopover
    } = this.props

    return (
      <SearchContainer ref={node => this.container = node}>
        <InputAdornment variant={'start'} styles={{left: 40}}>
          {
            variant !== 'profile' && !disablePopover && (
              <>
                <Tooltip title='All filters'>
                  <MuiIconButton onClick={this.onOpenDropdown} sizeSmall><Sliders size={18} style={{transform: 'rotate(90deg)'}} /></MuiIconButton>
                </Tooltip>
                &nbsp;
              </>
            )
          }
          <SearchIcon />
        </InputAdornment>
        <SearchArea>
          {
            filters.map((filter, index) => (
              <FilterGroup
                disablePopover={disablePopover}
                key={filter.id}
                variant={variant}
                index={index}
                keyOptions={this.availableFilters}
                getValuesAsync={this.props.values}
                groupBy={this.props.groupBy}
                placeholder={'Search and Filter'}
                filter={filter}
                onMoveSearch={this.onMoveSearch}
                onChange={this.onChange}
                onDelete={this.onDelete}
                onShowAll={this.onOpenDropdown}
              />
            ))
          }
        </SearchArea>
        <InputAdornment variant={'end'}>
          {
            !!filters[0] && !!filters[0].key && !!filters[0].key.id && (
              <MuiIconButton onClick={this.onReset} sizeSmall>
                <ResetIcon />
              </MuiIconButton>
            )
          }
          {/*{*/}
          {/*  this.props.variant === 'leads' && <ToggleRelevant active={this.props.withoutRelevant} onChange={this.props.onToggleWithoutRelevant}/>*/}
          {/*}*/}
          {
            this.props.variant === 'intelligence' && intelligenceProps.showFilterKind &&  (
              <>
                <Button disableElevation size="small" sx={{fontSize: 12, textTransform: 'none'}} disabled={intelligenceProps.disableAllFilter} onClick={() => intelligenceProps.onFilterKindChange(null)} variant={!intelligenceProps.filterKind ? "contained" : 'outlined'}>All</Button>
                <Button disableElevation size="small" sx={{fontSize: 12, textTransform: 'none'}} onClick={() => intelligenceProps.onFilterKindChange('existingPartnersFor')} variant={intelligenceProps.filterKind === 'existingPartnersFor' ? "contained" : 'outlined'}>Existing&nbsp;Partners</Button>
                <Button disableElevation size="small" sx={{fontSize: 12, textTransform: 'none'}} onClick={() => intelligenceProps.onFilterKindChange('potentialLeadsFor')} variant={intelligenceProps.filterKind === 'potentialLeadsFor' ? "contained" : 'outlined'}>Potential&nbsp;Leads</Button>
              </>
            )
          }
        </InputAdornment>

        <FiltersPopover
          variant={variant}
          intelligenceKind={intelligenceKind}
          anchorEl={anchorEl}
          keys={this.filtersKeys}
          filters={filters}
          onGetFilter={this.onGetFilter}
          onChange={this.onChangeDropdownFilters}
          onApply={this.onApplyDropdownFilters}
          onCancel={this.onCancelDropdownFilters}
        />
      </SearchContainer>
    )
  }
}

export default Filter
