import React from 'react'
import styled from 'styled-components'
import {
  Table, TableBody, TableRow, TableCell
} from '@material-ui/core'

import Pane from '../Details/Pane'
import { languages } from '../../data/languages'

const imageKitUrl = process.env.IMAGEKIT_URL

const LanguageIcon = styled.img`
  height: 24px;
  border-radius: 50%;
`

const TableCellBody = styled(TableCell)`
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 5px 15px;
  height: 25px;
`;

const LanguageLabel = styled.div`
  display: flex;
  align-items: center;
  span {
    padding-left: 10px;
  }
`

export default function LanguagesWidget ({ data }) {

  return (
    <Pane empty={data.length === 0} emptyMessage={'No language data is available yet'}>
      <Table aria-label="Languages table">
        <TableBody>
          {
            data.map(language => (
              <TableRow key={language}>
                <TableCellBody>
                  <LanguageLabel>
                    <LanguageIcon alt={languages[language]} src={`${imageKitUrl}/languages/${language}.svg`} />
                    <span>{languages[language]}</span>
                  </LanguageLabel>
                </TableCellBody>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </Pane>
  )
}
