import { action, computed, observable, toJS } from 'mobx'
import Queries from './queries'
import { find } from 'lodash'
import { Get } from '../utils/request'
import GqlClient from '../gql/gql-client'
import { GET_BRAND_ECOSYSTEMS } from '../gql/ecosystems'


class IntelligenceStore extends Queries {
  @observable id
  @observable data = []
  @observable list = []

  fields = ['id']

  constructor () {
    super()
    this.getQueriesFromSearch()
  }

  @action
  async load (brandId) {
    const { success, ...rest } = await GqlClient.query({ query: GET_BRAND_ECOSYSTEMS, variables: { id: brandId } })

    if (success) {
      this.data = rest.data['brand'].ecosystems.map(({ ecosystem }) => ({...ecosystem }))
    }

    return { success, message: 'message', data: toJS(this.data) }
  }

  @action
  setEcosystems (ecosystems, ecosystemId) {
    this.list = ecosystems.map(({ id, name, ...rest }) => ({ id, label: name, name, ...rest }))
    const list = toJS(this.list)

    if (ecosystemId) {
      this.id = ecosystemId
    } else if (list && list.length) {
      const { id } = list[0]
      this.id = id
      this.updateQueries()
    }
  }

  @action
  setSelected (ecosystemId) {
    this.id = ecosystemId
    this.updateQueries()
  }

  @computed
  get selected () {
    return find(toJS(this.list), ['id', this.id])
  }

  @computed
  get ecosystemId () {
    return toJS(this.id)
  }

  @computed
  get ecosystems () {
    return toJS(this.list)
  }

  @computed
  get brandEcosystems () {
    return toJS(this.data)
  }
}

export default new IntelligenceStore()
