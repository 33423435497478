import React from 'react'
import { inject, observer } from 'mobx-react'
import Settings from './Settings'
import MembersStore from '../../stores/members'
import PaymentsStore from '../../stores/payments'

import withAccess from '../../permissions/withAccess'

const AccessSettings = withAccess(Settings)

@inject(({ UserStore, BrandStore, SearchStore }) => ({
  user: UserStore.user,
  onResetPassword: details => UserStore.forgotPassword(details),
  onUpdateEmail: email => UserStore.updateEmail(email, 'verify'),
  onSubmitAccount: details => UserStore.update(details),

  brandId: BrandStore.id,
  role: BrandStore.role,
  company: BrandStore.details,
  credits: BrandStore.credits,
  plan: BrandStore.plan,
  billingStatus: BrandStore.billingStatus,
  trialDaysLeft: BrandStore.trialDaysLeft,
  assistedLock: BrandStore.assistedLock,
  chargebeeStatusProps: BrandStore.chargebeeStatus,
  onSubmitBrand: details => BrandStore.update(details),
  onUpdateCredits: details => BrandStore.updateCounters(details),
  onPollingBrand: () => BrandStore.pollingBillingStatus(),
  onBookDemo: () => BrandStore.openBooking(),
}))
@observer
export default class _Settings extends React.Component {
  constructor (props) {
    super(props)
    this.membersStore = props.role === 'brandOwner' ? new MembersStore(props.brandId) : {}
    this.paymentsStore = (props.role === 'brandOwner' && !!props.billingStatus) ? new PaymentsStore(props.brandId) : {}
  }

  onSendInvitation = async ({ email }) => {
    return await this.membersStore.invite(email)
  }

  onRemoveMember = (id) => this.membersStore.remove(id)
  onResendInvitation = (id) => this.membersStore.reInvite(id)

  onUpdatePayments = () => this.paymentsStore.load()

  render () {
    const { members } = this.membersStore
    const { paymentsDetails } = this.paymentsStore

    return <AccessSettings
      {...this.props}
      members={members}
      paymentsDetails={{...paymentsDetails , load: () => this.paymentsStore.load()}}
      onSendInvitation={this.onSendInvitation}
      onRemoveMember={this.onRemoveMember}
      onResendInvitation={this.onResendInvitation}
      onUpdatePayments={this.onUpdatePayments}
    />
  }
}

