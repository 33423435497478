import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StepperMUI from '@material-ui/core/Stepper';
import WebsiteIcon, {Thumbnail} from '../../components/Leads/Icon'
import StepConnector from '@material-ui/core/StepConnector';
import styled from 'styled-components'
import icons from '../../components/AffiliateNetworkIcon'

const ICON_SIZE = 25

const StepNumber = styled.div`
  position: absolute;
  background: blue;
  color: white;
  width: 1.3em;
  height: 1.3em;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -0.5em;
  right: -0.5em;
  font-size: 10px;
`
const Step = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 8px;
  align-items: center;
`
const Icon = styled.div`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Thumbnail} {
    margin-right: 0;
  }
  svg {
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
  }
`;
const Label = styled.div`
  color: white;
  padding-top: 0.2em;
  font-size: 12px;
`
const IconHolder = styled.div`
  position: relative;
  background: white;
  color: blue;
  border: 2px blue solid;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`
const Root = styled.div`
  display: flex;
  padding: 15px 10px 10px;
  ${Step}:first-child ${IconHolder}, ${Step}:last-child ${IconHolder} {
    border-radius: 5px;
  }
  ${Step}:after, ${Step}:before {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    background: blue;
    right: -14px;
    top: 14px;
    position: absolute;
  }
  ${Step}:after {
    right: -14px;
  }
  ${Step}:before {
    left: -14px;
  }
  ${Step}:first-child:before, ${Step}:last-child:before {
    display: none;
  }
  ${Step}:first-child:after {
    right: -14px;
  }
  ${Step}:last-child:after {
    left: -14px;
  }
`

export default function NestedAffiliatesMap({networks, website, target}) {
  let hops = networks
  if (typeof networks === 'string') hops = [networks]
  function renderStep(icon, name, number) {
    return (
      <Step>
        <IconHolder>
          {Boolean(number) && <StepNumber>{number}</StepNumber>}
          <Icon>{icon}</Icon>
        </IconHolder>
        <Label>{name}</Label>
      </Step>
    );
  }
  return (
    <Root>

      {renderStep(<WebsiteIcon item={website.icon}/>, website.name)}

      {hops.map((hop, i) => {
        return renderStep(icons[hop], hop, i + 1)
      })}

      {renderStep(<WebsiteIcon item={target.icon}/>, target.name)}

    </Root>
  );
}
