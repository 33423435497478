import React, { Component } from 'react'
import SkeletonMuiUnstyled, {SkeletonProps as SkeletonPropsBase} from '@mui/material/Skeleton'
import styled, {css} from 'styled-components'

const SkeletonMui = styled(SkeletonMuiUnstyled)`
    background-color: ${props => props.theme.palette.brand.shadow.light}!important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
`

const SkeletonBg = styled.span`
    background: ${props => props.theme.palette.brand.shadow.main};
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    position: relative;
  ${props => !props.inline && css`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    `}
`

interface SkeletonProps extends SkeletonPropsBase{
    children: Array<React.ReactNode> | React.ReactNode
    loading?: boolean
}

interface SkeletonBaseProps extends SkeletonPropsBase {
    inline?: boolean
}

function Skeleton ({ children, loading }: SkeletonProps) {
    return <>
        {
            loading
            ? <SkeletonBase />
            : children
        }
    </>
}

export function SkeletonBase ({children, width, height, ...props}:SkeletonBaseProps) {
    return <SkeletonBg width={width} height={height} {...props}><SkeletonMui width={'100%'} height={'100%'} {...props}>{children}</SkeletonMui></SkeletonBg>
}

SkeletonBase.defaultProps = {
    width: '100%',
    height: '100%',
    variant: 'rounded'
}

export default Skeleton
