import React from 'react'
import styled from 'styled-components'
import {
  FormControlLabel as MuiFormControl,
  Checkbox as MuiCheckbox,
  Grid,
} from '@material-ui/core'
import {Form, Input} from '../Form'
import Validator from '../../utils/validator'
import {FormsModal} from '../Forms/FormsModal'
import {
  Content,
  ActionsHolder,
  Button,
} from '../Modal'

const Root = styled.div`
  padding: 0 20px;
`

const FORM_ID = 'account-form'

const schema = [
  {
    field: 'firstName',
    rules: 'required',
    messages: {
      required: 'First name is required'
    }
  },
  {
    field: 'lastName',
    rules: 'required',
    messages: {
      required: 'Last name is required'
    }
  },
  // {
  //   field: 'email',
  //   rules: 'required|valid_email',
  //   messages: {
  //     required: 'Email is required',
  //     valid_email: 'Please enter valid email'
  //   }
  // }
]

export default class Account extends React.Component {
  constructor(props) {
    super(props)
    const {firstName, lastName, email, consent} = this.props
    this.state = {firstName, lastName, email, consent, errors: {}, error: null}

    // console.log('formConstructor')
  }

  componentDidMount () {
    if (this.props.initialValidation) {
      Validator(FORM_ID, schema,
        errors => this.setState({errors}),
        () => {}
      )
    }
  }

  onChange = name => ({target: {value, checked}}) => {
    this.setState({[name]: name === 'consent' ? checked : value})
    this.resetError(name)
    if (this.props.onChange) {
      this.props.onChange({[name]: name === 'consent' ? checked : value})
    }
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      async () => {
        const {firstName, lastName} = this.state

        const {error} = await this.props.onSubmit({firstName, lastName})

        if (error) {
          const message = error === 'emailExists' ? 'User with given email already exist' : error
          this.setState({errors: {email: message}})
        }
      })
  }

  onCancel = () => {
    const {email, consent, firstName, lastName} = this.props
    this.setState({firstName, lastName, email, consent, errors: {}, error: null})
  }

  render() {
    const {firstName, lastName, email, consent, errors} = this.state
    const {
      header,
      subHeader,
      welcome,
      thinking,
      error,
      noAction,
      noText,
      fullWidth,
      disableEmail,
      createRef
    } = this.props
    return (
      <Root>
        <Form
          noValidate
          formId={FORM_ID}
          error={error}
          thinking={thinking}
          createRef={createRef}
          onSubmit={this.onSubmit}
        >
          <Grid container>
            <Grid item xs={12}>
              <Input
                autoFocus
                required
                fullWidth
                placeholder="e.g. Joe"
                margin="normal"
                id={'firstName'}
                label={'First name'}
                inputLabel={'First name'}
                variant={'outlined'}
                fieldType='clearable'
                value={firstName}
                disabled={thinking}
                error={errors.firstName}
                onChange={this.onChange('firstName')}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required
                fullWidth
                placeholder="e.g. Bloggs"
                margin="normal"
                id={'lastName'}
                label={'Last name'}
                inputLabel={'Last name'}
                variant={'outlined'}
                fieldType='clearable'
                value={lastName}
                disabled={thinking}
                error={errors.lastName}
                onChange={this.onChange('lastName')}
              />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <Input*/}
            {/*    margin="normal"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    placeholder="e.g. joe.bloggs@example.com"*/}
            {/*    id={'email'}*/}
            {/*    fieldType='clearable'*/}
            {/*    label={'Email'}*/}
            {/*    inputLabel={'Email'}*/}
            {/*    variant={'outlined'}*/}
            {/*    value={email}*/}
            {/*    error={errors.email}*/}
            {/*    disabled={thinking || disableEmail}*/}
            {/*    onChange={this.onChange('email')}*/}
            {/*  />*/}
            {/*</Grid>*/}
          </Grid>
        </Form>
      </Root>
    )
  }
}

