import React, { Component } from 'react'
import styled from 'styled-components'
import { ArrowRightCircle } from 'react-feather'



const LinkIcon = styled(ArrowRightCircle)`
  stroke: currentColor;
  width: 0.95em;
  height: 0.95em;
  flex: 0 0 0.95em;
  transform: rotate(-45deg);
  position: absolute;
  top: 0.2em;
  right: 0.2em;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
`

function LinkOverlay () {
    return <LinkIcon />
}

export default LinkOverlay
