import React from 'react'
import styled from 'styled-components'
import {StyledEngineProvider} from '@mui/material/styles'
import ButtonBase, {ButtonBaseProps} from '@mui/material/ButtonBase'
import {ChevronLeft, ChevronRight} from 'react-feather'

interface BaseProps {
    horizontal?: boolean
    active?: boolean
    width?: string
    height?: string
}

interface RootProps extends ButtonBaseProps, BaseProps {}

const Root = styled(ButtonBase)<RootProps>(({theme, width, height, horizontal }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
    height: height,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    opacity: 0.5,
    transition: 'opacity 0.3s ease, background 0.3s ease',
    background: `rgba(186, 248, 244, 0)`,
    '&:hover': {
        opacity: 1,
        background: `linear-gradient(${horizontal ? '180deg' : '-90deg'}, rgba(186, 248, 244, 0), rgba(186, 248, 244, 0.3))`
    },
    '& svg': {
        transform: horizontal ? 'rotate(-90deg)' : 'rotate(0deg)'
    }
}))


interface EdgeActiveProps extends BaseProps {
    onClick?: () => void
}

function EdgeActive ({ active, ...rootProps}: EdgeActiveProps) {
    return (
        <StyledEngineProvider  injectFirst>
            <Root {...rootProps}>
                {active ? <ChevronRight/> : <ChevronLeft/>}
            </Root>
        </StyledEngineProvider>
    )
}

EdgeActive.defaultProps = {
    horizontal: false,
    active: false,
    onClick: () => {},
    width: '100%',
    height: '100%'
}

export default EdgeActive
