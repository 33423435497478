import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import {
  Close,
  ActionsHolder,
  DialogTitle,
  Modal
} from '../Modal'

import Sign from '../SVGs/Trash.svg'

export default class ConfirmationDialog extends React.Component {
  static defaultProps = {
    message: 'Are you sure you want to delete',
    actionText: 'Remove'
  }

  render () {
    const {
      open,
      message,
      subMessage,
      actionText
    } = this.props

    return (
      <Modal
        disableMediaQuery
        maxWidth="sm"
        open={open}
        ariaLabelledby="dialog-title"
        onBackdropClick={this.props.onClose}
        onEscapeKeyDown={this.props.onClose}
      >
        {/*<DialogTitle>*/}
        {/*  /!*<Close aria-label="close" onClick={this.props.onClose}>*!/*/}
        {/*  /!*  <CloseIcon/>*!/*/}
        {/*  /!*</Close>*!/*/}
        {/*</DialogTitle>*/}

        <div style={{display: 'flex'}}>


        <img src={Sign} alt="Hand wave" style={{maxWidth: 150, margin: '2.5em', marginRight: 0}} />

        <div style={{ padding: '70px 70px 50px 50px', fontSize: 16 }}>
          { message }
          { subMessage && <br/> }
          { subMessage }
        </div>
        </div>

        <ActionsHolder borderTop right>
          {/*<Note>You will be able to re-run discovery search once you update inputs.</Note>*/}
          <Button
            color="primary"
            variant="outlined"
            mb={2}
            mr={4}
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            mb={2}
            onClick={this.props.onAction}
          >
            &nbsp;&nbsp;{actionText}&nbsp;&nbsp;
          </Button>
        </ActionsHolder>
      </Modal>
    )
  }
}
