import React from 'react'
import styled from 'styled-components'
import { Button as MuiButton, Grid } from '@material-ui/core'
import { Form, Input } from '../Form'
import Validator from '../../utils/validator'

const Root = styled.div`
  padding: 0 20px;
`

const Button = styled(MuiButton)`
  text-transform: none;
  font-weight: 600;
`

const Title = styled.h4`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
`

const Text = styled.p`
  font-size: 14px;
  color: #494949;
  margin: 20px 0;
`

const ActionsHolder = styled.div`
  max-width: 300px;
  margin: 32px auto 0 auto;
  text-align: center;
  width: 100%;
`

const FORM_ID = 'email-form'
const schema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  }
]

export default class Email extends React.Component {
  constructor(props) {
    super(props)
    const { email } = this.props
    this.state = { email, errors: {}, error: null, changed: false, updated: false }
  }

  onChange = name => ({ target: { value } }) => {
    this.setState({ [name]: value })
    this.resetError(name)
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    event.preventDefault()
    Validator(FORM_ID, schema,
      errors => this.setState({errors}),
      async () => {
        const { email } = this.state
        const { error } = await this.props.onSubmit({ email })

        if (error) {
          switch (error) {
            case 'emailAlreadyExists':
              this.setState({errors: {email: 'User with given email already exist'}})
              break;
            case 'emailExists':
              this.setState({errors: {email: 'User with given email already exist'}})
              break;
            case 'noEmail':
              this.setState({errors: {email: 'Email not found'}})
              break;
            case 'wrongPassword':
              this.setState({errors: {password: 'Your password is not correct'}})
              break;
            default:
              this.setState({errors: {email: error}})
          }
        } else {
          this.setState({ updated: true, changed: false })
        }

      }
    )
  }

  render() {
    const { email: oldEmail, thinking, disable } = this.props
    const {
      email,
      error,
      errors,
      updated,
      changed
    } = this.state

    return (
      <Root>
        <Form
          noValidate
          formId={FORM_ID}
          error={error}
          thinking={thinking}
          onSubmit={this.onSubmit}
        >
          <Grid container>
            <Grid item xs={12}>
              <Input
                margin="normal"
                required
                fullWidth
                placeholder="e.g. joe.bloggs@example.com"
                id={'email'}
                fieldType='clearable'
                label={'Email'}
                inputLabel={'Email'}
                variant={'outlined'}
                value={email}
                error={errors.email}
                disabled={thinking || disable}
                onChange={this.onChange('email')}
              />
            </Grid>
          </Grid>
          { updated && !changed && <Text>An email with instructions will be sent to your inbox to reset your password.</Text> }
          {
            oldEmail !== email && (
              <ActionsHolder>
                <Button variant="outlined" color="primary" type={'submit'}>Change email</Button>
              </ActionsHolder>
            )
          }

        </Form>
      </Root>
    )
  }
}

//

