import React from "react"
import * as Icon from "./AffiliateNetworkIcon"
import {eBayPartnerNetwork} from "./AffiliateNetworkIcon";

const icons = {
  "Amazon": <Icon.Amazon />,
  "Amazon Associates": <Icon.Amazon />,
  "Impact": <Icon.Impact />,
  "Fintel Connect": <Icon.FintelConnect />,
  "RevenueWire": <Icon.RevenueWire />,
  "Pepperjam": <Icon.Pepperjam />,
  "Paid On Results": <Icon.PaidOnResults />,
  "LinkConnector": <Icon.LinkConnector />,
  "Commission Factory": <Icon.CommissionFactory />,
  "ClickBank": <Icon.ClickBank />,
  "AvantLink": <Icon.AvantLink />,
  "Avangate": <Icon.Avangate />,
  "refersion": <Icon.Refersion />,
  "ADCELL": <Icon.Adcell />,
  "AWIN": <Icon.Awin />,
  "Admitad": <Icon.Admitad />,
  "Affiliate Future": <Icon.AffiliateFuture />,
  "Commission Junction": <Icon.CommissionJunction />,
  "FinanceQuality": <Icon.FinanceQuality />,
  "Groupon": <Icon.Groupon />,
  "Netaffiliation": <Icon.NetAffiliation />,
  "Partnerize": <Icon.Partnerize />,
  "Rakuten": <Icon.Rakuten />,
  "ShareASale": <Icon.ShareASale />,
  "ShopStyle": <Icon.ShopStyle />,
  "Skimlinks": <Icon.Skimlinks />,
  "StackCommerce": <Icon.Stackcommerce />,
  "Target Circle": <Icon.TargetCircle />,
  "TradeTracker": <Icon.TradeTracker />,
  "Tradedoubler": <Icon.Tradedoubler />,
  "VigLink": <Icon.VigLink />,
  "Webgains": <Icon.Webgains />,
  "Zanox": <Icon.Zanox />,
  "belboon": <Icon.Belboon />,
  "eBay Partner Network": <Icon.eBayPartnerNetwork />,
  "rewardStyle": <Icon.RewardStyle />,
  "Geniuslink": <Icon.Geniuslink />,
  "Tune": <Icon.Tune />,
  "Indoleads": <Icon.Indoleads />,
  "LTK": <Icon.Ltk />,
  "Branch": <Icon.Branch />,
  "Walmart Affiliates": <Icon.WalmartAffiliates />,
  "Everflow": <Icon.Everflow />,
  "TinyURL": <Icon.TinyUrl />,
  "Bitly": <Icon.Bitly />,

  //Added from admin panel
  "Edu-Affiliates": <Icon.UnknownNetwork /> ,
  "DatingJam": <Icon.UnknownNetwork /> ,
  "DirectAffiliate": <Icon.UnknownNetwork /> ,
  "cpaIkon": <Icon.UnknownNetwork />,
  "LeadReaktor": <Icon.UnknownNetwork />,
  "LuckyOnline": <Icon.UnknownNetwork />,
  "Media500": <Icon.UnknownNetwork />,
  "MediAdvice": <Icon.UnknownNetwork />,
  "CpaHub": <Icon.UnknownNetwork />,
  "GetCake": <Icon.UnknownNetwork />,
  "ByOffers": <Icon.UnknownNetwork />,
  "Biggico": <Icon.UnknownNetwork />,
  "BigBangAds": <Icon.UnknownNetwork />,
  "AdAttract": <Icon.UnknownNetwork />,
  "FastSpring": <Icon.UnknownNetwork />,
  "TorFX": <Icon.UnknownNetwork />,
  "Tanga": <Icon.UnknownNetwork />,
  "Soicos": <Icon.UnknownNetwork />,
  "Bankier.pl": <Icon.UnknownNetwork />,
  "AVANTRIP": <Icon.UnknownNetwork />,
  "Get Ambassador": <Icon.UnknownNetwork />,
  "NetSalesMedia": <Icon.UnknownNetwork />,
  "Partnerstack": <Icon.UnknownNetwork />,
  "LinkPrice": <Icon.UnknownNetwork />,
  "AdMarula": <Icon.UnknownNetwork />,
  "Adlabs Global": <Icon.UnknownNetwork />,
  "Adscend Media": <Icon.UnknownNetwork />,
  "Adslot": <Icon.UnknownNetwork />,
  "AdsMain": <Icon.UnknownNetwork />,
  "1xPartenrs": <Icon.UnknownNetwork />,
  "AdsBridge": <Icon.UnknownNetwork />,
  "Adform": <Icon.UnknownNetwork />,
  "Goaffpro": <Icon.UnknownNetwork />,
  "Income Access": <Icon.UnknownNetwork />,
  "Revfresh Marketplace": <Icon.UnknownNetwork />,
  "lemonads": <Icon.UnknownNetwork />,
  "Evadav": <Icon.UnknownNetwork />,
  "Post Affiliate Pro": <Icon.UnknownNetwork />,
  "Leadsale": <Icon.UnknownNetwork />,
  "Affise": <Icon.UnknownNetwork />,
  "Cpamatica": <Icon.UnknownNetwork />,
  "AdCombo": <Icon.UnknownNetwork />,
  "Familyblend": <Icon.UnknownNetwork />,
  "Gasmobi": <Icon.UnknownNetwork />,
  "Tracdelight": <Icon.UnknownNetwork />,
  "Affmy": <Icon.UnknownNetwork />,
  "HugeOffers": <Icon.UnknownNetwork />,
  "Leadbit": <Icon.UnknownNetwork />,
  "AvaPartner": <Icon.UnknownNetwork />,
  "Lomadee": <Icon.UnknownNetwork />,
  "LeadAff": <Icon.UnknownNetwork />,
  "LinkWise": <Icon.UnknownNetwork />,
  "Adfox": <Icon.UnknownNetwork />,
  "Avantgate": <Icon.UnknownNetwork />,
  "MGID": <Icon.UnknownNetwork />,
  "Perform[cb]": <Icon.UnknownNetwork />,
  "FlexOffers": <Icon.UnknownNetwork />,
  "Mobidea": <Icon.UnknownNetwork />,
  "MaxWeb": <Icon.UnknownNetwork />,
  "Travelpayouts": <Icon.UnknownNetwork />,
  "ArabClicks": <Icon.UnknownNetwork />,
  "RefferalRock": <Icon.UnknownNetwork />,
  "PepperJam": <Icon.UnknownNetwork />,
  "PeerFly": <Icon.UnknownNetwork />,
  "TheAffiliateGateway": <Icon.UnknownNetwork />,
  "TopAdvert": <Icon.UnknownNetwork />,
  "cPark": <Icon.UnknownNetwork />,
  "Crakrevenue": <Icon.UnknownNetwork />,
  "FinAff": <Icon.UnknownNetwork />,
  "Swaarm": <Icon.UnknownNetwork />,
  "Partner-Ads": <Icon.UnknownNetwork />,
  "Paynura": <Icon.UnknownNetwork />,
  "DoAff": <Icon.UnknownNetwork />,
  "Adtraction": <Icon.UnknownNetwork />,
  "TORO Advertising": <Icon.UnknownNetwork />,
  "Adrecord": <Icon.UnknownNetwork />,
  "PropellerAds": <Icon.UnknownNetwork />,
  "MoreNiche": <Icon.UnknownNetwork />,
  "Rebrandly": <Icon.UnknownNetwork />,
  "CityAds": <Icon.UnknownNetwork />,
  "Optimise Media": <Icon.UnknownNetwork />,
  "eSpolupráce": <Icon.UnknownNetwork />,
  "Circlewise": <Icon.UnknownNetwork />,
  "vCommission": <Icon.UnknownNetwork />,
  "TimeOne": <Icon.UnknownNetwork />,
  "LEADS": <Icon.UnknownNetwork />,
  "Matching Visions": <Icon.UnknownNetwork />,
  "LeadGid": <Icon.UnknownNetwork />,
  "Aivix": <Icon.UnknownNetwork />,
  "Advendor": <Icon.UnknownNetwork />,
  "Pay4Results": <Icon.UnknownNetwork />,
  "MyLead": <Icon.UnknownNetwork />,
  "Yieldkit": <Icon.UnknownNetwork />,
  "Effiliation": <Icon.UnknownNetwork />,

  "unknown": <Icon.UnknownNetwork />
}

export default icons

