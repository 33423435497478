import React from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import SignupForm from '../../components/Forms/SignUpRoleSelection'
import UniversalCookie from 'universal-cookie'

const Cookies = new UniversalCookie()

const removeTags = () => {
  return Cookies.remove(`tags`)
}

const readTags = () => {
  return Cookies.get(`tags`)
}

const saveTags = (tags) => {
  Cookies.set(`tags`, JSON.stringify(tags), { path: '/' })
}

const UTM_TAGS = ['utm_medium', 'utm_source', 'utm_campaign', 'utm_content']

const getBookDemoQueries = ({ firstName, lastName, email }) => {
  const hasFirstName = Boolean(firstName)
  const name = `${firstName}${hasFirstName ? ` ${lastName}` : ''}`

  return `?email=${email}${Boolean(name) ? `&name=${name}` : ''}`
}

@inject(({ UserStore }) => ({
  thinking: UserStore.thinking,
  user: UserStore.user,
  onSubmit: (details, tags) => UserStore.signup(details, tags)
}))
@observer
@withRouter
export default class WelcomeUser extends React.Component {
  constructor(props) {
    super(props)

    const { firstName, lastName, email, ...rest } = qs.parse(props.location.search)

    let tags = {}

    UTM_TAGS.forEach(tag => {
      if (rest.hasOwnProperty(tag)) {
        tags[tag] = rest[tag]
      }
    })

    saveTags(tags)

    this.state = {firstName, lastName, email, tags}
  }

  onSubmit = async (details) => {
    const { email, firstName, lastName, role, ...restDetails } = details

    if (role === 'brand') {
      return window.location.href = `https://breezy.io/book-a-demo/${getBookDemoQueries({ email, firstName, lastName })}#calendlyWidget`
    }

    const tags = readTags() || this.state.tags

    const { success, error } = await this.props.onSubmit({
      email,
      firstName,
      lastName,
      isCreator: role === 'creator',
      ...restDetails
    }, tags)

    if (success) {
      removeTags()
      this.props.history.push({
        pathname: '/verify-sent',
        state: { email }
      })
    }

    return { error: null }
  }

  render () {
    const { thinking } = this.props

    return (
      <SignupForm
        thinking={thinking}
        onSubmit={this.onSubmit}
        header={'Sign up'}
        subHeader={'Sign up to create an account and start your journey towards easy-breezy partner discovery.'}
      />
    )
  }
}
