import React from 'react'
import styled from 'styled-components'

export const Footer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  background: white;
  top: auto;
  bottom: 0;
  padding: 10px 20px;
  width: 100%;
  align-items: center;
  height: 65px;
  border-top: 1px solid rgba(0,0,0,0.1);
  //box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`