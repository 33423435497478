export type PromoteKeys = 'creator' | 'brand'

export type PromotesProps = {
    heading: string
    name: string
}

const promotes:{[key: string]: PromotesProps} = {
    brand: {
        heading: 'Promoted by',
        name: 'Creator'
    },
    creator: {
        heading: 'Promotes',
        name: 'Brand'
    }
}

export default promotes
