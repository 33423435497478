import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Filter from '../../new-components/Filter'
import { ContactRequestsTable } from '../../new-components/List/ContactsTable'
import TagsManagement from '../../new-components/Tags/_TagsManagement'

import {
  SEARCH,
  REQUESTER
} from '../../configFilters'

const Container = styled.div`
  width: 100%;
  padding: 0.5em 1em 0;
  height: calc(100vh - 116px);
`

const computeFiltersConfig = () => {
  return [
    SEARCH,
    REQUESTER
  ]
}

function RequestsTable (props) {
  const { brandId } = useParams()

  const [tagsManagement, setTagsManagement] = useState(null)
  const { filters, listProps, sortProps } = props
  const { loading, total, items } = listProps

  const onSort = (model) => {
    if (model && model[0]) {
      const { sort: direction, field } = model[0]
      props.onSort(field, direction)
    }
  }

  const onFilters = (filters = {}) => props.onFilters(filters)

  const getChannelTags = (channelId) => {
    const manageChannel = items.filter(item => item.channelId === channelId)[0]

    if (manageChannel) {
      const { tags } = manageChannel
      return tags
    }

    return []
  }

  const onCloseTagsManagement = () => {
    setTagsManagement(null)
  }

  const getTagsManagementDetails = (channelId, target) => {
    setTagsManagement({
      anchorEl: target,
      leadId: channelId
    })
  }

  const onQuickActions = (id, action, domain, e) => {
    if (action === 'toExport') {
      props.onExportToCSV()
    } else {
      const contact = items.filter(item => item.id === id)[0]
      if (contact && action === 'toManageTags') {
        const { channelId } = contact
        getTagsManagementDetails(channelId, e.target)
        return null
      }
    }
  }

  const onManageTags = ({ channelId, tag, tagId, e }, action) => {
    if (action === 'select') {
      props.onAddTag(channelId, tag)
    } else if (action === 'remove') {
      props.onRemoveTag(channelId, tagId)
    } else if (action === 'update') {
      props.onUpdateTag(tag)
    } else if (action === 'delete') {
      props.onDeleteTag(tagId)
    } else if (action === 'open') {
      getTagsManagementDetails(channelId, e.target)
    }
  }

  useEffect(() => {
    props.load(brandId)
  }, [])

  const sortModel = { sort: sortProps.order, field: sortProps.orderBy }

  return (
    <Container>
      <Filter
        disablePopover
        variant={'contacts'}
        placeholder={'Select a filter'}
        filters={filters}
        keys={computeFiltersConfig()}
        onChange={onFilters} />

      <ContactRequestsTable
        hideFooter
        total={total}
        loading={loading}
        rows={items.map(item => ({...item, onQuickActions, onManageTags}))}
        sortingOrder={['desc', 'asc']}
        sortModel={[sortModel]}
        onRowsScrollEnd={props.onPageChange}
        onCellClick={() => null}
        onSortModelChange={onSort}
        onManageTags={onManageTags}
        onQuickActions={onQuickActions}
        onColumnResize={console.log}
      />

      {
        Boolean(tagsManagement) && (
          <TagsManagement
            {...tagsManagement}
            itemTags={getChannelTags(tagsManagement.leadId)}
            onClose={onCloseTagsManagement}

            onSelectTag={(tag) => onManageTags({ channelId: tagsManagement.leadId, tag }, 'select')}
            onUpdateTag={(tag) => onManageTags({ tag }, 'update')}
            onRemoveTag={(tagId, channelId) => onManageTags({ channelId, tagId }, 'remove')}
            onDeleteTag={(tagId) => onManageTags({ tagId }, 'delete')}
          />
        )
      }
    </Container>
  )
}

export default inject(({ ContactRequestsStore }) => ({
  filters: ContactRequestsStore.filters,
  listProps: ContactRequestsStore.listProps,
  sortProps: ContactRequestsStore.sortProps,
  load: (brandId) => ContactRequestsStore.load(brandId),
  onAddTag: (channelId, tag) => ContactRequestsStore.addTags(channelId, tag),
  onUpdateTag: (tag) => ContactRequestsStore.updateTags(tag),
  onRemoveTag: (channelId, tagId) => ContactRequestsStore.removeTags(channelId, tagId),
  onDeleteTag: (tagId) => ContactRequestsStore.deleteTags(tagId),
  onSort: (direction, sort) => ContactRequestsStore.sorting(direction, sort),
  onFilters: filters => ContactRequestsStore.filtersChange(filters),
  onPageChange: page => ContactRequestsStore.pageChange(page + 1),
  onPerPageChange: rows => ContactRequestsStore.perPageChange(rows),

  onExportToCSV: () => ContactRequestsStore.exportToCSV()

}))(observer(RequestsTable))
