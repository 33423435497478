import React from 'react'
import styled from 'styled-components'
import { Collapse, IconButton as MuiIconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {TabCompact as Tab, TabsCompact as Tabs} from '../Tabs'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Pane from '../Details/Pane'
import ShowAll from '../ShowAll'
import Link from '../Link'
import Icon from './Icon'
import IconLabelSearch from '../../new-components/IconLabelSearch'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'

const List = styled.ul`
  margin: 0;
  padding: 0.2em 15px 0;
`

const ListItem = styled.li`
  display: block;
  font-size: 14px;
  color: #000000; 
`;

const Heading = styled.div`
  font-size: 12px;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 8px 0;
  overflow: hidden;
`
const Label = styled.div`
  font-size: 12px;
  line-height: 25px;
  color: #828282;
`

const Accordion = styled(MuiAccordion)`
    box-shadow: none;
    border: 0;
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  &:before {
      display: none;
    }
    margin: auto;
  //'&:not(:last-child)': {
  //  borderBottom: 0,
  //},
  //'&:before': {
  //  display: 'none',
  //},
  //'&$expanded': {
  //  margin: 'auto',
  //},
  //},
  //expanded: {},
`

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: auto;
  padding: 0;
  margin: 0;
  overflow: hidden;
  .MuiAccordionSummary-content {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .MuiAccordionSummary-expandIcon {
    padding: 5px;
    margin-right: 3px;
  }
`

const AccordionDetails = styled(MuiAccordionDetails)`
`

const clearKeyword = str => str ? str.replace(/::\d+$/, '') : ''

const ListItemsByUrl = ({ list, kind }) => (
  <>
    {
      list.map(({ id, url, list, icon }) => (
        list && list.length ? (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${id}-content`}
              id={`panel${id}-header`}
            >
            <Header>
              {
                kind === 'bySearch' && <Label><IconLabelSearch>{url}</IconLabelSearch></Label>
              }

              {
                kind === 'byRelevant' && (
                  <>
                    <Icon item={{ icon }} size={12} color={'#828282'} />
                    <Link
                      disabled
                      pathOnly
                      value={url}
                      // link={url}
                      rtl
                      title={url}
                    />
                  </>
                )
              }

            </Header>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {
                  list.map(item => <ListItem>{clearKeyword(item)}</ListItem>)
                }
              </List>
            </AccordionDetails>
          </Accordion>
        ) : null
      ))
    }
  </>
)


class SeedsList extends React.Component {
  static defaultProps = {
    list: [],
    message: '',
    minRows: 5
  }

  state = { collapsed: true, tab: 'all' }

  onToggleCollapsed = () => this.setState({ collapsed: !this.state.collapsed })

  onTabChange = (e, tab) => this.setState({ tab })

  get slicedList () {
    const { list, minRows } = this.props
    const { collapsed } = this.state

    return collapsed ? list.slice(0, minRows) : list
  }

  render() {
    const {
      kind,
      list,
      listByUrl,
      listByProduct,
      message,
      minRows
    } = this.props
    const { collapsed, tab } = this.state
    const listLength = (list || []).length || (listByProduct || []).length

    return (
      <Pane empty={listLength === 0} emptyMessage={message}>
        <Heading>
          <Tabs
            value={tab}
            onChange={this.onTabChange}>
            {/*<Tab value="show" label="Show" disabled/>*/}
            <Tab value="all" label="All" />
            <Tab value="byRelevant" label="By Relevant Pages" />
            {
              kind === 'brand' && <Tab value="bySearch" label="By Search" />
            }
          </Tabs>
        </Heading>

        {
          tab === 'all' && (
            <>
              <List>
                {
                  this.slicedList.map((item) => <ListItem>{clearKeyword(item)}</ListItem>)
                }
              </List>
                  <ShowAll
                    more={listLength > minRows}
                    amount={minRows}
                    total={list.length}
                    collapsed={collapsed}
                    onClick={this.onToggleCollapsed}
                  />
            </>
          )
        }

        {
          tab === 'byRelevant' && <ListItemsByUrl kind={tab} list={listByUrl} />
        }

        {
          tab === 'bySearch' && <ListItemsByUrl kind={tab} list={listByProduct} />
        }

      </Pane>
    );
  }
}

export default SeedsList
