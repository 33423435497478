import React from 'react'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'

import { languages } from '../../data/languages'

const imageKitUrl = process.env.IMAGEKIT_URL || 'https://ik.imagekit.io/afill'

const Root = styled.div`
  display: flex;
`

const LanguageIcon = styled.img`
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;
`

const OtherCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 400;
  background: ${props => props.theme.palette.brand.grey.dark};
`

interface LanguagesInterface {
    limit?: number;
    data: Array<string>;
}


const Languages = ({ limit, data }:LanguagesInterface) => {
    const compact = limit > 0
    const list =  compact ? data.slice(0, limit) : data
    const otherCount = data.length - limit
    const empty = !list.length
    return <Root>
        {
            !empty ? list.map(lang => {
                const language = languages[lang]

                return lang !== 'missing' ? (
                    <Tooltip title={language}>
                        <LanguageIcon alt={language} src={`${imageKitUrl}/languages/${lang}.svg`} />
                    </Tooltip>
                ) : null
            }): null
        }
        { compact && otherCount > 0 && <OtherCount>+{otherCount}</OtherCount> }
    </Root>
}

Languages.defaultProps = {
    compact: false,
    limit: 5,
    data: []
}

export default Languages
