import React from 'react'
import styled from 'styled-components'

import { Autocomplete, TextField as TextFieldMui, Box } from '@mui/material'
import { findIndex } from 'lodash'

import SingleValue from '../SelectDropdown/SingleValue'
import MultipleValues from '../SelectDropdown/MultipleValues'

const Root = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 10px;
  padding: 10px 0 0;
  z-index: 1;
  position: relative;
`

// InputProps={{ disableUnderline: true }} />

const TextField = styled((props) => (
  <TextFieldMui fullWidth variant="standard" {...props} />
))(({ theme }) => ({
  background: 'white',
  '& .MuiStandardInput-root': {
    // height: 50,
    fontSize: 14,
    padding: '0 9px 0!important',
  },
  '& .MuiAutocomplete-input': {
    background: 'white',

    minHeight: '40px!important'
  }
}));

export default function EcosystemComparisonSelect ({ primary, secondary, channels, showFilterKind, filterKind, disableAllFilter, onChange, onFilterKindChange }) {
  const selectedPrimaryIds = primary.map(({ id }) => id)
  const selectedSecondaryIds = secondary.map(({ id }) => id)

  const primaryOptions = channels.filter(channel => {
    if (!secondary || !secondary.length) return true
    return !selectedSecondaryIds.includes(channel.id)
  })

  const secondaryOptions = channels.filter(channel => {
    if (primary && primary.id === channel.id) return false
    return !selectedPrimaryIds.includes(channel.id)
  })

  return <Root variant="outlined">
    <strong>Compare</strong>
    <MultipleValues
      placeholder={'You'}
      valueField={'id'}
      labelField={'domain'}
      inputPlaceholder={'Search multiple brands'}
      values={primary}
      limitTags={3}
      options={primaryOptions}
      onApply={value => onChange('primary')(value)}
    />
    &nbsp;
    <strong>with</strong>
    &nbsp;
    <MultipleValues
      placeholder="Competitors"
      valueField={'id'}
      labelField={'domain'}
      inputPlaceholder={'Search multiple competitors'}
      values={secondary}
      limitTags={3}
      options={secondaryOptions}
      onApply={values => onChange('secondary')(values)}
    />
  </Root>
}
