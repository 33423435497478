import React from 'react'
import { inject, observer } from 'mobx-react'
import PlansModal from './PlansModal'

@inject(({ BrandStore, PartnersStore, ProspectsStore }) => ({
  loading: BrandStore.loading,
  booking: BrandStore.booking,
  showPlans: BrandStore.showPlans,
  plan: BrandStore.plan,
  role: BrandStore.role,
  brandId: BrandStore.id,
  credits: BrandStore.credits,
  inactive: BrandStore.inactive,
  trialDaysLeft: BrandStore.trialDaysLeft,
  billingStatus: BrandStore.billingStatus,
  onTrialSubscribe: (plan) => BrandStore.selectSubscription(plan),
  onClose: () => BrandStore.hidePlansModal(),
  onUpdateCredits: details => BrandStore.updateCounters(details),
  onBookDemo: () => BrandStore.openBooking(),
}))
@observer
export default class _SubscriptionInactiveModal extends React.Component {
  state = { open: false }

  // inTrial
  // active
  // paused
  // cancelled
  // nonRenewing

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ((prevProps.loading && !this.props.loading) || (!prevProps.showPlans && this.props.showPlans)) {
      this.setState({ open: this.props.inactive || this.props.showPlans })
    }
  }

  onClose = () => {
    this.setState({ open: false })
    this.props.onClose()
  }

  render () {

    return <PlansModal
      open={this.state.open}
      {...this.props}
      onClose={this.onClose}
    />
  }
}
