import React from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import ForgotPasswordForm from '../../components/Forms/ForgotPassword'
import Success from '../../components/Success'

@inject(({ UserStore }) => ({
  thinking: UserStore.thinking,
  user: UserStore.user,
  onSubmit: details => UserStore.forgotPassword(details)
}))
@observer
@withRouter
export default class ForgotPassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = { success: false, email: '' }
  }

  onSendAgain = () => {
    this.setState({ success: false })
  }

  onSubmit = async (details) => {
    const { error } = await this.props.onSubmit(details)
    this.setState({ success: !error, email: details.email })
    return { error }
  }


  render () {
    const { success, email } = this.state
    const { thinking } = this.props
    return success ?
      <Success
        header={'Check your email'}
        subHeader={'If you can’t find it, please, check the spam or verify the email that you’ve provided.'}
        email={email}
        onClick={this.onSendAgain}
        forgotPasswordSuccess
      />
      :
      (
      <ForgotPasswordForm
        thinking={thinking}
        onSubmit={this.onSubmit}
        header={'Forgot Password'}
        subHeader={`Please enter the email address you registered with and we will send you a link to reset your password.`}
      />
    )
  }
}
