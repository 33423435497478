import React, {useContext} from 'react'
import { CreatorRequestContext } from '../CreatorRequestDetailsContext'
import WidgetMetric from '../../Widget/WidgetMetric'
import WidgetVisits from '../../Widget/WidgetVisits'

interface DetailsMetricsProps {
    onClick: Function
}

export default function CreatorRequestDetailsMetrics (props: DetailsMetricsProps) {
    const {
        loading,
        metrics, countries,
        summary, geoFilter
    } = useContext(CreatorRequestContext)

    const { type, partnershipsInCount, partnershipsOutCount } = summary
    const showPromotes = Boolean(type && type === 'creator')
    const showPromotedBy = Boolean(type && type === 'brand' )
    const promotesWidgetSize = '1x1' //['gap', 'landscape', 'partnerships'].includes(variant) ? '1x1' : '2x1'

    return  <>
        <WidgetMetric loading={loading} variant={'moz'} value={metrics.domainAuthority} onClick={props.onClick} />
        <WidgetVisits loading={loading} countries={countries} geo={geoFilter} total={summary.swVisits} onClick={props.onClick} />
        <WidgetMetric loading={loading} variant={'rank'} value={metrics.swGlobalRank} onClick={props.onClick}/>

        { showPromotes && <WidgetMetric loading={loading} size={promotesWidgetSize} variant={'promotes'} value={partnershipsOutCount} onClick={() => null}/> }
        { showPromotedBy && <WidgetMetric loading={loading} size={promotesWidgetSize} variant={'promotedBy'} value={partnershipsInCount} onClick={() => null} /> }
    </>
}
