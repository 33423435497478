import React, {useContext, useState} from 'react'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

import {DetailsContext} from '../DetailsContext'
import {PlacementInterface} from '../interfaces'
import Placement from '../../Profile/Placements/Placement'
import Filter from '../../../new-components/Filter'
import ProfileAside from '../ProfileView/ProfileAside'
import ProfileLayout from '../../Profile/ProfileLayout'
import LayoutSearchableList from '../LayoutSearchableList'
import {useParams} from "react-router-dom";

const getFiltersConfig = (searches) => {
    return [
        {
            id: 'search',
            kind: 'search',
            label: `Search for this text`
        },
        {
            label: 'Searches',
            id: 'searches',
            kind: 'multiselect',
            options: searches || [],
            placeholder: "E.g.: Search name"
        }
    ]
}

interface Filter {
    search?: string
    searches?: Array<{ value: string, label: string }>
}

const filter = (data: Array<PlacementInterface>, filters: Filter): Array<PlacementInterface> => {
    if (isEmpty(filters)) return data

    const { searches, search } = filters

    const pattern = search ? new RegExp(search, 'i') : null
    const searchIds = searches ? searches.map(({ value }) => value) : null

    return data.filter(({ title, searchId }) => {
        const bySearch = pattern ? pattern.test(title) : true
        const bySearches = searches ? searchIds.includes(searchId) : true

        return bySearch && bySearches
    })
}

interface RelevantPlacementsViewProps {
    onSetViewState: Function
    onQuickActions?: () => void
}

export default function RelevantPlacementsView (props: RelevantPlacementsViewProps) {
    // @ts-ignore
    const { brandId } = useParams()
    const link = `/brand/${brandId}/discovery`
    return <RelevantPlacementsViewBase {...useContext(DetailsContext)} link={link} {...props}/>
}


const Header = styled.div`
  grid-column: span 2;
`

const Search = styled.div`
  grid-column: span 2;
  position: relative;
`
const List = styled.div`
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`


interface RelevantPlacementsViewBaseProps {
    loading?: boolean
    link?: string
    placements?: Array<any>
    searches?: Array<any>
    summary?: any
}

export function RelevantPlacementsViewBase (props: RelevantPlacementsViewBaseProps) {
    const [filters, setFilters] = useState({})
    const { loading, placements, searches, summary, link, networks } = props

    const search = <Filter variant={'profile'} placeholder={'Search and Filter'} filters={filters}
        keys={getFiltersConfig(searches.map(({ id, name }) => ({ value: id, label: name })))} onChange={setFilters} />
    const title ='Placements '
    const domain = {...summary, loading: loading}

    return (
        <ProfileLayout aside={<ProfileAside {...props} fullHeight />}>
            <LayoutSearchableList filter={search} title={title} domain={domain}>
                {
                    //TODO: Hack for Copy Text for AI summary property
                    filter(placements, filters).map(placement => <Placement {...placement} link={link} summary={summary} networks={networks} />)
                }
            </LayoutSearchableList>
        </ProfileLayout>
    )
}

