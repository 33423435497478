import { gql } from '@apollo/client'

const typeDefs = gql`
  extend type ClassificationByLevelInput {
    level: Int!
    name: String!
  }

  extend type SigninUserInput {
    email: String!
    password: String!
  }

  extend type SignupUserInput {
    email: String!
    password: String!
    consent: Boolean
    firstName: String
    isCreator: Boolean
    lastName: String
    receiveNotifications: Boolean
  }

  extend type User {
    id: ID!
  }

  extend type UserWithToken {
    token: String!
    user: User!
  }

  extend type InviteUserInput {
    brandId: String!
    email: String!
  }

  extend type UpdateUserInputPatch {
    consent: Boolean
    description: String
    email: String
    facebookId: String
    firstName: String
    lastName: String
    receiveNotifications: Boolean
  }

  extend type UpdateUserInput {
    id: String!
    patch: UpdateUserInputPatch!
  }

  extend type CreateBrandInput {
    name: String!
    link: String
    logoUrl: String
    description: String
  }

  extend type UpdateBrandInputPatch {
    name: String
    flags: String
    link: String
    logoUrl: String
    description: String
    onboardingStep: String
    status: String
  }

  extend type UpdateBrandInput {
    id: String!
    patch: UpdateBrandInputPatch!
  }

  extend type CreateSearchInput {
    brandId: String!
    countryCode: String!
    domain: String!
  }

  extend type UpdateSearchInputPatch {
    competitorUrls: String
    description: String
    irrelevants: String
    keywords: String
    name: String
  }

  extend type UpdateSearchInput {
    id: String!
    patch: UpdateSearchInputPatch!
  }

  extend type ImportPartnersInput {
    brandId: String!
    links: [String!]!
  }
  extend type ChangeProspectsKindInput {
    kind: String!
    brandId: String!
    ids: [String!]!
  }
  extend type DeleteProspectsInput {
    brandId: String!
    ids: [String!]!
  }

  extend type CreateFoldersInput {
    brandId: String!
    kind: String!
    names: [String!]!
  }
  extend type CreateFolderInput {
    brandId: String!
    kind: String!
    names: String!
  }
  extend type UpdateFolderInputPatch {
    name: String
    status: String
  }
  extend type UpdateFolderInput {
    id: String!
    brandId: String!
    patch: UpdateFolderInputPatch!
  }
  extend type ReorderFoldersInput {
    brandId: String!
    kind: String!
    folderIds: [String!]!
  }

  extend type AddProspectToFolderInput {
    id: String!
    brandId: String!
    prospectIds: [String!]!
  }

  extend type RemoveProspectsFromFolderInput {
    id: String!
    brandId: String!
    prospectIds: [String!]!
  }

  extend type RequestContactsInput {
    brandId: String!
    channelIds: [String!]!
    outreachMessage: String
  }

  extend type CreateTagInput {
    brandId: String!
    favourite: Boolean
    name: String!
  }

  extend type UpdateTagInputPatch {
    favourite: Boolean
    name: String
  }

  extend type UpdateTagInput {
    id: String!
    brandId: String!
    patch: UpdateTagInputPatch!
  }

  extend type CreateTagInput {
    brandId: String!
    favourite: Boolean
    name: String!
  }

  extend type MarkChannelsWithTagInput {
    id: String!
    brandId: String!
    channelIds: [String!]!
  }

  extend type RemoveTagFromChannelsInput {
    id: String!
    brandId: String!
    channelIds: [String!]!
  }

  extend type CreateProspectInput {
    brandId: String!
    domain: String!
    kind: String!
  }

  extend type CreateProspectsInput {
    brandId: String!
    channelIds: [String!]!
    kind: String!
  }

  extend type CreateEventInput {
    brandId: String
    kind: String!
  }
`

export default typeDefs
