import React from 'react'
import styled from 'styled-components'
import { orderBy } from 'lodash'
import { Tooltip } from '@mui/material'
import icon from '../../components/AffiliateNetworkIcon'
import { NetworksInterface } from '../Details/interfaces'

const imageKitUrl = process.env.IMAGEKIT_URL || 'https://ik.imagekit.io/afill'

const Root = styled.div`
  display: flex;
`

const Icon = styled.div`
  width: 25px;
  height: 25px;
`;

const OtherCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 400;
  background: ${props => props.theme.palette.brand.grey.dark};
`

export interface NetworksProps {
    limit?: number;
    data: Array<NetworksInterface>;
}


const Networks = ({ limit, data }:NetworksProps) => {
    const compact = limit > 0
    const networks = data
    const list = compact ? networks.slice(0, limit) : networks
    const otherCount = networks.length - limit
    const empty = !list.length

    return !empty ? <Root>
        {
            list.map(({ name }) => {
                return (
                    <Tooltip title={name}>
                        <Icon>{ icon[name] || icon['unknown'] }</Icon>
                    </Tooltip>
                )
            })
        }
        { compact && otherCount > 0 && <OtherCount>+{otherCount}</OtherCount> }
    </Root> : null
}

Networks.defaultProps = {
    compact: false,
    limit: 5,
    data: {}
}

export default Networks
