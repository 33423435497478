import React from 'react'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom'
import {BreezyLogo} from "../../components/Icons/BreezyLogo"
import {
  SwipeableDrawer as MuiDrawer,
  Typography,
} from '@material-ui/core'
// import { Content } from '../../components/Modal'

const Drawer = styled(MuiDrawer)`
  z-index: unset;
  border-right: 0;
  padding: 0;
  > div {
    position: relative;
    z-index: unset;
    height: 100vh;
    overflow: hidden;
    //padding: 18px 12px;
    border-right: 0;
    background: ${props => props.theme.palette.brand.grey.light};
    display: flex;
  }
`;

const LogoHolder = styled.div`
  padding: 1em 0 1em 1em;
  
  width: 120px;
  box-sizing: border-box;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Sidebar = ({ loading, children, PaperProps }) => (
  <Drawer variant="permanent" PaperProps={PaperProps} onClose={()=>{}} onOpen={()=>{}} open>
    <Wrapper>
      <LogoHolder>
        <BreezyLogo compact version={process.env.VERSION} />
      </LogoHolder>
    </Wrapper>
      { children }
  </Drawer>
)

export default withRouter(Sidebar)
