import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { find } from 'lodash'
import LeadsBase from './Leads'

const listProperties = (store) => ({
  loading: store.loading,
  listProps: store.listProps,
  sortProps: store.sortProps,
  filters: store.filters,
  geoFilter: store.geoFilter,
  hasFilters: store.hasFilters,
  withoutFavourites: store.withoutFavourites,
  exportCSVUrl: store.exportCSVUrl,
  withoutRelevant: store.withoutRelevant,

  onExportToCSV: () => store.exportToCSV(),
  onGetAllIds: () => store.loadIds(),
  onLoad: (loading) => store.load(null, null, !loading),
  onPageChange: (page) => store.pageChange(page),
  onPerPageChange: (perPage) => store.perPageChange(perPage),
  onSort: (key, value, callback, reject) =>
    store.sorting(key, value, callback, reject),
  onFiltersChange: (filters) => store.filtersChange(filters),
  onToggleShowFavorites: () => store.toggleShowFavorites(),
  onRemoveItemsFromCurrent: (ids) => store.removeItemsFromList(ids),
  onToggleCompetitors: (ids) => store.toCompetitors(ids),
  onToggleIrrelevant: (ids) => store.toBlocklist(ids),
  onRemoveFromSpecialList: (ids) => store.removeFromSpecialList(ids),
  onToggleWithoutRelevant: () => store.toggleWithoutRelevant(),

  onAddLeadTags: (leads, tags, brandId) => store.addTags(leads, tags, brandId),
  onRemoveLeadTags: (leads, tags, brandId) =>
    store.removeTags(leads, tags, brandId),
  onUpdateLeadTags: (tag) => store.updateTags(tag),

  onToggleToPartners: (kind, kindView, actionKind, ids) =>
    store.toggleToPartners(kind, kindView, actionKind, ids),

  onUpdateNotesCount: (id, count) => store.updateNotesCount(id, count),
})

const productProperties = (store) => ({
  searchId: store.id,
  productName: store.name || 'My first product or service',
  competitors: store.competitorUrls,
  newCompetitors: store.newCompetitorUrls,
  keywords: store.keywords,
  blocklist: store.irrelevants,

  firstResearchSeen: store.firstResearchSeen,
  keywordsChanged: store.keywordsChanged,
  competitorUrlsChanged: store.competitorUrlsChanged,
  irrelevantsChanged: store.irrelevantsChanged,

  reportCount: store.totals.total,
  prospectsCount: store.totals.prospects,
  partnersCount: store.totals.partners,

  researchStatus: store.researchStatus,
  competitorsCount: store.competitorsCount,
  keywordsCount: store.keywordsCount,
  tldsOptions: store.tldsOptions,
  maxValues: store.maxValues,

  productLoading: store.loading,
  productThinking: store.thinking,
  isProductNotCompletelyFilled: store.isNotCompletelyFilled,
  newReportIsReady: store.newReportIsReady,

  planLimits: store.planLimits,

  expired: store.hidden,
  expiredDaysLeft: store.expiredDaysLeft,

  onResetReportSeen: () => store.resetReportSeen(),
  onUpdateProduct: (details) => store.update(details),
  onCreateCompany: (details) => store.createCompany(details),
})

const partnersProperties = (store) => ({
  onImportExistingPartners: (details) => store.importPartners(details),
})

export const createLeadList = (kind, storeName) => {
  return inject((stores) => ({
    kind,
    firstName: stores.UserStore.firstName,
    fullName: stores.UserStore.user.fullName,

    brandId: stores.BrandStore.id,
    paywall: stores.BrandStore.paywall,
    billingStatus: stores.BrandStore.billingStatus,

    ...listProperties(stores[storeName], kind),

    onUpdateData: () => {
      stores[storeName].load(null, null, null).then()
      stores.SearchStore.updateCounters().then()
      stores.BrandStore.updateCounters().then()
    },

    ...productProperties(stores.SearchStore),
    ...partnersProperties(stores.PartnersStore),

    onStartDiscovery: () =>
      stores.BrandStore.initReRun({
        searchId: stores.SearchStore.id,
        kind: 'product',
      }),

    onAddToList: (listId, leadIds) =>
      stores.ProductListsStore.addToList(listId, leadIds),

    onUpdateTag: (details) => stores.TagsStore.update(details),
    onDeleteTag: (id) => stores.TagsStore.remove(id),

    onRequestSingle: (itemIds, message) =>
      stores[storeName].requestLeadContacts(itemIds, message),
    onRequestSelected: (itemIds, message) =>
      stores[storeName].requestLeadsContacts(itemIds, message),
  }))(observer(LeadsBase))
}

export const Report = createLeadList('report', 'ReportStore')

export const Competitors = createLeadList('competitors', 'CompetitorLeadsStore')

export const Irrelevant = createLeadList('irrelevant', 'IrrelevantLeadsStore')

export const Prospects = createLeadList('relevants', 'ProspectLeadsStore')

@inject(
  ({
    UserStore,
    BrandStore,
    ReportStore,
    PartnerLeadsStore,
    ProspectLeadsStore,
    LeadsListStore,
    SearchStore,
    ProductListsStore,
    PartnersStore,
  }) => ({
    kind: 'lead-list',
    firstName: UserStore.firstName,
    fullName: UserStore.user.fullName,

    brandId: BrandStore.id,
    paywall: BrandStore.paywall,
    billingStatus: BrandStore.billingStatus,

    ...listProperties(LeadsListStore, 'leads-list'),

    onUpdateData: () => {
      LeadsListStore.load(null, true, null)
      SearchStore.updateCounters()
      BrandStore.updateCounters()
    },

    onSetListId: (listId) => LeadsListStore.setListId(listId),

    onRequestSingle: (itemIds, channelId, brandId) =>
      LeadsListStore.requestLeadContacts(itemIds, channelId, brandId),
    onRequestSelected: (itemIds, brandId) =>
      LeadsListStore.requestLeadsContacts(itemIds, brandId),

    ...productProperties(SearchStore),
    ...partnersProperties(PartnersStore),

    onStartDiscovery: () =>
      BrandStore.initReRun({ searchId: SearchStore.id, kind: 'product' }),

    lists: ProductListsStore.lists,
    listsLoading: ProductListsStore.loading,
    onAddToList: (listId, prospectIds) =>
      ProductListsStore.addToList(listId, prospectIds),
    onRemoveFromList: (listId, prospectIds) =>
      ProductListsStore.removeFromList(listId, prospectIds),
  })
)
@observer
@withRouter
export class LeadsList extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: { listId },
      },
    } = props
    props.onSetListId(listId)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: {
        params: { listId: prevListId },
      },
    } = prevProps
    const {
      match: {
        params: { listId },
      },
    } = this.props

    if (listId !== prevListId) {
      this.props.onSetListId(listId)
    }
  }

  get listTotal() {
    const {
      match: {
        params: { listId },
      },
    } = this.props
    const list = find(this.props.lists, ['id', listId])

    return list ? list.prospectsCount : 0
  }

  get listName() {
    const {
      match: {
        params: { listId },
      },
    } = this.props
    const list = find(this.props.lists, ['id', listId])

    return list ? list.name : 'Breezy'
  }

  get listId() {
    const {
      match: {
        params: { listId },
      },
    } = this.props
    return listId
  }

  render() {
    return (
      <LeadsBase
        {...this.props}
        listId={this.listId}
        listTotal={this.listTotal}
        listName={this.listName}
      />
    )
  }
}

export { default } from './Leads'
