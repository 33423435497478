import React from 'react'
import styled from "styled-components";
import {
  Button as MuiButton,
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'


const Button = styled(MuiButton)`
  font-size: 16px;
  box-shadow: none;
  text-transform: none;
  padding: 9px 3em;
  border-radius: 100px;
`

const ButtonLeft = styled(MuiButton)`
  font-size: 16px;
  box-shadow: none;
  text-transform: none;
  padding: 9px 3em;
  border-radius: 100px 0 0 100px;
  //height: 50px;
  .MuiButton-label {
    white-space: nowrap;
  }
`

const ButtonRight = styled(MuiButton)`
  font-size: 16px;
  box-shadow: none;
  text-transform: none;
  padding: 11px 0;
  border-radius: 0 100px 100px 0;
  min-width: 40px;
  //height: 50px;
  margin-left: 1px;
  .MuiButton-label {
    white-space: nowrap;
  }
`

export const MenuButton = ({
                             variant,
                             color,
                             md,
                             children,
                             disabled,
  onPrimaryClick,
  onSecondaryClick
                           }) => {

  return (
    <>
      <ButtonLeft
        classes={variant === 'outlined' && {
          root: 'outlined',
        }}
        variant={variant}
        color={color}
        md={md}
        disabled={disabled}
        onClick={onPrimaryClick}
      >
        { children }
      </ButtonLeft>
      <ButtonRight
        classes={variant === 'outlined' && {
          root: 'outlined',
        }}
        variant={variant}
        color={color}
        disabled={disabled}
        onClick={onSecondaryClick}
      >
        <ArrowDropDown />
      </ButtonRight>
    </>
  )
}

export default class StyledButton extends React.Component {
  render() {
    const {variant, ...other} = this.props
    return (
      <Button
        classes={variant === 'outlined' && {
          root: 'outlined',
        }}
        variant={variant}
        {...other}
      />)
  }
}
