import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import Button from "../../components/Button"
import PaywallModal from '../Modals/PaywallModal'
import bg from './bg.png'
import config from '../../config'
import { sendEvent } from '../../utils/events'

const BannerRoot = styled.div`
  background-color: white;
  background-image:  url(${bg}), url(${bg});
  background-repeat: no-repeat, no-repeat;
  background-position: 100% center, 60% top;
  background-size: 30%, 30%;
  box-shadow: 0 -5px 15px rgba(0,0,0,0.05);
  color: black;

  display: flex;
  //justify-content: center;
  align-items: center;
  padding: 2em;
  height: 90px;
  width: 100%;
  border-top: solid 1px ${props => props.theme.palette.brand.grey.main};
  //background-image:  ;
  position: relative;
  z-index: 9;
  font-family: Work Sans, Arial, sans-serif;
`

const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const Lead = styled.div`
  font-size: 18px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3em;
`


const PaywallBanner = (props) => {
  const [open, setOpen] = useState(false)
  const onOpen = () => {
    setOpen(true)
    sendEvent(props.brandId, 'unlockPaywallButton')
  }
  const onClose = () => {
    setOpen(false)
  }
  return (
    <>
      <BannerRoot>
        <Content>
          <Heading>Clear Pricing. No Surprises.</Heading>
          <Lead>Upgrade today to see your full set of results. Cancel anytime.</Lead>
        </Content>
        <Button
          variant={'contained'}
          color={'primary'}
          size={'large'}
          onClick={onOpen}
        >
          Unlock full access
        </Button>
      </BannerRoot>
      <PaywallModal open={open} brandId={props.brandId} onClose={onClose} />
    </>
  )
}

export default inject(({ BrandStore }) => ({
  brandId: BrandStore.id
}))(observer(PaywallBanner))
